import React from "react";
import "./adminDashboard.css/adminPopup.component.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { useState, useContext, useEffect, useRef } from "react";
import { UserContext } from "../../../App";
import sqlService from "../../../services/sqlService";
import timeUtility from "../../../functions/timeUtility";
import DatePicker from "react-datepicker";
import stripeService from "../../../services/stripeService";

export default function AdminStripe({
  onClose,
  item,
  stripeItem,
  columns,
  editFunction,
  setRefreshData,
  embedded
}) {
  const user = useContext(UserContext);
  const [changes, setChanges] = useState({ id: item.id });
  const [boards, setBoards] = useState([]);
  const [brokerages, setBrokerages] = useState([]);
  const [agents, setAgents] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [trialStart, setTrialStart] = useState(stripeItem.trial_start);
  const trialEndRef = useRef(stripeItem?.trial_end);
  const resumesAtRef = useRef(stripeItem?.pause_collection?.resumes_at ?? new Date());
  const [cancelCouponConfirm, setCancelCouponConfirm] = useState(false);

  const handleChange = (event) => {
    setChanges({
      ...changes,
      [event.target.name]: event.target.value,
    });
  };

  const handleEdit = async (subId, key, value) => {
    stripeService.updateSubscriptionByField({ input: { "subscriptionId": subId, "key": key, "value": value } }).then(d => {
      setRefreshData((refresh) => !refresh);
    }).catch(err => {
      console.log(err);
    })
  };


  useEffect(() => { console.log(stripeItem) }, [stripeItem])

  const cancelCoupon = (id)=> {
    stripeService.cancelCouponFromSubscription({"input": {"subscriptionId": id}}).then(d => {
      setRefreshData((refresh) => !refresh);
    }).catch(err => {
      console.log(err);
    })
  }

  return (
    <>
      {/* <div className="backdrop"></div>
      <div className="admin-popup">
        <div className="admin-popup-title">
          <h3>Edit</h3>
          <FontAwesomeIcon
            className="admin-popup-close"
            icon={faCircleXmark}
            onClick={onClose}
          />
        </div> */}

        <div className="display-flex flex-direction-column margin-bottom-20-px">
          <div  className="Stripe-Container display-flex flex-direction-column flex-wrap padding-10-px margin-top-20-px">
            <span ><span className="bold ">Status:</span> {stripeItem.status}</span>
            <span ><span className="bold">Collection Method:</span> {stripeItem.collection_method}</span>
            <span ><span className="bold">Start Date:</span> {timeUtility.getTimeFromSeconds(stripeItem.billing_cycle_anchor).toString()}</span>
            <span ><span className="bold">Billing Cylce Anchor:</span> {timeUtility.getTimeFromSeconds(stripeItem.start_date).toString()}</span>
            <span ><span className="bold">Current Period Start:</span> {timeUtility.getTimeFromSeconds(stripeItem.current_period_start).toString()}</span>
            <span ><span className="bold">Current Period End:</span> {timeUtility.getTimeFromSeconds(stripeItem.current_period_end).toString()}</span>

            <span ><span className="bold">Pause Collection:</span> {stripeItem.pause_collection ?
              <div>
                <span><span className="bold">Behaviour:</span> {stripeItem?.pause_collection?.behavior}</span>
                <div className="display-flex">
                  <span className="bold">Resumes At</span>
                  <DatePicker className="Input-Style"
                    onFocus={e => e.target.blur()}
                    selected={resumesAtRef.current ? timeUtility.getTimeFromSeconds(resumesAtRef.current) : new Date()}
                    onChange={(value) => { resumesAtRef.current = value; setRefresh(!refresh); }
                    }
                  />
                  {resumesAtRef.current !== stripeItem?.pause_collection?.resumes_at ?
                    <button className="admin-popup-submit-button-small height-10-px" onClick={() => { handleEdit(stripeItem.id, "pause_collection", { "behavior": "void", "resumes_at": resumesAtRef.current }) }}>
                      Submit
                    </button> : null}
                </div>

              </div>
              : "NA"}
            </span>




            <span ><span className="bold">Trial Start:</span> {stripeItem?.trial_start ? timeUtility.getTimeFromSeconds(stripeItem.trial_start).toString() : "No Trail"}</span>
            <span className=" display-flex">
              <span className="bold">Trial End:</span>
              <DatePicker className="Input-Style"
                onFocus={e => e.target.blur()}
                selected={trialEndRef.current ? timeUtility.getTimeFromSeconds(trialEndRef.current) : new Date()}
                onChange={(value) => { trialEndRef.current = value; setRefresh(!refresh); }
                }
              />
              {trialEndRef.current !== stripeItem?.trial_end ?
                <button className="admin-popup-submit-button-small height-10-px" onClick={() => { handleEdit(stripeItem.id, "trial_end", trialEndRef.current) }}>
                  Submit
                </button> : null
              }
            </span>
          </div>
          <div className="background1-transparent display-flex margin-top-30-px">
            <div style={{backgroundColor: 'lightgrey'}}>
            <span className="bold margin-10-px">Plan Details:</span>
            </div>
            <div style={{padding: '0 20px 10px 0'}} className="display-flex flex-direction-column ">
              <span className="margin-3-0-0-20-px"><span className="bold">Interval:</span> {stripeItem?.plan?.interval}</span>
              <span className="margin-3-0-0-20-px"><span className="bold">Interval Count:</span> {stripeItem?.plan?.interval_count}</span>
              <span className="margin-3-0-0-20-px"><span className="bold">Amount/Price:</span> {stripeItem.plan.amount}{" "}{stripeItem?.plan?.currency}</span>
              <span className="margin-3-0-0-20-px"><span className="bold">Created:</span> {timeUtility.getTimeFromSeconds(stripeItem?.plan?.created)?.toString()}</span>
              <span className="margin-3-0-0-20-px"><span className="bold">Active:</span> {stripeItem?.plan?.active ? "✓ True" : "✕ False"}</span>

            </div>
          </div>


        </div>
        {/* <div className="admin-popup-button-containers">

          <button className="admin-popup-cancel-button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div> */}
    </>
  );
}
