import serverConstants from "../constants/serverConstants";
import axios from "axios";
import { serverResponseErrActions, getUserFromCookie } from "./requestActions";

function getTrendingStickers(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getTrendingStickers`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function saveTemplate(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/saveTemplate`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          console.log(err);
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getMyTemplates(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getMyTemplates`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getStickersByKeyworkd(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/searchStickers`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function sendEcardByEmail(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/mail/sendEcardByEmail`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}


function getMyTemplatesThemes(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getMyTemplatesThemes`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getMyTemplatesThemesUniversal(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getMyTemplatesThemesUniversal`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getMyTemplatesUniversal(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getMyTemplatesUniversal`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function saveTheme(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/saveTheme`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function deleteEcard(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/deleteEcard`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function deleteEcardTheme(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/deleteEcardTheme`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addEcardToEmailQueue(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addEcardToEmailQueue`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getScheduledEcardsForUser(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getScheduledEcardsForUser`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function deleteScheduledEcard(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/deleteScheduledEcard`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function updateEcardRecipients(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/updateEcardRecipients`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function updateEcardDate(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/updateEcardDate`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

const sqlEcardsService = {
  getTrendingStickers,
  saveTemplate,
  getMyTemplates,
  getStickersByKeyworkd,
  sendEcardByEmail,
  getMyTemplatesThemes,
  getMyTemplatesThemesUniversal,
  getMyTemplatesUniversal,
  saveTheme,
  deleteEcard,
  deleteEcardTheme,
  addEcardToEmailQueue,
  getScheduledEcardsForUser,
  deleteScheduledEcard,
  updateEcardRecipients,
  updateEcardDate
  
}

export default sqlEcardsService;