import React, { useEffect, useRef, useState } from "react";
import "./agentNewsletter.css"
import sqlService from "../../../services/sqlService";
import timeUtility from "../../../functions/timeUtility";
import { UserContext } from "../../../App";
import { useContext } from "react";

export default function AgentNewsletter() {

    const [newsletter, setNewsletter] = useState(null);

    const [optout, setOptout] = useState(false);

    const user = useContext(UserContext);

    const loaded = useRef(false)
    useEffect(() => {
        if (!loaded.current) {
            loaded.current = true;
            sqlService.getNewsletterQueueForAgent().then(data => {
                console.log(data);
                if (data && data.length) {
                    setNewsletter(data[0])
                    sqlService.getOptNewsletterStatus({ input: { "newsletter_id": data[0]?.id} }).then(d => {
                        if(d.result === "opt_in"){
                            setOptout(true);
                        }else{
                            setOptout(false);
                        }
                    }).catch(err => {
                        console.log(err)
                    })
                }
            }).catch(err => {
                console.log(err);
            });

      
        }
    })

    const hanldeOptOut = () => {
        sqlService.optAgentNewsletter({ input: { "newsletter_id": newsletter?.id, "optout": !optout } }).then(d => {
            setOptout(!optout);
        }).catch(err => {
            console.log(err)
        })
    }

    const processNewsletter = (newsletter)=> {
        newsletter = newsletter.replaceAll("[#name#]", user?.contact_name ?? " ");
        newsletter = newsletter.replaceAll("[#phone#]", formatPhoneNumber(user?.phone_number) ?? " ");
        newsletter = newsletter.replaceAll("[#brokerage_name#]", user?.brokerage_name ?? " ");
        newsletter = newsletter.replaceAll("[#email#]", user?.email ?? " ");
        newsletter = newsletter.replaceAll("[#website#]", user?.website ?? " ");
        // newsletter = newsletter.replaceAll("[#img#]", user?.img_link ?? " ");
        // newsletter = newsletter.replaceAll("[#logo#]", user?.logo_link ?? " ");

        newsletter = newsletter.replaceAll("<!--[#img#]-->", user?.img_link && user?.img_link !== "" ? `
        <div
        style="background-color: white; padding: 5px; max-height: 160px; max-width: 160px; border-radius: 50%; margin-right: 5px; height: fit-content; width: fit-content; margin-left: 10px; margin-bottom: 10px;">
        <img id="imgAgentLogo"
            src="${user?.img_link}"
            width="150" height="auto" alt="Brokerage"
            style="border: 0; padding: 0; display: block; max-height: 150px; max-width: 150px; border-radius: 50%;" />
        </div>`: "<!--[#img#]-->");
        // newsletter = newsletter.replaceAll("[#logo#]", user?.agent_logo && user?.agent_logo !== "" ? user?.agent_logo : "https://honeyydo.s3.us-east-2.amazonaws.com/other/White-removebg.png");
        newsletter = newsletter.replaceAll("<!--[#logo#]-->", user?.logo_link && user?.logo_link !== "" ? `
        <div
        style="background-color: white; padding: 5px; max-height: 160px; max-width: 160px; border-radius: 50%; margin-right: 5px; height: fit-content; width: fit-content; margin-left: 10px; margin-bottom: 10px;">
        <img id="imgAgentLogo"
            src="${user?.logo_link}"
            width="150" height="auto" alt="Brokerage"
            style="border: 0; padding: 0; display: block; max-height: 150px; max-width: 150px; border-radius: 50%;" />
        </div>` : "<!--[#logo#]-->");
        return newsletter;
    }

    function formatPhoneNumber(number) {
        return '(' + number?.slice(0, 3) + ') ' + number?.slice(3, 6) + '-' + number?.slice(6, 10);
    }

    return (
        <div className="AgentNewsletter-Container">
            {newsletter ?
                <div className="display-flex flex-direction-column column-gap-20-px row-gap-20-px align-items-center">
                    <span className="bold">This newsletter will be sent to your homeowners, you have the option to opt out from this newsletter. If you opt out, this newsletter will not be sent to your homeowners</span>
                    <div className="display-flex flex-wrap column-gap-10-px row-gap-10-px align-items-center">
                        <span className={`bold ${optout ? "Color-Red": "Color-Green"}`}>You are {optout ? " Opted out": " Opted In"}</span>
                        <button className="Opt-Button" onClick={() => { hanldeOptOut() }}>{optout ? "Click to opt in" : "Click to opt out"}</button>
                    </div>
                    <span>{`Newsletter Title: `}<span className="bold">{newsletter.title}</span></span>
                    <span>{`Sending Date: `}<span className="bold">{timeUtility.getTimeFromMysqlDatetime(newsletter.send_date).toDateString()}</span></span>
                    {/* <span>{`Subject: `}<span className="bold">{newsletter.subject}</span></span> */}

                    <div dangerouslySetInnerHTML={{
                        __html: newsletter && newsletter.content ? processNewsletter(newsletter.content) : ""
                    }}></div>
                </div>
                : <span>No Newsletter is in queue for now. Please check back in later</span>}
        </div>
    )
}