import React, { useEffect, useState, useRef } from "react";
import sqlService from "../../../services/sqlService";
import "./library.component.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import ScrollToTop from "../../utility.component/scrollToTop.component";
import { useNavigate, Link } from "react-router-dom";


export default function Library() {
    const Navigate = useNavigate();
    const [refresh, setRefresh] = useState(false);
    const [libraries, setLibraries] = useState([]);
    const [filteredLibraries, setFilteredLibraries] = useState([]);
    const loaded = useRef(false);

    const [windowSize, setWindowSize] = React.useState(window.innerWidth);
    const tags = useRef([]);
    const [tagFilter, setTagFilter] = useState("All")
    const allBlogs = useRef()
    useEffect(() => {
      
        window.addEventListener('resize', onWindowResize);

        return () => {
            window.removeEventListener('resize', onWindowResize);
        };
    }, []);

    const onWindowResize = () => {
       
        setWindowSize(window.innerWidth);
    };

    useEffect(() => {
        if (!loaded.current) {
            sqlService.getLibraries()
                .then(data => {
                    const info = data.data;
                    setLibraries(info.reverse());
                    let t = info.flatMap((post) => post.tags?.split(', '));
                    tags.current = ['All', ...new Set(t)]
 
                })
                .catch(err => {
                    console.log(err);
                });
            loaded.current = true;
        }
    }, [refresh]);

    useEffect(() => {
        const filtered = libraries.filter(item => tagFilter === 'All' || item.tags?.includes(tagFilter))
        setFilteredLibraries(filtered)
    }, [libraries, tagFilter])


    return (
        <div className="Blog-Page">
            <div className="Blog-Hero-Section">
                <h2 className="Blog-Page-Heading">Honeyydo Home Blog</h2>
                <h6 className="Blog-Hero-Text text-smaller color-med-grey">Get inspiration for your next project or learn a new skill-Honeyydo has you covered</h6>
            </div>
            <div className="Featured-Blog-Heading">
                <h4 className="grey">Most Recent Posts</h4>
                <div onClick={()=>(setTagFilter('All'), allBlogs.current.scrollIntoView())} className="cursor-pointer display-flex align-items-center text-decoration-none mid-blue">
                    <h6 className="padding-top-5-px padding-right-5-px">View All</h6>
                    <FontAwesomeIcon icon={faCaretRight} />
                </div>
            </div>
            <div className="Featured-Blog-Container">
                <Link to={`/blog/${libraries[0]?.id}`} className="Featured-Blog">
                    <div className="Blog-Img-Container">
                        <img className="Featured-Blog-Img" src={libraries[0]?.img_link} alt={libraries[0]?.title}></img>
                    </div>
                    <h6 >{libraries[0]?.tags}</h6>
                    <h4 className="grey">{libraries[0]?.title}</h4>
                    <span className="grey">{libraries[0]?.content?.replace(/(<([^>]+)>)/gi, '')?.split(' ')?.splice(0, 50)?.join(' ') + " ..."}</span>
                </Link>
                <Link to={`/blog/${libraries[1]?.id}`} className="text-decoration-none">
                    <div className="Blog-Img-Container">
                        <img className="Blog-Img" src={libraries[1]?.img_link} alt={libraries[1]?.title}></img>
                    </div>
                    <h6 className="Blog-Tags" >{libraries[1]?.tags}</h6>
                    <h6 className="grey">{libraries[1]?.title}</h6>
                    <span className="grey">{libraries[1]?.content?.replace(/(<([^>]+)>)/gi, '')?.split(' ')?.splice(0, 25)?.join(' ') + " ..."}</span>
                </Link>
                <Link to={`/blog/${libraries[2]?.id}`} className="text-decoration-none">
                    <div className="Blog-Img-Container">
                        <img className="Blog-Img" src={libraries[2]?.img_link} alt={libraries[2]?.title}></img>
                    </div>
                    <h6 className="Blog-Tags" >{libraries[2]?.tags}</h6>
                    <h6 className="grey">{libraries[2]?.title}</h6>
                    <span className="grey">{libraries[2]?.content?.replace(/(<([^>]+)>)/gi, '')?.split(' ')?.splice(0, 25)?.join(' ') + " ..."}</span>
                </Link>


            </div>
            <div ref={allBlogs} className="Featured-Blog-Heading">
                <h4 className="grey">Browse by Category</h4>

            </div>
            <div className="Blog-Tags-Container">
                {tags.current.filter(t => t).map((tag, index) => <button key={index} onClick={()=>setTagFilter(tag)} className={`Tag-Button ${tag === tagFilter ? 'Selected-Tag' : ''}`}>{tag}</button>)
                }
            </div>

            <div  className="Featured-Blog-Container">
                {filteredLibraries.map((item, index) => 
                <div key={index}>
                    <Link to={`/blog/${item.id}`} className="text-decoration-none">
                    <div className="Blog-Img-Container">
                        <img className="Blog-Img" src={item.img_link} alt={item.title}></img>
                    </div>
                    <h6 className="Blog-Tags" >{item.tags}</h6>
                    <h6 className="grey">{item.title}</h6>
                </Link>
                </div>)}
            </div>
        </div>
       
    );
}