import serverConstants from "../constants/serverConstants";
import axios from "axios";
import {serverResponseErrActions, getUserFromCookie} from "./requestActions";


function chargeOnePayment(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data?.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/stripe/chargeOnePayment`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getBillingInfo(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data?.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/stripe/getBillingInfo`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addNewCard(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data?.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/stripe/addNewCard`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getCustomerSubscriptions(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data?.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/stripe/getCustomerSubscriptions`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function updatePaymentMethod(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data?.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/stripe/updatePaymentMethod`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}


function getAllSubscriptions(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data?.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/stripe/getAllSubscriptions`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}


function updateSubscriptionByField(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data?.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/stripe/updateSubscriptionByField`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function createCoupon(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data?.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/stripe/createCoupon`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAllCoupons(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data?.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/stripe/getAllCoupons`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function modifyCoupon(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data?.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/stripe/modifyCoupon`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function deleteCoupon(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data?.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/stripe/deleteCoupon`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function cancelCouponFromSubscription(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data?.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/stripe/cancelCouponFromSubscription`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAllInvoices(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data?.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/stripe/getAllInvoices`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}


const stripeService = {
  chargeOnePayment,
  getBillingInfo,
  addNewCard,
  getCustomerSubscriptions,
  updatePaymentMethod,
  getAllSubscriptions,
  updateSubscriptionByField,
  createCoupon,
  getAllCoupons,
  modifyCoupon,
  deleteCoupon,
  cancelCouponFromSubscription,
  getAllInvoices
}

export default stripeService;