import signupConstants from "../constants/signupConstants";
import timeUtility from "./timeUtility";


function isPaidUser(user) {
  var validFreeTrial = false;
  if (user.free_trial === 1) {
    let cDate = timeUtility.getCurrentDateTimeInUtc();
    let freeTrialDate = timeUtility.getTimeFromMysqlDatetime(user?.free_trial_start_date)
    let diffDays = timeUtility.differenceInDays(cDate, freeTrialDate);
    if (diffDays < 7) {
      validFreeTrial = true;
    }
  }
  if ((user.solo_subscribed === 1 && user.subscribed === 1) || (user.solo_subscribed === 1 && user.free_trial === 1 && validFreeTrial)) {
    return true;
  }
  return false;
}

// these routes prevents routing back to dashboard if you are in a different page
const AGENT_USER_URLS = ["/agent-home", "/clients", "/manage-a-pro", "/ecards", "/calendar", "/settings", "/agent-email-service", "/agent-newsletter", "/email-history"];
const HOMEOWNER_USER_URLS = ["/dashboard", "/tasklist", "/filing-cabinet", "/find-a-pro", "/profession", "/my-property", "/settings"];
const Brokerage_USER_URLS = ["/brokerage-home", "/manage-an-agent", "/settings"];
const PRO_USER_URLS = ["/pro-dashboard"];
const ADMIN_USER_URLS = ["/admin-dashboard","/ecards", "/admin-stripe-control", "/admin-associations-control", "/admin", "/admin-email-service"];
const MORTGAGE_AGENT_USER_URLS = ["/mortgage-agent-home", "/clients", "/manage-a-pro", "/ecards", "/calendar", "/settings", "/agent-email-service", "/agent-newsletter", "/email-history"];
const INSURANCE_AGENT_USER_URLS = ["/insurance-agent-home", "/clients", "/manage-a-pro", "/ecards", "/calendar", "/settings", "/agent-email-service", "/agent-newsletter", "/email-history"];

const PUBLIC_URLS  = ["/about", "/faq", "/contact", "/terms-of-service", "/privacy-policy", "/blogs", "/pricing", "/affiliate-program"]

function getUserRoute(user, currentRoute) {

  if (user?.role_id === 1) {
    if (user?.verified === 0) {
      return signupConstants.HOMEOWNER;
    } else {
      if (isPaidUser(user)) {
        if (user?.finished_signup === 0) {
          return signupConstants.HOMEOWNER;
        }
        else {
          if(!HOMEOWNER_USER_URLS.includes(currentRoute) && !currentRoute?.startsWith("/profession") && !PUBLIC_URLS.includes(currentRoute) && !currentRoute?.startsWith("/blog")){
            return "/dashboard";
          }else{
            return "";
          }
        }
      } else if (user?.solo_subscribed === 0) {
        if (user?.finished_signup === 0) {
          return signupConstants.HOMEOWNER;
        }
        else {
          if(!HOMEOWNER_USER_URLS.includes(currentRoute) && !currentRoute?.startsWith("/profession") && !PUBLIC_URLS.includes(currentRoute) && !currentRoute?.startsWith("/blog")){
            return "/dashboard";
          }else{
            return "";
          }
        }
      } else {
        if(!currentRoute?.startsWith("/add-payment")){
          return "/pricing";
        }else{
          return "";
        }
        //user did not pay subscription
      
      }
    }
  } else if (user?.role_id === 2) {
    if (user?.verified === 0) {
      return signupConstants.AGENT;
    } else {
      if (isPaidUser(user)) {
        if (user?.finished_signup === 0 || user?.admin_verified === 0) {
          return signupConstants.AGENT;
        }
        else {
          if(!AGENT_USER_URLS.includes(currentRoute) && !currentRoute?.startsWith("/client") && !PUBLIC_URLS.includes(currentRoute) && !currentRoute?.startsWith("/blog")){
            return "/agent-home";
          }else{
            return "";
          }
        }
      } else if (user?.solo_subscribed === 0) {
        if (user?.finished_signup === 0 || user?.admin_verified === 0) {
          return signupConstants.AGENT;
        }
        else {
          if(!AGENT_USER_URLS.includes(currentRoute) && !currentRoute?.startsWith("/client") && !PUBLIC_URLS.includes(currentRoute) && !currentRoute?.startsWith("/blog")){
            return "/agent-home";
          }else{
            return "";
          }
        }
      } else {
        //user did not pay subscription
        console.log("currentRoute", currentRoute, currentRoute?.startsWith("/add-payment"));
        if(!currentRoute?.startsWith("/add-payment")){
          return "/pricing";
        }else{
          return "";
        }
      }
    }
  } else if (user?.role_id === 3) {
    if (user?.verified === 0) {
      return signupConstants.BROKERAGE;
    } else {
      if (isPaidUser(user)) {
        if (user?.finished_signup === 0 || user?.admin_verified === 0) {
          return signupConstants.BROKERAGE;
        }
        else {
          if(!Brokerage_USER_URLS.includes(currentRoute) && !PUBLIC_URLS.includes(currentRoute) && !currentRoute?.startsWith("/blog")){
            return "/brokerage-home";
          }else{
            return "";
          }
        }
      } else if (user?.solo_subscribed === 0) {
        if (user?.finished_signup === 0 || user?.admin_verified === 0) {
          return signupConstants.BROKERAGE;
        }
        else {
      
          if(!Brokerage_USER_URLS.includes(currentRoute) && !PUBLIC_URLS.includes(currentRoute) && !currentRoute?.startsWith("/blog")){
            return "/brokerage-home";
          }else{
            return "";
          }
        }
      } else {
        //user did not pay subscription
        if(!currentRoute?.startsWith("/add-payment")){
          return "/pricing";
        }else{
          return "";
        }
      }
    }

  }
  else if (user?.role_id === 4) {
    if((user?.plan_type === 2 && user?.admin_verified === 1 && user?.subscribed === 1 && user?.verified === 1) || (user?.plan_type === 1 && user?.admin_verified === 1 && user?.verified === 1)){
      if(!PRO_USER_URLS.includes(currentRoute) && !(currentRoute?.startsWith("/add-payment") && user?.plan_type === 1 ) && !PUBLIC_URLS.includes(currentRoute) && !currentRoute?.startsWith("/blog")){
        return "/pro-dashboard"
      }else{
        return "";
      }
      
    }else if ((user?.plan_type === 2 && user?.admin_verified === 0 && user?.verified === 1 && user?.payment_attached === 1) || (user?.plan_type === 1 && user?.admin_verified === 0 && user?.verified === 1)){
      return "/pro_pending"
    }else if ((user?.plan_type === 2 && user?.subscribed === 0 && user?.payment_attached === 0 && user?.verified === 1)){
      return "/add-payment/pro/monthly"
    }
    else if (user?.verified === 0){
      return "/pro_verify"
    }else {
      return "";
    }
  }
  else if (user?.role_id === 0) {
    if(!ADMIN_USER_URLS.includes(currentRoute) && !currentRoute?.startsWith("/admin") && !PUBLIC_URLS.includes(currentRoute) && !currentRoute?.startsWith("/blog")){
      return "/admin-dashboard"
    }else{
      return "";
    }
  }

  else if (user?.role_id === 5) {
    if (user?.verified === 0) {
      return signupConstants.MORTGAGE_AGENT;
    } else {
      if (isPaidUser(user)) {
        if (user?.finished_signup === 0 || user?.admin_verified === 0) {
          return signupConstants.MORTGAGE_AGENT;
        }
        else {
          if(!MORTGAGE_AGENT_USER_URLS.includes(currentRoute) && !PUBLIC_URLS.includes(currentRoute) && !currentRoute?.startsWith("/blog")){
            return "/mortgage-agent-home";
          }else{
            return "";
          }
        }
      } else if (user?.solo_subscribed === 0) {
        if (user?.finished_signup === 0 || user?.admin_verified === 0) {
          return signupConstants.MORTGAGE_AGENT;
        }
        else {
          if(!MORTGAGE_AGENT_USER_URLS.includes(currentRoute) && !PUBLIC_URLS.includes(currentRoute) && !currentRoute?.startsWith("/blog")){
            return "/mortgage-agent-home";
          }else{
            return "";
          }
        }
      } else {
        //user did not pay subscription
        console.log("currentRoute", currentRoute, currentRoute?.startsWith("/add-payment"));
        if(!currentRoute?.startsWith("/add-payment")){
          return "/pricing";
        }else{
          return "";
        }
      }
    }
  }

  else if (user?.role_id === 6) {
    if (user?.verified === 0) {
      return signupConstants.INSURANCE_AGENT;
    } else {
      if (isPaidUser(user)) {
        if (user?.finished_signup === 0 || user?.admin_verified === 0) {
          return signupConstants.INSURANCE_AGENT;
        }
        else {
          if(!INSURANCE_AGENT_USER_URLS.includes(currentRoute) && !PUBLIC_URLS.includes(currentRoute) && !currentRoute?.startsWith("/blog")){
            return "/insurance-agent-home";
          }else{
            return "";
          }
        }
      } else if (user?.solo_subscribed === 0) {
        if (user?.finished_signup === 0 || user?.admin_verified === 0) {
          return signupConstants.INSURANCE_AGENT;
        }
        else {
          if(!INSURANCE_AGENT_USER_URLS.includes(currentRoute) && !PUBLIC_URLS.includes(currentRoute) && !currentRoute?.startsWith("/blog")){
            return "/insurance-agent-home";
          }else{
            return "";
          }
        }
      } else {
        //user did not pay subscription
        console.log("currentRoute", currentRoute, currentRoute?.startsWith("/add-payment"));
        if(!currentRoute?.startsWith("/add-payment")){
          return "/pricing";
        }else{
          return "";
        }
      }
    }
  }
}

const userUtility = {
  isPaidUser,
  getUserRoute
}

export default userUtility;