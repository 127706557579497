import React, { useState, useEffect, useContext, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import sqlService from "../../../services/sqlService";
import { UserContext } from "../../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faShare } from "@fortawesome/free-solid-svg-icons";

function InviteButton({ buttonText, topMargin, header }) {

    const user = useContext(UserContext);
    const [windowSize, setWindowSize] = React.useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', onWindowResize);
        return () => {
            window.removeEventListener('resize', onWindowResize);
        };
    }, []);

    const onWindowResize = () => {
        setWindowSize(window.innerWidth);
    }
    // const [inviteLink, setInviteLink] = useState('');
    const [inviteLink, setInviteLink] = useState("Invite link")
    const [copyMessage, setCopyMessage] = useState('');
    const [inviteModal, setInviteModal] = useState(false);
    const handleClose = () => {
        setInviteModal(false);
        setCopyMessage('');
        // setInviteLink('');
        setInviteLinkText("Check out Honeyydo, the all-in-one home-management service");

    };

    const [canGenerateLink, setCanGenerateLink] = useState(true);
    const [timeWhenYouCantGenerateLink, setTimeWhenYouCantGenerateLink] = useState(null);



    const Ref = useRef(null);
    // The state for our timer
    const [timer, setTimer] = useState('0');

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    }

    const startTimer = (e) => {
        let { total, hours, minutes, seconds }
            = getTimeRemaining(e);
        if (total >= 0) {

            // update the timer
            // check if less than 10 then we need to
            // add '0' at the beginning of the variable
            setTimer(
                //    (hours > 9 ? hours : '0' + hours) + ':' +
                //    (minutes > 9 ? minutes : '0' + minutes) + ':'
                //    +
                seconds
            )
        }
    }


    const clearTimer = (e) => {

        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next
        setTimer('10');

        // If you try to remove this line the
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }


    // useEffect(() => {
    //     clearTimer(getDeadTime());
    // }, []);

    const getDeadTime = () => {
        let deadline = new Date();

        // This is where you need to adjust if
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + 10);
        return deadline;
    }

    const resetTimer = () => {
        clearTimer(getDeadTime());
    }

    const [referral, setReferral] = useState("")

    function generateLink(n) {
        setCanGenerateLink(false)
        resetTimer();
        setTimeout(() => { setCanGenerateLink(true) }, 10000);
        sqlService
            .addReferral({ user: user })
            .then((data) => {
                const referral = data.data;
                setReferral(referral)
                if (n) {
                    setInviteLink(`${process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_BASE_DOMAIN : process.env.REACT_APP_ENV === "staging" ? process.env.REACT_APP_BASE_DOMAIN_STAGING : process.env.REACT_APP_BASE_DOMAIN_LOCAL}signup/${referral}`)

                } else {
                    setInviteLink(`${process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_BASE_DOMAIN : process.env.REACT_APP_ENV === "staging" ? process.env.REACT_APP_BASE_DOMAIN_STAGING : process.env.REACT_APP_BASE_DOMAIN_LOCAL}signup/${referral}`)
                    //setInviteLink(inviteLink + '\n\nhttps://www.honeyydo.com/' + referral)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // Called by "copy invitation link" button
    function copyLink() {
        var copyText = document.getElementById("inviteLink");
        copyText.select();
        copyText.setSelectionRange(0, 99999);

        navigator.clipboard.writeText(copyText.value);

        setCopyMessage("Copied to clipboard!");
        setTimeout(() => {
            setCopyMessage();
        }, 3000);
    }

    // Holds data for share invitation link function
    const [inviteLinkText, setInviteLinkText] = useState("Check out Honeyydo, the all-in-one home-management service");
    const [inviteLinkTitle, setInviteLinkTitle] = useState("You're Invited to Join Honeyydo");



    async function share() {
        const url = inviteLink[0]+`/signup/${referral}`;
        try {
            console.log(inviteLink, inviteLinkText, inviteLinkTitle)
            await navigator.share({ "text": inviteLinkText, "title": inviteLinkTitle, "url": url});
        } catch (err) {
            setCopyMessage(`Error: ${err}`);
        }

    }

    return (
        <>

            <button style={{marginTop: topMargin, padding: header ? '0 10px' : '', height: header ? '35px' : ""}} className="popup-submit-button display-block margin-auto-lr"
                onClick={() => {
                    setInviteModal(true);
                    generateLink();
                }}
                disabled={!canGenerateLink}
            >
                {buttonText} {timer.toString() !== "0" ? `in ${timer}` : ""}
            </button>

            <Modal
                show={inviteModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}


            >
                <Modal.Header closeButton>
                    <Modal.Title className="grey">
                        {"Send an Invitation"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-align-center">


                    <div className={`display-flex width-full-pt margin-top-20-px margin-bottom-20-px ${windowSize > 450 ? 'margin-left-50-px' : 'flex-direction-column'}`} >

                        <div className="display-flex flex-direction-column align-items-center">
                            <span style={{ marginBottom: '15px' }}>Each link is valid for one user</span>
                            <textarea
                                style={{ height: '150px', width: '250px' }}
                                type="text"
                                value={inviteLinkText}
                                className="Input-Style resize-none"
                                onChange={(value) => setInviteLinkText(value.currentTarget.value)}

                            ></textarea>
                            <textarea
                                style={{ height: '100px', width: '250px' }}
                                type="text"
                                id="inviteLink"
                                value={inviteLink}
                                className="Input-Style resize-none"
                                // onChange={(value) => setInviteLink(value.currentTarget.value)}
                                disabled

                            ></textarea>
                            {canGenerateLink ?
                                <a className=" padding-top-15-px text-decoration-none grey hover-dark-blue cursor-pointer" onClick={() => generateLink(1)}>
                                    Generate a new link
                                </a> :
                                <span className="padding-top-15-px">{`You can generate a new link in ${timer}`}</span>
                            }

                            <div className="display-flex justify-content-center width-full-pt height-30-px" >
                                <p className="padding-top-15-px">{copyMessage}</p>
                            </div>

                            {/* <span>This link will only be valid for a single individule</span> */}

                        </div>
                        <div style={windowSize > 450 ? { marginTop: '40px' } : { marginTop: '-10px' }} className={`height-fit-content width-fit-content display-flex flex-direction-column margin-left-10-px`}>


                            <div className="cursor-pointer display-flex flex-dierction-column height-fit-content width-fit-content column-gap-5-px row-gap-5-px" onClick={copyLink}>
                                <span className="height-fit-content width-fit-content">Copy</span>
                                <span>
                                    <FontAwesomeIcon
                                        className="orange fa-2x"
                                        icon={faCopy}
                                    />
                                </span>


                            </div>

                            <div className="cursor-pointer display-flex flex-dierction-column height-fit-content width-fit-content column-gap-5-px row-gap-5-px" onClick={share}>

                            <span className="height-fit-content width-fit-content">Share</span>
                                    <span>
                                        <FontAwesomeIcon

                                            className={`orange fa-2x`}
                                            icon={faShare}
                                        />
                                    </span>

                            </div>


                        </div>


                    </div>



                </Modal.Body>
            </Modal>
        </>
    )

}

export default InviteButton;