function sortByName(value, array, ascending) {
    if (ascending) {
        
    
        // var sorted = [...array].sort((a, b) =>
        //     a[value].split(" ")[1] + a[value].split(" ")[0] > b[value].split(" ")[1] + a[value].split(" ")[0]
        //         ? 1
        //         : -1
        // );

        var sorted = [...array].sort((a, b) =>
                a[value]?.split(" ")[1]?.toLowerCase() > b[value]?.split(" ")[1]?.toLowerCase()
                    ? 1
                    : a[value]?.split(" ")[1]?.toLowerCase() < b[value]?.split(" ")[1]?.toLowerCase()
                        ? -1
                        : a[value].toLowerCase() > b[value].toLowerCase()
                            ? 1
                            : -1
            );
    
    } else {
     
        // var sorted = [...array].sort((a, b) =>
        //     a[value].split(" ")[1] + a[value].split(" ")[0] < b[value].split(" ")[1] + a[value].split(" ")[0]
        //         ? 1
        //         : -1
        // );

        var sorted = [...array].sort((a, b) =>
                a[value]?.split(" ")[1]?.toLowerCase() < b[value]?.split(" ")[1]?.toLowerCase()
                    ? 1
                    : a[value]?.split(" ")[1]?.toLowerCase() > b[value]?.split(" ")[1]?.toLowerCase()
                        ? -1
                        : a[value].toLowerCase() < b[value].toLowerCase()
                            ? 1
                            : -1
            );
           
    }
   
    return sorted;
}

function sort(value, array, ascending) {

    if (ascending) {
        var sorted = [...array].sort((a, b) =>
            a[value] > b[value] ? 1 : -1
        );

    } else {
      
        var sorted = [...array].sort((a, b) =>
            a[value] <= b[value] ? 1 : -1
        );
    }
    
    return sorted;
 

}

const sortUtility = { sortByName, sort }

export default sortUtility;