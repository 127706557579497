import React, { useEffect, useRef, useState } from "react"
import stripeService from "../../../services/stripeService";
import "./agentEmailService.css";
import sqlService from "../../../services/sqlService";
import { Modal, ModalBody, Button } from "react-bootstrap";
import searchUtility from "../../../functions/searchUtility";
import { AiOutlineSearch } from "react-icons/ai";
import { faCaretRight, faEllipsisVertical, faEnvelope, faPlus, faPaperclip, faX, faEdit, faEye, faCircleXmark, faImage, faAlignLeft, faPenFancy, faPenNib, faFont, faRepeat, faArrowLeftLong, faTrash, faXmark, faPaperPlane, faArrowRight, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../../App";
import { useContext } from "react";
import DatePicker from "react-datepicker";
import { Spinner } from "react-bootstrap";

import validateUtility from "../../../functions/validateUtility";
import inputConstants from "../../../constants/inputConstants";
import PopupMessage from "../../utility.component/popupMessage.component/popupMessage.component";
import sqlEcardsService from "../../../services/sqlEcardService";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { useLocation, Link } from "react-router-dom";

export default function AgentEmailService() {
    const { state } = useLocation()
    const selected = state?.selected;
    const [hint, setHint] = useState('')
    const user = useContext(UserContext);
    const [selectedView, setSelectedView] = useState("notSelected");
    const [editEnabled, setEditEnabled] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [selectedSignatureTemplate, setSelectedSignatureTemplate] = useState(null);
    const initialTemplateContent = useRef("");
    const loaded = useRef(false);
    const [newTitle, setNewTitle] = useState("");
    const [templateContent, setTemplateContent] = useState("");
    const [signatureTemplateContent, setSignatureTemplateContent] = useState("");

    const [welcomeEmailContent, setWelcomeEmailContent] = useState("");

    const [searchKeywordFilter, setSearchKeywordFilter] = useState("");
    // const [minDiscountPercentageFilter, setMinDiscountPercentageFilter] = useState(0);
    // const [maxDiscountPercentageFilter, setMaxDiscountPercentageFilter] = useState(100);
    const [selectedDurationFilter, setSelectedDurationFilter] = useState("Homeowners");


    const [selectedUsers, setSelectedUsers] = useState({});
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [sendConfirmation, setSendConfirmation] = useState(false);

    const [allPros, setAllPros] = useState([]);
    const [allHomeowners, setAllHomeowners] = useState([]);
    const [allAgents, setAllAgents] = useState([]);
    const [allInsuranceAgents, setAllInsuranceAgents] = useState([]);
    const [allMortgageAgents, setAllMortgageAgents] = useState([]);
    const [allBrokerages, setAllBrokerages] = useState([]);

    const [filteredUsersFromSelected, setFilteredUsersFromSelected] = useState([]);
    const [filteredBySearchUsers, setFilteredBySearchUsers] = useState([])

    const [minimumCreationDate, setMinimumCreationDate] = useState(null);
    const [maximumCreationDate, setMaximumCreationDate] = useState(null);

    const [displayMinimumCreationDatePicker, setDisplayMinimumCreationDatePicker] = useState(false);
    const [displayMaximumCreationDatePicker, setDisplayMaximumCreationDatePicker] = useState(false);

    const [minimumCreationFilteredUsers, setMinimumCreationFilteredUsers] = useState([]);
    const [maximumCreationFilteredUsers, setMaximumCreationFilteredUsers] = useState([]);

    const [sendingIsOn, setSendingIsOn] = useState(false);

    const [emailSubject, setEmailSubject] = useState("");
    const [emailCategory, setEmailCategory] = useState("");
    const [emailSubCategory, setEmailSubCategory] = useState("");

    const emailCategories = { "ESSENTIAL": ["ESSENTIAL"], "AGENT_EMAIL": ["AGENT_EMAIL"], "REMINDERS": ["EVENT_REMINDERS"], "PROMOTIONS": ["PROMOTIONS"] };

    const [emailRecords, setEmailRecords] = useState([]);

    const [minimumCreationDate1, setMinimumCreationDate1] = useState(null);
    const [maximumCreationDate1, setMaximumCreationDate1] = useState(null);

    const [displayMinimumCreationDatePicker1, setDisplayMinimumCreationDatePicker1] = useState(false);
    const [displayMaximumCreationDatePicker1, setDisplayMaximumCreationDatePicker1] = useState(false);


    const [searchKeywordFilter1, setSearchKeywordFilter1] = useState("");

    const [filteredBySearchRecords, setFilteredBySearchRecords] = useState([]);
    const [minimumCreationFilteredRecords, setMinimumCreationFilteredRecords] = useState([]);
    const [maximumCreationFilteredRecords, setMaximumCreationFilteredRecords] = useState([]);

    const [selectedDurationFilter1, setSelectedDurationFilter1] = useState("Homeowners");
    const [filteredByRecipientRoleIdRecords, setFilteredByRecipientRoleIdRecords] = useState([]);
    const [filteredByCategoryRecords, setFilteredByCategoryRecords] = useState([]);
    const [filteredBySubCategoryRecords, setFilteredBySubCategoryRecords] = useState([]);

    const [categoryFilter, setCategoryFilter] = useState("Agent_Email");
    const [subCategoryFilter, setSubCategoryFilter] = useState("Agent_Email");

    const [selectedViewEmail, setSelectedViewEmail] = useState(null);

    const [emailTemplates, setEmailTemplates] = useState([]);
    const [signatureTemplates, setSignatureTemplates] = useState([]);

    const [emailTemplateItems, setEmailTemplateItems] = useState({});

    const [customEmail, setCustomEmail] = useState("");

    const [signatureModeIsOn, setSignatureModeIsOn] = useState(false);

    const [selectedEcards, setSelectedEcards] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);

    const [allEcards, setAllEcards] = useState([]);
    const [welcomeEmailEdit, setWelcomeEmailEdit] = useState(false);
    const [windowSize, setWindowSize] = React.useState(window.innerWidth);

    const bannerImgs = ['https://honeyydo.s3.us-east-2.amazonaws.com/other/EmailBrandingBackground/House1-min.jpg',
        'https://honeyydo.s3.us-east-2.amazonaws.com/other/EmailBrandingBackground/House2-min.jpg',
        'https://honeyydo.s3.us-east-2.amazonaws.com/other/EmailBrandingBackground/House3-min.jpg',
        'https://honeyydo.s3.us-east-2.amazonaws.com/other/EmailBrandingBackground/House4-min.jpg',
        'https://honeyydo.s3.us-east-2.amazonaws.com/other/EmailBrandingBackground/House5-min.jpg'
    ]

    const banner = useRef({})

    useEffect(() => {

        banner.current = bannerImgs[Math.floor(Math.random() * bannerImgs?.length)]

    }, [])

    useEffect(() => {
        window.addEventListener("resize", onWindowResize);

        return () => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, []);


    const onWindowResize = () => {
        setWindowSize(window.innerWidth);

    };


    const [welcomeEmailObject, setWelcomeEmailObject] = useState({
        "image": null,
        "allow_sending": true,
        "header": "",
        "p1": "Hi {{homeowner-name}}, you have been invited to Honeyydo by {{my-name}}",
        "p2": "Honeyydo is an all-in-one home-management service that offers customizable task management, a management system for your appliance warranties and manuals, and connects you to verified home maintenance professionals in your area.",
        "p3": "Finish setting up your account to take advantage of better organization and productivity for your home.",
        "p4": "",
        "p5": ""
    })

    const resetWelcomeEmail = () => {
        setWelcomeEmailObject({
            "image": { ...welcomeEmailObject.image, "old": welcomeEmailObject.image?.data, "data": null, "new": null },
            "allow_sending": true,
            "header": "",
            "p1": "Hi {{homeowner-name}}, you have been invited to Honeyydo by {{my-name}}",
            "p2": "Honeyydo is an all-in-one home-management service that offers customizable task management, a management system for your appliance warranties and manuals, and connects you to verified home maintenance professionals in your area.",
            "p3": "Finish setting up your account to take advantage of better organization and productivity for your home.",
            "p4": "",
            "p5": ""
        });


    }

    const emailImg = useRef()

    const handleUpload = () => {
        emailImg.current.click();

    }


    const uploadImg = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        if (!validateUtility.validFileSize(file)) {
            setServerMessage({ "message": `File size must be less than ${inputConstants.MAX_FILE_SIZE / 1000000}mb`, "title": "Error" })
            return;
        }

        reader.onload = async () => {
            let data = reader.result;
            let t = file.type;
            let type = t.split("/")[1]

            var dd = { "type": type };
            // if (welcomeEmailObject?.image?.data) {
            //     dd["old"] = welcomeEmailObject?.image?.data;
            // }
            // dd["new"] = data;
            // setWelcomeEmailObject({ ...welcomeEmailObject, "image": dd });
            const link = await handleUploadToAws(data, t)
            // setSelectedImages([...selectedImages, link])
            addImage(link)



        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };


    const uploadAgentWelcomEmailImg = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        if (!validateUtility.validFileSize(file)) {
            setServerMessage({ "message": `File size must be less than ${inputConstants.MAX_FILE_SIZE / 1000000}mb`, "title": "Error" })
            return;
        }

        reader.onload = async () => {
            let data = reader.result;
            let t = file.type;
            let type = t.split("/")[1]

            var dd = { "type": type };
            if (welcomeEmailObject?.image?.data) {
                dd["old"] = welcomeEmailObject?.image?.data;
            }
            dd["new"] = data;
            setWelcomeEmailObject({ ...welcomeEmailObject, "image": dd });

        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleUploadToAws = async (file, type) => {
        return new Promise((resolve, reject) => {
            let fileObject = { "imgFile": file, "imgType": type, "imgSubfolder": "client/agent/agent_email_imgs" }
            sqlService.uploadImgAws({ input: fileObject }).then(lnk => {
                lnk = lnk?.data?.link;
                console.log(lnk)
                resolve(lnk);
            }).catch(err => {
                console.log(err);
                reject(err);
            })
        })

    }

    const handleDeleteImg = (link) => {
        return new Promise((resolve, reject) => {
            sqlService.deleteAwsImage({ input: { "link": link, "imgSubfolder": "client/agent/agent_email_imgs" } }).then(d => {
                console.log(d)
                resolve(d);
            }).catch(err => {
                console.log(err);
                reject(err);
            })
        })
    }

    useEffect(() => {
        if (!loaded.current) {
            loaded.current = true;
            getTemplates();
            getSignatureTemplates();
            getEmailRecords();
            getEcards();
            getAgentWelcomeEmail();
        }


    }, []);


    const getEcards = () => {
        sqlEcardsService.getMyTemplates({ "user": user }).then(data => {
            console.log(data);
            var ecards = data;
            sqlEcardsService.getMyTemplatesUniversal({ "user": user }).then(data1 => {
                console.log(data1);

                ecards = [...ecards, ...data1]
                setAllEcards(ecards);
            }).catch(err => {
                console.log(err);
            })

            ecards = data;
        }).catch(err => {
            console.log(err)
        });
    }

    const getAgentWelcomeEmail = () => {
        sqlService.getAgentWelcomeEmail({ "user": user }).then(data => {
            console.log(data);
            if (data?.data) {
                var d = data?.data;
                if (d?.image) {
                    d["image"] = { "data": d?.image }
                }
                setWelcomeEmailObject(d)
            } else {
                resetWelcomeEmail();
            }
        }).catch(err => {
            console.log(err);
            resetWelcomeEmail();
        });
    }


    const getTemplates = () => {
        sqlService.getEmailTemplatesByUserId().then(d => {
            console.log(d);
            setEmailTemplates(d?.data)
        }).catch(err => {
            console.log(err);
        })
    }

    const getSignatureTemplates = () => {
        sqlService.getSignatureTemplatesByUserId().then(d => {
            console.log(d);
            setSignatureTemplates(d?.data)
        }).catch(err => {
            console.log(err);
        })
    }

    const getEmailRecords = () => {
        sqlService.getEmailRecordsByUserId().then(d => {
            console.log(d);
            setEmailRecords(d?.data)
        }).catch(err => {
            console.log(err);
        })
    }

    const variableFiller = {
        "name": "John Doe",
        "email": "emailfake@fake.come",
        "phone": "123-123-3212",
        "address": "21 fake street Drive",
        "city": "New York",
        "country": "United States",
        "website": "fakewebsite.com"
    }

    const processHtml = (html) => {

        var htmlCpy = html;
        htmlCpy = htmlCpy?.replaceAll("{{contact_name}}", "John Doe");
        htmlCpy = htmlCpy?.replaceAll("{{name}}", "John Doe");
        htmlCpy = htmlCpy?.replaceAll("{{email}}", "emailfake@fake.come");
        htmlCpy = htmlCpy?.replaceAll("{{phone_number}}", "123-123-3212");
        htmlCpy = htmlCpy?.replaceAll("{{phone}}", "123-123-3212");
        htmlCpy = htmlCpy?.replaceAll("{{address}}", "21 fake street Drive");
        htmlCpy = htmlCpy?.replaceAll("{{city}}", "New York");
        htmlCpy = htmlCpy?.replaceAll("{{country}}", "United States");
        htmlCpy = htmlCpy?.replaceAll("{{website}}", "fakewebsite.com");
        return htmlCpy;
    }

    function spliceSlice(str, index, count, add) {
        // We cannot pass negative indexes directly to the 2nd slicing operation.
        if (index < 0) {
            index = str.length + index;
            if (index < 0) {
                index = 0;
            }
        }

        return str.slice(0, index) + (add || "") + str.slice(index + count);
    }

    const processSignature = (sign) => {
        var sigg = ""
        for (let it of sign.split(/\n/)) {
            sigg += `<p style="font-weight: bold; max-width:500px;">${it}</p>`
        }
        return sigg;
    }


    const processTemplate = (sign) => {
        var sigg = ""
        for (let it of sign.split(/\n/)) {
            sigg += `<p>${it}</p>`
        }
        return sigg;
    }

    const LOGIN_LINK = "https://honeydo.com/#/login";

    function formatPhoneNumber(number) {
        return '(' + number?.slice(0, 3) + ') ' + number?.slice(3, 6) + '-' + number?.slice(6, 10);
    }

    const emailTemplateWrapper = (endMessage, html) => {
        return `
        
    <!DOCTYPE html>

    <html>
    <head>
    
      <meta charset="utf-8">
      <meta http-equiv="x-ua-compatible" content="ie=edge">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
      <title>Email Receipt</title>
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <style type="text/css">
      /**
       * Google webfonts. Recommended to include the .woff version for cross-client compatibility.
       */
      @media screen {
        @font-face {
          font-family: 'Source Sans Pro';
          font-style: normal;
          font-weight: 400;
          src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/ODelI1aHBYDBqgeIAH2zlBM0YzuT7MdOe03otPbuUS0.woff) format('woff');
        }
    
        @font-face {
          font-family: 'Source Sans Pro';
          font-style: normal;
          font-weight: 700;
          src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/toadOcfmlt9b38dHJxOBGFkQc6VGVFSmCnC_l7QZG60.woff) format('woff');
        }
      }
    
      /**
       * Avoid browser level font resizing.
       * 1. Windows Mobile
       * 2. iOS / OSX
       */
      body,
      table,
      td,
      a {
        -ms-text-size-adjust: 100%; /* 1 */
        -webkit-text-size-adjust: 100%; /* 2 */
      }
    
      /**
       * Remove extra space added to tables and cells in Outlook.
       */
      table,
      td {
        mso-table-rspace: 0pt;
        mso-table-lspace: 0pt;
      }
    
      /**
       * Better fluid images in Internet Explorer.
       */
      img {
        -ms-interpolation-mode: bicubic;
      }
    
      /**
       * Remove blue links for iOS devices.
       */
      a[x-apple-data-detectors] {
        font-family: inherit !important;
        font-size: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
        color: inherit !important;
        text-decoration: none !important;
      }
    
      /**
       * Fix centering issues in Android 4.4.
       */
      div[style*="margin: 16px 0;"] {
        margin: 0 !important;
      }
    
      body {
        width: 100% !important;
        height: 100% !important;
        padding: 0 !important;
        margin: 0 !important;
      }
    
      /**
       * Collapse table borders to avoid space between cells.
       */
      table {
        border-collapse: collapse !important;
      }
    
      a {
        color: #1a82e2;
      }
    
      img {
        height: auto;
        line-height: 100%;
        text-decoration: none;
        border: 0;
        outline: none;
      }
      </style>
    
    </head>
    <body style="background-color: #f8a025;">


      <!-- start body -->
      <table border="0" cellpadding="0" cellspacing="0" width="100%">
      
      
       <!-- start logo -->
      <tr >
        <td align="left" bgcolor="#f7f3f2"  style="background-image: url(${banner.current}); background-size: cover; background-position: center;" >
            <div style="width: 100%; min-width: ${windowSize > 700 ? '40vw' : '60vw'}; margin-bottom: 40px; background-color:rgb(62,59,60, .6); padding: 5px 15px;">
                <h5 style="color: #e2e2e2; font-style: italic;">Brought to you by </h5>
                <h4 style="color: white"> ${user?.contact_name}</h4>
            </div>
     <div style="padding: 15px;">
        <table>
            <tr>
                
                ${user?.img_link ?
              `
                <td>
                    <div style=" overflow: hidden; border-radius: 50%; width: 100px; height: 100px; border: solid 3px white;">
                        <img  src="${user?.img_link}" width="100%" height="100%" style="object-fit: cover; height: 100%"; width: 100%;"/>
                    </div>
                </td>`
                : '' }
                ${user?.logo_link ?
              `
                <td valign="bottom">
                    <div style="margin-left: ${user?.img_link ? '-35px' : '0px'}; overflow: hidden; border-radius: 999px; position: relative; z-index: 10;  width: 50px; height: 50px;">
                        <img  src="${user?.logo_link}" width="100%" height="100%" style="object-fit: cover; height: 100%"; width: 100%;"/>
                    </div>
                </td>`
                : '' }
            </tr>
        </table>
        </div>
       
      

          <!--[if (gte mso 9)|(IE)]>
          
          <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%">
          <tr>
          <td align="center" valign="top" width="100%"  >
          <![endif]-->
      
      

      
 

          <!--[if (gte mso 9)|(IE)]>
          </td>
          </tr>
          </table>
          <![endif]-->
          </td>
        </tr>
        <tr>






            <td align="left" bgcolor="#f7f3f2" style="padding: 24px;">
                <div style="font-family: Arial, sans-serif; color: #333; line-height: 1.5; width: 100%; padding: 15px 0">

                    
                
               ${processHtml(document.getElementById('editor')?.innerHTML)}



                </div>
            </td>
        </tr> 
          



      
    
    
        <!-- start footer -->
        <tr>
          <td align="left" bgcolor="#f0eae4" style="padding: 24px;">

            <!--[if (gte mso 9)|(IE)]>
            <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
            <tr>
            <td align="center" valign="top" width="600">
            <![endif]-->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
    
              <!-- start permission -->
      
              <!-- end permission -->
    
              <div style="font-family: Arial, sans-serif; margin-top: 20px;">
              <p style="font-size:14px">Honeyydo</p>

              <a href="https://honeyydo.com" target="_blank" rel="noreferrer" style="display: inline-block;">
              <img src="https://honeyydo.s3.us-east-2.amazonaws.com/other/Honeyydo-logo-no-text.png" alt="Honeyydo Logo" width="50px" height="50px">
             </a>
     
              
              </div>
              <div style="margin: 20px 0;">
              <p style="font-size:14px">Want to stop receiving these emails? </p>
              <a style="font-size:14px" href="">Click here to unsubscribe</a>

              </div>
    
            </table>
            <!--[if (gte mso 9)|(IE)]>
            </td>
            </tr>
            </table>
            <![endif]-->
          </td>
        </tr>
        <!-- end footer -->
    
      </table>
      <!-- end body -->
    
    </body>
    </html> 
    
        
        `

    }

    const proccessTemplateWelcomeEmail = (temp) => {
        temp = temp.replaceAll("{{homeowner-name}}", "John Doe");
        temp = temp.replaceAll("{{my-name}}", user.contact_name);
        return temp;
    }


    const [attachment, setAttachment] = useState([])
    const attachmentFile = useRef(null)
    const [serverMessage, setServerMessage] = useState(null);

    const handleAttachmentUpload = () => {
        attachmentFile.current.click();

    }

    function removeAttachment(index) {
        let updated = [...attachment]
        updated.splice(index, 1)
        console.log(updated)

        setAttachment(updated)

    }

    const getAttachmentsSizs = (att) => {
        var size = 0;
        for (let a of att) {
            size += a?.size;
        }
        return size;
    }

    const handleAttachmentChange = (event) => {


        const file = event.target.files[0];
        const reader = new FileReader();

        console.log(file?.size)
        if (!validateUtility.validFileSize(file)) {
            setServerMessage({ "message": `File size must be less than ${inputConstants.MAX_FILE_SIZE / 1000000}mb`, "title": "Error" })
            return;
        }
        if ((getAttachmentsSizs(attachment) + file?.size) > inputConstants.MAX_FILE_SIZE) {
            setServerMessage({ "message": `Attachments size must be less than ${inputConstants.MAX_FILE_SIZE / 1000000}mb and your attachements size is will be ${(getAttachmentsSizs(attachment) + file?.size) / 1000000}mb with this file`, "title": "Error" })
            return;
        }

        reader.onload = () => {
            if (!file?.size) {
                setServerMessage({ "message": `File is invalid`, "title": "Error" })
                return
            }

            let data = reader.result;

            let t = file.type;

            let type = t.split("/")[1];

            let updated = [...attachment]
            updated.push({ name: file.name, type: type, data: data, size: file?.size });


            setAttachment(updated);


        };

        if (file) {
            reader.readAsDataURL(file);
        }

    }


    const handleCusomEmail = () => {
        if (customEmail !== "" && !selectedUsers[customEmail]) {
            let exist = maximumCreationFilteredUsers.filter(item => item.email === customEmail);
            var obj = { "email": customEmail };
            if (exist.length) {
                obj = exist[0]
            }
            setSelectedUsers({ ...selectedUsers, [customEmail]: obj });
            setCustomEmail("");
        }
    }



    function saveSignature() {
        console.log(document.getElementById('signature').innerHTML)
        sqlService.saveEmailTemplate({ "input": { signatureMode: true, template: { 'content': document.getElementById('signature').innerHTML, 'title': signatureTitle ?? 'Signature' } } }).then(d => {
            setSignatureTitle('')
            setSignaturePopup(false)
            getSignatureTemplates();

        }).catch(err => {
            console.log(err);
        })


    }


    const saveTemplate = async (isNew) => {
        // if (welcomeEmailEdit) {
        //     var copyOfEmailObject = { ...welcomeEmailObject };
        //     if (welcomeEmailObject?.image?.new) {
        //         await handleUploadToAws(welcomeEmailObject?.image?.new, welcomeEmailObject?.image?.type).then(d => {
        //             copyOfEmailObject = { ...copyOfEmailObject, "image": { ...copyOfEmailObject.image, "data": d, "new": null } }
        //         }).catch(err => {
        //             console.log(err);
        //         });
        //     }
        //     if (welcomeEmailObject?.image?.old) {
        //         await handleDeleteImg(welcomeEmailObject?.image?.old).then(d => {
        //             copyOfEmailObject = { ...copyOfEmailObject, "image": { ...copyOfEmailObject.image, "old": null } }
        //         }).catch(err => {
        //             console.log(err);
        //         });
        //     }
        //     sqlService.updateAgentWelcomeEmail({ "input": { emailObject: copyOfEmailObject } }).then(d => {
        //         console.log("ddd is", d);
        //         setServerMessage({ "message": `Template is saved`, "title": "Success" })
        //     }).catch(err => {
        //         console.log(err);
        //     })
        // } else {
        //     if (isNew || (signatureModeIsOn && signatureTemplateContent !== selectedSignatureTemplate?.content) || (!signatureModeIsOn && templateContent !== selectedTemplate?.content) || (!signatureModeIsOn && newTitle !== selectedTemplate.title) || (signatureModeIsOn && newTitle !== selectedSignatureTemplate.title)) {
        //         var newTemp = signatureModeIsOn ? { ...selectedSignatureTemplate } : { ...selectedTemplate }
        //         if (isNew) {
        //             delete newTemp["id"];
        //         }

        //         if (!signatureModeIsOn) {
        //             newTemp["content"] = templateContent;
        //             newTemp["title"] = newTitle;
        //             newTemp["subject"] = emailSubject;
        //             newTemp["category"] = "AGENT_EMAIL";
        //             newTemp["sub_category"] = "AGENT_EMAIL";
        //             selectedTemplate["title"] = newTitle;
        //             selectedTemplate["content"] = templateContent;
        //             selectedTemplate["subject"] = emailSubject;
        //             selectedTemplate["category"] = "AGENT_EMAIL";
        //             selectedTemplate["sub_category"] = "AGENT_EMAIL";
        //         } else {
        //             newTemp["content"] = signatureTemplateContent;
        //             newTemp["title"] = newTitle;
        //             selectedSignatureTemplate["content"] = signatureTemplateContent;
        //             selectedSignatureTemplate["title"] = newTitle;
        //         }

        sqlService.saveEmailTemplate({ "input": { template: { 'content': document.getElementById('editor').innerHTML, 'subject': emailSubject, 'title': newTitle } } }).then(d => {


            getTemplates();
            setFinishedPopup('template')

        }).catch(err => {
            console.log(err);
        })


    }




    const [savePopup, setSavePopup] = useState(false)
    const [signaturePopup, setSignaturePopup] = useState(false)
    const [signatureTitle, setSignatureTitle] = useState(null)
    const [deletePopup, setDeletePopup] = useState(false)
    const [deleteSignaturePopup, setDeleteSignaturePopup] = useState(false)
    const clientEmails = useRef([])
    const [schedulePopup, setSchedulePopup] = useState(false)
    const [finishedPopup, setFinishedPopup] = useState(false)
    const [sendDate, setSendDate] = useState(new Date())


    const signatureStyle = '<div style="color: grey; margin-top: 20px" class="signature">'

    function getContent() {
        return document.getElementById('editor').innerHTML?.split(signatureStyle)
    }

    function addImage(item) {
        var temp = getContent()

        temp[0] += `<img src=${item} width="75%" style="display: block; margin: auto" />`

        if (temp[1]) {
            setContent(temp[0] + signatureStyle + temp[1])
        } else {
            setContent(temp[0])
        }

    }

    function addSignature(item) {
        var temp = getContent()[0]

        temp += `${signatureStyle}<p>--</p>${item?.content}</div>`

        setContent(temp)

    }

    function addVariable(item) {
        var temp = getContent()

        temp[0] += `<span>{{${item}}}</span>`

        if (temp[1]) {
            setContent(temp[0] + signatureStyle + temp[1])
        } else {
            setContent(temp[0])
        }

    }

    function deleteTemplate() {
        sqlService.deleteEmailTemplate({ input: deletePopup }).then(d => {
            setDeletePopup(false)
            getTemplates()

        }).catch(err => {


        })
    }

    function deleteEmailSignature() {
        sqlService.deleteEmailSignature({ input: deleteSignaturePopup }).then(d => {
            setDeleteSignaturePopup(false)
            getSignatureTemplates()

        }).catch(err => {

        })
    }


    useEffect(() => {
        switch (selectedDurationFilter) {
            case "Homeowners":
                setFilteredUsers(allHomeowners)
                break;
            default:
                setFilteredUsers([...allHomeowners])
                break;
        }
    }, [allHomeowners, selectedDurationFilter])

    useEffect(() => {
        setFilteredUsersFromSelected(filteredUsers.filter(item => !selectedUsers[item.email]))
    }, [selectedUsers, filteredUsers])

    useEffect(() => {
        if (searchKeywordFilter !== "") {
            let filteredBySearch = searchUtility.filterBySearchArrayOfObjects(filteredUsersFromSelected, searchKeywordFilter)
            setFilteredBySearchUsers(filteredBySearch)

        } else {
            setFilteredBySearchUsers(filteredUsersFromSelected);
        }
    }, [searchKeywordFilter, filteredUsersFromSelected]);

    useEffect(() => {
        if (minimumCreationDate) {
            var pass = []
            let min = new Date(minimumCreationDate)
            for (let item of filteredBySearchUsers) {
                let dat = new Date(item?.creation_date);
                if (dat >= min) {
                    pass.push(item);
                }
            }
            setMinimumCreationFilteredUsers(pass);
        }
        else {
            setMinimumCreationFilteredUsers(filteredBySearchUsers)
        }
    }, [filteredBySearchUsers, minimumCreationDate])

    useEffect(() => {
        if (maximumCreationDate) {
            var pass = []
            let max = new Date(maximumCreationDate)
            for (let item of minimumCreationFilteredUsers) {
                let dat = new Date(item?.creation_date);
                if (dat <= max) {
                    pass.push(item);
                }
            }
            setMaximumCreationFilteredUsers(pass);
        }
        else {
            setMaximumCreationFilteredUsers(minimumCreationFilteredUsers)
        }
    }, [minimumCreationFilteredUsers, maximumCreationDate])

    useEffect(() => {


        console.log("getting all types of users");
        sqlService
            .getHomeownersByAgent()
            .then((d) => {
                console.log(d)
                setAllHomeowners(d?.data ?? []);

                clientEmails.current = d?.data?.map(item => item.email)
                console.log(clientEmails.current)
            })
            .catch((err) => {
                console.log(err)
            });

    }, [])

    const resetFilters = () => {
        setMaximumCreationDate(null);
        setMinimumCreationDate(null);
        setSearchKeywordFilter("");
        setSelectedDurationFilter("Homeowners");
    }

    const resetFilters1 = () => {
        setMaximumCreationDate1(null);
        setMinimumCreationDate1(null);
        setSearchKeywordFilter1("");
        setSelectedDurationFilter1("Homeowners");
        setCategoryFilter("Agent_Email");
        setSubCategoryFilter("Agent_Email");
    }

    const handleSelectAll = () => {
        var select = {};
        for (let item of maximumCreationFilteredUsers) {
            select[item?.email] = item;
        };
        setSelectedUsers(select);
    }

    const handleSendingEmail = () => {
        if (Object.keys(selectedUsers)?.length === 0) {
            setServerMessage({title: "Oops", message: 'Please select at least one recipient'})

            return;
        }
       
        setSendingIsOn(true);

        var inp = { subject: emailSubject, banner: banner.current, template: document.getElementById('editor').innerHTML, recipients: selectedUsers, attachment: attachment, category: 'AGENT_EMAIL', sub_category: 'AGENT_EMAIL' };
        if (selectedSignatureTemplate) {
            inp["signature"] = signatureTemplateContent;
        }
        // if (selectedEcards) {
        //     inp["ecard"] = selectedEcards?.img_link;
        // }
        sqlService.sendEmailsToSelectedUsersUsingTemplateId({ input: inp }).then(data => {
            setSendingIsOn(false);
            setFinishedPopup(Object.keys(selectedUsers)?.length)
            setSelectedUsers({});
            setAttachment([]);
            getEmailRecords();
            setSearchKeywordFilter('')


        }).catch(err => {
            console.log(err);
            setSendingIsOn(false);
        })
    }

    useEffect(() => {
        if (searchKeywordFilter1 !== "") {
            let filteredBySearch = searchUtility.filterBySearchArrayOfObjects(emailRecords, searchKeywordFilter1)
            setFilteredBySearchRecords(filteredBySearch)

        } else {
            setFilteredBySearchRecords(emailRecords);
        }
    }, [searchKeywordFilter1, emailRecords]);

    useEffect(() => {
        if (minimumCreationDate1) {
            var pass = []
            let min = new Date(minimumCreationDate1)
            for (let item of filteredBySearchRecords) {
                let dat = new Date(item?.creation_time);
                if (dat >= min) {
                    pass.push(item);
                }
            }
            setMinimumCreationFilteredRecords(pass);
        }
        else {
            setMinimumCreationFilteredRecords(filteredBySearchRecords)
        }
    }, [filteredBySearchRecords, minimumCreationDate1])

    useEffect(() => {
        if (maximumCreationDate1) {
            var pass = []
            let max = new Date(maximumCreationDate1)
            for (let item of minimumCreationFilteredRecords) {
                let dat = new Date(item?.creation_time);
                if (dat <= max) {
                    pass.push(item);
                }
            }
            setMaximumCreationFilteredRecords(pass);
        }
        else {
            setMaximumCreationFilteredRecords(minimumCreationFilteredRecords)
        }
    }, [minimumCreationFilteredRecords, maximumCreationDate1]);

    const [panel, setPanel] = useState('initial')
    const [content, setContent] = useState('')
    const [closeDropdown, setCloseDropdown] = useState(true)
    const [maxSelected, setMaxSelected] = useState(10)



    useEffect(() => {

        const handler = (e) => {

            if (document.getElementById('clickbox').contains(e.target)) {
                setCloseDropdown(false)
            } else {
                setCloseDropdown(true)
            }
        };
        window.addEventListener("click", handler);
        return () => {
            window.removeEventListener("click", handler);
        };
    }, []);

    useEffect(() => {
        if (selected) {
            var select = {};
            for (let item of selected) {
                select[item?.email] = item;
            };
            setSelectedUsers(select)

        }
    }, [])

    function filteredTimes(time) {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();


    }

    function scheduleEmail() {

        // if (Object.keys(selectedUsers)?.length === 0) {
        //     setServerMessage({title: "Oops", message: 'Please select at least one recipient'})

        //     return;
        // }


        sqlService.addEmailToUserEmailQueue({ user: user, "input": { subject: emailSubject ?? "No Subject", message: document.getElementById('editor').innerHTML, attachment: attachment, recipients: selectedUsers, date: sendDate } })
            .then((data) => {
                setSchedulePopup(false)

                setFinishedPopup('scheduled')

            })
            .catch((err) => {
                console.log(err);
            });




    }


    return (
        <>
            <div className="Ecard-Page">
                <input className='display-none' type="file" id="honeyydo-ecard-imageUpload-hidden" accept="image/*" onChange={(e) => { handleUploadToAws(e) }} />
                <h2 className="Blog-Page-Heading">Honeyydo Email Service</h2>
                <h6 style={{ marginBottom: "5vh" }} className="Blog-Hero-Text color-med-grey">Manage all your email blasts conveniently in one place. Create customizable templates for emails you send frequently.</h6>
                <div style={{ paddingBottom: "5vh" }} className="Email-Grid flex-wrap margin-top-30-px ">
                    <input value={emailSubject} onChange={(val) => setEmailSubject(val.currentTarget.value)} className="Input-Style Email-Subject" placeholder="Subject"></input>
                    <div id='editor' contentEditable="true" dangerouslySetInnerHTML={{ __html: content }} className="Input-Style Email-Message" placeholder="Message">


                    </div>
                    <div className="Email-Panel-Position position-relative">
                        {panel === 'initial' || windowSize < 800 ?
                            <div className="Email-Button-Panel Email-Panel "  >
                                <div onClick={() => setPanel('templates')}>
                                    <FontAwesomeIcon icon={faAlignLeft} className="Button-Icon" />
                                    {windowSize > 900 ?
                                        'Select Template' : ''}
                                </div>
                                <div onClick={() => handleUpload()}>
                                    <input className="display-none" type="file" ref={emailImg} accept="image/*" onChange={(e) => uploadImg(e)} />

                                    <FontAwesomeIcon icon={faImage} className="Button-Icon" />
                                    {windowSize > 900 ?
                                        'Insert an Image' : ''}
                                </div>
                                <div onClick={() => setPanel('ecards')}>
                                    <FontAwesomeIcon icon={faEnvelope} className="Button-Icon" />
                                    {windowSize > 900 ?
                                        'Insert an Ecard' : ''}
                                </div>
                                <div onClick={() => setPanel('variables')} className="position-relative">
                                    {hint ?
                                        <div style={{ backgroundColor: 'rgb(105, 105, 105, .7)', color: 'white', bottom: '100%', left: '105%' }} className="variable-hint position-absolute">{hint}</div>
                                        : null}
                                    <FontAwesomeIcon icon={faRepeat} className="Button-Icon" />
                                    {windowSize > 900 ?
                                        <>
                                            Insert a Variable
                                            <FontAwesomeIcon icon={faQuestionCircle} onMouseEnter={() => setHint("To fully customize your email, you have the option of including variables in your template. These can include things such as the recipient's name or address. Simply insert the desired phrase and in each email sent it will be replaced with the recipient's unique information.")} onMouseLeave={() => setHint('')} className="margin-left-10-px variable-question" />
                                        </> : null}
                                </div>

                                <div onClick={() => setPanel('signatures')}>
                                    <FontAwesomeIcon icon={faPenNib} className="Button-Icon" />
                                    {windowSize > 900 ?
                                        'Select Signature' : ''}
                                </div>

                            </div> : null
                        }
                        {panel === 'templates' ?
                            <div className=" options-dropdown">
                                <h6 style={{ color: '#919191' }}>Select a Template</h6>
                                <div className="Email-Panel-Inner Email-Template-Panel margin-top-15-px display-flex flex-direction-column row-gap-15-px">
                                    {

                                        emailTemplates?.map((item, index) =>
                                            <div className="display-flex justify-content-space-between column-gap-10-px position-relative" key={index}
                                                onClick={() => { setContent(item?.content); setEmailSubject(item.subject) }}>

                                                <span>{item.title}</span>
                                                <FontAwesomeIcon onClick={() => setDeletePopup(item.id)} className="fa-sm hover-red" icon={faTrash} />
                                                {/* {deletePopup === item.id ?
                                            <div style={{height: 'unset'}} className="notification-popup ">
                                                <span>hi</span>

                                            </div> : null} */}
                                            </div>
                                        )
                                    }
                                    {!emailTemplates?.length ?
                                        <span className="bold grey">You haven't added any templates yet. Start drafting an email and click "Save as Template" to create your first one.</span>
                                        : null}
                                </div>
                                <div className="display-flex column-gap-10-px justify-content-start">

                                    <button style={{ minWidth: '75px' }} onClick={() => setPanel('initial')} className="Cancel-Button margin-top-10-px ">
                                        {windowSize > 800 ?
                                            <>
                                                <FontAwesomeIcon className="margin-right-5-px" icon={faArrowLeftLong} />
                                                Back
                                            </> : 'Close'}</button>


                                </div>

                            </div>
                            : panel === 'variables' ?
                                <div className=" options-dropdown">
                                    <h6 style={{ color: '#919191' }}>Insert a Variable</h6>
                                    <div className=" Email-Panel-Inner Email-Template-Panel margin-top-15-px display-flex flex-direction-column row-gap-15-px">
                                        <span>Insert the desired variable and in each email sent it will be replaced with the recipient's unique information.
                                        </span>
                                        <h6 style={{ color: '#919191' }}>Available Variables:</h6>
                                        <div onClick={() => addVariable('name')} className="display-flex justify-content-space-between column-gap-10-px position-relative">
                                            Name
                                        </div>
                                        <div onClick={() => addVariable('address')} className="display-flex justify-content-space-between column-gap-10-px position-relative">
                                            Address
                                        </div>
                                        <div onClick={() => addVariable('city')} className="display-flex justify-content-space-between column-gap-10-px position-relative">
                                            City
                                        </div>
                                        <div onClick={() => addVariable('email')} className="display-flex justify-content-space-between column-gap-10-px position-relative">
                                            Email
                                        </div>

                                    </div>
                                    <div className="display-flex column-gap-10-px justify-content-start">
                                        <button style={{ minWidth: '75px' }} onClick={() => setPanel('initial')} className="Cancel-Button margin-top-10-px ">
                                            {windowSize > 800 ?
                                                <>
                                                    <FontAwesomeIcon className="margin-right-5-px" icon={faArrowLeftLong} />
                                                    Back
                                                </> : 'Close'}</button>

                                    </div>

                                </div>
                                : panel === 'ecards' ?
                                    <div className="Related-Container options-dropdown">

                                        {allEcards?.length ?
                                            <>
                                                <h6 style={{ color: '#919191' }}>Select an Ecard</h6>

                                                <div className="Email-Panel-Inner">

                                                    {allEcards?.map((item, index) =>



                                                        <div className={`Ecard-Preview-Container margin-top-15-px cursor-pointer`} onClick={() => { addImage(item.img_link) }}>
                                                            <img style={{ width: '90%' }} className="" src={item.img_link} alt={item.title}></img>
                                                        </div>





                                                    )}
                                                </div>
                                                <div className="display-flex column-gap-10-px justify-content-start">
                                                    <button style={{ minWidth: '75px' }} onClick={() => setPanel('initial')} className="Cancel-Button margin-top-10-px ">
                                                        {windowSize > 800 ?
                                                            <>
                                                                <FontAwesomeIcon className="margin-right-5-px" icon={faArrowLeftLong} />
                                                                Back
                                                            </> : 'Close'}</button>
                                                </div>
                                            </>
                                            :
                                            null
                                        }


                                    </div>
                                    : panel === 'signatures' ?
                                        <div className="options-dropdown ">
                                            <h6 style={{ color: '#919191' }}>Select a Signature</h6>
                                            <div className="Email-Panel-Inner Email-Template-Panel margin-top-15-px display-flex flex-direction-column row-gap-15-px">
                                                {
                                                    signatureTemplates?.map((item, index) =>
                                                        <div className="display-flex justify-content-space-between column-gap-10-px position-relative" key={index} onClick={() => { addSignature(item) }}  >

                                                            <span >{item.title}</span>
                                                            <FontAwesomeIcon onClick={() => setDeleteSignaturePopup(item.id)} className="fa-sm hover-red" icon={faTrash} />

                                                        </div>
                                                    )
                                                }
                                                {!signatureTemplates?.length ?
                                                    <span className="bold grey">You haven't added any signatures yet. Click on "New" to create your first one.</span>
                                                    : null}
                                            </div>
                                            <div className="display-flex column-gap-10-px justify-content-start position-relative">
                                                <button style={{ minWidth: '75px' }} onClick={() => setPanel('initial')} className="Cancel-Button margin-top-10-px ">
                                                    {windowSize > 800 ?
                                                        <>
                                                            <FontAwesomeIcon className="margin-right-5-px" icon={faArrowLeftLong} />
                                                            Back
                                                        </> : 'Close'}</button>
                                                <button style={{ padding: '10px', minWidth: '75px' }} onClick={() => setSignaturePopup(true)} className="popup-submit-button margin-top-10-px ">
                                                    <FontAwesomeIcon className="margin-right-5-px" icon={faPlus} />
                                                    New</button>
                                                {signaturePopup ?
                                                    <div className="notification-popup diffuse-shadow row-gap-15-px">
                                                        <h5 className="white">New Signature</h5>
                                                        <input className="Input-Style" value={signatureTitle} onChange={(val) => setSignatureTitle(val.currentTarget.value)} placeholder="Title"></input>
                                                        <div style={{ height: '150px', overflow: 'auto' }} id='signature' className="Input-Style" contentEditable={true}></div>
                                                        <div className="display-flex column-gap-15-px justify-content-end">
                                                            <button onClick={() => { setSignaturePopup(false); setSignatureTitle(null) }} className="Cancel-Button">Cancel</button>
                                                            <button onClick={() => saveSignature()} className="popup-submit-button">Save</button>
                                                        </div>
                                                    </div> : null}
                                            </div>
                                        </div>
                                        : null
                        }
                    </div>

                    <div className="Selection-Options" >
                        <div className="Selection-Options-Inner display-flex column-gap-10-px ">
                            <h6 className="grey">Send to:</h6>
                            <div className="margin-bottom-10-px ">
                                <span onClick={() => { handleSelectAll(); }} className="bold grey background-color-light-grey padding-5-px cursor-pointer border-radius-5-px text-align-center width-fit-content">Select All</span>
                                <span onClick={() => { setSelectedUsers({}); setMaxSelected(7) }} className="bold grey margin-left-5-px background-color-light-grey padding-5-px cursor-pointer border-radius-5-px text-align-center width-fit-content">Clear Selected</span>
                                <input style={{ marginLeft: windowSize < 800 ? '0' : '', marginTop: windowSize < 800 ? '10px' : '' }} className="Input-Style margin-left-5-px " onChange={(e) => { setCustomEmail(e.currentTarget.value); }} value={customEmail} placeholder="Custom Email"></input>
                                <button style={{ padding: '4px 8px' }} onClick={() => { handleCusomEmail() }} className="popup-submit-button margin-left-5-px "><FontAwesomeIcon icon={faPlus} /></button>

                            </div>
                        </div>
                        <div id='clickbox' className={`card-input__input Settings-Input-Theme position-relative`}>
                            <div style={{ overflow: 'auto', scrollbarWidth: 'thin' }} className="display-flex flex-wrap column-gap-10-px row-gap-10-px overflow-x-hidden max-height-100-px">
                                {Object.keys(selectedUsers)?.splice(0, maxSelected).map((item, index) =>
                                    <div style={{ padding: '3px 6px' }} className="position-relative column-gap-10-px display-flex align-items-center background-color-light-blue border-radius-5-px">
                                        <span >{selectedUsers[item].contact_name ?? selectedUsers[item].email}</span>
                                        <FontAwesomeIcon className="cursor-pointer grey" icon={faXmark}
                                            onClick={() => {
                                                let copy = { ...selectedUsers }
                                                delete copy[item];
                                                setSelectedUsers(copy);
                                            }} />


                                    </div>

                                )}
                                {Object.keys(selectedUsers)?.length > maxSelected ?
                                    <div onClick={() => setMaxSelected(Object.keys(selectedUsers)?.length)} style={{ padding: '3px 6px' }} className="cursor-pointer position-relative column-gap-10-px display-flex align-items-center background-color-light-blue border-radius-5-px">
                                        <span ><FontAwesomeIcon icon={faPlus} className="grey margin-right-5-px" /> {Object.keys(selectedUsers)?.length - maxSelected}</span>



                                    </div> : null
                                }
                            </div>

                            <div className="Search-Bar-Focus position-relative  height-fit-content border-radius-5-px">
                                <span
                                    className="position-absolute left-8-px top-4-px purple border-radius-5-px"
                                >
                                    <AiOutlineSearch />
                                </span>
                                <input
                                    className="Search-Bar"
                                    type="text"
                                    value={searchKeywordFilter}
                                    onChange={(e) => { setSearchKeywordFilter(e.currentTarget.value) }}
                                    placeholder={"Search Users"}
                                    style={{ width: '100%', border: 'none' }}
                                    onClick={()=>setFilteredBySearchUsers(maximumCreationFilteredUsers)}
                                />
                            </div>
                            {!closeDropdown && maximumCreationFilteredUsers?.length ?
                                <div style={{ width: '90%', padding: '5px 10px', background: 'white', top: '100%', maxHeight: '264px', zIndex: '100', overflow: 'auto', scrollbarWidth: 'thin' }} className="position-absolute box-shadow-2 border-radius-5-px">
                                    {
                                        maximumCreationFilteredUsers?.map((item, index) =>
                                            <div style={{ padding: '3px 0' }} className="cursor-pointer display-flex flex-wrap column-gap-10-px hover-background-blue" onClick={() => { setSelectedUsers({ ...selectedUsers, [item.email]: item }) }}>
                                                <span className="bold grey">{item.contact_name}</span>
                                                <span>{item.email}</span>
                                            </div>
                                        )
                                    }
                                </div>
                                : null} 
                        </div>

                    </div>
                    <div className="Attachments">
                        {!sendConfirmation ?
                            <div style={{ padding: windowSize > 900 ? '10px 20px' : '5px 10px', alignSelf: 'start' }} onClick={(e) => handleAttachmentUpload(e)} className="grey width-fit-content column-gap-5-px display-flex justify-content-center align-items-center bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px">
                                <span >Attach</span>
                                <FontAwesomeIcon
                                    className="grey"
                                    transform={{ rotate: -45 }}
                                    icon={faPaperclip}
                                />
                            </div>
                            : null}
                        <input
                            className='display-none'
                            type="file"
                            ref={attachmentFile}

                            onChange={(e) => { handleAttachmentChange(e) }}
                        />
                        {attachment !== null ?
                            <div style={{ marginTop: windowSize > 900 ? '10px' : '', }} className="display-flex flex-direction-column">
                                {attachment.map((file, index) =>
                                    <div className="display-flex margin-top-5-px">

                                        <span style={{ marginTop: '-5px' }} className="padding-left-10-px ">{file.name}</span>
                                        <FontAwesomeIcon icon={faX} style={{ marginTop: '2px' }} className="grey padding-left-10-px fa-xs cursor-pointer" onClick={() => removeAttachment(index)} />
                                    </div>
                                )}
                            </div> : null}
                    </div>

                    <div className="Email-Save-Options">
                        <button style={{ padding: windowSize < 900 ? '5px 10px' : '' }} onClick={() => setSelectedViewEmail(true)} className="popup-submit-button">Preview</button>
                        <button style={{ padding: windowSize < 900 ? '5px 10px' : '' }} onClick={() => setSavePopup(true)} className="popup-submit-button">Save{windowSize > 900 ? ' as Template' : ''}</button>
                        <button style={{ padding: windowSize < 900 ? '5px 10px' : '' }} onClick={() => {if (Object.keys(selectedUsers)?.length > 0) {setSchedulePopup(true)} else {setServerMessage({title: "Oops", message: 'Please select at least one recipient'})}}} className="popup-submit-button">Schedule</button>
                        <button style={{ padding: windowSize < 900 ? '5px 10px' : '' }} onClick={() => handleSendingEmail()} className="popup-submit-button">Send</button>
                    </div>





                </div>




            </div>
            {/* <div className="display-flex justify-content-center flex-wrap">

                <div style={{minWidth: '800px'}}  dangerouslySetInnerHTML={{
                    __html: emailTemplateWrapper("", welcomeEmailEdit ? "" : templateContent)
                }}></div>
            </div> */}


            {deletePopup ?
                <>
                    <div className="backdrop"></div>
                    <div className="popup">
                        <h5 className="grey">Are you sure you want to delete?</h5>
                        <div className="display-flex column-gap-10-px margin-top-20-px">
                            <button className="Cancel-Button" onClick={() => setDeletePopup(false)}>
                                Cancel
                            </button>
                            <button className="popup-delete-button" onClick={() => deleteTemplate()}>
                                Delete
                            </button>
                        </div>
                    </div>
                </> : null}
            {deleteSignaturePopup ?
                <>
                    <div className="backdrop"></div>
                    <div className="popup">
                        <h5 className="grey">Are you sure you want to delete?</h5>
                        <div className="display-flex column-gap-10-px margin-top-20-px">
                            <button className="Cancel-Button" onClick={() => setDeleteSignaturePopup(false)}>
                                Cancel
                            </button>
                            <button className="popup-delete-button" onClick={() => deleteEmailSignature()}>
                                Delete
                            </button>
                        </div>
                    </div>
                </> : null}


            {savePopup ?
                <>

                    <div className="backdrop"></div>
                    <div className="popup row-gap-20-px">
                        {/* <div className="card-input">
                            <label  className="card-input__label">
                                Theme
                            </label>
                            <input className="card-input__input" value={templateThemeTitle} onChange={(e) => { setTemplateThemeTitle(e.currentTarget.value); }}></input>
                        </div> */}
                        {/* {saveAsTriggered ? */}
                        <h5 className="grey">Save Template</h5>
                        <input placeholder="Title" className="card-input__input" onChange={(e) => { setNewTitle(e.currentTarget.value); }}></input>

                        <div className="display-flex column-gap-10-px">
                            {/* <div className="card-input">
                                    <label className="card-input__label">
                                        Title
                                    </label> */}
                            {/* </div> */}
                            <button className="popup-submit-button" onClick={() => { saveTemplate(); setSavePopup(false) }}>
                                Save
                            </button>
                            <button className="Cancel-Button" onClick={() => setSavePopup(false)}>
                                Cancel
                            </button>
                        </div>
                        {/* :
                            <div className="display-flex column-gap-10-px">
                                <button className="Cancel-Button" onClick={() => (setSavePopup(false))}>
                                    Cancel
                                </button>
                                <button className="popup-submit-button" onClick={() => { setSaveAsTriggered(true); }}>
                                    Save
                                </button>
                                {(templateId) ?
                                    <button className="popup-submit-button" onClick={() => { saveTemplate(true, true); setTemplateModeEditing(false); setStartNewTheme(false); setSavePopup(false) }}>
                                        Overwrite
                                    </button> : null
                                }

                            </div>
                        } */}


                    </div>
                </>
                : null}

            {schedulePopup ?
                <>


                    <div style={{ zIndex: '1999' }} className="backdrop"></div>
                    <div style={{ zIndex: '2000', width: '400px', height: '450px' }} className="popup">
                        <div className="display-flex flex-direction-column flex-wrap">
                            <label className="card-input__label">
                                Select Date to Send
                            </label>
                            <div style={{ minHeight: '300px', paddingTop: '15px' }}>
                                <DatePicker className="Input-Style"
                                    minDate={new Date()}
                                    selected={sendDate}
                                    onChange={(value) => { setSendDate(value) }}
                                    inline
                                    showTimeSelect
                                    filterTime={filteredTimes}


                                />
                            </div>

                        </div>

                        <div className="display-flex column-gap-10-px">
                            <button className="Cancel-Button" onClick={() => (setSchedulePopup(false), setSendDate(null))}>
                                Cancel
                            </button>
                            <button className="popup-submit-button" onClick={() => (scheduleEmail())}>
                                Save
                            </button>
                        </div>
                    </div>
                </>
                : null}

            <Modal
                show={selectedViewEmail}
                onHide={() => setSelectedViewEmail(null)}
                keyboard={false}
                size="lg"
                focus={true}
            >
                <ModalBody >

                    <div className="display-flex justify-content-center flex-wrap">

                        <div dangerouslySetInnerHTML={{
                            __html: emailTemplateWrapper()
                        }}></div>
                    </div>

                </ModalBody>
                <Modal.Footer >

                    <Button variant="secondary height-40-px" onClick={() => setSelectedViewEmail(null)}>
                        Close
                    </Button>



                </Modal.Footer>

            </Modal>
            <PopupMessage onConfirm={() => { setServerMessage(null) }} message={serverMessage?.message} title={serverMessage?.title} show={serverMessage !== null} />

            {
                finishedPopup ?
                    <>
                        <div className="backdrop"></div>
                        <div style={{ zIndex: '2000', minWidth: '350px', height: '250px' }} className="popup">
                            {finishedPopup === 'scheduled' ?
                                <>
                                    <h4 className="grey">Scheduled for {new Date(sendDate).toLocaleString('default', { month: 'short', day: 'numeric' })} </h4>
                                    <FontAwesomeIcon className="grey fa-lg" icon={faPaperPlane} />
                                </>
                                : finishedPopup === 'template' ? <h4 className="grey">Template Saved</h4>
                                    : <>
                                        <h4 className="grey">Sent to {finishedPopup} user{finishedPopup > 1 ? 's' : ''}</h4>

                                        <FontAwesomeIcon className="grey fa-lg" icon={faPaperPlane} />
                                    </>}
                            {finishedPopup === 'scheduled' ?
                                <Link className="text-decoration-none margin-top-30-px" to={"/calendar"}>View scheduled emails <FontAwesomeIcon className="grey" icon={faArrowRight} /></Link>
                                : finishedPopup === 'template' ?
                                    null :
                                    <Link className="text-decoration-none margin-top-30-px" to={"/email-history"}>View email history <FontAwesomeIcon className="grey" icon={faArrowRight} /></Link>
                            }
                            <button className="Cancel-Button margin-top-30-px" onClick={() => (setFinishedPopup(false))}>
                                Close
                            </button>
                        </div>
                    </>

                    : null
            }




        </>
    )



}