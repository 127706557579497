import React, {useState, useRef, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../../App";
import sqlService from "../../../services/sqlService";
import { Button, Spinner } from "react-bootstrap";
export default function ProVerify() {
    const user = useContext(UserContext);
    const [authCodeStatus, setAuthCodeStatus] = useState("");
    const [authCode, setAuthCode] = useState("");
    const loaded = useRef(false);
    const loaded1 = useRef(false);
    const userRef = useRef(user);
    const allowRender = useRef(user);
    const [verified, setVerified] = useState(user?.verified ?? false);
    const [verifyButtonClicked, setVerifyButtonClicked] = useState(false);
    const [sendButtonClicked, setSendButtonClicked] = useState(false);

    const Navigate = useNavigate();
    useEffect(() => {
        console.log(user)
        if (!loaded1.current || (user && userRef.current && JSON.stringify(userRef.current) !== JSON.stringify(user))) {
            userRef.current = user;
            if (!user || !user.role_id === 4 || user?.verified === 1) {
                Navigate("/");
            }

            if (user?.token && user?.verified === 0) {
                sqlService.getAuthCodeStatus({}).then(data => {
                    console.log(data)
                    if (data?.status === "ready") {
                        setAuthCodeStatus("ready");
                    } else if (data?.status === "expired") {
                        setAuthCodeStatus("expired");
                    } else {
                        console.log("code is null")
                        setAuthCodeStatus("null");
                    }
                }).catch(err => {
                    console.log("errrrrrrrrrrrrr", err)
                    setAuthCodeStatus("null");
                })
            } else {
                setAuthCodeStatus("null");
            }
            loaded1.current = true;
        }
    }, [user])

    const verifyUser = () => {
        console.log(authCode, "sdfsd")
        setVerifyButtonClicked(true);
        sqlService.verifyUser({ input: { "authCode": authCode } }).then(data => {
            console.log(data)
            if (data?.data === "expired") {
                setAuthCodeStatus("expired");
            } else {
                loaded.current = false;
                console.log("token", user.token);
                data["token"] = user.token;
                setVerified(true);
                setAuthCodeStatus("null");
                document.cookie = `honeyydo_user_cookie=${JSON.stringify({ data })}; expires=${new Date(Date.now() + (30 * 86400000))}; path=/; sameSite=strict; secure`;
                allowRender.current = false;
                window.location.reload();
            }
            setVerifyButtonClicked(false);

        }).catch(err => {
            console.log(err)
            loaded.current = false;
            setVerifyButtonClicked(false);

        })
    }

    const sendNewCode = () => {
        setSendButtonClicked(true);
        sqlService.sendNewCode({}).then(data => {
            setAuthCodeStatus("ready");
            setSendButtonClicked(false);
        }).catch(err => {
            console.log(err)
            setSendButtonClicked(false);
        })
    }

    return (
        <>
            {
                user?.verified === 0 && authCodeStatus === "ready" ?
                    <div className="Signup-Border Verify-Border margin-top-50-px" >
                        <div className="align-items-center display-flex flex-direction-column margin-top-40-px column-gap-10-px row-gap-10-px">
                            <span>Verify the code sent to your email: <span className="bold">{user?.email}</span></span>
                            <input type="tel" className="Input-Style" value={authCode} onChange={(e) => setAuthCode(e.currentTarget.value)} />
                            {verifyButtonClicked ?
                                <Button className="submit" variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span className=""> Loading...</span>
                                </Button> :
                                <button className="submit" onClick={() => { verifyUser() }}>Verify</button>
                            }

                        </div>
                    </div>
                    : null
            }

            {
                user?.verified === 0 && authCodeStatus === "expired" ?
                    <div className="Signup-Border Verify-Border margin-top-50-px" >
                        <div className="align-items-center display-flex flex-direction-column margin-top-40-px">
                            <span>The code sent to your email is expired please click "Send" to send new code to your email and then verify</span>
                            {sendButtonClicked ?
                                <Button className="submit" variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span className=""> Loading...</span>
                                </Button> :
                                <button className="submit" onClick={() => { sendNewCode() }}>Send</button>
                            }

                        </div>
                    </div>
                    : null
            }
        </>
    )
}