import React, { useEffect, useRef, useState } from "react";
import { MdCancel } from "react-icons/md";
import searchUtility from "../../functions/searchUtility";
import useKeypress from "react-use-keypress";
import { AiOutlineSearch } from "react-icons/ai"

function MultipleSelection({ selectionMap, handleSelectionMap, selectionOptionsArray, selectionOptionArrayMap, theme, searchMap, searchMapResultField, enableSelectAll, showFieldArray }) {
    // const loaded = useRef(false)
    const [filteredSelection, setFilteredSelection] = useState(null);
    const [filteredSelectionMap, setFilteredSelectionMap] = useState(null);
    const filteredSelectionRef = useRef(null);
    const filteredSelectionMapRef = useRef(null);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [selectionSize, setSelectionSize] = useState(1);
    // filtering the selected ones
    useEffect(() => {
        // console.log("dsds",selectionMap);
        // debugger;
        if(selectionMap){
            let filt = selectionOptionsArray?.filter(item => !selectionMap[item])
            setFilteredSelection(filt);
            filteredSelectionRef.current = filt;
        }

        if (selectionMap && searchMap && searchMapResultField) {
            let filt1 = searchMap?.filter(item => !selectionMap[item[searchMapResultField]])
            setFilteredSelectionMap(filt1);
            filteredSelectionMapRef.current = filt1;
        }

    }, [selectionMap, searchMap, selectionOptionsArray])

    useEffect(()=>{
        if(selectionMap && Object.keys(selectionMap).length > 0 && selectionOptionsArray){
            let newMap = {};
            for(let key of Object.keys(selectionMap)){
                if(selectionOptionsArray.includes(key)){
                    newMap[key] = "1"
                }
            }
            if(JSON.stringify(newMap) !== JSON.stringify(selectionMap)){
                handleSelectionMap(newMap);
            }
        }
    },[selectionOptionsArray])

    const addSelection = (value) => {
        if (value === "Select") { return }
        let newMap = { ...selectionMap }
        console.log(selectionOptionsArray);
        var country = "1"
        // if(selectionOptionArrayMap){
        //     console.log(selectionOptionArrayMap)
        //     let d = selectionOptionArrayMap.filter(item => item.contact_name === value);
        //     if(d){
        //         if(d[0] && d[0].country){
        //             country = d[0].country;
        //         }
        //     }
        // }
        newMap[value] = country;
        console.log(newMap);
        handleSelectionMap(newMap);
        setSelectionSize(1);
    }

    const removeSelection = (value) => {
        let newMap = { ...selectionMap }
        delete newMap[value]
        handleSelectionMap(newMap);
    }

    useKeypress("Enter", () => {
        onSearch();
    });

    const onSearch = (value) => {
        if ((!value && searchKeyword === "") || (value === "")) {
            let filt = selectionOptionsArray.filter(item => !selectionMap[item])
            setFilteredSelection(filt);
            setSelectionSize(1);
            return
        }
        if (searchMap) {
            let searchFilterMap = searchUtility.filterBySearchArrayOfObjects(filteredSelectionMapRef.current, value ?? searchKeyword)
            if (searchFilterMap.length > 0) {
                setSelectionSize(searchFilterMap.length + 1 > 10 ? 10 : searchFilterMap.length + 1);
                setFilteredSelectionMap(searchFilterMap)

            }
        } else {
            let searchFilter = searchUtility.filterBySearchArray(filteredSelectionRef.current, value ?? searchKeyword)
            if (searchFilter.length > 0) {
                setSelectionSize(searchFilter.length + 1 > 10 ? 10 : searchFilter.length + 1);
                setFilteredSelection(searchFilter)

            }
        }

    }

    const onSearchBarChange = (e) => {
        setSearchKeyword(e.currentTarget.value);
        onSearch(e.currentTarget.value)
    }

    const selectAll = () => {
        let newMap = { ...selectionMap }
        for (let item of selectionOptionsArray) {
            var country = "1"
            // if(selectionOptionArrayMap){
            //     console.log(selectionOptionArrayMap)
            //     let d = selectionOptionArrayMap.filter(item => item.contact_name === item);
            //     if(d){
            //         if(d[0] && d[0].country){
            //             country = d[0].country;
            //         }
            //     }
            // }
            newMap[item] = country;
        }
        handleSelectionMap(newMap);
        setSelectionSize(1);
    }

    return (
        <div className={`display-flex flex-direction-column flex-wrap column-gap-20-px row-gap-20-px  ${theme !== 'Settings' ? '' : 'Settings-Form padding-10-px'}`}>
            <div className="align-items-center ">
                <div style={{ paddingBottom: '15px' }} className="Search-Bar-Focus position-relative ">
                    <span
                        className="position-absolute left-8-px top-4-px purple hover-blue cursor-pointer border-radius-5-px"
                        onClick={() => {
                            onSearch();
                        }}
                    >
                        <AiOutlineSearch />
                    </span>
                    <input
                        className={` ${theme === 'Settings' ? 'card-input__input Settings-Input-Theme Search-Bar' : 'Multi-Search'}`}
                        type="text"
                        onChange={onSearchBarChange}
                        value={searchKeyword}
                        placeholder={"Search"}
                    />
                </div>

                <div className={` ${theme === 'Settings' ? 'width-100-psnt' : ''}`}>
                    {(filteredSelectionMap && searchMap && searchMapResultField) ?
                        <select style={{ maxHeight: '140px' }} className={` ${theme === 'Settings' ? 'card-input__input Settings-Input-Theme min-height-38-px ' : 'Multi-Search width-100-psnt'}`} onChange={(e) => addSelection(e.target.value)} value={"Select"} size={selectionSize} >
                            <option value={"Select"}>{"Select"}</option>
                            {
                                filteredSelectionMap.map((item,index1) => <option key={index1} value={item[searchMapResultField]}>{showFieldArray? showFieldArray.map(key => `${item[key]} | `) : item[searchMapResultField]}</option>)
                            }

                        </select>
                        : null}
                    {filteredSelection && !(filteredSelectionMap && searchMap && searchMapResultField) ?
                        <select style={{ maxHeight: '140px' }} className={` ${theme === 'Settings' ? 'card-input__input Settings-Input-Theme min-height-38-px ' : 'Multi-Search width-100-psnt'}`} onChange={(e) => addSelection(e.target.value)} value={"Select"} size={selectionSize} >
                            <option value={"Select"}>{"Select"}</option>
                            {
                                filteredSelection.map((item,index1) => <option key={index1} value={item}>{item}</option>)
                            }

                        </select>
                        : null}
                </div>
                {enableSelectAll ?
                    <div className="display-flex flex-wrap column-gap-5-px row-gap-5-px margin-top-10-px">
                        <button className="multiple-selection-button" onClick={() => { selectAll() }} >Select All</button>
                        <button className="multiple-selection-button" onClick={() => { handleSelectionMap({}) }} >Empty All</button>
                    </div> :
                    null
                }



            </div>
            {selectionMap && Object.keys(selectionMap).length ?
                <div className={`background-color-light-grey custome-border-1 ${theme === 'Settings' ? 'card-input__input Settings-Input-Theme' : ''}`}>
                    <div className="display-flex flex-direction-column column-gap-10-px row-gap-10-px overflow-y-scroll overflow-x-hidden max-height-100-px">
                        {Object.keys(selectionMap).map(key =>
                            <div className="position-relative"> 
                                <span className="position-absolute top-neg-3 right-0 cursor-pointer" onClick={() => { removeSelection(key) }}><MdCancel /></span>
                                <span className="background-color-light-blue border-radius-5-px padding-custome-5-20-5-5">{key}</span>
                            </div>
                        )}
                    </div>
                </div>
                : null
            }





        </div>
    )
}

export default MultipleSelection;