import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import inputConstants from "../../../constants/inputConstants";
import PopupMessage from "../../utility.component/popupMessage.component/popupMessage.component";
import sqlService from "../../../services/sqlService";
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser, faCoins, faHandHoldingDollar, faMagnifyingGlassLocation, faPlus, faSearch, faStar, faTags, faUser, faUserTie, faUsers } from "@fortawesome/free-solid-svg-icons";
import { faXmarkCircle } from "@fortawesome/free-regular-svg-icons";
import proPhoto from "../../../assets/images/service-pro-photo.png";
import signupConstants from "../../../constants/signupConstants";
import { UserContext } from "../../../App";
import "./proSignup.component.css";
import validateUtility from "../../../functions/validateUtility";
import { useNavigate } from "react-router-dom";
import userUtility from "../../../functions/userUtitily";
import { ImCheckmark } from "react-icons/im";

function ProSignup() {
    const { agentId, tier, plan } = useParams()
    const Navigate = useNavigate();
    const planRef = useRef(plan)

    const [newTask, setNewTask] = useState({})
    const [serverMessage, setServerMessage] = useState(null)

    const requiredVals = ["contact_name", "company_name", "industry_name", "email", "address", "city", "zip_code", "phone_number", "state", "country"]
    const licensedFields = ['Plumbing', 'Electricity', 'Pest']
    const loaded = React.useRef(false);
    const loaded2 = React.useRef(false);
    const [addresses, setAddresses] = useState([]);

    const [INDUSTRIES, setINDUSTRIES] = useState([]);
    const form = useRef()

    const user = useContext(UserContext);
    const [checked, setChecked] = useState({ password: false, confirm_password: false })
    const [focused, setFocused] = useState(null);


    useEffect(() => {
        if (!loaded2.current) {
            sqlService
                .getIndustryNames()
                .then((data) => {
                    const INDUSTRIES = [];
                    for (let i = 0; i < data.data.length; i++) {
                        INDUSTRIES[i] = data.data[i].type_name;
                    }
                    setINDUSTRIES(INDUSTRIES);
                })
                .catch((err) => {
                    console.log("Didn't get industry names");
                    console.log(err);
                });
            loaded2.current = true;
        }
    }, []);

    const [CompanyNames, setCompanyNames] = useState([]);


    useEffect(() => {
        if (!loaded.current) {
            sqlService
                .getCompanyNames()
                .then((data) => {
                    const CompanyNames = [];
                    for (let i = 0; i < data.data.length; i++) {
                        CompanyNames[i] = data.data[i].type_name;
                    }
                    setCompanyNames(CompanyNames);
                })
                .catch((err) => {
                    console.log("Didn't get company names");
                    console.log(err);
                });
            loaded2.current = true;
        }
    }, []);

    const validateChecked = () => {
        var valid = false;
        valid = (checked?.password === 'true' && checked?.confirm_password === 'true')

        return valid;
    }


    function updateNewTask(key, value) {
        switch (key) {
            case ("password"):
                if (validateUtility.regValidate(key, value)) {
                    let nChecked = { ...checked }
                    nChecked["password"] = 'true';
                    if (value === newTask.confirm_password) {
                        nChecked["confirm_password"] = 'true';
                    } else {
                        nChecked["confirm_password"] = 'false';
                    }
                    setChecked(nChecked)
                    setFocused(null)
                } else {
                    setChecked({ ...checked, "password": 'false', "confirm_password": 'false' })
                    // setFocused({password: 'Password not strong enough'})
                    setFocused('Password not strong enough')

                }
                break;

            case ("confirm_password"):
                if (checked.password === 'false') {
                    // setFocused({confirm_password:'Password not strong enough'})
                    setFocused('Password not strong enough')
                } else if (newTask?.password !== value) {
                    // setFocused({confirm_password: 'Password does not match'})
                    setFocused('Password does not match')

                }

                if (newTask?.password === value && checked.password === 'true') {
                    let nChecked = { ...checked }
                    nChecked["confirm_password"] = 'true';
                    setChecked(nChecked)
                    setFocused(null)
                } else {
                    setChecked({ ...checked, "confirm_password": 'false' })
                }

                break;

            case ("state"):
                // console.log(value)
                if (value !== "") {
                    setChecked({ ...checked, [key]: "true" })
                } else {
                    setChecked({ ...checked, [key]: "false" })
                }
                break;


            case ("city"):
                if (value !== "") {
                    setChecked({ ...checked, [key]: "true" })
                } else {
                    console.log("Not a Valid Name")
                    setChecked({ ...checked, [key]: "false" })
                }
                break;

            case ("country"):
                if (value !== "") {
                    setChecked({ ...checked, [key]: "true" })
                } else {
                    console.log("Not a Valid Name")
                    setChecked({ ...checked, [key]: "false" })
                }
                break;

            case ("email"):
                if (validateUtility.regValidate(key, value)) {
                    setChecked({ ...checked, [key]: "true" })
                } else {
                    setChecked({ ...checked, [key]: "false" })
                }
                break;

            case ("contact_name"):
                if (validateUtility.regValidate(key, value)) {
                    setChecked({ ...checked, [key]: "true" })
                } else {
                    setChecked({ ...checked, [key]: "false" })
                }
                break;


            case ("phone_number"):
                if (validateUtility.regValidate(key, value)) {
                    // console.log("validPhone")
                    setChecked({ ...checked, [key]: "true" })
                } else {
                    setChecked({ ...checked, [key]: "false" })
                }
                break;

            case ("address"):
                if (validateUtility.regValidate(key, value)) {
                    // console.log("validAddress")
                    setChecked({ ...checked, [key]: "true" })
                } else {
                    setChecked({ ...checked, [key]: "false" })
                }
                break;

            case ("zip_code"):
                if (validateUtility.regValidate(key, value)) {
                    setChecked({ ...checked, [key]: "true" })
                } else {
                    setChecked({ ...checked, [key]: "false" })
                }
                break;

            case ("company_name"):
                // console.log(value)
                if (value !== "") {
                    setChecked({ ...checked, [key]: "true" })
                } else {
                    setChecked({ ...checked, [key]: "false" })
                }
                break;

            case ("company_type"):
                // console.log(value)
                if (value !== "") {
                    setChecked({ ...checked, [key]: "true" })
                } else {
                    setChecked({ ...checked, [key]: "false" })
                }
                break;

            case ("industry_name"):
                // console.log(value)
                if (value !== "") {
                    setChecked({ ...checked, [key]: "true" })
                } else {
                    setChecked({ ...checked, [key]: "false" })
                }
                break;

            case ("license_number"):
                // console.log(value)
                if (value !== "") {
                    setChecked({ ...checked, [key]: "true" })
                } else {
                    setChecked({ ...checked, [key]: "false" })
                }
                break;

        }
        let nTask = { ...newTask }
        nTask[key] = value;
        setNewTask(nTask);


    }

    useEffect(() => {
        updateNewTask('agent', agentId)

    }, [agentId])

    useEffect(() => {
        updateNewTask('plan_type', tier === 'basic' ? 1 : 2)

    }, [tier])

    useEffect(() => {
        console.log(user);
        if (user) {
            let userRoute = userUtility.getUserRoute(user)
            let currentPath = window.location.pathname;
            if (!currentPath.endsWith(userRoute)) {
                Navigate(userRoute);
            }
        }

    }, [])



    const updateAddress = (key, value, index) => {
        let nAddress = [...addresses]
        nAddress[index][key] = value;
        setAddresses(nAddress)
        updateNewTask("additional_addresses", nAddress)
    }

    function deleteAddress(index) {
        let updated = [...addresses]
        updated.splice(index, 1)
        setAddresses(updated)
        updateNewTask("additional_addresses", updated)

    }

    function closePopup() {
        setServerMessage(null)
    }

    const [triggerX, setTriggerX] = useState(false);


    function submitPro(token) {

        if (!validateChecked()) {
            setServerMessage({ title: 'Oops', message: !checked.password ? "Password not strong enough" : "Password does not match" });
            setTriggerX(true)

            return;
        }


        for (let value of requiredVals) {
            if (!(newTask[value])) {
                setServerMessage({ title: 'Oops', message: "Please fill out all required fields" });
                setTriggerX(true)

                return;

            }
        }

        if (licensedFields.includes(newTask?.industry_name) && !(newTask?.license_number)) {
            setServerMessage({ title: 'Oops', message: "Please fill out all required fields" });
            setTriggerX(true)
            return;
        }

        newTask["inform_pro"] = "true";

        sqlService
            .addProfessional({ user: null, input: newTask, captchaTokenV2: token })
            .then((data) => {
                console.log(data.data)
                data = data.data
                document.cookie = `honeyydo_user_cookie=${JSON.stringify({ data })}; expires=${new Date(Date.now() + (30 * 86400000))}; path=/; sameSite=strict; secure`;
                setNewTask({})
                window.location.reload();
            })
            .catch((err) => {

                setServerMessage({ title: "Oops", message: err?.response?.data?.err?.code === "ER_DUP_ENTRY" ? "Disctinct service areas must have different zip/postal codes" : "Something went wrong" });

            });

    }

    function onSubmit() {
        const token = captchaRef.current.getValue();

        captchaRef.current.reset();
        if (!token) {
            setServerMessage({ "message": "Please verify that you are not a robot", "title": "Error" })
            return
        }
        submitPro(token)
    }

    const captchaRef = useRef(null)
    const [loadedCaptcha, setLoadedCaptcha] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setLoadedCaptcha(true);
        }, 1500);
    })

    const [windowSize, setWindowSize] = React.useState(window.innerWidth);

    useEffect(() => {
        //   if (user) {
        //     let userRoute = userUtility.getUserRoute(user)
        //     let currentPath = window.location.pathname;
        //     if (!currentPath.endsWith(userRoute)) {
        //       Navigate(userRoute);
        //     }
        //   }

        window.addEventListener("resize", onWindowResize);

        return () => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, []);

    const onWindowResize = () => {
        setWindowSize(window.innerWidth);
    };



    return (
        <>
            {agentId && agentId !== "noid" ?
                <>
                    <div style={{ maxWidth: '1400px', padding: '0 5%', margin: '7% auto 5% auto' }} className="Pro-Hero">
                        <div >
                            <h2 className="Blog-Page-Heading">Broaden your client-base</h2>
                            <h5 className="grey">Our database of verified pros is trusted by our homeowners to connect them with professionals in their area</h5>
                            <button style={{ margin: '30px 0' }} className="popup-submit-button" onClick={() => { form.current.scrollIntoView() }}>Join now</button>
                        </div>
                        <img src={proPhoto} className="Pro-Photo" />
                    </div>
                    <div>
                        <h2 className="text-align-center grey">How it Works</h2>
                        <div className="Pro-Icons">
                            <div className="Landing-sub-grid">
                                <FontAwesomeIcon className="display-block margin-auto-lr orange fa-4x" icon={faCoins} />
                                <h4 className="text-align-center grey">Choose your tier</h4>
                                <p>There is no cost to join our database at our basic tier. Simply provide us the information below and we will let you know once you have been approved.</p>
                            </div>
                            <div className="Landing-sub-grid">
                                <FontAwesomeIcon className="display-block margin-auto-lr orange fa-4x" icon={faMagnifyingGlassLocation} />
                                <h4 className="text-align-center grey">Appear in Search Results</h4>
                                <p>When homeowners in your area search for trusted pros in your industry, they will see your information and be able to easily reach out to you.</p>
                            </div>
                            <div className="Landing-sub-grid">
                                <FontAwesomeIcon className="display-block margin-auto-lr orange fa-4x" icon={faHandHoldingDollar} />
                                <h4 className="text-align-center grey">Get More Business</h4>
                                <p>More eyes on your business means more clients for you. It's a great way to jumpstart a new business or supercharge an existing one, all at no cost to you.</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className=" column-gap-10-px justify-content-center">
                        <h2 className="text-align-center grey margin-bottom-30-px">Pricing</h2>
                        <div className="toggle-buttons-container">
                            <div className="toggle-buttons">
                                <button
                                    className={`toggle-button ${plan === "monthly" ? "toggle-button-selected" : ""
                                        }`}
                                    id="month"
                                    onClick={() => setPlan("monthly")}
                                >
                                    Monthly
                                </button>
                                <button
                                    className={`toggle-button ${plan === "yearly" ? "toggle-button-selected" : ""
                                        }`}
                                    id="year"
                                    onClick={() => setPlan("yearly")}
                                >
                                    Yearly
                                </button>
                            </div>
                        </div>
                    </div>
                    <div style={{ maxWidth: '800px' }} className={`${windowSize > 750 ? 'price-cards-container' : 'mobile-price-cards-container'}`}>
                        <div className="price-card" id="agent">
                            <FontAwesomeIcon className="agent-icon" icon={faUser} />
                            <span className="price-card-title grey">Basic</span>
                            <span className="price-card-price">
                                Free              </span>
                            <span style={{ color: 'white' }} className="price-card-term">
                                {plan === "monthly" ? 'per month' : 'per year'}
                            </span>
                            <Link
                                to={user && user?.role_id === 4 ? `/add-payment/agent/${plan}` : signupConstants.AGENT}
                                className="price-card-button"
                                id="homeowner-button"
                            >
                                Sign Up
                            </Link>
                            <span className="price-card-list-title">Includes:</span>
                            <div className="price-card-list-container">
                                <ul
                                    className="price-card-list"
                                    style={{ listStyleType: "disc" }}
                                >
                                    <li>Appearance in search results</li>

                                </ul>
                            </div>
                        </div>
                        <div className="price-card" id="brokerage">
                            <FontAwesomeIcon className="brokerage-icon" icon={faUsers} />
                            <span className="price-card-title grey">Premium</span>
                            <span className="price-card-price">
                                ${plan === "monthly" ? inputConstants.SUBSCRIPTION_PRICES.agent.monthly : inputConstants.SUBSCRIPTION_PRICES.agent.yearly}.00<span style={{ fontSize: '0.5em', color: '#aaa5a5' }}> USD</span>
                            </span>
                            <span className="price-card-term">
                                {plan === "monthly" ? 'per month' : 'per year'}
                            </span>
                            <Link
                                to={user && user?.role_id === 4 ? `/add-payment/agent/${plan}` : signupConstants.AGENT}
                                className="price-card-button"
                                id="homeowner-button"
                            >
                                Sign Up
                            </Link>
                            <span className="price-card-list-title">Includes:</span>
                            <div className="price-card-list-container">
                                <ul
                                    className="price-card-list"
                                    style={{ listStyleType: "disc" }}
                                >
                                    <li>Search priority</li>
                                    <li>Add logos and detailed business information</li>
                                    <li>Ratings and reviews</li>
                                    <li>Promotional content</li>

                                </ul>
                            </div>
                        </div>



                    </div> */}
                </> : null}
            <div ref={form} style={{ padding: '0 5%', margin: 'auto', maxWidth: '1400px' }}>
                <div style={{ margin: '3% 0 5% 0' }} className="">
                    <h2 className="Blog-Page-Heading">{tier === 'basic' ? 'Join Honeyydo as a Pro' : 'Join Honeyydo Pro Premium'}</h2>
                    <h6 style={{ marginBottom: '20px' }} className="Blog-Hero-Text text-smaller color-med-grey">{tier === 'basic' ? ' Fill out the information below to request to join our database. Please note that professionals in licensed industries must provide their license number. Once you are approved, you will appear in the search results of homeowners in your area.'
                        : 'Get the most out of the platform with our premium plan. After submitting your information your application will be reviewed by our team. Once you are approved, your billing cycle will begin. Please note that professionals in licensed industries must provide their license number.'}</h6>
                    <span><span className="red-font">*</span> denotes a required field</span>
                </div>
                <div style={{ gap: '50px 200px' }} className="display-flex Pro-Signup-Page">
                    <div>

                        <div style={{ padding: '0', width: 'unset' }} className="Settings-Panel display-flex flex-direction-column">

                            <h5 style={{ paddingLeft: '10px' }} className="wrap-none grey">Account Information </h5>
                            <span className="bold" style={{ color: '#b2b2b2', paddingLeft: '10px' }}>Password must be at least 6 characters and include a special character and number</span>

                            <div className="card-form height-fit-content padding-10-px box-shadow-none margin-top-20-px">

                                <div className=" box-shadow-none ">

                                    <div className="card-input position-relative">
                                        <label className="card-input__label">
                                            Email Address<span className="red-font">*</span>
                                        </label>
                                        <input
                                            type="text"


                                            className={`card-input__input ${(triggerX && checked?.email !== "true") ? "Input-Error-Border" : ""}`}
                                            autoComplete="off"
                                            value={newTask?.email}
                                            onChange={(e) => { updateNewTask("email", e.currentTarget.value); }}
                                        />
                                        {checked?.email === "true" ?

                                            <span className="Finish-Check"><ImCheckmark /></span>
                                            : null}
                                    </div>
                                </div>
                                <div className="card-input position-relative">
                                    {/* {focused.password ?
                                    <div className="password-hint">
                                        {focused.password}

                                    </div>:null} */}
                                    <label className="card-input__label">
                                        Password<span className="red-font">*</span>
                                    </label>
                                    <input
                                        type="password"

                                        className={`card-input__input ${(triggerX && checked?.password !== "true") ? "Input-Error-Border" : ""}`}
                                        autoComplete="off"
                                        value={newTask?.password}
                                        onChange={(e) => { updateNewTask("password", e.currentTarget.value); }}
                                    />
                                    {checked?.password === "true" ?

                                        <span className="Finish-Check"><ImCheckmark /></span>
                                        : null}
                                </div>
                                <div className="card-input position-relative">
                                    {/* {focused.password ?
                                    <div className="password-hint">
                                        {focused.password}

                                    </div>:null} */}
                                    <label className="card-input__label">
                                        Confirm password<span className="red-font">*</span>
                                    </label>
                                    <input
                                        type="password"

                                        className={`card-input__input ${(triggerX && checked?.confirm_password !== "true") ? "Input-Error-Border" : ""}`}
                                        autoComplete="off"
                                        value={newTask?.confirm_password}
                                        onChange={(e) => { updateNewTask("confirm_password", e.currentTarget.value); }}
                                    />
                                    {checked?.confirm_password === "true" ?

                                        <span className="Finish-Check"><ImCheckmark /></span>
                                        : null}
                                </div>
                            </div>
                        </div>

                        <div style={{ padding: '0', width: 'unset' }} className="Settings-Panel display-flex flex-direction-column">

                            <h5 style={{ paddingLeft: '10px', marginTop: '20px' }} className="wrap-none grey">Business Information </h5>

                            <div className="card-form height-fit-content padding-10-px box-shadow-none margin-top-20-px">

                                <div className=" box-shadow-none ">

                                    <div className="card-input position-relative">

                                        <label className="card-input__label">
                                            Contact Name<span className="red-font">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`card-input__input ${(triggerX && checked?.contact_name !== "true") ? "Input-Error-Border" : ""}`}
                                            autoComplete="off"

                                            value={newTask.contact_name}
                                            onChange={(e) => { updateNewTask("contact_name", e.currentTarget.value); }}

                                        />
                                        {checked?.contact_name === "true" ?

                                            <span className="Finish-Check"><ImCheckmark /></span>
                                            : null}


                                    </div>


                                    <div className="card-input position-relative">
                                        <label className="card-input__label">
                                            Company Name<span className="red-font">*</span>
                                        </label>
                                        <input
                                            type="text"

                                            className={`card-input__input ${(triggerX && checked?.company_name !== "true") ? "Input-Error-Border" : ""}`}
                                            autoComplete="off"
                                            value={newTask?.company_name}
                                            onChange={(e) => { updateNewTask("company_name", e.currentTarget.value); }}
                                        />
                                        {checked?.company_name === "true" ?

                                            <span className="Finish-Check"><ImCheckmark /></span>
                                            : null}
                                    </div>

                                    <div className="card-input position-relative">
                                        <label className="card-input__label">
                                            Company Type<span className="red-font">*</span>
                                        </label>
                                        <select
                                            name="company_type"

                                            className={`card-input__input -select ${(triggerX && checked?.company_type !== "true") ? "Input-Error-Border" : ""}`}
                                            value={newTask?.company_type ?? ""}
                                            onChange={(e) => { updateNewTask("company_type", e.currentTarget.value); }}

                                        >
                                            <option value="" >
                                                Select
                                            </option>

                                            {CompanyNames.map((val, index) => (
                                                <option key={index} value={val}>
                                                    {val}
                                                </option>
                                            ))}
                                        </select>
                                        {checked?.company_type === "true" ?

                                            <span className="Finish-Check"><ImCheckmark /></span>
                                            : null}
                                    </div>

                                    <div className="card-input position-relative">
                                        <label className="card-input__label">
                                            Industry<span className="red-font">*</span>
                                        </label>
                                        <select
                                            name="industry"
                                            className={`card-input__input -select ${(triggerX && checked?.industry_name !== "true") ? "Input-Error-Border" : ""}`}
                                            value={newTask?.industry_name ?? ""}
                                            onChange={(e) => { updateNewTask("industry_name", e.currentTarget.value); }}

                                        >
                                            <option value="" >
                                                Select
                                            </option>

                                            {inputConstants?.INDUSTRIES.map((val, index) => (
                                                <option key={index} value={val}>
                                                    {val}
                                                </option>
                                            ))}
                                        </select>
                                        {checked?.industry_name === "true" ?

                                            <span className="Finish-Check"><ImCheckmark /></span>
                                            : null}
                                    </div>
                                    {licensedFields.includes(newTask.industry_name) ?

                                        <div className="card-input position-relative">
                                            <label className="card-input__label">
                                                License Number<span className="red-font">*</span>
                                            </label>
                                            <input
                                                type="text"

                                                className={`card-input__input ${(triggerX && checked?.license_number !== "true") ? "Input-Error-Border" : ""}`}
                                                autoComplete="off"
                                                value={newTask?.license_number}
                                                onChange={(e) => { updateNewTask("license_number", e.currentTarget.value); }}
                                            />
                                            {checked?.license_number === "true" ?

                                                <span className="Finish-Check"><ImCheckmark /></span>
                                                : null}
                                        </div>
                                        : null}

                                    <div className="card-form__row">
                                        <div className="card-form__col position-relative">
                                            <label className="card-input__label">
                                                Phone<span className="red-font">*</span>
                                            </label>
                                            <input
                                                type="tel"
                                                className={`card-input__input ${(triggerX && checked?.phone_number !== "true") ? "Input-Error-Border" : ""}`}
                                                autoComplete="off"

                                                value={newTask.phone_number}
                                                onChange={(e) => { updateNewTask("phone_number", e.currentTarget.value); }}

                                            />
                                            {checked?.phone_number === "true" ?

                                                <span className="Finish-Check"><ImCheckmark /></span>
                                                : null}
                                        </div>


                                        <div className="card-form__col ">
                                            <div className="card-input position-relative">
                                                <label className="card-input__label">
                                                    Fax
                                                </label>
                                                <input
                                                    type="tel"
                                                    className="card-input__input"
                                                    autoComplete="off"

                                                    value={newTask.fax_number}
                                                    onChange={(e) => { updateNewTask("fax_number", e.currentTarget.value); }}

                                                />
                                            </div>
                                        </div>

                                    </div>




                                    <div className="card-input position-relative">
                                        <label className="card-input__label">
                                            Address<span className="red-font">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`card-input__input ${(triggerX && checked?.address !== "true") ? "Input-Error-Border" : ""}`}
                                            autoComplete="off"

                                            value={newTask.address}
                                            onChange={(e) => { updateNewTask("address", e.currentTarget.value); }}

                                        />
                                        {checked?.address === "true" ?

                                            <span className="Finish-Check"><ImCheckmark /></span>
                                            : null}
                                    </div>

                                    <div className="card-form__row">
                                        <div className="card-form__col position-relative">
                                            <label

                                                className="card-input__label"
                                            >
                                                City<span className="red-font">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className={`card-input__input ${(triggerX && checked?.city !== "true") ? "Input-Error-Border" : ""}`}

                                                autoComplete="off"
                                                value={newTask?.city ?? ""}
                                                onChange={(e) => { updateNewTask("city", e.currentTarget.value); }}


                                            />
                                            {checked?.city === "true" ?

                                                <span className="Finish-Check"><ImCheckmark /></span>
                                                : null}
                                            {/* <div className="width-150-px">
                                        <Select  options={inputConstants.US_CITIES} onChange={(e) => { updateNewTask("city", e.value); }} />
                                    </div> */}
                                        </div>
                                        <div className="card-form__col position-relative">

                                            <label

                                                className="card-input__label"
                                            >
                                                {newTask.country === "Canada" ? "Province" : "State"}
                                                <span className="red-font">*</span>
                                            </label>
                                            <select
                                                name="state"
                                                className={`card-input__input -select ${(triggerX && checked?.state !== "true") ? "Input-Error-Border" : ""}`}
                                                value={newTask?.state ?? ""}
                                                onChange={(e) => { updateNewTask("state", e.currentTarget.value); }}

                                            >
                                                
                                                {/* <option value="" >
                                                Select State
                                            </option> */}

                                                {newTask.country !== "Canada" ?
                                                    inputConstants?.US_STATES.map((val, index) => (
                                                        <option key={index} value={val.value}>
                                                            {val.label}
                                                        </option>
                                                    )) : null}
                                                {newTask.country && newTask.country !== "United States" ?
                                                    inputConstants?.CANDADIAN_PROVINCES.map((val, index) => (
                                                        <option key={index} value={val.value}>
                                                            {val.label}
                                                        </option>
                                                    )) : null}
                                            </select>
                                            {checked?.state === "true" ?

                                                    <span className="Finish-Check"><ImCheckmark /></span>
                                                    : null}

                                            {/* <div className="width-150-px">
                                            <Select options={inputConstants.US_STATES} onChange={(e) => { updateNewTask("state", e.value); }} />

                                        </div> */}



                                        </div>
                                        <div className="card-form__col ">
                                            <div className="card-input position-relative">
                                                <label

                                                    className="card-input__label"
                                                >
                                                    {newTask.country === "Canada" ? "Postal Code" : "Zip Code"}
                                                    <span className="red-font">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`card-input__input ${(triggerX && checked?.zip_code !== "true") ? "Input-Error-Border" : ""}`}
                                                    maxLength={newTask?.country === "Canada" ? "6" : "5"}
                                                    autoComplete="off"
                                                    value={newTask?.zip_code ?? ""}
                                                    onChange={(e) => { updateNewTask("zip_code", e.currentTarget.value); }}


                                                />
                                                {checked?.zip_code === "true" ?

                                                    <span className="Finish-Check"><ImCheckmark /></span>
                                                    : null}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-input position-relative">
                                        <label className="card-input__label">
                                            Country<span className="red-font">*</span>
                                        </label>
                                        <select
                                            name="country"
                                            className={`card-input__input -select ${(triggerX && checked?.country !== "true") ? "Input-Error-Border" : ""}`}
                                            value={newTask?.country ?? ""}
                                            onChange={(e) => { updateNewTask("country", e.currentTarget.value); }}

                                        >
                                            {checked?.country === "true" ?

                                                <span className="Finish-Check"><ImCheckmark /></span>
                                                : null}
                                            <option value="" >
                                                Select
                                            </option>

                                            <option value="United States" >
                                                United States
                                            </option>


                                            <option value='Canada'>
                                                Canada
                                            </option>
                                        </select>
                                    </div>



                                    <div onClick={() => console.log(checked)} className="card-input position-relative">
                                        <label className="card-input__label">
                                            Website
                                        </label>
                                        <input
                                            type="text"

                                            className="card-input__input"
                                            autoComplete="off"
                                            value={newTask?.website}
                                            onChange={(e) => { updateNewTask("website", e.currentTarget.value) }}
                                        />
                                    </div>

                                    <div className="display-flex align-items-center width-full-pt">
                                        <div className="margin-auto-lr align-items-center display-flex padding-15-px" >
                                            <span className="wrap-none">Additional service areas</span>
                                            <button style={{ marginLeft: '15px' }} className="Tag-Button" onClick={() => setAddresses(addresses.concat({ address: "", state: "", city: "", zip_code: "" }))}>Add <FontAwesomeIcon icon={faPlus} /></button>
                                        </div>
                                    </div>

                                    {addresses.map((address, index) =>
                                        <div key={index}>
                                            <div className="card-input position-relative">
                                                <FontAwesomeIcon onClick={() => deleteAddress(index)} icon={faXmarkCircle} className=" color-med-grey position-absolute cursor-pointer circle-x" />

                                                <label className="card-input__label">
                                                    Address<span className="red-font">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="card-input__input"
                                                    autoComplete="off"

                                                    value={addresses[index]?.address ?? ""}
                                                    onChange={(e) => { updateAddress("address", e.currentTarget.value, index); }}

                                                />
                                            </div>

                                            <div className="card-form__row">
                                                <div className="card-form__col">
                                                    <label

                                                        className="card-input__label"
                                                    >
                                                        City<span className="red-font">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="card-input__input"

                                                        autoComplete="off"
                                                        value={addresses[index]?.city ?? ""}
                                                        onChange={(e) => { updateAddress("city", e.currentTarget.value, index); }}


                                                    />

                                                </div>
                                                <div className="card-form__col">

                                                    <label

                                                        className="card-input__label"
                                                    >
                                                        {newTask.country === "Canada" ? "Province" : "State"}
                                                        <span className="red-font">*</span>
                                                    </label>
                                                    <select
                                                        name="state"
                                                        className="card-input__input -select"
                                                        value={addresses[index]?.state ?? ""}
                                                        onChange={(e) => { updateAddress("state", e.currentTarget.value, index); }}

                                                    >

                                                        {newTask.country !== "Canada" ?
                                                            inputConstants?.US_STATES.map((val, index) => (
                                                                <option key={index} value={val.value}>
                                                                    {val.label}
                                                                </option>
                                                            )) : null}
                                                        {newTask.country !== "United States" ?
                                                            inputConstants?.CANDADIAN_PROVINCES.map((val, index) => (
                                                                <option key={index} value={val.value}>
                                                                    {val.label}
                                                                </option>
                                                            )) : null}
                                                    </select>

                                                </div>
                                                <div className="card-form__col ">
                                                    <div className="card-input position-relative">
                                                        <label

                                                            className="card-input__label"
                                                        >
                                                            {newTask?.country === "Canada" ? "Postal Code" : "Zip Code"}
                                                            <span className="red-font">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="card-input__input"
                                                            maxLength={newTask?.country === "Canada" ? "6" : "5"}
                                                            autoComplete="off"
                                                            value={addresses[index]?.zip_code ?? ""}
                                                            onChange={(e) => { updateAddress("zip_code", e.currentTarget.value, index); }}


                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    )}



                                </div>
                                {loadedCaptcha &&
                                    <ReCAPTCHA
                                        style={{ display: "inline-block", marginTop: '20px' }}
                                        theme="light"
                                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY_V2}
                                        ref={captchaRef}
                                        onChange={null}
                                        asyncScriptOnLoad={null}
                                    />
                                }
                                {/* <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY_V2} ref={captchaRef} /> */}
                                {tier === 'basic' ?
                                    <button style={{ marginBottom: '40px' }} className="popup-submit-button Settings-Submit " type="submit" onClick={() => { onSubmit() }}>Submit</button>
                                    : <button style={{ marginBottom: '40px' }} className="popup-submit-button Settings-Submit " type="submit" onClick={() => { onSubmit() }}>Proceed</button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="Plan-Preview box-shadow-2">
                        <div className="Plan-Header">
                            <h4 className="white wrap-none text-align-center margin-bottom-20-px">{tier === 'basic' ? 'Basic Plan' : 'Premium Plan'}</h4>
                            {tier === 'basic' ?
                                <h5 className="text-align-center" style={{ color: '#e2e2e2' }}>Free</h5>
                                :
                                <div className="display-flex align-items-center justify-content-center column-gap-20-px">
                                    <div className="display-flex flex-direction-column align-items-center">
                                        <h5 style={{ color: 'white', marginBottom: '0' }}>{inputConstants.SUBSCRIPTION_PRICES.agent.monthly} usd </h5>
                                        <h6 style={{ color: '#e2e2e2' }}>monthly</h6>
                                    </div>
                                    <span style={{ color: '#f2f2f2' }}>or</span>
                                    <div className="display-flex flex-direction-column align-items-center">
                                        <h5 style={{ color: 'white', marginBottom: '0' }}>{inputConstants.SUBSCRIPTION_PRICES.agent.yearly} usd </h5>
                                        <h6 style={{ color: '#e2e2e2' }}>yearly</h6>
                                    </div>
                                </div>}
                        </div>

                        {tier === 'basic' ?
                            <div className="Plan-Features">
                                <div><FontAwesomeIcon className="grey padding-right-10-px padding-top-5-px" icon={faUsers} /><span>Visibility to our database of homeowners</span></div>
                                <div><FontAwesomeIcon className="grey padding-right-10-px padding-top-5-px" icon={faSearch} /><span>Appear in search results of users in your area</span></div>

                                <Link to={"/pro-info"}>See other plans</Link>

                            </div>

                            : <div className="Plan-Features">
                                <div><FontAwesomeIcon className="grey padding-right-10-px padding-top-5-px" icon={faSearch} /><span>Appear at the top of search results</span></div>
                                <div><FontAwesomeIcon className="grey padding-right-10-px padding-top-5-px" icon={faCircleUser} /><span>Add logos and detailed business information</span></div>
                                <div><FontAwesomeIcon className="grey padding-right-10-px padding-top-5-px" icon={faStar} /><span>Establish trust with ratings and reviews</span></div>
                                <div><FontAwesomeIcon className="grey padding-right-10-px padding-top-5-px" icon={faTags} /><span>Get more business with promotional content</span></div>
                                <Link to={"/pro-info"}>See other plans</Link>
                            </div>}

                    </div>
                </div>
            </div>

            <PopupMessage title={serverMessage?.title} message={serverMessage?.message} onConfirm={closePopup} show={serverMessage} />
        </>
    )
}

export default ProSignup;