import React, { useState, useEffect, useRef } from "react";
import { json, useNavigate } from "react-router-dom";
import BarChart from "../../utility.component/dash.component/barchart.component";
import StarRating from "../../utility.component/rating.component/rating.component";
import Notifications from "../../utility.component/notifications.component/notifications.component";

import sqlService from "../../../services/sqlService";

import { useContext } from "react";
import { UserContext } from "../../../App";
import Notepad from "../../utility.component/notepad.component/notepad.component";
import NotepadMobile from "../../utility.component/notepad.component/notepadMobile.component";
import inputConstants from "../../../constants/inputConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faCircleUser, faCircleXmark, faCoins, faEdit, faHandHoldingDollar, faKey, faMagnifyingGlassLocation, faPlus, faSave, faSearch, faStar, faTags, faTrash, faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { faXmarkCircle } from "@fortawesome/free-regular-svg-icons";
import Placeholder from '../../../assets/images/Placeholder-removebg.png';
import "../proDashboard.component/proDashboard.component.css"
import validateUtility from "../../../functions/validateUtility";
import Cookies from "universal-cookie";
import PopupMessage from "../../utility.component/popupMessage.component/popupMessage.component";
import { ImCheckmark } from "react-icons/im";
import Invoice from "../../main.component/invoice.component/invoice.component";
import calcUtility from "../../../functions/calcUtility";
import sortUtility from "../../../functions/sortUtility";
import honeyydoLogo from "../../../assets/images/Honeyydo logo/Honeyydo-logo-icon.png";
import stripeService from "../../../services/stripeService";
import { RiVisaLine, RiMastercardFill } from "react-icons/ri";
import { SiAmericanexpress } from "react-icons/si";
import { BiRadioCircleMarked, BiRadioCircle } from "react-icons/bi";
import Modal from "react-bootstrap/Modal";
import Rating from 'react-rating';
import timeUtility from "../../../functions/timeUtility";



export default function ProDashboard({setUser}) {
  const user = useContext(UserContext);
  const COLORS = ['#f8a025', '#2596be', '#34378b', '#b7b8d6', '#b551dd']
  const [show, setShow] = useState(false)
  const [pro, setPro] = useState({})
  const [newTask, setNewTask] = useState({ ...user });
  const [selectedTab, setSelectedTab] = useState('Subscription')
  const [infoTab, setInfoTab] = useState('info')
  const [deletePopup, setDeletePopup] = useState(null)
  const [upgradePopup, setUpgradePopup] = useState(null)
  const [message, setMessage] = useState(null)
  const [newPayment, setNewPayment] = useState({})
  // const [serverMessage, setServerMessage] = useState(null)

  const requiredVals = ["contact_name", "company_name", "company_type", "industry_name", "email", "address", "city", "zip_code", "phone_number", "state", "country"]
  const licensedFields = ['Plumbing', 'Electricity', 'Pest']
  // const loaded = React.useRef(false);
  // const loaded2 = React.useRef(false);
  const [addresses, setAddresses] = useState([]);

  const Navigate = useNavigate();

  const [windowSize, setWindowSize] = React.useState(window.innerWidth);

  const addressLoadedRef = useRef(false);
  const updateUser = useRef(false);

  useEffect(() => {
    if (!user) {
      Navigate("/")
    }else{
      if(!addressLoadedRef.current){
        addressLoadedRef.current = true;
        if(user?.addresses){
          setAddresses(user?.addresses?.filter(item => item.primary_address === 0));
        }
      }
    }
    // else if ((user.plan_type === 2 && user.subscribed === 0) || user.admin_verified === 0) {
    //   Navigate("/pro_pending")
    // }
  })

  const onWindowResize = () => {
    setWindowSize(window.innerWidth);
  }


  useEffect(() => {
    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  const [checked, setChecked] = useState({})
  const [focused, setFocused] = useState(null);


  function updateNewTask(key, value) {
    switch (key) {
      case ("password"):
        if (validateUtility.regValidate(key, value)) {
          let nChecked = { ...checked }
          nChecked["password"] = "true";
          if (value === newTask.confirm_password) {
            nChecked["confirm_password"] = "true";
          } else {
            nChecked["confirm_password"] = "false";
          }
          setChecked(nChecked)
          setFocused('')
        } else {
          setChecked({ ...checked, "password": "false", "confirm_password": "false" })
          setFocused('Password not strong enough')
        }
        break;

      case ("confirm_password"):
        if (checked.password === "false") {
          setFocused('Password not strong enough')

        } else if (newTask?.password !== value) {
          setFocused('Password does not match')
        }

        if (newTask?.password === value && checked.password === "true") {
          let nChecked = { ...checked }
          nChecked["confirm_password"] = "true";
          setChecked(nChecked)
          setFocused('')
        } else {
          setChecked({ ...checked, "confirm_password": "false" })
        }

        break;

      case ("current_password"):
        if (key === "current_password") {
          if (validateUtility.regValidate("password", value)) {
            let nChecked = { ...checked }
            nChecked["current_password"] = "true";
            setChecked(nChecked)
          } else {
            setChecked({ ...checked, "current_password": "false" })
          }
        }
    }


    let nTask = { ...newTask }
    nTask[key] = value;
    setNewTask(nTask);


  }

  function updateNewPayment(key, value) {
    let temp = { ...newPayment }
    temp[key] = value;
    setNewPayment(temp);
  }



  const updateAddress = (key, value, index) => {
    let nAddress = [...addresses]
    nAddress[index][key] = value;
    setAddresses(nAddress)
    updateNewTask("additional_addresses", nAddress)
  }

  function deleteAddress(index) {
    let updated = [...addresses]
    updated.splice(index, 1)
    setAddresses(updated)
    updateNewTask("additional_addresses", updated)

  }

  const imgEdit = useRef(null)


  const handleImageClick = (event, key) => {
    if (user.plan_type === 2 && user?.subscribed === 1) {
      imgEdit.current.click(event, key)
    } else {
      setUpgradePopup(true)
    }



  }


  const [refresh, setRefresh] = useState(false)



  function submitImage(event) {

    const file = event.target.files[0];
    const reader = new FileReader();
    var fileObject = null;


    if (!validateUtility.validFileSize(file)) {
      // setServerMessage({ "message": `File size must be less than ${inputConstants.MAX_FILE_SIZE / 1000000}mb`, "title": "Error" })
      return;
    }


    reader.onload = () => {

      let data = reader.result;
      let t = file.type;
      let type = t.split("/")[1]

      sqlService.uploadImg({ input: { "imgFile": data, "imgType": type, "imgKey": "logo_link", "imgSubfolder": "client/pro/pro_imgs" } }).then(d => {

        user.logo_link = d.data.link
        setRefresh(!refresh)
      }).catch(err => {
        console.log(err);
      });


    };

    if (file) {
      reader.readAsDataURL(file);
    }
    // });
  };
  const [coupons, setCoupons] = useState([])
  const [tempCoupon, setTempCoupon] = useState(null)
  const companyNames = useRef([])
  const [userInvoices, setUserInvoices] = useState([])
  const [selectedInvoice, setSelectedInvoice] = useState(null)
  const [paymentMethodsArray, setPaymentMethodsArray] = useState(null);
  const [billingAddress, setBillingAddress] = useState(null);
  const [triggerX, setTriggerX] = useState(false);
  const [activePaymentMethodId, setActivePaymentMethodId] = useState(null);
  const [addingNewPayment, setAddingNewPayment] = useState(false)
  const [userSubscriptions, setUserSubscriptions] = useState([])

  function updateTempCoupon(key, value) {
    var temp = { ...tempCoupon }
    temp[key] = value
    setTempCoupon(temp)
  }

  useEffect(() => {
    sqlService.getProCoupons({ user: user, input: { id: user.id } }).then((d) => {
      setCoupons(d.data)
    }).catch((err) => {
      console.log(err)
    });

    if(updateUser.current){
      updateUser.current = false;
      sqlService.getProById({"input": {"id": user.id}}).then(d => {
        let usr = d?.data
        console.log("ddddddd", d);
        // setUser(usr);
        setNewTask(usr);
        addressLoadedRef.current = true;
        setAddresses(usr?.addresses.filter(item => item.primary_address === 0));
      }).catch(err => {
        console.log(err);
      });
  
    }

  }, [refresh])

  const loaded = useRef(false)
  useEffect(() => {
    if (!loaded.current) {
      sqlService.getCompanyNames().then((data) => {
        const CompanyNames = [];
        for (let i = 0; i < data.data.length; i++) {
          CompanyNames[i] = data.data[i].type_name;
        }
        companyNames.current = CompanyNames;
      })
        .catch((err) => {
          console.log("Didn't get company names");
          console.log(err);
        });

      // sqlService.getUserInvoices({}).then(d => {
      //   var temp = sortUtility.sort('creation_date', d, false)
      //   if (temp) {
      //     setUserInvoices(temp)
      //     if (temp[0]) {
      //       setSelectedInvoice(temp[0].id)
      //     }
      //   }
      //   console.log(d)
      // }).catch(err => {
      //   console.log(err)
      // })
      if(user.subscribed === 1){
        getAllInvoices()
      }
      

      if (user?.subscribed === 1) {
        stripeService.getBillingInfo({ "user": user }).then(d => {
          let data = d.data;
          let paymentMethodsArray = data?.paymentMethods?.data ?? null;
          let billingAddress = data?.address ?? null;
          // console.log(paymentMethodsArray)
          setPaymentMethodsArray(paymentMethodsArray);
          setBillingAddress(billingAddress);
        }).catch(err => {
          console.log(err);
        });
      }

      if (user.plan_type === 2) {
        stripeService.getCustomerSubscriptions({ "user": user }).then(d => {
          let data = d.data;
          console.log(data)
          // console.log(data);
          setUserSubscriptions(data.data);

        }).catch(err => {
          console.log(err);
        })
      }




      loaded.current = true;
    }

  }, [])

  useEffect(() => {
    setActivePaymentMethodId(paymentMethodsArray?.find((p) => p.id === userSubscriptions[0]?.default_payment_method)?.id)
  }, [paymentMethodsArray, userSubscriptions])

  function deleteCoupon() {
    sqlService.deleteCoupon({ input: deletePopup }).then(() => {
      setDeletePopup(null)
      setRefresh(!refresh)
    }).catch((err) => {

    })

  }

  function submitCoupon() {
    if (!tempCoupon.name && !tempCoupon.description) {
      return
    }
    sqlService.addCoupon({ user: user, input: tempCoupon }).then(() => {
      setTempCoupon(null)
      setRefresh(!refresh)
    }).catch((err) => {

    })

  }

  function updateInfo() {
    addressLoadedRef.current = false;
    for (let value of requiredVals) {
      if (!(newTask[value])) {
        setMessage({ title: 'Oops', message: `Please enter your ${value.toLowerCase().replace('_', ' ')}` });
        return;

      }
    }

    if (licensedFields.includes(newTask?.industry_name) && !(newTask?.license_number)) {
      setMessage({ title: 'Oops', message: "Please provide your license number" });

      return;
    }
    sqlService.updateProfessional({ user: user, input: { newVals: newTask, pro: user.id } }).then(() => {
      setMessage({ title: 'Thanks!', message: 'Your changes have been saved' })
      updateUser.current = true;
      setRefresh(!refresh)
    }).catch((err) => {

    })
  }

  const handlePasswordUpdate = () => {
    let pass = true;
    if (!validateCheckedPasswords()) {
      pass = false;
      setMessage({ title: 'Oops', message: focused })

    }


    if (pass) {
      sqlService.updateUserPassword({ "input": newTask }).then(d => {
        setChecked({ "current_password": "false", "password": "false", "confirm_password": "false" })
        setMessage({ title: 'Thanks!', message: 'Your password has been updated' })

      }).catch(err => {
        console.log(err);

        setChecked({ "current_password": "false", "password": "false", "confirm_password": "false" })

      })
    }

  }

  const validateCheckedPasswords = () => {
    var valid = false;
    valid = ((checked?.current_password === "true" || (user.google_verified === 1 && user.has_password === 0)) && checked?.password === "true" && checked?.confirm_password === "true")
    return valid;
  }


  const cardTypesIcons = {
    "visa": <RiVisaLine />,
    "mastercard": <RiMastercardFill />,
    "amex": <SiAmericanexpress />
  }

  const currentYear = new Date().getFullYear();
  const monthsArr = Array.from({ length: 12 }, (x, i) => {
    const month = i + 1;
    return month <= 9 ? '0' + month : month;
  });
  const yearsArr = Array.from({ length: 9 }, (_x, i) => currentYear + i);

  const handleAddingNewPayment = (val) => {
    if (val) {
      setNewTask({
        "card_name": "",
        "card_year": "",
        "card_month": "",
        "card_cvv": "",
        "card_number": "",
        "card_year": "",
        "country": "United States",
      })
      setAddingNewPayment(true);
    } else {
      let task = {
        "state": billingAddress?.state ?? "",
        "country": "United States",
        "city": billingAddress?.city ?? "",
        "address1": billingAddress?.address1 ?? "",
        "address2": billingAddress?.address2 ?? "",
        "zip_code": billingAddress?.zip_code ?? "",
      }
      setNewTask(task)
      setAddingNewPayment(false);
    }
  }

  const addNewCard = () => {
    console.log(newPayment)
    stripeService.addNewCard({ "user": user, input: { card: newPayment } }).then(d => {
      let data = d.data;
      let paymentMethodsArray = data?.paymentMethods?.data ?? null;
      let billingAddress = data?.address ?? null;
      setPaymentMethodsArray(paymentMethodsArray);
      setBillingAddress(billingAddress);
      handleAddingNewPayment(false);
      setAddingNewPayment(false)
    }).catch(err => {
      console.log(err)
    })
  }

  const handleUpdatePaymentMethod = () => {
    if (userSubscriptions[0] && activePaymentMethodId && activePaymentMethodId !== userSubscriptions[0].default_payment_method) {
      stripeService.updatePaymentMethod({ "user": user, "input": { "paymentId": activePaymentMethodId, "subscriptionId": userSubscriptions[0].id } }).then(d => {
        stripeService.getCustomerSubscriptions({ "user": user }).then(d => {
          let data = d.data;
          // console.log(data);
          setUserSubscriptions(data.data);
        }).catch(err => {
          console.log(err);
        })
      }).catch(err => {
        console.log(err);
      })
    }
  }

  const getAllInvoices = async () => {
    var hasMore = false;
    var allInvoices = [];
    var starting_after = null;
    do {
      await stripeService.getAllInvoices({ input: { starting_after: starting_after } }).then(d => {
        let data = d.data;
        hasMore = data?.has_more
        starting_after = data?.data[data?.data?.length - 1]?.id;
        allInvoices = [...allInvoices, ...data.data];
      }).catch(err => {
        console.log(err)
      })
    } while (hasMore === true)
    console.log(allInvoices);
    var temp = sortUtility.sort('created', allInvoices, false)
    if (temp) {
      setUserInvoices(temp)
      if (temp[0]) {
        setSelectedInvoice(temp[0].number)
      }
    }
    setUserInvoices(allInvoices);
  }

  return (
    <>
      {windowSize > 850 ?
        <>
          <Notepad />

        </> : null
      }
      <div className="Detail-View-Page">
        <div className="Detail-Top-Bar">
          <h5>{user.company_name}</h5>

        </div>
        <div style={{ columnGap: '4%' }} className="Detail-View-Header " >
          <div className="position-relative align-self-center">
            <img className="margin-auto-lr display-block" src={user.logo_link?.length ? user.logo_link : Placeholder} />
            <div onClick={(e) => handleImageClick(e)} className="Add-Image-Icon"><FontAwesomeIcon className="white fa-sm" icon={faPlus} /></div>
            <input className='display-none' ref={imgEdit} type="file" id="imageUpload" accept="image/*" onChange={(e) => submitImage(e)} />

          </div>
          <div>
            <h4>{user.contact_name}</h4>

            <div className="Details-Grid-Container">
              <div>

                <div className="Details-Grid">

                  <span style={{ color: '#a1a1a1' }} className="bold">{user.email}</span>

                </div>
              </div>
            </div>
            <div>

            </div>

          </div>
          <div>
            <h5 className="grey">My Rating<FontAwesomeIcon style={{ fontSize: '.7em', marginBottom: '3px' }} className="margin-left-10-px" icon={faStar} /></h5>
            {user.avg_rating && user.plan_type === 2 ?
              <div className="display-flex column-gap-5-px margin-top-15-px">
                <span className="grey bold">{user.avg_rating}</span>
                <Rating style={{ fontSize: '.5em' }} initialRating={user.avg_rating} readonly={true} emptySymbol={` bi-hexagon fa-2x  star-grey `} fullSymbol={` bi-hexagon-fill fa-2x logo-orange`} />
              </div>
              : user.plan_type === 1 ?
                <div style={{ color: '#a1a1a1' }} className="margin-top-20-px bold ">Upgrade to premium to see your rating</div>
                :
                <div style={{ color: '#a1a1a1' }} className="margin-top-20-px bold">No ratings yet</div>}
            {user.rating_count ?
              <span style={{ color: '#a1a1a1' }}>{user.rating_count} rating{user.rating_count > 1 ? 's' : ''}</span>
              : null}
          </div>
        </div>
        <div className="Selection-Bar">

          <div className={`Selection-Bar-Tab cursor-pointer ${selectedTab === 'Subscription' ? 'Selection-Bar-Selected' : ''} `} onClick={() => setSelectedTab('Subscription')}>
            Subscription
          </div>
          <div className={`Selection-Bar-Tab cursor-pointer ${selectedTab === 'Coupon' ? 'Selection-Bar-Selected' : ''} `} onClick={() => setSelectedTab('Coupon')}>
            Manage Coupons
          </div>
          <div className={`Selection-Bar-Tab cursor-pointer ${selectedTab === 'Edit' ? 'Selection-Bar-Selected' : ''} `} onClick={() => setSelectedTab('Edit')}>
            Update Info
          </div>

        </div>


        <div className={`Tab-Page position-relative`}>
          {selectedTab === 'Edit' ?
            <div className="display-flex Settings-Container">
              <div className="Settings-Buttons Pro-Info-Container" >

                <button className={`pro-info-button ${infoTab === 'info' ? "Selected-Info-Button" : ""}`} onClick={() => { setInfoTab('info') }}><FontAwesomeIcon icon={faUser} className="fa-sm padding-right-10-px" />Information</button>

                <button className={`pro-info-button ${infoTab === 'password' ? "Selected-Info-Button" : ""}`} onClick={() => { setInfoTab('password') }}><FontAwesomeIcon icon={faKey} className="fa-sm padding-right-10-px" />Password</button>
              </div>
              {infoTab === 'info' ?

                <div style={{ marginTop: '55px', marginLeft: '30px' }} className="card-form height-fit-content padding-10-px box-shadow-none margin-top-20-px">

                  <div className=" box-shadow-none ">

                    <div className="card-input">

                      <label className="card-input__label">
                        Contact Name*
                      </label>
                      <input
                        type="text"
                        className="card-input__input"
                        autoComplete="off"
                        style={{ backgroundColor: 'white' }}

                        value={newTask.contact_name}
                        onChange={(e) => { updateNewTask("contact_name", e.currentTarget.value); }}

                      />


                    </div>


                    <div className="card-input">
                      <label className="card-input__label">
                        Company Name*
                      </label>
                      <input
                        type="text"
                        style={{ backgroundColor: 'white' }}

                        className="card-input__input"
                        autoComplete="off"
                        value={newTask.company_name}
                        onChange={(e) => { updateNewTask("company_name", e.currentTarget.value); }}
                      />
                    </div>

                    <div className="card-input">
                      <label className="card-input__label">
                        Company Type*
                      </label>
                      <select
                        name="company_type"
                        className="card-input__input -select"
                        value={newTask?.company_type}
                        onChange={(e) => { updateNewTask("company_type", e.currentTarget.value); }}
                        style={{ backgroundColor: 'white' }}

                      >
                        <option value="" >
                          Select
                        </option>

                        {companyNames.current.map((val, index) => (
                          <option key={index} value={val}>
                            {val}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="card-input">
                      <label className="card-input__label">
                        Industry*
                      </label>
                      <select
                        name="industry"
                        className="card-input__input -select"
                        value={newTask?.industry_name}
                        onChange={(e) => { updateNewTask("industry_name", e.currentTarget.value); }}
                        style={{ backgroundColor: 'white' }}

                      >
                        <option value="" >
                          Select
                        </option>

                        {inputConstants?.INDUSTRIES.map((val, index) => (
                          <option key={index} value={val}>
                            {val}
                          </option>
                        ))}
                      </select>
                    </div>

                    {licensedFields.includes(newTask.industry_name) ?

                      <div className="card-input">
                        <label className="card-input__label">
                          License Number*
                        </label>
                        <input
                          type="text"

                          className="card-input__input"
                          autoComplete="off"
                          value={newTask?.license_number}
                          onChange={(e) => { updateNewTask("license_number", e.currentTarget.value); }}
                          style={{ backgroundColor: 'white' }}
                        />
                      </div>
                      : null}


                    <div className="card-form__row">
                      <div className="card-form__col">
                        <label className="card-input__label">
                          Phone*
                        </label>
                        <input
                          type="tel"
                          className="card-input__input"
                          autoComplete="off"

                          value={newTask.phone_number}
                          onChange={(e) => { updateNewTask("phone_number", e.currentTarget.value); }}
                          style={{ backgroundColor: 'white' }}

                        />
                      </div>


                      <div className="card-form__col ">
                        <div className="card-input">
                          <label className="card-input__label">
                            Fax
                          </label>
                          <input
                            type="tel"
                            className="card-input__input"
                            autoComplete="off"

                            value={newTask.fax_number}
                            onChange={(e) => { updateNewTask("fax_number", e.currentTarget.value); }}
                            style={{ backgroundColor: 'white' }}

                          />
                        </div>
                      </div>

                    </div>




                    <div className="card-input">
                      <label className="card-input__label">
                        Address*
                      </label>
                      <input
                        type="text"
                        className="card-input__input"
                        autoComplete="off"

                        value={newTask.address}
                        onChange={(e) => { updateNewTask("address", e.currentTarget.value); }}
                        style={{ backgroundColor: 'white' }}

                      />
                    </div>

                    <div className="card-form__row">
                      <div className="card-form__col">
                        <label

                          className="card-input__label"
                        >
                          City*
                        </label>
                        <input
                          type="text"
                          className="card-input__input"

                          autoComplete="off"
                          value={newTask?.city ?? ""}
                          onChange={(e) => { updateNewTask("city", e.currentTarget.value); }}
                          style={{ backgroundColor: 'white' }}


                        />

                      </div>
                      <div className="card-form__col">

                        <label

                          className="card-input__label"
                        >
                          {newTask.country === "Canada" ? "Province" : "State"}
                          *
                        </label>
                        <select
                          name="state"
                          className="card-input__input -select"
                          value={newTask?.state ?? ""}
                          onChange={(e) => { updateNewTask("state", e.currentTarget.value); }}
                          style={{ backgroundColor: 'white' }}

                        >


                          {newTask.country !== "Canada" ?
                            inputConstants?.US_STATES.map((val, index) => (
                              <option key={index} value={val.value}>
                                {val.label}
                              </option>
                            )) : null}
                          {newTask.country && newTask.country !== "United States" ?
                            inputConstants?.CANDADIAN_PROVINCES.map((val, index) => (
                              <option key={index} value={val.value}>
                                {val.label}
                              </option>
                            )) : null}
                        </select>




                      </div>
                      <div className="card-form__col ">
                        <div className="card-input">
                          <label

                            className="card-input__label"
                          >
                            {newTask.country === "Canada" ? "Postal Code" : "Zip Code"}
                            *
                          </label>
                          <input
                            type="text"
                            className="card-input__input"
                            maxLength={newTask?.country === "Canada" ? "6" : "5"}
                            autoComplete="off"
                            value={newTask?.zip_code ?? ""}
                            onChange={(e) => { updateNewTask("zip_code", e.currentTarget.value); }}
                            style={{ backgroundColor: 'white' }}


                          />
                        </div>
                      </div>
                    </div>

                    <div className="card-input">
                      <label className="card-input__label">
                        Country*
                      </label>
                      <select
                        name="country"
                        className="card-input__input -select"
                        value={newTask?.country ?? ""}
                        onChange={(e) => { updateNewTask("country", e.currentTarget.value); }}
                        style={{ backgroundColor: 'white' }}

                      >
                        <option value="" >
                          Select
                        </option>

                        <option value="United States" >
                          United States
                        </option>


                        <option value='Canada'>
                          Canada
                        </option>
                      </select>
                    </div>



                    <div className="card-input">
                      <label className="card-input__label">
                        Website
                      </label>
                      <input
                        type="text"

                        className="card-input__input"
                        autoComplete="off"
                        value={newTask?.website}
                        onChange={(e) => { updateNewTask("website", e.currentTarget.value) }}
                        style={{ backgroundColor: 'white' }}
                      />
                    </div>

                    <div className="display-flex align-items-center width-full-pt">
                      <div className="margin-auto-lr align-items-center display-flex padding-15-px" >
                        <span className="wrap-none">Additional service areas</span>
                        <button style={{ marginLeft: '15px' }} className="Tag-Button" onClick={() => setAddresses(addresses.concat({ address: "", state: "", city: "", zip_code: "" }))}>Add <FontAwesomeIcon icon={faPlus} /></button>
                      </div>
                    </div>

                    {addresses.map((address, index) =>
                      <div key={index}>
                        <div className="card-input position-relative">
                          <FontAwesomeIcon onClick={() => deleteAddress(index)} icon={faXmarkCircle} className=" color-med-grey position-absolute cursor-pointer circle-x" />

                          <label className="card-input__label">
                            Address*
                          </label>
                          <input
                            type="text"
                            className="card-input__input"
                            autoComplete="off"
                            style={{ backgroundColor: 'white' }}

                            value={addresses[index]?.address ?? ""}
                            onChange={(e) => { updateAddress("address", e.currentTarget.value, index); }}

                          />
                        </div>

                        <div className="card-form__row">
                          <div className="card-form__col">
                            <label

                              className="card-input__label"
                            >
                              City*
                            </label>
                            <input
                              type="text"
                              className="card-input__input"

                              autoComplete="off"
                              value={addresses[index]?.city ?? ""}
                              onChange={(e) => { updateAddress("city", e.currentTarget.value, index); }}
                              style={{ backgroundColor: 'white' }}


                            />

                          </div>
                          <div className="card-form__col">

                            <label

                              className="card-input__label"
                            >
                              {newTask.country === "Canada" ? "Province" : "State"}
                              *
                            </label>
                            <select
                              name="state"
                              className="card-input__input -select"
                              value={addresses[index]?.state ?? ""}
                              onChange={(e) => { updateAddress("state", e.currentTarget.value, index); }}
                              style={{ backgroundColor: 'white' }}

                            >

                              {newTask.country !== "Canada" ?
                                inputConstants?.US_STATES.map((val, index) => (
                                  <option key={index} value={val.value}>
                                    {val.label}
                                  </option>
                                )) : null}
                              {newTask.country !== "United States" ?
                                inputConstants?.CANDADIAN_PROVINCES.map((val, index) => (
                                  <option key={index} value={val.value}>
                                    {val.label}
                                  </option>
                                )) : null}
                            </select>

                          </div>
                          <div className="card-form__col ">
                            <div className="card-input">
                              <label

                                className="card-input__label"
                              >
                                {newTask?.country === "Canada" ? "Postal Code" : "Zip Code"}
                                *
                              </label>
                              <input
                                type="text"
                                className="card-input__input"
                                maxLength={newTask?.country === "Canada" ? "6" : "5"}
                                autoComplete="off"
                                value={addresses[index]?.zip_code ?? ""}
                                onChange={(e) => { updateAddress("zip_code", e.currentTarget.value, index); }}
                                style={{ backgroundColor: 'white' }}


                              />
                            </div>
                          </div>
                        </div>
                      </div>

                    )}



                  </div>
                  <button className="popup-submit-button display-block " style={{ margin: '20px auto' }} onClick={() => updateInfo()}>Save</button>

                </div>
                : null}

              {infoTab === "password" ?

                <div style={{ marginTop: '55px', marginLeft: '30px' }} className="card-form height-fit-content padding-10-px box-shadow-none margin-top-20-px">

                  <div className=" box-shadow-none">
                    {(!(user.google_verified) || user.google_verified && user.has_password) ?
                      <div className="card-input position-relative">
                        <label className="card-input__label " htmlFor="currentpass">
                          Current Password
                        </label>
                        <input
                          name="currentpass"
                          type="password"

                          className={`card-input__input `}
                          autoComplete="off"
                          value={newTask?.current_password}
                          onChange={(e) => { updateNewTask("current_password", e.currentTarget.value); }}
                          style={{ backgroundColor: 'white' }}
                        />
                        {checked?.current_password === "true" ?
                          <span style={{ top: '50%' }} className="settings-blue position-absolute right-10-px top-2-px"><ImCheckmark /></span>
                          : null}

                      </div>
                      :
                      <div className="card-input position-relative">
                        <span className="">
                          Your account was verified by Google, but you can add a new password to use it to login with your email <span className="bold">{user.email}</span>.
                        </span>
                      </div>

                    }


                    <div className="card-input position-relative">
                      <label className="card-input__label " htmlFor="newpass">
                        New Password
                      </label>
                      <input
                        name="newpass"
                        type="password"

                        className={`card-input__input `}

                        autoComplete="off"
                        value={newTask?.password}
                        onChange={(e) => { updateNewTask("password", e.currentTarget.value); }}
                        style={{ backgroundColor: 'white' }}
                      />
                      {checked?.password === "true" ?
                        <span style={{ top: '50%' }} className="settings-blue position-absolute right-10-px top-2-px"><ImCheckmark /></span>
                        : null}


                    </div>

                    <div className="card-input position-relative">
                      <label className="card-input__label " htmlFor="repeatpass">
                        Confirm Password
                      </label>
                      <input
                        name="repeatpass"
                        type="password"
                        className={`card-input__input `}
                        autoComplete="off"
                        value={newTask?.confirm_password}
                        onChange={(e) => { updateNewTask("confirm_password", e.currentTarget.value); }}
                        style={{ backgroundColor: 'white' }}
                      />
                      {checked?.confirm_password === "true" ?
                        <span style={{ top: '50%' }} className="settings-blue position-absolute right-10-px top-2-px"><ImCheckmark /></span>
                        : null}


                    </div>
                  </div>
                  {focused ?
                    <div style={{ height: '18px' }} className='width-100-psnt  display-flex flex-wrap justify-content-center' >

                      <span className="Signup-Hint padding-x-10-px align-items-center display-flex justify-content-center grey">{focused}</span>

                    </div> : <div style={{ height: '18px' }} />}
                  <div className="display-flex flex-direction-column justify-content-center width-full-pt row-gap-15-px">
                    <button style={{ margin: '20px auto' }} onClick={() => { handlePasswordUpdate() }} className="popup-submit-button Settings-Submit" type="submit">{(!user.google_verified || user.google_verified && user.has_password) ? "Update" : "Add Password"}</button>

                  </div>
                </div>

                : null}


            </div> : null}
          {selectedTab === 'Subscription' ?
            < >
              {user.plan_type === 1 ?
                <div className="Tab-Wrap">
                  <div className="Current-Plan">
                    <span className="bold" style={{ color: '#c2c2c2' }}>Current Plan</span>
                    <h4 className="grey margin-top-20-px">Basic </h4>
                    <span className=" bold" style={{ color: '#a1a1a1' }}  >Visibility to homeowners in your area, appear in search results targeted by location and keywords</span>

                    <button onClick={() => { Navigate("/add-payment/pro_complete/monthly"); }} className="Upgrade-Button margin-top-20-px">Upgrade Plan</button>

                  </div>
                  <div className="Current-Plan">

                    <h5 className="grey margin-top-20-px">Upgrade to Premium to receive the following features: </h5>

                    <div className="Plan-Features" style={{ margin: '20px 0' }}>
                      <div><FontAwesomeIcon className="grey padding-right-10-px padding-top-5-px" icon={faSearch} /><span className=" bold" style={{ color: '#a1a1a1' }}>Appear at the top of search results</span></div>
                      <div><FontAwesomeIcon className="grey padding-right-10-px padding-top-5-px" icon={faCircleUser} /><span className=" bold" style={{ color: '#a1a1a1' }}>Add logos and detailed business information</span></div>
                      <div><FontAwesomeIcon className="grey padding-right-10-px padding-top-5-px" icon={faStar} /><span className=" bold" style={{ color: '#a1a1a1' }}>Establish trust with ratings and reviews</span></div>
                      <div><FontAwesomeIcon className="grey padding-right-10-px padding-top-5-px" icon={faTags} /><span className=" bold" style={{ color: '#a1a1a1' }}>Get more business with promotional content</span></div>

                    </div>

                    <p className=" bold grey"  >Premium users make a stronger impression and get more business. Get the most out of your membership by upgrading for as little as ${(inputConstants.SUBSCRIPTION_PRICES.pro.yearly / 12).toFixed(2)} a month.</p>
                  </div>
                </div> : null}
              {user.plan_type === 2 && user?.subscribed === 1 ?
                <div className="Tab-Wrap">
                  <div className="Current-Plan">
                    <span className="bold" style={{ color: '#c2c2c2' }}>Current Plan</span>
                    <h4 className="grey margin-top-20-px">Premium </h4>
                    <span className=" bold" style={{ color: '#a1a1a1' }}  >Enhanced visibility and branding</span>

                    <div className="Plan-Features" style={{ margin: '20px 0' }}>
                      <div><FontAwesomeIcon className="grey padding-right-10-px padding-top-5-px" icon={faSearch} /><span className=" bold" style={{ color: '#a1a1a1' }}>Appear at the top of search results</span></div>
                      <div><FontAwesomeIcon className="grey padding-right-10-px padding-top-5-px" icon={faCircleUser} /><span className=" bold" style={{ color: '#a1a1a1' }}>Add logos and detailed business information</span></div>
                      <div><FontAwesomeIcon className="grey padding-right-10-px padding-top-5-px" icon={faStar} /><span className=" bold" style={{ color: '#a1a1a1' }}>Establish trust with ratings and reviews</span></div>
                      <div><FontAwesomeIcon className="grey padding-right-10-px padding-top-5-px" icon={faTags} /><span className=" bold" style={{ color: '#a1a1a1' }}>Get more business with promotional content</span></div>

                    </div>


                  </div>
                  <div className="Current-Plan">


                    {/* <div className="Payment-Method">

                    </div> */}

                    {/* <Invoice dashboard={true}/> */}
                    <div className="Settings-Heading margin-bottom-20-px">
                      <h5 className="wrap-none grey">Billing Info </h5>
                    </div>
                    {/* <div className="card-form height-fit-content padding-10-px box-shadow-none margin-top-20-px"> */}

                    {/* <label className="card-input__label">
                        Default Payment Method
                      </label> */}
                    <span className="bold padding-10-px" style={{ color: '#c2c2c2' }}>Payment Method</span>
                    {paymentMethodsArray ? paymentMethodsArray?.map(paymentMethod =>
                      <div className="display-flex flex-direction-column padding-10-px ">

                        <div className={`Payment-Method-Div ${((activePaymentMethodId && activePaymentMethodId === paymentMethod.id && paymentMethodsArray?.length > 1) || (userSubscriptions.length && userSubscriptions[0].default_payment_method === paymentMethod.id && !activePaymentMethodId && paymentMethodsArray?.length > 1)) ? 'Selected-Payment' : paymentMethodsArray?.length > 1 ? 'Multi-Payment-Method-Div' : ''}`} onClick={() => { setActivePaymentMethodId(paymentMethod.id) }}>
                          <div className="display-flex flex-wrap column-gap-15-px row-gap-15-px position-relative">
                            <div className="display-flex">
                              <span> {(((activePaymentMethodId && activePaymentMethodId === paymentMethod.id) || (userSubscriptions.length && userSubscriptions[0].default_payment_method === paymentMethod.id && !activePaymentMethodId)) && paymentMethodsArray?.length > 1) ? <BiRadioCircleMarked /> : paymentMethodsArray?.length > 1 ? <BiRadioCircle /> : null}</span>
                            </div>

                            <div className="display-flex flex-direction-column">
                              <span className="width-fit-content">{paymentMethod?.billing_details?.name ?? 'No Name'}</span>
                              <span>**** {paymentMethod?.card?.last4 ?? ""} Expiry: {paymentMethod?.card?.exp_month ?? ""}/{paymentMethod?.card?.exp_year ?? ""}</span>
                              {userSubscriptions.length && userSubscriptions[0].default_payment_method === paymentMethod.id ? <div className="Default-Payment">Default</div> : null}

                            </div>

                            <div style={{ position: 'absolute', right: '5%', top: '-10%' }}>
                              <span className="cc-logo">{cardTypesIcons[paymentMethod?.card?.brand] ?? paymentMethod?.card?.brand}</span>
                            </div>


                          </div>
                        </div>



                      </div>) : null}

                    <div className="display-flex row-gap-10-px flex-wrap margin-top-20-px">
                      <button onClick={() => setAddingNewPayment(true)} className="Upgrade-Button margin-left-10-px">Add Payment Method</button>
                      {(userSubscriptions && userSubscriptions[0] && activePaymentMethodId && activePaymentMethodId !== userSubscriptions[0]?.default_payment_method) ?
                        <button onClick={handleUpdatePaymentMethod} className="Border-Button margin-left-10-px">Update Default</button> : null}
                    </div>


                    {/* <div style={{ maxWidth: '550px' }} className="display-flex flex-wrap column-gap-15-px row-gap-15-px justify-content-center">

                        <button onClick={() => { handleAddingNewPayment(true) }} className="popup-submit-button Add-Payment-Button" type="submit">Add New Payment</button>
                        <button onClick={() => { handleUpdatePaymentMethod() }} className={`${!(userSubscriptions && userSubscriptions[0] && activePaymentMethodId && activePaymentMethodId !== userSubscriptions[0]?.default_payment_method) ? "Settings-Submit-Disabled" : "popup-submit-button Settings-Submit"}`} type="submit" disabled={!(userSubscriptions && userSubscriptions[0] && activePaymentMethodId && activePaymentMethodId !== userSubscriptions[0]?.default_payment_method)}>Update Billing</button>
                      </div> */}



                    <div className="Settings-Heading display-flex justify-content-space-between margin-top-50-px flex-wrap">
                      <h5 className="wrap-none grey align-self-end ">Payment History </h5>
                      {userInvoices?.length ?
                        <select style={{ marginBottom: '5px' }} className="Input-Style" value={selectedInvoice} onChange={(e) => setSelectedInvoice(e.currentTarget.value)}>
                          {userInvoices?.map((item) =>
                            <option value={item.number} >{timeUtility.getTimeFromSecondsToString(item?.created)}</option>
                          )}

                        </select> : null}
                    </div>
                    {userInvoices.filter((i) => i.number === selectedInvoice).map((item) =>
                      <>
                        {windowSize > 1175 ?

                          <div className="Settings-Invoice-Div">
                            <div style={{ margin: '10px 0' }} className="display-flex column-gap-10-px width-full-pt">
                              <img style={{ height: '30px' }} src={honeyydoLogo} />
                              <span className="align-text-left width-100-psnt bold margin-bottom-10-px">{`Honeyydo Order ID ${item?.number}`}</span>
                            </div>
                            <span className="align-text-left width-100-psnt bold margin-bottom-10-px">{timeUtility.getTimeFromSecondsToString(item?.created)}</span>
                            <span className="Invoice-Line-Break"></span>

                            <table className="Invoice-Table">
                              <thead>
                                <tr>
                                  <th>Product</th>
                                  <th>Items</th>
                                  <th>Duration</th>
                                  <th>Amount</th>
                                  <th>Discount</th>
                                  <th>Charged</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{item?.lines?.data?.length > 0 ? item?.lines?.data[0].type : "Subscription"}</td>
                                  <td>1</td>
                                  <td>{item?.lines?.data?.length > 0 ? item?.lines?.data[0].description.split(" ")[2] ?? "" : ""}</td>
                                  <td>${parseInt(item?.subtotal) / 100}</td>
                                  <td>{item?.discount ? item.discount + "%" : "0%"}</td>
                                  <td>${parseInt(item.total) / 100}</td>
                                  <td>✓</td>

                                </tr>
                                <tr>
                                  <td>Subtotal</td>
                                  <td>{""}</td>
                                  <td>{""}</td>
                                  <td>${parseInt(item?.subtotal) /100}</td>
                                  <td>{""}</td>
                                  <td>${parseInt(item?.total) /100}</td>
                                  <td>{""}</td>

                                </tr>
                              </tbody>
                            </table>


                          </div>
                          :
                          <div className="Invoice-Mobile-Div">
                            <div className={`display-flex column-gap-10-px width-full-pt margin-bottom-10-px ${windowSize < 450 ? 'flex-wrap row-gap-10-px' : ''} `}>
                              <img style={{ height: '30px' }} src={honeyydoLogo} />
                              <span style={{ fontSize: '.85em' }} className="align-text-left  bold margin-bottom-10-px">{`Honeyydo Subscription ID: ${item?.stripe_subscription_id}`}</span>
                            </div>

                            <span className="align-text-left width-100-psnt bold margin-bottom-10-px">{item?.creation_date?.split('T')[0]}</span>
                            <div className="Invoice-Mobile-Grid">
                              <span className="bold grey justify-self-end">Product:</span>
                              <span style={{ fontSize: '.9em' }} className="grey margin-left-10-px">{item?.duration?.charAt(0).toUpperCase() + item?.duration?.slice(1)} Subscription</span>
                              <span className="bold grey justify-self-end">Items:</span>
                              <span className="grey margin-left-10-px">1</span>
                              <span className="bold grey justify-self-end">Amount:</span>
                              <span className="grey margin-left-10-px">{item?.amount}</span>
                              <span className="bold grey justify-self-end">Discount:</span>
                              <span className="grey margin-left-10-px">{item?.discount ? item.discount + "%" : "--"}</span>
                              <span className="bold grey justify-self-end">Charged:</span>
                              <span className="grey margin-left-10-px">${calcUtility.getDiscountedValue(item?.discount ?? 0, item?.amount)}</span>


                            </div>
                            <div className="Line-Break" />
                            <div className="display-flex margin-top-10-px">
                              <span className="bold">Subtotal:</span>
                              <span className="grey margin-left-10-px">${calcUtility.getDiscountedValue(item?.discount ?? 0, item?.amount)}</span>

                            </div>
                          </div>}

                      </>)}

                  </div>
                </div>

                : null}



            </> : null
          }
          {selectedTab === 'Coupon' ?
            <div style={{ minHeight: '500px', maxWidth: '70%' }}>
              {user.plan_type === 1 ?

                <div className="Current-Plan">
                  {/* <span className="bold" style={{ color: '#c2c2c2' }}>Current Plan</span> */}
                  <h4 className="grey margin-top-20-px">This is a premium feature </h4>
                  <span className=" bold" style={{ color: '#a1a1a1' }}  >To add promotions and encourage clients to choose your services, upgrade to a premium account</span>

                  <button onClick={() => { Navigate("/add-payment/pro_complete/monthly") }} className="Upgrade-Button margin-top-20-px">Upgrade Now</button>

                </div>

                : null}
              {user.plan_type === 2 && user?.subscribed === 1 ?
                <div className="Current-Plan">
                  <span className=" bold" style={{ color: '#a1a1a1' }} >Encourage potential clients to choose your services by adding a coupon. You can have up to two active coupons at a time.</span>
                  {coupons?.length < 2 ?
                    tempCoupon ?
                      <button style={{ height: '45px' }} className="popup-submit-button display-block margin-top-20-px margin-bottom-20-px" onClick={() => submitCoupon()}>Save<FontAwesomeIcon icon={faSave} className="fa-sm margin-left-10-px" /></button> :
                      <button style={{ height: '45px' }} className="Tag-Button display-block margin-top-20-px margin-bottom-20-px" onClick={() => { setTempCoupon({ title: null, description: null }) }}>Add<FontAwesomeIcon icon={faPlus} className="fa-sm margin-left-5-px" /></button>
                    : <div style={{ height: '45px' }} />}
                  <div className="display-flex column-gap-15-px">
                    {tempCoupon ?
                      <div className="Coupon">
                        <FontAwesomeIcon style={{ top: '10px', right: '10px' }} className="grey position-absolute cursor-pointer" icon={faXmarkCircle} onClick={() => { setTempCoupon(null) }} />

                        <label className="card-input__label">Name</label>
                        <input className="Input-Style" type="text" onChange={(e) => updateTempCoupon("name", e.target.value)}></input>
                        <label className="card-input__label margin-top-15-px" >Description</label>
                        <input className="Input-Style" type="text" onChange={(e) => updateTempCoupon("description", e.target.value)}></input>


                      </div> : null}
                    {coupons.map((coupon) =>
                      <div style={{ alignSelf: 'start' }} className="Coupon">
                        <div className="display-flex width-full-pt justify-content-space-between" >
                          <h5 className="grey">{coupon.name}</h5>
                          <div className="display-flex column-gap-10-px">
                            {/* <FontAwesomeIcon className="grey cursor-pointer hover-icon" icon={faEdit} /> */}
                            <FontAwesomeIcon className="grey cursor-pointer hover-icon-red" icon={faTrash} onClick={() => setDeletePopup(coupon.id)} />
                          </div>

                        </div>

                        <span className=" bold" style={{ color: '#a1a1a1' }}  >{coupon.description}</span>


                      </div>)}

                  </div>
                </div> : null}

            </div> : null}
        </div>
      </div>

      {deletePopup ?
        <>
          <div className="backdrop"></div>
          <div style={{ width: '250px', top: '50%' }} className="popup box-shadow-2">

            <span className="bold grey" style={{ padding: '20px 0' }}>Delete coupon?</span>
            <div className="display-flex column-gap-10-px margin-top-15-px">
              <button className="Cancel-Button" onClick={() => setDeletePopup(null)}>Cancel</button>
              <button className="Cancel-Button red" onClick={() => deleteCoupon()}>Delete</button>
            </div>
          </div>
        </>


        : null}

      {upgradePopup ?
        <>
          <div className="backdrop"></div>
          <div style={{ width: '500px', top: '50%' }} className="popup box-shadow-2">
            <h4 className="grey margin-top-20-px">This is a premium feature </h4>
            <span className=" bold" style={{ color: '#a1a1a1', margin: '20px 30px' }}  >To personalize your listing by adding a logo, upgrade to a premium account</span>

            <div className="display-flex column-gap-10-px margin-top-15-px">
              <button style={{ height: '45px', marginTop: '0' }} className="Upgrade-Button margin-top-20-px">Upgrade Now</button>
              <button style={{ height: '45px', marginTop: '0' }} className="Cancel-Button" onClick={() => setUpgradePopup(null)}>No Thanks</button>


            </div>
          </div>
        </>


        : null}

      <PopupMessage message={message?.message} title={message?.title} show={message} onConfirm={() => setMessage(null)} />

      <Modal

        show={addingNewPayment}
        onHide={() => setAddingNewPayment(false)}
        backdrop="static"
        keyboard={false}
        size="lg"

      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card-form height-fit-content padding-10-px box-shadow-none margin-top-20-px margin-auto-lr">
            <div className="card-input">
              <label className="card-input__label">
                Address
              </label>
              <input
                type="text"
                className="card-input__input"
                autoComplete="off"
                value={newPayment.address}
                onChange={(e) => { updateNewPayment("address", e.currentTarget.value); }}

              />
            </div>

            <div className="card-form__row">
              <div className="card-form__col">
                <label

                  className="card-input__label"
                >
                  City*
                </label>
                <input
                  type="text"
                  className="card-input__input"

                  autoComplete="off"
                  value={newPayment?.city}
                  onChange={(e) => { updateNewPayment("city", e.currentTarget.value); }}


                />
              </div>
              <div className="card-form__col">
                <div className="card-form__group">
                  <label

                    className="card-input__label"
                  >
                    {newPayment?.country === "Canada" ? "Province*" : "State*"}
                  </label>
                  <select
                    name="state"
                    className="card-input__input -select"
                    value={newPayment?.state}
                    onChange={(e) => { updateNewPayment("state", e.currentTarget.value); }}

                  >
                    {newPayment.country !== "Canada" ?
                      inputConstants?.US_STATES.map((val, index) => (
                        <option key={index} value={val.value}>
                          {val.label}
                        </option>
                      )) : null}
                    {newPayment.country !== "United States" ?
                      inputConstants?.CANDADIAN_PROVINCES.map((val, index) => (
                        <option key={index} value={val.value}>
                          {val.label}
                        </option>
                      )) : null}
                  </select>


                </div>
              </div>
              <div className="card-form__col ">
                <div className="card-input">
                  <label

                    className="card-input__label"
                  >
                    {newPayment?.country === "Canada" ? "Postal Code*" : "Zip Code*"}
                  </label>
                  <input
                    type="text"
                    className="card-input__input"
                    maxLength={newPayment?.country === "Canada" ? "6" : "5"}
                    autoComplete="off"
                    value={newPayment?.zip_code}
                    onChange={(e) => { updateNewPayment("zip_code", e.currentTarget.value); }}

                  />
                </div>
              </div>


            </div>

            <div className="card-input">
              <label className="card-input__label">
                Country*
              </label>
              <select
                name="country"
                className="card-input__input -select"
                value={newPayment?.country ?? ""}
                onChange={(e) => { updateNewPayment("country", e.currentTarget.value); }}


              >
                <option value="" >
                  Select
                </option>

                <option value="United States" >
                  United States
                </option>


                <option value='Canada'>
                  Canada
                </option>
              </select>
            </div>
            <div className="card-input">
              <label htmlFor="cardName" className="card-input__label">
                Card Holder
              </label>
              <input
                type="text"
                className="card-input__input"
                autoComplete="off"
                name="cardHolder"
                value={newPayment.card_name}
                onChange={(e) => { updateNewPayment("card_name", e.currentTarget.value); }}

              />
            </div>
            <div className="card-input">
              <label htmlFor="cardNumber" className="card-input__label">
                Card Number
              </label>
              <input
                type="tel"
                name="cardNumber"
                className="card-input__input"
                autoComplete="off"
                onChange={(e) => { updateNewPayment("card_number", e.currentTarget.value); }}
                maxLength="19"

                value={newPayment.card_number}
              />
            </div>

            <div className="card-form__row">
              <div className="card-form__col">
                <div className="card-form__group">
                  <label
                    htmlFor="cardMonth"
                    className="card-input__label"
                  >
                    Expiration Date
                  </label>
                  <select
                    className="card-input__input -select"
                    value={newPayment.card_month}
                    name="cardMonth"
                    onChange={(e) => { updateNewPayment("card_month", e.currentTarget.value); }}

                  >
                    <option value="" disabled>
                      Month
                    </option>

                    {monthsArr.map((val, index) => (
                      <option key={index} value={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                  <select
                    name="cardYear"
                    className="card-input__input -select"
                    value={newPayment.card_year}
                    onChange={(e) => { updateNewPayment("card_year", e.currentTarget.value); }}

                  >
                    <option value="" disabled>
                      Year
                    </option>

                    {yearsArr.map((val, index) => (
                      <option key={index} value={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="card-form__col -cvv">
                <div className="card-input">
                  <label
                    htmlFor="cardCvv"
                    className="card-input__label"
                  >
                    CVV
                  </label>
                  <input
                    type="tel"
                    className="card-input__input"
                    maxLength="4"
                    autoComplete="off"
                    name="cardCvv"
                    value={newPayment.card_cvv}
                    onChange={(e) => { updateNewPayment("card_cvv", e.currentTarget.value); }}


                  />

                </div>

              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="Cancel-Button" onClick={() => setAddingNewPayment(false)}>
            Cancel
          </button>
          <button className="popup-submit-button" onClick={() => addNewCard()}>
            Add
          </button>
        </Modal.Footer>
      </Modal>




      {/* 
        <Notifications />

        {windowSize <= 850 ?
          <>
            <NotepadMobile />

          </>
          : null} */}
    </>
  )
}