const emailCategories = {
    "NEWSLETTER": {
        1: {
            "KEY": "HOMEOWNER_NEWSLETTER",
            "DESCRIPTION": "Newsletters",
            "ACTIVE": true
        },
        2: {
            "KEY": "AGENT_NEWSLETTER",
            "DESCRIPTION": "Newsletters",
            "ACTIVE": true
        },
    },
    "ESSENTIAL": {
        "ESSENTIAL": {
            "KEY": "ESSENTIAL",
            "ACTIVE": true

        }
    },
    "AGENT_EMAIL": {
        1: {
            "KEY": "AGENT_EMAIL",
            "DESCRIPTION": "Emails from your agent",
            "ACTIVE": true
            
        },
        1000: {
            "KEY": "AGENT_EMAIL",
            "DESCRIPTION": "Emails from realtors",
            "ACTIVE": true
            
        }

    },
    "REMINDERS": {
        2: {
            "KEY": "EVENT_REMINDERS",
            "DESCRIPTION": "Reminders about events",
            "ACTIVE": true
        }
    }, 
    "PROMOTIONS": {
        0: {
            "KEY": "PROMOTIONS",
            "DESCRIPTION": "Promotions",
            "ACTIVE": true

        },
        1: {
            "KEY": "PROMOTIONS",
            "DESCRIPTION": "Promotions",
            "ACTIVE": true

        },
        2: {
            "KEY": "PROMOTIONS",
            "DESCRIPTION": "Promotions",
            "ACTIVE": true

        },
        3: {
            "KEY": "PROMOTIONS",
            "DESCRIPTION": "Promotions",
            "ACTIVE": true

        },
    
        1000: {
            "KEY": "PROMOTIONS",
            "DESCRIPTION": "Promotions",
            "ACTIVE": true

        }
    }
}

module.exports = emailCategories;