import serverConstants from "../constants/serverConstants";
import Cookies from "universal-cookie";

function serverResponseErrActions(err) {
  console.log(err?.response?.data?.message);
  let message = err?.response?.data?.message?.err?.message ?? err?.response?.data?.message?.err?.code ?? err?.response?.data?.message?.error ?? err?.response?.data?.err ?? err?.response?.data?.message;
  if (message) {
    localStorage.setItem("honeyydo-storage-server-state", message)
    window.dispatchEvent(new Event("storage"));
  }
}

function getUserFromCookie() {
  // var s = document.cookie;
  // if(!s){
  //   return null;
  // }else{
  // s = s.replace("honeyydo_user_cookie=", "");
  // s = s.replace("g_state={\"i_l\":0}; ", "");
  // }



  try {
    const cookie = new Cookies();
    // cookie.get("honeyydo_user_cookie")
    const userData = cookie.get("honeyydo_user_cookie");
    return userData?.data ?? null;

    //   let data = JSON.parse(s);
    //   return data?.data ?? null;
  } catch (err) {
    return null
  }

}



export {
  serverResponseErrActions,
  getUserFromCookie
};