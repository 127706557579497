import React, { useEffect, useState } from 'react';
import Rating from 'react-rating';
import '@fortawesome/fontawesome-free/css/all.css';
import './rating.component.css';
import sqlService from '../../../services/sqlService';
import { UserContext } from '../../../App';
import { useContext } from "react";
import Cookies from "universal-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SiZillow } from "react-icons/si";
import {
faFacebook,
faInstagram,
faGoogle,
} from "@fortawesome/free-brands-svg-icons";


const StarRating = ({ setUser, readOnly, dashboard, links }) => {
    const user = useContext(UserContext);
    const [rating, setRating] = useState(user?.general_rating ?? null);
    const [characterRating, setCharacterRating] = useState(user?.character_rating ?? null);
    const [experienceRating, setExperienceRating] = useState(user?.experience_rating ?? null);
    const [skillsRating, setSkillsRating] = useState(user?.skills_rating ?? null);
    const [isSubmitted, setSubmitted] = useState(false);
    const [submittedMsg, setSubmittedMsg] = useState('')
    const [popUp, setPopUp] = useState(false);
    const socialIcons = { "facebook": faFacebook, "instagram": faInstagram, "google": faGoogle }

    const changeRating = (newRating) => {
        setRating(newRating);
        setSubmitted(false);
    };


    const changeCharacterRating = (newRating) => {
        averageRating(newRating, experienceRating, skillsRating);
        setCharacterRating(newRating);
        setSubmitted(false);
    };

    const changeExperienceRating = (newRating) => {
        averageRating(characterRating, newRating, skillsRating);
        setExperienceRating(newRating);
        setSubmitted(false);
    };

    const changeSkillsRating = (newRating) => {
        averageRating(characterRating, experienceRating, newRating);
        setSkillsRating(newRating);
        setSubmitted(false);
    };

    const closePopUp = () => {
        setPopUp(false)
    }

    const averageRating = (characterRating, experienceRating, skillsRating) => {
        let count = 0;
        let sum = 0;

        if (characterRating !== null) {
            count++;
            sum += characterRating;
        }

        if (experienceRating !== null) {
            count++;
            sum += experienceRating;
        }

        if (skillsRating !== null) {
            count++;
            sum += skillsRating;
        }

        let average = count > 0 ? sum / count : 0;
        changeRating(average);
        return average;
    };


    function setCookie(data) {
        const cookie = new Cookies();
        //cookie expires in one month
        //secures means only accessible using https
        //you can also add domain which is the domain of the cookie

        document.cookie = `honeyydo_user_cookie=${JSON.stringify({ data })}; expires=${new Date(Date.now() + (30 * 86400000))}; path=/; sameSite=strict; secure`;
        // window.location.reload();
    }

    const handleSubmit = () => {
        // console.log("links", Object.values(links)[0])
        sqlService.postRating({"input": { "rating": rating, "character_rating": characterRating, "experience_rating": experienceRating, "skills_rating": skillsRating } }).then(data => {
            setSubmitted(true);
            setPopUp(true);
            let newUser = { ...user };
            newUser["general_rating"] = rating;
            newUser["character_rating"] = characterRating;
            newUser["experience_rating"] = experienceRating;
            newUser["skills_rating"] = skillsRating;
            setSubmittedMsg("Submitted!")
            setTimeout(() => {
                setSubmittedMsg('');
            }, 3000);
            if(setUser){
                setUser(newUser);
            }
            setCookie(newUser);
        }).catch(err => {
            console.log(err);
        });
    };

    return (
        <>
            <div className={dashboard ? "rating-container " : "rating-container"}>
                {dashboard ?
                        <Rating
                            initialRating={rating}
                            onClick={changeRating}
                            emptySymbol={` bi-hexagon fa-2x star-grey `}
                            fullSymbol={` bi-hexagon-fill fa-2x logo-orange `}
                            fractions={2}
                            readonly={readOnly}
                        />
                        : null
                }
              
                {!rating && !dashboard && (user?.role_id === 2 || user?.role_id === 3) ?
                    <>
                        <p >You have not received any ratings yet. Check back here later to see how you're being rated by homeowners!</p>

                    </>
                    : !dashboard ?
                        <>
                            <div className='extra-ratings'>

                                <div className='extra-ratings-container'>
                                    <label className="card-input__label">Character</label>
                                    <Rating
                                        initialRating={characterRating}
                                        onClick={changeCharacterRating}

                                        emptySymbol={` bi-hexagon fa-2x  star-grey ${user?.role_id === 1 ? 'star-animate' : ''}`}
                                        fullSymbol={` bi-hexagon-fill fa-2x logo-orange ${user?.role_id === 1 ? 'star-animate' : ''}`}
                                        fractions={2}
                                        readonly={readOnly}
                                    />
                                </div>

                                <div className='extra-ratings-container'>
                                    <label className="card-input__label">Experience</label>
                                    <Rating
                                        initialRating={experienceRating}
                                        onClick={changeExperienceRating}

                                        emptySymbol={` bi-hexagon fa-2x  star-grey ${user?.role_id === 1 ? 'star-animate' : ''}`}
                                        fullSymbol={` bi-hexagon-fill fa-2x logo-orange ${user?.role_id === 1 ? 'star-animate' : ''}`}
                                        fractions={2}
                                        readonly={readOnly}
                                    />
                                </div>


                                <div className='extra-ratings-container'>
                                    <label className="card-input__label">Skill</label>
                                    <Rating
                                        initialRating={skillsRating}
                                        onClick={changeSkillsRating}

                                        emptySymbol={` bi-hexagon fa-2x  star-grey ${user?.role_id === 1 ? 'star-animate' : ''}`}
                                        fullSymbol={` bi-hexagon-fill fa-2x logo-orange ${user?.role_id === 1 ? 'star-animate' : ''}`}
                                        fractions={2}
                                        readonly={readOnly}
                                    />
                                </div>

                            </div>
                            <div className='margin-auto-lr display-flex flex-direction-column margin-top-20-px row-gap-10-px'>
                            <label className="card-input__label">Total</label>
                            <Rating
                                initialRating={rating}
                                onClick={changeRating}
                                emptySymbol={`fa bi-hexagon fa-2x star-grey `}
                                fullSymbol={`fa bi-hexagon-fill fa-2x logo-orange `}
                                fractions={2}
                                readonly={true}
                            />
                            {rating > 0 ?
                                <label className={`card-input__label rating-value text-align-center margin-top-10-px ${isSubmitted ? 'submitted' : ''}`}>Your Overall Rating: {rating.toFixed(2)}</label>

                              
                                : null
                            }
                            {user?.role_id === 1 ?
                            <>
                            <button className="popup-submit-button Settings-Submit" onClick={() => { handleSubmit(); }}>Submit</button>
                            {isSubmitted && !links ?
                                <>
                                    <div className="submitted-rating text-align-center">{submittedMsg}</div>
                                </>
                                : null
                            }
                            </>
                            : null }
                            
                            </div>
                        </>

                            : null
                }
            </div>
            {(popUp) &&
                <>
                    <div className="backdrop"></div>
                    <div className='review-popup'>
                        <h5 className='grey'>Thanks for rating your agent!</h5>
                        {Object.values(links)[0] ? 
                        <>
                        <p className='margin-top-10-px'>Please thank them by also submitting a review on their professional accounts:</p>
                        <div className='display-flex flex-direction-row column-gap-20-px margin-bottom-5-pt'>
                            {Object.entries(links).map(([key, value]) =>
                                <a href={value} target="_blank">
                                    {key === 'zillow' ?
                                        <SiZillow className='fa-xl' />
                                        : <FontAwesomeIcon icon={socialIcons[key]} className='fa-xl' />
                                    }

                                </a>

                            )}
                        </div>
                        </>
                        : null }
                        <button style={{marginTop: '15px'}} className="Cancel-Button" onClick={closePopUp}>
                            Close
                        </button>
                    </div>
                </>

            }
        </>
    );
};

export default StarRating;
