import React, { useEffect, useRef, useState } from "react";

function DraggerResizer({ children, id, style, classNames, handleOnClick, onPositionChange, onSizeChange, preventEditingExternal, initialValues }) {
    const coords = useRef({
        startX: 0,
        startY: 0,
        lastX: 0,
        lastY: 0
    })

    const leftRef = useRef(0);
    const topRef = useRef(0);

    const isClicked = useRef(false);

    const isResizing = useRef(false);

    const handleSizeChange = (key, val) => {
        onSizeChange(key, val);
    }

    const preventEditing = useRef("true");

    useEffect(() => { preventEditing.current = preventEditingExternal; console.log("triggered", preventEditingExternal) }, [preventEditingExternal])

    const loaded = useRef(false);
    const lastId = useRef("");


    const firstDownLeft = useRef("false");
    const firstDownTop = useRef("false");
    const firstDownRight = useRef("false");
    const firstDownBottom = useRef("false");

    const firstDownBottomLeft = useRef("false");
    const firstDownTopLeft = useRef("false");
    const firstDownTopRight = useRef("false");

    const lastXRef = useRef(0);
    const lastYRef = useRef(0);
    // var lastId = "";
    useEffect(() => {
        // console.log(`lastId = ${lastId.current}  id = ${id}`)
        if (lastId.current !== id) {
            const ref = document.getElementById(id);
            if (!ref) {
                return
            }
            // console.log("+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++=NM", initialValues)
            const resizeableEle = ref;
            resizeableEle.style.width = `${initialValues.width}px`;
            resizeableEle.style.height = `${initialValues.height}px`;
            resizeableEle.style.left = `${initialValues.left}px`;
            resizeableEle.style.top = `${initialValues.top}px`;
            loaded.current = true
            lastId.current = id
            lastXRef.current = initialValues.left;
            lastYRef.current = initialValues.top;
        }


    }, [id])


 

    useEffect(() => {
        const ref = document.getElementById(id);
        if (!ref) {
            return
        }

        const container = ref.parentElement;
        if (!container) { console.log("target element must have a parent"); return }



        const refLeft = document.getElementById(id + "left");
        const refTop = document.getElementById(id + "top");
        const refRight = document.getElementById(id + "right");
        const refBottom = document.getElementById(id + "bottom");

        const refBottomLeftHeight = document.getElementById(id + "bottom-left-height");
        const refBottomLeftWidth = document.getElementById(id + "bottom-left-width");

        const refBottomRightHeight = document.getElementById(id + "bottom-right-height");
        const refBottomRightWidth = document.getElementById(id + "bottom-right-width");

        const refTopLeftHeight = document.getElementById(id + "top-left-height");
        const refTopLeftWidth = document.getElementById(id + "top-left-width");

        const refTopRightHeight = document.getElementById(id + "top-right-height");
        const refTopRightWidth = document.getElementById(id + "top-right-width");


        const resizeableEle = ref;
        const styles = window.getComputedStyle(resizeableEle);
        let width = parseInt(styles.width, 10);
        let height = parseInt(styles.height, 10);
        let x = 0;
        let y = 0;



        // resizeableEle.style.top = `${position.x}px`;
        // resizeableEle.style.left = `${position.y}px`;

        // resizeableEle.style.width = `${size.w}px`;
        // resizeableEle.style.height = `${size.h}px`;


        // Right resize
        const onMouseMoveRightResize = (event) => {
            if (preventEditing.current === "true") { return }
            isResizing.current = true;
            const dx = event.clientX - x;
            x = event.clientX;
            lastXRef.current = event.clientX;
            width = width + dx;
            resizeableEle.style.width = `${width}px`;
            handleSizeChange("width", width);
            // console.log(`dx ${dx} = event.clientx ${event.clientX} - x ${x}`);
        };
        
        const onMouseUpRightResize = (event) => {
            if (preventEditing.current === "true") { return }
            isResizing.current = false;
            handleSizeChange("width", resizeableEle.style.width);
            document.removeEventListener("mousemove", onMouseMoveRightResize);
        };
        
        const onMouseDownRightResize = (event) => {
            if (preventEditing.current === "true") { return }
            isResizing.current = true;
            x = event.clientX;
            lastXRef.current = event.clientX;
            resizeableEle.style.left = styles.left;
            resizeableEle.style.right = null;
            document.addEventListener("mousemove", onMouseMoveRightResize);
            document.addEventListener("mouseup", onMouseUpRightResize);
        };

        // Top resize
        const onMouseMoveTopResize = (event) => {
            if (preventEditing.current === "true") { return }
            isResizing.current = true;
            const dy = event.clientY - y;
            height = height - dy;
            y = event.clientY; // added @@
            lastYRef.current = event.clientY;
            topRef.current = y;
            resizeableEle.style.height = `${height}px`;
            handleSizeChange("height", height);
        };

        const onMouseUpTopResize = (event) => {
            if (preventEditing.current === "true") { return }
            isResizing.current = false;
            handleSizeChange("height", resizeableEle.style.height);
            document.removeEventListener("mousemove", onMouseMoveTopResize);
        };

        const onMouseDownTopResize = (event) => {
            if (preventEditing.current === "true") { return }
            if (firstDownTop.current === "true") { // added @@
                resizeableEle.style.bottom = null;
                firstDownTop.current = "false";
            }
            isResizing.current = true;
            y = event.clientY;
            lastYRef.current = event.clientY;
            // console.log(`-------------->  newY ${y}`)
            resizeableEle.style.bottom = styles.bottom;
            handlePositionChange(leftRef.current, topRef.current) // added @@

            resizeableEle.style.bottom = styles.bottom;
            resizeableEle.style.top = null;
            document.addEventListener("mousemove", onMouseMoveTopResize);
            document.addEventListener("mouseup", onMouseUpTopResize);
        };

        // Bottom resize
        const onMouseMoveBottomResize = (event) => {
            if (preventEditing.current === "true") { return }
            isResizing.current = true;
            const dy = event.clientY - y;
            height = height + dy;
            y = event.clientY;
            lastYRef.current = event.clientY;
            resizeableEle.style.height = `${height}px`;
            handleSizeChange("height", height);
        };
        
        const onMouseUpBottomResize = (event) => {
            if (preventEditing.current === "true") { return }
            isResizing.current = false;
            handleSizeChange("height", resizeableEle.style.height);
            document.removeEventListener("mousemove", onMouseMoveBottomResize);
        };
        
        const onMouseDownBottomResize = (event) => {
            if (preventEditing.current === "true") { return }
            isResizing.current = true;
            y = event.clientY;
            lastYRef.current = event.clientY;
            const styles = window.getComputedStyle(resizeableEle);
            resizeableEle.style.top = styles.top;
            resizeableEle.style.bottom = null;
            document.addEventListener("mousemove", onMouseMoveBottomResize);
            document.addEventListener("mouseup", onMouseUpBottomResize);
        };



        // Left resize
        const onMouseMoveLeftResize = (event) => {
            if (preventEditing.current === "true") { return }
            isResizing.current = true;
            const dx = event.clientX - x;
            x = event.clientX;
            lastXRef.current = event.clientX;
            leftRef.current = x; // added @@
            width = width - dx;
            resizeableEle.style.width = `${width}px`;
            handleSizeChange("width", width);
        };

        const onMouseUpLeftResize = (event) => {
            if (preventEditing.current === "true") { return }
            isResizing.current = false;
            handleSizeChange("width", resizeableEle.style.width);
            document.removeEventListener("mousemove", onMouseMoveLeftResize);
        };

        const onMouseDownLeftResize = (event) => {
            if (preventEditing.current === "true") { return }
            if (firstDownLeft.current === "true") { // added @@
                resizeableEle.style.right = null;
                firstDownLeft.current = "false";
            }
            isResizing.current = true;
            x = event.clientX;
            lastXRef.current = event.clientX;
            // console.log(`--------------> newX ${x}`)
            resizeableEle.style.right = styles.right;
            handlePositionChange(leftRef.current, topRef.current) // added @@
            resizeableEle.style.left = leftRef.current;
            resizeableEle.style.left = null;
            document.addEventListener("mousemove", onMouseMoveLeftResize);
            document.addEventListener("mouseup", onMouseUpLeftResize);
        };


         //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        //Bottom Left resize
        const onMouseMoveBottomLeftResize = (event) => {
            if (preventEditing.current === "true") { return }
            isResizing.current = true;

            // console.log(`{{{{{{{{ lastX ${lastXRef.current}   lastY ${lastYRef.current} }}}}}}`)

            var ratio = 1;
            const dy = event.clientY - y;
            const dx = event.clientX - x;

            // console.log(`dy = ${dy} | dx = ${dx}`);

            const distance = Math.sqrt((dy * dy) + (dx * dx));

            // console.log(`distance ${distance}`);

            if (dy > 0 && dx < 0) {
                ratio = height / width;
                // console.log(ratio, distance)
                if (height > width && ratio > 1) {
                    height = height + (distance * ratio);
                    width = width + distance;
                }
                else if (width > height && ratio > 1) {
                    height = height + distance;
                    width = width + (distance * ratio);
                }
                else if (height > width && ratio < 1) {
                    height = height + (distance / ratio);
                    width = width + distance;
                }
                else if (width > height && ratio < 1) {
                    height = height + distance;
                    width = width + (distance / ratio);
                }
                else {
                    height = height + distance;
                    width = width + distance;
                }
            }
            if (dy < 0 && dx > 0) {
                ratio = height / width;
                // console.log(ratio, distance)

                if (height > width && ratio > 1) {
                    height = height - (distance * ratio);
                    width = width - distance;
                }
                else if (width > height && ratio > 1) {
                    height = height - distance;
                    width = width - (distance * ratio);
                }
                else if (height > width && ratio < 1) {
                    height = height - (distance / ratio);
                    width = width - distance;
                }
                else if (width > height && ratio < 1) {
                    height = height - distance;
                    width = width - (distance / ratio);
                }
                else {
                    height = height - distance;
                    width = width - distance;
                }
            }

            // height = height + dy;
            y = event.clientY;
            topRef.current = y;

            // console.log(`y ${y}  topRef ${JSON.stringify(topRef)}`)
            
            x = event.clientX;
            // width = width - dx  ;
            leftRef.current = x;

            lastXRef.current =  event.clientX;
            lastYRef.current = event.clientY;

            // x and y here are relative the page not the element of the component
            // console.log(`x ${x}  leftRef ${JSON.stringify(leftRef)}`)
            
            // console.log(`height ${height}  width ${width}`);

            // console.log(`resizableEle ${resizeableEle}`);

            resizeableEle.style.height = `${Math.round(height)}px`;
            handleSizeChange("height", Math.round(height));
            resizeableEle.style.width = `${Math.round(width)}px`;
            handleSizeChange("width", Math.round(width));
        };

        const onMouseUpBottomLeftResize = (event) => {
            if (preventEditing.current === "true") { return }
            isResizing.current = false;
            handleSizeChange("height", resizeableEle.style.height);
            handleSizeChange("width", resizeableEle.style.width);
            // console.log(`eventX ${event.clientX}`)
            // console.log(`eventY ${event.clientY}`)
            lastXRef.current = event.clientX;
            lastYRef.current = event.clientY;
            document.removeEventListener("mousemove", onMouseMoveBottomLeftResize);
        };

        const onMouseDownBottomLeftResize = (event) => {
            if (preventEditing.current === "true") { return }
            if (firstDownLeft.current === "true") { // added @@
                resizeableEle.style.right = null;
                firstDownLeft.current = "false";
            }
 
            isResizing.current = true;
            y = event.clientY;
            // console.log(`^^^^^ event.clientY ${event.clientY}    lastYRef ${lastYRef.current} ^^^^^^^`)
            const styles = window.getComputedStyle(resizeableEle);
            resizeableEle.style.top = styles.top;
            resizeableEle.style.bottom = null;

            x = event.clientX;
            resizeableEle.style.right = styles.right;
            resizeableEle.style.left = null;

            // console.log("______________________________________________________________")
            // console.log("firstDownLeft ",firstDownLeft.current)
            // console.log("firstDownTop ",firstDownTop.current)
            // console.log("firstDownBottom ",firstDownBottom.current)
            // console.log("firstDownRight ",firstDownRight.current)
            // console.log("______________________________________________________________")


            document.addEventListener("mousemove", onMouseMoveBottomLeftResize);
            document.addEventListener("mouseup", onMouseUpBottomLeftResize);
        };

        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////



        //TopLeft resize
        const onMouseMoveTopLeftResize = (event) => {
            if (preventEditing.current === "true") { return }
            isResizing.current = true;

            var ratio = 1;
            const dy = event.clientY - y;
            const dx = event.clientX - x;
            // console.log(dy, dx);

            const distance = Math.sqrt((dy * dy) + (dx * dx));

            if (dy < 0 && dx < 0) {
                ratio = height / width;
                // console.log(ratio, distance)
                if (height > width && ratio > 1) {
                    height = height + (distance * ratio);
                    width = width + distance;
                }
                else if (width > height && ratio > 1) {
                    height = height + distance;
                    width = width + (distance * ratio);
                }
                else if (height > width && ratio < 1) {
                    height = height + (distance / ratio);
                    width = width + distance;
                }
                else if (width > height && ratio < 1) {
                    height = height + distance;
                    width = width + (distance / ratio);
                }
                else {
                    height = height + distance;
                    width = width + distance;
                }
            }
            if (dy > 0 && dx > 0) {
                ratio = height / width;
                // console.log(ratio, distance)

                if (height > width && ratio > 1) {
                    height = height - (distance * ratio);
                    width = width - distance;
                }
                else if (width > height && ratio > 1) {
                    height = height - distance;
                    width = width - (distance * ratio);
                }
                else if (height > width && ratio < 1) {
                    height = height - (distance / ratio);
                    width = width - distance;
                }
                else if (width > height && ratio < 1) {
                    height = height - distance;
                    width = width - (distance / ratio);
                }
                else {
                    height = height - distance;
                    width = width - distance;
                }
            }

            // height = height + dy;
            y = event.clientY;
            topRef.current = y;

            x = event.clientX;
            // width = width - dx  ;
            leftRef.current = x;

            // console.log(`--------------------top left---------------------`)
            
            // console.log(`y ${y}  topRef ${JSON.stringify(topRef)}`)
            // console.log(`x ${x}  leftRef ${JSON.stringify(leftRef)}`)
            
            // console.log(`height ${height}  width ${width}`);

            // console.log(`resizableEle ${resizeableEle}`);

            // console.log("heaight: ",height)
            // console.log("width: ",width)
            // console.log("x", x)
            // console.log("y:", y);

            resizeableEle.style.height = `${Math.round(height)}px`;
            handleSizeChange("height", Math.round(height));
            resizeableEle.style.width = `${Math.round(width)}px`;
            handleSizeChange("width", Math.round(width));
        };

        const onMouseUpTopLeftResize = (event) => {
            if (preventEditing.current === "true") { return }
            isResizing.current = false;
            handleSizeChange("height", resizeableEle.style.height);
            handleSizeChange("width", resizeableEle.style.width);
            document.removeEventListener("mousemove", onMouseMoveTopLeftResize);
        };

        const onMouseDownTopLeftResize = (event) => {
            if (preventEditing.current === "true") { return }

            // console.log(`***top left mouse down **** lastX = ${lastXRef.current}   | lastY ${lastYRef.current}`)

            if (firstDownLeft.current === "true") { // added @@
                resizeableEle.style.right = null;
                firstDownLeft.current = "false";
            }
            if (firstDownTop.current === "true") { // added @@
                resizeableEle.style.bottom = null;
                firstDownTop.current = "false";
            }
            isResizing.current = true;
            y = event.clientY;
            const styles = window.getComputedStyle(resizeableEle);
           
            //console.log("sylessss", styles, lastYRef.current)

            // resizeableEle.style.top = `${lastYRef.current}px`;
            // let tp = styles?.top?.split("px")[0]
            // let lngt = styles?.height;
            // console.log(tp, height);
            // console.log(lastYRef.current - 615);
            resizeableEle.style.top = styles?.top;
            setTimeout(()=>{
                resizeableEle.style.top = null;
            },10)
            resizeableEle.style.bottom = styles.bottom;

            x = event.clientX;
            resizeableEle.style.right = styles.right;
            resizeableEle.style.left = null;

            //console.log(`eeeeeeeeeeeeeeeeev clientX${event.clientX}  clientY${event.clientY}`)
            // console.log("______________________________________________________________")
            // console.log("firstDownLeft ",firstDownLeft.current)
            // console.log("firstDownTop ",firstDownTop.current)
            // console.log("firstDownBottom ",firstDownBottom.current)
            // console.log("firstDownRight ",firstDownRight.current)
            // console.log("______________________________________________________________")

            document.addEventListener("mousemove", onMouseMoveTopLeftResize);
            document.addEventListener("mouseup", onMouseUpTopLeftResize);
        };

        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////


        //Bottom Right resize
        const onMouseMoveBottomRightResize = (event) => {
            if (preventEditing.current === "true") { return }
            isResizing.current = true;

            var ratio = 1;
            const dy = event.clientY - y;
            const dx = event.clientX - x;
            // console.log(dy, dx);

            const distance = Math.sqrt((dy * dy) + (dx * dx));

            if (dy > 0 && dx > 0) {
                ratio = height / width;
                // console.log(ratio, distance)
                if (height > width && ratio > 1) {
                    height = height + (distance * ratio);
                    width = width + distance;
                }
                else if (width > height && ratio > 1) {
                    height = height + distance;
                    width = width + (distance * ratio);
                }
                else if (height > width && ratio < 1) {
                    height = height + (distance / ratio);
                    width = width + distance;
                }
                else if (width > height && ratio < 1) {
                    height = height + distance;
                    width = width + (distance / ratio);
                }
                else {
                    height = height + distance;
                    width = width + distance;
                }
            }
            if (dy < 0 && dx < 0) {
                ratio = height / width;
                // console.log(ratio, distance)

                if (height > width && ratio > 1) {
                    height = height - (distance * ratio);
                    width = width - distance;
                }
                else if (width > height && ratio > 1) {
                    height = height - distance;
                    width = width - (distance * ratio);
                }
                else if (height > width && ratio < 1) {
                    height = height - (distance / ratio);
                    width = width - distance;
                }
                else if (width > height && ratio < 1) {
                    height = height - distance;
                    width = width - (distance / ratio);
                }
                else {
                    height = height - distance;
                    width = width - distance;
                }
            }

            // height = height + dy;
            y = event.clientY;
            topRef.current = y;

            x = event.clientX;
            // width = width - dx  ;
            leftRef.current = x;

            resizeableEle.style.height = `${Math.round(height)}px`;
            handleSizeChange("height", Math.round(height));
            resizeableEle.style.width = `${Math.round(width)}px`;
            handleSizeChange("width", Math.round(width));
        };

        const onMouseUpBottomRightResize = (event) => {
            if (preventEditing.current === "true") { return }
            isResizing.current = false;
            handleSizeChange("height", resizeableEle.style.height);
            handleSizeChange("width", resizeableEle.style.width);
            document.removeEventListener("mousemove", onMouseMoveBottomRightResize);
        };

        const onMouseDownBottomRightResize = (event) => {
            if (preventEditing.current === "true") { return }
            // if (firstDownRight.current === "true") { // added @@
            //     resizeableEle.style.left = null;
            //     firstDownRight.current = "false";    
            // }
            // if (firstDownTop.current === "true") { // added @@
            //     resizeableEle.style.bottom = null;
            //     firstDownTop.current = "false";
            // }
            isResizing.current = true;
            y = event.clientY;
            const styles = window.getComputedStyle(resizeableEle);
            resizeableEle.style.top = styles.top;
            resizeableEle.style.bottom = null;

            x = event.clientX;
            resizeableEle.style.right = styles?.left;
            setTimeout(()=>{
                resizeableEle.style.right = null;
            },10)
            resizeableEle.style.left = styles.right;

            // console.log("______________________________________________________________")
            // console.log("firstDownLeft ",firstDownLeft.current)
            // console.log("firstDownTop ",firstDownTop.current)
            // console.log("firstDownBottom ",firstDownBottom.current)
            // console.log("firstDownRight ",firstDownRight.current)
            // console.log("______________________________________________________________")

            document.addEventListener("mousemove", onMouseMoveBottomRightResize);
            document.addEventListener("mouseup", onMouseUpBottomRightResize);
        };


        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //Top Right resize
        const onMouseMoveTopRightResize = (event) => {
            if (preventEditing.current === "true") { return }
            isResizing.current = true;

            var ratio = 1;
            const dy = event.clientY - y;
            const dx = event.clientX - x;
            // console.log(dy, dx);

            // console.log("heaight: ",height)
            // console.log("width: ",width)
            // console.log("x", x)
            // console.log("y:", y);
            const distance = Math.sqrt((dy * dy) + (dx * dx));

            if (dy < 0 && dx > 0) {
                ratio = height / width;
                // console.log(ratio, distance)
                if (height > width && ratio > 1) {
                    height = height + (distance * ratio);
                    width = width + distance;
                }
                else if (width > height && ratio > 1) {
                    height = height + distance;
                    width = width + (distance * ratio);
                }
                else if (height > width && ratio < 1) {
                    height = height + (distance / ratio);
                    width = width + distance;
                }
                else if (width > height && ratio < 1) {
                    height = height + distance;
                    width = width + (distance / ratio);
                }
                else {
                    height = height + distance;
                    width = width + distance;
                }
            }
            if (dy > 0 && dx < 0) {
                ratio = height / width;
                // console.log(ratio, distance)

                if (height > width && ratio > 1) {
                    height = height - (distance * ratio);
                    width = width - distance;
                }
                else if (width > height && ratio > 1) {
                    height = height - distance;
                    width = width - (distance * ratio);
                }
                else if (height > width && ratio < 1) {
                    height = height - (distance / ratio);
                    width = width - distance;
                }
                else if (width > height && ratio < 1) {
                    height = height - distance;
                    width = width - (distance / ratio);
                }
                else {
                    height = height - distance;
                    width = width - distance;
                }
            }
            // height = height + dy;
            y = event.clientY;
            topRef.current = y;

            x = event.clientX;
            // width = width - dx  ;
            leftRef.current = x;

            resizeableEle.style.height = `${Math.round(height)}px`;
            handleSizeChange("height", Math.round(height));
            resizeableEle.style.width = `${Math.round(width)}px`;
            handleSizeChange("width", Math.round(width));
        };

        const onMouseUpTopRightResize = (event) => {
            if (preventEditing.current === "true") { return }
            isResizing.current = false;
            handleSizeChange("height", resizeableEle.style.height);
            handleSizeChange("width", resizeableEle.style.width);
            
            
            document.removeEventListener("mousemove", onMouseMoveTopRightResize);
        };

        const onMouseDownTopRightResize = (event) => {

            if (preventEditing.current === "true") { return }
            if (firstDownTop.current === "true") { // added @@
                resizeableEle.style.bottom = null;
                firstDownLeft.current = "false";
            }
            // console.log("+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++=NM", initialValues)

            isResizing.current = true;
            y = event.clientY;
            const styles = window.getComputedStyle(resizeableEle);
            resizeableEle.style.bottom = styles.bottom;
            resizeableEle.style.top = styles?.top;
            setTimeout(()=>{
                resizeableEle.style.top = null;
            },10)
            // resizeableEle.style.top = null;

            x = event.clientX;
            resizeableEle.style.left = styles.left;
            resizeableEle.style.right = null;


            document.addEventListener("mousemove", onMouseMoveTopRightResize);
            document.addEventListener("mouseup", onMouseUpTopRightResize);
        };

        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////



        const onMouseDown = (e) => {
            e.preventDefault();
            if (preventEditing.current === "true") { return }
            isClicked.current = true;
            coords.current.startX = e.clientX;
            coords.current.startY = e.clientY;
        }

        const onMouseUp = (e) => {
            if (preventEditing.current === "true") { return }
            isClicked.current = false;
            coords.current.lastX = ref.offsetLeft;
            coords.current.lastY = ref.offsetTop;
            handlePositionChange(ref.offsetLeft, ref.offsetTop);
            // console.log("firstDownLeft ",firstDownLeft.current)
            // console.log("firstDownTop ",firstDownTop.current)
            // console.log("firstDownBottom ",firstDownBottom.current)
            // console.log("firstDownRight ",firstDownRight.current)
        }

        const onMouseMove = (e) => {
            if (preventEditing.current === "true") { return }

            if (!isClicked.current || isResizing.current) return;

            firstDownLeft.current = "true";
            firstDownTop.current = "true";
            firstDownBottom.current = "true";
            firstDownRight.current = "true";
            // firstDownTopLeft.current = "true";
            // firstDownTopRight.current = "true";
            // firstDownBottomLeft.current = "true";

            // console.log ("CLIENTX", e.clientX, "CLIENTY", e.clientY, "coords.current.startX", coords.current.startX, "coords.current.startY", coords.current.startY, "coords.current.lastX", coords.current.lastX, "coords.current.lastY", coords.current.lastY);
            // console.log (e.clientX - coords.current.startX + coords.current.lastX);
            // console.log (e.clientY - coords.current.startY + coords.current.lastY);
            const nextX = e.clientX - coords.current.startX + coords.current.lastX;
            const nextY = e.clientY - coords.current.startY + coords.current.lastY;

            leftRef.current = nextX;
            topRef.current = nextY;

            ref.style.left = `${nextX}px`;
            ref.style.top = `${nextY}px`;
            handlePositionChange(nextX, nextY);


        }




        ref.addEventListener('mousedown', onMouseDown);
        ref.addEventListener('mouseup', onMouseUp);
        // target.addEventListener('mouseleave', onMouseUp);
        container.addEventListener('mousemove', onMouseMove);

        // container.addEventListener('mouseleave', onMouseUp);
        document.body.addEventListener("mouseup", onMouseUp);


        // Add mouse down event listener
        const resizerRight = refRight;
        resizerRight.addEventListener("mousedown", onMouseDownRightResize);
        const resizerTop = refTop;
        resizerTop.addEventListener("mousedown", onMouseDownTopResize);
        const resizerBottom = refBottom;
        resizerBottom.addEventListener("mousedown", onMouseDownBottomResize);
        const resizerLeft = refLeft;
        resizerLeft.addEventListener("mousedown", onMouseDownLeftResize);

        const resizerBottomLeftWidth = refBottomLeftWidth;
        resizerBottomLeftWidth.addEventListener("mousedown", onMouseDownBottomLeftResize);
        const resizerBottomLeftHeight = refBottomLeftHeight;
        resizerBottomLeftHeight.addEventListener("mousedown", onMouseDownBottomLeftResize);

        const resizerTopLeftWidth = refTopLeftWidth;
        resizerTopLeftWidth.addEventListener("mousedown", onMouseDownTopLeftResize);
        const resizerTopLeftHeight = refTopLeftHeight;
        resizerTopLeftHeight.addEventListener("mousedown", onMouseDownTopLeftResize);

        const resizerBottomRightWidth = refBottomRightWidth;
        resizerBottomRightWidth.addEventListener("mousedown", onMouseDownBottomRightResize);
        const resizerBottomRightHeight = refBottomRightHeight;
        resizerBottomRightHeight.addEventListener("mousedown", onMouseDownBottomRightResize);

        const resizerTopRightWidth = refTopRightWidth;
        resizerTopRightWidth.addEventListener("mousedown", onMouseDownTopRightResize);
        const resizerTopRightHeight = refTopRightHeight;
        resizerTopRightHeight.addEventListener("mousedown", onMouseDownTopRightResize);

        return () => {
            resizerRight.removeEventListener("mousedown", onMouseDownRightResize);
            resizerTop.removeEventListener("mousedown", onMouseDownTopResize);
            resizerBottom.removeEventListener("mousedown", onMouseDownBottomResize);
            resizerLeft.removeEventListener("mousedown", onMouseDownLeftResize);

            ref.removeEventListener('mousedown', onMouseDown);
            ref.removeEventListener('mouseup', onMouseUp);
            // target.removeEventListener('mouseleave', onMouseUp);
            container.removeEventListener('mousemove', onMouseMove);
            // container.removeEventListener('mouseleave', onMouseUp);
            document.body.removeEventListener("mouseup", onMouseUp);

            resizerBottomLeftWidth.removeEventListener("mousedown", onMouseDownBottomLeftResize);
            resizerBottomLeftHeight.removeEventListener("mousedown", onMouseDownBottomLeftResize);

            resizerTopLeftWidth.removeEventListener("mousedown", onMouseDownTopLeftResize);
            resizerTopLeftHeight.removeEventListener("mousedown", onMouseDownTopLeftResize);

            resizerBottomRightWidth.removeEventListener("mousedown", onMouseDownBottomRightResize);
            resizerBottomRightHeight.removeEventListener("mousedown", onMouseDownBottomRightResize);

            resizerTopRightWidth.removeEventListener("mousedown", onMouseDownTopRightResize);
            resizerTopRightHeight.removeEventListener("mousedown", onMouseDownTopRightResize);


        };
    }, [id]);


    useEffect(() => {


        // const target = document.getElementById(id);
        // if (!target) { console.log("item does not exist"); return }

        // const container = target.parentElement;
        // if (!container) { console.log("target element must have a parent"); return }




        // const onMouseDown = (e) => {
        //     e.preventDefault();
        //     if (preventEditing.current === "true") { return }
        //     isClicked.current = true;
        //     coords.current.startX = e.clientX;
        //     coords.current.startY = e.clientY;
        // }

        // const onMouseUp = (e) => {
        //     if (preventEditing.current === "true") { return }
        //     isClicked.current = false;
        //     coords.current.lastX = target.offsetLeft;
        //     coords.current.lastY = target.offsetTop;
        //     handlePositionChange(target.offsetLeft, target.offsetTop);
        // }

        // const onMouseMove = (e) => {
        //     if (preventEditing.current === "true") { return }

        //     if (!isClicked.current || isResizing.current) return;


        //     const nextX = e.clientX - coords.current.startX + coords.current.lastX;
        //     const nextY = e.clientY - coords.current.startY + coords.current.lastY;

        //     leftRef.current = nextX;
        //     topRef.current = nextY;

        //     target.style.left = `${nextX}px`;
        //     target.style.top = `${nextY}px`;
        //     handlePositionChange(nextX, nextY);


        // }

        // target.addEventListener('mousedown', onMouseDown);
        // target.addEventListener('mouseup', onMouseUp);
        // // target.addEventListener('mouseleave', onMouseUp);
        // container.addEventListener('mousemove', onMouseMove);

        // // container.addEventListener('mouseleave', onMouseUp);
        // document.body.addEventListener("mouseup", onMouseUp);

        const cleanup = () => {
            // target.removeEventListener('mousedown', onMouseDown);
            // target.removeEventListener('mouseup', onMouseUp);
            // // target.removeEventListener('mouseleave', onMouseUp);
            // container.removeEventListener('mousemove', onMouseMove);
            // // container.removeEventListener('mouseleave', onMouseUp);
            // document.body.removeEventListener("mouseup", onMouseUp);
        }

        return cleanup;
    }, [id])


    const handlePositionChange = (x, y) => {
        // console.log(typeof onPositionChange)
        // these are relative to the frame window of the ecard 
        onPositionChange(x, y);
        // console.log(`new x = ${x} | new y = ${y}`);
    }

    return (
        <div
            onClick={() => { handleOnClick() }}
            className={classNames}
            id={id}
            style={style}

        // enableResizing={templateModeEditing}
        // disableDragging={true}


        // updateSize={{ width, height }}
        // onResizeStart={() => { if (!templateModeEditing) { return } }}
        // onResizeStop={(e, direction, ref, delta, position) => {
        //     if (!templateModeEditing) { return }
        //     console.log("resizingdddddddddddddddd________", ref.style.width, ref.style.height);
        //     // setWidth(ref.style.width);
        //     // setHeight(ref.style.height);
        //     setWindowWidth(ref.offsetWidth);
        //     setWindowHeight(ref.offsetHeight);
        //     handleWindowResize(ref.offsetWidth, ref.offsetHeight)
        //     // handlePlace()
        // }}
        >
            {/* <div>
            <div ref={exportRef} onDragOver={e => {
                e.dataTransfer.dropEffect = "move";
                e.preventDefault()
            }} onDragEnter={e => {
                e.preventDefault()
            }} onClick={(e) => { handleMainClick(e) }} onMouseUp={(e) => { handleItemMouseUp(e) }}   id="honeyydo-main-div" className={`honeyydo-main-div`} style={{ width: `${windowWidth}px`, height: `${windowHeight}px`, backgroundColor: `${mainDivBackgroundColor}` }}>
                {showBackground ? Object.keys(htmlItems).map(key =>
                    drawItemReact(key, htmlItems[key])
                ) : null}
            </div>

        </div> */}
            <div id={id + "left"} className="resizer resizer-l"></div>
            <div id={id + "top"} className="resizer resizer-t"></div>
            <div id={id + "right"} className="resizer resizer-r"></div>
            <div id={id + "bottom"} className="resizer resizer-b"></div>

            <div id={id + "bottom-left-height"} className="resizer resizer-bl-height"></div>
            <div id={id + "bottom-left-width"} className="resizer resizer-bl-width"></div>

            <div id={id + "bottom-right-height"} className="resizer resizer-br-height"></div>
            <div id={id + "bottom-right-width"} className="resizer resizer-br-width"></div>

            <div id={id + "top-left-height"} className="resizer resizer-tl-height"></div>
            <div id={id + "top-left-width"} className="resizer resizer-tl-width"></div>

            <div id={id + "top-right-height"} className="resizer resizer-tr-height"></div>
            <div id={id + "top-right-width"} className="resizer resizer-tr-width"></div>

            {children}

        </div>

    )

}

export default DraggerResizer;