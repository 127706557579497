import React, { useState, useRef, useCallback, useEffect } from 'react';
import CForm from './components/form';
import Card from './components/card';

import inputConstants from '../../../constants/inputConstants';
import { Link } from 'react-router-dom';

import stripeService from '../../../services/stripeService';
import { UserContext } from '../../../App';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';

import PopupMessage from '../../utility.component/popupMessage.component/popupMessage.component';
import sqlService from "../../../services/sqlService";
import calcUtility from "../../../functions/calcUtility";
import { AiOutlineStop } from "react-icons/ai";
import { MdClear } from "react-icons/md";
import Cookies from "universal-cookie";
import userUtility from '../../../functions/userUtitily';
const initialState = {
    cardNumber: '#### #### #### ####',
    cardHolder: 'FULL NAME',
    cardMonth: '',
    cardYear: '',
    cardCvv: '',
    isCardFlipped: false
};

const initialAddrsesState = {
    address1: '',
    address2: '',
    city: '',
    country: 'United States',
    zip_code: '',
    state: ''
};


const AddPayment = ({ setUser }) => {
    const user = useContext(UserContext);
    const Navigate = useNavigate();
    const params = useParams();
    const [subscriptionType1, setSubscriptionType1] = useState(params?.subscriptionType ?? "");
    const [plan1, setPlan1] = useState(params?.plan ?? "");

    const [state, setState] = useState(initialState);
    const [address, setAddres] = useState(initialAddrsesState);
    const [currentFocusedElm, setCurrentFocusedElm] = useState(null);

    const [startMembershipClicked, setStartMembershiptClicked] = useState(false);

    const [serverMessage, setServerMessage] = useState(null);
    const [duration, setDuration] = useState(null);

    const [couponCode, setCouponCode] = useState(null);
    const [couponInfo, setCouponInfo] = useState(null);

    const [discountPercentage, setDiscountPercentage] = useState(null)
    const [discountDurationInMonths, setDiscountDurationInMonths] = useState(null)

    const loaded = useRef(false);

    const onConfirmServerMessage = () => {
        setServerMessage(null);
    }

    const updateStateValues = useCallback(
        (keyName, value) => {
            setState({
                ...state,
                [keyName]: value || initialState[keyName]
            });
        },
        [state]
    );

    // References for the Form Inputs used to focus corresponding inputs.
    let formFieldsRefObj = {
        cardNumber: useRef(),
        cardHolder: useRef(),
        cardDate: useRef(),
        cardCvv: useRef()
    };

    let focusFormFieldByKey = useCallback((key) => {
        formFieldsRefObj[key].current.focus();
    });

    // This are the references for the Card DIV elements.
    let cardElementsRef = {
        cardNumber: useRef(),
        cardHolder: useRef(),
        cardDate: useRef()
    };

    let onCardFormInputFocus = (_event, inputName) => {
        const refByName = cardElementsRef[inputName];
        setCurrentFocusedElm(refByName);
    };

    let onCardInputBlur = useCallback(() => {
        setCurrentFocusedElm(null);
    }, []);

    const handleAddressChange = (event) => {
        const { name, value } = event.target;

        setAddres({ ...address, [name]: value });
    };

    const getPriceString = (price) => {
        return `$${price}`
    }

    const startMembership = () => {
        setStartMembershiptClicked(true);
        stripeService.chargeOnePayment({ input: { "address": address, "card": state, "plan": plan1, "subscriptionType": subscriptionType1, "couponCode": couponCode } }).then(data => {
            clearCoupon();
            setStartMembershiptClicked(false)
            setServerMessage({
                color: "green",
                message: "You successfully subscribed to Honeyydo Congradulation ",
                title: "Success"
            })
            loaded.current = false;
            setServerMessage(null);
            data["token"] = user.token;
            console.log(data);
            updateCookie(data, { "subscription": subscriptionType1,"created": data?.invoice_object?.created, "orderId": data?.invoice_object?.number, "plan": plan1, "discount": discountPercentage, "price": data?.invoice_object?.subtotal ?? inputConstants.SUBSCRIPTION_PRICES[subscriptionType1][plan1], "priceAfterDiscount": data?.invoice_object?.total ?? calcUtility.getDiscountedValue(discountPercentage, inputConstants.SUBSCRIPTION_PRICES[subscriptionType1] ? inputConstants.SUBSCRIPTION_PRICES[subscriptionType1][plan1] : "") });
        }).catch(err => {
            console.log(err);
            loaded.current = false;
            setStartMembershiptClicked(false)
            setServerMessage({ message: err?.response?.data?.err ?? "Something went wrong", title: "Error" });
            setTimeout(() => {
                setServerMessage(null);
            }, 3000);
        })

    }


    async function updateCookie(data, subscription) {
        document.cookie = `honeyydo_user_invoice=${JSON.stringify({ subscription })}; expires=${new Date(Date.now() + (30 * 86400000))}; path=/; sameSite=strict; secure`;
        document.cookie = `honeyydo_user_cookie=${JSON.stringify({ data })}; expires=${new Date(Date.now() + (30 * 86400000))}; path=/; sameSite=strict; secure`;
        let cookies = document.cookie;
        console.log(cookies);
        console.log(data)
        const cookie = new Cookies();
        const userData = cookie.get("honeyydo_user_cookie");
        console.log(userData)
        setUser(data);
        // debugger
        // document.cookie = await `honeyydo_user_invoice=${JSON.stringify({ subscription })}; expires=${new Date(Date.now() + (30 * 86400000))}; path=/; sameSite=strict; secure`;
        // document.cookie = await `honeyydo_user_cookie=${JSON.stringify({ data })}; expires=${new Date(Date.now() + (30 * 86400000))}; path=/; sameSite=strict; secure`;
        window.location.reload();
        // if (subscriptionType1 === "pro") {
        //     Navigate("/pro_verify");
        // } else {
        //     Navigate("/invoice");
        // }
    }

    const clearCoupon = () => {
        localStorage.removeItem("honeyydo-coupon");
    }

    const applyCouponCode = () => {
        if (couponCode && couponCode !== "") {
            console.log(plan1);
            sqlService.applyCouponCode({ input: { couponCode: couponCode?.trim() } }).then(res => {
                let discount_percentage = res?.discount_percentage;
                let discount_duration_in_months = res?.discount_duration_in_months;
                let duration = res?.duration;
                setPlan1(res?.subscription_plan)
                setDiscountDurationInMonths(discount_duration_in_months)
                setDiscountPercentage(discount_percentage)
                setDuration(duration);
                console.log(discount_percentage, discount_duration_in_months);
                setCouponInfo(`A discount of ${discount_percentage}% ${duration === "repeating" ? "for " + discount_duration_in_months + " months" : duration} is applied`)
            }).catch(err => {
                setCouponInfo(`${err?.response?.data?.err ?? "Something Went Wrong"}`)
            })
        }
    }

    const cancelCoupon = () => {
        setDiscountDurationInMonths(null);
        setDiscountPercentage(null);
        setCouponInfo(null);
        setDuration(null);
        setCouponCode("");
    }

    useEffect(() => {
        console.log(user)
        if (!user || (user?.subscribed === 1) || (user?.role_id === 4 && user?.plan_type === 2 && user?.payment_attached === 1)) {
            // debugger;
            Navigate("/");
        }
    }, []);


    return (
        <div className='display-flex flex-direction flex-wrap justify-content-center align-items-center margin-top-40-px'>


            <div className="payment-wrapper">
                <CForm
                    cardMonth={state.cardMonth}
                    cardYear={state.cardYear}
                    onUpdateState={updateStateValues}
                    cardNumberRef={formFieldsRefObj.cardNumber}
                    cardHolderRef={formFieldsRefObj.cardHolder}
                    cardDateRef={formFieldsRefObj.cardDate}
                    onCardInputFocus={onCardFormInputFocus}
                    onCardInputBlur={onCardInputBlur}
                >
                    <Card
                        cardNumber={state.cardNumber}
                        cardHolder={state.cardHolder}
                        cardMonth={state.cardMonth}
                        cardYear={state.cardYear}
                        cardCvv={state.cardCvv}
                        isCardFlipped={state.isCardFlipped}
                        currentFocusedElm={currentFocusedElm}
                        onCardElementClick={focusFormFieldByKey}
                        cardNumberRef={cardElementsRef.cardNumber}
                        cardHolderRef={cardElementsRef.cardHolder}
                        cardDateRef={cardElementsRef.cardDate}
                    ></Card>
                </CForm>
            </div>

            <div className='padding-top-10-percent'>


                <div className="card-form">
                    <div className="card-list"></div>
                    <div className="card-form__inner_no_padding">
                        <div className="card-input">
                            <label htmlFor="cardName" className="card-input__label">
                                Full Address *
                            </label>
                            <input
                                type="tel"
                                name="address1"
                                className="card-input__input"
                                autoComplete="off"
                                onChange={handleAddressChange}
                                maxLength="50"
                                placeholder='Address Line 1*'
                                value={address.address1}
                            />
                        </div>
                        <div className="card-input">

                            <input
                                type="tel"
                                name="address2"
                                className="card-input__input"
                                autoComplete="off"
                                onChange={handleAddressChange}
                                maxLength="50"
                                placeholder='Address Line 2'
                                value={address.address2}
                            />
                        </div>

                        <div className="card-form__row">
                            <div className="card-form__col -cvv">
                                <div className="card-input">
                                    <input
                                        placeholder='City'
                                        value={address.city}
                                        type="text"
                                        className="card-input__input"
                                        maxLength="50"
                                        autoComplete="off"
                                        name="city"
                                        onChange={handleAddressChange}

                                    />
                                    {/* <div className="flex-1">
                                        <Select options={inputConstants.US_CITIES} onChange={(e) => { handleAddressChange({ "target": { "name": "city", "value": e.value } }) }} />
                                    </div> */}
                                </div>
                            </div>
                            <div className="card-form__col">
                                <div className="card-form__group">

                                    <select
                                        name="state"
                                        className="card-input__input -select"
                                        value={address.state}
                                        onChange={handleAddressChange}

                                    >
                                        {/* <option value="" disabled>
                                            Select State
                                        </option> */}

                                        {address.country !== "Canada" ?
                                            inputConstants?.US_STATES.map((val, index) => (
                                                <option key={index} value={val.value}>
                                                    {val.label}
                                                </option>
                                            )) : null}
                                        {address.country !== "United States" ?
                                            inputConstants?.CANDADIAN_PROVINCES.map((val, index) => (
                                                <option key={index} value={val.value}>
                                                    {val.label}
                                                </option>
                                            )) : null}
                                    </select>

                                    {/* <div className="flex-1">
                                        <Select options={inputConstants.US_STATES} onChange={(e) => { handleAddressChange({ "target": { "name": "state", "value": e.value } }) }} />
                                    </div> */}
                                </div>
                            </div>

                        </div>
                        <div className="card-form__row">
                            <div className="card-form__col -cvv">
                                <div className="card-input">
                                    <input
                                        placeholder='Zip/Postal'
                                        type="text"
                                        className="card-input__input"
                                        maxLength="6"
                                        autoComplete="off"
                                        name="zip_code"
                                        onChange={handleAddressChange}
                                        value={address.zip_code}
                                    />
                                </div>
                            </div>
                            <div className="card-form__col">
                                <div className="card-form__group">

                                    <select
                                        name="country"
                                        className={`card-input__input -select}`}
                                        value={address?.country ?? ""}
                                        onChange={handleAddressChange}

                                    >

                                        <option value="">Select Country</option>
                                        <option key={"countryCanada"} value={"Canada"}>
                                            {"Canada"}
                                        </option>
                                        <option key={"countryUS"} value={"United States"}>
                                            {"United States"}
                                        </option>
                                    </select>

                                    {/* <select
                                        name="country"
                                        className="card-input__input -select"
                                        value={address.country}
                                        onChange={handleAddressChange}
                                    >
                                        <option value="" disabled>
                                            Select Country
                                        </option>
                                        <option value="United State">
                                            United State
                                        </option>
                                        <option value="Canada">
                                            Canada
                                        </option>

                                    </select> */}
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <div className="card-form">
                    {/* <div className="card-list"></div> */}
                    <div className="card-form__inner_no_padding-margin">
                        <div className="">
                            <div className="display-flex flex-wrap justify-content-center margin-10-px column-gap-5-px row-gap-5-px">
                                <input placeholder="Coupon Code" className="pricing-trial-input " type="text" value={couponCode} onChange={(e) => { setCouponCode(e.currentTarget.value) }} disabled={discountPercentage} />
                                {!duration ? <button onClick={() => { if (discountPercentage) { console.log("df"); return } applyCouponCode() }} className="price-card-button1" >Apply Coupon Code</button> : <button onClick={() => { cancelCoupon() }} className="price-card-button2">Cancel Coupon</button>}
                                {couponInfo ? <span className="coupon-info padding-10-px">{couponInfo}</span> : null}
                            </div>
                        </div>
                        <div className='text-align-right div-custome-6 display-flex justify-content-space-around '>
                            {discountPercentage ? <div className='position-relative margin-right-10-px'>
                                <span className='position-absolute top-5-px left-3-px'>
                                    ${inputConstants?.SUBSCRIPTION_PRICES[subscriptionType1] ? inputConstants.SUBSCRIPTION_PRICES[subscriptionType1][plan1] : ""}
                                </span>
                                <span className='position-absolute icon-size-50-px color-transparent-50'>
                                    <MdClear />

                                </span>
                            </div> : null}
                            <span className='float-left font-size-18-px'>{getPriceString(calcUtility.getDiscountedValue(discountPercentage, inputConstants.SUBSCRIPTION_PRICES[subscriptionType1] ? inputConstants.SUBSCRIPTION_PRICES[subscriptionType1][plan1] : "")) + " USD"} / {plan1?.toLocaleUpperCase()} - {subscriptionType1?.toLocaleUpperCase()}</span>

                            <div>
                                <select className='border-radius-5-px bold cursor-pointer font-size-20-px color-1 text-decoration-none' onChange={(e) => { setPlan1(e.currentTarget.value) }} value={plan1}>
                                    <option value={"monthly"}>Monthly</option>
                                    <option value={"yearly"}>Yearly</option>
                                </select>
                            </div>

                        </div>
                        <span>By clicking the “Start Membership” button below, you agree to our <Link to={"/terms-of-service"}><span className='bold blue cursor-pointer'>Terms of Use</span></Link>, <Link to={"/privacy-policy"}><span className='bold blue cursor-pointer'>Privacy Statement</span></Link>, that you are over 18, and that Honeyydo will automatically continue your membership and charge the membership fee (currently <span>{getPriceString(inputConstants.SUBSCRIPTION_PRICES[subscriptionType1] ? inputConstants.SUBSCRIPTION_PRICES[subscriptionType1][plan1] : "") + " USD"}</span>) to your payment method until you cancel.</span>
                        {startMembershipClicked ?
                            <Button className="card-form__button" variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className=""> Loading...</span>
                            </Button> :
                            <button onClick={() => { startMembership() }} className="card-form__button">Start Membership</button>
                        }
                    </div>
                </div>

            </div>
            <PopupMessage onConfirm={onConfirmServerMessage} message={serverMessage?.message} title={serverMessage?.title} show={serverMessage !== null} />
        </div>
    );
};

export default AddPayment;
