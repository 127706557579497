import React from "react";
import "./adminDashboard.css/adminPopup.component.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { useState, useContext } from "react";
import { UserContext } from "../../../App";
import validateUtility from "../../../functions/validateUtility";
import inputConstants from "../../../constants/inputConstants";
import PopupMessage from "../../utility.component/popupMessage.component/popupMessage.component";

export default function AdminPopup({
  tableName,
  onClose,
  item,
  columns,
  editFunction,
  setRefreshData,
}) {
  const user = useContext(UserContext);
  const [changes, setChanges] = useState(item);
  const [serverMessage, setServerMessage] = useState(null);

  const handleChange = (event) => {
    setChanges({
      ...changes,
      [event.target.name]: event.target.value,
    });
  };

  const handleEdit = async () => {
    try {
      await editFunction({ user: user, input: { changes: changes, old_link: item?.img_link ?? null } });
      console.log("Edit successful");
      setRefreshData((refresh) => !refresh);
      onClose();
    } catch (err) {
      console.error("Error editing item", err);
    }
  };

  const handleImgUpload = (event, key) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    console.log(file.size)
    if(!validateUtility.validFileSize(file)){
      setServerMessage({ "message": `File size must be less than ${inputConstants.MAX_FILE_SIZE/1000000}mb`, "title": "Error" })
      return;
    }

    reader.onload = () => {
      let data = reader.result;
      let t = file.type;
      let type = t.split("/")[1]
      let fileObject = { "imgFile": data, "imgType": type, "imgKey": "img_link", "imgSubfolder": "content/Library" }
      setChanges({ ...changes, "img_link": fileObject });


    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  return (
    <>
      <div className="backdrop"></div>
      <div className="admin-popup row-gap-10-px">
        <div className="admin-popup-title">
          <h3> Edit {tableName}</h3>
          <FontAwesomeIcon
            className="admin-popup-close"
            icon={faCircleXmark}
            onClick={onClose}
          />
        </div>
        {columns.map((column, index) => (
          <>
            {column.field === 'content' ?
              <textarea
                key={index}
                name={column.field}
                value={changes[column.field] || ""}
                placeholder={column.header}
                onChange={handleChange}
                className="admin-popup-textarea"
              ></textarea>
              : column.field === "img_link" ?
                <input type="file" id="imageUpload" accept="image/*" onChange={(e) => handleImgUpload(e, "img_link")} />
                : <input

                  type="text"
                  className="admin-popup-input"
                  defaultValue={changes[column.field]}
                  name={column.field}
                  onChange={handleChange}
                />}
          </>
        ))}
        <div className="admin-popup-button-containers">
          <button className="admin-popup-submit-button" onClick={handleEdit}>
            Edit
          </button>
          <button className="admin-popup-cancel-button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
      <PopupMessage onConfirm={()=>{setServerMessage(null)}} message={serverMessage?.message} title={serverMessage?.title} show={serverMessage !== null} />
    </>
  );
}
