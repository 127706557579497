import React from "react";


function Error404(){
    return (
        <div className="padding-90-px">
            Page not found
        </div>

    )
}

export default Error404;