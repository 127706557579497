import React from "react";
import { useState, useEffect } from "react";
import "./pricing.component.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie, faHouse, faUsers, faPaperPlane, faBuildingUser } from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, EffectFade } from "swiper";

import dwellImg from "../../../assets/images/company-logos/dwell.png";
import limbirdImg from "../../../assets/images/company-logos/limbard3.jpg";
import jparImg from "../../../assets/images/company-logos/JPAR.png";
import reachImg from "../../../assets/images/company-logos/reach.jpg";
import stratumImg from "../../../assets/images/company-logos/stratum.jpg";
import exp from "../../../assets/images/company-logos/exp.png";
import coldwellBanker from "../../../assets/images/company-logos/coldwellBanker.jpg";
import exitRealty from "../../../assets/images/company-logos/exitRealty.png";
import kw from "../../../assets/images/company-logos/KW.jpg";
import compass from "../../../assets/images/company-logos/compass.png";
import redfin from "../../../assets/images/company-logos/redfin.png";
import berkshirehathaway from "../../../assets/images/company-logos/berkshire-hathaway.jpg";
import sothebys from "../../../assets/images/company-logos/sothebys.jpg";


import ScrollToTop from "../../utility.component/scrollToTop.component";
import { Link } from 'react-router-dom';
import signupConstants from "../../../constants/signupConstants";
import inputConstants from "../../../constants/inputConstants";
import { useContext } from "react";
import { UserContext } from "../../../App";
import userUtility from "../../../functions/userUtitily";
import { useNavigate } from "react-router-dom";


export default function Pricing() {
  const user = useContext(UserContext);
  const brandLogos = [jparImg, dwellImg, limbirdImg, reachImg, stratumImg, exp, coldwellBanker, exitRealty, kw, compass, redfin, berkshirehathaway, sothebys];

  const Navigate = useNavigate();

  const [dropdown, setDropdown] = useState(false);

  const [plan, setPlan] = useState("monthly");
  const monthTerm = "PER MONTH";
  const yearTerm = "PER YEAR";

  const [rotateToBack, setRotateToBack] = useState("false");

  const [questionForm, setQuestionForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    question: "",
  });

  const [questionPopUp, setQuestionPopUp] = useState(false);

  const openPopUp = () => {
    setQuestionPopUp(true);
  };

  const closePopUp = () => {
    setQuestionPopUp(false);
  };

  const handleChange = (event) => {
    setQuestionForm({
      ...questionForm,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`http://localhost:8080/mail/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(questionForm), // The form data
      });

      if (response.ok) {
        openPopUp();
        setQuestionForm({
          firstName: "",
          lastName: "",
          email: "",
          question: "",
        });
      } else {
        console.error("Error sending email:", response);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  const [windowSize, setWindowSize] = React.useState(window.innerWidth);

  useEffect(() => {
    if (user) {
      let userRoute = userUtility.getUserRoute(user)
      let currentPath = window.location.pathname;
      if (!currentPath.endsWith(userRoute)) {
        Navigate(userRoute);
      }
    }

    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  const onWindowResize = () => {
    setWindowSize(window.innerWidth);
  };

  const toggleDropdown = () => {
    console.log("drop", dropdown)
    setDropdown(dropdown => !dropdown);
  }

  const getPaymenLink = (roleId) => {
    switch (roleId) {
      case 2:
        return '/add-payment/agent/'
      case 5:
        return '/add-payment/mortgage_agent/'
      case 6:
        return '/add-payment/insurance_agent/'
      default: return ''
    }
  }

  const getName = (roleId) => {
    switch (roleId) {
      case 2:
        return 'Agent'
      case 5:
        return 'Mortgage Agent'
      case 6:
        return 'Insurance Agent'
      default:
        return 'Agent'
    }
  }

  const getPriceMonthly = (roleId) => {
    switch (roleId) {
      case 2:
        return inputConstants.SUBSCRIPTION_PRICES.agent.monthly
      case 5:
        return inputConstants.SUBSCRIPTION_PRICES.mortgage_agent.monthly
      case 6:
        return inputConstants.SUBSCRIPTION_PRICES.insurance_agent.monthly
      default:
        return inputConstants.SUBSCRIPTION_PRICES.agent.monthly
    }
  }

  const getPriceYearly = (roleId) => {
    switch (roleId) {
      case 2:
        return inputConstants.SUBSCRIPTION_PRICES.agent.yearly
      case 5:
        return inputConstants.SUBSCRIPTION_PRICES.mortgage_agent.yearly
      case 6:
        return inputConstants.SUBSCRIPTION_PRICES.insurance_agent.yearly
      default:
        return inputConstants.SUBSCRIPTION_PRICES.agent.yearly
    }
  }

  return (
    <>
      <div className="margin-bottom-50-px">
        <div className="pricing-title-container">
          <span className="pricing-title grey">Honeyydo Pricing</span>
        </div>
        <div className="toggle-buttons-container">
          <div className="toggle-buttons">
            <button
              className={`toggle-button ${plan === "monthly" ? "toggle-button-selected" : ""
                }`}
              id="month"
              onClick={() => setPlan("monthly")}
            >
              Monthly
            </button>
            <button
              className={`toggle-button ${plan === "yearly" ? "toggle-button-selected" : ""
                }`}
              id="year"
              onClick={() => setPlan("yearly")}
            >
              Yearly
            </button>
          </div>
        </div>
        {windowSize > 750 ? (
          <div className="price-cards-container">
            {(user && (user.role_id === 2 || user.role_id === 5 || user.role_id === 6)) || !user ?


              <div class={`flip-card`} onClick={() => { setRotateToBack(rotateToBack === "true" ? "false" : "true") }}>
                <div class={`flip-card-inner ${rotateToBack === "true" ? "rotate-to-back" : ""}`}>
                  <div class={`flip-card-back rotate-to-front`}>
                    <span className="bold margin-top-30-px margin-bottom-10-px">
                      Select an Agent Type
                    </span>
                    <Link className="login-option" to={signupConstants.AGENT}><div>Realtor</div></Link>
                    {/* <Link onClick={toggleDropdown} className="login-option" to={"/pro-info"}><div>Join as a Pro</div></Link> */}
                    <Link className="login-option" to={signupConstants.MORTGAGE_AGENT}><div>Mortgage Agent</div></Link>
                    <Link className="login-option" to={signupConstants.INSURANCE_AGENT}><div>Insurance Agent</div></Link>
                  </div>
                  <div class="flip-card-front" id="agent">

                    {/* <div className="price-card" id="agent"> */}
                    <FontAwesomeIcon className="agent-icon" icon={faUserTie} />
                    <span className="price-card-title grey">{getName(user?.role_id)}</span>
                    <span className="price-card-price">
                      ${plan === "monthly" ? getPriceMonthly(user?.role_id) : getPriceYearly(user?.role_id)}.00<span style={{ fontSize: '0.5em', color: '#aaa5a5' }}> USD</span>
                    </span>
                    <span className="price-card-term">
                      {plan === "monthly" ? monthTerm : yearTerm}
                    </span>
                    {user ?
                      <Link
                        to={`${getPaymenLink(user?.role_id)}${plan}`}
                        className="price-card-button"
                        id="homeowner-button"
                      >
                        Continue Signup
                      </Link>
                      :
                      <>
                        <Link
                          // onClick={() => { setRotateToBack(true); }}
                          className="login-button price-card-button"
                          id="homeowner-button"
                        >
                          Sign Up
                        </Link>
                        {dropdown && (
                          <div className="login-dropdown right-10px">

                          </div>
                        )}
                      </>
                    }

                    <span className="price-card-list-title">Includes:</span>
                    <div className="price-card-list-container">
                      <ul
                        className="price-card-list"
                        style={{ listStyleType: "disc" }}
                      >
                        <li>Client Engagement</li>
                        <li>Advanced CRM</li>
                        <li>Lead Generation Tools</li>
                        <li>Agent Branding</li>
                        <li>Reviews & Referrals</li>
                        <li>Ecards & Gifts</li>
                      </ul>
                    </div>
                    {/* </div> */}
                  </div>

                </div>
              </div>

              : null}

            {(user && user.role_id === 3) || !user ?

              <div className="price-card" id="brokerage">
                <FontAwesomeIcon className="brokerage-icon" icon={faUsers} />
                <span className="price-card-title grey">Brokerage</span>
                <span className="price-card-price">
                </span>
                <span className="price-card-term association-price-card-info">
                  Pricing available on request
                </span>
                <Link
                  to={`/request-a-quote`}
                  className="price-card-button"
                  id="homeowner-button"
                >
                  Request a Quote
                </Link>
                <span className="price-card-list-title">Includes:</span>
                <div className="price-card-list-container">
                  <ul
                    className="price-card-list"
                    style={{ listStyleType: "disc" }}
                  >
                    <li>Agent Oversight</li>
                    <li>Performance Insights</li>
                    <li>Reputation Tracker</li>
                    <li>Manage your Agents</li>
                    <li>Personalized Branding</li>
                    <li>Premium Support</li>
                  </ul>
                </div>
              </div>

              : null}

            {(user && user.role_id === 4) || !user ?
              <div className="price-card" id="association">
                <FontAwesomeIcon className="association-icon" icon={faBuildingUser} />
                <span className="price-card-title grey">Association</span>
                <span className="price-card-price">

                </span>
                <span className="price-card-term association-price-card-info">
                  Pricing available on request
                </span>
                <Link
                  to={`/request-a-quote`}
                  className="price-card-button"
                  id="homeowner-button"
                >
                  Request a Quote
                </Link>
                <span className="price-card-list-title">Includes:</span>
                <div className="price-card-list-container">
                  <ul className="price-card-list" style={{ listStyleType: "disc" }}>
                    <li>Benefits for members</li>
                    <li>Premium Support</li>
                    <li>Customized Features</li>
                  </ul>
                </div>
              </div>
              : null}


          </div>
        ) : (
          <div className="mobile-price-cards-container">
            {/* {(user && user.role_id === 1) || !user ?
              <div className="price-card" id="homeowner">
                <FontAwesomeIcon className="homeowner-icon" icon={faHouse} />
                <span className="price-card-title">Homeowner</span>
                <span className="price-card-price">
                  {plan === "monthly" ? inputConstants.SUBSCRIPTION_PRICES.homeowner.monthly : inputConstants.SUBSCRIPTION_PRICES.homeowner.yearly}
                </span>
                <span className="price-card-term">
                  {plan === "monthly" ? monthTerm : yearTerm}
                </span>
                <Link
                  to={user && user?.role_id === 1 ? `/add-payment/homeowner/${plan}` : signupConstants.HOMEOWNER}
                  className="price-card-button"
                  id="homeowner-button"
                >
                  Sign Up
                </Link>
                <span className="price-card-list-title">Includes:</span>
                <div className="price-card-list-container">
                  <ul
                    className="price-card-list"
                    style={{ listStyleType: "disc" }}
                  >
                    <li>Home Maintenance List</li>
                    <li>Virtual File Cabinet</li>
                    <li>Find Local Pros</li>
                    <li>Invite a friend</li>
                  </ul>
                </div>
              </div>
              : null} */}

            {(user && (user.role_id === 2 || user.role_id === 5 || user.role_id === 6)) || !user ?


              <div class={`flip-card`} onClick={() => { setRotateToBack(rotateToBack === "true" ? "false" : "true") }}>
                <div class={`flip-card-inner ${(rotateToBack === "true" && !user) ? "rotate-to-back" : ""}`}>
                  <div class={`flip-card-back rotate-to-front`}>
                    <span className="bold margin-top-30-px margin-bottom-10-px">
                      Select an Agent Type
                    </span>
                    <Link onClick={toggleDropdown} className="login-option" to={signupConstants.AGENT}><div>Realtor</div></Link>
                    {/* <Link onClick={toggleDropdown} className="login-option" to={"/pro-info"}><div>Join as a Pro</div></Link> */}
                    <Link onClick={toggleDropdown} className="login-option" to={signupConstants.MORTGAGE_AGENT}><div>Mortgage Agent</div></Link>
                    <Link onClick={toggleDropdown} className="login-option" to={signupConstants.INSURANCE_AGENT}><div>Insurance Agent</div></Link>
                  </div>
                  <div class="flip-card-front" id="agent">

                    {/* <div className="price-card" id="agent"> */}
                    <FontAwesomeIcon className="agent-icon" icon={faUserTie} />
                    <span className="price-card-title grey">{getName(user?.role_id)}</span>
                    <span className="price-card-price">
                      ${plan === "monthly" ? getPriceMonthly(user?.role_id) : getPriceYearly(user?.role_id)}.00<span style={{ fontSize: '0.5em', color: '#aaa5a5' }}> USD</span>
                    </span>
                    <span className="price-card-term">
                      {plan === "monthly" ? monthTerm : yearTerm}
                    </span>
                    {user ?
                      <Link
                        to={`${getPaymenLink(user?.role_id)}${plan}`}
                        className="price-card-button"
                        id="homeowner-button"
                      >
                        Continue Signup
                      </Link>
                      :
                      <>
                        <Link
                          // onClick={() => { setRotateToBack(true); }}
                          className="login-button price-card-button"
                          id="homeowner-button"
                        >
                          Sign Up
                        </Link>
                        {dropdown && (
                          <div className="login-dropdown right-10px">

                          </div>
                        )}
                      </>
                    }

                    <span className="price-card-list-title">Includes:</span>
                    <div className="price-card-list-container">
                      <ul
                        className="price-card-list"
                        style={{ listStyleType: "disc" }}
                      >
                        <li>Client Engagement</li>
                        <li>Advanced CRM</li>
                        <li>Lead Generation Tools</li>
                        <li>Agent Branding</li>
                        <li>Reviews & Referrals</li>
                        <li>Ecards & Gifts</li>
                      </ul>
                    </div>
                    {/* </div> */}
                  </div>

                </div>
              </div>

              : null}

            {(user && user.role_id === 3) || !user ?

              <div className="price-card" id="brokerage">
                <FontAwesomeIcon className="brokerage-icon" icon={faUsers} />
                <span className="price-card-title grey">Brokerage</span>
                <span className="price-card-price">
                </span>
                <span className="price-card-term association-price-card-info">
                  Pricing available on request
                </span>
                <Link
                  to={`/request-a-quote`}
                  className="price-card-button"
                  id="homeowner-button"
                >
                  Request a Quote
                </Link>
                <span className="price-card-list-title">Includes:</span>
                <div className="price-card-list-container">
                  <ul
                    className="price-card-list"
                    style={{ listStyleType: "disc" }}
                  >
                    <li>Agent Oversight</li>
                    <li>Performance Insights</li>
                    <li>Reputation Tracker</li>
                    <li>Manage your Agents</li>
                    <li>Personalized Branding</li>
                    <li>Premium Support</li>
                  </ul>
                </div>
              </div>

              : null}

            {(user && user.role_id === 4) || !user ?
              <div className="price-card" id="association">
                <FontAwesomeIcon className="association-icon" icon={faBuildingUser} />
                <span className="price-card-title grey">Association</span>
                <span className="price-card-price">

                </span>
                <span className="price-card-term association-price-card-info">
                  Request a quote
                </span>
                <Link
                  to={`/contact`}
                  className="price-card-button"
                  id="homeowner-button"
                >
                  Contact Us
                </Link>
                <span className="price-card-list-title">Includes:</span>
                <div className="price-card-list-container">
                  <ul className="price-card-list" style={{ listStyleType: "disc" }}>
                    <li>Benefits for members</li>
                    <li>Premium Support</li>
                    <li>Customized Features</li>
                  </ul>
                </div>
              </div>
              : null}

            {(user && user?.role_id === 5) ? <div className="price-card" id="agent">
              <FontAwesomeIcon className="agent-icon" icon={faUserTie} />
              <span className="price-card-title grey">Mortgage Agent</span>
              <span className="price-card-price">
                ${plan === "monthly" ? inputConstants.SUBSCRIPTION_PRICES.mortgage_agent.monthly : inputConstants.SUBSCRIPTION_PRICES.mortgage_agent.yearly}.00<span style={{ fontSize: '0.5em', color: '#aaa5a5' }}> USD</span>
              </span>
              <span className="price-card-term">
                {plan === "monthly" ? monthTerm : yearTerm}
              </span>
              <Link
                to={user && user?.role_id === 5 ? `/add-payment/insurance_agent/${plan}` : signupConstants.MORTGAGE_AGENT}
                className="price-card-button"
                id="homeowner-button"
              >
                Sign Up
              </Link>
              <span className="price-card-list-title">Includes:</span>
              <div className="price-card-list-container">
                <ul
                  className="price-card-list"
                  style={{ listStyleType: "disc" }}
                >
                  <li>Client Engagement</li>
                  <li>Advanced CRM</li>
                  <li>Lead Generation Tools</li>
                  <li>Agent Branding</li>
                  <li>Reviews & Referrals</li>
                  <li>Ecards & Gifts</li>
                </ul>
              </div>
            </div> : null}



            {(user && user?.role_id === 6) ? <div className="price-card" id="agent">
              <FontAwesomeIcon className="agent-icon" icon={faUserTie} />
              <span className="price-card-title grey">Insurance Agent</span>
              <span className="price-card-price">
                ${plan === "monthly" ? inputConstants.SUBSCRIPTION_PRICES.insurance_agent.monthly : inputConstants.SUBSCRIPTION_PRICES.insurance_agent.yearly}.00<span style={{ fontSize: '0.5em', color: '#aaa5a5' }}> USD</span>
              </span>
              <span className="price-card-term">
                {plan === "monthly" ? monthTerm : yearTerm}
              </span>
              <Link
                to={user && user?.role_id === 6 ? `/add-payment/insurance_agent/${plan}` : signupConstants.INSURANCE_AGENT}
                className="price-card-button"
                id="homeowner-button"
              >
                Sign Up
              </Link>
              <span className="price-card-list-title">Includes:</span>
              <div className="price-card-list-container">
                <ul
                  className="price-card-list"
                  style={{ listStyleType: "disc" }}
                >
                  <li>Client Engagement</li>
                  <li>Advanced CRM</li>
                  <li>Lead Generation Tools</li>
                  <li>Agent Branding</li>
                  <li>Reviews & Referrals</li>
                  <li>Ecards & Gifts</li>
                </ul>
              </div>
            </div> : null}

          </div>
        )}
        <div className="trusted-by-container">
          <span className="pricing-trust-by-title">Trusted by:</span>
          <div className="pricing-Swiper-Wrapper">
            <Swiper
              slidesPerView={1}
              pagination={{
                clickable: true,
              }}
              loop={true}
              loopPreventsSliding={true}
              loopedSlides={brandLogos.length - 3}
              autoplay={{
                delay: 6000,
                disableOnInteraction: true,
              }}
              modules={[Pagination, Autoplay]}
              breakpoints={{
                375: {
                  spaceBetween: 50,
                  slidesPerView: 2
              },
              550: {
                  spaceBetween: 75,
                  slidesPerView: 2
              },
              750: {
                  spaceBetween: 75,
                  slidesPerView: 3
              },
              1200: {
                  spaceBetween: 150,
                  slidesPerView: 3

              },
              1600: {
                  spaceBetween: 250,
                  slidesPerView: 3

              }
              }}
              className=" "
            >
              {brandLogos.map((logo, index) => (
                <SwiperSlide key={index}>
                  <div className="pricing-swipe-slide" >
                    <img src={logo} style={{ width: "100%" }} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        {/* {windowSize > 850 ? (
          <form
            className="pricing-contact-us-container"
            onSubmit={handleSubmit}
          >
            <div className="pricing-contact-us">
              <span className="pricing-question-title">Question?</span>
              <label className="pricing-name-input-container">
                <div className="pricing-names-input-container">
                  <input
                    className="pricing-name-input"
                    id="first-name"
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={questionForm.firstName}
                    onChange={handleChange}
                  />
                  <input
                    className="pricing-name-input"
                    id="last-name"
                    type="text"
                    name="lastName"
                    value={questionForm.lastName}
                    placeholder="Last Name"
                    onChange={handleChange}
                  />
                </div>
                <div className="pricing-email-button-container">
                  <div className="pricing-email-button-container">
                    <input
                      className="pricing-email-input"
                      type="text"
                      name="email"
                      value={questionForm.email}
                      placeholder="Email"
                      onChange={handleChange}
                    />
                    <textarea
                      className="pricing-question-input"
                      type="text"
                      name="question"
                      value={questionForm.question}
                      placeholder="Type your question here..."
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </label>
              <button className="pricing-question-button" type="submit">
                Submit
              </button>
            </div>
          </form>
        ) : (
          <form
            className="pricing-contact-us-container"
            onSubmit={handleSubmit}
          >
            <div className="pricing-contact-us">
              <span className="pricing-question-title">Question?</span>
              <label className="mobile-pricing-name-input-container">
                <input
                  className="mobile-pricing-name-input"
                  id="mobile-first-name"
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={questionForm.firstName}
                  onChange={handleChange}
                />
                <input
                  className="mobile-pricing-name-input"
                  id="mobile-last-name"
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={questionForm.lastName}
                  onChange={handleChange}
                />
              </label>
              <label className="pricing-email-button-container">
                <div className="pricing-email-button-container">
                  <input
                    className="mobile-pricing-email-input"
                    type="email"
                    name="email"
                    placeholder="Enter your email address"
                    value={questionForm.email}
                    onChange={handleChange}
                  />
                  <textarea
                    className="mobile-pricing-question-input"
                    name="question"
                    placeholder="Type your question here.."
                    value={questionForm.question}
                    onChange={handleChange}
                  ></textarea>
                  <button className="pricing-question-button">Submit</button>
                </div>
              </label>
            </div>
          </form>
        )} */}
        <ScrollToTop />
      </div>
      {questionPopUp && (
        <>
          <div className="backdrop">
            <div className="question-popup">
              <FontAwesomeIcon
                className="paperplane-icon"
                icon={faPaperPlane}
              />
              <h2>Thank You!</h2>
              <span>We'll get back to you shortly.</span>
              <button className="ThankYou-Close-Button" onClick={closePopUp}>
                Close
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
