import React, { useState, useEffect, useContext, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { ModalBody } from "react-bootstrap";
import sqlService from "../../../services/sqlService";
import { UserContext } from "../../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faShare } from "@fortawesome/free-solid-svg-icons";
import { Button, Spinner } from "react-bootstrap";


import * as xlsx from 'xlsx';
import {

    faPaperclip,
    faFileImport,
    faFileCsv,
    faFileExcel

} from "@fortawesome/free-solid-svg-icons";
import PopupMessage from "../popupMessage.component/popupMessage.component";
import validateUtility from "../../../functions/validateUtility";
import inputConstants from "../../../constants/inputConstants";

function ImportButton({ importButtonStyle, buttonText, importType, importMenu, setImportMenu, openImportMenu, onImport, country }) {

    const proExcelSheet = "https://honeyydo.s3.us-east-2.amazonaws.com/content/spreadsheet/import_templates/Pros_Template_America.xlsx";
    const proCsv = "https://honeyydo.s3.us-east-2.amazonaws.com/content/spreadsheet/import_templates/Pros_Template_America.csv";
    const agentExcelSpreadsheet = "https://honeyydo.s3.us-east-2.amazonaws.com/content/spreadsheet/import_templates/Agents_Template_America.xlsx";
    const agentsCsv = "https://honeyydo.s3.us-east-2.amazonaws.com/content/spreadsheet/import_templates/Agents_Template_America.csv";

    const proExcelSheetCanada = "https://honeyydo.s3.us-east-2.amazonaws.com/content/import_templates/Pros_Template_Canada.xlsx";
    const proCsvCanada = "https://honeyydo.s3.us-east-2.amazonaws.com/content/spreadsheet/import_templates/Pros_Template_Canada.csv";
    const agentExcelSpreadsheetCanada = "https://honeyydo.s3.us-east-2.amazonaws.com/content/spreadsheet/import_templates/Agents_Template_Canada.xlsx";
    const agentsCsvCanada = "https://honeyydo.s3.us-east-2.amazonaws.com/content/spreadsheet/import_templates/Agents_Template_Canada.csv";

    const [spreadsheet, setSpreadsheet] = useState(null);
    const [spreadsheetName, setSpreadsheetName] = useState(null);
    const [spreadsheetType, setSpreadsheetType] = useState(null);
    const [incorrectFormat, setIncorrectFormat] = useState(false)
    const spreadsheetFile = useRef(null);

    const [uploadingImport, setUploadingImport] = useState(false);
    const user = useContext(UserContext);

    const loaded = React.useRef(false);
    const [refresh, setRefresh] = useState(false);

    const [makeUsDoIt, setMakeUsDoIt] = useState(false);

    const [userFileUpload, setUserFileUpload] = useState(false);

    const [serverMessage1, setServerMessage1] = useState({
        color: "none",
        message: "",
    });
    const [serverMessage, setServerMessage] = useState(null);

    const closeImportMenu = () => {
        setImportMenu(false)
        setSpreadsheetName(null)
        setSpreadsheet(null)

    }

    const handleSpreadsheetUpload = () => {
        spreadsheetFile.current.click();
    }

    const onDownloadExcel = () => {
        const excelPro = country === "Canada" ? proExcelSheetCanada : proExcelSheet;
        const excelAgent = country === "Canada" ? agentExcelSpreadsheetCanada : agentExcelSpreadsheet;
        const pdfUrl = importType === "pros" ? excelPro : excelAgent;
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = `Honeyydo ${importType === "homeowners" ? "Homeowners" : importType === "pros" ? "Pros" : importType === "agents" ? "Agents" : ""} spreadsheet.xlsx`; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const onDownloadCsv = () => {
        const csvPro = country === "Canada" ? proCsvCanada : proCsv;
        const csvAgent = country === "Canada" ? agentsCsvCanada : agentsCsv;
        const pdfUrl = importType === "pros" ? csvPro : csvAgent;
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = `Honeyydo ${importType === "homeowners" ? "Homeowners" : importType === "pros" ? "Pros" : importType === "agents" ? "Agents" : ""} csv.csv`; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };



    const handleSpreadsheetImport = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        console.log(file.size)
        if (!validateUtility.validFileSize(file)) {
            setServerMessage({ "message": `File size must be less than ${inputConstants.MAX_FILE_SIZE / 1000000}mb`, "title": "Error" })
            return;
        }

        reader.onload = () => {

            let data = reader.result;

            let t = file.type;
            setSpreadsheetName(file.name)
            let type = t.split("/")[1];

            console.log(type)
            setSpreadsheetType(type)
            if (type.toLowerCase() !== 'csv' && type !== 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') {

                setServerMessage1({
                    message: "File format must be .xlsx or .csv",
                });
                setTimeout(() => {
                    setServerMessage({ color: "none", message: "" });
                }, 3000);
                return;
            }

            const workbook = xlsx.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = xlsx.utils.sheet_to_json(worksheet);
            if (json[0]) {
                // if (!("Email" in json[0]) || !("Contact Name" in json[0]) || (!("State" in json[0]) && !("Province" in json[0])) || (!("Postal Code" in json[0]) && !("Zip Code" in json[0])) || !("Address" in json[0]) || !("City" in json[0]) || (importType === "pros" && !("Industry" in json[0])) || !("Country" in json[0])) {
                if (!("Email" in json[0]) || !("Contact Name" in json[0])) {
                    setServerMessage1({
                        message: "Please ensure your spreadsheet contains the correct format, look at the example spreadsheets that we have.",
                    });
                    setTimeout(() => {
                        setServerMessage1({ color: "none", message: "" });
                    }, 3000);
                    setIncorrectFormat(true);
                    return;
                } else {
                    setIncorrectFormat(false)
                }
            } else {
                console.log("no spreadsheet")
            }
            setSpreadsheet(json)

        };

        if (file) {
            reader.readAsArrayBuffer(event.target.files[0]);
        }

    }


    const handleSpreadsheetImportUser = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        console.log(file.size)
        if (!validateUtility.validFileSize(file)) {
            setServerMessage({ "message": `File size must be less than ${inputConstants.MAX_FILE_SIZE / 1000000}mb`, "title": "Error" })
            return;
        }

        reader.onload = () => {

            let data = reader.result;
            let t = file.type;
            let type = t.split("/")[1];
            console.log(data);
            // setLogo(reader.result);
            //handleChange("logo_link",  data)
            var importTypeG = "";
            if (importType === "pros") {
                importTypeG = "professional"
            } else if (importType === "homeowners") {
                importTypeG = "homeowner"
            } else if (importType === "agents") {
                importTypeG = "agent"
            }
            console.log(`spreadsheet/client/${importTypeG}/${country === "Canada" ? "canada" : "america"}/${type === "csv" ? "csv" : "xlsx"}`);
            sqlService.uploadFile({ input: { "file": data, "type": type, "subfolder": `content/spreadsheet/client/${importTypeG}/${country === "Canada" ? "canada" : "america"}/${type === "csv" ? "csv" : "xlsx"}` } }).then(d => {
                let link = d.link
                console.log(link);
                sqlService.addNewSpreadSheetRequest({ input: { "link": link, "import_type": importTypeG } }).then(d => {
                    setMakeUsDoIt(true);
                }).catch(err => {
                    console.log(err)
                })
            }).catch(err => {
                console.log(err);
                setServerMessage1({
                    message: "Your file was not uploaded correctly, someting went wrong",
                });
                setTimeout(() => {
                    setServerMessage1({ color: "none", message: "" });
                }, 5000);
            });

        };

        if (file) {
            reader.readAsDataURL(event.target.files[0])
        }

    }


    const handleUserFileUpload = () => {
        let inputUserFile = document.getElementById("user-file-upload-input")
        if (inputUserFile) {
            inputUserFile.click();
        }
    }



    async function submitUpload() {
        if (spreadsheetType !== 'vnd.openxmlformats-officedocument.spreadsheetml.sheet' && spreadsheetType?.toLowerCase() !== "csv") {
            setServerMessage1({
                message: "File format must be .xlsx or .csv",
            });
            setTimeout(() => {
                setServerMessage1({ color: "none", message: "" });
            }, 3000);
            return;
        }
        if (incorrectFormat) {

            setServerMessage1({
                message: "Please ensure your spreadsheet contains Email and Contact Name in the correct format",
            });
            setTimeout(() => {
                setServerMessage1({ color: "none", message: "" });
            }, 3000);
            return;
        }

        setUploadingImport(true);
        console.log("spreadsheet ", spreadsheet)
        await sqlService.importSpreadsheet({ "input": { "spreadsheet": spreadsheet, "dataType": importType === "homeowners" ? "homeowner" : importType === "pros" ? "pro" : importType === "agents" ? "agent" : "" } }).then(data => {
            let prosAdded = data?.data?.added;
            let skippedUser = data?.data?.skipped;
            loaded.current = false;
            setRefresh(!refresh);

            setServerMessage1({
                color: "green",
                message: `${prosAdded} ${importType === "homeowners" ? "Homeowner" : importType === "pros" ? "Pro" : importType === "agents" ? "Agent" : ""}${prosAdded !== 1 ? "s" : ""} Imported Successfully \n ${skippedUser && Object.keys(skippedUser).length ?
                    "The Following users were not imported successfully because they either have dublicated emails within the existing users or they don't have and email associated with them:  " : ""}
                ${skippedUser && Object.keys(skippedUser).length ? skippedUser.map(item => `${Object.entries(item).map(([key, value]) => `${key}: ${value}`)}\n------------------`) : ""}`,
            });
            setUploadingImport(false);
            // setTimeout(() => {
            //     setServerMessage1({ color: "none", message: "" });
            // }, 3000);
            setSpreadsheet(null);
            setSpreadsheetName(null);
            setSpreadsheetType(null);
            document.getElementById('honeydo-file-input-manage-imports').value = null;
            onImport();
        }).catch(err => {
            setUploadingImport(false);
            setSpreadsheet(null);
            setSpreadsheetName(null);
            setSpreadsheetType(null);
            document.getElementById('honeydo-file-input-manage-imports').value = null;
            console.log(err);
        })

    }

    const loaded2 = React.useRef(false);
    const [INDUSTRIES, setINDUSTRIES] = useState([]);


    useEffect(() => {
        if (!loaded2.current) {
            sqlService
                .getIndustryNames()
                .then((data) => {
                    console.log(data);
                    const INDUSTRIES = [];
                    for (let i = 0; i < data.data.length; i++) {
                        INDUSTRIES[i] = data.data[i].type_name;
                    }
                    setINDUSTRIES(INDUSTRIES);
                })
                .catch((err) => {
                    console.log("Didn't get industry names");
                    console.log(err);
                });
            loaded2.current = true;
        }
    }, []);

    return (
        <>

            <button className={importButtonStyle}
                onClick={() => {
                    openImportMenu();
                }}
            >
                {buttonText}
            </button>

            <Modal
                show={importMenu}
                onHide={closeImportMenu}
                backdrop="static"
                keyboard={false}
                size=""
            >
                <Modal.Header closeButton>
                    <Modal.Title>Import {importType === "homeowners" ? "Homeowners" : importType === "pros" ? "Pros" : importType === "agents" ? "Agents" : ""}</Modal.Title>
                </Modal.Header>
                <ModalBody>
                    <div>
                        <div className="text-align-left Import-Text">
                            Upload an .xlsx or .csv spreadsheet of your {importType} to batch import them.
                            <br /><br />
                            <span className="bold">We will set up accounts for your {importType} and email them to welcome them to Honeyydo and give them temporary passwords so they can log in.</span>
                            <br /><br />
                            Please ensure that your spreadsheet contains the following column names: <span className="bold">Contact Name</span>, {importType === 'pros' ? <><span className="bold">Copmany Name, </span><span className="bold">Industry, </span></> : null} <span className="bold">Email Address</span>, <span className="bold">Phone Number</span>, <span className="bold">Address</span>,  <span className="bold">City</span>, <span className="bold">{country === "Canada" ? "Province" : "State"}</span> and <span className="bold">{country === "Canada" ? "Postal Code" : "Zip Code"}</span>.
                            {importType === 'pros' ? <> <span className="bold">Sub Industry</span> is an optional field.</> : null} Spreadsheet headings must match these fields in order to be parsed correctly. Other fields will be ignored.
                            <br /><br />
                            {(importType === 'pros' && INDUSTRIES) ?
                                <div className="display-flex flex-wrap justify-content-center aling-items-center">
                                    <span>Please try to match the following list of industries that we have to imporve the search for pros.</span>
                                    <div className="display-flex flex-wrap justify-content-center aling-items-center column-gap-5-px row-gap-5-px">   {INDUSTRIES.map(item =>
                                            <span className="bubble-option">{item}</span>
                                        )}</div>
                                </div>
                                : null}
                            <br />
                            <span>Alternatively, download our sample spreadsheet, fill it with your records, then re-upload it.</span>
                            <br />
                            <span>Excel<FontAwesomeIcon
                                className="cursor-pointer grey padding-left-10-px"
                                icon={faFileExcel}
                                onClick={() => {
                                    onDownloadExcel()
                                }}
                            /></span>
                            <br />
                            <span>CSV<FontAwesomeIcon
                                className="cursor-pointer grey padding-left-10-px"
                                icon={faFileCsv}
                                onClick={() => {
                                    onDownloadCsv()
                                }}
                            /></span>
                        </div>
                    </div>
                    <div className="Import-Input wrap-none">
                        <input
                            disabled
                            className="Input-Style "
                            type="text"
                            value={spreadsheetName}
                            onChange={(e) => { setSpreadsheet(e.target.value) }}
                            style={{ width: '90%' }}
                        />
                        <div className="cursor-pointer" onClick={(e) => handleSpreadsheetUpload(e)}>
                            <span>Upload</span>
                            <FontAwesomeIcon
                                className="grey padding-left-10-px"
                                transform={{ rotate: -45 }}
                                icon={faPaperclip}

                            />
                        </div>


                        <input
                            id="honeydo-file-input-manage-imports"
                            className='display-none'
                            type="file"
                            ref={spreadsheetFile}
                            accept=".csv,.xlsx,.xls"
                            onChange={(e) => { handleSpreadsheetImport(e) }}
                        />
                    </div>





                </ModalBody>
                <Modal.Footer >
                    <div className=" display-flex align-items-center margin-bottom-10-px float-right ">
                        {serverMessage1?.color !== "none" ? (
                            <span
                                className={`margin-right-20-px ${serverMessage1 === "red"
                                    ? "red-background"
                                    : serverMessage1 === "green"
                                        ? "green-background"
                                        : ""
                                    }`}
                            >
                                {serverMessage1?.message}
                            </span>
                        ) : null}


                        {uploadingImport ?
                            <Button style={{ marginBottom: '15px' }} className="submit margin-right-10-px" variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className=""> Loading...</span>
                            </Button> :
                            <span
                                className="margin-right-10-px"
                                onClick={submitUpload}
                            >
                                <button className="Tasklist-Button height-40-px ">
                                    Import
                                </button>
                            </span>
                        }
                        <Button variant="secondary height-40-px" onClick={closeImportMenu}>
                            Close
                        </Button>
                    </div>

                    <div className="display-flex width-100-psnt">

                        {makeUsDoIt ?

                            <span>
                                We are working on your upload, thank you.
                            </span> :
                            <div className="display-flex flex-direction-column margin-10-px">
                                <span>Having trouble uploading? </span>
                                <span><span className="orange cursor-pointer" onClick={() => { handleUserFileUpload() }}>
                                    Click here
                                </span> to send your file and we will do it for you.</span>
                            </div>
                        }
                    </div>
                    <input id="user-file-upload-input" onChange={(e) => handleSpreadsheetImportUser(e)} className="invisible position-absolute" type="file" />

                </Modal.Footer>


            </Modal>
            <PopupMessage onConfirm={() => { setServerMessage(null) }} message={serverMessage?.message} title={serverMessage?.title} show={serverMessage !== null} />
        </>
    )

}

export default ImportButton;