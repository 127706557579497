// Example Template

export default function emailtemplates() {
    function exampletemplate() {
        const emailbody = `
        <div>
            <h1>Example Template</h1>
        </div>
    `;
        return emailbody;
    }

    function exampletemplateSubject() {
        const emailsubject = "Example Template Subject";
        return emailsubject;
    }

    return {
        exampletemplate,
        exampletemplateSubject
    };
}