import serverConstants from "../constants/serverConstants";
import axios from "axios";
import {serverResponseErrActions, getUserFromCookie} from "./requestActions";

function getLocationFromAddress(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if(!data.user){
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/location/getLocationFromAddress`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}


function getGeoInfo() {
  return new Promise((resolve, reject) => {
    try {
      // axios.get('https://ipapi.co/json/').then((response) => {
      //   let data = response.data;
      //   resolve(data);
      // }).catch((err) => {
      //   console.log(err);
      //   reject(err);
      // });
      fetch(`https://ipapi.co/json/`).then(data => {
        // console.log(data)
        return data.json()
    }).then((data) => {
      // console.log(data)
        resolve(data)
    }).catch(err => {
        reject(err)
    })
    } catch (err) {
      reject(err);
    }
  });

};


const locationService = {
  getLocationFromAddress,
  getGeoInfo
}

export default locationService;