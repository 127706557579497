import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faQuestionCircle, faCircleQuestion, faQuestion, faXmarkCircle, faPlus, faCircleXmark, faX } from "@fortawesome/free-solid-svg-icons";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./inputModal.component.css";
import timeUtility from "../../../functions/timeUtility";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import HoverHint from "../hoverHint.component/hoverHint.component";
import { Spinner } from "react-bootstrap";
import ImportButton from "../importButton.component/importButton.component";
import inputConstants from "../../../constants/inputConstants";
import validateUtility from "../../../functions/validateUtility";
import GoogleAutoComplete from "../googleAutoComplete.omponent";
import PopupMessage from "../popupMessage.component/popupMessage.component";

import { FcCancel } from "react-icons/fc";
import { ImCheckmark } from "react-icons/im";
import { object } from "prop-types";
import sqlService from "../../../services/sqlService";
function InputModal({
    inputValues,
    blurCondition,
    show,
    hide,
    title,
    deletable,
    deleteModal,
    aggFunction,
    agg,
    submit,
    serverMessage,
    filledval,
    advanced,
    requestedAddAgent,
    skipValidation,
    additionalAddresses,
    isNewAgent,
    stateWideOptions,
    user,
    setMultipleAggValues,
    newClient,
    additionalFeatures,
    customFeatures,
    setCustomFeatures,
    deleteFeature,
    setServerMessage,
    itemId
}) {

    const valRef = useRef({});
    const [newAddressKey, setNewAddressKey] = useState(null);
    const addressRef = useRef()
    const inputDataRef = useRef()
    const [googlePros, setGooglePros] = useState({});
    const aggRef = useRef();

    const [canProcessCurrentAddresses, setCanProcessCurrentAddresses] = useState(false);
    const loaded = useRef(false);

    const [windowSize, setWindowSize] = React.useState(window.innerWidth);
    const [homeImprovementImgOne, setHomeImprovementImgOne] = useState(null)
    const [homeImprovementImgTwo, setHomeImprovementImgTwo] = useState(null)


    //const [checked, setChecked] = useState({ "contact_name": "false", "email": "false", "city": "false", "address": "false", "state": "false", "zip_code": "false", "phone_number": "false" })
    const checkedRef = useRef(isNewAgent ? { "contact_name": "false", "email": "false", "address": "false", "phone_number": "false" } : { "contact_name": "false", "email": "false", "city": "false", "address": "false", "state": "false", "zip_code": "false", "phone_number": "false" })
    const [addresses, setAddresses] = useState(filledval?.additional_addresses ?? [])
    useEffect(() => {
        window.addEventListener("resize", onWindowResize);
        return () => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, []);

    useEffect(() => {
        if (additionalFeatures) {
            setCustomFeatures(Object.keys(agg.metadata).filter((feature) => !additionalFeatures.includes(feature)))
        }


    }, [])


    useEffect(() => {
        if (agg?.additional_addresses) {
            //   agg.additional_addresses = []
            let newAddresses = {}
            for (let address of agg?.additional_addresses) {
                const random = `${Math.random()}`;
                newAddresses[random] = address;
                // changes.additional_addresses.push(address)
            }
            setAddresses(newAddresses);
            setCanProcessCurrentAddresses(true)
        }

    }, [])

    useEffect(() => {
        if (!canProcessCurrentAddresses) { return }
        let newGooglePros = {};
        for (let newAddressKey of Object.keys(addresses)) {
            let inputField = document.getElementById(`${newAddressKey}_honeyydo_pros_address`)
            valRef.current = { ...addresses };
            if (!newGooglePros[`${newAddressKey}_honeyydo_pros_address_google`]) {
                newGooglePros[`${newAddressKey}_honeyydo_pros_address_google`] = <GoogleAutoComplete specifyCountry={user?.country ?? null} addressKey={`${newAddressKey}_honeyydo_pros_address`} addresses={newAddressKey} address1FieldRef={inputField} callBack={(postcode, city1, country1, state1, address1, oldKey) => {
                    runCallBack(postcode, city1, country1, state1, address1, oldKey);
                }} />;

            }
            setGooglePros(newGooglePros);
            inputField.value = addresses[newAddressKey].address;
        }

    }, [canProcessCurrentAddresses]);

    useEffect(() => {

        if (!agg) {
            return
        }
        if (typeof agg === "object") {
            aggRef.current = { ...agg }
        } else {
            aggRef.current = [...agg]
        }
        if (agg) {
            for (let [key, value] of Object.entries(agg)) {
                updateChecked(key, value);
            }
        }
    }, [agg]);

    const handleAggClicked = (key, value) => {
        console.log(key, value);
        updateChecked(key, value);
        aggFunction(key, value);
    }



    const updateAddress = (key, value, addressKey) => {
        let nAddress = { ...addresses }
        nAddress[addressKey][key] = value;
        let el = document.getElementById(`${addressKey}_honeyydo_pros_address`);
        if (el) {
            el.value = value;
        }
        setAddresses(nAddress)
        aggFunction("additional_addresses", Object.values(nAddress))
    }

    const runCallBack = (postcode, city1, country1, state1, address1, addressesKey) => {
        let inputField = document.getElementById(`${addressesKey}_honeyydo_pros_address`)
        if (inputField) {
            inputField.value = address1;
        }
        if (!addresses) { return }
        let nAddress = { ...valRef.current ?? {} }
        nAddress[addressesKey]["address"] = address1;
        nAddress[addressesKey]["city"] = city1;
        nAddress[addressesKey]["zip_code"] = postcode;
        // nAddress[addressesKey]["country"] = country1;
        nAddress[addressesKey]["state"] = state1;
        setAddresses(nAddress)
        valRef.current = nAddress;
    }



    function deleteAddress(key) {
        let updated = { ...addresses }
        // updated.splice(index, 1)
        delete updated[key]
        let newGooglePro = {}
        for (let key1 of Object.keys(updated)) {
            let el = document.getElementById(`${key1}_honeyydo_pros_address`);
            if (el) {
                el.value = updated[key1].address;
                valRef.current = { ...addresses };
                if (!newGooglePro[`${key1}_honeyydo_pros_address_google`]) {
                    newGooglePro[`${newAddressKey}_honeyydo_pros_address_google`] = <GoogleAutoComplete specifyCountry={user?.country ?? null} addressKey={`${key1}_honeyydo_pros_address`} addresses={key1} address1FieldRef={el} callBack={(postcode, city1, country1, state1, address1, oldKey) => {
                        runCallBack(postcode, city1, country1, state1, address1, oldKey);
                    }} />
                }
            }
        }
        // delete newGooglePro[`${newAddressKey}_honeyydo_pros_address_google`];
        setGooglePros(newGooglePro);
        setAddresses(updated)
        aggFunction("additional_addresses", Object.values(updated))

    }


    useEffect(() => {
        if (!newAddressKey) {
            return
        }
        let inputField = document.getElementById(`${newAddressKey}_honeyydo_pros_address`)
        valRef.current = { ...addresses };
        if (!googlePros[`${newAddressKey}_honeyydo_pros_address_google`]) {
            setGooglePros({
                ...googlePros,
                [`${newAddressKey}_honeyydo_pros_address_google`]: <GoogleAutoComplete specifyCountry={user?.country ?? null} addressKey={`${newAddressKey}_honeyydo_pros_address`} addresses={newAddressKey} address1FieldRef={inputField} callBack={(postcode, city1, country1, state1, address1, oldKey) => {
                    runCallBack(postcode, city1, country1, state1, address1, oldKey);
                }} />
            })
        }
    }, [newAddressKey]);


    const updateChecked = (key, value) => {

        switch (key) {
            case ("state"):

                if (value !== "") {
                    //setChecked({ ...checked, [key]: "true" })
                    checkedRef.current = { ...checkedRef.current, [key]: "true" }
                } else {
                    //setChecked({ ...checked, [key]: "false" })
                    checkedRef.current = { ...checkedRef.current, [key]: "false" }
                }
                break;


            case ("city"):
                if (value !== "") {
                    //setChecked({ ...checked, [key]: "true" })
                    checkedRef.current = { ...checkedRef.current, [key]: "true" }
                } else {
                    // console.log("Not a Valid Name")
                    //setChecked({ ...checked, [key]: "false" })
                    checkedRef.current = { ...checkedRef.current, [key]: "false" }
                }
                break;

            case ("email"):
                if (validateUtility.regValidate(key, value)) {
                    //setChecked({ ...checked, [key]: "true" })
                    checkedRef.current = { ...checkedRef.current, [key]: "true" }
                } else {
                    //setChecked({ ...checked, [key]: "false" })
                    checkedRef.current = { ...checkedRef.current, [key]: "false" }
                }
                break;

            case ("contact_name"):
                if (validateUtility.regValidate(key, value)) {
                    //setChecked({ ...checked, [key]: "true" })
                    checkedRef.current = { ...checkedRef.current, [key]: "true" }
                } else {
                    //setChecked({ ...checked, [key]: "false" })
                    checkedRef.current = { ...checkedRef.current, [key]: "false" }
                }
                break;


            case ("phone_number"):
                if (validateUtility.regValidate(key, value)) {
                    // console.log("validPhone")
                    // setChecked({ ...checked, [key]: "true" })
                    checkedRef.current = { ...checkedRef.current, [key]: "true" }
                } else {
                    // console.log("Not a Valid Phone")
                    //setChecked({ ...checked, [key]: "false" })
                    checkedRef.current = { ...checkedRef.current, [key]: "false" }
                }
                break;

            case ("address"):
                if (validateUtility.regValidate(key, value)) {
                    // console.log("validAddress")
                    //setChecked({ ...checked, [key]: "true" })
                    checkedRef.current = { ...checkedRef.current, [key]: "true" }
                } else {
                    //setChecked({ ...checked, [key]: "false" })
                    checkedRef.current = { ...checkedRef.current, [key]: "false" }
                }
                break;

            case ("zip_code"):
                if (validateUtility.regValidate(key, value)) {
                    // console.log("validAddress")
                    //setChecked({ ...checked, [key]: "true" })
                    checkedRef.current = { ...checkedRef.current, [key]: "true" }
                } else {
                    //setChecked({ ...checked, [key]: "false" })
                    checkedRef.current = { ...checkedRef.current, [key]: "false" }
                }
                break;

            case ("closing_date"):
                if (Object.hasOwn(checkedRef.current, 'closing_date')) {
                    // console.log(value)
                    if (value !== "") {
                        //setChecked({ ...checked, [key]: "true" })
                        checkedRef.current = { ...checkedRef.current, [key]: "true" }
                    } else {
                        //setChecked({ ...checked, [key]: "false" })
                        checkedRef.current = { ...checkedRef.current, [key]: "false" }
                    }
                }
                break;

            case ("property_type"):
                if (Object.hasOwn(checkedRef.current, 'property_type')) {
                    // console.log(value)
                    if (value !== "") {
                        //setChecked({ ...checked, [key]: "true" })
                        checkedRef.current = { ...checkedRef.current, [key]: "true" }
                    } else {
                        //setChecked({ ...checked, [key]: "false" })
                        checkedRef.current = { ...checkedRef.current, [key]: "false" }
                    }
                }
                break;
        }
    }

    const [advancedOpen, setAdvancedOpen] = useState(false)
    // const [inputSize, setInputSize] = useState(0)
    // const [advancedInputSize, setAdvancedInputSize] = useState(0)

    const [assigned, setAssigned] = useState(false);
    const [triggerX, setTriggerX] = useState(false);

    useEffect(() => {
        if (!loaded.current) {
            // console.log("old new Task",agg)

            // console.log(   "current task",     filledval)
            // console.log(   "input values",     inputValues)
            if (filledval) {
                if (filledval?.homeImprovementRequestsPhotos) {
                    console.log(filledval?.homeImprovementRequestsPhotos)
                    let first = filledval?.homeImprovementRequestsPhotos?.filter(item => item?.position === 1);
                    let second = filledval?.homeImprovementRequestsPhotos?.filter(item => item?.position === 2);
                    console.log(first, second)
                    if (first[0]) {
                        setHomeImprovementImgOne({ "link": first[0]?.link });
                    }
                    if (second[0]) {
                        setHomeImprovementImgTwo({ "link": second[0]?.link });
                    }
                }
                for (let [key] of Object.entries(inputValues)) {
                    console.log(inputValues[key].key, filledval)
                    if (filledval[inputValues[key].key]) {

                        agg[inputValues[key].key] = filledval[inputValues[key].key]
                    } else if (key === 'taskDate') {
                        agg.start_date = filledval.repeating_date ?? filledval.task_date
                    } else if (key === 'additional_addresses') {
                        for (let address of filledval[key]) {
                            for (let val of Object.entries(address)) {
                                agg[inputValues.additional_addresses[val].val] = filledval.additional_addresses[inputValues[val].val]

                            }
                        }

                    }


                }
                if (filledval?.additional_addresses) {
                    agg.additional_addresses = []
                    for (let address of filledval.additional_addresses) {
                        agg.additional_addresses.push(address)

                    }

                }

                if (advanced) {
                    for (let [key] of Object.entries(advanced)) {
                        console.log({ key })
                        console.log(key === 'repeatingDropdown')


                        if (filledval[advanced[key].key]) {

                            agg[advanced[key].key] = filledval[advanced[key].key]
                        }
                        if (advanced[key].submenus) {
                            console.log(filledval[advanced[key].submenus.key])

                            agg[advanced[key].submenus.key] = filledval[advanced[key].submenus.key]



                        }

                        // if(value){
                        //     agg[key] = value;
                        // }
                    }
                }
                setAssigned(true);
            }
            loaded.current = true;
        }

    }, []);

    const onWindowResize = () => {
        setWindowSize(window.innerWidth);
    };


    const validateChecked = () => {
        var valid = true;
        console.log(checkedRef.current)
        if (!skipValidation) {
            for (let val of Object.values(checkedRef.current)) {
                if (!(val === "true")) {
                    valid = false;
                    break;
                }
            }
        }
        return valid;
    }



    // useEffect(() => {


    //     const forms = [[inputValues, setInputSize], [advanced, setAdvancedInputSize]]

    //     for (const form of forms) {
    //         if (form[0]) {
    //             var size = 0;
    //             for (let [key, value] of Object.entries(form[0])) {
    //                 // for (const input of form[0]) {
    //                 if (value.hasPopUp || value.submenus) {
    //                     size++;
    //                 }
    //                 if (value.long) {
    //                     size += 2;
    //                 }
    //                 size++;

    //             }
    //             if (!size % 2) {
    //                 size--;
    //             }
    //             form[1](size)
    //         }

    //     }


    // }, [inputValues, advanced])

    const setMultipleAggValuesLocal = (values) => {
        console.log("...............>>>>>", values);
        for (let [key, value] of Object.entries(values)) {
            updateChecked(key, value);
        }
        setMultipleAggValues(values);
    }


    const uploadHomeImprovementRequestPhoto = async (event, key) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        if (!validateUtility.validFileSize(file)) {
            setServerMessage({ "message": `File size must be less than ${inputConstants.MAX_FILE_SIZE / 1000000}mb`, "title": "Error" })
            return;
        }

        var imgValues = {}
        if (key === "HomeImprovementImgOne") {
            if (homeImprovementImgOne?.oldLink) {
                sqlService.deleteAwsImage({ input: { "link": homeImprovementImgOne?.oldLink, "imgSubfolder": "client/homeowner/property_renovation_request_photos" } }).then(d => {
                    console.log(d)
                }).catch(err => {
                    console.log(err);
                })
            }
            if (homeImprovementImgOne?.link) {
                imgValues = { "oldLink": homeImprovementImgOne?.link }
            }
        } else if (key === "HomeImprovementImgTwo") {
            if (homeImprovementImgTwo?.oldLink) {
                sqlService.deleteAwsImage({ input: { "link": homeImprovementImgTwo?.oldLink, "imgSubfolder": "client/homeowner/property_renovation_request_photos" } }).then(d => {
                    console.log(d)
                }).catch(err => {
                    console.log(err);
                })
            }
            if (homeImprovementImgTwo?.link) {
                imgValues = { "oldLink": homeImprovementImgTwo?.link }
            }
        }

        reader.onload = async () => {
            let data = reader.result;
            let t = file.type;
            let type = t.split("/")[1]
            // let subFolder = (key === "logo_link") ? "logos" : "imgs"
            let fileObject = { "imgFile": data, "imgType": type, "imgSubfolder": "client/homeowner/property_renovation_request_photos" }
            //   setInputData({ ...inputData, "img_link": fileObject });
            //   setBlogImg(fileObject)
            await sqlService.uploadImgAws({ input: fileObject }).then(lnk => {
                console.log(lnk);
                lnk = lnk?.data?.link;
                console.log(lnk)
                if (key === "HomeImprovementImgOne") {
                    setHomeImprovementImgOne({ ...imgValues, "link": lnk });
                } else if (key === "HomeImprovementImgTwo") {
                    setHomeImprovementImgTwo({ ...imgValues, "link": lnk })
                }
            }).catch(err => {
                console.log(err);
            })
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleDeleteImg = (key) => {
        if (key === "one") {
            setHomeImprovementImgOne({ "oldLink": homeImprovementImgOne?.link })
        } else {
            setHomeImprovementImgTwo({ "oldLink": homeImprovementImgTwo?.link })
        }

    }

    return (
        <Modal
            className={blurCondition ? "blur" : ''}
            show={show}
            onHide={hide}
            backdrop="static"
            keyboard={false}
            size="lg"
            style={{ zIndex: '12' }}
        >
            <Modal.Header closeButton>
                <Modal.Title className="grey">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                {newClient ?
                    <h6 className="grey" style={{ padding: '10px 50px' }}>We will set up your client's account and send them a welcome email with a temporary password to log in. If you would prefer we not send a welcome email on your behalf, you can change this in your account settings.</h6> : null}
                <div className='Input-Container '>
                    <GoogleAutoComplete specifyCountry={user?.country ?? null} address1FieldRef={addressRef} callBack={(postcode, city1, country1, state1, address1) => {
                        let nInputData = { ...aggRef.current, "zip_code": postcode, "city": city1, "state": state1, "address": address1 }
                        console.log("ddddd calling")
                        setMultipleAggValuesLocal(nInputData);
                    }} />

                    <div className="position-absolute">
                        {Object.keys(googlePros)?.map(key => googlePros[key])}
                    </div>
                    {
                        Object.keys(inputValues).map((key, index) =>

                            <div key={index} className="Input-Wrap position-relative">
                                <div className="Input-Div">
                                    {inputValues[key]?.hint ?

                                        <HoverHint
                                            hint={inputValues[key].hint}

                                        />
                                        : null
                                    }

                                    <span className="wrap-none">{inputValues[key].label}</span>
                                </div>
                                {key.match('stateSelect') ?
                                    <>
                                        <select
                                            name="state"
                                            style={{ maxWidth: '217px' }}
                                            className={`card-input__input -select}`}
                                            value={agg ? agg?.[inputValues[key]?.key] : null}
                                            onChange={(e) => {
                                                handleAggClicked(inputValues[key].key, e.currentTarget.value);
                                            }}

                                        >
                                            {user?.country !== "Canada" ?
                                                inputConstants?.US_STATES.map((val, index) => (
                                                    <option key={index} value={val.value}>
                                                        {val.label}
                                                    </option>
                                                )) : null}
                                            {user?.country !== "United States" ?
                                                inputConstants?.CANDADIAN_PROVINCES.map((val, index) => (
                                                    <option key={index} value={val.value}>
                                                        {val.label}
                                                    </option>
                                                )) : null}

                                        </select>
                                        {checkedRef.current[inputValues[key].key] === "true" && !additionalFeatures ?

                                            <span className="Finish-Check1"><ImCheckmark /></span>
                                            : null}
                                        {(triggerX && checkedRef.current[inputValues[key].key] && checkedRef.current[inputValues[key].key] !== "true") ?

                                            <span className="Finish-Check1"><FcCancel /></span>
                                            : null}
                                    </>
                                    : null}
                                {key.match('Fixed') ?
                                    <div className=" position-relative">
                                        <input
                                            className={`Input-Style`}
                                            key={key}
                                            type={"text"}
                                            value={inputValues[key]?.key ?? null}
                                            disabled
                                        />
                                    </div>
                                    : null}
                                {key.match('homeImprovementRequestsPhotos') ?
                                    <div className=" position-relative">
                                        <div className="display-flex flex-wrap column-gap-10-px row-gap-10-px margin-10-px">
                                            <div className="Input_Modal_IMG_INPUT" >
                                                <input className="display-none" type="file" id="HomeImprovementImgOne" accept="image/*" onChange={(e) => uploadHomeImprovementRequestPhoto(e, "HomeImprovementImgOne")} />
                                                {homeImprovementImgOne && homeImprovementImgOne?.link ?
                                                    <div className="Home-Improvement-Img-Wrap">
                                                        <span className="Home-Improvement-Img-Delete" onClick={() => { handleDeleteImg("one") }}>
                                                            <FontAwesomeIcon className="Home-Improvement-Img-Delete-Icon" icon={faCircleXmark} />
                                                        </span>
                                                        <img onClick={() => { let inp = document.getElementById("HomeImprovementImgOne"); if (inp) { inp.click() } }} className="Home-Improvement-Img" src={homeImprovementImgOne?.link} />
                                                    </div>
                                                    :
                                                    <div className="cursor-pointer" onClick={() => { let inp = document.getElementById("HomeImprovementImgOne"); if (inp) { inp.click() } }}>
                                                        <FontAwesomeIcon
                                                            icon={faPlus}
                                                            className="Home-Improvement-plus-icon"
                                                        />
                                                    </div>

                                                }
                                            </div>
                                            <div className="Input_Modal_IMG_INPUT">
                                                <input className="display-none" type="file" id="HomeImprovementImgTwo" accept="image/*" onChange={(e) => uploadHomeImprovementRequestPhoto(e, "HomeImprovementImgTwo")} />
                                                {homeImprovementImgTwo && homeImprovementImgTwo?.link ?
                                                    <div className="Home-Improvement-Img-Wrap">
                                                        <span className="Home-Improvement-Img-Delete" onClick={() => { handleDeleteImg("two") }}>
                                                            <FontAwesomeIcon className="Home-Improvement-Img-Delete-Icon" icon={faCircleXmark} />
                                                        </span>
                                                        <img onClick={() => { let inp = document.getElementById("HomeImprovementImgTwo"); if (inp) { inp.click() } }} className="Home-Improvement-Img" src={homeImprovementImgTwo?.link} />
                                                    </div>
                                                    :
                                                    <div className="cursor-pointer" onClick={() => { let inp = document.getElementById("HomeImprovementImgTwo"); if (inp) { inp.click() } }} >
                                                        <FontAwesomeIcon
                                                            icon={faPlus}
                                                            className="Home-Improvement-plus-icon"
                                                        />
                                                    </div>

                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : null}

                                {key.match('addressText') ?
                                    <div className=" position-relative">
                                        <input
                                            className={`Input-Style  ${(triggerX && checkedRef.current[inputValues[key].key] && checkedRef.current[inputValues[key].key] !== "true") ? "Input-Error-Border" : ""}`}
                                            ref={addressRef}
                                            key={key}
                                            type={inputValues[key].key === "website" ? "url" : "text"}
                                            value={agg ? agg?.[inputValues[key]?.key] : null}
                                            onChange={(e) => { handleAggClicked("address", e.currentTarget.value) }}
                                        />
                                        {checkedRef.current[inputValues[key].key] === "true" && !additionalFeatures ?

                                            <span className="Finish-Check1"><ImCheckmark /></span>
                                            : null}
                                        {(triggerX && checkedRef.current[inputValues[key].key] && checkedRef.current[inputValues[key].key] !== "true") ?

                                            <span className="Finish-Check1"><FcCancel /></span>
                                            : null}
                                    </div>

                                    : null}

                                {key.match('countrySelect') ?
                                    <select
                                        name="country"
                                        style={{ maxWidth: '217px' }}
                                        className={`card-input__input -select`}
                                        value={agg ? agg?.country : null}
                                        onChange={(e) => {
                                            handleAggClicked(inputValues[key].key, e.currentTarget.value);
                                        }}

                                    >
                                        <option value="">Select Country</option>
                                        <option key={"countryCanada"} value={"Canada"}>
                                            {"Canada"}
                                        </option>
                                        <option key={"countryUS"} value={"United States"}>
                                            {"United States"}
                                        </option>

                                    </select>
                                    : null}
                                {key.match('stateManualState') ?

                                    <div className="position-relative">
                                        <select
                                            name="state"
                                            style={{ maxWidth: '217px' }}

                                            className={`Input-Style  ${(triggerX && checkedRef.current?.state !== "true") ? "Input-Error-Border" : ""}`}
                                            value={agg ? agg?.[inputValues[key]?.key] : null}
                                            onChange={(e) => {
                                                handleAggClicked(inputValues[key].key, e.currentTarget.value);

                                            }}

                                        >
                                            {inputConstants?.US_STATES.map((val, index) => (
                                                <option key={index} value={val.value}>
                                                    {val.label}
                                                </option>
                                            ))}
                                        </select>

                                        {checkedRef.current[inputValues[key].key] === "true" ?

                                            <span className="Finish-Check1"><ImCheckmark /></span>
                                            : null}
                                        {(triggerX && checkedRef.current[inputValues[key].key] && checkedRef.current[inputValues[key].key] !== "true") ?

                                            <span className="Finish-Check1"><FcCancel /></span>
                                            : null}


                                    </div> : null}
                                {key.match('Dropdown') ?
                                    <>
                                        <select
                                            key={key}
                                            className={`Input-Style  ${(triggerX && checkedRef.current[inputValues[key].key] && checkedRef.current[inputValues[key].key] !== "true") ? "Input-Error-Border" : ""}`}
                                            style={{ maxWidth: '217px' }}


                                            onChange={(e) => {

                                                if (e.target.value || inputValues[key].textInput) {

                                                    handleAggClicked(inputValues[key].key, e.target.value);
                                                    if (inputValues[key]?.value?.[1]) {
                                                        inputValues[key].value[1](e.target.value);
                                                    }
                                                } else {

                                                    handleAggClicked(inputValues[key].key, 0);
                                                }



                                            }}
                                            value={agg?.[inputValues[key].key] ?? filledval?.[inputValues[key].key]}
                                        >
                                            <option value=''>Select</option>
                                            {inputValues[key].dropdown.map((option, index) => (
                                                <option value={inputValues[key].textInput ? option : index + 1}>{option}</option>
                                            ))}

                                        </select>


                                        {inputValues[key]?.value?.[0] === 'Custom' ?
                                            <>
                                                <span></span>
                                                <div className="position-relative">
                                                    <input
                                                        className="Input-Style margin-top-15-px"
                                                        type="text"
                                                        placeholder={`Enter ${inputValues[key].label}`}
                                                        onChange={(e) => {
                                                            handleAggClicked(inputValues[key].key, e.currentTarget.value);

                                                        }}

                                                    />
                                                </div>
                                            </>

                                            : null}

                                        {inputValues[key]?.submenus && inputValues[key].value[0] ?
                                            <>
                                                <div className="margin-top-15-px">
                                                    <span>{inputValues[key].submenus.label}</span>
                                                </div>
                                                <select
                                                    className="Input-Style margin-top-15-px"

                                                    onChange={(e) => {

                                                        handleAggClicked(inputValues[key].submenus.key, e.target.value);

                                                    }}
                                                    value={agg?.[inputValues[key].submenus.key] ?? filledval?.[inputValues[key].key]}
                                                >
                                                    <option value=''>Select</option>
                                                    {inputValues[key].submenus.menus[inputValues[key].value[0] - 1].map((option, index) => (
                                                        <option value={inputValues[key].submenus.textInput ? option : index + 1}>{option}</option>
                                                    ))}

                                                </select>
                                            </>
                                            : null
                                        }
                                    </>
                                    : null
                                }
                                {key.match('Date') ?
                                    <DatePicker className="Input-Style"

                                        onFocus={e => e.target.blur()}
                                        selected={agg?.[inputValues?.[key]?.key] ? new Date(agg?.[inputValues[key].key]) : filledval?.[inputValues?.[key]?.key] ? new Date(filledval?.[inputValues[key].key]) : new Date()}
                                        onChange={(value) => { handleAggClicked(inputValues[key].key, value); console.log(value) }

                                        }

                                    /> : null
                                }

                                {(key.match('Text') && !key.match("addressText")) ?

                                    inputValues[key].long ?





                                        <div className=" position-relative">

                                            <textarea
                                                className={`Input-Style ${(triggerX && checkedRef.current[inputValues[key].key] && checkedRef.current[inputValues[key].key] !== "true") ? "Input-Error-Border" : ""}`}
                                                style={{ height: '100px', minWidth: '217px' }}
                                                type="text"
                                                value={agg ? agg?.[inputValues[key]?.key] : null}
                                                onChange={(e) => {
                                                    handleAggClicked(inputValues[key].key, e.currentTarget.value);

                                                }}
                                            />

                                            {checkedRef.current[inputValues[key].key] === "true" ?

                                                <span className="Finish-Check1"><ImCheckmark /></span>
                                                : null}
                                            {(triggerX && checkedRef.current[inputValues[key].key] && checkedRef.current[inputValues[key].key] !== "true") ?

                                                <span className="Finish-Check1"><FcCancel /></span>
                                                : null}
                                        </div>



                                        :



                                        <div className=" position-relative">


                                            <input
                                                className={`Input-Style  ${(triggerX && checkedRef.current[inputValues[key].key] && checkedRef.current[inputValues[key].key] !== "true") ? "Input-Error-Border" : ""}`}

                                                type={inputValues[key].key === "website" ? "url" : "text"}
                                                value={agg ? agg?.[inputValues[key]?.key] : null}
                                                onChange={(e) => {
                                                    handleAggClicked(inputValues[key].key, e.currentTarget.value);
                                                }}
                                            />
                                            {checkedRef.current[inputValues[key].key] === "true" && !additionalFeatures ?

                                                <span className="Finish-Check1"><ImCheckmark /></span>
                                                : null}
                                            {(triggerX && checkedRef.current[inputValues[key].key] && checkedRef.current[inputValues[key].key] !== "true") ?

                                                <span className="Finish-Check1"><FcCancel /></span>
                                                : null}
                                        </div>






                                    : null
                                }



                            </div>
                        )
                    }


                </div>
                {newClient ?
                    <>
                        {user?.country !== "Canada" ?
                            <div className="display-flex align-items-center justify-content-center">
                                <HoverHint
                                    hint={"Make your client's move seamless by setting them up with Honeyydo Connects, our complimentary all-in-one utility setup service. Simply provide your client's information and we will contact them to confirm which services they need, helping to get everything set up before their move-in date."
                                    }

                                />
                                <span>Set up concierge service for this client? </span>
                                <input className="margin-left-15-px" type="checkbox" name={"concierge"} checked={agg.concierge || false} onChange={(e) => {
                                    aggFunction("concierge", e.target.checked);
                                    if (e.target.checked) {
                                        checkedRef.current = { ...checkedRef.current, closing_date: "false", property_type: 'false' }

                                    } else {
                                        delete checkedRef.current.closing_date;
                                        delete checkedRef.current.property_type;

                                    }

                                }}
                                ></input>
                            </div>
                            : null}
                        {agg.concierge ?
                            <>

                                <div className="Input-Container">

                                    <div className="Input-Wrap position-relative">
                                        <div className="Input-Div">

                                            <span className="wrap-none">Closing Date*</span>

                                        </div>
                                        <DatePicker className={`Input-Style  ${(triggerX && checkedRef.current.closing_date && checkedRef.current.closing_date !== "true") ? "Input-Error-Border" : ""}`}
                                            onFocus={e => e.target.blur()}
                                            selected={agg.closing_date}
                                            onChange={(value) => { handleAggClicked('closing_date', value) }

                                            }

                                        />
                                        {checkedRef.current.closing_date === "true" ?

                                            <span className="Finish-Check1"><ImCheckmark /></span>
                                            : null}
                                        {(triggerX && checkedRef.current.closing_date && checkedRef.current.closing_date !== "true") ?

                                            <span className="Finish-Check1"><FcCancel /></span>
                                            : null}
                                    </div>
                                    <div className="Input-Wrap position-relative">
                                        <div className="Input-Div">
                                            <span>Property Type*</span>

                                        </div>
                                        <select
                                            className={`Input-Style  ${(triggerX && checkedRef.current.property_type && checkedRef.current.property_type !== "true") ? "Input-Error-Border" : ""}`}
                                            style={{ width: '217px' }}
                                            onChange={(e) => handleAggClicked('property_type', e.target.value)}
                                        >
                                            <option value=''>Select</option>
                                            <option value='Rent'>Rental</option>
                                            <option value='Own'>Ownership</option>
                                        </select>

                                        {checkedRef.current.property_type === "true" ?

                                            <span className="Finish-Check1"><ImCheckmark /></span>
                                            : null}
                                        {(triggerX && checkedRef.current.property_type && checkedRef.current.property_type !== "true") ?

                                            <span className="Finish-Check1"><FcCancel /></span>
                                            : null}

                                    </div>
                                </div>
                                <div style={{ padding: '0 50px' }} className="display-flex justify-content-center">Make your client's move seamless by setting them up with Honeyydo Connects, our complimentary all-in-one utility setup service.
                                    Simply provide your client's information and we will contact them to confirm which services they need, helping to get everything set up by tapping into over 17 years of collected utility data across
                                    35,000+ U.S. zip codes. Not only do they save the time and frustration of setting up services themselves, we also save them money with exclusive discounts on select services. If you have any questions about Honeyydo Connects, please call (1-888-960-2560) or email connect@honeyydo.com.
                                </div>
                            </>


                            : null
                        }
                    </>
                    : null}
                {stateWideOptions ?
                    <div style={{ marginBottom: '20px' }} className="display-flex align-items-center justify-content-center">
                        <span>Nation Wide? </span>
                        <input className="margin-left-15-px" type="checkbox" name={"nation_wide"} checked={agg.nation_wide || false} onChange={(e) => aggFunction("nation_wide", e.target.checked)}></input>
                        <span className="margin-left-15-px">{user?.country === 'Canada' ? 'Province' : 'State'} Wide?</span>
                        <input className="margin-left-15-px" type="checkbox" name={"state_wide"} checked={agg.state_wide || false} onChange={(e) => aggFunction("state_wide", e.target.checked)}></input>
                    </div> : null
                }
                {additionalFeatures ?
                    <>
                        <div className="display-flex align-items-center width-full-pt">
                            <div className="margin-auto-lr align-items-center display-flex " >
                                <span>Additional Features</span>
                            </div>

                        </div>
                        <div className="additional-features ">
                            {additionalFeatures.map((feature) =>
                                <div className="display-flex column-gap-10-px">
                                    <input type="checkbox" onChange={(e) => aggFunction('metadata', e.target.checked, feature)} checked={feature in agg.metadata}></input>
                                    <span>{feature}</span>
                                </div>

                            )}
                            {customFeatures.map((feature, index) =>
                                <div className="position-relative">
                                    <div onClick={() => deleteFeature(index)} className="x-delete-button"><FontAwesomeIcon className="grey" icon={faX} /></div>
                                    <input value={customFeatures[index]} style={{ maxWidth: '150px' }} className="Input-Style position-relative" onChange={(e) => { var temp = [...customFeatures]; temp[index] = e.currentTarget.value; setCustomFeatures(temp) }}></input>
                                </div>
                            )}
                        </div>
                        <button className="Tag-Button additional-features-button" onClick={() => { setCustomFeatures([...customFeatures, ""]) }}>Add Custom <FontAwesomeIcon icon={faPlus} /></button>

                    </> : null}
                {additionalAddresses ?
                    <>
                        {console.log("======>", addresses)}
                        <div className="display-flex align-items-center width-full-pt">
                            <div className="margin-auto-lr align-items-center display-flex " >
                                <span>Additional service areas</span>
                                <button style={{ marginLeft: '15px' }} className="Tag-Button" onClick={() => { const random = `${Math.random()}`; setAddresses({ ...addresses, [random]: {} }); setNewAddressKey(random) }}>Add <FontAwesomeIcon icon={faPlus} /></button>
                            </div>
                        </div>
                        {Object.keys(addresses).map(key =>
                            <div className='Input-Container'>

                                <div key={key} className="Input-Wrap ">
                                    <div key={key + "1"} className="Input-Div">
                                        <span className="wrap-none">Address


                                        </span>
                                    </div>
                                    <div key={key + "2"} className=" position-relative">
                                        <FontAwesomeIcon onClick={() => deleteAddress(key)} icon={faXmarkCircle} className=" color-med-grey position-absolute cursor-pointer" style={{ top: '10%', left: '-43%' }} />
                                        <input
                                            className="Input-Style"
                                            type="text"
                                            // value={agg ? agg?.[advanced[key].key] : null}
                                            value={addresses[key]?.address ?? ""}
                                            id={`${key}_honeyydo_pros_address`}
                                            onChange={(e) => {
                                                updateAddress("address", e.currentTarget.value, key);

                                            }}
                                        />
                                    </div>
                                </div>

                                <div key={key + "3"} className="Input-Wrap ">
                                    <div className="Input-Div">
                                        <span className="wrap-none">City


                                        </span>
                                    </div>
                                    <div className=" position-relative">
                                        <input
                                            className="Input-Style"
                                            type="text"
                                            // value={agg ? agg?.[advanced[key].key] : ""}
                                            value={addresses[key]?.city ?? ""}

                                            onChange={(e) => {
                                                updateAddress("city", e.currentTarget.value, key);

                                            }}
                                        />
                                    </div>
                                </div>
                                <div key={key + "4"} className="Input-Wrap ">
                                    <div className="Input-Div">
                                        <span className="wrap-none">{user?.country === 'Canada' ? 'Province*' : 'State*'}


                                        </span>
                                    </div>
                                    <div className="position-relative">
                                        <select
                                            name="state"
                                            style={{ maxWidth: '217px' }}

                                            className='Input-Style '
                                            value={addresses[key]?.state ?? ""}
                                            onChange={(e) => {
                                                updateAddress("state", e.currentTarget.value, key);

                                            }}


                                        >
                                            {user?.country === "United States" ?
                                                inputConstants?.US_STATES.map((val, index) => (
                                                    <option key={index} value={val.value}>
                                                        {val.label}
                                                    </option>
                                                )) : null}
                                            {/* {user?.country === "Canada" ?
                                                
                                                inputConstants?.US_STATES.map((val, index) => (
                                                    <option key={index} value={val.value}>
                                                        {val.label}
                                                    </option>
                                                )) : null} */}
                                            {user?.country === "Canada" ?
                                                inputConstants?.CANDADIAN_PROVINCES.map((val, index) => (
                                                    <option key={index} value={val.value}>
                                                        {val.label}
                                                    </option>
                                                )) : null}
                                        </select>

                                    </div>
                                </div>
                                <div key={key + "5"} className="Input-Wrap ">
                                    <div className="Input-Div">
                                        <span className="wrap-none">{user?.country === 'Canada' ? 'Postal' : 'Zip'} Code*


                                        </span>
                                    </div>
                                    <div className=" position-relative">
                                        <input
                                            className="Input-Style"
                                            type="text"
                                            // value={agg ? agg?.[advanced[key].key] : ""}
                                            value={addresses[key]?.zip_code ?? ""}

                                            onChange={(e) => {
                                                updateAddress("zip_code", e.currentTarget.value, key);

                                            }}
                                        />
                                    </div>
                                </div>

                            </div>
                        )}
                    </> : null}
                {advanced ? <p className="text-center grey cursor-pointer" onClick={() => setAdvancedOpen(!advancedOpen)}>Advanced options</p> : null}

                {advancedOpen ?
                    <div className={`Input-Container `}>
                        {
                            Object.keys(advanced).map((key, index) =>

                                <div key={index} className="Input-Wrap ">
                                    <div className="Input-Div">
                                        {advanced[key]?.hint ?
                                            <HoverHint
                                                hint={advanced[key].hint}

                                            />
                                            : null
                                        }

                                        <span className="wrap-none">{advanced[key].label}


                                        </span>
                                    </div>
                                    {key.match('Dropdown') ?
                                        <>

                                            <select
                                                className="Input-Style "
                                                style={{ maxWidth: '217px' }}

                                                onChange={(e) => {

                                                    if (e.target.value || advanced[key].textInput) {

                                                        handleAggClicked(advanced[key].key, e.target.value);
                                                        advanced[key].value[1](e.target.value);
                                                    } else {

                                                        handleAggClicked(advanced[key].key, 0);
                                                    }

                                                }}
                                                value={agg?.[advanced[key].key] ?? filledval?.[advanced[key].key]}
                                            >
                                                <option value=''>Select</option>
                                                {advanced[key].dropdown.map((option, index) => (
                                                    <option value={advanced[key].textInput ? option : index + 1}>{option}</option>
                                                ))}

                                            </select>


                                            {advanced[key].value[0] === 'Custom' ?
                                                <>
                                                    <span></span>
                                                    <div className=" position-relative">
                                                        <input
                                                            className="Input-Style margin-top-15-px"

                                                            type="text"
                                                            placeholder={`Enter ${advanced[key].label}`}

                                                            // value={agg?.[val.key]}
                                                            onChange={(e) => {
                                                                handleAggClicked(advanced[key].key, e.currentTarget.value);

                                                            }}

                                                        />
                                                    </div>
                                                </>

                                                : null}

                                            {advanced[key]?.submenus && advanced[key].value[0] ?
                                                <>
                                                    <div className="margin-top-15-px Input-Div">
                                                        <span >{advanced[key].submenus.label}</span>
                                                    </div>
                                                    <select
                                                        className="Input-Style margin-top-15-px"
                                                        style={{ maxWidth: '217px' }}

                                                        onChange={(e) => {

                                                            handleAggClicked(advanced[key].submenus.key, e.target.value);

                                                        }}
                                                        value={agg?.[advanced[key].submenus.key] ?? filledval?.[advanced[key].key]}
                                                    >
                                                        <option value=''>Select</option>
                                                        {advanced[key].submenus.menus[advanced[key].value[0] - 1].map((option, index) => (
                                                            <option value={advanced[key].submenus.textInput ? option : index + 1}>{option}</option>
                                                        ))}

                                                    </select>
                                                </>
                                                : null
                                            }
                                        </>
                                        : null}

                                    {key.match('Date') ?

                                        <DatePicker className="Input-Style"
                                            onFocus={e => e.target.blur()}
                                            selected={agg?.[advanced?.[key]?.key] ? new Date(agg?.[advanced?.[key]?.key]) : filledval?.[advanced?.[key]?.key] ? new Date(filledval?.[advanced?.[key]?.key]) : new Date()}
                                            onChange={(value) => { handleAggClicked(advanced[key].key, value); }
                                            }
                                        /> : null
                                    }
                                    {key.match('Text') ?
                                        <div className=" position-relative">
                                            <input
                                                className="Input-Style"
                                                type="text"
                                                value={agg ? agg?.[advanced[key].key] : null}
                                                onChange={(e) => {
                                                    handleAggClicked(advanced[key].key, e.currentTarget.value);

                                                }}
                                            />
                                        </div>
                                        : null
                                    }
                                </div>
                            )
                        }
                    </div> : null

                }

            </Modal.Body>
            <Modal.Footer >
                {deletable ?
                    <div className="flex-grow-3">
                        <FontAwesomeIcon
                            icon={faTrash}
                            className="grey fa-lg float-left cursor-pointer"
                            onClick={() => deleteModal(true)} />
                    </div>
                    : null}
                <div className=" display-flex align-items-center margin-bottom-10-px float-right ">
                    {serverMessage?.color !== "none" ? (
                        <span
                            className={`margin-right-20-px ${serverMessage === "red"
                                ? "red-background"
                                : serverMessage === "green"
                                    ? "green-background"
                                    : ""
                                }`}
                        >
                            {serverMessage?.message}
                        </span>
                    ) : null}
                    <span
                        className="margin-right-10-px"
                        onClick={() => {
                            setTriggerX(true);
                            // console.log(checked)
                            // console.log(checkedRef.current)

                            if (validateChecked()) {
                                //setChecked({ "contact_name": "false", "email": "false", "city": "false", "address": "false", "state": "false", "zip_code": "false", "phone_number": "false" });
                                checkedRef.current = (isNewAgent ? { "contact_name": "false", "email": "false", "address": "false", "phone_number": "false" } : { "contact_name": "false", "email": "false", "city": "false", "address": "false", "state": "false", "zip_code": "false", "phone_number": "false" });
                                setTriggerX(false);
                                submit({ "one": homeImprovementImgOne, "two": homeImprovementImgTwo });
                            }
                        }}
                    >
                        {requestedAddAgent ?
                            <Spinner style={{ marginRight: '15px' }} /> :
                            <button className="Tasklist-Button height-40-px ">
                                Save
                            </button>
                        }

                    </span>
                    <Button variant="secondary height-40-px" onClick={hide}>
                        Close
                    </Button>
                </div>
                <PopupMessage onConfirm={() => { if (setServerMessage) { setServerMessage(null) } }} message={serverMessage?.message} title={serverMessage?.title} show={serverMessage && serverMessage?.message !== ""} />

            </Modal.Footer>
        </Modal>

    )

}

export default InputModal;