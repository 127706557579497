import React, { useEffect, useState, useRef } from "react";
import sqlService from "../../../services/sqlService";
import { UserContext } from "../../../App";
import { useContext } from "react";
import "./property.component.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBath, faBed, faDownload, faEdit, faHammer, faImage, faPlus, faRulerCombined, faSave, faShare, faShareAlt, faTrash, faTrashCan, faX } from "@fortawesome/free-solid-svg-icons";
import { BiDownload } from "react-icons/bi";
import { MdFileDownload } from "react-icons/md";
import placeholderImg from "../../../assets/images/house-placeholder2.png"
import validateUtility from "../../../functions/validateUtility";
import inputConstants from "../../../constants/inputConstants";
import InputModal from "../../utility.component/inputModal.component/inputModal.component";
import sortUtility from "../../../functions/sortUtility";
import html2pdf from 'html2pdf.js'




export default function PropertyPage() {
    const user = useContext(UserContext)
    const propertyLoaded = useRef(false)
    const renosLoaded = useRef(false)
    const roomsLoaded = useRef(false)
    const [refresh, setRefresh] = useState(false)
    // const [imgFileObject, setImgFileObject] = useState(null)
    const imgFileObject = useRef(null)
    const imgEdit = useRef(null)
    const renoImg = useRef(null)
    const renoImg2 = useRef(null)
    const renoImgAdd = useRef(null)
    const [serverMessage, setServerMessage] = useState(null)
    const [editPopup, setEditPopup] = useState(false)
    const [propTemp, setPropTemp] = useState({ metadata: {}, purchase_date: new Date() })
    const [customFeatures, setCustomFeatures] = useState([])
    const [editingMode, setEditingMode] = useState({ interior: false, exterior: false })
    const [editingReno, setEditingReno] = useState({})
    const [roomsTemp, setRoomsTemp] = useState({ interior: [], exterior: [] })
    const roomOptions = { interior: ['Bedroom', 'Bathroom', 'Kitchen', 'Living Area', 'Custom'], exterior: ['Deck', 'Patio', 'Garage', 'Custom'] }
    const roomParentCategories = ['interior', 'exterior']
    const [rooms, setRooms] = useState({ interior: [], exterior: [] })
    const roomsRef = useRef({ interior: [], exterior: [] })
    const roomsTempRef = useRef([])
    const [renos, setRenos] = useState([])
    const [imagesTemp, setImagesTemp] = useState([])
    const [property, setProperty] = useState({ id: null, address: null, bedrooms: null, bathrooms: null, squarefeet: null, year_built: null, purchase_date: null, price: null, equity: null, metadata: null, link: null });
    const propValues = {
        "addressText": { label: 'Address', key: 'address' },
        "cityText": { label: 'City', key: 'city' },
        "stateSelect": { label: 'State', key: 'state' },
        "zipText": { label: 'Zip Code', key: 'zip_code' },
        "countrySelect": { label: 'Country', key: 'country' },
        "bedroomsDropdown": { label: 'Bedrooms', key: 'bedrooms', dropdown: [1, 2, 3, 4, 5, 6] },
        "bathroomsDropdown": { label: 'Bathrooms', key: 'bathrooms', dropdown: [1, 2, 3, 4, 5, 6] },
        "squarefeetText": { label: 'Squarefeet', key: 'squarefeet' },
        "yearText": { label: 'Year Built', key: 'year_built' },
        "purchaseDate": { label: 'Purchase Date', key: 'purchase_date' },
        "priceText": { label: 'Purchase Price', key: 'price' },
        "equityText": { label: 'Current Value', key: 'equity' },


    }
    const additionalFeatures = ['Pool', 'Backyard', 'Central Air', 'Finished Basement']

    const upgrades = [{ title: 'Kitchen Renovation', date: 'July 2021', cost: 3500, equity_added: 9000, contractor: 'Bob Pro', details: ["Installed new counters", "Replaced all appliances", "Refinished cabinets"] },
    { title: 'New Roof', date: 'September 2021', cost: 8000, value: 11000, contractor: 'Bob Pro', details: ["Installed Best Shingle Pro brand shingles"] }
    ]

    useEffect(() => {
        if (!propertyLoaded.current) {
            sqlService.getHomeownerProperty({ user: user }).then((data) => {
                if (data.data?.length) {

                    setProperty(data.data[0])
                    var temp = { ...data.data[0] }
                    temp.metadata = JSON.parse(temp.metadata) ?? {}
                    setPropTemp(temp)
                    
                }

                propertyLoaded.current = true;

            }).catch((err) => {

            })
        }
        if (!roomsLoaded.current) {
            sqlService.getPropertyDetails({ user: user, input: { id: property.id } }).then((data) => {
                if (data.data.length) {
                    roomsTempRef.current.interior = data.data.filter((r) => r.parent_category === 'interior');
                    roomsTempRef.current.exterior = data.data.filter((r) => r.parent_category === 'exterior');
                    var interiorRooms = {}
                    var exteriorRooms = {}
                    for (let room of roomsTempRef.current.interior) {

                        if (interiorRooms[room.category]) {
                            interiorRooms[room.category] = [...interiorRooms[room.category], room]
                        } else {
                            interiorRooms[room.category] = [room]
                        }

                    }
                    for (let room of roomsTempRef.current.exterior) {

                        if (exteriorRooms[room.category]) {
                            exteriorRooms[room.category] = [...exteriorRooms[room.category], room]
                        } else {
                            exteriorRooms[room.category] = [room]
                        }

                    }

                    setRooms({ interior: interiorRooms, exterior: exteriorRooms })

                    roomsRef.current.interior = interiorRooms;
                    roomsRef.current.exterior = exteriorRooms

                    roomsLoaded.current = true;
                }

            }).catch((err) => {

            })
        }

        if (!renosLoaded.current && property.id) {
            sqlService.getHomeownerRenovations({ input: property.id }).then((data) => {
                var temp = data.data
                for (let reno of temp) {
                    if (reno.details) {
                        reno.details = JSON.parse(reno.details).details
                    } else {
                        reno.details = []
                    }
                    var photos = []
                    if (reno.photos) {

                        var temp2 = reno.photos.split('<<<!>>>')
                        for (let i = 0; i < temp2.length; i++) {
                            photos.push({ position: temp2[i].split('***!***')[1], link: temp2[i].split('***!***')[0] })
                        }
                        photos = sortUtility.sort('position', photos, true)


                    }
                    reno.photos = photos;

                }

                console.log(temp)

                setRenos(sortUtility.sort('creation_date', temp, false))
                renosLoaded.current = true;

            }).catch((err) => { })
        }


    }, [refresh, property])

    function submitProperty() {
        var temp = { ...propTemp }

        if (temp.metadata) {
            if (customFeatures) {
                var t = {}

                var s = Object.keys(temp.metadata).filter((t) => additionalFeatures.includes(t))
                console.log({ s })
                for (let feature of s) {
                    t[feature] = "1"

                }
                for (let feature of customFeatures) {
                    if (feature.length) {
                        t[feature] = "1"
                    }
                }
                temp.metadata = t

            }
            temp.metadata = JSON.stringify(temp.metadata)

        }
        console.log(temp)


        sqlService.addHomeownerProperty({ user: user, input: temp }).then((data) => {

            propertyLoaded.current = false;
            setRefresh(!refresh);
            setEditPopup(false)

        }).catch((err) => {

        })


    }

    function updateProperty(key, value, feature) {
        var temp = { ...propTemp }
        if (feature) {
            if (value) {
                temp.metadata[feature] = "1"
            } else {
                delete temp.metadata[feature]
            }
        } else {
            temp[key] = value && ['bedrooms', 'bathrooms', 'squarefeet', 'year_built'].includes(key) ? parseInt(value) : value
        }

        setPropTemp(temp)
    }

    function deleteFeature(index) {

        var temp = [...customFeatures]

        temp.splice(index, 1)
        console.log(temp)
        setCustomFeatures(temp)
    }

    function closeEdit() {
        setEditPopup(false)

    }

    const handleRenoImageClick = (index) => {
        if (index === 0) {

            renoImg.current.click()
        } else {
            renoImg2.current.click()
        }

    }

    const handleAddImage = () => {

        renoImgAdd.current.click()

    }



    async function handleRenoImageChange(e, index) {

        const file = e.target.files[0];
        const reader = new FileReader();
        var fileObject = null;
        console.log(index)


        if (!validateUtility.validFileSize(file)) {
            setServerMessage({ "message": `File size must be less than ${inputConstants.MAX_FILE_SIZE / 1000000}mb`, "title": "Error" })
            return;
        }

        reader.onload = () => {

            let data = reader.result;
            let t = file.type;
            let type = t.split("/")[1]
            fileObject = { "imgFile": data, "imgType": type, "imgSubfolder": `client/homeowner/property_imgs` }

            var temp = { ...editingReno }
            var temp2 = [...temp.photos]
            const i = index ?? temp2.length
            if (index) {
                var temp3 = { ...temp2[index] }
            } else {
                var temp3 = { position: i + 1 }

            }

            temp3.link = data
            temp3.file = fileObject
            temp2[i] = temp3
            temp.photos = temp2

            console.log(temp)

            setEditingReno(temp)



        };

        if (file) {
            reader.readAsDataURL(file);
        }


    }

    function deleteRenoPhoto(index) {
        var temp = { ...editingReno };
        var temp2 = [...temp.photos];
        temp2.splice(index, 1);
        temp.photos = temp2;
        setEditingReno(temp)
    }

    const handleImageClick = (event, key) => {

        imgEdit.current.click(event, key)

    }


    async function submitImage(e) {
        const fileObject = await handleImageChange(e)
        console.log(fileObject)

        sqlService.addPropertyImage({ user: user, input: { property: property.id, photo_id: property.photo_id, file: fileObject, old_link: property.link } }).then((data) => {
            propertyLoaded.current = false;
            setRefresh(!refresh)

        }).catch((err) => {

        })
    }

    function handleImageChange(event) {

        const file = event.target.files[0];
        const reader = new FileReader();
        var fileObject = null;


        if (!validateUtility.validFileSize(file)) {
            setServerMessage({ "message": `File size must be less than ${inputConstants.MAX_FILE_SIZE / 1000000}mb`, "title": "Error" })
            return;
        }

        return new Promise((resolve, reject) => {

            reader.onload = () => {

                let data = reader.result;
                let t = file.type;
                let type = t.split("/")[1]
                fileObject = { "imgFile": data, "imgType": type, "imgSubfolder": `client/homeowner/property_imgs` }
                imgFileObject.current = fileObject
                resolve(fileObject)


            };

            if (file) {
                reader.readAsDataURL(file);
            }
        });
    };


    function addRoom(category, parent_category) {
        var count = 1

        if (roomsRef.current[parent_category]?.[category]?.length && !editingMode[parent_category]) {
            count += roomsRef.current[parent_category][category]?.length
        }

        count += roomsTemp[parent_category]?.filter((room) => room.category === category).length
        var temp = { ...roomsTemp }

        temp[parent_category] = [...temp[parent_category], { name: category + ' ' + count, category: category, notes: '' }]

        setRoomsTemp(temp)


    }

    function updateRooms(index, key, value, category) {
        var t = { ...roomsTemp }
        t[category][index][key] = value
        setRoomsTemp(t)

    }

    function saveRooms(category) {
        console.log({ category: category, property: property.id, rooms: roomsTemp[category] })

        sqlService.addPropertyDetails({ user: user, input: { category: category, property: property.id, rooms: roomsTemp[category] } }).then((data) => {

            resetEditingMode(category, false)
            resetRoomsTemp(category, [])

            roomsLoaded.current = false;
            setRefresh(!refresh)
        }).catch((err) => {

        })

    }
    function resetRooms(category, value) {
        var temp = { ...rooms }
        temp[category] = value
        setRooms(temp)
    }

    function resetRoomsTemp(category, value) {
        var temp = { ...roomsTemp }
        temp[category] = value
        setRoomsTemp(temp)
    }

    function resetEditingMode(mode, bool) {
        var temp = { ...editingMode };
        temp[mode] = bool;
        setEditingMode(temp)
    }


    function editRooms(category) {

        resetEditingMode(category, true)
        resetRoomsTemp(category, roomsTempRef.current[category])
        resetRooms(category, [])

    }

    function deleteRoom(index, parent_category) {
        var t = { ...roomsTemp }
        t[parent_category]?.splice(index, 1)
        setRoomsTemp(t)

    }

    function deleteRenoFeature(index) {
        var temp = { ...editingReno }
        temp.details?.splice(index, 1)
        setEditingReno(temp)

    }

    function updateReno(key, value, index) {
        var temp = { ...editingReno }
        if (key === 'details') {
            temp.details[index] = value

        } else {


            temp[key] = value
        }

        setEditingReno(temp)
    }

    async function editReno() {

        var temp = { ...editingReno }
        var temp2 = []
        for (let d of temp.details) {
            if (d.length) {
                temp2.push(d)
            }
        }
        temp.details = JSON.stringify({ details: temp2 })
        for (let photo of temp.photos) {
            if (photo.file) {
                await sqlService.uploadFile({ input: { "file": photo.file.imgFile, "type": photo.file.imgTyle, "subfolder": photo.file.imgSubfolder } }).then(data => {

                    photo.link = data.link



                }).catch(err => {
                    console.log(err);
                });
            }

        }
        console.log(temp)
        sqlService.updateRenovation({ user: user, input: temp }).then((data) => {

            renosLoaded.current = false;
            setRefresh(!refresh)
            setEditingReno({})

        })

    }

    function getPdf() {
        var element = document.getElementById('Property-Page');
       
        var opt = {
            margin: 0,
            filename: `${property.address ?? user.address}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: {
                unit: 'mm',
                format: [280, 350],
                orientation: 'portrait'
            }
        };

        return html2pdf().set(opt).from(element);

    }

    async function sharePdf() {

        const file = await getPdf().outputPdf('blob')
        const pdf = new File([file], `${property.address ?? user.address}.pdf`, { type: "application/pdf" });
        const files = [pdf];
        const data = { files: files, title: `Property Information for ${property.address ?? user.address}` }
        try {
            await navigator.share(data);
        } catch (err) {

        }

    }


    function savePdf() {

        getPdf().save()

    }

    return (
        <>
            <div className="Property-Page" id='Property-Page'>
                <div>
                    <h2 className="grey actor-regular">{property?.address ?? user.address}</h2>
                    <span className="grey ">{property?.city} {property?.state?.split('-')[1]}{property.city || property.state ? ',' : ''} {property?.zip_code}</span>
                </div>
                <div className="Property-Hero-Section">

                    <div className='position-relative Property-Image width-full-pt'>
                        <img crossOrigin="anonymous" className="border-radius-10-px prop-img" src={property?.link ?? placeholderImg} />
                        {property?.id ? 
                        <div onClick={(e) => handleImageClick(e)} className="Prop-Image-Edit no-print" data-html2canvas-ignore="true">
                            <FontAwesomeIcon className="white" icon={faImage} />
                            <span >Edit</span>
                            <input className='display-none' ref={imgEdit} type="file" id="imageUpload" accept="image/*" onChange={(e) => submitImage(e)} />

                            </div> : null}
                    </div>

                    <div className="Prop-Hero-Details">
                        <h5 >Purchased</h5>
                        <p>{property?.purchase_date ? new Date(property.purchase_date).getFullYear() : '--'}</p>
                        <h5 >Purchase Price</h5>
                        <p>${property?.price ?? '--'}</p>
                        {/* <h5 >Current Appraised Value</h5>
                        <p>${property?.equity ?? '--'}</p> */}
                    </div>
                    <div className="display-flex column-gap-10-px Prop-Button-Container no-print" data-html2canvas-ignore="true">
                        <div onClick={savePdf} className="Prop-Button Tag-Button display-flex cursor-pointer">
                            <MdFileDownload />
                            <span>Save PDF</span>
                        </div>
                        <div onClick={sharePdf} className="Prop-Button Tag-Button display-flex cursor-pointer">
                            <FontAwesomeIcon icon={faShare} />
                            <span>Share</span>
                        </div>
                    </div>
                </div>
                <div className="Prop-Sub-Container">

                    <div className="Settings-Heading margin-bottom-10-px display-flex justify-content-space-between">
                        <h5 className="wrap-none grey">Property Information </h5>
                        <button style={{ padding: '3px 5px', marginBottom: '5px' }} onClick={() => setEditPopup(true)} data-html2canvas-ignore="true" className="no-print popup-submit-button"><FontAwesomeIcon icon={faEdit} style={{ marginRight: '3px' }} />Edit</button>
                    </div>
                    <div className="display-flex Prop-Info-Wrap">
                        <div className="Property-Info">
                            <div className="Prop-Attribute">
                                <FontAwesomeIcon className="icon-grey fa-xl" icon={faBed} />
                                <span className="bold grey">{property?.bedrooms ?? '--'} Bed</span>
                            </div>
                            <div className="Prop-Attribute">
                                <FontAwesomeIcon className="icon-grey fa-xl" icon={faBath} />
                                <span className="bold grey">{property?.bathrooms ?? '--'} Bath</span>
                            </div>
                            <div className="Prop-Attribute">
                                <FontAwesomeIcon className="icon-grey fa-xl" icon={faRulerCombined} />
                                <span className="bold grey">{property?.squarefeet ?? '--'} sqft</span>
                            </div>
                            <div className="Prop-Attribute">
                                <FontAwesomeIcon className="icon-grey fa-xl" icon={faHammer} />
                                <span className="bold grey">Built in {property?.year_built ?? '--'}</span>
                            </div>

                        </div>
                        {property.metadata ?
                            <div>
                                <span className="bold grey">Additional Features</span>
                                <ul className="Feature-List ">
                                    {Object.keys(JSON.parse(property.metadata)).map((feature) =>
                                        <li>{feature}</li>)}
                                </ul>
                            </div> : null}
                    </div>
                </div>
                {property.id ?

                    roomParentCategories.map((parent) =>
                        <div className="Prop-Sub-Container">

                            <div className="Settings-Heading margin-bottom-10-px display-flex justify-content-space-between">
                                <h5 className="wrap-none grey">{parent.charAt(0).toUpperCase() + parent.slice(1)} </h5>
                                {Object.keys(rooms[parent]).length ?
                                    <button style={{ padding: '3px 5px', marginBottom: '5px' }} onClick={() => editRooms(parent)} data-html2canvas-ignore="true" className="no-print popup-submit-button"><FontAwesomeIcon icon={faEdit} style={{ marginRight: '3px' }} />Edit</button>
                                    : null}
                            </div>

                            <div className="Prop-Buttons-Panel no-print" data-html2canvas-ignore="true">
                                {roomOptions[parent].map((option) =>
                                    <div onClick={() => addRoom(option, parent)} className="Prop-Button Tag-Button display-flex cursor-pointer">

                                        <span>Add {option}</span>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </div>

                                )}

                            </div>

                            {!editingMode[parent] ?
                                <div className="Room-Grid">

                                    {roomOptions[parent].map((category) =>
                                        rooms[parent][category] ?
                                            <>

                                                <h5 className="grey">{category === 'Custom' ? 'Additional' : category + 's'}</h5>

                                                <div className="Room-Div">
                                                    {rooms[parent][category]?.map((room) =>
                                                        <>
                                                            <h6 className="grey">{room.name}</h6>
                                                            <p>{room.notes}</p>
                                                        </>
                                                    )}
                                                </div>

                                            </> : null

                                    )}

                                </div> : null}
                            {roomsTemp?.[parent]?.length ?
                                <div className="card-form height-fit-content box-shadow-none ">
                                    {roomsTemp[parent].map((room, index) =>
                                        <div>

                                            <div className="card-input position-relative padding-right-15-px">
                                                <label htmlFor="roomName" className="card-input__label">
                                                    Name
                                                </label>

                                                <input
                                                    type="text"
                                                    name="roomName"
                                                    className="card-input__input"
                                                    autoComplete="off"
                                                    onChange={(e) => updateRooms(index, 'name', e.currentTarget.value, parent)}
                                                    value={room.name}
                                                />
                                                <FontAwesomeIcon onClick={() => deleteRoom(index, parent)} className="position-absolute grey room-delete" icon={faTrashCan} />

                                            </div>
                                            <div className="card-input padding-right-15-px">
                                                <label htmlFor="roomDescription" className="card-input__label">
                                                    Description
                                                </label>
                                                <textarea
                                                    type="text"
                                                    name="roomDescription"
                                                    className="card-input__input"
                                                    autoComplete="off"
                                                    onChange={(e) => updateRooms(index, 'notes', e.currentTarget.value, parent)}
                                                    value={room.notes}
                                                />

                                            </div>


                                        </div>
                                    )}
                                    <div style={{ justifyContent: 'center' }} className="display-flex width-full-pt column-gap-10-px">
                                        <button onClick={() => { resetRooms(parent, roomsRef.current.interior); resetRoomsTemp(parent, []); resetEditingMode(parent, false) }} className="Cancel-Button">Cancel</button>
                                        <button onClick={() => saveRooms(parent)} className="popup-submit-button "><FontAwesomeIcon icon={faSave} className="margin-right-5-px" />Save</button>

                                    </div>
                                </div> : null}

                        </div>
                    ) : null}

                {renos?.length ?
                    <div className="Prop-Sub-Container">

                        <div className="Settings-Heading margin-bottom-10-px">
                            <h5 className="wrap-none grey">Completed Upgrades </h5>
                        </div>
                        {renos.map((entry, index) =>
                            <div className="Upgrade-Div box-shadow-2">
                                <div className="display-flex justify-content-space-between column-gap-10-px">
                                    <div className="display-flex column-gap-10-px">
                                        <h5 className="icon-blue">{entry.title}</h5>
                                        {editingReno.id === entry.id ?
                                            <>
                                                {editingReno.photos?.length < 2 ?
                                                    <button style={{ marginTop: '-5px', marginLeft: '5px', height: '35px', fontSize: '.9em' }} className="Prop-Button Tag-Button display-flex" onClick={(e) => { handleAddImage(e) }}>Add Image<FontAwesomeIcon style={{ fontSize: '.9em' }} icon={faPlus} /></button>
                                                    : null}
                                                <input className="display-none" ref={renoImgAdd} type="file" id="imageUpload" accept="image/*" onChange={(e) => handleRenoImageChange(e)} />

                                                <button style={{ marginTop: '-5px', marginLeft: '5px', height: '35px', fontSize: '.9em' }} className="Prop-Button Tag-Button display-flex" onClick={() => { var temp = { ...editingReno }; temp.details = [...temp.details, '']; setEditingReno(temp) }}>Add Detail<FontAwesomeIcon style={{ fontSize: '.9em' }} icon={faPlus} /></button></> : null}

                                    </div>
                                    <h6 className="grey">{new Date(entry.creation_date).toLocaleString('default', { month: 'long', year: 'numeric' })}</h6>
                                </div>
                                {entry.photos?.length || (editingReno.id === entry.id && editingReno.photos?.length) ?
                                    <div style={{ marginLeft: editingReno.id === entry.id ? '45px' : '0' }} className="Reno-Images">
                                        {editingReno.id === entry.id ?

                                            editingReno.photos?.map((photo, index) =>
                                                <div >
                                                    <div onClick={() => { deleteRenoPhoto(index) }} className="reno-image-delete"><FontAwesomeIcon icon={faX} /></div>

                                                    <img className="cursor-pointer" onClick={() => handleRenoImageClick(index)} src={photo.link} />
                                                    <input className="display-none" ref={index === 0 ? renoImg : renoImg2} type="file" id="imageUpload" accept="image/*" onChange={(e) => handleRenoImageChange(e, index)} />
                                                </div>

                                            )
                                            :

                                        entry.photos?.map((photo) =>
                                            <div>
                                                <img crossOrigin="anonymous" src={photo.link} />
                                                </div>

                                            )}
                                    </div> : null}

                                <div style={{ display: 'flex', gap: '10px', margin: '20px 0', flexWrap: 'wrap' }}>
                                    {editingReno.id === entry.id ?
                                        <>

                                            {editingReno.details?.map((detail, index) =>
                                                <div style={{ marginLeft: '45px' }} className="position-relative">
                                                    <div onClick={() => deleteRenoFeature(index)} className="x-delete-button"><FontAwesomeIcon className="grey" icon={faX} /></div>
                                                    <input placeholder="Detail" value={editingReno.details[index]} className="Input-Style position-relative" onChange={(e) => { var temp = { ...editingReno }; temp.details[index] = e.currentTarget.value; setEditingReno(temp); console.log(temp) }}></input>
                                                </div>
                                            )}



                                        </>

                                        :
                                        <>
                                            {entry.details?.map((detail) =>
                                                <div className="Prop-Button box-shadow-3 width-fit-content">
                                                    <span className="grey">{detail}</span>
                                                </div>)}</>}
                                </div>


                                <div className="display-flex justify-content-space-between column-gap-10-px row-gap-10-px flex-wrap">
                                    <div className="display-flex flex-wrap column-gap-30-px row-gap-10-px">
                                        <div className="display-flex column-gap-10-px">
                                            <span className="icon-blue bold">Cost:</span>
                                            {editingReno.id === entry.id ?
                                                <input className="Input-Style" style={{ height: '30px', width: '100px' }} value={editingReno.cost} onChange={(e) => updateReno('cost', e.currentTarget.value)}></input>
                                                : <span className="grey">${entry.cost ?? '--'}</span>}
                                        </div>
                                        <div className="display-flex column-gap-10-px">
                                            <span className="icon-blue bold wrap-none">Value added:</span>
                                            {editingReno.id === entry.id ?
                                                <input className="Input-Style" style={{ height: '30px', width: '100px' }} value={editingReno.equity_added} onChange={(e) => updateReno('equity_added', e.currentTarget.value)}></input>
                                                : <span className="grey">${entry.equity_added ?? '--'}</span>}
                                        </div>

                                    </div>
                                    {editingReno.id === entry.id ?
                                        <div className="display-flex column-gap-10-px">
                                            <button onClick={() => { setEditingReno({}) }} style={{ padding: '3px 7px', height: '35px', marginBottom: '-6px' }} className="Cancel-Button">Cancel</button>
                                            <button onClick={() => editReno(entry)} style={{ padding: '3px 7px', height: '35px', marginBottom: '-6px' }} className="popup-submit-button">Save</button>
                                        </div>
                                        :
                                        <FontAwesomeIcon data-html2canvas-ignore="true" onClick={() => { if (entry.details.length) { setEditingReno({ ...entry }); setImagesTemp(entry.photos) } else { var temp = { ...entry }; temp.details = ['']; setEditingReno(temp) } }} className="cursor-pointer icon-blue fa-lg hover-dark-blue" icon={faEdit} />
                                    }
                                </div>
                            </div>)}
                    </div> : null}



            </div>

            {editPopup ?
                <InputModal
                    inputValues={propValues}

                    show={editPopup}
                    hide={closeEdit}
                    title='Edit Property Information'
                    aggFunction={updateProperty}
                    agg={propTemp}
                    submit={submitProperty}

                    filledval={property}
                    skipValidation={true}
                    additionalFeatures={additionalFeatures}
                    customFeatures={customFeatures}
                    setCustomFeatures={setCustomFeatures}
                    deleteFeature={deleteFeature}
                    setMultipleAggValues={setPropTemp}


                />

                : null
            }
        </>
    )
}