import React from "react";
import "./cabinet.component.css";
import PoweredBy from "../poweredby.component/poweredby.component";
import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight, faPaperclip, faTrash, faPrint, faEdit } from "@fortawesome/free-solid-svg-icons";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { faBoxArchive } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import SubHeader from "../../main.component/subHeader.component/subHeader.component";
import sqlService from "../../../services/sqlService";
import searchUtility from "../../../functions/searchUtility";
import TableFooter from "../../utility.component/tableFooter.component";
import ScrollToTop from "../../utility.component/scrollToTop.component.js";

import { useContext } from "react";
import { UserContext } from "../../../App";
import { useNavigate } from "react-router-dom";

import { Document, Page, Outline } from 'react-pdf';

import { pdfjs } from 'react-pdf';
import HoverHint from "../../utility.component/hoverHint.component/hoverHint.component";

import validateUtility from "../../../functions/validateUtility";
import inputConstants from "../../../constants/inputConstants";
import PopupMessage from "../../utility.component/popupMessage.component/popupMessage.component";
import Spinner from 'react-bootstrap/Spinner';



export default function Cabinet() {
  const user = useContext(UserContext);
  const [cabinets, setCabinets] = useState([]);

  const [cabinetRoom, setCabinetRoom] = useState("All");

  const [filteredCabinates, setFilteredCabinates] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState(0);

  const roomPerPage = 10;

  const [categories, setCategories] = useState({});

  const [filteredPaginatedCabinates, setFilteredPaginatedCabinates] = useState(
    []
  );

  const fileTypes = ['pdf', 'jpg', 'jpeg', 'png', 'webp']

  // pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  //   'pdfjs-dist/build/pdf.worker.min.js',
  //   import.meta.url,
  // ).toString();

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  const loaded = React.useRef(false);

  const [refresh, setRefresh] = useState(false);

  const [popUp, setPopUp] = useState(false);

  const [scrollCurrentPage, setScrollCurrentPage] = useState(1)
  const [scrollTotalPages, setScrollTotalPages] = useState(1)

  const Navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      Navigate("/")
    }
  })

  const [serverMessage1, setServerMessage1] = useState({
    color: "none",
    message: "",
  });

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPdfPage() {
    changePage(-1);
  }

  function nextPdfPage() {
    changePage(1);
  }

  function onItemClick({ pageNumber: itemPageNumber }) {
    setPageNumber(itemPageNumber);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);




  const openPopUp = () => {
    setPopUp(true);
  };

  const closePopUp = () => {
    setPopUp(false);
  };

  const [addCabinetView, setAddCabinetView] = useState(false);

  const openAddCabinet = () => {
    setAddCabinetView(true);
  };
  const closeAddCabinet = () => {
    setAddCabinetView(false);
    setEditCabinetView(false);
    setCurrentCabinet(null);
    resetCabinet();
  };

  const ID = 1;

  const indexOfLastRoom = currentPage * roomPerPage;
  const indexOfFirstRoom = indexOfLastRoom - roomPerPage;

  const [originalCabinets, setOriginalCabinets] = useState([]);

  //adding to cabinet state variables
  const [room, setRoom] = useState("");
  const [appliance, setAppliance] = useState("");

  const warrantyFile = useRef(null);
  const manualFile = useRef(null);
  const [warrantyLink, setWarrantyLink] = useState(null);
  const [warrantyUpload, setWarrantyUpload] = useState(null);
  const [warrantyType, setWarrantyType] = useState(null);
  const [warrantyName, setWarrantyName] = useState(null);

  const [manualLink, setManualLink] = useState(null);
  const [manualUpload, setManualUpload] = useState(null);
  const [manualType, setManualType] = useState(null);
  const [manualName, setManualName] = useState(null);

  const [documentShow, setDocumentShow] = useState(false);
  const [doc, setDoc] = useState(null);
  const iframe = useRef(null);
  const [serverMessage, setServerMessage] = useState(null);
  const [saving, setSaving] = useState(false)

  const [editCabinetView, setEditCabinetView] = useState(false);
  const [currentCabinet, setCurrentCabinet] = useState(null);

  const handleCurrentCabinet = (cabinet) => {
    console.log(cabinet);
    setAppliance(cabinet?.appliance ?? "");
    setCurrentCabinet(cabinet);
    setManualLink(cabinet?.manualLink);
    setRoom(cabinet?.room ?? "");
    setWarrantyLink(cabinet?.warrantyLink);
  }

  const handleManualChange = (event) => {

    setManualLink(null);


    const file = event.target.files[0];
    const reader = new FileReader();
    console.log(file?.size)
    if (!validateUtility.validFileSize(file)) {
      setServerMessage({ "message": `File size must be less than ${inputConstants.MAX_FILE_SIZE / 1000000}mb`, "title": "Error" })
      return;
    }

    reader.onload = () => {

      let data = reader.result;

      let t = file.type;
      setManualName(file.name)
      let type = t.split("/")[1];
      setManualType(type)
      setManualUpload(data)

    };

    if (file) {
      reader.readAsDataURL(file);
    }

  }

  const handleManualUpload = () => {
    manualFile.current.click();

  }

  const handleWarrantyChange = (event) => {

    setWarrantyLink(null);


    const file = event.target.files[0];
    const reader = new FileReader();

    if (!validateUtility.validFileSize(file)) {
      setServerMessage({ "message": `File size must be less than ${inputConstants.MAX_FILE_SIZE / 1000000}mb`, "title": "Error" })
      return;
    }

    reader.onload = () => {

      let data = reader.result;

      let t = file.type;
      setWarrantyName(file.name)
      let type = t.split("/")[1];
      setWarrantyType(type)
      setWarrantyUpload(data)


    };

    if (file) {
      reader.readAsDataURL(file);
    }

  }

  const handleWarrantyUpload = () => {
    warrantyFile.current.click();

  }


  const resetCabinet = () => {
    setRoom("");
    setAppliance("");
    setManualLink(null);
    setWarrantyLink(null);
    setManualUpload(null);
    setWarrantyUpload(null);
    setManualName(null);
    setWarrantyName(null);
  }

  async function submitCabinet() {
    var warranty = null;
    var manual = null;
    if (!warrantyUpload && !manualUpload) {
      setServerMessage1({ color: "red", message: "Please add a warranty or manual file" });
      setTimeout(() => {
        setServerMessage1({ color: "none", message: "" });
      }, 5000);
      return;
    }
    setSaving(true)

    if (warrantyUpload) {
      if (!fileTypes.includes(warrantyType)) {
        setServerMessage1({ color: "red", message: "Warranty file type not accepted. Accepted files are pdf, jpg or png." });
        setTimeout(() => {
          setServerMessage1({ color: "none", message: "" });
        }, 5000);
        setSaving(false)
        return;

      }
      await sqlService.uploadFile({ input: { "file": warrantyUpload, "type": warrantyType, "subfolder": "content/cabinet/warranty_attachment" } }).then(data => {

        warranty = data.link



      }).catch(err => {
        console.log(err);
      });

    }

    if (manualUpload) {
      if (!fileTypes.includes(manualType)) {
        setServerMessage1({ color: "red", message: "Manual file type not accepted. Accepted files are pdf, jpg or png." });
        setTimeout(() => {
          setServerMessage1({ color: "none", message: "" });
        }, 5000);
        return;

      }
      await sqlService.uploadFile({ input: { "file": manualUpload, "type": manualType, "subfolder": "content/cabinet/manual_attachments" } }).then(data => {

        manual = data.link


      }).catch(err => {
        console.log(err);
      });

    }

    if (currentCabinet) {
      sqlService
        .editCabinet({
          user: user,
          input: {
            room: room,
            appliance: appliance,
            manualLink: manualLink ?? manual,
            warrantyLink: warrantyLink ?? warranty,
            id: currentCabinet?.id
          },
        })
        .then(() => {
          setServerMessage1({
            color: "green",
            message: "Item is Edited Successfully",
          });
          setTimeout(() => {
            setServerMessage1({ color: "none", message: "" });
          }, 3000);
          // resetCabinet();
          loaded.current = false;
          setRefresh(!refresh)
          closePopUp();
          setSaving(false)
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
          setServerMessage1({ color: "red", message: "Something Went Wrong" });
          setTimeout(() => {
            setServerMessage1({ color: "none", message: "" });
          }, 3000);
          setSaving(false)
        });

    } else {
      sqlService
        .addCabinet({
          user: user,
          input: {
            room: room,
            appliance: appliance,
            manualLink: manualLink ?? manual,
            warrantyLink: warrantyLink ?? warranty,
          },
        })
        .then(() => {
          setServerMessage1({
            color: "green",
            message: "Item is Added Successfully",
          });
          setTimeout(() => {
            setServerMessage1({ color: "none", message: "" });
          }, 3000);
          resetCabinet();
          loaded.current = false;
          setRefresh(!refresh)
          closePopUp();
          setSaving(false)
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
          setServerMessage1({ color: "red", message: "Something Went Wrong" });
          setTimeout(() => {
            setServerMessage1({ color: "none", message: "" });
          }, 3000);
          setSaving(false)
        });
    }





  }

  //delete cabinet row below

  const [cabinetToDelete, setCabinetToDelete] = useState(null);

  useEffect(() => {
    const filterCabinetRoom = cabinets.filter((cabinetItem) => {
      const isRoomMatch =
        cabinetRoom === "All" || cabinetRoom === cabinetItem.room;
      return isRoomMatch;
    });

    setFilteredCabinates(filterCabinetRoom);
  }, [cabinets, cabinetRoom]);

  // useEffect(() => {
  //   setTotalPages(Math.ceil(filteredCabinates.length / roomPerPage));
  // }, [filteredCabinates]);

  useEffect(() => {
    // const indexOfLastRoom = currentPage * roomPerPage;
    // const indexOfFirstRoom = indexOfLastRoom - roomPerPage;

    // const currentRooms = filteredCabinates.slice(
    //   indexOfFirstRoom,
    //   indexOfLastRoom
    // );

    setFilteredPaginatedCabinates(filteredCabinates);
  }, [currentPage, filteredCabinates]);

  // useEffect(() => {
  //   const currentRooms = filteredCabinates.slice(
  //     indexOfFirstRoom,
  //     indexOfLastRoom
  //   );

  //   setFilteredPaginatedCabinates(currentRooms);
  // }, [indexOfFirstRoom, indexOfLastRoom, filteredCabinates]);

  useEffect(() => {
    if (!loaded.current) {

      sqlService
        .getCabinets({ user: user })
        .then((data) => {
          const cabinets = data.data;
          const categories = {};
          categories["All"] = 1;
          for (let item of cabinets) {
            categories[item.room] = 1;
          }
          setCategories(categories);
          setCabinets(cabinets);
          setOriginalCabinets(cabinets);
        })
        .catch((err) => {
          console.log(err);
        });
      loaded.current = true;
    }
  }, [refresh]);

  {
    /*search bar function */
  }

  const [searchBarValue, setSearchBarValue] = useState("");

  const prof = useRef([]);

  const onSearchBarChange = (e) => {
    setSearchBarValue(e.currentTarget.value);
    onSearch(e.currentTarget.value)
  };

  const onSearch = (value) => {
    if ((!value && searchBarValue === "") || (value === "")) {
      setCabinets(originalCabinets);
    } else {
      let filteredCabinets = searchUtility.filterBySearch(
        originalCabinets,
        value ?? searchBarValue
      );
      setCabinets(filteredCabinets);
    }
    setCurrentPage(1);
  };

  const nextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const [windowSize, setWindowSize] = React.useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  const onWindowResize = () => {
    setWindowSize(window.innerWidth);
  };


  const handleScrollingTable = (e) => {
    let element = e.target
    let pageNumber = Math.round(element.scrollTop / (element.clientHeight - 100)) + 1;
    setScrollCurrentPage(pageNumber);
    if (element.scrollHeight - Math.round(element.scrollTop) <= element.clientHeight) {
      setScrollCurrentPage(scrollTotalPages);
    }
  }


  useEffect(() => {
    let element = document.getElementById('tableScrollElementHoneyydoClient');
    if (element) {
      let totalPages = Math.round(element?.scrollHeight / (element?.clientHeight - 100));
      console.log(totalPages, element.scrollHeight, element.clientHeight)
      element.scrollTo({ top: 0 })
      setScrollCurrentPage(1);
      setScrollTotalPages(totalPages)
    }
  }, [windowSize, filteredPaginatedCabinates])

  return (
    <>
      <div className="align-items-center display-flex flex-direction-column ">
        <SubHeader
          pageName="Cabinet"
          addbutton={openAddCabinet}
          buttonName="Add To Cabinet"
          showSearchBar={true}
          onSearchBarChange={onSearchBarChange}
          searchBarValue={searchBarValue}
          searchBarPlaceHolder={"Search Cabinet"}
          onSearch={onSearch}
        />
        {cabinets.length ?
          <div className="Table-List-Container">
            {windowSize > 850 ? (
              <div id="tableScrollElementHoneyydoClient" className="Table-List-Container" onScroll={handleScrollingTable}>
                <table className="Uni-Table-Container ">
                  {" "}
                  {/*desktop view */}
                  <thead className="Uni-Table-Header">
                    <tr>
                      <th>Appliance</th>
                      <th>
                        Room
                        <select
                          className="margin-left-5-px dropdown"
                          onChange={(e) => {
                            setCabinetRoom(e.target.value);
                            setRefresh(true);
                          }}
                        >
                          {Object.keys(categories).map((key) => (
                            <option value={key}>{key}</option>
                          ))}
                        </select>
                      </th>

                      <th>Owner's Manual</th>
                      <th>Warranty Info</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="Uni-Table-Body">
                    {filteredPaginatedCabinates.map((cabinet, index) => (
                      <tr key="index">
                        <td>{cabinet.appliance}</td>
                        <td>{cabinet.room}</td>
                        <td>
                          {cabinet.manualLink !== null ?
                            <button className="Cabinet-Button">
                              {
                                cabinet?.manualLink?.match('^https://honeyydo.s3*') ?
                                  <span onClick={() => (setDocumentShow(true), setDoc(cabinet.manualLink))}>{cabinet.appliance} Manual</span>
                                  : <a target='_blank' href={cabinet.manualLink}>{cabinet.appliance} Manual</a>}
                            </button>
                            : null
                          }
                        </td>
                        <td>
                          {cabinet.warrantyLink !== null ?
                            <button className="Cabinet-Button">
                              {
                                cabinet?.warrantyLink?.match('^https://honeyydo.s3*') ?
                                  <span onClick={() => (setDocumentShow(true), setDoc(cabinet.warrantyLink))}>{cabinet.appliance} Warranty</span>
                                  : <a target='_blank' href={cabinet.warrantyLink}>{cabinet.appliance} Warranty</a>}
                            </button>
                            : null
                          }
                        </td>
                        <td>
                          <div className="display-flex  column-gap-10-px row-gap-10-px">
                            <div
                              className="Trash"
                              onClick={() => { handleCurrentCabinet(cabinet); setEditCabinetView(true); }}
                            >
                              <FontAwesomeIcon
                                className="hover-icon grey margin-right-20-px cursor-pointer"
                                icon={faEdit}

                              />
                            </div>
                            <div
                              className="Trash"
                              onClick={() => {
                                setCabinetToDelete({ id: cabinet.id, warranty: cabinet.warrantyLink, manual: cabinet.manualLink });
                                openPopUp();
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </div>
                          </div>

                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <>
                <div className="mobile-page-name-container">
                  <h2 className="-mobile-page-name grey">Cabinet</h2>
                </div>
                <div id="tableScrollElementHoneyydoClient" className="Table-List-Container" onScroll={handleScrollingTable}>
                  <div className="cabinets-mobile-container">
                    {" "}
                    {/*mobile view */}
                    {filteredPaginatedCabinates.map((cabinet, index) => (
                      <div className="cabinet-mobile-container" key={index}>
                        <div className="icon-room-title-mobile">
                          <div>
                            <FontAwesomeIcon
                              icon={faBoxArchive}
                              className="faBoxArchive"
                            />
                          </div>
                          <div className="mobile-room-title">{cabinet.room}</div>
                        </div>
                        <div className="mobile-appliance-title grey">
                          {cabinet.appliance}
                        </div>
                        <div className="mobile-cabinet-links">
                          {cabinet.manualLink !== null ?
                            <button className="Cabinet-Button">
                              {
                                cabinet?.manualLink?.match('^https://honeyydo.s3*') ?
                                  <span onClick={() => (setDocumentShow(true), setDoc(cabinet.manualLink))}>{cabinet.appliance} Manual</span>
                                  : <a target='_blank' href={cabinet.manualLink}>{cabinet.appliance} Manual</a>}
                            </button>
                            : null
                          }
                          {cabinet.warrantyLink !== null ?
                            <button className="Cabinet-Button">
                              {
                                cabinet?.warrantyLink?.match('^https://honeyydo.s3*') ?
                                  <span onClick={() => (setDocumentShow(true), setDoc(cabinet.warrantyLink))}>{cabinet.appliance} Warranty</span>
                                  : <a target='_blank' href={cabinet.warrantyLink}>{cabinet.appliance} Warranty</a>}
                            </button>
                            : null
                          }
                        </div>
                        <div className="trash-mobile-container">
                          <div
                            className="mobile-cabinet-trash"
                            onClick={() => {
                              setCabinetToDelete({ id: cabinet.id, warranty: cabinet.warrantyLink, manual: cabinet.manualLink });
                              openPopUp();
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            {filteredPaginatedCabinates?.length ?

              <div className="center-wrapper margin-top-10-px">
                <TableFooter
                  currentPage={scrollCurrentPage}
                  totalPages={scrollTotalPages}
                  nextPage={() => {
                    let element = document.getElementById("tableScrollElementHoneyydoClient");
                    if (element) {
                      element.scrollBy({
                        top: element.clientHeight - 100,
                        behavior: "smooth",
                      })
                    }

                  }}
                  prevPage={() => {
                    let element = document.getElementById("tableScrollElementHoneyydoClient");
                    if (element) {
                      element.scrollBy({
                        top: - (element.clientHeight - 100),
                        behavior: "smooth",
                      })
                    }
                  }}
                />
              </div>
              : null}
          </div>
          :
          <div className="Empty-Message">
            <h3>Your cabinet is empty</h3>
            <p>Click "Add to Cabinet" to get started storing your user manuals and warranties</p>
          </div>

        }
      </div>
      {/*delete cabinet item pop up below */}
      {popUp && (
        <>
          <div className="backdrop"></div>
          <div className="popup">
            <FontAwesomeIcon
              className="ExTriangle"
              icon={faExclamationTriangle}
            />
            <h2 className="Pop-Up-Question">
              Are you sure you want to delete?
            </h2>
            <div className="Pop-Up-Buttons">
              <button
                className="Delete-Button"
                onClick={() => {

                  sqlService
                    .deleteCabinet({
                      user: user,
                      input: cabinetToDelete,
                    })
                    .then(() => {
                      loaded.current = false;
                      setRefresh(!refresh)
                      // console.log("Delete successful");
                      // sqlService
                      //   .getCabinets({ user: user })
                      //   .then((data) => {
                      //     const cabinets = data.data;
                      //     const categories = {};
                      //     categories["All"] = 1;
                      //     for (let item of cabinets) {
                      //       categories[item.room] = 1;
                      //     }
                      //     setCabinets(cabinets);
                      //     closePopUp();
                      //   })
                      //   .catch((err) => {
                      //     console.log(err);
                      //   });
                      closePopUp();
                    })
                    .catch((err) => {
                      console.log("Error deleting cabinet", err);
                    });
                }}
              >
                Delete
              </button>
              <button className="Cancel-Button" onClick={closePopUp}>
                Cancel
              </button>
            </div>
          </div>
        </>
      )}
      {/*add cabinet pop up below*/}
      <Modal
        show={addCabinetView || editCabinetView}
        onHide={closeAddCabinet}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{"Add To Cabinet"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="Cabinet-Input-Container">
            <div className="Input-Wrap">
              <div className="Input-Div">
                <span className="">Appliance</span>
              </div>
              <input
                className="Input-Style"
                type="text"
                value={appliance}
                onChange={(e) => setAppliance(e.target.value)}
                style={{ width: '75%' }}
                required
              />
            </div>
            <div className="Input-Wrap">
              <div className="Input-Div">
                <span className="">Room</span>
              </div>
              <input
                className="Input-Style"
                type="text"
                value={room}
                onChange={(e) => setRoom(e.target.value)}
                style={{ width: '75%' }}
              />
            </div>
            <div className="Input-Wrap">
              <div className="Input-Div">
                <HoverHint
                  hint={'Upload a PDF or image file or paste a link'}
                  widthMaxContent
                />
                <span>Manual</span>
              </div>
              <div className="position-relative">
                <input
                  className="Input-Style"
                  type="text"
                  value={manualLink ?? manualName ?? manualUpload}
                  onChange={(e) => setManualLink(e.target.value)}
                  style={{ width: '75%' }}
                />
                {/* {manualUpload ?
              <span className="orange position-absolute right-10-px top-2-px cursor-pointer"><FontAwesomeIcon icon={faPaperclip} onClick={() => (setManualName(''), setManualUpload(null))} /></span>
              : null}
            */}
                <FontAwesomeIcon
                  className="cursor-pointer grey padding-left-10-px"
                  transform={{ rotate: -45 }}
                  icon={faPaperclip}
                  onClick={(e) => handleManualUpload(e)}
                />
                <input
                  className='display-none'
                  type="file"
                  ref={manualFile}
                  accept="image/* pdf/*"
                  // onInput={() => { console.log("df") }}
                  onChange={(e) => { handleManualChange(e) }}
                />
              </div>

            </div>
            <div className="Input-Wrap">
              <div className="Input-Div">
                <HoverHint
                  hint={'Upload a PDF or image file or paste a link'}
                  widthMaxContent

                />
                <span>Warranty</span>
              </div>
              <div>
                <input
                  className="Input-Style"
                  type="text"
                  value={warrantyLink ?? warrantyName ?? warrantyUpload}
                  onChange={(e) => { setWarrantyLink(e.target.value) }}
                  style={{ width: '75%' }}
                />
                <FontAwesomeIcon
                  className="cursor-pointer grey padding-left-10-px"
                  transform={{ rotate: -45 }}
                  icon={faPaperclip}
                  onClick={(e) => handleWarrantyUpload(e)}
                />
                <input
                  className='display-none'
                  type="file"
                  ref={warrantyFile}
                  accept="image/* pdf/*"
                  onChange={(e) => { handleWarrantyChange(e) }}
                />
              </div>

            </div>
          </div>
        </Modal.Body>
        <Modal.Footer >
          <div className=" display-flex align-items-center margin-bottom-10-px float-right ">
            {serverMessage1?.color !== "none" ? (
              <span
                className={`margin-right-20-px ${serverMessage1 === "red"
                  ? "red-background"
                  : serverMessage1 === "green"
                    ? "green-background"
                    : ""
                  }`}
              >
                {serverMessage1?.message}
              </span>
            ) : null}
            <span
              className="margin-right-10-px"
              onClick={submitCabinet}
            >
              <button className="Tasklist-Button height-40-px ">
                {saving ? <Spinner style={{ height: '20px', width: '20px' }} /> : 'Save'}

              </button>
            </span>
            <Button variant="secondary height-40-px" onClick={closeAddCabinet}>
              Close
            </Button>
          </div>


        </Modal.Footer>
      </Modal>
      <Modal
        show={documentShow}
        onHide={() => setDocumentShow(false)}
        id='Viewer'
        keyboard={false}
        size="lg">
        {/* <iframe ref={iframe} id="frame" style={{display: 'none'}}  src={doc}></iframe>
         */}


        {!`${doc}`.toString().match('.*pdf$') ?

          <img src={doc}></img>
          :

          <div>

            <Document file={doc} onLoadSuccess={onDocumentLoadSuccess} >
              <Page pageNumber={pageNumber}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                customTextRenderer={false}
              // style={{width: '200px'}}

              />
              <Outline onItemClick={onItemClick} />
            </Document>
            <p>
              Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
            </p>
            <div className="display-flex justify-content-center" style={{ paddingBottom: '20px' }}>
              <button
                className="Cabinet-Button Viewer-Button"
                type="button"
                disabled={pageNumber <= 1}
                onClick={previousPdfPage}

              >
                <FontAwesomeIcon icon={faCaretLeft} />
              </button>
              <button
                className="Cabinet-Button Viewer-Button"
                type="button"
                disabled={pageNumber >= numPages}
                onClick={nextPdfPage}

              >
                <FontAwesomeIcon icon={faCaretRight} />
              </button>
            </div>
          </div>

        }
        {/* <Modal.Footer>
          <FontAwesomeIcon
            icon={faPrint}
            className="print-icon float-right"
            onClick={print}
          />
        </Modal.Footer> */}


      </Modal>
      <ScrollToTop />
      <PoweredBy />
      <PopupMessage onConfirm={() => { setServerMessage(null) }} message={serverMessage?.message} title={serverMessage?.title} show={serverMessage !== null} />
    </>
  );
}
