import React, { useEffect, useRef, useState } from "react"
import stripeService from "../../../services/stripeService";
import "./agentEmailService.css";
import sqlService from "../../../services/sqlService";
import { Modal, ModalBody, Button } from "react-bootstrap";
import searchUtility from "../../../functions/searchUtility";
import { AiOutlineSearch } from "react-icons/ai";
import { faCaretRight, faEllipsisVertical, faEnvelope, faPlus, faPaperclip, faX, faEdit, faEye, faCircleXmark, faImage, faAlignLeft, faPenFancy, faPenNib, faFont, faRepeat, faArrowLeftLong, faTrash, faXmark, faPaperPlane, faArrowRight, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../../App";
import { useContext } from "react";
import DatePicker from "react-datepicker";
import { Spinner } from "react-bootstrap";

import validateUtility from "../../../functions/validateUtility";
import inputConstants from "../../../constants/inputConstants";
import PopupMessage from "../../utility.component/popupMessage.component/popupMessage.component";
import sqlEcardsService from "../../../services/sqlEcardService";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { useLocation, Link } from "react-router-dom";

export default function EmailHistory() {
    const [minimumCreationDate1, setMinimumCreationDate1] = useState(null);
    const [maximumCreationDate1, setMaximumCreationDate1] = useState(null);

    const [displayMinimumCreationDatePicker1, setDisplayMinimumCreationDatePicker1] = useState(false);
    const [displayMaximumCreationDatePicker1, setDisplayMaximumCreationDatePicker1] = useState(false);


    const [searchKeywordFilter1, setSearchKeywordFilter1] = useState("");

    const [filteredBySearchRecords, setFilteredBySearchRecords] = useState([]);
    const [minimumCreationFilteredRecords, setMinimumCreationFilteredRecords] = useState([]);
    const [maximumCreationFilteredRecords, setMaximumCreationFilteredRecords] = useState([]);
    const [selectedViewEmail, setSelectedViewEmail] = useState(null);
    const [selectedDurationFilter1, setSelectedDurationFilter1] = useState("Homeowners");
    const [emailRecords, setEmailRecords] = useState([]);
    const [maximumCreationDate, setMaximumCreationDate] = useState(null);
    const allowedFields = {'recipient_email': 'Email Address', 'recipient_name': 'Name', 'subject': 'Subject', 'creation_time': 'Sent'}

    const resetFilters1 = () => {
        setMaximumCreationDate1(null);
        setMinimumCreationDate1(null);
        setSearchKeywordFilter1("");
        setSelectedDurationFilter1("Homeowners");
        // setCategoryFilter("Agent_Email");
        // setSubCategoryFilter("Agent_Email");
    }

  
    useEffect(()=>{
        sqlService.getEmailRecordsByUserId().then(d => {
            console.log(d);
            setEmailRecords(d?.data)
        }).catch(err => {
            console.log(err);
        })
    }, [])

    useEffect(() => {
        if (searchKeywordFilter1 !== "") {
            let filteredBySearch = searchUtility.filterBySearchArrayOfObjects(emailRecords, searchKeywordFilter1)
            setFilteredBySearchRecords(filteredBySearch)

        } else {
            setFilteredBySearchRecords(emailRecords);
        }
    }, [searchKeywordFilter1, emailRecords]);
    useEffect(() => {
        if (minimumCreationDate1) {
            var pass = []
            let min = new Date(minimumCreationDate1)
            for (let item of filteredBySearchRecords) {
                let dat = new Date(item?.creation_time);
                if (dat >= min) {
                    pass.push(item);
                }
            }
            setMinimumCreationFilteredRecords(pass);
        }
        else {
            setMinimumCreationFilteredRecords(filteredBySearchRecords)
        }
    }, [filteredBySearchRecords, minimumCreationDate1])

    useEffect(() => {
        if (maximumCreationDate1) {
            var pass = []
            let max = new Date(maximumCreationDate1)
            for (let item of minimumCreationFilteredRecords) {
                let dat = new Date(item?.creation_time);
                if (dat <= max) {
                    pass.push(item);
                }
            }
            setMaximumCreationFilteredRecords(pass);
        }
        else {
            setMaximumCreationFilteredRecords(minimumCreationFilteredRecords)
        }
    }, [minimumCreationFilteredRecords, maximumCreationDate1]);


    return (
   
        <div className="display-flex flex-direction-column column-gap-20-px row-gap-20-px">
               
                <div style={{marginTop: '5%'}} className="display-flex flex-wrap align-items-center justify-content-center column-gap-10-px row-gap-10-px">
                    
                    <h4 className="grey">Email History</h4>
                </div>
                  {/* <h2 className="Blog-Page-Heading">Email History</h2> */}

                <div className="display-flex flex-wrap column-gap-10-px row-gap-10-px align-items-center justify-content-center padding-10-px border-radius-10-px">
                    <div className="display-flex flex-direction-column column-gap-10-px row-gap-10-px">
                     
                        <div className="Search-Bar-Focus position-relative background-color-light-grey height-fit-content border-radius-5-px">
                            <span
                                className="position-absolute left-8-px top-4-px purple border-radius-5-px"
                            >
                                <AiOutlineSearch />
                            </span>
                            <input
                                className="Search-Bar"
                                type="text"
                                value={searchKeywordFilter1}
                                onChange={(e) => { setSearchKeywordFilter1(e.currentTarget.value) }}
                                placeholder={"Search"}
                            />
                        </div>
                        {/* <span style={{alignSelf: 'center'}} onClick={() => { resetFilters1() }} className="bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px">Clear Filter</span> */}
                    </div>
                    <div className="display-flex flex-direction-column row-gap-5-px">


                        <div className="display-flex flex-direction-column">
                            <div className="display-flex position-relative flex-wrap justify-content-end align-items-center column-gap-5-px row-gap-5-px">
                                <span className="cursor-pointer" onClick={() => { setDisplayMinimumCreationDatePicker1(true); setDisplayMaximumCreationDatePicker1(false) }}>{minimumCreationDate1 ? minimumCreationDate1.toString()?.split('00:00:00')[0] : 'Minimum Creation Date'}</span>
                                {!minimumCreationDate1 ?
                                <FontAwesomeIcon onClick={() => { setDisplayMinimumCreationDatePicker1(true) }} className="cursor-pointer" icon={faPlus} />
                                : null}
                                {(displayMinimumCreationDatePicker1) ?
                                    <div className="background-color-light-grey position-absolute diffuse-shadow" style={{ minHeight: '310px', top: '100%', textAlign:'right', zIndex: '10'  }}>
                                        <DatePicker className="Input-Style background-color-light-grey"
                                            minDate={null}
                                            maxDate={maximumCreationDate1 ?? null}

                                            selected={minimumCreationDate1}
                                            onChange={(value) => { console.log(value); setMinimumCreationDate1(value); setDisplayMinimumCreationDatePicker1(false); }}
                                            inline
                                            // showTimeSelect
                                        //   filterTime={filteredTimes}

                                        />
                                        <span className="bold padding-5-px cursor-pointer border-radius-5-px" onClick={() => { setDisplayMinimumCreationDatePicker1(false); }}>Cancel</span>
                                    </div> : null}
                                {minimumCreationDate1 ? <span className="bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px" onClick={() => { setMinimumCreationDate1(null) }}>Clear</span> : null}
                            </div>
                            {/* {minimumCreationDate1 ? <span className="cursor-pointer" onClick={() => { setDisplayMinimumCreationDatePicker1(true); }}>{minimumCreationDate1.toString().split("GMT")[0]}</span> : null} */}

                        </div>
                        <div className="display-flex flex-direction-column">
                            <div className="display-flex position-relative flex-wrap justify-content-end align-items-center column-gap-5-px row-gap-5-px">
                                <span className="cursor-pointer" onClick={() => { setDisplayMaximumCreationDatePicker1(true); setDisplayMinimumCreationDatePicker1(false) }}>{maximumCreationDate1 ? maximumCreationDate1.toString()?.split('00:00:00')[0] : 'Maximum Creation Date'}</span>
                                {!maximumCreationDate1 ?
                                <FontAwesomeIcon onClick={() => { setDisplayMaximumCreationDatePicker1(true) }} className="cursor-pointer" icon={faPlus} />
                                : null}
                                {(displayMaximumCreationDatePicker1) ?
                                    <div className="background-color-light-grey position-absolute diffuse-shadow" style={{ minHeight: '310px', top: '100%', textAlign:'right', zIndex: '10'  }}>
                                        <DatePicker className="Input-Style background-color-light-grey"
                                            minDate={minimumCreationDate1 ?? null}
                                            maxDate={null}

                                            selected={maximumCreationDate1}
                                            onChange={(value) => { console.log(value); setMaximumCreationDate1(value); setDisplayMaximumCreationDatePicker1(false); }}
                                            inline
                                            // showTimeSelect
                                        //   filterTime={filteredTimes}

                                        />
                                        <span className="bold padding-5-px cursor-pointer border-radius-5-px" onClick={() => { setDisplayMaximumCreationDatePicker1(false); }}>Cancel</span>
                                    </div> : null}
                                {maximumCreationDate1 ? <span className="bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px" onClick={() => { setMaximumCreationDate1(null) }}>Clear</span> : null}
                            </div>
                            {/* {maximumCreationDate1 ? <span className="cursor-pointer" onClick={() => { setDisplayMaximumCreationDatePicker1(true); }}>{maximumCreationDate1.toString().split("GMT")[0]}</span> : null} */}

                        </div>
                        {/* <span style={{alignSelf: 'end'}} onClick={() => { resetFilters1() }} className="bold margin-top-10-px background-color-light-grey padding-5-px cursor-pointer border-radius-5-px">Clear Filter</span> */}

                    </div>
                </div>

                <div className="display-flex flex-wrap justif-content-center align-items-center flex-direction-column column-gap-10-px row-gap-10-px">
                    {maximumCreationFilteredRecords?.length ?
                    
                    <table class="styled-table">
                        <thead>
                            <tr>
                                {maximumCreationFilteredRecords[0] && Object.keys(maximumCreationFilteredRecords[0]).filter(item => allowedFields[item]).map((item1, index) =>
                                    <th key={`filter ${index}`}>{allowedFields[item1]}</th>
                                )}
                                <th>Message</th>
                            </tr>
                        </thead>
                        <tbody>
                            {maximumCreationFilteredRecords.map((item11, idx) =>
                                <tr>

                                    {Object.keys(maximumCreationFilteredRecords[maximumCreationFilteredRecords.length - 1 - idx]).filter(item => allowedFields[item]).map(item1 =>
                                        <td key={idx}>{item1 === 'creation_time' ? maximumCreationFilteredRecords[maximumCreationFilteredRecords.length - 1 - idx][item1]?.split('T')[0] : maximumCreationFilteredRecords[maximumCreationFilteredRecords.length - 1 - idx][item1]}</td>
                                    )}
                                    <td><FontAwesomeIcon onClick={() => { setSelectedViewEmail(maximumCreationFilteredRecords[maximumCreationFilteredRecords.length - 1 - idx]["content"]) }} className="cursor-pointer" icon={faEnvelope} /></td>
                                </tr>)}

                        </tbody>
                    </table>
                    : <h5 className="grey">No emails to show</h5> }

                </div>


                <Modal
                    show={selectedViewEmail}
                    onHide={() => setSelectedViewEmail(null)}
                
                    keyboard={false}
                    size="lg"
                
                    focus={true}
                >
                    <ModalBody >

                     
                        <div className="display-flex justify-content-center flex-wrap">

                            <div dangerouslySetInnerHTML={{
                                __html: selectedViewEmail ? selectedViewEmail : ""
                            }}></div>
                        </div>

                    </ModalBody>
                    <Modal.Footer >

                        <Button variant="secondary height-40-px" onClick={() => setSelectedViewEmail(null)}>
                            Close
                        </Button>



                    </Modal.Footer>

                </Modal>


            </div>
    )
}