import React, { useEffect, useRef, useState } from "react";
import inputConstants from "../../constants/inputConstants";

function GoogleAutoComplete({address1FieldRef, callBack, addresses, addressKey, specifyCountry}) {

    const autoCompleteRef = useRef();

    const loaded = useRef(false);

    function fillInAddress(place) {
        // Get the place details from the autocomplete object.
        // const place = autocompleteRef.current.getPlace();

        let address1 = "";
        let postcode = "";
        let city1 = ""
        let country1 = ""
        let state1 = ""
        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.
        // place.address_components are google.maps.GeocoderAddressComponent objects
        // which are documented at http://goo.gle/3l5i5Mr
        if (place && place?.address_components) {
            for (const component of place?.address_components) {
                // @ts-ignore remove once typings fixed
                const componentType = component?.types[0];

                switch (componentType) {
                    case "street_number": {
                        address1 = `${component?.long_name} ${address1}`;
                        break;
                    }

                    case "route": {
                        address1 += component?.short_name;
                        break;
                    }

                    case "postal_code": {
                        postcode = `${component?.long_name}${postcode}`;
                        break;
                    }

                    case "postal_code_suffix": {
                        postcode = `${postcode}-${component?.long_name}`;
                        break;
                    }
                    case "locality":
                        city1 = component?.long_name;
                        break;
                    case "administrative_area_level_1": {
                        state1 = component?.short_name
                        break;
                    }
                    case "country":
                        country1 = component?.long_name
                        break;
                }
                if(address1FieldRef && address1FieldRef.current){
                    address1FieldRef.current.value = address1;
                }else if(addressKey && address1FieldRef && address1FieldRef.value){
                    let el = document.getElementById(addressKey);
                    if(el){
                        console.log(`================ > setttting id ${addressKey} value`, address1);
                        el.value = address1;
                    }
                }

                // updateNewTask("zip_code", postcode)
                // updateNewTask("city", city1)
                // updateNewTask("country", country1)
                // updateNewTask("state", state1)
                console.log(state1);
                let provicesAndStates = inputConstants.CANDADIAN_PROVINCES.concat(inputConstants.US_STATES)
                let filter = provicesAndStates.filter(item => item.value.startsWith(state1))
                if(filter && filter[0]){
                    state1 = filter[0].value;
                }
                postcode = postcode.split("-")[0]
                callBack(postcode, city1, country1, state1, address1, addresses ?? null);

                // zipCodeRef.current.value = postcode;

            }
        }
        // setRefresh(refresh => !refresh);
    }

    const options = {
        componentRestrictions: { country: ["us", "ca"] },
        fields: ["address_components", "geometry"],
        types: ["address"],
    };
    const optionsCountrySpecified = {
        componentRestrictions: { country: [specifyCountry === "Canada" ? "ca" : "us"] },
        fields: ["address_components", "geometry"],
        types: ["address"],
    };
    useEffect(() => {
        if((process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "staging" || process.env.REACT_APP_ENV === "development") && !loaded.current){
            loaded.current = true;
            try{
                let el = document.getElementById(addressKey);
                autoCompleteRef.current = new window.google.maps.places.Autocomplete(
                    (address1FieldRef && address1FieldRef.current) ? address1FieldRef.current : el,
                    specifyCountry ? optionsCountrySpecified : options
                );
                async function placeChanged() {
                    const place = await autoCompleteRef.current.getPlace();
                    console.log({ place });
                    fillInAddress(place);
                }
                autoCompleteRef.current.addListener("place_changed", placeChanged);
            }catch(err){
                console.log(err);
            }
       
        }
    }, []);


    return (
        <></>
    )
}

export default GoogleAutoComplete;