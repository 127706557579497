import React, { useState, useEffect, useRef } from "react";
import Spinner from 'react-bootstrap/Spinner';


import { useNavigate } from "react-router-dom";
import sqlService from "../../../services/sqlService";
import serverResponseMessagesConstants from "../../../constants/serverResponseMessagesConstants";

import PopupMessage from "../../utility.component/popupMessage.component/popupMessage.component";
import { ImCheckmark } from "react-icons/im"

import { useParams } from "react-router-dom";
import inputConstants from "../../../constants/inputConstants";
import { FcCancel } from "react-icons/fc";

export default function PasswordResetConfirm() {
  const Navigate = useNavigate();
  const { code } = useParams();
  const { email } = useParams();
  const [serverMessage, setServerMessage] = useState(null);
  const [windowSize, setWindowSize] = React.useState(window.innerWidth);

  const [linkStatus, setLinkStatus] = useState("pending");

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  const onWindowResize = () => {
    setWindowSize(window.innerWidth);
  };

  const [checked, setChecked] = useState({ "password": "false", "confirm_password": "false" })
  const [newTask, setNewTask] = useState({});
  const [emailIsSent, setEmailIsSent] = useState(false);
  const [requested, setRequested] = useState(false);
  const [focused, setFocused] = useState(null);
  const [triggerX, setTriggerX] = useState(false);

  const loaded = useRef(false);
  useEffect(() => {
    if (!loaded.current) {
      sqlService.passwordResetConfirm({input: { "email": email, "code": code, "password": null } }).then(d => {
        setLinkStatus("success");
      }).catch(err => {
        console.log(err)
        setLinkStatus("fail");
      })
      loaded.current = true;
    }

  }, [linkStatus])

  const validatePassword = (password) => {
    return String(password)
      .toLowerCase()
      .match(
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/
      );
  }

  function updateNewTask(key, value) {
    if (key === "password") {
      if (validatePassword(value)) {
        let nChecked = { ...checked }
        nChecked["password"] = "true";
        setChecked(nChecked)
      } else {
        setChecked({ ...checked, "password": "false" })
      }
    }
    if (key === "confirm_password") {
      if (newTask?.password === value && checked.password === "true") {
        let nChecked = { ...checked }
        nChecked["confirm_password"] = "true";
        setChecked(nChecked)
      } else {
        setChecked({ ...checked, "confirm_password": "false" })
      }
    }

    let nTask = { ...newTask }
    nTask[key] = value;
    setNewTask(nTask);
  }

  const onConfirmServerMessage = () => {
    setServerMessage(null);
  }

  const resetPassword = () => {
    let pass = true;
    for (let key of Object.keys(checked)) {
      if (checked[key] !== "true") {
        pass = false;
      }
    }
    if (pass) {
      //start reset process
      setRequested(true);
      setEmailIsSent(false);
      sqlService.passwordResetConfirm({ input: { "email": email, "code": code, "password": newTask.password } }).then(d => {
        setRequested(false);
        setEmailIsSent(true);
        setTimeout(() => { Navigate("/login") }, 2000)
      }).catch(err => {
        setRequested(false);
        setEmailIsSent(false);
      })
    } else {
      if (!triggerX) {
        setTriggerX(true);
      }
    }

  }


  return (
    <div className="Signup-Spacing-Div" >
      <div className="Password-Reset-Background" >
        <div className="margin-top-30-px">
          {linkStatus === "success" ?

            <div className="Signup-Border align-items-center display-flex flex-direction-column ">

              <div className="Signup-Container padding-20-px  column-gap-30-px">
                <div className="margin-bottom-40-px  display-flex flex-wrap flex-direction-column justify-content-center  column-gap-10-px row-gap-10-px">

                  {!emailIsSent ?
                    <>
                      <div className=" display-flex flex-direction-row margin-bottom-10-px flex-wrap position-relative">
                        <span className="Signup-Label background-linear-gradient padding-5-px  white">New Password*</span>
                        <input onFocus={() => { setFocused(`*Minimum ${inputConstants.PASSWORD_MINIMUM_LENGTH} characters, including a special character and number `) }} className={`Signup-Input-Style flex-grow-1 ${(triggerX && checked?.password !== "true") ? "Input-Error-Border" : ""}`} type="password" value={newTask?.password ?? ""} onChange={(e) => { updateNewTask("password", e.currentTarget.value); }} pattern="/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/" />

                        {checked?.password === "true" ?
                          <span className="orange position-absolute right-10-px top-2-px"><ImCheckmark /></span>
                          : null}
                        {(triggerX && checked?.password !== "true") ?
                          <span className="Finish-Check1"><FcCancel /></span>
                          : null}
                      </div>


                      <div className=" display-flex flex-direction-row margin-bottom-10-px flex-wrap position-relative">
                        <span className="Signup-Label background-linear-gradient  padding-5-px   white">Confirm Password*</span>
                        <input onFocus={() => { setFocused(`*Minimum ${inputConstants.PASSWORD_MINIMUM_LENGTH} characters, including a special character and number`) }} className={`Signup-Input-Style flex-grow-1 ${(triggerX && checked?.confirm_password !== "true") ? "Input-Error-Border" : ""}`} type="password" value={newTask?.confirm_password ?? ""} onChange={(e) => { updateNewTask("confirm_password", e.currentTarget.value); }} pattern="/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/" />

                        {checked?.confirm_password === "true" ?
                          <span className="orange position-absolute right-10-px top-2-px margin-bottom-10-px"><ImCheckmark /></span>
                          : null}
                        {(triggerX && checked?.confirm_password !== "true") ?

                          <span className="Finish-Check1"><FcCancel /></span>
                          : null}
                      </div>
                      <div className='' >
                        <span className="Signup-Hint padding-x-10-px align-items-center display-flex justify-content-center grey">{focused}</span>
                      </div>
                      <button style={{ width: windowSize > 499 ? '350px' : '200px', height: '40px', padding: '0' }} className="submit margin-bottom-10-px" type="submit" onClick={() => { resetPassword() }} disabled={requested}>Reset Password</button>
                    </> : null
                  }

                  {requested ? <div className="display-flex align-items-center justify-content-center"><Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner></div> : null}
                  {emailIsSent ?
                    <span className="">Your password is updated successfully.</span>
                    : null}

                </div>

              </div>



            </div>
            : null
          }
          {linkStatus === "pending" ?
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            : null}
          {linkStatus === "fail" ?
            <div className='' >
              <span className="grey-font-color padding-x-10-px align-items-center display-flex justify-content-center grey font-size-25-px">Error 403 forbidden</span>
            </div>
            : null}
        </div>
      </div>
    </div>
  );
}
