import logo from "./logo.svg";
import "./App.css";
import './App.scss';
// import { useNavigate } from "react-router-dom";

import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes, json, Navigate, Outlet } from "react-router-dom";
import Footer from "./components/main.component/footer.component/footer.component";
import TaskList from "./components/homeowner.component/tasklist.component/tasklist.component";

import Header from "./components/main.component/header.component/header.component";
import About from "./components/universal.component/about.component/about.component";
import Faq from "./components/universal.component/faq.component/faq.component";
import Contact from "./components/universal.component/contact.component/contact.component";
import TOS from "./components/universal.component/library.component/tos.component/tos.component";
import PrivacyPolicy from "./components/universal.component/privacy-policy.component/privacy-policy.component";

import Landing from "./components/main.component/landing.component/landing.component";
import Cabinet from "./components/homeowner.component/cabinet.component/cabinet.component";
import FindPro from "./components/homeowner.component/findaPro.component/findPro";
import Profession from "./components/homeowner.component/professions.component/profession";
// import Blog from "./components/universal.component/blog.component/blog.component";
import Blogs from "./components/universal.component/library.component/library.component";
// import Content from "./components/universal.component/content.component/content.component";

import Error404 from "./components/error404.component/error404";
import Settings from "./components/main.component/settings.component/settings.component";
import HomeownerDashboard from "./components/homeowner.component/homeownerlanding.component/homeownerlanding.component";
import InsuranceAgentHome from "./components/agent.component/insuranceAgentlanding.component/insuranceAgentlanding.component";
import MortgageAgentHome from "./components/agent.component/mortgageAgentlanding.component/mortgageAgentlanding.component";
import BrokerageHome from "./components/brokerage.component/brokeragelanding.component/brokeragelanding";
import ManageAgent from "./components/brokerage.component/manageAgent.component/manageAgent.component";

import ManagePro from "./components/agent.component/managePro.component/managePro.component";

import Login from "./components/main.component/login.component/login.component";
import Signup from "./components/main.component/signup.component/signup.component";
import Pricing from "./components/universal.component/pricing.component/pricing.component";
import Clients from "./components/agent.component/clients.component/clients.component"
import AdminDashboard from "./components/main.component/adminDashboard.component/adminDashboard.component"
import NewLanding from "./components/main.component/newLanding.component/newLanding.component";
// import BlogView from "./components/universal.component/library.component/BlogPost.component";

import { useEffect, useRef, useState } from "react";
import { useLocation } from 'react-router-dom';
import { createContext, useContext } from "react";
import sqlService from "./services/sqlService";

import Cookies from "universal-cookie";
import EcardsGifts from "./components/agent.component/ecardsGifts.component/ecardsGifts.component";
import EcardsGiftsComingSoon from "./components/agent.component/ecardsGifts.component/ecardsComingSoon.component";

import AddPayment from "./components/main.component/addPayment.component/addPayment.component";
import { HashRouter } from "react-router-dom";
import PasswordResetRequest from "./components/main.component/login.component/passwordResetRequest.component";
import PasswordResetConfirm from "./components/main.component/login.component/passwordResetConfirm.component";
import { useSyncExternalStore } from 'react';

import { useNavigate } from "react-router-dom";
import BlogPost from "./components/universal.component/library.component/BlogPost.component";
import BlogParent from "./components/universal.component/library.component/BlogParent.component";
import ProSignup from "./components/universal.component/proSignup.component/proSignup.component";
import QuoteForm from "./components/universal.component/quoteForm.component/quoteForm.component";
import AgentCalendar from "./components/agent.component/agentcalendar.component/agentcalendar.component";

import AdminStripControl from "./components/main.component/adminStripeControl.component/adminStripeControl.component";

import AdminAssociationsControl from "./components/main.component/adminAssociationsControl.component/adminAssociationsControl.component";
import AdminDetailView from "./components/main.component/adminDetailView.component/adminDetailView.component";

import Unsubscribe from "./components/main.component/unsubscribe.component/unsubscribe.component";
import Invoice from "./components/main.component/invoice.component/invoice.component";
import AffiliateProgram from "./components/universal.component/affiliateProgram.component/affiliateProgram.component";
import PropertyPage from "./components/homeowner.component/property.component/property.component";
import ProInfo from "./components/universal.component/proSignup.component/proInfo.component";

import ProDashboard from "./components/pro.component/proDashboard.component/proDashboard.component";
import ProPending from "./components/universal.component/proSignup.component/proPending.component";

import ProVerify from "./components/universal.component/proSignup.component/proVerify.component";

import AdminEmailService from "./components/main.component/adminEmailService.component/adminEmailService.component";
import ClientDetailView from "./components/agent.component/clientDetailView.component/clientDetailView.component";
import AgentEmailService from "./components/agent.component/agentEmailService.component/agnetEmailService.component";

import AgentLanding from "./components/agent.component/agentlanding.component/agentlanding.component";

import AgentNewsletter from "./components/agent.component/agentNewslettter.component/agentNewsletter.component";
import EmailHistory from "./components/agent.component/agentEmailService.component/emailHistory.component";

export const UserContext = createContext({ user: null });

export const userState = { "locked": "false" }



export function useOnlineIndicator() {
  const isOnline = useSyncExternalStore(subscribe, getSnapshot);
  return isOnline;
}






function getSnapshot() {
  return navigator.onLine;
}

function subscribe(callback) {
  window.addEventListener('online', callback);
  window.addEventListener('offline', callback);
  return () => {
    window.removeEventListener('online', callback);
    window.removeEventListener('offline', callback);
  };
}

const reportPageView = () => {
  const page_path = window.location.pathname + window.location.search;
  if (window.gtag) {
    window.gtag('config', 'G-NNSQRN5S36', { page_path });
  }
};


function PageViewTracker() {
  const location = useLocation();

  useEffect(() => {
    // Assuming reportPageView function is defined or imported
    reportPageView();
  }, [location]);

  return null; // This component does not render anything
}



function App() {
  // const navigate = useNavigate();
  const loaded = useRef(false);

  // const Navigate = useNavigate();
  const userRef = useRef();

  const [user, setUser] = useState(() => {
    const cookie = new Cookies();
    // cookie.get("honeyydo_user_cookie")
    var userData = cookie.get("honeyydo_user_cookie");
    if (userData?.user) {
      userData = { "data": userData?.user }
    }
    if (userRef.current) {
      setUser(userRef.current)
    }
    else if (userData) {
      // setUser(userData.data)
      // console.log(JSON.parse(cookie))
      // setUser(cookie.getAll)
      return userData.data;
    }
    return null;
  })

  const getUserCookie = () => {
    const cookie = new Cookies();
    // cookie.get("honeyydo_user_cookie")
    const userData = cookie.get("honeyydo_user_cookie");
    if (userRef.current) {
      setUser(userRef.current)
    }
    else if (userData) {
      // setUser(userData.data)
      // console.log(JSON.parse(cookie))
      // setUser(cookie.getAll)
      return userData.data;
    }
    return null;
  }


  useEffect(() => {
    // debugger;
    console.log("visited: ", window.location.href)
    // document.body.className = document.body.className.replace("grecaptcha-badge","");
    // let captch = document.getElementsByClassName("grecaptcha-badge");
    // if(captch && captch["0"] && captch["0"].style){
    //     captch["0"].style.visibility = "none"
    //     console.log(captch["0"].style.visibility);
    // }
    console.log(user)
    // if (!loaded.current) {
    if (user) {
      if (user.role_id === 1) {
        sqlService.getHomeownerById({}).then((res) => {
          let data = res.data;
          data["token"] = user.token;
          setUser(data);
          setCookie(data);
        }).catch(err => {
          console.log(err)
        })

      } else if (user.role_id === 2) {
        sqlService.getAgentById({}).then((res) => {
          let data = res.data;
          // console.log("====> user: ", data)
          data["token"] = user.token;
          // console.log("Data",data)
          // debugger; 
          setUser(data);
          setCookie(data);

          // for (let row of data) {
          //   setUser(previousState => ({
          //     ...previousState,
          //     ["rating" + row.type_id]: row.rating
          //   }))

          // }
        }).catch(err => {
          console.log(err)
        })

      } else if (user.role_id === 3) {
        sqlService.getBrokerageById({}).then((res) => {
          let data = res.data;
          data["token"] = user.token;
          setUser(data);
          // console.log(res)
          // console.log("====> user: ", data)
          setCookie(data);
        }).catch(err => {
          console.log(err)
        })
      }
      else if (user.role_id === 4) {
        sqlService.getProById({}).then((res) => {
          // debugger;
          let data = res.data;
          console.log(data);
          data["token"] = user.token;
          setUser(data);
          // console.log(res)
          // console.log("====> user: ", data)
          setCookie(data);
        }).catch(err => {
          console.log(err)
        })
      }
      else if (user.role_id === 5) {
        sqlService.getMortgageAgentById({}).then((res) => {
          let data = res.data;
          data["token"] = user.token;
          setUser(data);
          setCookie(data);
        }).catch(err => {
          console.log(err)
        })

      }
      else if (user.role_id === 6) {
        sqlService.getInsuranceAgentById({}).then((res) => {
          let data = res.data;
          data["token"] = user.token;
          setUser(data);
          setCookie(data);
        }).catch(err => {
          console.log(err)
        })

      }
      loaded.current = true;
    }
    // }

  }, []);

  const setUserFun = (newUser) => {
    setUser(newUser);
    userRef.current = newUser;
  }

  function setCookie(data) {
    //cookie expires in one month
    //secures means only accessible using https
    //you can also add domain which is the domain of the cookie
    console.log(data);
    // debugger;
    document.cookie = `honeyydo_user_cookie=${JSON.stringify({ data })}; expires=${new Date(Date.now() + (30 * 86400000))}; path=/; sameSite=strict; secure`;
    //3 minutes document.cookie = `honeyydo_user_cookie=${JSON.stringify({ data })}; expires=${new Date(Date.now() + (30 * 605000))}; path=/; sameSite=strict; secure`;
    //window.location.reload();
  }


  const AdminRoute = ({ user }) => {
    if (!user || user.role_id !== 0) {
      return <Navigate to="/*" replace />;
    }
    return <Outlet />;
  };


  const BrokerageRoute = ({ user }) => {
    if (!user || user.role_id !== 3) {
      return <Navigate to="/*" replace />;
    }
    return <Outlet />;
  };

  const AgentRoute = ({ user }) => {
    if (!user || (user.role_id !== 2 && user.role_id !== 0)) {
      return <Navigate to="/*" replace />;
    }
    return <Outlet />;
  };

  const AgentOptionsRoute = ({ user }) => {
    if (!user || (user.role_id !== 2 && user.role_id !== 0 && user.role_id !== 5 && user.role_id !== 6)) {
      return <Navigate to="/*" replace />;
    }
    return <Outlet />;
  };

  const MortgageAgentRoute = ({ user }) => {
    if (!user || (user.role_id !== 5 && user.role_id !== 0)) {
      return <Navigate to="/*" replace />;
    }
    return <Outlet />;
  };

  const InsuranceAgentRoute = ({ user }) => {
    if (!user || (user.role_id !== 6 && user.role_id !== 0)) {
      return <Navigate to="/*" replace />;
    }
    return <Outlet />;
  };

  const HomeownerRoute = ({ user }) => {
    if (!user || user.role_id !== 1) {
      return <Navigate to="/*" replace />;
    }
    return <Outlet />;
  };

  const ProRoute = ({ user }) => {
    if (!user || user.role_id !== 4) {
      return <Navigate to="/*" replace />;
    }
    return <Outlet />;
  };



  const UserRoute = ({ user }) => {
    if (!user || user.role_id === 0) {
      return <Navigate to="/*" replace />;
    }
    return <Outlet />;
  };


  return (
    <UserContext.Provider value={user}>
      <HashRouter>
        {/* <BrowserRouter> */}

        {/* For google analytics */}
        <PageViewTracker />

        {/* The rest of your app goes here */}
        <Header setUser={setUserFun} />
        <div className="body">
          <Routes>
            <Route path="*" element={<Error404 />} />
            <Route path="/" element={<NewLanding />} />
            <Route path="/about" element={<About />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/terms-of-service" element={<TOS />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />


            {/* <Route path="/blogs" element={<Blog />} /> */}
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blog/:id" element={<BlogParent />} />
            {/* <Route path="/content/:pageTitle/:itemId" element={<Content />} /> */}
            <Route path="/login" element={<Login setUser={setUserFun} />} />
            <Route // example: /signup/1/newaccount/2/2/2
              path="/signup/:role_id/:link/:parent/:senderId/:senderRoleTypeId"
              element={<Signup setUser={setUserFun} />}
            />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/home" element={<NewLanding />} />
            <Route path="/password-reset-request" element={<PasswordResetRequest />} />
            <Route path="/password-reset-confirm/:email/:code" element={<PasswordResetConfirm />} />
            <Route path="/request-a-quote" element={<QuoteForm />} />
            <Route path="/unsubscribe/:roleId/:code/:email?" element={<Unsubscribe />} />
            <Route path="/affiliate-program" element={<AffiliateProgram />} />

            <Route element={<UserRoute user={user} />}>
              <Route path="/settings" element={<Settings setUser={setUserFun} />} />
              <Route path="/add-payment/:subscriptionType/:plan" element={<AddPayment setUser={setUserFun} />} />
              <Route path="/invoice" element={<Invoice />} />
            </Route>

            <Route element={<AdminRoute user={user} />}>
              <Route path="/admin-dashboard" element={<AdminDashboard />} />
              <Route path="/admin-stripe-control" element={<AdminStripControl />} />
              <Route path="/admin-associations-control" element={<AdminAssociationsControl />} />
              <Route path="/admin/:userType/:id" element={<AdminDetailView />} />
              <Route path="/admin-email-service" element={<AdminEmailService />} />
            </Route>

            <Route element={<BrokerageRoute user={user} />}>
              <Route path="/brokerage-home" element={<BrokerageHome />} />
              <Route path="/manage-an-agent" element={<ManageAgent />} />
            </Route>

            <Route element={<AgentOptionsRoute user={user} />}>
              <Route path="/manage-a-pro" element={<ManagePro />} />
              <Route path="/clients" element={<Clients />} />
              <Route path="/ecards" element={<EcardsGifts />} />
              <Route path="/calendar" element={<AgentCalendar />} />
              <Route path="/client/:id" element={<ClientDetailView />} />
              <Route path="/agent-email-service" element={<AgentEmailService />} />
              <Route path="/agent-newsletter" element={<AgentNewsletter />} />
              <Route path="/email-history" element={<EmailHistory/>} />

            </Route>

            <Route element={<AgentRoute user={user} />}>
              <Route path="/agent-home" element={<AgentLanding />} />
            </Route>

            <Route element={<MortgageAgentRoute user={user} />}>
              <Route path="/mortgage-agent-home" element={<MortgageAgentHome />} />
            </Route>

            <Route element={<InsuranceAgentRoute user={user} />}>
              <Route path="/insurance-agent-home" element={<InsuranceAgentHome />} />
            </Route>

            <Route element={<HomeownerRoute user={user} />}>
              <Route path="/dashboard" element={<HomeownerDashboard />} />
              <Route path="/tasklist" element={<TaskList />} />
              <Route path="/filing-cabinet" element={<Cabinet />} />
              <Route path="/find-a-pro" element={<FindPro />} />
              <Route path="/profession/:profession" element={<Profession />} />
              <Route path="/my-property" element={<PropertyPage />} />
            </Route>

            <Route element={<ProRoute user={user} />}>
              <Route path="/pro-dashboard" element={<ProDashboard setUser={setUser} />} />
              <Route path="/pro_verify" element={<ProVerify />} />
              <Route path="/pro_pending" element={<ProPending />} />
            </Route>

            <Route path="/pro-signup/:tier/:agentId?/:plan?" element={<ProSignup />} />
            <Route path="/pro-info" element={<ProInfo />} />




          </Routes>
        </div>
        <Footer style={{ marginTop: "auto" }} />
      </HashRouter>
      {/* </BrowserRouter> */}
    </UserContext.Provider>
  );
}

export default App;
