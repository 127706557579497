import React, { useState } from "react";
import '../../../../node_modules/react-vis/dist/style.css';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalBarSeries,
  Hint,
  DiscreteColorLegend
} from 'react-vis';



export default function BarChart({ barColor, barData, maxValue, xLabel, secondBar, secondBarColor, labels, screenWidth }) {
  const [hoveredBar, setHoveredBar] = useState(null)


  return (
    <>
      <XYPlot
        xType="ordinal"
        width={screenWidth < 415 || (screenWidth > 700 && screenWidth < 800)  ? 250 : 300}
        height={200}
        xDistance={100}
        yDomain={[0, Math.round(maxValue * 1.25)]}
      >

        <XAxis />
        <YAxis />

        <VerticalBarSeries
          data={barData}
          color={barColor}
          onValueMouseOver={v =>
            setHoveredBar(v)
          }
          onValueMouseOut={v => setHoveredBar(null)}
        >

        </VerticalBarSeries>

        {secondBar ? <VerticalBarSeries
          data={secondBar}
          color={secondBarColor}
          onValueMouseOver={v =>
            setHoveredBar(v)
          }
          onValueMouseOut={v => setHoveredBar(null)}
        >
        </VerticalBarSeries>
          : null

        }

        {hoveredBar ? (


          <Hint className='Hint-Hover' value={hoveredBar}>
            <div style={{ height: 'fit-content' }}>

              <span className="white" >{hoveredBar.label}</span>
            </div>
          </Hint>
        ) : null}

      </XYPlot>
      { secondBar && labels ?
      <>
      {/* <div className='Chart-Label-Small' >
        <span>{xLabel}</span>
      </div> */}
      
      <DiscreteColorLegend
        items={labels}
        colors={[barColor, secondBarColor]}
        orientation='horizontal'
        style={{ display: 'flex' }}
        className="flex-wrap justify-content-center "
        height={50}
      >
      </DiscreteColorLegend>
      </>
      : <div className='Chart-Label' >
      <span>{xLabel}</span>
    </div> }
    </>
  )
}