import React, { useEffect, useRef, useState } from "react"
import stripeService from "../../../services/stripeService";
import "./adminEmailService.css";
import sqlService from "../../../services/sqlService";
import { Modal, ModalBody, Button } from "react-bootstrap";
import searchUtility from "../../../functions/searchUtility";
import { AiOutlineSearch } from "react-icons/ai";
import { faCaretRight, faEllipsisVertical, faEnvelope, faPlus, faPaperclip, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../../App";
import { useContext } from "react";
import DatePicker from "react-datepicker";
import { Spinner } from "react-bootstrap";

import validateUtility from "../../../functions/validateUtility";
import inputConstants from "../../../constants/inputConstants";
import PopupMessage from "../../utility.component/popupMessage.component/popupMessage.component";

export default function AdminEmailService() {
    const user = useContext(UserContext);
    const [selectedView, setSelectedView] = useState("notSelected");
    const [editEnabled, setEditEnabled] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const initialTemplateContent = useRef("");
    const loaded = useRef(false);
    const [newTitle, setNewTitle] = useState("");


    const [searchKeywordFilter, setSearchKeywordFilter] = useState("");
    // const [minDiscountPercentageFilter, setMinDiscountPercentageFilter] = useState(0);
    // const [maxDiscountPercentageFilter, setMaxDiscountPercentageFilter] = useState(100);
    const [selectedDurationFilter, setSelectedDurationFilter] = useState("All");


    const [selectedUsers, setSelectedUsers] = useState({});
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [sendConfirmation, setSendConfirmation] = useState(false);

    const [allPros, setAllPros] = useState([]);
    const [allHomeowners, setAllHomeowners] = useState([]);
    const [allAgents, setAllAgents] = useState([]);
    const [allInsuranceAgents, setAllInsuranceAgents] = useState([]);
    const [allMortgageAgents, setAllMortgageAgents] = useState([]);
    const [allBrokerages, setAllBrokerages] = useState([]);

    const [filteredUsersFromSelected, setFilteredUsersFromSelected] = useState([]);
    const [filteredBySearchUsers, setFilteredBySearchUsers] = useState([])

    const [minimumCreationDate, setMinimumCreationDate] = useState(null);
    const [maximumCreationDate, setMaximumCreationDate] = useState(null);

    const [displayMinimumCreationDatePicker, setDisplayMinimumCreationDatePicker] = useState(false);
    const [displayMaximumCreationDatePicker, setDisplayMaximumCreationDatePicker] = useState(false);

    const [minimumCreationFilteredUsers, setMinimumCreationFilteredUsers] = useState([]);
    const [maximumCreationFilteredUsers, setMaximumCreationFilteredUsers] = useState([]);

    const [sendingIsOn, setSendingIsOn] = useState(false);

    const [emailSubject, setEmailSubject] = useState("");
    const [emailCategory, setEmailCategory] = useState("");
    const [emailSubCategory, setEmailSubCategory] = useState("");

    const emailCategories = { "NEWSLETTER": ["HOMEOWNER_NEWSLETTER", "AGENT_NEWSLETTER"], "ESSENTIAL": ["ESSENTIAL"], "AGENT_EMAIL": ["AGENT_EMAIL"], "REMINDERS": ["EVENT_REMINDERS", "FINISH_SIGNUP_REMINDER", "SUBSCRIBE_REMINDER", "ADMIN_R_FINISH_SIGNUP_REMINDER", "ADMIN_R_SUBSCRIBE_REMINDER"], "PROMOTIONS": ["PROMOTIONS"], "ADMIN_EMAIL": ["ADMIN_EMAIL"] };

    const [emailRecords, setEmailRecords] = useState([]);

    const [minimumCreationDate1, setMinimumCreationDate1] = useState(null);
    const [maximumCreationDate1, setMaximumCreationDate1] = useState(null);

    const [displayMinimumCreationDatePicker1, setDisplayMinimumCreationDatePicker1] = useState(false);
    const [displayMaximumCreationDatePicker1, setDisplayMaximumCreationDatePicker1] = useState(false);


    const [searchKeywordFilter1, setSearchKeywordFilter1] = useState("");

    const [filteredBySearchRecords, setFilteredBySearchRecords] = useState([]);
    const [minimumCreationFilteredRecords, setMinimumCreationFilteredRecords] = useState([]);
    const [maximumCreationFilteredRecords, setMaximumCreationFilteredRecords] = useState([]);

    const [selectedDurationFilter1, setSelectedDurationFilter1] = useState("All");
    const [filteredByRecipientRoleIdRecords, setFilteredByRecipientRoleIdRecords] = useState([]);
    const [filteredByCategoryRecords, setFilteredByCategoryRecords] = useState([]);
    const [filteredBySubCategoryRecords, setFilteredBySubCategoryRecords] = useState([]);

    const [categoryFilter, setCategoryFilter] = useState("All");
    const [subCategoryFilter, setSubCategoryFilter] = useState("All");

    const [selectedViewEmail, setSelectedViewEmail] = useState(null);

    const [customEmail, setCustomEmail] = useState("");

    useEffect(() => {
        if (!loaded.current) {
            loaded.current = true;
            getTemplates();
            getEmailRecords();
        }
        console.log(Object.keys(emailCategories));

    }, []);



    const [emailTemplates, setEmailTemplates] = useState([]);

    const getTemplates = () => {
        sqlService.getUniversalEmailTemplates().then(d => {
            console.log(d);
            setEmailTemplates(d?.data)
        }).catch(err => {
            console.log(err);
        })
    }

    const getEmailRecords = () => {
        sqlService.getEmailRecords().then(d => {
            console.log(d);
            setEmailRecords(d?.data)
        }).catch(err => {
            console.log(err);
        })
    }

    const variableFiller = {
        "name": "John Doe",
        "email": "emailfake@fake.come",
        "phone": "123-123-3212",
        "address": "21 fake street Drive",
        "city": "New York",
        "country": "United States",
        "website": "fakewebsite.com"
    }

    const processHtml = (html) => {
        // var found = false;
        var htmlCpy = html;
        // var variable = "";
        // var variableFound = false;
        // var foundVariables = []

        // let regexp = /{{(.*?)}}/g;

        // let matchAll = html.matchAll(regexp);
        // matchAll = Array.from(matchAll); // array now
        // console.log("88889", matchAll)
        // console.log("html", html)
        // let don = false;
        // for(let match of matchAll){
        //     if(don){
        //         break;
        //     }
        //     don = true;
        //     let variable = match[1];
        //     let inx = match[3]
        //     htmlCpy = spliceSlice(html, inx, variable+4, variableFiller[variable] ?? variable);
        // }


        htmlCpy = htmlCpy.replaceAll("{{contact_name}}", "John Doe");
        htmlCpy = htmlCpy.replaceAll("{{name}}", "John Doe");
        htmlCpy = htmlCpy.replaceAll("{{email}}", "emailfake@fake.come");
        htmlCpy = htmlCpy.replaceAll("{{phone_number}}", "123-123-3212");
        htmlCpy = htmlCpy.replaceAll("{{phone}}", "123-123-3212");
        htmlCpy = htmlCpy.replaceAll("{{address}}", "21 fake street Drive");
        htmlCpy = htmlCpy.replaceAll("{{city}}", "New York");
        htmlCpy = htmlCpy.replaceAll("{{country}}", "United States");
        htmlCpy = htmlCpy.replaceAll("{{website}}", "fakewebsite.com");


        // do {
        //     variableFound = false;
        //     for (let i = 0; i < htmlCpy.length - 1; i++) {
        //         if (htmlCpy[i] === "{" && htmlCpy[i + 1] === "{") {
        //             found = true;
        //             variable = "";
        //             for (let j = i + 2; j < htmlCpy.length - 1; j++) {
        //                 if (htmlCpy[j] === "}" && htmlCpy[j + 1] === "}") {
        //                     break;
        //                 }
        //                 variable += htmlCpy[j];
        //             }
        //             console.log("variable", variable);
        //             foundVariables.push(variable);
        //             htmlCpy = spliceSlice(htmlCpy, i, variable+4, variableFiller[variable] ?? variable);
        //             if(!foundVariables.includes(variable)){
        //                 variableFound = true;
        //             }

        //         }
        //     }
        // } while (variableFound)
        return htmlCpy;
    }

    function spliceSlice(str, index, count, add) {
        // We cannot pass negative indexes directly to the 2nd slicing operation.
        if (index < 0) {
            index = str.length + index;
            if (index < 0) {
                index = 0;
            }
        }

        return str.slice(0, index) + (add || "") + str.slice(index + count);
    }

    const emailTemplateWrapper = (endMessage, html) => {
        return `
        
    <!DOCTYPE html>

    <html>
    <head>
    
      <meta charset="utf-8">
      <meta http-equiv="x-ua-compatible" content="ie=edge">
      <title>Email Receipt</title>
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <style type="text/css">
      /**
       * Google webfonts. Recommended to include the .woff version for cross-client compatibility.
       */
      @media screen {
        @font-face {
          font-family: 'Source Sans Pro';
          font-style: normal;
          font-weight: 400;
          src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/ODelI1aHBYDBqgeIAH2zlBM0YzuT7MdOe03otPbuUS0.woff) format('woff');
        }
    
        @font-face {
          font-family: 'Source Sans Pro';
          font-style: normal;
          font-weight: 700;
          src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/toadOcfmlt9b38dHJxOBGFkQc6VGVFSmCnC_l7QZG60.woff) format('woff');
        }
      }
    
      /**
       * Avoid browser level font resizing.
       * 1. Windows Mobile
       * 2. iOS / OSX
       */
      body,
      table,
      td,
      a {
        -ms-text-size-adjust: 100%; /* 1 */
        -webkit-text-size-adjust: 100%; /* 2 */
      }
    
      /**
       * Remove extra space added to tables and cells in Outlook.
       */
      table,
      td {
        mso-table-rspace: 0pt;
        mso-table-lspace: 0pt;
      }
    
      /**
       * Better fluid images in Internet Explorer.
       */
      img {
        -ms-interpolation-mode: bicubic;
      }
    
      /**
       * Remove blue links for iOS devices.
       */
      a[x-apple-data-detectors] {
        font-family: inherit !important;
        font-size: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
        color: inherit !important;
        text-decoration: none !important;
      }
    
      /**
       * Fix centering issues in Android 4.4.
       */
      div[style*="margin: 16px 0;"] {
        margin: 0 !important;
      }
    
      body {
        width: 100% !important;
        height: 100% !important;
        padding: 0 !important;
        margin: 0 !important;
      }
    
      /**
       * Collapse table borders to avoid space between cells.
       */
      table {
        border-collapse: collapse !important;
      }
    
      a {
        color: #1a82e2;
      }
    
      img {
        height: auto;
        line-height: 100%;
        text-decoration: none;
        border: 0;
        outline: none;
      }
      </style>
    
    </head>
    <body style="background-color: #f8a025;">
    
      <!-- start preheader -->
      <div class="preheader" style="display: none; max-width: 0; max-height: 0; overflow: hidden; font-size: 1px; line-height: 1px; color: #fff; opacity: 0;">
        A preheader is the short summary text that follows the subject line when an email is viewed in the inbox.
      </div>
      <!-- end preheader -->
    
      <!-- start body -->
      <table border="0" cellpadding="0" cellspacing="0" width="100%">
    
        <!-- start logo -->
        <tr>
          <td align="center" bgcolor="#D2C7BA">
            <!--[if (gte mso 9)|(IE)]>
            <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
            <tr>
            <td align="center" valign="top" width="600">
            <![endif]-->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
              <tr>
                <td align="center" valign="top" style="padding: 36px 24px;">
                  <a href="https://honeyydo.com" target="_blank" style="display: inline-block;">
                    <img src="https://honeyydo.s3.us-east-2.amazonaws.com/other/Honeyydo-logo-no-text.png" alt="Honeyydo Logo" border="0" width="48" style="display: block; width: 48px; max-width: 48px; min-width: 48px;">
                  </a>
                </td>
              </tr>
            </table>
            <!--[if (gte mso 9)|(IE)]>
            </td>
            </tr>
            </table>
            <![endif]-->
          </td>
        </tr>
        <!-- end logo -->
    
        ${processHtml(html)}
     
        <!-- end receipt address block -->
    
        <!-- start footer -->
        <tr>
          <td align="center" bgcolor="#f0eae4" style="padding: 24px;">
            <!--[if (gte mso 9)|(IE)]>
            <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
            <tr>
            <td align="center" valign="top" width="600">
            <![endif]-->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
    
              <!-- start permission -->
      
              <!-- end permission -->
    
              <!-- start unsubscribe 
              <tr>
                <td align="center" bgcolor="#D2C7BA" style="padding: 12px 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 20px; color: #666;">
                  <p style="margin: 0;">To stop receiving these emails, you can <a href="https://honeyydo.com" target="_blank">unsubscribe</a> at any time.</p>
                </td>
              </tr> -->
              <!-- end unsubscribe -->
    
            </table>
            <!--[if (gte mso 9)|(IE)]>
            </td>
            </tr>
            </table>
            <![endif]-->
          </td>
        </tr>
        <!-- end footer -->
    
      </table>
      <!-- end body -->
    
    </body>
    </html> 
    
        
        `
    }


    const notSelected = () => {
        return (
            <div>
                <h2 className="Blog-Page-Heading">Honeyydo Email Serivce</h2>
                <div className="Theme-Container">

                    <div style={{ width: '100%', height: '100px' }} onClick={() => { setSelectedView("view-email-templates") }} className="nicefindaprostuff  display-flex flex-direction-row justify-content-center column-gap-10-px row-gap-10-px cursor-pointer align-items-center border-radius-10-px">
                        <h6 className="grey">{"View and Send Email Templates"}</h6>
                    </div>

                    <div style={{ width: '100%', height: '100px' }} onClick={() => { setSelectedView("view-email-history") }} className="nicefindaprostuff  display-flex flex-direction-row justify-content-center column-gap-10-px row-gap-10-px cursor-pointer align-items-center border-radius-10-px">
                        <h6 className="grey">{"View Emails History"}</h6>
                    </div>

                </div>
            </div>
        )
    }

    const viewEmailTemplates = () => {
        if (!selectedTemplate && emailTemplates.length === 0) {
            setSelectedTemplate({ "content": `<div>Add you html here.</div>` });
            setEmailSubject("");
            setNewTitle("");
            setEditEnabled(true);
        }
        return (
            <div className="display-flex flex-direction-column column-gap-20-px row-gap-20-px">
                <div onClick={() => { setSelectedTemplate(null); setSelectedView("notSelected"); }} className=" cursor-pointer display-flex flex-wrap align-items-center justify-content-left column-gap-10-px row-gap-10-px">
                    <h4>Back to Menu</h4>
                    <FontAwesomeIcon icon={faEllipsisVertical} />

                </div>
                <div className="display-flex flex-wrap align-items-center justify-content-center column-gap-10-px row-gap-10-px">
                    <FontAwesomeIcon icon={faCaretRight} />
                    <h4>Email Templates</h4>
                </div>

                <div className="display-flex flex-direction-column flex-wrap justify-content-center align-items-center background-color-light-grey-3 padding-10-px border-radius-10-px margin-bottom-10-px">
                    {selectedTemplate ?
                        <div className="display-flex flex-wrap justify-content-center align-items-center margin-10-px column-gap-10-px row-gap-10-px">
                            {!editEnabled ?
                                <span onClick={() => {
                                    setSelectedTemplate({ "content": `<div>Add you html here.</div>` }); setEmailSubject("");
                                    setNewTitle(""); setEditEnabled(true);
                                }} className="bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px">Create New Template</span>
                                : null}
                            {!editEnabled ?
                                <span onClick={() => { setEditEnabled(true) }} className="bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px">Edit</span>
                                : null}
                            {editEnabled ?
                                <input className="card-input__input" placeholder="title" value={newTitle} onChange={(e) => { setNewTitle(e.currentTarget.value); }}></input>
                                : null}
                            {(editEnabled && selectedTemplate?.id) ?
                                <span onClick={() => { saveTemplate(); }} className="bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px">Save</span>
                                : null}
                            {editEnabled ?
                                <span onClick={() => { saveTemplate("new"); }} className="bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px">Save as New</span>
                                : null}
                            {editEnabled ?
                                <span onClick={() => { setEditEnabled(false); }} className="bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px">Cancel</span>
                                : null}

                            {!editEnabled ?
                                <span onClick={() => { setSelectedView("send-emails"); }} className="bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px">Send Email</span>
                                : null}

                        </div>
                        : null}
                    <div>
                        <div className="display-flex flex-wrap justify-content-center column-gap-20-px row-gap-20-px width-100-psnt">
                            <div className="display-flex flex-wrap justify-content-center background-color-grey padding-10-px margin-bottom-10-px width-200-px column-gap-10-px row-gap-10-px height-55-vh overflow-y-scroll">
                                <span className="bold text-align-center">Templates</span>
                                {
                                    emailTemplates?.map((item, index) =>
                                        <span key={index} className={`height-fit-content cursor-pointer border-radius-5-px width-100-psnt text-align-center ${selectedTemplate?.id === item?.id ? "background-color-dark-blue white" : "background-color-blue"}`} onClick={() => { setEditEnabled(false); setNewTitle(item.title ?? ""); initialTemplateContent.current = item?.content; setSelectedTemplate(item); setEmailSubject(item.subject ?? ""); setEmailCategory(item.category ?? ""); setEmailSubCategory(item.sub_category ?? ""); }}>{item.title}</span>
                                    )
                                }
                            </div>
                            {selectedTemplate ?
                                <div>
                                    <div className="display-flex flex-wrap align-items-center justify-content-center">
                                        <input disabled={!editEnabled} className="card-input__input" placeholder="Email Subject" value={emailSubject} onChange={(e) => { setEmailSubject(e.currentTarget.value); }}></input>

                                        <select disabled={!editEnabled}
                                            name="state"
                                            className="card-input__input -select"
                                            value={emailCategory}
                                            onChange={(e) => { setEmailCategory(e.currentTarget.value) }}

                                        >
                                            <option disabled>
                                                Select Email Category
                                            </option>
                                            {
                                                Object.keys(emailCategories).map((item, index) =>
                                                    <option key={index} value={item}>
                                                        {item}
                                                    </option>
                                                )
                                            }
                                        </select>
                                        {(emailCategory && emailCategory !== "") ?
                                            <select disabled={!editEnabled}
                                                name="state"
                                                className="card-input__input -select"
                                                value={emailSubCategory}
                                                onChange={(e) => { setEmailSubCategory(e.currentTarget.value) }}

                                            >
                                                <option disabled>
                                                    Select Email Sub Category
                                                </option>
                                                {
                                                    emailCategories[emailCategory].map((item, index) =>
                                                        <option key={index} value={item}>
                                                            {item}
                                                        </option>
                                                    )
                                                }
                                            </select> : null
                                        }
                                    </div>
                                    <textarea disabled={!editEnabled} className="Admin-Email-Service-Html-Input" value={selectedTemplate.content} onChange={(e) => { setSelectedTemplate({ ...selectedTemplate, "content": e.currentTarget.value }) }} />
                                </div>
                                : null}

                            {selectedTemplate ? <div className="display-flex flex-direction-column flex-wrap justify-content-start background-color-grey padding-10-px margin-bottom-10-px width-200-px column-gap-10-px row-gap-10-px height-55-vh">
                                <span className="bold text-align-center">List of Variables</span>
                                {
                                    Object.keys(variableFiller)?.map((item, index) =>
                                        <span key={index} className={`border-radius-5-px width-100-psnt text-align-center ${"background-color-light-purple"} cursor-pointer`} onClick={() => { if (!editEnabled) { return } setSelectedTemplate({ ...selectedTemplate, "content": selectedTemplate?.content ? selectedTemplate?.content + ` {{${item}}}` : `{{${item}}}` }) }}>{item}</span>
                                    )
                                }
                                <span className="text-align-center">{`To include a variable in your email, simply wrap the variable with two curly brackets Ex: Dear {{name}}, I hope you had a great weekend.`}</span>
                            </div> : null}
                        </div>
                        {selectedTemplate ?
                            <div className="display-flex justify-content-center flex-wrap">

                                <div dangerouslySetInnerHTML={{
                                    __html: selectedTemplate && selectedTemplate.content ? emailTemplateWrapper("", selectedTemplate.content) : ""
                                }}></div>
                            </div>
                            : null}
                    </div>

                </div>

            </div>
        )
    }



    
    const [attachment, setAttachment] = useState([])
    const attachmentFile = useRef(null)
    const [serverMessage, setServerMessage] = useState(null);

    const handleAttachmentUpload = () => {
        attachmentFile.current.click();

    }

    function removeAttachment(index) {
        let updated = [...attachment]
        updated.splice(index, 1)
        console.log(updated)

        setAttachment(updated)

    }

    const getAttachmentsSizs = (att) => {
        var size = 0;
        for (let a of att) {
            size += a?.size;
        }
        return size;
    }

    const handleAttachmentChange = (event) => {


        const file = event.target.files[0];
        const reader = new FileReader();

        console.log(file?.size)
        if (!validateUtility.validFileSize(file)) {
            setServerMessage({ "message": `File size must be less than ${inputConstants.MAX_FILE_SIZE / 1000000}mb`, "title": "Error" })
            return;
        }
        if ((getAttachmentsSizs(attachment) + file?.size) > inputConstants.MAX_FILE_SIZE) {
            setServerMessage({ "message": `Attachments size must be less than ${inputConstants.MAX_FILE_SIZE / 1000000}mb and your attachements size is will be ${(getAttachmentsSizs(attachment) + file?.size) / 1000000}mb with this file`, "title": "Error" })
            return;
        }

        reader.onload = () => {
            if (!file?.size) {
                setServerMessage({ "message": `File is invalid`, "title": "Error" })
                return
            }

            let data = reader.result;

            let t = file.type;

            let type = t.split("/")[1];

            let updated = [...attachment]
            updated.push({ name: file.name, type: type, data: data, size: file?.size });


            setAttachment(updated);


        };

        if (file) {
            reader.readAsDataURL(file);
        }

    }


    const sendEmails = () => {
        return (
            <div className="display-flex flex-direction-column column-gap-20-px row-gap-20-px">
                <div onClick={() => { setSelectedView("notSelected") }} className=" cursor-pointer display-flex flex-wrap align-items-center justify-content-left column-gap-10-px row-gap-10-px">
                    <h4>Back to Menu</h4>
                    <FontAwesomeIcon icon={faEllipsisVertical} />

                </div>
                <div className="display-flex flex-wrap align-items-center justify-content-center column-gap-10-px row-gap-10-px">
                    <FontAwesomeIcon icon={faCaretRight} />
                    <h4>Send Emails</h4>
                </div>

                <div className="display-flex flex-wrap column-gap-10-px row-gap-10-px aling-items-center justify-content-center background-color-grey padding-10-px border-radius-10-px">
                    <span className="font-size-20-px bold">Filters</span>
                    <div className="Search-Bar-Focus position-relative background-color-light-grey height-fit-content border-radius-5-px">
                        <span
                            className="position-absolute left-8-px top-4-px purple border-radius-5-px"
                        >
                            <AiOutlineSearch />
                        </span>
                        <input
                            className="Search-Bar"
                            type="text"
                            value={searchKeywordFilter}
                            onChange={(e) => { setSearchKeywordFilter(e.currentTarget.value) }}
                            placeholder={"Search Users"}
                        />
                    </div>
                    <div className="display-flex flex-direction-column">


                        <div className="display-flex flex-direction-column">
                            <div className="display-flex position-relative flex-wrap justify-content-center align-items-center column-gap-5-px row-gap-5-px">
                                <span>Minimum Creation Date</span>
                                <FontAwesomeIcon onClick={() => { setDisplayMinimumCreationDatePicker(true) }} className="cursor-pointer" icon={faPlus} />
                                {(displayMinimumCreationDatePicker) ?
                                    <div className=" background-color-light-grey" style={{ minHeight: '300px', paddingTop: '15px' }}>
                                        <DatePicker className="Input-Style background-color-light-grey"
                                            minDate={null}
                                            maxDate={maximumCreationDate ?? null}

                                            selected={minimumCreationDate}
                                            onChange={(value) => { console.log(value); setMinimumCreationDate(value); setDisplayMinimumCreationDatePicker(false); }}
                                            inline
                                            showTimeSelect
                                        //   filterTime={filteredTimes}

                                        />
                                        <span className="bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px" onClick={() => { setDisplayMinimumCreationDatePicker(false); }}>Cancel</span>
                                    </div> : null}
                                {minimumCreationDate ? <span className="bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px" onClick={() => { setMinimumCreationDate(null) }}>Clear</span> : null}
                            </div>
                            {minimumCreationDate ? <span className="cursor-pointer" onClick={() => { setDisplayMinimumCreationDatePicker(true); }}>{minimumCreationDate.toString().split("GMT")[0]}</span> : null}

                        </div>
                        <div className="display-flex flex-direction-column">
                            <div className="display-flex position-relative flex-wrap justify-content-center align-items-center column-gap-5-px row-gap-5-px">
                                <span>Maximum Creation Date</span>
                                <FontAwesomeIcon onClick={() => { setDisplayMaximumCreationDatePicker(true) }} className="cursor-pointer" icon={faPlus} />
                                {(displayMaximumCreationDatePicker) ?
                                    <div className="background-color-light-grey" style={{ minHeight: '300px', paddingTop: '15px' }}>
                                        <DatePicker className="Input-Style background-color-light-grey"
                                            minDate={minimumCreationDate ?? null}
                                            maxDate={null}

                                            selected={maximumCreationDate}
                                            onChange={(value) => { console.log(value); setMaximumCreationDate(value); setDisplayMaximumCreationDatePicker(false); }}
                                            inline
                                            showTimeSelect
                                        //   filterTime={filteredTimes}

                                        />
                                        <span className="bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px" onClick={() => { setDisplayMaximumCreationDatePicker(false); }}>Cancel</span>
                                    </div> : null}
                                {maximumCreationDate ? <span className="bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px" onClick={() => { setMaximumCreationDate(null) }}>Clear</span> : null}
                            </div>
                            {maximumCreationDate ? <span className="cursor-pointer" onClick={() => { setDisplayMaximumCreationDatePicker(true); }}>{maximumCreationDate.toString().split("GMT")[0]}</span> : null}

                        </div>
                    </div>
                    {/* <div class="range_container">
                        <div class="sliders_control">
                            <input className="Admin_Range_Style" id="fromSlider" type="range" min="0" max={maxDiscountPercentageFilter ?? 100} value={minDiscountPercentageFilter ?? 0} onChange={(e) => { setMinDiscountPercentageFilter(e.currentTarget.value) }} />
                            <input className="Admin_Range_Style" id="toSlider" type="range" value={maxDiscountPercentageFilter ?? 100} min={minDiscountPercentageFilter} max={100} onChange={(e) => { setMaxDiscountPercentageFilter(e.currentTarget.value) }} />
                        </div>
                        <div class="form_control">
                            <div class="form_control_container">
                                <div class="form_control_container__time">Min</div>
                                <input class="form_control_container__time__input Admin_Range_Number" onChange={(e) => { setMinDiscountPercentageFilter(e.currentTarget.value) }} type="number" id="fromInput" value={minDiscountPercentageFilter ?? 0} min="0" max={maxDiscountPercentageFilter ?? 100} />
                            </div>
                            <div>
                                <span className="">Percentage off</span>
                            </div>
                            <div class="form_control_container">
                                <div class="form_control_container__time">Max</div>
                                <input class="form_control_container__time__input Admin_Range_Number" type="number" id="toInput" onChange={(e) => { setMaxDiscountPercentageFilter(e.currentTarget.value) }} value={maxDiscountPercentageFilter} min={minDiscountPercentageFilter} max="100" />
                            </div>
                        </div>
                    </div> */}
                    <div className="display-flex flex-direction-column aling-items-center">
                        {/* <span className="bold">User Type</span> */}
                        <div className="display-flex flex-wrap width-300-px">
                            <span onClick={() => { setSelectedDurationFilter("All") }} className={`${selectedDurationFilter === "All" ? "Stripe-Selected-Duration" : ""} Coupon-Duration-Button Coupon-Left-Border Coupon-Right-Border`}>All Users</span>
                            <span onClick={() => { setSelectedDurationFilter("Pros") }} className={`${selectedDurationFilter === "Pros" ? "Stripe-Selected-Duration" : ""} Coupon-Duration-Button Coupon-Left-Border Coupon-Right-Border`}>Pros</span>
                            <span onClick={() => { setSelectedDurationFilter("Homeowners") }} className={`${selectedDurationFilter === "Homeowners" ? "Stripe-Selected-Duration" : ""} Coupon-Duration-Button Coupon-Left-Border Coupon-Right-Border`}>Homeowners</span>
                            <span onClick={() => { setSelectedDurationFilter("Agents") }} className={`${selectedDurationFilter === "Agents" ? "Stripe-Selected-Duration" : ""} Coupon-Duration-Button Coupon-Left-Border Coupon-Right-Border`}>Agents</span>
                            <span onClick={() => { setSelectedDurationFilter("Mortgage_Agents") }} className={`${selectedDurationFilter === "Mortgage_Agents" ? "Stripe-Selected-Duration" : ""} Coupon-Duration-Button  Coupon-Left-Border Coupon-Right-Border-Curve`}>Mortgage Agents</span>
                            <span onClick={() => { setSelectedDurationFilter("Insurance_Agents") }} className={`${selectedDurationFilter === "Insurance_Agents" ? "Stripe-Selected-Duration" : ""} Coupon-Duration-Button Coupon-Left-Border Coupon-Right-Border-Curve`}>Insurance Agents</span>
                            <span onClick={() => { setSelectedDurationFilter("Brokerages") }} className={`${selectedDurationFilter === "Brokerages" ? "Stripe-Selected-Duration" : ""} Coupon-Duration-Button Coupon-Left-Border Coupon-Right-Border-Curve`}>Brokerages</span>
                        </div>
                    </div>

                </div>


                <div className="display-flex flex-direction-column flex-wrap justify-content-center align-items-center">
                    {selectedTemplate ?
                        <div className="display-flex flex-wrap justify-content-center align-items-center margin-10-px column-gap-10-px row-gap-10-px">



                            {sendConfirmation ? <div className="display-flex column-gap-10-px row-gap-10-px flex-wrap align-items-center">
                                <span>{`Are you sure you want to send this email to ${Object.keys(selectedUsers).length} user(s)?`}</span>
                                <span onClick={() => { setSendConfirmation(false); }} className="bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px">No</span>
                                <span onClick={() => { setSendConfirmation(false); handleSendingEmail(); }} className="bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px">Yes</span>

                            </div>
                                : <>
                                    <span onClick={() => { resetFilters() }} className="bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px">Reset Filters</span>
                                    {sendingIsOn ?
                                        <Spinner /> :
                                        <span onClick={() => { if (Object.keys(selectedUsers).length) { setSendConfirmation(true); } }} className="bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px">Send</span>
                                    }
                                </>}
                            
                                <div className="display-flex">
                                {!sendConfirmation ?
                                    <div onClick={(e) => handleAttachmentUpload(e)} className="display-flex justify-content-center align-items-center bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px">
                                        <span>Attachment</span>
                                        <FontAwesomeIcon
                                            className="grey"
                                            transform={{ rotate: -45 }}
                                            icon={faPaperclip}
                                        />
                                    </div>
                                    : null}
                                <input
                                    className='display-none'
                                    type="file"
                                    ref={attachmentFile}

                                    onChange={(e) => { handleAttachmentChange(e) }}
                                />
                                {attachment !== null ?
                                    <div style={{ marginBottom: '20px' }} className="display-flex flex-direction-column">
                                        {attachment.map((file, index) =>
                                            <div className="display-flex margin-top-5-px">

                                                <span style={{ marginTop: '-5px' }} className="padding-left-10-px ">{file.name}</span>
                                                <FontAwesomeIcon icon={faX} style={{ marginTop: '2px' }} className="grey padding-left-10-px fa-xs cursor-pointer" onClick={() => removeAttachment(index)} />
                                            </div>
                                        )}
                                    </div> : null}
                            </div>


                        </div>
                        : null}
                    <div className="display-flex flex-direction-column column-gap-30-px row-gap-30-px background-color-light-grey-2 padding-10-px width-95-vw border-radius-10-px">
                        <div className="display-flex flex-wrap justify-content-center column-gap-30-px row-gap-30-px">
                            <div className="display-flex flex-direction-column flex-wrap aling-items-center justify-content-center min-width-300-px">
                                <span className="bold text-align-center margin-10-px">Select Users {maximumCreationFilteredUsers?.length} results</span>
                                <span onClick={() => { handleSelectAll(); }} className="bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px text-align-center width-fit-content">Select All</span>
                                <div className="display-flex flex-wrap justify-content-left background-color-grey padding-10-px margin-bottom-10-px min-width-300-px column-gap-10-px row-gap-10-px overflow-y-scroll height-500-px">
                                    {/* {
                                        maximumCreationFilteredUsers?.map((item, index) =>
                                            <div className="display-flex height-fit-content width-100-psnt" onClick={() => { setSelectedUsers({ ...selectedUsers, [item.email]: item }) }}>
                                                <span key={index + "name"} className={`cursor-pointer border-radius-5-px padding-x-2-px text-align-center background-color-light-blue`}>{item?.contact_name}</span>
                                                <span key={index + "email"} className={`cursor-pointer border-radius-5-px padding-x-2-px text-align-center background-color-blue`}>{item?.email}</span>
                                            </div>
                                        )
                                    } */}

                                    <table class="styled-table">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                maximumCreationFilteredUsers?.map((item, index) =>
                                                    <tr className="cursor-pointer background-color-blue-pink-hover" onClick={() => { setSelectedUsers({ ...selectedUsers, [item.email]: item }) }}>
                                                        <td>{item.contact_name}</td>
                                                        <td>{item.email}</td>
                                                    </tr>
                                                )
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="display-flex flex-direction-column flex-wrap aling-items-center justify-content-center min-width-300-px">
                                <span className="bold text-align-center margin-10-px">Selected Users {Object.keys(selectedUsers).length}</span>
                                <div className="display-flex flex-wrap column-gap-10-px row-gap-10-px">
                                    <span onClick={() => { setSelectedUsers({}); }} className="bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px text-align-center width-fit-content">Clear Selected</span>
                                    <div><input type="text" className="Input-Style" onChange={(e) => { setCustomEmail(e.currentTarget.value); }} value={customEmail} placeholder="custom email recipient" /><button onClick={() => { handleCusomEmail() }} className="border-radius-5-px">Add</button></div>

                                </div>
                                <div className="display-flex flex-wrap justify-content-left background-color-grey padding-10-px margin-bottom-10-px min-width-300-px column-gap-10-px row-gap-10-px overflow-y-scroll height-500-px">

                                    {/* {Object.keys(selectedUsers).map((item, index) =>
                                        <div className="display-flex height-fit-content width-100-psnt" onClick={() => {
                                            let copy = { ...selectedUsers }
                                            delete copy[item];
                                            setSelectedUsers(copy);
                                        }}>
                                            <span key={index + "name"} className={`cursor-pointer border-radius-5-px padding-x-2-px text-align-center background-color-light-blue`}>{selectedUsers[item].contact_name}</span>
                                            <span key={index + "email"} className={`cursor-pointer border-radius-5-px padding-x-2-px text-align-center background-color-blue`}>{selectedUsers[item].email}</span>
                                        </div>
                                    )
                                    } */}

                                    <table class="styled-table">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Object.keys(selectedUsers).map((item, index) =>
                                                    <tr className="cursor-pointer background-color-blue-pink-hover" onClick={() => {
                                                        let copy = { ...selectedUsers }
                                                        delete copy[item];
                                                        setSelectedUsers(copy);
                                                    }}>
                                                        <td>{selectedUsers[item].contact_name}</td>
                                                        <td>{selectedUsers[item].email}</td>
                                                    </tr>
                                                )
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>





                        </div>
                        <div className="display-flex justify-content-center flex-wrap">

                            <div dangerouslySetInnerHTML={{
                                __html: selectedTemplate && selectedTemplate.content ? emailTemplateWrapper("", selectedTemplate.content) : ""
                            }}></div>
                        </div>

                    </div>

                </div>

                <PopupMessage onConfirm={() => { setServerMessage(null) }} message={serverMessage?.message} title={serverMessage?.title} show={serverMessage !== null} />

            </div>
        )
    }

    const handleCusomEmail = () => {
        if (customEmail !== "" && !selectedUsers[customEmail]) {
            let exist = maximumCreationFilteredUsers.filter(item => item.email === customEmail);
            var obj = { "contact_name": "User", "email": customEmail };
            if (exist.length) {
                obj = exist[0]
            }
            setSelectedUsers({ ...selectedUsers, [customEmail]: obj });
            setCustomEmail("");
        }
    }

    const viewEmailHistory = () => {
        return (
            <div className="display-flex flex-direction-column column-gap-20-px row-gap-20-px">
                <div onClick={() => { setSelectedView("notSelected") }} className=" cursor-pointer display-flex flex-wrap align-items-center justify-content-left column-gap-10-px row-gap-10-px">
                    <h4>Back to Menu</h4>
                    <FontAwesomeIcon icon={faEllipsisVertical} />

                </div>
                <div className="display-flex flex-wrap align-items-center justify-content-center column-gap-10-px row-gap-10-px">
                    <FontAwesomeIcon icon={faCaretRight} />
                    <h4>Email History</h4>
                </div>

                <div className="display-flex flex-wrap column-gap-10-px row-gap-10-px aling-items-center justify-content-center background-color-grey padding-10-px border-radius-10-px">
                    <div className="display-flex flex-direction-column column-gap-10-px row-gap-10-px">
                        <span className="font-size-20-px bold">Filters</span>
                        <div className="Search-Bar-Focus position-relative background-color-light-grey height-fit-content border-radius-5-px">
                            <span
                                className="position-absolute left-8-px top-4-px purple border-radius-5-px"
                            >
                                <AiOutlineSearch />
                            </span>
                            <input
                                className="Search-Bar"
                                type="text"
                                value={searchKeywordFilter1}
                                onChange={(e) => { setSearchKeywordFilter1(e.currentTarget.value) }}
                                placeholder={"Search Users"}
                            />
                        </div>
                        <span onClick={() => { resetFilters1() }} className="bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px">Reset Filters</span>
                    </div>
                    <div className="display-flex flex-direction-column">


                        <div className="display-flex flex-direction-column">
                            <div className="display-flex position-relative flex-wrap justify-content-center align-items-center column-gap-5-px row-gap-5-px">
                                <span>Minimum Creation Date</span>
                                <FontAwesomeIcon onClick={() => { setDisplayMinimumCreationDatePicker1(true) }} className="cursor-pointer" icon={faPlus} />
                                {(displayMinimumCreationDatePicker1) ?
                                    <div className=" background-color-light-grey" style={{ minHeight: '300px', paddingTop: '15px' }}>
                                        <DatePicker className="Input-Style background-color-light-grey"
                                            minDate={null}
                                            maxDate={maximumCreationDate1 ?? null}

                                            selected={minimumCreationDate1}
                                            onChange={(value) => { console.log(value); setMinimumCreationDate1(value); setDisplayMinimumCreationDatePicker1(false); }}
                                            inline
                                            showTimeSelect
                                        //   filterTime={filteredTimes}

                                        />
                                        <span className="bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px" onClick={() => { setDisplayMinimumCreationDatePicker1(false); }}>Cancel</span>
                                    </div> : null}
                                {minimumCreationDate1 ? <span className="bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px" onClick={() => { setMinimumCreationDate1(null) }}>Clear</span> : null}
                            </div>
                            {minimumCreationDate1 ? <span className="cursor-pointer" onClick={() => { setDisplayMinimumCreationDatePicker1(true); }}>{minimumCreationDate1.toString().split("GMT")[0]}</span> : null}

                        </div>
                        <div className="display-flex flex-direction-column">
                            <div className="display-flex position-relative flex-wrap justify-content-center align-items-center column-gap-5-px row-gap-5-px">
                                <span>Maximum Creation Date</span>
                                <FontAwesomeIcon onClick={() => { setDisplayMaximumCreationDatePicker1(true) }} className="cursor-pointer" icon={faPlus} />
                                {(displayMaximumCreationDatePicker1) ?
                                    <div className="background-color-light-grey" style={{ minHeight: '300px', paddingTop: '15px' }}>
                                        <DatePicker className="Input-Style background-color-light-grey"
                                            minDate={minimumCreationDate1 ?? null}
                                            maxDate={null}

                                            selected={maximumCreationDate1}
                                            onChange={(value) => { console.log(value); setMaximumCreationDate1(value); setDisplayMaximumCreationDatePicker1(false); }}
                                            inline
                                            showTimeSelect
                                        //   filterTime={filteredTimes}

                                        />
                                        <span className="bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px" onClick={() => { setDisplayMaximumCreationDatePicker1(false); }}>Cancel</span>
                                    </div> : null}
                                {maximumCreationDate1 ? <span className="bold background-color-light-grey padding-5-px cursor-pointer border-radius-5-px" onClick={() => { setMaximumCreationDate1(null) }}>Clear</span> : null}
                            </div>
                            {maximumCreationDate1 ? <span className="cursor-pointer" onClick={() => { setDisplayMaximumCreationDatePicker1(true); }}>{maximumCreationDate1.toString().split("GMT")[0]}</span> : null}

                        </div>
                    </div>

                    <div className="display-flex flex-direction-column aling-items-center">
                        <span className="bold">Recipient User Type</span>
                        <div className="display-flex flex-wrap width-300-px">
                            <span onClick={() => { setSelectedDurationFilter1("All") }} className={`${selectedDurationFilter1 === "All" ? "Stripe-Selected-Duration" : ""} Coupon-Duration-Button Coupon-Left-Border Coupon-Right-Border`}>All Users</span>
                            <span onClick={() => { setSelectedDurationFilter1("Pros") }} className={`${selectedDurationFilter1 === "Pros" ? "Stripe-Selected-Duration" : ""} Coupon-Duration-Button Coupon-Left-Border Coupon-Right-Border`}>Pros</span>
                            <span onClick={() => { setSelectedDurationFilter1("Homeowners") }} className={`${selectedDurationFilter1 === "Homeowners" ? "Stripe-Selected-Duration" : ""} Coupon-Duration-Button Coupon-Left-Border Coupon-Right-Border`}>Homeowners</span>
                            <span onClick={() => { setSelectedDurationFilter1("Agents") }} className={`${selectedDurationFilter1 === "Agents" ? "Stripe-Selected-Duration" : ""} Coupon-Duration-Button Coupon-Left-Border Coupon-Right-Border`}>Agents</span>
                            <span onClick={() => { setSelectedDurationFilter1("Mortgage_Agents") }} className={`${selectedDurationFilter1 === "Mortgage_Agents" ? "Stripe-Selected-Duration" : ""} Coupon-Duration-Button  Coupon-Left-Border Coupon-Right-Border-Curve`}>Mortgage Agents</span>
                            <span onClick={() => { setSelectedDurationFilter1("Insurance_Agents") }} className={`${selectedDurationFilter1 === "Insurance_Agents" ? "Stripe-Selected-Duration" : ""} Coupon-Duration-Button Coupon-Left-Border Coupon-Right-Border-Curve`}>Insurance Agents</span>
                            <span onClick={() => { setSelectedDurationFilter1("Brokerages") }} className={`${selectedDurationFilter1 === "Brokerages" ? "Stripe-Selected-Duration" : ""} Coupon-Duration-Button Coupon-Left-Border Coupon-Right-Border-Curve`}>Brokerages</span>
                        </div>
                    </div>
                    <div className="display-flex flex-direction-column aling-items-center">
                        <select
                            name="state"
                            className="card-input__input -select"
                            value={categoryFilter}
                            onChange={(e) => { setCategoryFilter(e.currentTarget.value) }}

                        >
                            <option disabled>
                                Select Email Category
                            </option>
                            <option value={"All"}>
                                {"All"}
                            </option>
                            {
                                Object.keys(emailCategories).map((item, index) =>
                                    <option key={index} value={item}>
                                        {item}
                                    </option>
                                )
                            }
                        </select>
                        {(categoryFilter && categoryFilter !== "" && categoryFilter !== "All" && emailCategories[categoryFilter]) ?
                            <select
                                name="state"
                                className="card-input__input -select"
                                value={subCategoryFilter}
                                onChange={(e) => { setSubCategoryFilter(e.currentTarget.value) }}

                            >
                                <option disabled>
                                    Select Email Sub Category
                                </option>
                                <option value={"All"}>
                                    {"All"}
                                </option>
                                {
                                    emailCategories[categoryFilter].map((item, index) =>
                                        <option key={index} value={item}>
                                            {item}
                                        </option>
                                    )
                                }
                            </select> : null
                        }
                    </div>
                </div>

                <div className="display-flex flex-wrap justif-content-center align-items-center flex-direction-column column-gap-10-px row-gap-10-px">
                    <table class="styled-table">
                        <thead>
                            <tr>
                                {maximumCreationFilteredRecords[0] && Object.keys(maximumCreationFilteredRecords[0]).filter(item => item !== "sending_status" && item !== "content").map(item1 =>
                                    <th>{item1}</th>
                                )}
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {maximumCreationFilteredRecords.map((item11, idx) =>
                                <tr>

                                    {Object.keys(maximumCreationFilteredRecords[maximumCreationFilteredRecords.length - 1 - idx]).filter(item => item !== "sending_status" && item !== "content").map(item1 =>
                                        <td>{maximumCreationFilteredRecords[maximumCreationFilteredRecords.length - 1 - idx][item1]}</td>
                                    )}
                                    <td><FontAwesomeIcon onClick={() => { setSelectedViewEmail(maximumCreationFilteredRecords[maximumCreationFilteredRecords.length - 1 - idx]["content"]) }} className="cursor-pointer" icon={faEnvelope} /></td>
                                </tr>)}

                        </tbody>
                    </table>

                </div>


                <Modal
                    show={selectedViewEmail}
                    onHide={() => setSelectedViewEmail(null)}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                    style={{ top: '15%' }}
                    focus={true}
                >
                    <ModalBody >
                        <Button variant="secondary height-40-px" onClick={() => setSelectedViewEmail(null)}>
                            Close
                        </Button>

                        <div className="display-flex justify-content-center flex-wrap">

                            <div dangerouslySetInnerHTML={{
                                __html: selectedViewEmail ? selectedViewEmail : ""
                            }}></div>
                        </div>

                    </ModalBody>
                    <Modal.Footer >

                        <Button variant="secondary height-40-px" onClick={() => setSelectedViewEmail(null)}>
                            Close
                        </Button>



                    </Modal.Footer>

                </Modal>


            </div>
        )
    }


    const viewOptions = {
        "notSelected": notSelected(),
        "view-email-templates": viewEmailTemplates(),
        "send-emails": sendEmails(),
        "view-email-history": viewEmailHistory()
    }

    const saveTemplate = (isNew) => {
        if (isNew || (initialTemplateContent.current !== selectedTemplate?.content) || newTitle !== selectedTemplate.title) {
            var newTemp = { ...selectedTemplate }
            if (isNew) {
                delete newTemp["id"];
            }
            newTemp["title"] = newTitle;
            newTemp["subject"] = emailSubject;
            newTemp["category"] = emailCategory;
            newTemp["sub_category"] = emailSubCategory;
            console.log("newTmp", newTemp);
            // selectedTemplate["title"] = newTitle;
            selectedTemplate["title"] = newTitle;
            selectedTemplate["subject"] = emailSubject;
            selectedTemplate["category"] = emailCategory;
            selectedTemplate["sub_category"] = emailSubCategory;
            sqlService.saveEmailTemplate({ "input": { template: newTemp } }).then(d => {
                console.log("ddd is", d);
                setEditEnabled(false);
                getTemplates();
            }).catch(err => {
                console.log(err);
            })
        } else {
            setEditEnabled(false);
            console.log("no change")
        }

    }


    useEffect(() => {
        switch (selectedDurationFilter) {
            case "Pros":
                setFilteredUsers(allPros);
                break;
            case "Agents":
                setFilteredUsers(allAgents)
                break;
            case "Insurance_Agents":
                setFilteredUsers(allInsuranceAgents)
                break;
            case "Mortgage_Agents":
                setFilteredUsers(allMortgageAgents)
                break;
            case "Homeowners":
                setFilteredUsers(allHomeowners)
                break;
            case "Brokerages":
                setFilteredUsers(allBrokerages)
                break;
            default:
                setFilteredUsers([...allPros, ...allAgents, ...allInsuranceAgents, ...allMortgageAgents, ...allHomeowners, ...allBrokerages])
                break;
        }
    }, [allPros, allAgents, allInsuranceAgents, allMortgageAgents, allHomeowners, allBrokerages, selectedDurationFilter])

    useEffect(() => {
        console.log("dfsdfsdfsdfsd")
        setFilteredUsersFromSelected(filteredUsers.filter(item => !selectedUsers[item.email]))
    }, [selectedUsers, filteredUsers])

    useEffect(() => {
        console.log("dfsdfsdfsdfsd", searchKeywordFilter)
        if (searchKeywordFilter !== "") {
            let filteredBySearch = searchUtility.filterBySearchArrayOfObjects(filteredUsersFromSelected, searchKeywordFilter)
            setFilteredBySearchUsers(filteredBySearch)

        } else {
            setFilteredBySearchUsers(filteredUsersFromSelected);
        }
    }, [searchKeywordFilter, filteredUsersFromSelected]);

    useEffect(() => {
        if (minimumCreationDate) {
            var pass = []
            let min = new Date(minimumCreationDate)
            for (let item of filteredBySearchUsers) {
                let dat = new Date(item?.creation_date);
                if (dat >= min) {
                    pass.push(item);
                }
            }
            setMinimumCreationFilteredUsers(pass);
        }
        else {
            setMinimumCreationFilteredUsers(filteredBySearchUsers)
        }
    }, [filteredBySearchUsers, minimumCreationDate])

    useEffect(() => {
        if (maximumCreationDate) {
            var pass = []
            let max = new Date(maximumCreationDate)
            for (let item of minimumCreationFilteredUsers) {
                let dat = new Date(item?.creation_date);
                if (dat <= max) {
                    pass.push(item);
                }
            }
            setMaximumCreationFilteredUsers(pass);
        }
        else {
            setMaximumCreationFilteredUsers(minimumCreationFilteredUsers)
        }
    }, [minimumCreationFilteredUsers, maximumCreationDate])

    useEffect(() => {
        console.log("sdfsdfsdfdsfsd selected tem[", selectedView)
        if (selectedView === "send-emails") {
            console.log("getting all types of users");
            sqlService
                .getAllBrokerages()
                .then((d) => {
                    setAllBrokerages(d?.data ?? [])
                })
                .catch((err) => {
                    console.log(err)
                });

            sqlService
                .getAllAgents()
                .then((d) => {
                    setAllAgents(d?.data ?? [])
                })
                .catch((err) => {
                    console.log(err)
                });

            sqlService
                .getAllMortgageAgents()
                .then((d) => {
                    setAllMortgageAgents(d?.data ?? []);
                })
                .catch((err) => {
                    console.log(err)
                });

            sqlService
                .getAllInsuranceAgents()
                .then((d) => {
                    setAllInsuranceAgents(d?.data ?? []);
                })
                .catch((err) => {
                    console.log(err)
                });

            sqlService
                .getAllHomeowners()
                .then((d) => {
                    setAllHomeowners(d?.data ?? []);
                })
                .catch((err) => {
                    console.log(err)
                });

            sqlService
                .getAllPros()
                .then((d) => {
                    var added = []
                    var items = []
                    for (let item of d?.data) {
                        if (!added.includes(item?.email)) {
                            added.push(item.email);
                            items.push(item);
                        }
                    }
                    setAllPros(items);
                })
                .catch((err) => {
                    console.log(err)
                });

        }
    }, [selectedView])

    const resetFilters = () => {
        setMaximumCreationDate(null);
        setMinimumCreationDate(null);
        setSearchKeywordFilter("");
        setSelectedDurationFilter("All");
    }

    const resetFilters1 = () => {
        setMaximumCreationDate1(null);
        setMinimumCreationDate1(null);
        setSearchKeywordFilter1("");
        setSelectedDurationFilter1("All");
        setCategoryFilter("All");
        setSubCategoryFilter("All");
    }

    const handleSelectAll = () => {
        var select = {};
        for (let item of maximumCreationFilteredUsers) {
            select[item?.email] = item;
        };
        setSelectedUsers(select);
    }

    const handleSendingEmail = () => {
        setSendingIsOn(true);
        console.log(selectedTemplate)
        sqlService.sendEmailsToSelectedUsersUsingTemplateId({ input: { template: selectedTemplate?.content, category: selectedTemplate?.category, sub_category: selectedTemplate?.sub_category, recipients: selectedUsers, attachment: attachment, subject: selectedTemplate?.subject  } }).then(data => {
            console.log(data);
            setSendingIsOn(false);
            setSelectedUsers({});
            setAttachment([]);
            getEmailRecords();
        }).catch(err => {
            console.log(err);
            setSendingIsOn(false);
        })
    }

    useEffect(() => {
        switch (selectedDurationFilter1) {
            case "Pros":
                setFilteredByRecipientRoleIdRecords(emailRecords.filter(item => item.recipient_role_id === 4))
                break;
            case "Agents":
                setFilteredByRecipientRoleIdRecords(emailRecords.filter(item => item.recipient_role_id === 2))
                break;
            case "Insurance_Agents":
                setFilteredUsers(allInsuranceAgents)
                setFilteredByRecipientRoleIdRecords(emailRecords.filter(item => item.recipient_role_id === 6))
                break;
            case "Mortgage_Agents":
                setFilteredByRecipientRoleIdRecords(emailRecords.filter(item => item.recipient_role_id === 5))
                break;
            case "Homeowners":
                setFilteredByRecipientRoleIdRecords(emailRecords.filter(item => item.recipient_role_id === 1))
                break;
            case "Brokerages":
                setFilteredByRecipientRoleIdRecords(emailRecords.filter(item => item.recipient_role_id === 3))
                break;
            default:
                setFilteredByRecipientRoleIdRecords(emailRecords);
                break;
        }
    }, [selectedDurationFilter1, emailRecords]);

    useEffect(() => {
        if (categoryFilter !== "All") {
            setFilteredByCategoryRecords(filteredByRecipientRoleIdRecords.filter(item => item.category === categoryFilter))
        } else {
            setFilteredByCategoryRecords(filteredByRecipientRoleIdRecords);
        }
    }, [categoryFilter, filteredByRecipientRoleIdRecords]);

    useEffect(() => {
        if (subCategoryFilter !== "All" && categoryFilter !== "All") {
            setFilteredBySubCategoryRecords(filteredByCategoryRecords.filter(item => item.sub_category === subCategoryFilter))
        } else {
            setFilteredBySubCategoryRecords(filteredByCategoryRecords);
        }
    }, [subCategoryFilter, filteredByCategoryRecords]);

    useEffect(() => {
        if (searchKeywordFilter1 !== "") {
            let filteredBySearch = searchUtility.filterBySearchArrayOfObjects(filteredBySubCategoryRecords, searchKeywordFilter1)
            setFilteredBySearchRecords(filteredBySearch)

        } else {
            setFilteredBySearchRecords(filteredBySubCategoryRecords);
        }
    }, [searchKeywordFilter1, filteredBySubCategoryRecords]);

    useEffect(() => {
        if (minimumCreationDate1) {
            var pass = []
            let min = new Date(minimumCreationDate1)
            for (let item of filteredBySearchRecords) {
                let dat = new Date(item?.creation_time);
                if (dat >= min) {
                    pass.push(item);
                }
            }
            setMinimumCreationFilteredRecords(pass);
        }
        else {
            setMinimumCreationFilteredRecords(filteredBySearchRecords)
        }
    }, [filteredBySearchRecords, minimumCreationDate1])

    useEffect(() => {
        console.log("minimumCrea", minimumCreationFilteredRecords)
        if (maximumCreationDate1) {
            var pass = []
            let max = new Date(maximumCreationDate1)
            for (let item of minimumCreationFilteredRecords) {
                let dat = new Date(item?.creation_time);
                if (dat <= max) {
                    pass.push(item);
                }
            }
            setMaximumCreationFilteredRecords(pass);
        }
        else {
            setMaximumCreationFilteredRecords(minimumCreationFilteredRecords)
        }
    }, [minimumCreationFilteredRecords, maximumCreationDate1])

    return (
        <div className="display-flex flex-direction-column flex-wrap justify-content-center align-items-center margin-top-50-px">
            {viewOptions[selectedView] ?? <div onClick={() => { setSelectedView("notSelected") }} className=" cursor-pointer display-flex flex-wrap align-items-center justify-content-center column-gap-10-px row-gap-10-px">
                <h4>View doesn't Exist</h4>
                <h4>Click to Go Back to Menu</h4>
                <FontAwesomeIcon icon={faEllipsisVertical} />

            </div>}
        </div>
    )
}