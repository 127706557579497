import React, { useRef } from "react";
import { useState, useEffect } from "react";
import "./adminDashboard.css/adminTable.component.css";
import TableFooter from "../../utility.component/tableFooter.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlass, faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import AdminEdit from "./adminEdit.component";
import AdminAdd from "./adminAdd.component";
import AdminDelete from "./adminDelete.component";
import AdminPopup from "./adminPopUp.component";
import AdminStripe from "./adminStripe.component";
import sqlService from "../../../services/sqlService";
import { useContext } from "react";
import { UserContext } from "../../../App";
import { MdHandyman } from "react-icons/md";
import { FaCcStripe, FaStripe } from "react-icons/fa";
import { RiLoginBoxLine } from "react-icons/ri";
import sortUtility from "../../../functions/sortUtility";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
const AdminTable = ({
  data,
  columns,
  selectedTable,
  tableOptions,
  onTableChange,
  addButton,
  currentPage,
  totalPages,
  nextPage,
  prevPage,
  editFunction,
  setRefreshData,
  deleteFunction,
  addFunction,
  selectedLockedFilter,
  onTableLockedFilterChange,
  tableLockedFilterOptions,
  selectedFilter,
  onTableFilterChange,
  tableFilterOptions,
  setRefreshSubscriptions,
  allSubscriptions,
  onTableActiveFilterChange,
  selectedActiveFilter,
  handleAgentFilter,
  agentFilter

}) => {
  const [windowSize, setWindowSize] = React.useState(window.innerWidth);

  const onWindowResize = () => {
    setWindowSize(window.innerWidth);

  };


  useEffect(() => {
    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);



  const user = useContext(UserContext);

  const [editPopup, setEditPopup] = useState(false);
  const [stripePopup, setStripePopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [addPopup, setAddPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [adminPopup, setAdminPopup] = useState(false);
  const [loginPopup, setLoginPopup] = useState(false);

  const [toBeLoggedinTo, setToBeLoggedinTo] = useState(false);

  const currentData = data;

  const [scrollCurrentPage, setScrollCurrentPage] = useState(1)
  const [scrollTotalPages, setScrollTotalPages] = useState(1)

  const openLoginPopup = (item) => {
    setLoginPopup(true);
  };

  const closeLoginPopUp = () => {
    setLoginPopup(false);
  };


  const openDeletePopup = (item) => {
    setSelectedItem(item);
    setDeletePopup(true);
  };

  const closeDeletePopUp = () => {
    setDeletePopup(false);
  };

  const openEditWindow = (item) => {
    setSelectedItem(item);
    setEditPopup(true);
  };

  const closeEditWindow = () => {
    setEditPopup(false);
  };

  const openStripeWindow = (item) => {
    setSelectedItem(item);
    setStripePopup(true);
  };

  const closeStripeWindow = () => {
    setStripePopup(false);
  };

  const openAddWindow = () => {
    setAddPopup(true);
  };

  const closeAddWindow = () => {
    setAddPopup(false);
  };

  const openAdminPopup = (item) => {
    setSelectedItem(item);
    setAdminPopup(true);
  };

  const closeAdminPopup = () => {
    setAdminPopup(false);
  };


  const handleLockedToggle = (item, value) => {
    if (selectedTable === "agents" || selectedTable === "brokerages" || selectedTable === "insurance_agents" || selectedTable === "mortgage_agents") {
      sqlService.updateUserByField({ "input": { "field": "locked", "value": value, "userId": item?.id ?? item?.user_id } }).then(data => {
        setRefreshData((refresh) => !refresh);
      }).catch(err => {
        console.log(err);
      })

    } else if (selectedTable === "pros") {
      sqlService.updateProByField({ "input": { "field": "locked", "value": value, "proId": item?.id ?? item?.user_id } }).then(data => {
        setRefreshData((refresh) => !refresh);
      }).catch(err => {
        console.log(err);
      })

    }
  }

  const handleSubscribeToggle = (item, value) => {
    sqlService.updateUserByField({ "input": { "field": "subscribed", "value": value, "userId": item.id } }).then(data => {
      setRefreshData((refresh) => !refresh);
    }).catch(err => {
      console.log(err);
    })
  }

  const handleValidateToggle = (item, value) => {
    if (selectedTable === "agents" || selectedTable === "brokerages") {
      sqlService.updateUserByField({ "input": { "field": "admin_verified", "value": value, "userId": item.id } }).then(data => {
        setRefreshData((refresh) => !refresh);
      }).catch(err => {
        console.log(err);
      })
    } else if (selectedTable === "pros") {
      sqlService.updateProByField({ "input": { "field": "admin_verified", "value": value, "proId": item.id } }).then(data => {
        setRefreshData((refresh) => !refresh);
      }).catch(err => {
        console.log(err);
      })
    }
  }

  const loginAdminToUser = (email) => {
    sqlService.loginAdminToUser({ input: { userEmail: email } }).then(data => {
      if (data?.user) {
        data = data?.user;
      }
      document.cookie = `honeyydo_user_cookie=${JSON.stringify({ data })}; expires=${new Date(Date.now() + (30 * 86400000))}; path=/; sameSite=strict; secure`;
      let baseURL = `http://localhost:3000/#/`;
      if (process.env.REACT_APP_ENV === "staging") {
        baseURL = process.env.REACT_APP_BASE_DOMAIN_STAGING;
      } else if (process.env.REACT_APP_ENV === "production") {
        baseURL = process.env.REACT_APP_BASE_DOMAIN;
      }
      window.location.assign(baseURL);
      window.location.reload();
    }).catch(err => {
      console.log(err);
    })
  }

  const [agents, setAgents] = useState([])
  const agentsLoaded = useRef(false)

  useEffect(() => {
    if (selectedTable === 'homeowners' || selectedTable === 'pros' && !agentsLoaded.current) {
      sqlService
        .getAdminAgents({ user: user })
        .then((data) => {
          setAgents(data);
          agentsLoaded.current = true;

        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedTable])

  const handleScrollingTable = (e) => {
    // console.log("scrollTop",e.target.scrollTop)
    // console.log("scrollHeight", e.target.scrollHeight)
    // console.log("offsetTop",e.target.offsetTop)
    // console.log("clientHeight",e.target.clientHeight)
    // client height is the different between the scrollHeight and the maximum scroll can react in the scroll top
    // so scrollHieght = maxScrollTOp + clientHeight
    let element = e.target
    let pageNumber = Math.round(element.scrollTop / (element.clientHeight - 100)) + 1;
    setScrollCurrentPage(pageNumber);
    // console.log("pageNumber", pageNumber)
    // console.log()
    if (element.scrollHeight - Math.round(element.scrollTop) <= element.clientHeight) {
      setScrollCurrentPage(scrollTotalPages);
    }
  }

  useEffect(() => {
    let element = document.getElementById('tableScrollElementHoneyydoClient');
    let totalPages = Math.round(element.scrollHeight / (element.clientHeight - 100));
    // console.log(totalPages, element.scrollHeight, element.clientHeight)
    element.scrollTo({ top: 0 })
    setScrollCurrentPage(1);
    setScrollTotalPages(totalPages)
  }, [selectedTable, currentData, windowSize])

  return (
    <>
      <div className="center-wrapper">
        <div className="adminTable-name-button-container">
          {/* <select
            className="admin-dropdown"
            value={selectedTable}
            onChange={onTableChange}
          >
            {tableOptions.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select> */}
          {tableFilterOptions ?
            <select
              className="admin-dropdown"
              value={selectedFilter}
              onChange={onTableFilterChange}
            >
              {tableFilterOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select> : null}
          {tableLockedFilterOptions ?
            <select
              className="admin-dropdown"
              value={selectedLockedFilter}
              onChange={onTableLockedFilterChange}
            >
              {tableLockedFilterOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select> : null}
          <select
            className="admin-dropdown"
            value={selectedTable}
            onChange={onTableChange}
          >
            {tableOptions.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <div onClick={openAddWindow}>{addButton}</div>
        </div>
      </div>
      {selectedTable === 'homeowners' || selectedTable === 'agents' || selectedTable === 'mortgage_agents' || selectedTable === 'insurance_agents' || selectedTable === 'brokerages' || selectedTable === 'pros' ?
        <div className="center-wrapper margin-top-5-px">
          <div className="adminTable-name-button-container">
            {selectedTable !== 'pros' ?
              <div >
                <span>Active since:</span>
                <div className="display-flex">
                  <DatePicker

                    onChange={onTableActiveFilterChange}
                    selected={selectedActiveFilter}
                  // icon={faCalendar}
                  ></DatePicker>
                  <button style={{ padding: '0', marginLeft: '5px', zIndex: 1 }} onClick={() => onTableActiveFilterChange(null)} className="Tag-Button">Clear</button>
                </div>
              </div>
              : <div />}

            {selectedTable === 'homeowners' || selectedTable === 'pros' ?
              <div style={{ paddingTop: '20px' }} className="display-flex align-items-end column-gap-10-px">
                <span>Agent:</span>
                <select onChange={handleAgentFilter} value={agentFilter}>
                  <option value={'All'}>All</option>
                  {agents.map((agent) =>
                    <option value={agent.id} >{agent.contact_name}</option>
                  )}
                </select>
              </div> : null}

          </div>
        </div> : null}

      <div className="center-wrapper">
        <div id="tableScrollElementHoneyydoClient" className="Table-List-Container-Admin margin-bottom-5-vh" onScroll={handleScrollingTable}>
          {(windowSize > 850 && selectedTable === 'tasks') || windowSize > 1200 ?
            <table style={{ minWidth: '800px' }} className="Uni-Table-Container">
              <thead className="Uni-Table-Header">
                <tr>
                  {columns.filter((column) => selectedTable === 'tasks' || ['company_name', 'contact_name', 'email', 'phone_number', 'agent_name'].includes(column.field)).map((column, index) => (
                    <th key={index}>{column.header}</th>
                  ))}
                  {/* {(selectedTable === "agents" || selectedTable === "brokerages" || selectedTable === "homeowners") ?
                  <th>Subscription</th> : null} */}
                  {/* {(selectedTable === "pros") ?
                  <th>Verified</th> : null} */}
                  {(selectedTable === 'tasks') ?
                    <>
                      <th>Locked</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </> : null}
                  {/* {(selectedTable === "agents" || selectedTable === "brokerages" || selectedTable === "homeowners") ?
                  <th>Subscribed</th> : null} */}
                  {selectedTable === 'homeowners' || selectedTable === 'agents' || selectedTable === 'mortgage_agents' || selectedTable === 'insurance_agents' || selectedTable === 'brokerages' || selectedTable === 'pros' ?
                    <th /> : null}
                </tr>
              </thead>
              <tbody className="Uni-Table-Body">
                {currentData && currentData?.length > 0
                  ? currentData?.map((item, index) => (
                    <tr key={index}>
                      {columns.filter((column) => selectedTable === 'tasks' || ['company_name', 'contact_name', 'email', 'phone_number', 'agent_name'].includes(column.field)).map((column, index) => (
                        <td key={index}>
                          {column.render
                            ? column.render(item, openAdminPopup)
                            : item[column.field]}
                        </td>
                      ))}

                      {/* <td className="">
                      <span className="cursor-pointer stripe-icon">
                        <FontAwesomeIcon
                          icon={faPenToSquare}
                          size="lg"
                          color="orange"
                          onClick={() => openEditWindow(item)}
                        />
                      </span>
                      {" "}{" "}{" "}
                      <FontAwesomeIcon
                        icon={faTrash}
                        size="lg"
                        color="orange"
                        onClick={() => {
                          openDeletePopup(item);
                        }}
                      />
                    </td> */}
                      {/* {(selectedTable === "agents" || selectedTable === "brokerages" || selectedTable === "homeowners") ?
                      <td className="">
                        <div><span onClick={() => { if (allSubscriptions[item?.id?.toString()]) { openStripeWindow(item) } }} className={`cursor-pointer stripe-icon ${allSubscriptions[item?.id?.toString()]?.status ? "background-color-green" : ""}`}>{allSubscriptions[item?.id?.toString()]?.status ? <FaCcStripe /> : <FaStripe />}</span></div>
                      </td> : null} */}
                      {/* {(selectedTable === "pros") ?
                      <td className="">
                        <div className="admin-toggle-buttons-container-small">
                          <div className="toggle-buttons-small">
                            <button
                              className={`toggle-button-small ${item.admin_verified === 0 ? "toggle-button-selected-off" : ""
                                }`}
                              onClick={() => handleValidateToggle(item, item.admin_verified === 0 ? 1 : 0)}
                            >

                            </button>
                            <button
                              className={`toggle-button-small ${item.admin_verified === 1 ? "toggle-button-selected" : ""
                                }`}
                              onClick={() => handleValidateToggle(item, item.admin_verified === 0 ? 1 : 0)}
                            >


                            </button>

                          </div>
                        </div>
                      </td> : null} */}
                      {selectedTable === 'tasks' ?
                        <>
                          <td className="">
                            <div className="admin-toggle-buttons-container-small">
                              <div className="toggle-buttons-small">
                                <button
                                  className={`toggle-button-small ${item.locked === 0 ? "toggle-button-selected-off" : ""
                                    }`}
                                  onClick={() => handleLockedToggle(item, item.locked === 0 ? 1 : 0)}
                                >
                                </button>
                                <button
                                  className={`toggle-button-small ${item.locked === 1 ? "toggle-button-selected" : ""
                                    }`}
                                  onClick={() => handleLockedToggle(item, item.locked === 0 ? 1 : 0)}
                                >


                                </button>

                              </div>
                            </div>
                          </td>

                          <td className="">
                            <span className="cursor-pointer stripe-icon">
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                size="lg"
                                color="orange"
                                onClick={() => openEditWindow(item)}
                              />
                            </span>
                          </td>
                          <td><FontAwesomeIcon className="cursor-pointer" icon={faTrash} onClick={() => openDeletePopup(item)} /></td>
                        </> : null}
                      {selectedTable === 'homeowners' || selectedTable === 'agents' || selectedTable === 'insurance_agents' || selectedTable === 'mortgage_agents' || selectedTable === 'brokerages' || selectedTable === 'pros' ?
                        <td>
                          <Link to={`/admin/${selectedTable}/${item?.id ?? item?.user_id}`} state={{ client: item, columns: columns, selectedTable: selectedTable, sub: allSubscriptions[item?.id?.toString()] }}>
                            <FontAwesomeIcon className="margin-right-10-px detail-icon" icon={faMagnifyingGlass} />
                          </Link>
                          {/* </td> : null}
                    {selectedTable === 'homeowners' || selectedTable === 'agents' || selectedTable === 'brokerages' ?
                      <td */}
                          <span
                            className="cursor-pointer stripe-icon icon-green"
                            onClick={() => {
                              setToBeLoggedinTo(item);
                              openLoginPopup(true);
                            }}>
                            <RiLoginBoxLine /></span>
                        </td> : null}

                      {/* {(selectedTable === "agents" || selectedTable === "brokerages" || selectedTable === "homeowners") ?
                      <td className="">
                        <div className="admin-toggle-buttons-container-small">
                          <div className="toggle-buttons-small">
                            <button
                              className={`toggle-button-small ${item.subscribed === 0 ? "toggle-button-selected-off" : ""
                                }`}
                              onClick={() => handleSubscribeToggle(item, item.subscribed === 0 ? 1 : 0)}
                            >
                            </button>
                            <button
                              className={`toggle-button-small ${item.subscribed === 1 ? "toggle-button-selected" : ""
                                }`}
                              onClick={() => handleSubscribeToggle(item, item.subscribed === 0 ? 1 : 0)}
                            >


                            </button>

                          </div>
                        </div>
                      </td> : null} */}
                    </tr>
                  ))
                  : null}
              </tbody>
            </table>
            :
            <>
              <div className="mobile-page-name-container margin-top-20-px">
                {" "}
                {/*mobile task list container */}
                {/* <h2 className="-mobile-page-name grey">{selectedTable}</h2> */}
              </div>
              <div className="tasklist-mobile-container">
                {currentData?.length > 0
                  ? currentData.map((item, index) => (

                    <div style={{ maxWidth: '700px' }} className="task-mobile-container" key={index}>
                      {columns.filter((column) => selectedTable === 'tasks' || selectedTable === 'pros' || ['contact_name', 'email', 'phone_number', 'agent_name'].includes(column.field)).map((column, index) => (


                        //   <td key={index}>
                        //     {column.render
                        //       ? column.render(item, openAdminPopup)
                        //       : item[column.field]}
                        //   </td>
                        // ))}
                        <>
                          <span style={{ padding: '0 10%' }} className={column.field === 'contact_name' || column.field === 'company_name' || column.field === 'title' ? 'bold' : ''}>
                            {column.field === 'creation_date' ? `Joined  ${new Date(item[column.field]).toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' })}`
                              : item[column.field]}
                          </span>
                        </>
                      ))}
                      <div className="display-flex margin-top-15-px justify-content-space-between" >
                        <div>
                          {/* {(selectedTable === "pros") ?

                          <div style={{ marginBottom: '10px' }} className="admin-toggle-buttons-container-small">
                            <span style={{ marginRight: '7px' }}>Verified: </span>
                            <div className="toggle-buttons-small">

                              <button
                                className={`toggle-button-small ${item.admin_verified === 0 ? "toggle-button-selected-off" : ""
                                  }`}
                                onClick={() => handleValidateToggle(item, item.admin_verified === 0 ? 1 : 0)}
                              >

                              </button>
                              <button
                                className={`toggle-button-small ${item.admin_verified === 1 ? "toggle-button-selected" : ""
                                  }`}
                                onClick={() => handleValidateToggle(item, item.admin_verified === 0 ? 1 : 0)}
                              >


                              </button>

                            </div>
                          </div>
                          : null} */}
                          {/* {(selectedTable === "agents" || selectedTable === "brokerages" || selectedTable === "homeowners") ?
                          <div className={`admin-toggle-buttons-container-small`}>
                            <span style={{ marginRight: '10px' }}>Subscribed:</span>

                            <div className="toggle-buttons-small">

                              <button
                                className={`toggle-button-small ${item.subscribed === 0 ? "toggle-button-selected-off" : ""
                                  }`}
                                onClick={() => handleSubscribeToggle(item, item.subscribed === 0 ? 1 : 0)}
                              >

                              </button>
                              <button
                                className={`toggle-button-small ${item.subscribed === 1 ? "toggle-button-selected" : ""
                                  }`}
                                onClick={() => handleSubscribeToggle(item, item.subscribed === 0 ? 1 : 0)}
                              >


                              </button>

                            </div>
                          </div>
                          : null} */}
                          {selectedTable === 'tasks' ?
                            <div className={`admin-toggle-buttons-container-small`}>
                              <span style={{ marginRight: '10px' }}>Locked:</span>

                              <div className="toggle-buttons-small">

                                <button
                                  className={`toggle-button-small ${item.locked === 0 ? "toggle-button-selected-off" : ""
                                    }`}
                                  onClick={() => handleLockedToggle(item, item.locked === 0 ? 1 : 0)}
                                >

                                </button>
                                <button
                                  className={`toggle-button-small ${item.locked === 1 ? "toggle-button-selected" : ""
                                    }`}
                                  onClick={() => handleLockedToggle(item, item.locked === 0 ? 1 : 0)}
                                >


                                </button>

                              </div>
                            </div> : null}
                        </div>
                        <div >
                          {/* <span className="cursor-pointer stripe-icon">
                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            size="lg"
                            color="orange"
                            onClick={() => openEditWindow(item)}
                          />
                        </span> */}

                          <Link to={`/admin/${selectedTable}/${item?.id ?? item?.user_id}`} state={{ client: item, columns: columns, selectedTable: selectedTable, sub: allSubscriptions[item?.id?.toString() ?? item?.user_id?.toString()] }}>
                            <FontAwesomeIcon className="fa-lg padding-10-px" icon={faMagnifyingGlass} />
                          </Link>
                          {/* {(selectedTable === "agents" || selectedTable === "brokerages" || selectedTable === "homeowners") ?

                          <div><span onClick={() => openStripeWindow(item)} className={`cursor-pointer stripe-icon ${allSubscriptions[item?.id?.toString()]?.status ? "background-color-green" : ""}`}>{allSubscriptions[item?.id?.toString()]?.status ? <FaCcStripe /> : <FaStripe />}</span></div>
                          : null} */}
                          {/* {" "}{" "}{" "}
                          <FontAwesomeIcon
                            icon={faTrash}
                            size="lg"
                            color="red"
                            onClick={() => {
                              openDeletePopup(item);
                            }}
                          /> */}
                        </div>

                      </div>
                    </div>

                  ))
                  : null}
              </div >
            </>
          }

          {
            deletePopup && (
              <AdminDelete
                onClose={closeDeletePopUp}
                item={selectedItem}
                deleteFunction={deleteFunction}
                setRefreshData={setRefreshData}
              />
            )
          }
          {
            editPopup && (
              <AdminEdit
                onClose={closeEditWindow}
                item={selectedItem}
                columns={columns}
                editFunction={editFunction}
                setRefreshData={setRefreshData}
                selectedTable={selectedTable}
              />
            )
          }
          {
            stripePopup && (
              <AdminStripe
                onClose={closeStripeWindow}
                item={selectedItem}
                stripeItem={allSubscriptions[selectedItem?.id?.toString()]}
                columns={columns}
                editFunction={editFunction}
                setRefreshData={setRefreshData}
                selectedTable={selectedTable}
              />
            )
          }
          {
            addPopup && (
              <AdminAdd
                onClose={closeAddWindow}
                columns={columns}
                addFunction={addFunction}
                setRefreshData={setRefreshData}
                selectedTable={selectedTable}
              />
            )
          }
          {
            adminPopup && (
              <AdminPopup
                onClose={closeAdminPopup}
                columns={columns.filter((column) => column.field === "content")}
                editFunction={editFunction}
                setRefreshData={setRefreshData}
                tableName={selectedTable}
                item={selectedItem}
                openAdminPopup={openAdminPopup}
              />
            )
          }
          {
            loginPopup && (
              <>
                <div className="delete-backdrop"></div>
                <div className="delete-popup">
                  <div className="admin-popup-title">
                    <h3>Confirm</h3>
                    <FontAwesomeIcon
                      className="admin-popup-close"
                      icon={faCircleXmark}
                      onClick={() => { setLoginPopup(false) }}
                    />
                  </div>
                  <h2 className="delete-Pop-Up-Question">
                    {`Are you sure you want to login to this user: ${toBeLoggedinTo?.email} ?`}
                  </h2>
                  <p>Pressing on login will make you logout as an admin.</p>
                  <div className="delete-Pop-Up-Buttons">
                    <button className="admin-delete-Button" onClick={() => { loginAdminToUser(toBeLoggedinTo?.email) }}>
                      Login
                    </button>
                    <button className="admin-cancel-Button" onClick={() => { setLoginPopup(false) }}>
                      Cancel
                    </button>
                  </div>
                </div>
              </>
            )
          }
        </div >
      </div>
      {currentData?.length ?
        <div className="center-wrapper margin-top-10-px">
          <TableFooter
            currentPage={scrollCurrentPage}
            totalPages={scrollTotalPages}
            nextPage={() => {
              let element = document.getElementById("tableScrollElementHoneyydoClient");
              if (element) {
                element.scrollBy({
                  top: element.clientHeight - 100,
                  behavior: "smooth",
                })
              }

            }}
            prevPage={() => {
              let element = document.getElementById("tableScrollElementHoneyydoClient");
              if (element) {
                element.scrollBy({
                  top: - (element.clientHeight - 100),
                  behavior: "smooth",
                })
              }
            }}
          />
        </div>
        : null}
    </>
  );
};

export default AdminTable;
