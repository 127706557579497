import React from "react";
import "./adminDashboard.css/adminPopup.component.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { useState, useContext, useEffect, useRef } from "react";
import { UserContext } from "../../../App";
import sqlService from "../../../services/sqlService";
import timeUtility from "../../../functions/timeUtility";
import DatePicker from "react-datepicker";
import stripeService from "../../../services/stripeService";

export default function AdminStripeCoupon({
    item,
    stripeItem,
    setRefreshData,
}) {
    const user = useContext(UserContext);
    const [changes, setChanges] = useState({ id: item.id });

    const [cancelCouponConfirm, setCancelCouponConfirm] = useState(false);

    useEffect(() => { console.log(stripeItem) }, [stripeItem])

    const cancelCoupon = (id) => {
        stripeService.cancelCouponFromSubscription({ "input": { "subscriptionId": id } }).then(d => {
            setRefreshData((refresh) => !refresh);
        }).catch(err => {
            console.log(err);
        })
    }

    return (
        <>
            <div className="background1-transparent display-flex">
                <div style={{ backgroundColor: 'lightgrey' }}>
                    <span className="bold margin-10-px">Discount:</span>
                </div>
                <div style={{ padding: '0 20px 10px 0' }} className="display-flex flex-direction-column">
                    <span className="margin-3-0-0-20-px"><span className="bold">start:</span> {timeUtility.getTimeFromSeconds(stripeItem?.discount?.start)?.toString()}</span>
                    <span className="margin-3-0-0-20-px"><span className="bold">end:</span> {timeUtility.getTimeFromSeconds(stripeItem?.discount?.end)?.toString()}</span>
                    <span className="margin-3-0-0-20-px"><span className="bold">Coupon time left in days:</span> {timeUtility.differenceInDays(null, timeUtility.getTimeFromSeconds(stripeItem?.discount?.end)?.toString())}</span>
                    {stripeItem?.discount?.coupon ?
                        <div className="display-flex flex-direction-column">
                            <span style={{ backgroundColor: 'lightgrey' }} className="bold">Coupon:</span>
                            <span className="margin-3-0-0-20-px"><span className="bold">Name:</span> {stripeItem?.discount?.coupon?.name}</span>
                            <span className="margin-3-0-0-20-px"><span className="bold">created:</span> {timeUtility.getTimeFromSeconds(stripeItem?.discount?.coupon?.created)?.toString()}</span>
                            <span className="margin-3-0-0-20-px"><span className="bold">Duration:</span> {stripeItem?.discount?.coupon?.duration}</span>
                            <span className="margin-3-0-0-20-px"><span className="bold">Duration in Months:</span> {stripeItem?.discount?.coupon?.duration_in_months}{" "}Months</span>
                            <span className="margin-3-0-0-20-px"><span className="bold">Percent off:</span> {stripeItem?.discount?.coupon?.percent_off}%</span>
                            <span className="margin-3-0-0-20-px"><span className="bold">Code:</span> {stripeItem?.discount?.coupon?.metadata?.coupon_code}</span>
                            <span className="margin-3-0-0-20-px"><span className="bold">Plan:</span> {stripeItem?.discount?.coupon?.metadata?.subscriptionPlan}</span>
                            <span className="margin-3-0-0-20-px"><span className="bold">Max Redemption:</span> {stripeItem?.discount?.coupon?.max_redemptions}</span>
                            <span className="margin-3-0-0-20-px"><span className="bold">Times Redeemed:</span> {stripeItem?.discount?.coupon?.times_redeemed}</span>
                            <span className="margin-3-0-0-20-px"><span className="bold">Redemptions Left:</span> {stripeItem?.discount?.coupon?.max_redemptions - stripeItem?.discount?.coupon?.times_redeemed}</span>
                            <span className="margin-3-0-0-20-px"><span className="bold">Association:</span> {stripeItem?.discount?.coupon?.max_redemptions - stripeItem?.discount?.coupon?.metadata?.association_id}</span>
                            <button onClick={() => setCancelCouponConfirm(true)} className="price-card-button2">Cancel Coupon</button>
                            {cancelCouponConfirm ?
                                <div className="display-flex flex-wrap">
                                    <span>Are you sure you want to cancel coupon?</span>
                                    <button onClick={() => cancelCoupon(stripeItem.id)} className="price-card-button2">Yes</button>
                                    <button onClick={() => setCancelCouponConfirm(false)} className="price-card-button2">No</button>
                                </div> : null
                            }
                        </div> : null}
                </div>
            </div>

        </>
    );
}
