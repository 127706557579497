
import React from 'react';
import './faq.component.css';
import { Link } from "react-router-dom";

function Faq() {

    return (
        <div className="background margin-bottom-5-vh">
            <h1 className="faq-padding heading">FAQ</h1>


            <div className="faq-container">
                <div className='faq-item'>
                    <h3 className="faq-padding heading">What does Honeyydo cost?</h3>
                    <p className="color-med-grey padding-0-5-pt text-smaller bold margin-top-5-vh" >Honeyydo is paid for by our realtor partners. Homeowners can gain access to Honeyydo if their realtor has set up an account with Honeyydo. If your realtor has provided you an account, feel free to drop them a review as a thank you!</p>
                </div>
                {/* <div className='faq-item'>
                    <h3 className="faq-padding heading">Can I use Honeyydo without a realtor?</h3>
                    <p className="color-med-grey padding-0-5-pt text-smaller bold margin-top-5-vh" >Yes you can! If you want to still use Honeyydo you can still access it. Checkout www.Honeyydo.com/pricing for more information.</p>
                </div> */}
                {/* <div>   Might come back to this in the future
                    <h3 className="faq-padding heading">What are the data and insights on the platform?</h3>
                    <p className="color-med-grey padding-0-5-pt text-smaller bold margin-top-5-vh">Honeyydo has partnered with Realm to provide best in class, property specific insights to you.  Check out Realm  for your FREE customized insights report.</p>
                </div> */}
                {/* <div className='faq-item'>
                    <h3 className="faq-padding heading">Do I have to do all the prebuilt maintenance items?</h3>
                    <p className="color-med-grey padding-0-5-pt text-smaller bold margin-top-5-vh">No, we pre-populate items based off of region and season, that being said, you will always have the ability to customize which tasks you have. You can delete and add as you need. </p>
                </div> */}
                <div className='faq-item'>
                    <h3 className="faq-padding heading">What types of things can I include in my Filing Cabinet?</h3>
                    <p className="color-med-grey padding-0-5-pt text-smaller bold margin-top-5-vh">Anything that is in your home and you would like to keep track of... Honeyydo is completely customizable, so as long as it has an owners manual or warranty info.. we'll house that info for you. </p>
                </div>
                {/* <div className='faq-item'>
                    <h3 className="faq-padding heading">What if I don't have my make and model handy?</h3>
                    <p className="color-med-grey padding-0-5-pt text-smaller bold margin-top-5-vh">Simply put what you know, most manufacturers will have information that is findable in our database and will point you in the right direction.  Or you can snap a picture on your mobile device.</p>
                </div> */}
                <div className='faq-item'>
                    <h3 className="faq-padding heading">I want to print out my list, can I do that?</h3>
                    <p className="color-med-grey padding-0-5-pt text-smaller bold margin-top-5-vh">Absolutely. You can click the print icon and print the page you are viewing. This makes for a great way to have a quick fridge list on those busy Saturday mornings. </p>
                </div>
                <div className='faq-item'>
                    <h3 className="faq-padding heading">Can I import all my clients?</h3>
                    <p className="color-med-grey padding-0-5-pt text-smaller bold margin-top-5-vh">Honeyydo provides you with the capability to import your entire client base. This feature allows you to transition your data to our system, ensuring no client data is lost in the process.</p>
                </div>
                <div className='faq-item'>
                    <h3 className="faq-padding heading">How do I use coupons?</h3>
                    <p className="color-med-grey padding-0-5-pt text-smaller bold margin-top-5-vh">If you have a Honeyydo coupon code, then in order to redeem it, you would need to sign up through the pricing page and before you pay, you are given the option to enter a coupon code. Just type it in and see the savings.</p>
                </div>
                <div className='faq-item'>
                    <h3 className="faq-padding heading">Is Honeyydo a CRM or a lead generation tool?</h3>
                    <p className="color-med-grey padding-0-5-pt text-smaller bold margin-top-5-vh">While Honeyydo does offer CRM and lead generation tools, this is not Honeyydo's primary purpose and instead we focus on key touchpoints between agents and homeowners.</p>
                </div>
            </div>

            <div className='margin-auto-lr margin-top-5-vh text-align-center'>
                <p className='text-align-center padding-3-5-pt'>More questions? Check out our detailed user guides <Link to="https://support.honeydo.com/hc/en-us" target='_blank'>here</Link></p>
            </div>

        </div>

    );

}

export default Faq;