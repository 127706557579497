import React, { useEffect, useState, useRef } from 'react';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import bannerImg from "../../../assets/images/house-hero-min.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MdOutlineMenuOpen } from "react-icons/md";
import referralImg from "../../../assets/images/Referral_Graphic.png";
import blogImg from "../../../assets/images/womanonlaptop.jpg";
import conciergeImg from "../../../assets/images/housekeys2.jpg";
import reviewsImg from "../../../assets/images/Reviews_2.jpg";
import newslettersImg from "../../../assets/images/Newsletter_Graphic.png";
import phoneImg from "../../../assets/images/Woman_on_Phone-Smiling-min.jpg"
import professionalImg from "../../../assets/images/Smiling_Professional-min.jpg"
import { Link } from "react-router-dom";
import './newLanding.component.css'
import {
    faUserGroup,
    faStar,
    faPenToSquare,
    faComment,
    faHouseCircleCheck,
    faScrewdriverWrench,
    faFile,
    faUsers,
    faUser,
    faMobileScreen,
    faArrowDown,
    faKey,
    faEnvelopeOpen,
    faPaperPlane,
    faFolder,
    faShieldHalved
} from '@fortawesome/free-solid-svg-icons';
// import HeaderAnimation from "../header-animation.component/header-animation.component";

import { useLocation } from "react-router-dom";
import logoImg from "../../../assets/images/Banner/Honeyydo_Logo2-removebg.png";
import Logo from "../../../assets/images/Banner/Honeyydo_Logo2-removebg.png";
import { TbTableOptions } from "react-icons/tb";
import { BiRightIndent } from "react-icons/bi";
import { TfiClose } from "react-icons/tfi";

import dwellImg from "../../../assets/images/company-logos/dwell.png";
import limbirdImg from "../../../assets/images/company-logos/limbard3.jpg";
import jparImg from "../../../assets/images/company-logos/JPAR.png";
import reachImg from "../../../assets/images/company-logos/reach.jpg";
import stratumImg from "../../../assets/images/company-logos/stratum.jpg";
import exp from "../../../assets/images/company-logos/exp.png";
import coldwellBanker from "../../../assets/images/company-logos/coldwellBanker.jpg";
import exitRealty from "../../../assets/images/company-logos/exitRealty.png";
import kw from "../../../assets/images/company-logos/KW.jpg";
import compass from "../../../assets/images/company-logos/compass.png";
import redfin from "../../../assets/images/company-logos/redfin.png";
import berkshirehathaway from "../../../assets/images/company-logos/berkshire-hathaway.jpg";
import sothebys from "../../../assets/images/company-logos/sothebys.jpg";


import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

import { useContext } from 'react';
import { UserContext } from '../../../App';
import { useNavigate } from 'react-router-dom';

import userUtility from '../../../functions/userUtitily';
import signupConstants from "../../../constants/signupConstants";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";



function NewLanding() {


    const user = useContext(UserContext);


    const Navigate = useNavigate();

    // useEffect(() => {
    //     if (user) {
    //         // console.log(user);

    //         let userRoute = userUtility.getUserRoute(user)
    //         let currentPath = window.location.pathname;
    //         console.log(currentPath, userRoute)
    //         // debugger;
    //         if (!currentPath.endsWith(userRoute)) {
    //             console.log(userRoute)
    //             // debugger;
    //             Navigate(userRoute);
    //         }
    //     }
    // }, []);




    const [windowSize, setWindowSize] = React.useState(window.innerWidth);



    const [path, setPath] = useState(window.location.pathname);

    const location = useLocation();


    useEffect(() => {
        window.addEventListener('resize', onWindowResize);

        return () => {
            window.removeEventListener('resize', onWindowResize);
        };
    }, []);


    const onWindowResize = () => {
        setWindowSize(window.innerWidth);
    };


    useEffect(
        () => {
            setPath(location.pathname);
        }
        , [location]);

    const firstContent = [[faStar, "Reviews"], [faUsers, "Referrals"], [faPenToSquare, "Blog Content"], [faComment, "Social Content"]];
    const secondContent = [
        [faHouseCircleCheck, "Manage home maintenance schedules",
            "Manage tasks in one single view with pre-built maintenance schedules and checklists."],
        [faFolder, "Digitize home owners manuals",
            "Keep track of everything in your home.  Keep owner's manuals and warranty info in one central place."],
        [faScrewdriverWrench, "Hire local pros",
            "Not into DIY? We've got locally vetted professionals ready to help you manage your home."],
        [faKey, "Concierge Services",
            "We get your clients internet and utilities set up for free, facilitating a smooth move into their new homes."],
        [faFile, "Property Report",
            "Easily track improvements done on the home and auto-generate a PDF report."]
    ];

    const brandLogos = [jparImg, dwellImg, limbirdImg, reachImg, stratumImg, exp, coldwellBanker, exitRealty, kw, compass, redfin, berkshirehathaway, sothebys];

    // Use '<|>' to separate quote from attribution
    const quotes = ["We partnered with Honeyydo  to enable agents to deliver more value to clients. We love the chance to be able to brand the app, provide it to the client and the ability for it to drive more long term client retention. <|>- JPAR, The Sears Group",
        "I have been using Honeyydo for a while now and I really feel that I’ve gotten my money's worth and more. It’s helped me manage and stay connected to my clients, generate leads through word of mouth, and even bolster my reputation via reviews. <|>- Craig Barton - Valley Wide Homes",
        "If you have a database of any sort, Honeyydo is a must-have. They have generated me leads, reviews, and many touchpoints so far. This app has so much potential and I can say that I am excited to see the future of Honeyydo. <|>- Sherri Lindsey - GoldSher Properties"
    ];
    // Original quote since I removed the dummy test one above
    // const quotes = ["We partnered with Honeyydo  to enable agents to deliver more value to clients. We love the chance to be able to brand the app, provide it to the client and the ability for it to drive more long term client retention. <|>- JPAR, The Sears Group",
    //     "Some nice text about honeyydo. They are really great. <|>- Someone"
    // ];



    return (

        <>

            <div style={{ backgroundColor: 'black', height: '90vh' }} className='display-flex justify-content-center  height-full-vh text-center position-relative background-color-grey'  >

                <img className='width-full-pt height-full-pt opacity-70 object-fit-cover' src={bannerImg} alt="Honeyydo landscape banner image" />

            </div>

            <div className='Banner-Div display-flex flex-direction-column align-items-center' >
                <h1 className="text-shadow  white  heading" >We help real estate professionals nail post transaction communications</h1>
                <p className="text-med text-shadow text-align-center  white bold text-spaced " >The gift that keeps on giving... back to you! </p>
                {/* <p className="text text-shadow text-align-center position-absolute white Hero-Text " >Get more referrals, online reviews, social media content with Honeyydo's easy to use homeowner suite. </p> */}
                <div className='hero-button-box display-flex column-gap-15-px row-gap-15-px '>
                    <Link to={'/pricing'}>
                        <button className='hero-button text-shadow'>
                            Get Started as an Agent</button>
                    </Link>
                    {/* <Link to={'/pro-info'}>
                    <button  className='hero-button text-shadow'>
                    Get Started as a Pro</button>
                </Link> */}
                </div>
            </div>



            <div className="  background-white width-full-pt " >
                <div className='wrap-1'>
                    <h2 className='grey heading margin-landing-custom'>Where your own clients become advocates</h2>
                    <p className='color-med-grey text-center padding-0-5-pt text-smaller bold'>Clients are your best sources of new business.  We help turn them into your biggest advocates and maximize the amount of referrals you get.

                        <br /><br />The Honeyydo platform helps you turn clients into advocates and get all of these benefits:</p>

                    <div className='First-Icons-Container display-flex justify-content-center margin-auto-lr flex-wrap align-self-start margin-landing-custom' >


                        {
                            firstContent.map((list, index) =>

                                <div key={index} className='flex-column text-center height-full-pt justify-content-center landing-first-icons'>
                                    <FontAwesomeIcon className='logo-orange fa-4x margin-auto-lr' icon={list[0]} />
                                    <h6 className='color-med-grey subheading bold padding-vertical-10-pt'>{list[1]}</h6>

                                </div>
                            )
                        }


                    </div>
                </div>

            </div>

            {/* <div className='Reviews  flex-center flex-column justify-content-center background-color-light-blue' >
                <h2 className='padding-bottom-5-pt grey'>Online Reviews</h2>

                <p className=' text text-center grey' >We get real estate professionals more online reviews on Google, Zillow, and Facebook.</p>

            </div> */}

            <div className="landing-two-box-blue" >
                <div  >
                    <img className='Half-Img ' src={reviewsImg} alt="Person writing reviews on smartphone" />
                </div>

                <div className='landing-text-box reviews-text' >
                    <h2 className='padding-bottom-5-pt grey text-align-center'>Online Reviews</h2>
                    <p className=' padding-0-5-pt text-center text-smaller color-med-grey bold' >We get real estate professionals more online reviews on Google, Zillow, and Facebook.</p>


                </div>


            </div>




            <div className='margin-3-pt'>
                <div className='ref-div max-width-landing'>
                    <div className='display-flex flex-wrap Ref-Wrap'>
                        <div className='ref-text '>
                            <h2 className='padding-bottom-5-pt grey '>More Referrals</h2>
                            <p className='padding-0-5-pt text-smaller  color-med-grey text-align-left bold'>
                                Honeyydo users use our platform 3-5 times a month.

                                <br /><br />Our unique "invite your friend" setup produces more leads and referrals for you as their agent, enhancing your organic growth as their go-to agent.
                            </p>
                        </div>
                        <img className="landing-graphic" src={referralImg} />
                    </div>
                </div>


            </div>
            <div className="landing-two-box" >

                <div className='landing-text-box' >
                    <h2 className='padding-bottom-5-pt white text-align-center'>Blog Content</h2>
                    <p className='padding-0-5-pt white text-center text-smaller bold'>We do all the boring marketing stuff.  Honeyydo's "done for you" blogs
                        share insightful tips on latest real estate trends. This helps you stay on the top of your client's mind.
                    </p>
                    <Link className="text-decoration-none" to={"blogs"}><div className={`landing-button ${path === "/blogs" ? "Selected-Header-Button" : ""}`}>Check out our blogs</div></Link>


                </div>
                <div  >
                    <img className='Half-Img ' src={blogImg} alt="Woman on laptop" />
                </div>

            </div>

            {/* <div className='ref-div '>
                <div className='display-flex flex-wrap News-Wrap'>
                    <img className="landing-graphic" src={newslettersImg} />
                    <div className=' news-text'>
                        <h2 className='padding-bottom-5-pt grey '>Branded Social Content</h2>
                        <p className='padding-0-5-pt bold color-med-grey text-align-left text-smaller'>
                            Our automated and personalized newsletters make it simple to stay top of mind with your clients, and our custom e-card tool is a great way to send thoughtful and unique greetings.

                        </p>
                    </div>
                </div>



            </div> */}
            <div className='social-content-grid max-width-landing margin-auto-lr'>
                <div className=' display-flex flex-direction-column background1-transparent border-radius-10-px span-two-rows span-two-columns padding-50-px'>
                    <h2 className='padding-bottom-5-pt grey '>Branded Content</h2>

                    <p className=' text-smaller  color-med-grey text-align-left bold'>
                        We fully brand your client's homeowner portal with your image and contact information&#8212;putting you front and center every time your clients and their referrals log in.
                        <br /><br />
                        Additionally, all marketing material we send on your behalf will be branded as well.

                    </p>
                    <div className='overflow-hidden branding-img' >
                        <img src={professionalImg} className='object-fit-cover height-width-100-pt' />
                    </div>



                </div>
                <div style={{ padding: '30px 20px' }} className='background-grey-gradient border-radius-10-px display-flex align-items-center justify-content-center'>
                    <img className="align-items-center newsletter-img" src={newslettersImg} />


                </div>
                <div className='background-grey-gradient-reverse border-radius-10-px  padding-50-px'>
                    <h3 className=' grey padding-bottom-20-px'>Personalized Newsletters</h3>
                    <p className=' text-smaller  color-med-grey text-align-left bold'>
                        We send out a fully-branded, homeowner-targetted newsletter to all your clients twice a month&#8212;fully automated to make staying top of mind as convenient as possible.
                    </p>
                    {/* <img className="landing-graphic" src={newslettersImg} /> */}

                </div>
                <div className='background-grey-gradient overflow-hidden border-radius-10-px '>
                    <img className='object-fit-cover height-width-100-pt ' src={phoneImg} />
                </div>
                <div className='background-grey-gradient border-radius-10-px  ecard-div padding-50-px'>
                    <h3 className=' grey padding-bottom-20-px'>Personalized Ecards</h3>
                    <p style={{ maxWidth: '800px' }} className=' text-smaller  color-med-grey text-align-left bold'>
                        Make your client's day by sending them a personalized ecard to mark a special occasion. Use one of our templates and customize it with your logo, or get creative and create your own.
                        <br /><br />
                        Ecards can also be scheduled to send at a later time, making organizing your follow-ups as easy as possible.
                    </p>

                </div>
            </div>


            <div className=' background-white' >

                <div className="Second-Landing-Container flex-column width-full-pt ">
                    <h2 className='grey heading margin-landing-custom'>So how does this work for my clients?</h2>
                    <h5 className='color-med-grey text-med padding-0-5-pt text-align-center bold margin-bottom-20-px'>You gift them a Honeyydo account which contains everything they'll need to manage their home:</h5>
                    <div className='Second-Icons-Container  margin-auto-lr ' >


                        {
                            secondContent.map((list, index) =>

                                <div key={index} className='Landing-sub-grid last-span-two position-relative'>
                                    <FontAwesomeIcon className='logo-orange fa-4x margin-auto-lr' icon={list[0]} />
                                    <h6 className=' grey subheading bold text-smaller'>{list[1]}</h6>
                                    <p className="padding-0-5-pt color-med-grey text-smallest bold ">{list[2]} {index === 3 ? <span style={{ fontSize: '.8em', position: 'absolute', bottom: '10px', left: '10%', fontWeight: '400' }} className='color-med-grey'><br /><br />*available for our users in the United States</span> : ''}</p>

                                </div>
                            )
                        }


                    </div>

                </div>

            </div>
            <h2 className=' grey heading padding-0-5-pt'>Want more info? Check out our demo:</h2>
            <div className='video-iframe' >
                {/* <LiteYouTubeEmbed
                    id="q_2ll64pQjw&t"
                /> */}
                <iframe allowFullScreen="true" loading='lazy' width="100%" height="100%" src="https://www.youtube.com/embed/q_2ll64pQjw?si=RNk_yj30EqYW-Tiz&rel=0" title="Honeyydo Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" referrerpolicy="strict-origin-when-cross-origin"></iframe>
            </div>


            <Swiper
                autoplay={{
                    delay: 9000,
                    disableOnInteraction: false,
                }}
                modules={[EffectFade, Autoplay]}
                effect='fade'
                fadeEffect={{ crossFade: true }}
                className='background-color-light-blue'

            >

                {quotes.map((quote, index) =>
                    <SwiperSlide key={index}>
                        <div className='Carousel-Slide' >
                            <h2 className='grey heading-font padding-sides-10-pt transition'>{quote.split('<|>')[0]}<br /><br />{quote.split('<|>')[1]}</h2>
                        </div>

                    </SwiperSlide>

                )}


            </Swiper>


            <div className='Swiper-Container width-full-pt'>
                <h2 className='heading grey padding-top-5-vh padding-x-3-pt'>Some of the people we love</h2>


                <div className='Swiper-Wrapper' >
                    <Swiper
                        slidesPerView={1}
                        // spaceBetween={50}
                        pagination={{
                            clickable: true,
                        }}
                        loop={true}
                        loopPreventsSliding={true}
                        loopedSlides={brandLogos.length - 3}
                        autoplay={{
                            delay: 9000,
                            disableOnInteraction: true,
                        }}
                        modules={[Pagination, Autoplay]}
                        breakpoints={{
                            375: {
                                spaceBetween: 50,
                                slidesPerView: 2
                            },
                            550: {
                                spaceBetween: 75,
                                slidesPerView: 2
                            },
                            750: {
                                spaceBetween: 75,
                                slidesPerView: 3
                            },
                            1200: {
                                spaceBetween: 150,
                                slidesPerView: 3

                            },
                            1600: {
                                spaceBetween: 250,
                                slidesPerView: 3

                            }
                        }}
                    >

                        {
                            brandLogos.map((logo, index) =>
                                <SwiperSlide key={index} >
                                    <div className='swipe-slide' >
                                        <img src={logo} style={{ width: '100%' }} />
                                    </div>
                                </SwiperSlide>


                            )
                        }

                    </Swiper>
                </div>


            </div>
            <div className='margin-3-pt'>
                <div className='ref-div display-flex align-items-center justify-content-center security-message'>
                    <FontAwesomeIcon icon={faShieldHalved} className='grey fa-5x' />
                    <div>
                    <h3 className=' grey padding-bottom-20-px'>We Take Your Security Seriously</h3>
                    <p style={{ maxWidth: '800px' }} className=' text-smaller color-med-grey text-align-left bold'>
                        We value you trusting us with your information. Honeyydo will never sell or share your data with anyone, and we take every measure to keep your data as secure as possible online.
                    </p>
                    <span className='grey'>Read our privacy policy <Link to={'privacy-policy'}>here</Link></span>

                    </div>

                </div>
            </div>

        </>


    );
}


export default NewLanding;
