import React, { useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faCalendar, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import ImportButton from "../../utility.component/importButton.component/importButton.component";
import "./subHeader.component.css";

import { AiOutlineSearch } from "react-icons/ai";

import useKeypress from "react-use-keypress";
import { useState } from "react";

import { FiMapPin } from "react-icons/fi";
import { UserContext } from "../../../App";
import { useContext } from "react";

export default function SubHeader({
  pageName,
  addbutton,
  buttonName,
  showOverdue,
  overDueButton,
  showToday,
  todayButton,
  showSearchBar,
  onSearchBarChange,
  searchBarValue,
  searchBarPlaceHolder,
  onSearch,
  sortValues,
  radioValue,
  sortedCol,
  fullWidth,
  showRangeSlider,
  rangeValue,
  setRangeValue,
  maxRangeValue,
  importLabel,
  openImportMenu,
  importType,
  importMenu,
  setImportMenu,
  onImport,
  addHomeImporvebutton
}) {
  const print = () => window.print();
  const [windowSize, setWindowSize] = React.useState(window.innerWidth);

  const user = useContext(UserContext);

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  const onWindowResize = () => {
    setWindowSize(window.innerWidth);
  };

  useKeypress("Enter", () => {
    // Do something when the user has pressed the Escape key
    onSearch();
  });



  return (
    <div className={`subheader ${fullWidth ? 'subheader-full-width' : ''}`}>
      {windowSize > 850 ? (
        <>
          {" "}
          {/*desktop view */}
          <h2 className="page-name grey">{pageName}</h2>
          <div className="subheader-right">
            {sortValues ? <>
              <div className="Sort-Radio display-flex justify-content-center">
                <span>Sort by:</span>
                {
                  sortValues.map((value, index) =>
                    <>
                      <input type='radio' name='sort' checked={radioValue === value[0]} onClick={value[1]} value={value[0]}></input>
                      <label>{value[0]}</label>
                    </>
                  )

                }
                <FontAwesomeIcon
                  className={
                    sortedCol.match('desc')
                      ? "orange fa-lg"
                      : sortedCol.match('asc')
                        ? "orange fa-lg fa-flip-vertical"
                        : "visibility-hidden"
                  }
                  icon={faArrowDown}
                />

              </div></>
              : null
            }
            {showRangeSlider ?
              <div className="margin-right-10-px display-flex flex-direction-column flex-wrap align-items-center justify-content-center column-gap-5-px row-gap-5-px">
                <span> {rangeValue}{user?.country === "Canada" ? "km" : "mi"} <FiMapPin /></span>
                <input type="range" min="0" max={maxRangeValue} value={rangeValue} className="slider" onChange={(e) => { setRangeValue(e.target.value) }} />

              </div>
              : null}
            {showSearchBar ? (
              <div className="Search-Bar-Focus position-relative">
                <span
                  className="position-absolute left-8-px top-4-px purple hover-blue cursor-pointer border-radius-5-px"
                  onClick={() => {
                    onSearch();
                  }}
                >
                  <AiOutlineSearch />
                </span>
                <input
                  className="Search-Bar"
                  type="text"
                  onChange={onSearchBarChange}
                  value={searchBarValue}
                  placeholder={searchBarPlaceHolder}
                />
              </div>
            ) : null}
            {showToday ? (
              <div className="cursor-pointer" onClick={todayButton}>
                <FontAwesomeIcon
                  icon={faCalendar}
                  className="today-icon"
                />
                <span>Today</span>
              </div>
            ) : null}
            {showOverdue ? (
              <div className="cursor-pointer" onClick={overDueButton}>
                <FontAwesomeIcon
                  icon={faClock}
                  className="clock-icon"
                />
                <span>Show Overdue</span>
              </div>
            ) : null}
            {addbutton ? (
              <div className="cursor-pointer" onClick={addbutton}>
                <FontAwesomeIcon
                  icon={faPlus}
                  className="plus-icon"
                />
                <span>{buttonName}</span>
              </div>
            ) : null}
            {addHomeImporvebutton ? (
              <div className="cursor-pointer" onClick={addHomeImporvebutton}>
                <FontAwesomeIcon
                  icon={faPlus}
                  className="plus-icon"
                />
                <span>Home Improvement</span>
              </div>
            ) : null}
            {importLabel ?
              <div className="flex-grow-3">
                <ImportButton importButtonStyle={"Tasklist-Button height-40-px "} buttonText={importLabel} importType={importType} importMenu={importMenu} setImportMenu={setImportMenu} openImportMenu={openImportMenu} onImport={onImport} />
              </div>
              : null
            }
            <div className="cursor-pointer" onClick={print}>
              <FontAwesomeIcon
                icon={faPrint}
                className="print-icon"
              />
              <span>Print</span>
            </div>

          </div>
        </>
      ) : (
        <>
          {" "}
          {/*mobile view */}
          {/* <h2 className="page-name">{pageName}</h2> */}
          <div className="subheader-right ">
            {showSearchBar ? (
              <>
                <div className="Search-Bar-Focus position-relative">
                  <span
                    className="position-absolute left-8-px top-4-px purple hover-blue cursor-pointer border-radius-5-px"
                    onClick={() => {
                      onSearch();
                    }}
                  >
                    <AiOutlineSearch />
                  </span>
                  <input
                    className="Search-Bar"
                    type="text"
                    onChange={onSearchBarChange}
                    value={searchBarValue}
                    placeholder={searchBarPlaceHolder}
                    style={showRangeSlider && windowSize < 400 ? { width: '175px' } : null}

                  />
                </div>
                {showRangeSlider ?
                  <div className="margin-right-10-px display-flex flex-direction-column flex-wrap align-items-center justify-content-center column-gap-5-px row-gap-5-px">
                    <span> {rangeValue}mi <FiMapPin /></span>
                    <input type="range" min="0" max={maxRangeValue} value={rangeValue} className="slider" onChange={(e) => { setRangeValue(e.target.value) }} />

                  </div>
                  : null}
              </>

            ) : null}

          </div>

          <div className={windowSize > 500 ? "float-right" : ""}>
            {addbutton ? (
              <div className="cursor-pointer" onClick={addbutton}>
                <FontAwesomeIcon
                  icon={faPlus}
                  className="plus-icon"
                />
                <span>{buttonName}</span>
              </div>
            ) : null}


            {/* I commented this out because it wasn't fully implemented and 
            it would probably be better to separate adding from filtering functions 
            rather than having them in the same dropdown */}

            {/* <select
                className="mobile-dropdown"
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === "today") {
                    // Execute logic for Today
                  } else if (value === "overdue") {
                    // Execute logic for Show Overdue
                  } else if (value === "addbutton") {
                    addbutton(); // This seems to be the function you'd like to run for "Add task"
                  }
                }}
              >
                {showToday ? <option value="today">Today</option> : null}
                {showOverdue ? (
                  <option value="overdue">Show Overdue</option>
                ) : null}
                {addbutton ? (
                  <option value="addbutton">{buttonName}</option>
                ) : null}
              </select> */}
            {/* <select className="mobile-dropdown">
                {showToday ? <option value="today">Today</option> : null}
                {showOverdue ? (
                  <option value="overdue">Show Overdue</option>
                ) : null}
                {addbutton ? (
                  <option value="addbutton">{buttonName}</option>
                ) : null}
              </select> */}

            <FontAwesomeIcon
              icon={faPrint}
              className="print-icon"
              onClick={print}
            />


          </div>
        </>
      )}
    </div>
  );
}
