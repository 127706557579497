import React, { useState, useEffect, useRef } from "react";
import useFetch from "../../utility.component/useFetch";
import serverConstants from "../../../constants/serverConstants";

import Cookies from "universal-cookie";
import { useContext } from "react";
import { UserContext } from "../../../App";

import { Navigate, useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";

import { ImCheckmark } from "react-icons/im"

import sqlService from "../../../services/sqlService";



import Spinner from 'react-bootstrap/Spinner';

import serverResponseMessagesConstants from "../../../constants/serverResponseMessagesConstants";

import PopupMessage from "../../utility.component/popupMessage.component/popupMessage.component";
import inputConstants from "../../../constants/inputConstants";

import signupConstants from "../../../constants/signupConstants";
import userUtility from "../../../functions/userUtitily";
import { FcCancel } from "react-icons/fc";
import ReCAPTCHA from "react-google-recaptcha";

function LoginDiv({ children, setResponse, onSubmit, setServerMessage }) {
  const Navigate = useNavigate()
  const [windowSize, setWindowSize] = React.useState(window.innerWidth);


  useEffect(() => {
    window.addEventListener("resize", onWindowResize);


    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);


  const onWindowResize = () => {
    setWindowSize(window.innerWidth);

  };
  const [newTask, setNewTask] = useState({});

  const loaded = useRef(false);

  const [checked, setChecked] = useState({ "email": "false", "password": "false" })
  const [triggerX, setTriggerX] = useState(false);
  const [focused, setFocused] = useState(null);

  function updateNewTask(key, value) {
    if (key === "password") {
      if (validatePassword(value)) {
        let nChecked = { ...checked }
        nChecked["password"] = "true";
        setChecked(nChecked)
      } else {
        setChecked({ ...checked, "password": "false" })
      }
    }
    if (key === "email") {
      if (validateEmail(value)) {
        let nChecked = { ...checked }
        nChecked["email"] = "true";
        setChecked(nChecked)
      } else {
        setChecked({ ...checked, "email": "false" })
      }
    }
    let nTask = { ...newTask }
    nTask[key] = value;
    setNewTask(nTask);
  }

  const login = () => {
    let pass = true;
    for (let key of Object.keys(checked)) {
      if (checked[key] !== "true") {
        pass = false;
      }
    }
    if (pass) {
      const token = captchaRef?.current?.getValue();
      captchaRef?.current?.reset();
      if (!token) {
          setServerMessage({ "message": "Please verify that you are not a robot", "title": "Error" })
          return
      }
      onSubmit(newTask, token)
    } else {
      if (!triggerX) {
        setTriggerX(true);
      }
    }



  }




  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePassword = (password) => {
    return String(password)
      .toLowerCase()
      .match(
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/
      );
  }

  function setResponse(data) {
    const cookie = new Cookies();
    //cookie expires in one month
    //secures means only accessible using https
    //you can also add domain which is the domain of the cookie

    document.cookie = `honeyydo_user_cookie=${JSON.stringify({ data })}; expires=${new Date(Date.now() + (30 * 86400000))}; path=/; sameSite=strict; secure`;
    //3 mintues document.cookie = `honeyydo_user_cookie=${JSON.stringify({ data })}; expires=${new Date(Date.now() + (30 * 605000))}; path=/; sameSite=strict; secure`;
    window.location.reload();
  }

  const captchaRef = useRef(null)
  const [loadedCaptcha, setLoadedCaptcha] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoadedCaptcha(true);
    }, 1500);
  })
  return (

    <div className="Signup-Border align-items-center display-flex flex-direction-column ">

      <div className="Signup-Container padding-20-px  column-gap-30-px">
        <div className="margin-bottom-40-px  display-flex flex-wrap flex-direction-column justify-content-center  column-gap-15-px row-gap-30-px">

          <div className=" display-flex flex-direction-row margin-bottom-10-px flex-wrap position-relative">
            <span className="Signup-Label background-linear-gradient padding-5-px  white">Email*</span>
            <input className={`Signup-Input-Style flex-grow-1 ${(triggerX && checked?.email !== "true") ? "Input-Error-Border" : ""}`} type="email" value={newTask?.email ?? ""} onChange={(e) => { updateNewTask("email", e.currentTarget.value); }} />
            {checked?.email === "true" ?
              <span className="Checkmark"><ImCheckmark /></span>
              : null}
            {(triggerX && checked?.email !== "true") ?

              <span className="Finish-Check1"><FcCancel /></span>
              : null}
          </div>



          <div className=" display-flex flex-direction-row margin-bottom-10-px flex-wrap position-relative">
            <span className="Signup-Label background-linear-gradient padding-5-px white">Password*</span>
            <input onFocus={() => { setFocused(`*Minimum ${inputConstants.PASSWORD_MINIMUM_LENGTH} characters, including a special character and number `) }} className={`Signup-Input-Style flex-grow-1 ${(triggerX && checked?.password !== "true") ? "Input-Error-Border" : ""}`} type="password" onChange={(e) => { updateNewTask("password", e.currentTarget.value); }} pattern="/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/" />
            {checked?.password === "true" ?
              <span className="Checkmark"><ImCheckmark /></span>
              : null}
            {(triggerX && checked?.password !== "true") ?

              <span className="Finish-Check1"><FcCancel /></span>
              : null}
          </div>
          <div className='Hint-Div' >
            <span className="Signup-Hint padding-x-10-px align-items-center display-flex justify-content-center grey">{focused}</span>
          </div>

        </div>




      </div>

      {loadedCaptcha &&
        <ReCAPTCHA
          style={{ display: "inline-block" }}
          theme="light"
          sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY_V2}
          ref={captchaRef}
          onChange={null}
          asyncScriptOnLoad={null}
        />
      }
      <button style={{ width: windowSize > 499 ? '350px' : '200px', height: '40px', padding: '0' }} className="submit margin-bottom-10-px" type="submit" onClick={() => { login() }}>Login</button>
      <span onClick={() => { Navigate("/password-reset-request") }} className="margin-bottom-30-px cursor-pointer">Forgot Password?</span>
      <div className="display-flex position-relative margin-bottom-30-px" style={{ height: '20px' }}>
        <div>
          <div className="separator-div" />
        </div>
        <span className="Or-Span" >or</span>
        <div className="separator-div"></div>
      </div>

      {children}


    </div>


  );
}



function GoogleDiv({ user, handleGoogle, verified, error, loading, serverMessage, setServerMessage }) {

  const [windowSize, setWindowSize] = React.useState(window.innerWidth);


  useEffect(() => {
    window.addEventListener("resize", onWindowResize);


    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);


  const onWindowResize = () => {
    setWindowSize(window.innerWidth);

  };

  useEffect(() => {
    /* global google */
    if (window.google) {
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogle,
      });

      google.accounts.id.renderButton(document.getElementById("loginDiv"), { theme: 'outline', text: "signin_with", width: windowSize > 499 ? 350 : 200, height: 50 });

      // google.accounts.id.prompt()
    }


  }, [handleGoogle]);

  return (
    <div className="align-items-center display-flex flex-direction-column height-100-px ">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >

        {loading ? <div>Loading....</div> : <div id="loginDiv"></div>}
        {error && <p style={{ padding: "2px", margin: '20px', backgroundColor: "red", height: "fit-content" }} >{serverResponseMessagesConstants[error] ? serverResponseMessagesConstants[error].message : "Error"}</p>}
      </div>
    </div>
  );
}


export default function Login({ setUser }) {
  const user = useContext(UserContext)

  const [serverMessage, setServerMessage] = useState(null);
  const [requested, setRequested] = useState(false);

  function setResponse(data) {
    //cookie expires in one month
    //secures means only accessible using https
    //you can also add domain which is the domain of the cookie
    if (data?.user) {
      data = data?.user;
    }
    document.cookie = `honeyydo_user_cookie=${JSON.stringify({ data })}; expires=${new Date(Date.now() + (30 * 86400000))}; path=/; sameSite=strict; secure`;
    window.location.reload();
  }

  const setError = (err) => {
    setServerMessage(serverResponseMessagesConstants[err ?? "SOMETHING_WENT_WRONG"]);
  }


  const { handleGoogle, loading, error } = useFetch(
    setError, `${serverConstants.baseURL}/auth/loginUsingGoogle`, setResponse
  );

  const loaded = useRef(false);

  const Navigate = useNavigate()

  useEffect(() => {
    if (!loaded.current) {
      if (user) {
        let userRoute = userUtility.getUserRoute(user)
        let currentPath = window.location.pathname;
        if (!currentPath.endsWith(userRoute)) {
          Navigate(userRoute);
        }
      }

      loaded.current = true;
    }

  }, [user])


  const [verified, setVerified] = useState(user !== null);


  function onSubmit(input, token) {
    setRequested(true);
    // window.grecaptcha.ready(function () {
    //   window.grecaptcha.execute(process.env.REACT_APP_CAPTCHA_SITE_KEY, { action: 'submit' }).then(function (token) {
    //     // Add your logic to submit to your backend server here.

    //   });
    // });
    sqlService.login({ "input": input, "captchaTokenV2": token }).then(data => {
      // the data contains the score for captcha if the score is less than 0.5 then we ask for email verification
      // also contains the user informations


      console.log(data.user);
      // debugger;
      let u = { ...data.user }
      setRequested(false);
      setResponse(u)
    }).catch(err => {
      console.log(err)
      setRequested(false);

    })
  }

  const onConfirmServerMessage = () => {
    setServerMessage(null);
  }


  return (
    <>
      <div className="Signup-Spacing-Div" />
      <div className="Signup-Background" />
      <div className="margin-top-30-px">

        <LoginDiv verified={verified} setVerified={setVerified} setResponse={setResponse} onSubmit={onSubmit} setServerMessage={setServerMessage} >
          <GoogleDiv handleGoogle={handleGoogle} verified={verified} error={error} loading={loading} />
        </LoginDiv>
        <PopupMessage onConfirm={onConfirmServerMessage} message={serverMessage?.message} title={"Error"} show={serverMessage !== null} />
        {requested ? <div className="display-flex align-items-center justify-content-center"><Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner></div> : null}
      </div>
    </>
  )
}