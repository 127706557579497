import React, { useRef } from "react";
import "./poweredby.component.css";
import Placeholder from "../../../assets/images/Placeholder-removebg.png";
import sqlService from "../../../services/sqlService";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { SiZillow } from "react-icons/si";
import {
  faFacebook,
  faInstagram,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";

import { useContext } from "react";
import { UserContext } from "../../../App";

export default function PoweredBy() {
  const user = useContext(UserContext);
  const [agents, setAgents] = useState("");
  const [socials, setSocials] = useState([])
  const socialIcons = { "facebook": faFacebook, "instagram": faInstagram, "google": faGoogle }


  const loaded = useRef(false)

  useEffect(() => {
    if (!user) { return }
    if (!loaded.current) {
      sqlService.getAgentsPB({}).then(data => {
        setAgents(data.data[0]);
        setSocials(data.data)
      }).catch(err => {
        console.log(err)
      })
      loaded.current = true;
    }

  }, []);

  if (!agents) return "Loading...";

  return (
    <>
      {user?.solo_subscribed === 0 ?
        <div className="Powered-by-title">
          <h1 className="Powered-by-title-text">Powered By:</h1>
          <div className="info-socials-container">
            <div className="Agent-details-container">
              <div className="agent-img-container">
                <img className="AgentImg" src={agents?.img_link && agents?.img_link !== '' ? agents.img_link : Placeholder} alt="agent" />

              </div>
              <div className="Agent-Details">
                <span className="Agent-Name">{agents?.contact_name}</span>
                <span className="Sales-Rep-Style">{agents?.title}</span>
                <div>
                  {agents?.brokerage_logo_link && agents?.brokerage_logo_link !== '' ?
                    <img className="BrokerageImg" src={agents?.brokerage_logo_link && agents?.brokerage_logo_link !== '' ? agents.brokerage_logo_link : Placeholder} alt="brokerage" />
                    : null}
                  <span className="brokerage-name">{agents?.brokerage_name}</span>
                </div>
                <span className="agent-number">
                  {" "}
                  <FontAwesomeIcon icon={faPhone} className="faPhone" />
                  {agents?.phone_number}
                </span>
                <span className="agent-number">
                  {" "}
                  <FontAwesomeIcon icon={faEnvelope} className="faPhone" />
                  {agents?.email}
                </span>
              </div>
            </div>
            <div className="socials-container">
              <div className="social-icons">
                {socials.map((link) =>
                  socialIcons[link.type_name] || link.type_name === 'zillow' ?
                    <a href={`https://${link.link.replace(/^https?:\/\//, '')}`} className="active" >
                      {link.type_name === 'zillow' ?
                        <SiZillow />
                        : <FontAwesomeIcon icon={socialIcons[link.type_name]} />
                      }

                    </a>
                    : null
                )}
                {agents?.website ?
                  <a href={`https://${agents.website.replace(/^https?:\/\//, '')}`} className="active" >
                    <FontAwesomeIcon icon={faGlobe} />
                  </a>
                  : null}
                {/* <a href={agents.website} className={agents.website ? 'active' : 'disabled'}>
              <FontAwesomeIcon icon={faGlobe} />
            </a>
            <a href={agents.facebook_link} className={agents.facebook_link ? 'active' : 'disabled'}>
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href={agents.instagram_link} className={agents.instagram_link ? 'active' : 'disabled'}>
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href={agents.google_link} className={agents.google_link ? 'active' : 'disabled'}>
              <FontAwesomeIcon icon={faGoogle} />
            </a>
            <a href={agents.zillow_link} className={agents.zillow_link ? 'active' : 'disabled'}>
              <SiZillow />
            </a> */}
              </div>

            </div>
          </div>
        </div>
        : null}
    </>
  );
}
