import React, { useState, useEffect, useRef } from "react";
import { faArrowRight, faX, faCheck, faStar, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import sqlService from "../../../services/sqlService";

import { UserContext } from "../../../App";
import { useContext } from "react";
import "./notifications.component.css";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { BsBell } from "react-icons/bs";
import { TbBellRingingFilled } from "react-icons/tb"
import notificationsTemplates from "../../../templates/notifications.templates";


function Notifications({ max, openFromBottom }) {
    const user = useContext(UserContext);

    const [notifications, setNotifications] = useState([]);


    // const [readNotifications, setReadNotifications] = useState([]);

    const [currentNotification, setCurrentNotification] = useState({});
    const [notificationModal, setNotificationModal] = useState(false);
    const handleClose = () => { setNotificationModal(false); };
    const [refresh, setRefresh] = useState(false);
    const notificationsLoaded = useRef(false);
    const maxNum = useRef(0)
    const [additional, setAdditional] = useState(false)
    const [windowSize, setWindowSize] = React.useState(window.innerWidth);
    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)
    const [element, setElement] = useState(null)
    const [fromAdditional, setFromAdditional] = useState(false)

    const minSwipeDistance = 150

    const onTouchStart = (e, id) => {
        setTouchEnd(null)
        setTouchStart(e.targetTouches[0].clientX)
        setElement(document.getElementById(`notification-div-${id}`))

    }

    const onTouchMove = (e) => {

        setTouchEnd(e.targetTouches[0].clientX)
        if (touchEnd && (touchStart - touchEnd > 5)) {

            element.style.transform = `translateX(-${150}px`
        }

    }

    const onTouchEnd = (id) => {

        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd

        if (distance > minSwipeDistance) {
            handleDeleteNotification(id)

        } else {
            element.style.transform = 'unset'
        }

    }

    useEffect(() => {
        window.addEventListener('resize', onWindowResize);

        return () => {
            window.removeEventListener('resize', onWindowResize);
        };
    }, []);


    const onWindowResize = () => {
        setWindowSize(window.innerWidth);
    };


    useEffect(() => {
        if (!user) {
            return
        }
        if (!notificationsLoaded.current) {
            sqlService.getUserNotifications({})
                .then((data) => {
                    const notifications = data.data.reverse();
                    let sorted = sortNotifications(notifications)
                    setNotifications(sorted);
                    maxNum.current = max ?? sorted.length;



                })
                .catch((err) => {
                    console.log(err);
                });
            notificationsLoaded.current = true;
        }
        // sqlService
        //     .getUserById({ user: user })
        //     .then((data) => {
        //         const agent = data.data;
        //         const read = agent.read_notifications;
        //         if (read) {
        //             setReadNotifications(read.split('-'));
        //         }
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });
    }, [refresh])



    // useEffect(() => {

    //     if (notifications.length > 0) {
    //         sortNotifications();
    //     }

    // }, [readNotifications])



    function readNewNotification(notificationId) {
        sqlService
            .updateUserNotificationToRead({
                "user": user,
                input: { "notification_id": notificationId }
            })
            .then((data) => {
                if (windowSize > 700) {
                    notificationsLoaded.current = false
                    setRefresh(!refresh);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function sortNotifications(notificationArray) {

        var unread = []
        var seen = []

        for (let i = 0; i < notificationArray.length; i++) {
            if (notificationArray[i].is_read) {
                seen.push(notificationArray[i])
            } else {
                unread.push(notificationArray[i])
            }
        }

        const sorted = unread.concat(seen)

        // console.log(sorted)

        return sorted;

    }

    function handleDeleteNotification(notificationId) {
       
        sqlService
            .deleteUserNotification({
                "user": user,
                input: { "notification_id": notificationId }
            })
            .then((data) => {
                notificationsLoaded.current = false
                setRefresh(!refresh);
                setNotificationModal(false)
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div className="display-flex flex-direction-column flex-wrap">

            <h5 className='grey text-align-center padding-bottom-20-px'>Notifications <span><BsBell /></span></h5>
            <div className={"Dashboard-Container-Notification"}>
                {additional && windowSize > 700 ?
                    <div style={{bottom: openFromBottom ? '20px' : '100%'}} className="additional-notifications">
                        <div style={{maxHeight: openFromBottom ? '100vh' : '50vh'}} className="additional-notifications-inner">
                            {
                                notifications.slice(maxNum.current, notifications?.length).map((notification) => {
                                    return (
                                        <div key={notification.id} className={
                                            notification.is_read ?
                                                "Notification-Container Read cursor-pointer diffuse-shadow"
                                                : "Notification-Container cursor-pointer diffuse-shadow"
                                        }
                                            onClick={() => {
                                                setCurrentNotification(notification);
                                                setNotificationModal(notification.id);
                                                setFromAdditional(true)
                                                if (!notification.is_read) {
                                                    readNewNotification(notification.id);
                                                }
                                                setAdditional(false)
                                            }}>
                                                

                                            <div className="display-flex flex-direction-column aling-item-flex-start row-gap-5-px position-relative">
                                                <span key={notification.id + "title-preview"} className="bold width-fit-content">{notification.title}</span>
                                                <span key={notification.id + "template-preview"} className="width-fit-content">{notificationsTemplates[notification.content] ? notificationsTemplates[notification.content].preview : notification.content?.replace(/(<([^>]+)>)/gi, '')?.split(' ')?.splice(0, 20)?.join(' ')} {notification.content?.split(' ').length > 20 ? ' ...' : ''} </span>

                                            </div>

                                            {notification.is_read ?
                                                <span className="Read-Icon-Notification">
                                                    <FontAwesomeIcon className="grey padding-top-5pt fa-1.5x" icon={faCheck} />
                                                </span>

                                                :

                                                <span className="Read-Icon-Notification">
                                                    <TbBellRingingFilled />
                                                </span>
                                            }


                                        </div>
                                    )
                                })


                            }
                        </div>
                        <button onClick={() => {setAdditional(false); setFromAdditional(false)}} className="Tag-Button align-self-end">Close</button>


                    </div> : null}
                {notificationModal && windowSize > 700 && fromAdditional?
                    <>
            
                        <div style={{bottom: openFromBottom ? '25px' : '100%'}} className=" diffuse-shadow notification-popup">
    
                            <div style={{ alignSelf: 'end' }} className="display-flex column-gap-10-px ">
                                <button onClick={()=>{handleDeleteNotification(notificationModal); setFromAdditional(false); setAdditional(true)}} className="Tag-Button cursor-pointer" >Delete</button>
                                <button onClick={()=>{handleClose(); setFromAdditional(false); setAdditional(true)}} className="Tag-Button cursor-pointer" >Close</button>
                            </div>
                            <div>
                                <span className=" margin-bottom-5-pt bold padding-sides-10-pt font-size-1-5-em ">{currentNotification.title}</span>
                                <p style={{ paddingTop: '20px' }} className="padding-sides-10-pt padding-top-20-px display-block">{notificationsTemplates[currentNotification.content] ? notificationsTemplates[currentNotification.content].normal : currentNotification.content}</p>
                            </div>
                            {currentNotification?.link?.length ?
                                <a style={{ paddingBottom: '30px' }} className="notification-link text-decoration-none white hover-light-blue margin-top-5-pt padding-sides-10-pt padding-bottom-30-px" href={currentNotification.link}>{currentNotification.link_text}
                                    <FontAwesomeIcon className="padding-left-10-px notification-arrow" icon={faArrowRightLong} />
                                </a>
                                : <div style={{ paddingBottom: '30px' }} />
                            }

                        </div>
                    </> : null}
                {notifications.length === 0 ?
                    <div className="Empty-Notification-Container Notification-Container ">
                        {/* <div className="Notification-Container"> */}

                        <span className="bold grey">All caught up</span>
                        <FontAwesomeIcon className="grey fa-lg" icon={faCheck} />
                    </div>

                    // </div>
                    : null
                }
                {
                    notifications.slice(0, additional && windowSize <= 700 ? notifications?.length : maxNum.current).map((notification) => {
                        return (
                            <div id={`notification-div-${notification.id}`} key={notification.id} className={
                                notification.is_read ?
                                    "Notification-Container Read cursor-pointer diffuse-shadow"
                                    : "Notification-Container cursor-pointer diffuse-shadow"
                            }
                                onTouchStart={(e) => onTouchStart(e, notification.id)} onTouchMove={onTouchMove} onTouchEnd={() => onTouchEnd(notification.id)}
                             >
                                    <div onClick={() => {
                                                setCurrentNotification(notification);
                                                setNotificationModal(notification.id);
                                                if (!notification.is_read && windowSize <= 700) {
                                                    readNewNotification(notification.id);
                                                }
                                                if (windowSize > 700) {
                                                setAdditional(false)
                                                }
                                                setFromAdditional(false)
                                            }} style={{position: 'absolute', zIndex: '100', height: '100%', width: '100%' }} />
                                            {notificationModal == notification.id && windowSize > 700 ?
                                                <>
                                                    {/* <div className="backdrop2"></div> */}
                                                    <div className=" diffuse-shadow notification-popup">

                                                        <div style={{ alignSelf: 'end' }} className="display-flex column-gap-10-px ">
                                                            <button onClick={()=>handleDeleteNotification(notification.id)} className="Tag-Button cursor-pointer" >Delete</button>
                                                            <button onClick={()=>{handleClose(); if (!notification.is_read) readNewNotification(notification.id)}} className="Tag-Button cursor-pointer" >Close</button>
                                                        </div>
                                                        <div>
                                                            <span className=" margin-bottom-5-pt bold padding-sides-10-pt font-size-1-5-em ">{currentNotification.title}</span>
                                                            <p style={{ paddingTop: '20px' }} className="padding-sides-10-pt padding-top-20-px display-block">{notificationsTemplates[currentNotification.content] ? notificationsTemplates[currentNotification.content].normal : currentNotification.content}</p>
                                                        </div>
                                                        {currentNotification?.link?.length ?
                                                            <a style={{ paddingBottom: '30px' }} className="notification-link text-decoration-none white hover-light-blue margin-top-5-pt padding-sides-10-pt padding-bottom-30-px" href={currentNotification.link}>{currentNotification.link_text}
                                                                <FontAwesomeIcon className="padding-left-10-px notification-arrow" icon={faArrowRightLong} />
                                                            </a>
                                                            : <div style={{ paddingBottom: '30px' }} />
                                                        }

                                                    </div>
                                                </> : null}

                                <div className="display-flex width-full-pt flex-direction-column aling-item-flex-start row-gap-5-px position-relative">
                                    <span key={notification.id + "title-preview"} className="bold width-fit-content">{notification.title}</span>
                                    {notificationModal === notification.id && windowSize <= 700 ?
                                        <>
                                            <span key={notification.id + "template-preview"} className="width-fit-content">{notificationsTemplates[notification.content] ? notificationsTemplates[notification.content].preview : notification.content} </span>
                                            {notification?.link?.length ?
                                                <a className="notification-link text-decoration-none grey hover-dark-blue margin-top-5-pt padding-sides-10-pt padding-bottom-30-px" href={notification.link}>{notification.link_text}
                                                    <FontAwesomeIcon className="padding-left-10-px notification-arrow" icon={faArrowRightLong} />
                                                </a> : null}

                                            <button onClick={() => handleDeleteNotification(notification.id)} style={{ minWidth: '40px' }} className="Tag-Button align-self-end margin-top-10-px"><FontAwesomeIcon icon={faTrash} /></button>

                                        </>
                                        :

                                        <span key={notification.id + "template-preview"} className="width-fit-content">{notificationsTemplates[notification.content] ? notificationsTemplates[notification.content].preview : notification.content?.replace(/(<([^>]+)>)/gi, '')?.split(' ')?.splice(0, 20)?.join(' ')} {notification.content?.split(' ').length > 20 ? ' ...' : ''} </span>

                                    }
                                </div>

                                {notification.is_read ?
                                    <span className="Read-Icon-Notification">
                                        <FontAwesomeIcon className="grey padding-top-5pt fa-1.5x" icon={faCheck} />
                                    </span>

                                    :

                                    <span className="Read-Icon-Notification">
                                        <TbBellRingingFilled />
                                    </span>
                                }


                            </div>
                        )
                    })

                }
                {(notifications.length > maxNum.current) && (windowSize > 700 || !additional) ?
                    <div onClick={() => { setAdditional(true); setNotificationModal(false) }} className="bold color-med-grey display-flex column-gap-10-px align-items-center cursor-pointer">
                        <span>See older</span>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div> : null}

            </div>



        </div>


    )

}

export default Notifications;