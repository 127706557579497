import React from "react";
import UniButton from "../../utility.component/uniButton";

const AddBrokerageButton = () => <UniButton>Add Brokerage</UniButton>;

const AddAgentButton = () => <UniButton>Add Agent</UniButton>;

const AddMortgageAgentButton = () => <UniButton>Add Mortgage Agent</UniButton>;

const AddInsuranceAgentButton = () => <UniButton>Add Insurance Agent</UniButton>;

const AddProButton = () => <UniButton>Add Pro</UniButton>;

const AddHomeownerButton = () => <UniButton>Add Homeowner</UniButton>;

const AddTaskButton = () => <UniButton>Add Task</UniButton>;

const AddBlogButton = () => <UniButton>Add Blog</UniButton>;

const AddLibraryButton = () => <UniButton>Add Library</UniButton>;

export {
  AddBrokerageButton,
  AddAgentButton,
  AddMortgageAgentButton,
  AddInsuranceAgentButton,
  AddProButton,
  AddHomeownerButton,
  AddTaskButton,
  AddBlogButton,
  AddLibraryButton,
};
