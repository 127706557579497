function getDateTimeToMysqlDatetime(date=new Date()) {
    let ret = date.toISOString().slice(0, 19).replace('T', ' ');
    return ret;
}

function getCurrentDateTimeToMysqlDatetimeAddExtraDays(days) {
    let date = new Date(new Date().getTime()+(days*24*60*60*1000)); 
    let ret = date.toISOString().slice(0, 19).replace('T', ' ');
    return ret;
}

function getTimeFromMysqlDatetime(time){
    // Split timestamp into [ Y, M, D, h, m, s ]
    var t = time.split(/[- : T .]/);
    

    // Apply each element to the Date function
    var d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
    
    return d;
}



function differenceInMinutes(dt2, dt1) 
 {

  var diff =(dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
  
 }

 function differenceInDays(dt2, dt1) 
 {
    console.log(dt2, typeof dt1)
  if(!dt2){
    dt2 = new Date();
  }
  if(typeof dt1 === "string"){
    dt1 = new Date(dt1);
  }
  var diff =(dt2.getTime() - dt1.getTime()) / 1000;
  diff /= (60 * 60 * 24);
  return Math.abs(Math.round(diff));
  
 }

 function getCurrentDateTimeInUtc() 
 {
    const d1 = new Date();
     var d = new Date( d1.getUTCFullYear(), d1.getUTCMonth(), d1.getUTCDate(), d1.getUTCHours(), d1.getUTCMinutes(), d1.getUTCSeconds() );
     d.toUTCString();

    //let d = new Date(Date.UTC(0, 0, 0, 0, 0, 0));

  return d;
  
 }


 function utcOffsetDate(utc_offset, date) {
    let offsetHours = parseInt(utc_offset)/100;
    let d = new Date(date);
    let ret = new Date(d.getTime()+(offsetHours*60*60*1000)); 
    return ret;
 }

 function getTimeFromSeconds(seconds){
    console.log(seconds)
    if(!parseInt(seconds)){
        if(typeof seconds === "string"){
            return getTimeFromMysqlDatetime(seconds);
        }else{
            return seconds;
        }
    }
    console.log("ds")
    let d = new Date(seconds * 1000)
    return d;
 }

 function getTimeFromSecondsToString(seconds){
    if(!parseInt(seconds)){
        if(typeof seconds === "string"){
            return getTimeFromMysqlDatetime(seconds);
        }else{
            return seconds;
        }
    }
    let d = new Date(seconds * 1000)
    return d.toDateString();
 }

const timeUtility = {
    
    getDateTimeToMysqlDatetime,
    getCurrentDateTimeToMysqlDatetimeAddExtraDays,
    getTimeFromMysqlDatetime,
    differenceInMinutes,
    differenceInDays,
    getCurrentDateTimeInUtc,
    utcOffsetDate,
    getTimeFromSeconds,
    getTimeFromSecondsToString
}

export default timeUtility;