import React from "react";
import { useEffect } from "react";
import "./tableFooter.component.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft, faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";

export default function TableFooter({
  currentPage,
  totalPages,
  nextPage,
  prevPage,
  fullWidth,
  mobile
}) {
  const [windowSize, setWindowSize] = React.useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  const onWindowResize = () => {
    setWindowSize(window.innerWidth);
  };


  return (
    <>
      {windowSize > 1000 && !mobile ? (
        <div className={`Uni-Table-Footer ${fullWidth ?  'footer-full-width' : ''}`}>
          <button
            className="Uni-Table-Footer-button"
            id="previous"
            onClick={prevPage}
            // disabled={currentPage === 1}
          >
            Prev
          </button>
          <span className="Uni-Table-Footer-Current-Page">
            {totalPages > 0
              ? `Page ${currentPage} of ${totalPages}`
              : "No Data Available"}
          </span>

          <button
            className="Uni-Table-Footer-button"
            id="next"
            onClick={nextPage}
            // disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      ) : (
        <div className="mobile-table-footer">
           <button
            className="mobile-table-footer-button"
            onClick={prevPage}
            disabled={currentPage === 1}
          >
            <FontAwesomeIcon icon={faCircleArrowLeft} />
          </button>
          <span className="mobile-table-current-page grey">
            {totalPages > 0
              ? `${currentPage} of ${totalPages}`
              : "No Data Available"}
          </span>

          <button
            className="mobile-table-footer-button"
            onClick={nextPage}
            disabled={currentPage === totalPages}
          >
            <FontAwesomeIcon icon={faCircleArrowRight} />
          </button>
        </div>
      )}
    </>
  );
}
