import React, { useState, useEffect, useRef } from "react";
import Spinner from 'react-bootstrap/Spinner';


import { useNavigate } from "react-router-dom";
import sqlService from "../../../services/sqlService";
import serverResponseMessagesConstants from "../../../constants/serverResponseMessagesConstants";
import { useContext } from "react";
import { UserContext } from "../../../App";
import PopupMessage from "../../utility.component/popupMessage.component/popupMessage.component";
import { ImCheckmark } from "react-icons/im"

export default function PasswordResetRequest() {

  const user = useContext(UserContext)
  const [serverMessage, setServerMessage] = useState(null);
  const [windowSize, setWindowSize] = React.useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  const onWindowResize = () => {
    setWindowSize(window.innerWidth);
  };

  const [checked, setChecked] = useState({ "email": "false" })
  const [email, setEmail] = useState("");
  const [emailIsSent, setEmailIsSent] = useState(false);
  const [requested, setRequested] = useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  function updateNewTask(key, value) {
    if (key === "email") {
      if (validateEmail(value)) {
        let nChecked = { ...checked }
        nChecked["email"] = "true";
        setChecked(nChecked)
      } else {
        setChecked({ ...checked, "email": "false" })
      }
    }

    setEmail(value);
  }

  const onConfirmServerMessage = () => {
    setServerMessage(null);
  }

  const resetPassword = () => {
    let pass = true;
    for (let key of Object.keys(checked)) {
      if (checked[key] !== "true") {
        pass = false;
      }
    }
    if (pass) {
      //start reset process
      setRequested(true);
      setEmailIsSent(false);
      sqlService.passwordResetRequest({input: { "email": email } }).then(d => {
        setRequested(false);
        setEmailIsSent(true);
      }).catch(err => {
        console.log(err)
         setRequested(false);
        setEmailIsSent(false);
      })
    }

  }


  return (
    <div className="Signup-Spacing-Div" >
      <div className="Password-Reset-Background" >
        <div className="margin-top-30-px">
          <div className="Signup-Border align-items-center display-flex flex-direction-column ">

            <div className="Signup-Container padding-20-px  column-gap-30-px">
              <div className="margin-bottom-40-px  display-flex flex-wrap flex-direction-column justify-content-center  column-gap-10-px row-gap-10-px">

                <span className="margin-bottom-30-px">Email associated with account*</span>
                <div className=" display-flex flex-direction-row margin-bottom-10-px flex-wrap position-relative">
                  <input className="Input-Style flex-grow-1 border-radius-5-px " type="email" value={email ?? ""} onChange={(e) => { updateNewTask("email", e.currentTarget.value); }} />
                  {checked?.email === "true" ?
                    <span className="Password-Reset-Checkmark "><ImCheckmark /></span>
                    : null}
                </div>
                <button style={{ margin: '10px auto', width: windowSize > 499 ? '350px' : '200px', height: '40px', padding: '0' }} className="submit" type="submit" onClick={() => { resetPassword() }} disabled={requested}>Reset Password</button>
                {requested ? <div className="display-flex align-items-center justify-content-center"><Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner></div> : null}
                {emailIsSent ?
                  <span style={{maxWidth: '350px'}} className="">Please check your email, we have sent you a link to reset your password.</span>
                  : null}
              </div>

            </div>



          </div>
        </div>
      </div>
    </div>
  );
}
