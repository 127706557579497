import inputConstants from "../constants/inputConstants";

const regValidate = (key, value) => {
    switch (key) {
        case ("email"):
            return String(value)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
            break;

        case ("password"):

            return String(value)
                .toLowerCase()
                .match(
                    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,64}$/
                );
            break;

        case ("contact_name"):
            return String(value)
                .toLowerCase()
                .match(
                    /^[^~,]{3,50}$/
                );
            break;

        case ("phone_number"):
            return String(value)
                .toLowerCase()
                .match(
                    /^[0-9]{10,10}$/
                );
            break;

        case ("address"):
            return String(value)
                .toLowerCase()
                .match(
                    /^[A-Za-z0-9'\.\-\s\,]{5,100}$/
                );
            break;

        case ("city"):
            return String(value)
                .toLowerCase()
                .match(
                    /^[A-Za-z'\.\-\s\,]{2,100}$/
                );
            break;

        case ("zip_code"):
            return String(value)
                .toLowerCase()
                .match(
                    /^[A-Za-z0-9 ]{5,11}$/
                );
            break;
        case ("brokerage"):
            return String(value)
                .toLowerCase()
                .match(
                    /^[^~,]{3,199}/
                );
            break;
        case ("brokerage_name"):
            return String(value)
                .toLowerCase()
                .match(
                    /^[^~,]{3,199}/
                );
            break;
        case ("subject"):
            return String(value)
                .toLowerCase()
                .match(
                    /^[^~,]{3,199}/
                );
        case ("message"):
            return String(value)
                .toLowerCase()
                .match(
                    /^[^~,]{3,199}/
                );

    }
    return false;
}


function validFileSize(file) {
    if (file?.size > inputConstants.MAX_FILE_SIZE) {
        return false;
    }
    return true;
}

const validateUtility = {
    regValidate,
    validFileSize
}

export default validateUtility;