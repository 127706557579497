import React, { useRef, useState, useEffect } from "react";
import "./tagsContainer.component.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import ScrollToTop from "../../utility.component/scrollToTop.component.js";
import sqlService from "../../../services/sqlService";


export default function TagsContainer({ tags, onClick, subIndustryFilter, handleProfessionChange, professionFilter }) {
  const containerRef = useRef();
  const [selectedTag, setSelectedTag] = useState("All");

  const handleRightClick = () => {
    containerRef.current.scrollLeft += 200;
  };

  const handleLeftClick = () => {
    containerRef.current.scrollLeft -= 200;
  };

  const handleTagChange = (e) => {
    setSelectedTag(e.target.value);
    onClick(e.target.value);
  };

  const [windowSize, setWindowSize] = React.useState(window.innerWidth);

  const [professions, setProfessions] = useState([]);

  const loaded2 = React.useRef(false);

  useEffect(() => {
    if (!loaded2.current) {
      sqlService
        .getIndustryNames()
        .then((data) => {
          const INDUSTRIES = [];
          for (let i = 0; i < data.data.length; i++) {
            INDUSTRIES[i] = data.data[i].type_name;
          }
          setProfessions(INDUSTRIES);
        })
        .catch((err) => {
          console.log("Didn't get industry names");
          console.log(err);
        });
      loaded2.current = true;
    }
  }, []);


  const [selectedProfession, setSelectedProfession] = useState(professionFilter);


  const handleChange = (event) => {
    setSelectedProfession(event.target.value);
    handleProfessionChange(event.target.value);
    setSelectedTag('All')
  };

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  const onWindowResize = () => {
    setWindowSize(window.innerWidth);
  };

  const [scrollRight, setScrollRight] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)

  const [element, setElement] = useState(null)

useEffect(() => {
  let tags = document.getElementById('Tags');
  setElement(tags)

  setScrollRight(tags?.scrollWidth - tags?.clientWidth - tags?.scrollLeft)

}, [tags])

useEffect(() => {

  if (element) {
      element.addEventListener('scroll', onTagsScroll)

  }

}, [element])

function onTagsScroll() {

  setScrollLeft(element.scrollLeft)
  setScrollRight(element.scrollWidth - element.clientWidth - element.scrollLeft)

}

  return (
    <>
      <div className="Arrows-Container-Tags margin-top-20-px margin-bottom-30-px"> {/*destop view */}
        {/* {windowSize > 850 ? ( */}
        {windowSize > 850 ? (
          <>
          {scrollLeft > 0 ?
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="faChevron faChevronLeft"
              onClick={handleLeftClick}
            />
            : null}
            <div id='Tags' className="sub-tags-container" ref={containerRef}>
              <div className="tags-wrapper">
                {tags.map((tag, index) => (
                  
                  <button
                    className={`sub-tags ${
                      subIndustryFilter === tag ? "selected" : ""
                    }`}
                    key={index}
                    onClick={() => onClick(tag)}
                  >
                    {tag}
                  </button>
          
                ))}
              </div>
            </div>
            {scrollRight > 1 ?
            <FontAwesomeIcon
              icon={faChevronRight}
              className="faChevron faChevronRight"
              onClick={handleRightClick}
            />
            : null}
          </>
        ) : (
          <div className="sub-industry-mobile"> {/*mobile view */}
          <div className="industry-dropdown-mobile-container">
            <p className="dropdown-industries-title">Industry</p>
              <select className="industry-dropdown-mobile" value={selectedProfession} onChange={handleChange}>
                {professions.map((profession, index) => (
                    <option key={index} value={profession}>
                        {profession}
                    </option>
                ))}
            </select>
            </div>
            <div className="sub-industry-dropdown-mobile-container">
              <p className="dropdown-industries-title">Sub-Industry</p>
            <select className="sub-industry-dropdown-mobile" value={selectedTag} onChange={handleTagChange}>
              {tags.map((tag, index) => (
                <option key={index} value={tag}>
                  {tag}
                </option>
              ))}
            </select>
            </div>
            <ScrollToTop />
          </div>
        )}
      </div>
    </>
  );
}
