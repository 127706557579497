// useFetch.jsx
import { useState } from "react";


const useFetch = (setErr, url, setGoogleResponse, ownerType, parent, link, senderId, senderRoleTypeId) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");


    // useFetch.jsx
    // this reponse is coming from the signup or sign in button that is rendered by google as a callback function
  const handleGoogle = async (response) => {
    setLoading(true);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({ credential: response.credential, input: {"role_id": ownerType, "parent": parent, "link": link, "senderId": senderId, "senderRoleTypeId": senderRoleTypeId} }),
    })
      .then((res) => {
        setLoading(false);
        return res.json();
      })
      .then((data) => {
        if(data?.user){
          data = data?.user;
        }
        if(data && data?.role_id){
          setGoogleResponse(data)
        }else{
          setErr(data);
        }
        
        throw new Error(data?.message || data);
      })
      .catch((error) => {
        console.log(error.message);
        setError(error?.message);
      });
  };


  return { loading, error, handleGoogle };
};

export default useFetch;