import React, { useState } from "react";
import '../../../../node_modules/react-vis/dist/style.css';
import { XYPlot, XAxis, YAxis, LineMarkSeries, Hint } from 'react-vis';



export default function LineChart({ lineColors, lineData, maxValue, xLabel }) {
  const [hoveredMark, setHoveredMark] = useState(null);

  return (
    <>
      <XYPlot
        height={200}
        width={250}
        yDomain={[0, Math.round(maxValue * 1.25)]}
        xType='ordinal'
      >
        <XAxis
        />
        <YAxis />
        <LineMarkSeries
          className="display-block margin-auto-lr"
          color={lineColors[0]}
          data={lineData}
          fill={lineColors[1]}
          markStyle={{ stroke: lineColors[1] }}
          size={3}
          curve={'curveMonotoneX'}
          onValueMouseOver={v =>
            setHoveredMark(v)
          }
          onValueMouseOut={v => setHoveredMark(null)}

        />
          
        {hoveredMark ? (
          
          <Hint className='Hint-Hover' value={hoveredMark}>
            <div style={{ height: 'fit-content' }}>

              <span className="white" >{hoveredMark.pointLabel}</span>
            </div>
          </Hint>
     
        ) : null}
      </XYPlot>
      <div className="Chart-Label" >
        <span >{xLabel}</span>
      </div>
    </>
  )
}