import React, { useState, useEffect, useRef, useContext } from "react";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { UserContext } from "../../../App";
import { DiscreteColorLegend } from "react-vis";
import sqlEcardService from "../../../services/sqlEcardService";
import sqlService from "../../../services/sqlService";
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCheck, faEnvelopeCircleCheck, faPaperPlane, faPaperclip, faPlus, faSave, faTrash, faX } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import rrulePlugin from '@fullcalendar/rrule'
import validateUtility from "../../../functions/validateUtility";
import inputConstants from "../../../constants/inputConstants";
import { Link } from "react-router-dom";

export default function AgentCalendar() {
    const user = useContext(UserContext);
    const repeatingVals = { 1: 'weekly', 2: 'monthly', 3: 'yearly' }
    const weeklyDays = { 'mo': false, 'tu': false, 'we': false, 'th': false, 'fr': false, 'sa': false, 'su': false }
    const dayNames = { 0: 'Monday', 1: 'Tuesday', 2: 'Wednesday', 3: 'Thursday', 4: 'Friday', 5: 'Saturday', 6: 'Sunday' }
    const COLORS = { 'Purple': '#b179fc', 'Aqua': '#8ceffc', 'Yellow': '#f7ef7f', 'Pink': '#fc8cfc', 'Green': '#52f596' }
    const [scheduledEcards, setScheduledEcards] = useState([]);
    const ecardsLoaded = useRef(false)
    const [scheduledEmails, setScheduledEmails] = useState([]);
    const emailsLoaded = useRef(false)
    const [showScheduled, setShowScheduled] = useState(true)
    const [showSent, setShowSent] = useState(true)
    const homeownersLoaded = useRef(false)
    const agentEventsLoaded = useRef(false)
    const [refresh, setRefresh] = useState(false);
    const [calData, setCalData] = useState([]);
    const [labels, setLabels] = useState([
        { title: 'Client Event', strokeWidth: 10, color: '#aeb1ff' },
        { title: 'Sent Ecard', strokeWidth: 10, color: 'blue' },
        { title: 'Unsent Ecard', strokeWidth: 10, color: '#f8a025' },
        { title: 'Sent Email', strokeWidth: 10, color: '#34378b' },
        { title: 'Unsent Email', strokeWidth: 10, color: '#3788D8' }
    ]);
    const [currentEvent, setCurrentEvent] = useState();
    const [emailContent, setEmailContent] = useState({})
    const [attachment, setAttachment] = useState([])
    const attachmentFile = useRef(null)
    const [eventView, setEventView] = useState(false);
    const [checkedState, setCheckedState] = useState([]);
    const [selectedAddresses, setSelectedAddresses] = useState([]);
    const [editingMode, setEditingMode] = useState(false)
    const [editEmail, setEditEmail] = useState(false)
    const [addPopup, setAddPopup] = useState(false);
    const [deletePopup, setDeletePopup] = useState(false);
    const [agentHomeowners, setAgentHomeOwners] = useState([]);
    const [arrayOfAgentHomeownersEmails, setArrayOfAgentHomeownersEmails] = useState([]);
    const [addressArray, setAddressArray] = useState([])
    const [schedulePopup, setSchedulePopup] = useState(false)
    const [sendDate, setSendDate] = useState('')
    const [customAddress, setCustomAddress] = useState('')
    const [type, setType] = useState('')

    const [serverMessage, setServerMessage] = useState(null);


    const [windowSize, setWindowSize] = React.useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = React.useState(document.body.scrollHeight)
    const [newEvent, setNewEvent] = useState({ title: '', start_date: new Date(), repeating: 0, color: '#b179fc', weekly_days: weeklyDays, reminder: 0 })
    const [agentEvents, setAgentEvents] = useState([])
    const [customEventView, setCustomEventView] = useState(false)
    const [deleteEventPopup, setDeleteEventPopup] = useState(false)
    const [dateInfo, setDateInfo] = useState({})
    const [theme, setTheme] = useState('')
    const [datePopup, setDatePopup] = useState(false)



    function updateNewEvent(key, value) {

        let nEvent = { ...newEvent };
        nEvent[key] = value;

        setNewEvent(nEvent);
    }

    function updateWeekday(val) {
        let nEvent = { ...newEvent }
        nEvent.weekly_days[val] = !nEvent.weekly_days[val];
        setNewEvent(nEvent)
        console.log(nEvent.weekly_days)
    }

    //events!

    function resetNewEvent() {
        setNewEvent({ title: '', start_date: new Date(), repeating: 0, color: '#b179fc', weekly_days: weeklyDays, reminder: 0 })
    }

    function submitNewEvent() {
        console.log(newEvent)
        sqlService.addAgentEvent({ user: user, input: newEvent })
            .then((data) => {
                setCustomEventView(false)
                resetNewEvent()
                setEditingMode(false)
                agentEventsLoaded.current = false;
                setRefresh(!refresh)

            })
            .catch((err) => {
                //   setServerMessage({ color: "red", message: "Something Went Wrong" });
                //   setTimeout(() => {
                //     setServerMessage({ color: "none", message: "" });
                //   }, 3000);
            });
    }


    function editEvent() {
        sqlService.updateAgentEvent({ user: user, input: { info: newEvent, id: currentEvent.info.id } })
            .then((data) => {
                setCustomEventView(false)
                resetNewEvent()
                setEditingMode(false)
                agentEventsLoaded.current = false;
                setRefresh(!refresh)

            })
            .catch((err) => {
                //   setServerMessage({ color: "red", message: "Something Went Wrong" });
                //   setTimeout(() => {
                //     setServerMessage({ color: "none", message: "" });
                //   }, 3000);
            });
    }


    function handleDeleteEvent() {

        sqlService.deleteAgentEvent({ user: user, input: { id: currentEvent.info.id } }).then(() => {
            setDeleteEventPopup(false);
            setCustomEventView(false)
            setEditingMode(false)
            resetNewEvent()
            agentEventsLoaded.current = false;
            setRefresh(!refresh)
        })
            .catch((err) => {

            })
    }



    useEffect(() => {
        window.addEventListener("resize", onWindowResize);


        return () => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, []);


    const onWindowResize = () => {
        setWindowSize(window.innerWidth);
        setWindowHeight(document.body.scrollHeight)
    };

    useEffect(() => {

        if (!homeownersLoaded.current) {

            sqlService.getHomeowners({}).then(data => {
                let homeowners = data.data;
                setAgentHomeOwners(homeowners);
                let homeownersEmail = homeowners.map(item => item.email);
                // console.log(homeownersEmail);
                setArrayOfAgentHomeownersEmails([...arrayOfAgentHomeownersEmails, ...homeownersEmail].sort());
                homeownersLoaded.current = true;
            }).catch(err => {
                console.log(err)
            });
        }
        if (!ecardsLoaded.current) {
         
            sqlEcardService.getScheduledEcardsForUser({ "user": user }).then((data) => {
            
                setScheduledEcards(data.data)
                ecardsLoaded.current = true;
     

            })
                .catch((err) => {
                    console.log(err);
                });

        }
        if (!emailsLoaded.current) {
            sqlService.getScheduledEmailsForUser({ "user": user }).then((data) => {

                setScheduledEmails(data.data)
                emailsLoaded.current = true;
            

            })
                .catch((err) => {
                    console.log(err);
                });

        }
        if (!agentEventsLoaded.current) {
            sqlService.getAgentEvents({ "user": user }).then((data) => {

                setAgentEvents(data.data)
                agentEventsLoaded.current = true;

            })
                .catch((err) => {
                    console.log(err);
                });
        }


    }, [refresh])

    function filteredTimes(time) {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();


    }





    function updateEcardDate() {
        sqlEcardService.updateEcardDate({ "user": user, "input": { id: currentEvent.card.id, date: sendDate, type: type } }).then((data) => {
            if (type === 'card') {
                ecardsLoaded.current = false;
            } else {
                emailsLoaded.current = false;
            }
            setRefresh(!refresh);
            setSchedulePopup(false)

        })
            .catch((err) => {
                console.log(err);
            });

    }

    const handleDelete = () => {
        console.log(currentEvent)
        sqlEcardService.deleteScheduledEcard({ "user": user, "input": { id: currentEvent.card.id, type: type } }).then((data) => {
            if (type === 'card') {
                ecardsLoaded.current = false;
            } else {
                emailsLoaded.current = false;
            }
            setRefresh(!refresh);
            setEventView(false)
            setDeletePopup(false)

        })
            .catch((err) => {
                console.log(err);
            });

    }



    const getAttachmentsSizs = (att) => {
        var size = 0;
        for (let a of att) {
            size += a?.size;
        }
        return size;
    }

    const handleAttachmentChange = (event) => {


        const file = event.target.files[0];
        const reader = new FileReader();

        if (!validateUtility.validFileSize(file)) {
            setServerMessage({ "message": `File size must be less than ${inputConstants.MAX_FILE_SIZE / 1000000}mb`, "title": "Error" })
            return;
        }

        if ((getAttachmentsSizs(attachment) + file?.size) > inputConstants.MAX_FILE_SIZE) {
            setServerMessage({ "message": `Attachments size must be less than ${inputConstants.MAX_FILE_SIZE / 1000000}mb and your attachements size will be ${(getAttachmentsSizs(attachment) + file?.size) / 1000000}mb with this file`, "title": "Error" })
            return;
        }

        reader.onload = () => {

            let data = reader.result;

            let t = file.type;

            let type = t.split("/")[1];

            let updated = [...attachment]
            updated.push({ name: file.name, type: type, data: data, size: file?.size })
            console.log(updated)

            setAttachment(updated)


        };

        if (file) {
            reader.readAsDataURL(file);
        }

    }

    const handleAttachmentUpload = () => {
        console.log(attachment)
        attachmentFile.current.click();

    }


    function removeAttachment(index) {
        let updated = [...attachment]
        updated.splice(index, 1)

        setAttachment(updated)

    }
    function saveEmail() {
        sqlService.updateScheduledEmail({ "user": user, "input": { id: currentEvent.card.id, attachments: attachment, content: {subject: emailContent.subject, message: document.getElementById('cal-editor')?.innerHTML }} }).then((data) => {
            emailsLoaded.current = false;
            setRefresh(!refresh);


        })
            .catch((err) => {
                console.log(err);
            });

    }

    function updateEmailContent(key, value) {
        let e = { ...emailContent };
        e[key] = value;
        setEmailContent(e);

    }

    function handleAdd() {

        var values = Array.prototype.slice.call(document.querySelectorAll('#multi-select option:checked'), 0).map(function (v) {
            return v.value;
        });
        if (values.length) {
            addToSelection(values)
            setAddPopup(false)
        }

    }

    function addToSelection(v) {


        setSelectedAddresses([...selectedAddresses].concat(v))
        setCheckedState([...checkedState].concat(new Array(v.length).fill(true)))
        setAddressArray([...addressArray].concat(v))

    }

    function handleSelectionChange(i) {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === i ? !item : item
        );
        setCheckedState(updatedCheckedState);
        updateSelected(updatedCheckedState);


    }

    function updateSelected(s) {
        console.log(s)
        var selected = [];
        for (var i = 0; i < addressArray.length && i < checkedState.length; i++) {
            if (s[i]) {
                selected.push(addressArray[i]);
            }
        }
        console.log(selected)
        setSelectedAddresses(selected);
        // console.log({ selected });

    }


    function updateRecipients() {

        console.log(selectedAddresses)

        sqlEcardService
            .updateEcardRecipients({ user: user, input: { addresses: selectedAddresses, id: currentEvent.card.id, type: type } })
            .then((data) => {
                if (type === 'card') {
                    ecardsLoaded.current = false;
                } else {
                    emailsLoaded.current = false;
                }
                setRefresh(!refresh);
                setAddressArray(selectedAddresses)


            })
            .catch((err) => {

            });
    }

    useEffect(() => {

        var data = []
        var recipients = []

        var mapOfEcards = {};
        for (let item of scheduledEcards) {
            if (mapOfEcards[item.ecard_id]) {
                mapOfEcards[item.ecard_id] = [...mapOfEcards[item.ecard_id], item];
            } else {
                mapOfEcards[item.ecard_id] = [item];
            }
        }

        var mapOfEmails = {};
        for (let item of scheduledEmails) {
            if (mapOfEmails[item.id]) {
                mapOfEmails[item.id] = [...mapOfEmails[item.id], item];
            } else {
                mapOfEmails[item.id] = [item];
            }
        }


        if (showSent || showScheduled) {

            for (let [key, value] of Object.entries(mapOfEcards)) {
                if (value[0].sent && !showSent) {
                    continue;
                }
                if (!value[0].sent && !showScheduled) {
                    continue;
                }
                recipients = value.map(item => item.email);

                var event = {
                    type: "card",
                    title: value[0].title + ' (' + parseInt(recipients.length) + ')',
                    start: value[0].start_date,
                    editable: true,
                    color: value[0].sent ? 'blue' : '#f8a025',
                    allDay: true,
                    card: value[0],
                    recipients: recipients
                }
                data.push(event)
            }


            for (let [key, value] of Object.entries(mapOfEmails)) {
                if (value[0].sent && !showSent) {
                    continue;
                }
                if (!value[0].sent && !showScheduled) {
                    continue;
                }
                recipients = value.map(item => item.email);
                if (value[0].attachments !== null && typeof value[0].attachments === 'string') {

                    var att = []

                    for (let a of value[0].attachments.split('<<<!>>>')) {
                        let temp = a?.split('***!***')

                        att.push({ id: temp[2], name: temp[0], link: temp[1] })

                    }
                    value[0].attachments = att;

                }
                var event = {
                    type: "email",
                    title: value[0].subject + ' (' + parseInt(recipients.length) + ')',
                    start: value[0].start_date,
                    editable: true,
                    color: value[0].sent ? '#34378b' : '#3788D8',
                    allDay: true,
                    card: value[0],
                    recipients: recipients

                }
                data.push(event)
            }


        }
        for (let e of agentEvents) {
            var event = {
                type: "event",
                title: e.title,
                editable: true,
                color: e.color,
                info: e

            }
            if (e.repeating && e.weekly_days.length > 0) {
                var days = e.weekly_days.split('<!>')
                days = days.splice(0, days.length - 1)
                var formattedDays = weeklyDays;
                for (let day of days) {
                    formattedDays[day] = true;
                }
                event.rrule = { freq: 'yearly', dtstart: e.start_date, byweekday: days }
                event.days = formattedDays
            } else if (e.repeating) {
                event.days = weeklyDays

                event.rrule = { freq: repeatingVals[e.repeating], dtstart: e.start_date }
            }


            else {
                event.days = weeklyDays
                event.start = e.start_date
            }
            data.push(event)

        }

        for (let client of agentHomeowners) {
            if (client.dates) {
                for (let date of client.dates.split('<<<!>>>')) {
                    let d = date.split('***!***')
                    var tempDate = new Date()
                    tempDate.setFullYear(tempDate.getFullYear() - 3)
                    tempDate.setMonth(d[0])
                    tempDate.setDate(d[1])
                    data.push({ type: 'client_date', title: d[2], editable: true, color: '#aeb1ff', client: client, clientdate: tempDate, rrule: { freq: 'yearly', dtstart: tempDate } })


                }

            }
        }


        setCalData(data)
        console.log(data)




    }, [scheduledEcards, scheduledEmails, agentEvents, showSent, showScheduled])

    return (
        <>
   
            <div className="Task-Wrap">

                <div className="Calendar-Wrap">
                    <FullCalendar

                        plugins={[dayGridPlugin, bootstrap5Plugin, rrulePlugin]}
                        themeSystem="bootstrap5"
                        initialView="dayGridMonth"
                        events={calData}
                        eventDisplay='block'
                        height={windowSize < 600 ? 'auto' : undefined}
                        eventClick={(e) => {
                            setCurrentEvent(e.event._def.extendedProps);
                            if (e.event._def.extendedProps.type === 'email' || e.event._def.extendedProps.type === 'card') {
                                setEventView(true);
                                setCheckedState(new Array(e.event._def.extendedProps.recipients.length).fill(true));
                                setAddressArray(e.event._def.extendedProps.recipients);
                                setSelectedAddresses(e.event._def.extendedProps.recipients);
                                setSendDate(new Date(e.event._def.extendedProps.card.start_date));

                            } else if (e.event._def.extendedProps.type === 'event') {
                                setCustomEventView(true)
                                setNewEvent({ title: e.event._def.title, reminder: e.event._def.extendedProps.info.reminder, repeating: e.event._def.extendedProps.info.repeating, start_date: new Date(e.event._def.extendedProps.info.start_date), color: e.event._def.extendedProps.info.color, weekly_days: e.event._def.extendedProps.days })
                                setEditingMode(true)

                            }

                            if (e.event._def.extendedProps.type === 'email') {
                                setType('email')
                                setEmailContent({ subject: e.event._def.extendedProps.card.subject, message: e.event._def.extendedProps.card.content })
                                setAttachment(e.event._def.extendedProps.card.attachments ?? [])


                            } else if (e.event._def.extendedProps.type === 'card') {
                                setType('card')
                            } else if (e.event._def.extendedProps.type === 'client_date') {
                                setType('date')
                                setDateInfo(e.event._def)
                                setTheme(Object.keys(inputConstants.ECARD_THEMES).find((key) => e.event._def.title.toUpperCase().match(key.toUpperCase())))
                                setDatePopup(true)


                            }




                        }}
                        dayMaxEventRows={windowSize < 600 ? false : true}
                        headerToolbar={{
                            left: `${windowSize < 480 ? '' : 'addButton'}`,
                            center: `${windowSize < 480 ? 'title' : 'title'}`,
                            right: `${windowSize < 480 ? 'addButton prev next' : 'prev next'}`
                            // left: `${windowSize < 480 ? '' : 'prev'}`,
                            // center: `${windowSize < 480 ? 'title' : 'title'}`,
                            // right: `${windowSize < 480 ? 'prev next' : 'next'}`
                        }}
                        customButtons={{
                            addButton: {
                                text: 'Add Event',
                                click: (() => setCustomEventView(true))
                            }
                        }}


                    />
                    <div>
                        <DiscreteColorLegend
                            items={labels}
                            orientation='vertical'
                            style={{ display: 'flex' }}
                            className="justify-content-center flex-wrap padding-top-5-px"
                            // height={windowSize < 750 ? 150 : 100}
                            height={75}


                        >
                        </DiscreteColorLegend>
                    </div>
                    <div className="display-flex column-gap-15-px justify-content-center margin-bottom-50-px">
                        <input type='checkbox' id="scheduled" checked={showScheduled} onChange={() => setShowScheduled(!showScheduled)} />
                        <label for="scheduled" style={{ fontSize: '.9em' }}>Show scheduled correspondence</label>
                        <input type='checkbox' id="sent" checked={showSent} onChange={() => setShowSent(!showSent)} />
                        <label for="sent" style={{ fontSize: '.9em' }}>Show sent correspondence</label>
                    </div>
                </div>
            </div>

            {datePopup ?
                <>
                    <div className="backdrop"></div>
                    <div style={{ minWidth: '300px' }} className="popup ">
                        <h5>{dateInfo.title}</h5>
                        <span className="margin-top-10-px">{dateInfo.extendedProps?.client?.contact_name}</span>
                        <span>{dateInfo.extendedProps?.client?.email}</span>
                        <span>{dateInfo.extendedProps?.client?.phone_number}</span>
                        <Link className="text-decoration-none grey hover-dark-blue margin-top-20-px" to={'/ecards'} state={{ theme: theme, client: dateInfo.extendedProps?.client?.email, date: dateInfo.extendedProps?.clientdate }}>
                            Send a{theme && theme?.[0] !== 'A' ? '' : 'n'} {theme} Ecard
                            <FontAwesomeIcon className="padding-left-10-px" icon={faArrowRight} />
                        </Link>

                        <button className="Cancel-Button margin-top-20-px" onClick={() => { setDatePopup(false); setTheme(''); setDateInfo(null) }}>
                            Close
                        </button>
                    </div>
                </> : null}




            {eventView ?
                <>


                    <div className="backdrop"></div>
                    <div className="popup ">
                        {deletePopup ?
                            <div style={{ width: '300px' }} className="popup box-shadow-2">
                                <span style={{ padding: '20px 0' }}>Are you sure you want to discard?</span>
                                <div className="display-flex column-gap-10-px margin-top-15-px">
                                    <button className="Cancel-Button" onClick={() => setDeletePopup(false)}>Close</button>
                                    <button className="Cancel-Button red" onClick={() => handleDelete()}>Delete</button>
                                </div>
                            </div>

                            : null}
                        {schedulePopup ?
                            <>



                                <div style={{ zIndex: '2000', width: '400px', height: '450px', bottom: '-15%', top: "unset" }} className="popup box-shadow-2">
                                    <div className="display-flex flex-direction-column flex-wrap">
                                        <label className="card-input__label">
                                            Select Date to Send
                                        </label>
                                        <div style={{ minHeight: '300px', paddingTop: '15px' }}>
                                            <DatePicker className="Input-Style"
                                                minDate={new Date()}

                                                selected={sendDate}
                                                onChange={(value) => { setSendDate(value) }}
                                                inline
                                                showTimeSelect
                                                filterTime={filteredTimes}

                                            />
                                        </div>

                                    </div>

                                    <div className="display-flex column-gap-10-px">
                                        <button className="Cancel-Button" onClick={() => (setSchedulePopup(false), setSendDate(new Date(currentEvent.card.start_date)))}>
                                            Cancel
                                        </button>
                                        <button className="popup-submit-button" onClick={() => (updateEcardDate())}>
                                            Save
                                            <FontAwesomeIcon className="fa-sm margin-left-5-px" icon={faSave} />
                                        </button>
                                    </div>
                                </div>
                            </>
                            : null}
                        {addPopup ?
                            <div style={{ minWidth: '300px', height: '300px', zIndex: '9999999', left: '45%', bottom: '5%', top: "unset", boxShadow: '0 2px 10px 0 rgba(192, 192, 192, 0.6)' }} className="popup">
                                <label style={{ marginLeft: '15px' }} className="card-input__label">Select Recipient(s)</label>
                                <select className="Input-Style Input-Style-Multiselect" id='multi-select' multiple size='8' >
                                    {arrayOfAgentHomeownersEmails.filter((e) => !addressArray.includes(e)).map((email) =>
                                        <option value={email}>{email}</option>

                                    )}
                                </select>


                                <div className="display-flex column-gap-10-px margin-top-15-px">

                                    <button className="Cancel-Button" onClick={() => (setAddPopup(false))}>
                                        Cancel
                                    </button>
                                    <button className="popup-submit-button" onClick={() => (handleAdd())}>
                                        Add
                                    </button>
                                </div>
                            </div>
                            : null

                        }
                        <div className="display-flex flex-direction-column flex-wrap position-relative">
                            {currentEvent?.card?.sent ?
                                <h4 className="grey">Sent <FontAwesomeIcon className="grey " icon={faEnvelopeCircleCheck} /></h4>
                                : <h4 className="grey">Scheduled <FontAwesomeIcon className="grey " icon={faPaperPlane} /></h4>
                            }
                        </div>
                        {type === 'card' ?
                            <img style={{ width: windowSize < 450 ? '300px' : '400px', padding: '20px 0' }} src={currentEvent.card.img_link} />
                            :
                            <>
                                <div className="display-flex flex-direction-column row-gap-15-px" style={{ alignContent: 'left', width: windowSize < 450 ? '300px' : '400px' }}>
                                    <div>
                                        <label className="card-input__label">Subject</label>
                                        {editEmail ?
                                            <input className="card-input__input" type="text" value={emailContent.subject} onChange={(e) => updateEmailContent("subject", e.target.value)}></input>

                                            :
                                            <p>{emailContent.subject}</p>}
                                    </div>
                                    <div>
                                        <label className="card-input__label">Message</label>
                                        {/* {editEmail ?
                                            <textarea className="card-input__input" type="text" value={emailContent.message} onChange={(e) => updateEmailContent("message", e.target.value)}></textarea>
                                            :
                                            <p>{emailContent.message}</p>} */}
                                            {editEmail ?
                                            <div className="Input-Style" id='cal-editor' contentEditable="true" dangerouslySetInnerHTML={{ __html: emailContent.message }}></div>
                                   : <div style={{maxHeight: '300px', overflow: 'auto', scrollbarWidth: 'thin'}} dangerouslySetInnerHTML={{ __html: emailContent.message }}></div>}
                                            </div>
                                    <div>

                                        <div className="display-flex">
                                            <label style={{ width: 'fit-content' }} className="card-input__label">Attachments </label>
                                            {editEmail ? <button style={{ padding: '0 5px', marginLeft: '10px' }} onClick={(e) => handleAttachmentUpload(e)} className="popup-submit-button"><FontAwesomeIcon icon={faPlus} /></button> : null}
                                        </div>
                                        <input className='display-none' type="file" ref={attachmentFile} onChange={(e) => { handleAttachmentChange(e) }} />
                                        {attachment?.length ?
                                            <div style={{ marginTop: '5px' }} className="display-flex flex-direction-column">
                                                {attachment?.map((file, index) =>
                                                    <div className="display-flex margin-top-5-px">
                                                        {editEmail ?
                                                            <FontAwesomeIcon icon={faX} style={{ marginTop: '2px', marginRight: '10px' }} className="grey  fa-xs cursor-pointer" onClick={() => removeAttachment(index)} />
                                                            : null}
                                                        <span style={{ marginTop: '-5px' }} className=" ">{file.name}</span>

                                                    </div>
                                                )}
                                            </div> : null}
                                    </div>
                                    {!editingMode && !currentEvent.card.sent ?
                                        <button style={{ padding: '5px', minWidth: '75px' }} className={`${editEmail ? 'popup-submit-button' : 'Tag-Button box-shadow-2'} margin-auto-lr display-block margin-top-20-px`} onClick={() => { if (editEmail) { saveEmail(); setEditEmail(false) } else { setEditEmail(true) } }}>
                                            {editEmail ? <span>Save<FontAwesomeIcon className="fa-sm margin-left-5-px" icon={faSave} /></span> : 'Edit'}
                                        </button>
                                        : null}

                                </div>
                            </>
                        }

                        <h6 className="grey padding-10-15 text-align-center margin-top-10-px">{currentEvent.card.sent ? 'Sent on ' : 'Sending on '} {new Date(sendDate).toLocaleString('default', { month: 'short', day: 'numeric' })} to:</h6>
                        <div style={{ maxHeight: '30vh', overflowY: 'auto', minWidth: windowSize < 450 ? '300px' : '400px' }}>
                            {
                                addressArray.map((address, index) => (
                                    <div key={index} style={{ padding: '0 15px' }} className={`display-flex align-items-center  ${checkedState[index] && editingMode ? 'background-color-light-blue' : ''}`}>
                                        {editingMode ?
                                            <input type='checkbox' checked={checkedState[index] || false} onChange={() => handleSelectionChange(index)} />
                                            : null}
                                        <p className="margin-left-10-px margin-top-10-px">{address}</p>
                                    </div>
                                ))
                            }
                        </div>
                        {editingMode ?
                            <div>
                                <button onClick={() => { setAddPopup(true) }} style={{ padding: '5px 10px', width: '200px' }} className="popup-submit-button margin-top-15-px">Add from Contacts
                                    <FontAwesomeIcon className="fa-sm margin-left-5-px" icon={faPlus} />
                                </button>


                                <div className="display-flex column-gap-10-px margin-top-15-px">
                                    <input style={{ width: '200px' }} className="Input-Style" placeholder="Add Custom" type="text" value={customAddress} onChange={(e) => setCustomAddress(e.target.value)}></input>
                                    <button style={{ minWidth: 'unset', width: '40px', padding: '5px 10px' }} onClick={() => { if (customAddress) { addToSelection(customAddress); setCustomAddress('') } }} className="popup-submit-button"><FontAwesomeIcon icon={faPlus} /></button>
                                </div>



                            </div>

                            : null
                        }
                        {!currentEvent.card.sent ?
                            <div className="display-flex column-gap-10-px margin-top-15-px">

                                <button onClick={() => {
                                    if (editingMode) {
                                        setAddressArray(currentEvent.recipients);
                                        setAddressArray(currentEvent.recipients);
                                        setCheckedState(new Array(currentEvent.recipients.length).fill(true))
                                    } else {
                                        setEditEmail(false)
                                    }
                                    setEditingMode(!editingMode);

                                }}
                                    className="Tag-Button box-shadow-2">{editingMode ? 'Reset' : 'Edit Recipients'}</button>
                                <button className="Tag-Button box-shadow-2" onClick={() => { setSchedulePopup(true); setAddPopup(false) }}>Reschedule</button>
                                <button className="Tag-Button box-shadow-2" onClick={() => setDeletePopup(true)}>
                                    Cancel Send
                                </button>

                            </div>
                            : null}


                        <div className="display-flex column-gap-10-px margin-top-15-px">


                            <button className="Cancel-Button" onClick={() => (setEventView(false), setEditingMode(false), setAddPopup(false), setEditEmail(false), setSelectedAddresses([]), setAddressArray([]))}>
                                Close
                            </button>
                            {editingMode ?
                                <button onClick={() => (setEditingMode(false), updateRecipients())} style={{ padding: '5px 10px' }} className="popup-submit-button">Save
                                    <FontAwesomeIcon className="fa-sm margin-left-5-px" icon={faSave} />
                                </button>
                                : null}

                        </div>
                    </div>
                </>
                : null}

            {customEventView ?
                <>
                    <div className="backdrop"></div>
                    <div className="popup" style={{ minWidth: windowSize < 450 ? '300px' : '400px' }}>
                        {deleteEventPopup ?
                            <div style={{ width: windowSize < 450 ? '250px' : '300px' }} className="popup box-shadow-2">
                                <span style={{ padding: '20px 0' }}>Are you sure you want to delete?</span>
                                <div className="display-flex column-gap-10-px margin-top-15-px">
                                    <button className="Cancel-Button" onClick={() => setDeleteEventPopup(false)}>Close</button>
                                    <button className="Cancel-Button red" onClick={() => handleDeleteEvent()}>Delete</button>
                                </div>
                            </div>

                            : null}
                        <h4 className="grey">{editingMode ? 'Edit' : 'Add'} Event</h4>
                        <div style={{ minWidth: '300px' }} className="margin-auto-lr">
                            <label className="card-input__label margin-top-15-px">
                                Title
                            </label>
                            <input onChange={(val) => updateNewEvent("title", val.target.value)} value={newEvent.title} className="Input-Style width-full-pt"></input>
                            <label className="card-input__label margin-top-15-px">
                                {parseInt(newEvent.repeating) ? 'Start ' : ''} Date
                            </label>
                            <DatePicker
                                showTimeSelect
                                className="Input-Style width-full-pt"
                                selected={newEvent.start_date}
                                onChange={(val) => updateNewEvent('start_date', val)} />
                            <div className="display-flex justify-content-space-between width-full-pt">
                                <div style={{ width: '40%' }}>
                                    <label className="card-input__label margin-top-15-px">Repeating</label>
                                    <select style={{ width: '100%' }} value={newEvent.repeating} className="Input-Style" onChange={(val) => updateNewEvent('repeating', val.target.value)}>
                                        <option value={0}>Never</option>
                                        <option value={1}>Weekly</option>
                                        <option value={2}>Monthly</option>
                                        <option value={3}>Yearly</option>
                                        <option value={4}>Custom</option>
                                    </select>
                                </div>
                                <div style={{ width: '40%' }}>
                                    <label className="card-input__label margin-top-15-px">Color</label>
                                    <select style={{ width: '100%' }} value={newEvent.color} className="Input-Style" onChange={(val) => updateNewEvent('color', val.target.value)} >
                                        {Object.keys(COLORS).map((key) =>
                                            <option key={key} value={COLORS[key]} style={{ color: 'white', background: COLORS[key] }}>{key}</option>
                                        )}


                                    </select>

                                </div>
                            </div>
                            {newEvent.repeating == 4 ?
                                <>
                                    <label className="card-input__label margin-top-15-px">Select days of the week</label>
                                    {Object.keys(weeklyDays).map((day, index) =>
                                        <div key={index} className="display-flex column-gap-10-px">
                                            <input id="weekday" checked={newEvent.weekly_days[day]} onChange={() => updateWeekday(day)} type='checkbox' />
                                            <label for="weekday">{dayNames[index]}</label>
                                        </div>
                                    )}

                                </>
                                :
                                <div className="display-flex align-items-center margin-10-px ">
                                    <input type="checkbox" id="reminder" name={"reminder"} checked={newEvent.reminder || false} onChange={(e) => setNewEvent({ ...newEvent, [e.target.name]: e.target.checked })}></input>
                                    <label className="margin-left-15-px" for="reminder">Send me a reminder the day before</label>
                                </div>
                            }


                            <div className="display-flex justify-content-center column-gap-10-px margin-top-40-px">

                                <button className="Cancel-Button" onClick={() => { setCustomEventView(false); setEditingMode(false); resetNewEvent() }}>Close</button>
                                {editingMode ?
                                    <button className="Cancel-Button" onClick={() => setDeleteEventPopup(true)}>Delete<FontAwesomeIcon className="fa-sm margin-left-5-px" icon={faTrash} /></button>
                                    : null}
                                <button className="popup-submit-button" onClick={() => { editingMode ? editEvent() : submitNewEvent() }}>
                                    Save
                                    <FontAwesomeIcon className="fa-sm margin-left-5-px" icon={faSave} />
                                </button>

                            </div>
                        </div>
                    </div>

                </> : null}

        </>
    )

}
