import React, { useState } from "react";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import './hoverHint.component.css'

// Hint is the message hint should display, use widthMaxContent if message is short and/or shouldn't wrap
export default function HoverHint({ hint, widthMaxContent }) {

    const [show, setShow] = useState(false);
    const hintHeight = document.getElementById('Hint')?.offsetHeight
    

    return (

        <span className="position-relative"
            onMouseEnter={(e) => {
                setShow(true)
            }}
            onMouseLeave={() => setShow(false)}>
            <BsFillQuestionCircleFill

                className="fa-xs grey Question-Mark position-relative"

            />
            <div style={{ top: `-${hintHeight}px`, width: widthMaxContent ? 'max-content' : '60vw' }} id='Hint' className={`Question-Hint border-radius-10-px ${show ? 'visibility-visibile' : 'visibility-hidden'}`}>
                <span>{hint}</span>
            </div>
        </span>

    )
}