import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import "./adminDashboard.css/adminCard.component.css";
import AdminPopup from "./adminPopUp.component";
import AdminDelete from "./adminDelete.component";


export default function AdminCard({
  itemData,
  selectedTable,
  setRefreshData,
  editFunction,
  deleteFunction,
  columns,
  tableName,
}) {
  const [adminPopup, setAdminPopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deletePopup, setDeletePopup] = useState(false);


  const openAdminPopup = (item) => {
    setSelectedItem(item);
    setAdminPopup(true);
  };

  const closeAdminPopup = () => {
    setAdminPopup(false);
  };

  const openDeletePopup = (item) => {
    setSelectedItem(item);
    setDeletePopup(true);
  };

  const closeDeletePopUp = () => {
    setDeletePopup(false);
  };

  return (
    <div className="admin-card-container">
      {itemData && (
        <div>
          <div className="admin-card-thumbnail">
            <div
              className="admin-card-delete"
              onClick={() => openDeletePopup(itemData)}
            >
              <span className="admin-trash-icon">
                <FontAwesomeIcon icon={faTrash} />
              </span>
            </div>
            <img
              src={itemData.img_link ? itemData.img_link : "defaultImageLink"}
              alt="thumbnail"
            />
          </div>
          <div className="admin-card-content">
            <div className="admin-card-category">{tableName}</div>
            <h1 className="admin-card-title">
              {itemData.title ? itemData.title : "No title available"}
            </h1>
            <div className="admin-card-button-date-container">
              <div className="admin-card-button-container">
                <button
                  className="admin-card-edit-button"
                  onClick={() => openAdminPopup(itemData)}
                >
                  View & Edit
                </button>
              </div>
              <div className="admin-card-date">
                <span className="admin-card-timestamp">
                  <span className="admin-card-icon">
                    <FontAwesomeIcon icon={faClock} />
                  </span>
                  <span className="admin-card-time">
                    {itemData.creation_date
                      ? itemData.creation_date
                      : "No content available"}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {adminPopup && (
        <AdminPopup
          onClose={closeAdminPopup}
          columns={columns}
          editFunction={editFunction}
          setRefreshData={setRefreshData}
          tableName={selectedTable}
          item={selectedItem}
          openAdminPopup={openAdminPopup}
        />
      )}
      {deletePopup && (
        <AdminDelete
          onClose={closeDeletePopUp}
          item={selectedItem}
          deleteFunction={deleteFunction}
          setRefreshData={setRefreshData}
        />
      )}
    </div>
  );
}
