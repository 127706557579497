import React, { useState } from "react";
import '../../../../node_modules/react-vis/dist/style.css';
import './dash.component.css'
import { RadialChart, DiscreteColorLegend, Hint } from 'react-vis';



export default function PieChart({ pieData, labels, pieColors }) {
  const [hoveredCell, setHoveredCell] = useState(null)

  function buildValue(hoveredCell) {
    const { radius, angle, angle0 } = hoveredCell;
    const truedAngle = (angle + angle0) / 2;
    return {
      x: radius * Math.cos(truedAngle),
      y: radius * Math.sin(truedAngle)
    };
  }
  return (
    <>

      <RadialChart
        className='display-block margin-auto-lr'
        data={pieData}
        width={200}
        height={200}
        colorType='literal'
        onValueMouseOver={v =>

          setHoveredCell(v)

        }
        onValueMouseOut={v => setHoveredCell(null)}
      >

        {hoveredCell ? (


          <Hint className='Hint-Hover' value={buildValue(hoveredCell)}>
            <div style={{ height: 'fit-content' }}>

              <span className="white" >{hoveredCell.label}</span>
            </div>
          </Hint>
        ) : null}


      </RadialChart>


      <DiscreteColorLegend
        items={labels}
        colors={pieColors}
        orientation='horizontal'
        style={{ display: 'flex' }}
        className="flex-wrap justify-content-center"
        height={150}
      >
      </DiscreteColorLegend>
    </>
  )
}