import React, { useEffect, useRef, useState } from "react";
import "./managePro.css";

import sqlService from "../../../services/sqlService";
import { HiVideoCamera } from "react-icons/hi";
// import {GrCompliance} from "react-icons/gr";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import { RiDeleteBinLine } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
import { VscSave } from "react-icons/vsc";
import { faCopy, faShare, faStar } from "@fortawesome/free-solid-svg-icons";


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Button from "react-bootstrap/Button";
import Spinner from 'react-bootstrap/Spinner';
import Modal from "react-bootstrap/Modal";
import { ModalBody } from "react-bootstrap";

import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

import SubHeader from "../../main.component/subHeader.component/subHeader.component";

import searchUtility from "../../../functions/searchUtility";

import TableFooter from "../../utility.component/tableFooter.component";

import ScrollToTop from "../../utility.component/scrollToTop.component";

import { useContext } from "react";
import { UserContext } from "../../../App";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  faCircleUser,
  faUser,
  faPhone,
  faLocationDot,
  faEnvelope,
  faGlobe,
  faPenToSquare,
  faEdit,
  faTrash,
  faPaperclip,
  faFileImport,
  faFileCsv,
  faFileExcel

} from "@fortawesome/free-solid-svg-icons";
import InputModal from "../../utility.component/inputModal.component/inputModal.component";


export default function ManagePro() {
  const user = useContext(UserContext);
  const [filteredProfessionals, setFilteredProfessionals] = useState([]);

  const [professionals, setProfessionals] = useState([]);

  const loaded = React.useRef(false);
  const loaded2 = React.useRef(false);

  const [editingRow, setEditingRow] = useState(null);
  const [editingCell, setEditingCell] = useState(null);

  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredCell, setHoveredCell] = useState(null);

  const [valueOnEdit, setValueOnEdit] = useState(null);

  const [taskModal, setTaskModal] = useState(false);
  const [proEditModal, setProEditModal] = useState(false);
  const [industry, setIndustry] = useState('')
  const [currentPro, setCurrentPro] = useState(null)

  const [uploadingImport, setUploadingImport] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [messageModal, setMessageModal] = useState(false)
  const [invitePopup, setInvitePopup] = useState(false)






  // const INDUSTRIES = [
  //   "Handyman",
  //   "Plumber",
  //   "Seasonal",
  //   "Electricity",
  //   "Roofing",
  //   "Pest",
  //   "Cleaning",
  //   "Gas",
  //   "Moving",
  //   "Landscaping",
  // ];

  const [newTask, setNewTask] = useState({
    contact_name: "",
    industry_name: "",
    sub_industry_name: "",
    company_name: "",
    email: "",
    phone_number: "",
    city: "",
    state: "",
    website: "",
    address: "",
    nation_wide: 0,
    state_wide: 0,
    country: user?.country
  });

  const resetProValues = () => {
    setNewTask({
      contact_name: "",
      industry_name: "",
      sub_industry_name: "",
      company_name: "",
      email: "",
      phone_number: "",
      city: "",
      state: "",
      website: "",
      address: "",
      nation_wide: 0,
      state_wide: 0,
      country: user?.country
    });
  }



  const handleClose = () => { };

  const [categories, setCategories] = useState({});

  const [searchBarValue, setSearchBarValue] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const currentPageRef = useRef(1);
  const prosPerPage = 10;
  const prosPerPageMobile = 5;

  const [totalPages, setTotalPages] = useState(0);

  const [scrollCurrentPage, setScrollCurrentPage] = useState(1)
  const [scrollTotalPages, setScrollTotalPages] = useState(1)

  const handleClose1 = () => {
    setTaskModal(false);
    resetProValues();
  };

  const handleProEditClose = () => {
    setProEditModal(false);
    resetProValues();
  };

  const [deleteModal, setDeleteModal] = useState(false);

  function updateNewTask(key, value) {
    let nTask = { ...newTask };
    nTask[key] = value;
    console.log(nTask)
    setNewTask(nTask);
  }

  const TABLE_COLUMNS = [
    "contact_name",
    "industry_name",
    "company_name",
    "phone_number",
    "state",
    "city",
    "address",
    "email",
    "website",
  ];

  const [windowSize, setWindowSize] = React.useState(window.innerWidth);

  const Navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      Navigate("/")
    }
  })


  useEffect(() => {
    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  const onWindowResize = () => {
    setWindowSize(window.innerWidth);
  };

  const [prosPerPageActive, setProsPerPageActive] = useState(0);

  const [INDUSTRIES, setINDUSTRIES] = useState([]);


  useEffect(() => {
    if (!loaded2.current) {
      sqlService
        .getIndustryNames()
        .then((data) => {
          const INDUSTRIES = [];
          for (let i = 0; i < data.data.length; i++) {
            INDUSTRIES[i] = data.data[i].type_name;
          }
          setINDUSTRIES(INDUSTRIES);
        })
        .catch((err) => {
          console.log("Didn't get industry names");
          console.log(err);
        });
      loaded2.current = true;
    }
  }, []);

  // useEffect(()=>{console.log(newTask)},[newTask]);

  const proValues = {
    "nameText": { label: "Name*", key: "contact_name" },
    "industryDropdown": {
      label: 'Industry', key: 'industry_name', dropdown: INDUSTRIES, value: [industry, setIndustry], textInput: true
    },
    "subText": { label: 'Subindustry', key: 'sub_industry_name' },
    "companyText": { label: 'Company', key: 'company_name' },
    "emailText": { label: 'Email*', key: 'email' },
    "phoneText": { label: 'Number*', key: 'phone_number' },
    "websiteText": { label: 'Website', key: 'website' },
    "cityText": { label: 'City*', key: 'city' },
    "addressText": { label: 'Address*', key: 'address' },
    "zipText": { label: user.country === 'Canada' ? 'Postal Code*' : 'Zip Code*', key: 'zip_code' },
    // "countrySelect": { label: 'Country*', key: 'country' },
    "stateSelect": { label: user.country === 'Canada' ? 'Province*' : 'State*', key: 'state' },
    "state_wide": { key: 'state_wide' },
    "nation_wide": { key: 'nation_wide' }
  }

  useEffect(() => {
    if (!loaded.current) {
      sqlService
        .getProfessionalsForAgent({ user: user })
        .then((data) => {
          var pros = data.data;

          var prosObj = {}
          for (let pro of pros) {
              let address = { address: pro?.address, state: pro?.state, city: pro?.city, zip_code: pro?.zip_code, country: pro?.country, lat: pro?.lat, lng: pro?.lng }
              if(prosObj[pro.id]){
                prosObj[pro.id] = {...prosObj[pro.id], "additional_addresses": [...prosObj[pro.id]["additional_addresses"], address]}
              }else{
                prosObj[pro.id] = {...pro, "additional_addresses": [address]}
              }
            // if (!pro.primary_address) {
            //   let address = { address: pro?.address, state: pro?.state, city: pro?.city, zip_code: pro?.zip_code, lat: pro?.lat, lng: pro?.lng }
            //   var temp = pros.find(p => p.primary_address && p.id === pro.id)
            //   if(temp){
            //     temp.additional_addresses = temp?.additional_addresses ? temp?.additional_addresses.concat([address]) : [address];
            //   }

            // }
          }
          // pros = pros.filter(p => p.primary_address);
          pros = Object.values(prosObj);
          setProfessionals(pros);
          setFilteredProfessionals(pros);
          prof.current = pros;
          // const prosPerPageActive = windowSize > 1000 ? prosPerPage : prosPerPageMobile;
          // setProsPerPageActive(prosPerPageActive);
          // let indexOfLastPro = currentPage * prosPerPageActive
          // setIndexOfLastPro(currentPage * prosPerPageActive);
          // setIndexOfFirstPro(indexOfLastPro)


          // setTotalPages(Math.ceil(pros?.length / prosPerPageActive));
        })
        .catch((err) => {
          console.log(err);
        });
      loaded.current = true;
    }
  }, [refresh, windowSize, currentPage]);



  const [serverMessage, setServerMessage] = useState({
    color: "none",
    message: "",
  });

  const handleDelete = () => {
    sqlService
      .deletePro({ user: user, input: currentPro })
      .then((data) => {
        loaded.current = false;
        setRefresh(!refresh);
      })
      .catch((err) => { });
  };

  // to add a new task in the task table
  function submitNewTask() {
    console.log(newTask)

    sqlService
      .addProfessional({ user: user, input: newTask })
      .then((data) => {
        setServerMessage({
          color: "green",
          message: "Pro is Added Successfully",
        });
        setTimeout(() => {
          setServerMessage({ color: "none", message: "" });
        }, 3000);
        loaded.current = false;
        setRefresh(!refresh);
        resetProValues();
        setTaskModal(false)
        setMessageModal(true)
      })
      .catch((err) => {
        setServerMessage({ color: "red", message: "Something Went Wrong" });
        setTimeout(() => {
          setServerMessage({ color: "none", message: "" });
        }, 3000);
      });
  }

  //to update an existing task in the task table in sql
  // function updateTask() {
  //   sqlService
  //     .updateProfessional({
  //       user: user,
  //       input: {
  //         id: editingRow,
  //         cell: editingCell,
  //         data: valueOnEdit,
  //       },
  //     })
  //     .then((data) => {
  //       setEditingCell(null);
  //       setEditingRow(null);
  //       loaded.current = false;
  //       setRefresh(true);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  function editPro() {
    sqlService
      .updateProfessional({
        user: user,
        input: { newVals: newTask, pro: currentPro.id },
      })
      .then((data) => {
        setServerMessage({
          color: "green",
          message: "Pro has been updated",
        });
        setTimeout(() => {
          setServerMessage({ color: "none", message: "" });
        }, 3000);
        loaded.current = false;
        setRefresh(!refresh);
        handleProEditClose()
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const onAdd = () => {
    setTaskModal(true);
    setRefresh(!refresh);
  };

  const onSearchBarChange = (e) => {
    setSearchBarValue(e.currentTarget.value);
    onSearch(e.currentTarget.value)
  };

  const onSearch = (value) => {
    if ((!value && searchBarValue === "") || (value === "")) {
      setFilteredProfessionals(prof.current);
    } else {
      let filteredPros = searchUtility.filterBySearch(
        prof.current,
        value ?? searchBarValue
      );
      setFilteredProfessionals(filteredPros);
    }
  };

  const prof = useRef([]);


  {
    /*pagination code below */
  }


  const nextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    currentPageRef.current = Math.min(currentPageRef.current + 1, totalPages)
  };

  const prevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
    currentPageRef.current = Math.min(currentPageRef.current - 1, 1)
  };

  const [currentPros, setCurrentPros] = useState([]);
  const [indexOfFirstPro, setIndexOfFirstPro] = useState(0);
  const [indexOfLastPro, setIndexOfLastPro] = useState(0);

  const [show, setShow] = useState(false);
  const [starMessage, setStarMessage] = useState('');
  const [hintY, setHintY] = useState(0);


  useEffect(() => {
    setCurrentPage(currentPageRef.current);
    setCurrentPros(filteredProfessionals?.slice(indexOfFirstPro, indexOfLastPro));

  }, [filteredProfessionals])

  //const prosPerPageActive = windowSize > 850 ? prosPerPage : prosPerPageMobile;
  //const indexOfLastPro = currentPage * prosPerPageActive;
  //const indexOfFirstPro = indexOfLastPro - prosPerPageActive;
  //const currentPros = filteredProfessionals.slice(indexOfFirstPro, indexOfLastPro);

  // Stuff for importing pros

  const [importMenu, setImportMenu] = useState(false);
  const [taskModalHidden, setTaskModalHidden] = useState(false);

  const openImportMenu = () => {
    setTaskModalHidden(true)
    setImportMenu(true)
  }

  const closeImportMenu = () => {
    setImportMenu(false)
  }

  const refreshPros = () => {
    loaded.current = false;
    setRefresh(!refresh);


  }

  // invite button stuff


  const inviteText = "I'm inviting you to join Honeyydo as my recommended pro. As one of my verified pros, you will appear to my clients when they search for professionals in their area.\n\nSimply visit the page below and fill out your information to get started."
  // const inviteUrl = `${process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_BASE_DOMAIN : process.env.REACT_APP_ENV === "staging" ? process.env.REACT_APP_BASE_DOMAIN_STAGING : process.env.REACT_APP_BASE_DOMAIN_LOCAL}/pro-signup/${user?.id}`
  const inviteUrl = `https://www.honeyydo.com/#/pro-signup/basic/${user?.id}/basicPlan`
  const [copyMessage, setCopyMessage] = useState("")


  async function share() {

    try {

      await navigator.share({ "text": inviteText, "title": "Reach more clients by joining Honeyydo", "url": inviteUrl });
    } catch (err) {
      setCopyMessage(`Error: ${err}`);
    }

  }

  function copyLink() {
    var copyText = document.getElementById("inviteLink");
    copyText.select();
    copyText.setSelectionRange(0, 99999);

    navigator.clipboard.writeText(copyText.value);

    setCopyMessage("Copied to clipboard!");
    setTimeout(() => {
      setCopyMessage();
    }, 3000);
  }


  const handleScrollingTable = (e) => {
    let element = e.target
    let pageNumber = Math.round(element.scrollTop / (element.clientHeight - 100)) + 1;
    setScrollCurrentPage(pageNumber);
    if (element.scrollHeight - Math.round(element.scrollTop) <= element.clientHeight) {
      setScrollCurrentPage(scrollTotalPages);
    }
  }


  useEffect(() => {
    let element = document.getElementById('tableScrollElementHoneyydoClient');
    if (element) {
      let totalPages = Math.round(element?.scrollHeight / (element?.clientHeight - 100));
      console.log(totalPages, element.scrollHeight, element.clientHeight)
      element.scrollTo({ top: 0 })
      setScrollCurrentPage(1);
      setScrollTotalPages(totalPages)
    }
  }, [windowSize, prof])


  return (
    <div className="align-items-center display-flex flex-direction-column margin-top-20-px">
      <SubHeader
        pageName={"Manage Pros"}
        addbutton={onAdd}
        buttonName={"Add a Pro"}
        showSearchBar={true}
        onSearchBarChange={onSearchBarChange}
        searchBarValue={searchBarValue}
        searchBarPlaceHolder={"Search Pros"}
        onSearch={onSearch}

        importLabel={'Import Pros'}
        openImportMenu={openImportMenu}
        importMenu={importMenu}
        importType={"pros"}
        setImportMenu={setImportMenu}
        onImport={refreshPros}
      />
      <>
        {prof.current.length ?

          windowSize > 1000 ? (
            <div id="tableScrollElementHoneyydoClient" className="Table-List-Container " onScroll={handleScrollingTable}>
              <table className="Uni-Table-Container ">
                <thead className="Uni-Table-Header">
                  <tr>
                    <th>Name</th>
                    <th>Industry</th>
                    <th>Company</th>
                    <th>Phone</th>
                    <th>State</th>
                    <th>City</th>
                    <th>Address</th>
                    <th>Email</th>
                    <th>Website</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="Uni-Table-Body">
                  <div style={{ top: hintY }} className={`star-hint border-radius-10-px ${show ? 'visibility-visible' : 'visibility-hidden'}`}>
                    <span>{starMessage}</span>
                  </div>
                  {filteredProfessionals?.length > 0
                    ? filteredProfessionals.map((task, index) => (
                      <tr key={task.id}  >

                        <td>
                          <FontAwesomeIcon
                            className={`fa-sm padding-right-10-px ${task.admin_verified === 1 ? 'orange' : 'star-blue'}`}
                            icon={faStar}
                            onMouseEnter={(e) => {
                              setShow(true);
                              setHintY(e.pageY - 40);
                              setStarMessage(task.admin_verified === 1 ? 'Verified' : 'Not Verified')
                            }}

                            onMouseLeave={() => setShow(false)}

                          />

                          {task.contact_name}
                        </td>
                        <td>{task.industry_name === "Other" ? task.sub_industry_name : task.industry_name}</td>
                        <td>{task.company_name}</td>
                        <td>{task.phone_number}</td>
                        <td>{task.state}</td>
                        <td>{task.city}</td>
                        <td>{task.address}</td>
                        <td>{task.email}</td>
                        <td>{task.website}</td>
                        <td>
                          <div className="width-50-px">
                            <span className="float-right" >
                              <FontAwesomeIcon
                                className="hover-icon grey margin-right-20-px cursor-pointer"
                                icon={faEdit}
                                onClick={() => { setCurrentPro(task); setProEditModal(true) }}
                              />
                              <FontAwesomeIcon
                                className="hover-icon-red grey cursor-pointer"
                                icon={faTrash}
                                onClick={() => { setCurrentPro(task); setDeleteModal(true) }}
                              />

                            </span>
                          </div>
                        </td>
                      </tr>
                    ))
                    : null}
                </tbody>
              </table>
            </div>
          ) : (
            <> {/*mobile view */}
              {windowSize < 850 ?
                <div className="mobile-page-name-container">
                  <h2 className="-mobile-page-name grey">Manage Pros</h2>
                </div>
                : null}
              <div id="tableScrollElementHoneyydoClient" className="Table-List-Container " onScroll={handleScrollingTable}>
                <div className="mobile-manageapros-container">
                  {filteredProfessionals?.length > 0
                    ? filteredProfessionals.map((task, index) => (
                      <div className="mobile-pro-container" key={index}>
                        <div className="icon-room-title-mobile">
                          <div>
                            <FontAwesomeIcon
                              icon={faCircleUser}
                              className="faBoxArchive"
                            />
                          </div>
                          <div className="mobile-room-title">
                            {task.company_name} - {task.industry_name === "Other" ? task.sub_industry_name : task.industry_name}
                          </div>
                        </div>
                        <div className="mobile-pro-info-container">
                          <div className="mobile-info-name">
                            {/* <FontAwesomeIcon
                            icon={faUser}
                            className="faPro-mobile-icons"
                          /> */}
                            <div className="mobile-pro-name grey">
                              {task.contact_name}
                            </div>
                          </div>
                          <div className="mobile-pro-number-address-container">
                            {task.phone_number ?
                              <div className="mobile-info">
                                <FontAwesomeIcon
                                  icon={faPhone}
                                  className="faPro-mobile-icons"
                                />
                                <div className="mobile-pro-number">
                                  {task.phone_number}
                                </div>
                              </div>
                              : null
                            }
                            {task.address || task.city || task.state ?
                              <div className="mobile-info">
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  className="faPro-mobile-icons"
                                />

                                <div className="mobile-pro-address">
                                  <span>{`${task.address ? task.address + ',' : ''} ${task.city ? task.city + ',' : ''} ${task.state ?? ''} `}

                                  </span>
                                </div>

                              </div>
                              : null
                            }
                          </div>

                          <div className="mobile-pro-email-site">
                            {task.email ?
                              <div className="mobile-info">
                                <FontAwesomeIcon
                                  icon={faEnvelope}
                                  className="faPro-mobile-icons"
                                />
                                <div className="mobile-pro-email">{task.email}</div>
                              </div>
                              : null
                            }
                            {task.website ?
                              <div className="mobile-info">
                                <FontAwesomeIcon
                                  icon={faGlobe}
                                  className="faPro-mobile-icons"
                                />
                                <div className="mobile-pro-website">
                                  {task.website}
                                </div>
                              </div>
                              : null
                            }
                          </div>
                        </div>
                        <div className="manageapro-container-footer">
                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            className="faPro-mobile-icons cursor-pointer"
                            onClick={() => (setCurrentPro(task), setProEditModal(true))}
                          />
                          <span
                            className="border-radius-5-px cursor-pointer hover-blue"
                            onClick={() => {
                              setCurrentPro(task);
                              setDeleteModal(true);
                            }}
                          >
                            <RiDeleteBinLine />
                          </span>
                        </div>
                      </div>
                    ))
                    : null}
                </div>
              </div>
            </>
          )


          :
          <div className="Empty-Message">
            <h3>You haven't added any pros yet</h3>
            <p>Click "Add a Pro" to get started referring your trusted home professionals to your clients</p>
          </div>
        }
      </>
      {filteredProfessionals?.length ?

        <div className="center-wrapper margin-top-10-px">
          <TableFooter
            currentPage={scrollCurrentPage}
            totalPages={scrollTotalPages}
            nextPage={() => {
              let element = document.getElementById("tableScrollElementHoneyydoClient");
              if (element) {
                element.scrollBy({
                  top: element.clientHeight - 100,
                  behavior: "smooth",
                })
              }

            }}
            prevPage={() => {
              let element = document.getElementById("tableScrollElementHoneyydoClient");
              if (element) {
                element.scrollBy({
                  top: - (element.clientHeight - 100),
                  behavior: "smooth",
                })
              }
            }}
          />
        </div>
        : null}
      <div className="Options-Bar display-flex ">
        <button onClick={() => setInvitePopup(true)}>Invite a Pro</button>



      </div>
      <ScrollToTop />

      {invitePopup ?
        <>
          <div className="backdrop"></div>
          <div style={{ width: windowSize > 550 ? '500px' : '90%' }} className="popup">

            <h5 className="grey">Invite a Pro</h5>
            <span style={{ padding: '20px 0' }}>Send a personalized invitation link to your trusted home maintenance professionals to invite them to join. Once they submit their information and pass our screening process, we will add them to our database to be shown to your homeowners.</span>

            <textarea id='inviteLink' disable style={{ height: '300px', width: '100%' }} className="Input-Style"
              value={inviteText + '\n\n' + inviteUrl}>
            </textarea>

            <div style={{ alignContent: 'start', alignItems: 'start', width: '100%' }}>

              <div style={{ columnGap: '15px', paddingTop: '10px', alignContent: 'center' }} className="display-flex">
                <div className="cursor-pointer display-flex flex-direction-column height-fit-content width-fit-content row-gap-5-px" onClick={copyLink}>
                  <span>
                    <FontAwesomeIcon
                      className="orange fa-2x"
                      icon={faCopy}
                    />
                  </span>
                  <span style={{ fontSize: '.85em' }} className="grey" >Copy</span>



                </div>

                <div className="cursor-pointer display-flex flex-direction-column height-fit-content width-fit-content row-gap-5-px" onClick={share}>
                  <span>
                    <FontAwesomeIcon

                      className={`orange fa-2x`}
                      icon={faShare}
                    />
                  </span>

                  <span style={{ fontSize: '.85em' }} className="grey" >Share</span>

                </div>
                <span className="grey">{copyMessage}</span>
              </div>


            </div>

            <button className="Cancel-Button margin-top-30-px" onClick={() => setInvitePopup(false)}>Close</button>
          </div>
        </> : null}

      {messageModal ?
        <>
          <div className="backdrop"></div>
          <div className="popup">
            <h5 className="grey">Thanks for submitting a pro!</h5>
            <span style={{ padding: '10px 20px 30px 20px', textAlign: 'center' }}>Once our admins verify your recommended pro, they will show up in your homeowner's search results</span>
            <button onClick={() => setMessageModal(false)} className="Cancel-Button">Close</button>
          </div>
        </> : null}


      {taskModal ?
        <InputModal
          inputValues={proValues}
          show={taskModal}
          hide={handleClose1}
          title="Add a Pro"
          aggFunction={updateNewTask}
          submit={submitNewTask}
          serverMessage={serverMessage}
          agg={newTask}
          additionalAddresses={true}
          stateWideOptions={true}
          user={user}
          setMultipleAggValues={setNewTask}
        />
        : null
      }

      {proEditModal ?
        <InputModal
          inputValues={proValues}
          show={proEditModal}
          hide={handleProEditClose}
          title="Edit Pro"
          aggFunction={updateNewTask}
          submit={editPro}
          serverMessage={serverMessage}
          agg={newTask}
          filledval={currentPro}
          additionalAddresses={true}
          stateWideOptions={true}
          user={user}
          setMultipleAggValues={setNewTask}
        />
        :
        null
      }
      <Modal


        show={deleteModal}
        onHide={() => setDeleteModal(false)}


        backdrop="static"
        keyboard={false}
        size="sm"
        style={{ top: '15%' }}
        focus={true}


      >
        <ModalBody >
          <p className="padding-10-15">Are you sure you want to delete?</p>
          <div className="display-flex justify-content-center">
            <span
              className="margin-right-10-px"
              onClick={() => {


                handleDelete();
                setDeleteModal(false);
                // setDeleteAll(false);


              }}
            >
              <button className="Tasklist-Button height-40-px red">
                Delete
              </button>
            </span>
            <Button variant="secondary height-40-px" onClick={() => setDeleteModal(false)}>
              Cancel
            </Button>
          </div>



        </ModalBody>
        {/* {current.repeating || current.universal ?
  <ModalFooter className="Delete-Footer">
    {current.universal ?
      <HoverHint
        hint={'If you change your mind, you can add Honeyydo tasks back under preferences in settings'}
        modal={true}
      />
      : null
    }
    <p >
      Delete all occurances?

    </p>
    <input className='margin-left-10-px'
      type='checkbox'
      onChange={() => setDeleteAll(!deleteAll)}
    />
  </ModalFooter>
  : null
} */}


      </Modal>
    </div>
  );
}
