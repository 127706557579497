
import React, { Component } from 'react';

function TOS()
{

    return (
        <div>
            <br /><h1 className="text-align-center grey margin-top-20-px">Terms of Service</h1>
            <div style={{padding: '0 5%', maxWidth: '1200px', margin: 'auto'}} className=''>
                <br /><br/>
                Effective Date: June 1st, 2023
                <br /><br /><br />
                Please read these Terms of Service carefully before using the Honeyydo website. Honeyydo is operated by Honeyydo inc. These Terms govern your use of the Website and its services.
                <br /><br />
                By accessing or using the Website, you agree to be bound by these Terms. If you disagree with any part of the Terms, please refrain from using our services.
                <br /><br />
                <span className='bold'>1. Website Services</span> <br />
                Honeyydo provides an all-in-one toolkit for Homeowners and aims to generate leads for agents. The Website offers a variety of services, which can be found at https://honeyydo.com. Additionally, we plan to introduce a feature that allows users to send Ecards to clients as gifts alongside ordinary gift cards.
                <br /><br />
                <span className='bold'>2. User Accounts </span><br />
                To access certain features or services on the Website, users are required to create an account. You are responsible for maintaining the confidentiality of your account information, including your username and password. Any activities that occur under your account are your responsibility. Please ensure that the information provided during registration is accurate and up to date.
                <br /><br />
                <span className='bold'>3. User-Generated Content </span><br />
                Users may have the ability to submit or upload content to the Website. By submitting or uploading content, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, adapt, publish, distribute, and display the content in connection with our services. However, the content shared by users will only be visible to the intended recipients as specified by the user.
                <br /><br />
                <span className='bold'>4. Intellectual Property</span> <br />
                The content, features, and functionality available on the Website are owned by or licensed to us and are protected by intellectual property laws. All trademarks, service marks, logos, and trade names displayed on the Website are proprietary to us or their respective owners. Unauthorized use of any intellectual property on the Website is strictly prohibited.
                <br /><br />
                <span className='bold'>5. Privacy Policy</span> <br />
                We are committed to protecting your privacy. Please review our Privacy Policy, which is incorporated by reference into these Terms, to understand how we collect, use, and protect your personal information.
                <br /><br />
                <span className='bold'>6. Prohibited Activities</span> <br />
                You agree not to engage in any of the following activities while using the Website:
                <br /><br />
                Hacking, cracking, or otherwise attempting to gain unauthorized access to the Website or its servers. <br />
                Transmitting or distributing viruses, worms, or any other malicious code. <br />
                Spamming, including sending unsolicited communications or advertising. <br />
                Engaging in any illegal activities or encouraging others to do so. <br />
                Violating the intellectual property rights of others. <br />
                Interfering with the functionality of the Website or the experience of other users. <br /><br/>
                <span className='bold'>7. User Conduct</span> <br />
                You are expected to conduct yourself in a responsible and respectful manner while using the Website. This includes complying with all applicable laws and regulations and refraining from engaging in any activities that may disrupt the Website's operation or the experience of other users.
                <br /><br />
                <span className='bold'>8. Dispute Resolution </span><br />
                If any dispute arises between you and Honeyydo, we encourage you to reach out to our support team to seek resolution. We will make reasonable efforts to address your concerns promptly.
                <br /><br />
                <span className='bold'>9. Termination</span> <br />
                We reserve the right to terminate user accounts or suspend access to the Website at our discretion and without prior notice, including for violations of these Terms or for any other reason.
                <br /><br />
                <span className='bold'>10. Limitation of Liability</span> <br />
                To the maximum extent permitted by law, Honeyydo and its affiliates, officers, directors, employees, and agents shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with the use or inability to use the Website or its services.
                <br /><br />
                <span className='bold'>11. Governing Law</span> <br />
                These Terms shall be governed by and construed in accordance with the laws of the United States. Any legal action or proceeding arising out of or relating to these Terms shall be exclusively brought in the federal or state courts located in Toronto Ontario, and you consent to the personal jurisdiction of such courts.
                <br /><br />
                
            </div>
        </div>
    );
}

export default TOS;