import React, { useState, useEffect, useRef } from "react";
import "./profession.css";
import "../../utility.component/uniTable.css";
import PoweredBy from "../poweredby.component/poweredby.component";
import { useParams } from "react-router";
import sqlService from "../../../services/sqlService";
import SubHeader from "../../main.component/subHeader.component/subHeader.component";
import searchUtility from "../../../functions/searchUtility";
import TableFooter from "../../utility.component/tableFooter.component";
import TagsContainer from "../tagsContainer.component/tagsContainer.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faEnvelope,
  faPhone,
  faToolbox,
  faStar,
  faLocationDot
} from "@fortawesome/free-solid-svg-icons";

import { UserContext } from "../../../App";
import { useContext } from "react";
import sortUtility from "../../../functions/sortUtility";
import locationUtility from "../../../functions/locationUtility";
import Rating from 'react-rating';


export default function Profession() {
  const user = useContext(UserContext);
  const { profession } = useParams();

  const [professionFilter, setProfessionFilter] = useState(profession);
  const [professionals, setProfessionals] = useState([]);
  const [filteredProfessionals, setFilteredProfessionals] = useState([]);
  const [filteredProfessionalsByDistance, setFilteredProfessionalsByDistance] = useState([]);
  const [currentProfessionals, setCurrentProfessionals] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [couponPopup, setCouponPopup] = useState(null);
  const [ratingPopup, setRatingPopup] = useState(null)

  const professionsPerPage = 10;
  const [totalPages, setTotalPages] = useState(0);

  const [subIndustries, setSubIndustries] = useState([]);

  const [subIndustryFilter, setSubIndustryFilter] = useState("All");

  const [hintY, setHintY] = useState(0);
  const [starMessage, setStarMessage] = useState('');
  const [show, setShow] = useState(false);

  const [userCoordinates, setUserCoordiates] = useState((user && user?.lng && user?.lat) ? { lng: user.lng, lat: user.lat } : null);

  const [distanceRange, setDistanceRange] = useState(40);
  const MAX_PRO_DISTANCE = 100;
  const [refresh, setRefresh] = useState(false)

  const [scrollCurrentPage, setScrollCurrentPage] = useState(1)
  const [scrollTotalPages, setScrollTotalPages] = useState(1)


  const handleProfessionChange = (profession) => {
    setProfessionFilter(profession);
    setCurrentPage(1);
    setSubIndustryFilter('All');
  };

  function getLocation() {
    if (navigator.geolocation) {
      if ((!user?.lng || !user?.lat)) {
        navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
          if (permissionStatus.state === 'denied') {
            alert('Please allow location access.');
            window.location.href = "app-settings:location";
          } else {
            navigator.geolocation.getCurrentPosition(success => {
              setUserCoordiates({ "lng": success.coords.longitude, "lat": success.coords.latitude });
              sqlService.updateUserByField({ input: { "userId": user.id, "field": "lng", "value": success.coords.longitude } }).then(d => { }).catch(err => console.log(err));
              sqlService.updateUserByField({ input: { "userId": user.id, "field": "lat", "value": success.coords.latitude } }).then(d => { }).catch(err => console.log(err));
            }, error => {
              console.log(error)
            });
          }
        });
      }
    } else {
      alert('Geolocation is not supported in your browser.');
    }
  }

  useEffect(() => {
    getLocation()
    sqlService
      .getProfessionalsForHomeownerByIndustry({
        user: user,
        input: { industry_name: professionFilter },
      })
      .then(async (data) => {
        const pros = data.data;
        console.log(pros)

        let temp = sortUtility.sort('agent', pros, false);
        let temp2 = sortUtility.sort('plan_type', temp, false);
        let i = 0;
        while (temp2[i]?.plan_type === 2 && i < temp2?.length) {
          await sqlService.getProCoupons({ input: { id: temp2[i].id } }).then((d) => {
            temp2[i].coupons = d.data
            console.log(temp2[i])
          }).catch((err) => {
            console.log(err)
          })

          i++;
        }
        console.log(temp2)

        var prosEmails = {};
        var tmpNew = []
        for (let pr of temp2) {
          if (!((pr.nation_wide === 1 || pr.state_wide === 1) && prosEmails[pr.email])) {
            prosEmails[pr.email] = "1";
            tmpNew.push(pr);
          }
        }
        console.log(tmpNew)
        setProfessionals(tmpNew)
        // let subIndustrySet = new Set(filteredProfessionalsByDistance.map((pro) => pro.sub_industry_name));
        // let subs = ["All", ...Array.from(subIndustrySet)].filter(val => val)
        // setSubIndustries(subs);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [professionFilter, refresh]);

  useEffect(() => {
    let filtered = filteredProfessionalsByDistance.filter(
      (pro) => pro.industry_name === professionFilter
    );
    if (subIndustryFilter !== "All") {
      filtered = filtered.filter(
        (pro) => pro.sub_industry_name === subIndustryFilter
      );
    }
    setFilteredProfessionals(filtered);
    prof.current = filtered;
    // setTotalPages(Math.ceil(filtered.length / professionsPerPage));
  }, [filteredProfessionalsByDistance, professionFilter, subIndustryFilter]);

  // useEffect(()=> {
  //   let subIndustrySet = new Set(filteredProfessionalsByDistance.map((pro) => pro.sub_industry_name));
  //       let subs = ["All", ...Array.from(subIndustrySet)].filter(val => val)
  //       setSubIndustries(subs);
  // }, [filteredProfessionalsByDistance])



  useEffect(() => {


    let filteredByDistance = []
    for (let pro of professionals) {
      let distance = locationUtility.getDistanceFromLatLonInMile(pro?.lat, pro?.lng, userCoordinates?.lat, userCoordinates?.lng, user?.country === "Canada" ? "true" : null);
      if (distance <= distanceRange || pro.nation_wide && pro.country === user.country || pro.state_wide && pro.state === user.state) {
        pro["distance"] = pro.nation_wide || pro.state_wide ? '' : `${Math.round(distance)}${user?.country === "Canada" ? "km" : "mi"}`;
        filteredByDistance.push(pro)
      }
    }
    let subIndustrySet = new Set(filteredByDistance.map((pro) => pro.sub_industry_name));
    let subs = ["All", ...Array.from(subIndustrySet)].filter(val => val)
    setSubIndustries(subs);
    setFilteredProfessionalsByDistance(filteredByDistance);
    setTotalPages(Math.ceil(filteredByDistance.length / professionsPerPage));
  }, [professionals, distanceRange]);

  useEffect(() => {

    // const indexOfLastProf = currentPage * professionsPerPage;
    // const indexOfFirstProf = indexOfLastProf - professionsPerPage;
    // let temp = filteredProfessionals.slice(indexOfFirstProf, indexOfLastProf)
    let temp = filteredProfessionals
    let prem = temp.filter((p) => p.plan_type === 2)
    let basic = temp.filter((p) => p.plan_type === 1)

    // setCurrentProfessionals(
    //   filteredProfessionals.slice(indexOfFirstProf, indexOfLastProf)
    // );
    setCurrentProfessionals([prem, basic])
  }, [filteredProfessionals, currentPage]);

  const nextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const [windowSize, setWindowSize] = React.useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  const onWindowResize = () => {
    setWindowSize(window.innerWidth);
  };

  {
    /*search bar function */
  }

  const [searchBarValue, setSearchBarValue] = useState("");

  const prof = useRef([]);

  const onSearchBarChange = (e) => {
    setSearchBarValue(e.currentTarget.value);
    onSearch(e.currentTarget.value)
  };

  const onSearch = (value) => {
    if ((!value && searchBarValue === "") || (value === "")) {
      // console.log("empty");
      setFilteredProfessionals(prof.current);
    } else {
      let filteredPros = searchUtility.filterBySearch(
        prof.current,
        value ?? searchBarValue
      );
      setFilteredProfessionals(filteredPros);
      setTotalPages(Math.ceil(filteredPros.length / professionsPerPage));
    }
  };

  const handleSubIndustryClick = (subIndustry) => {
    setSubIndustryFilter(subIndustry === "All" ? "" : subIndustry);
    setCurrentPage(1);
  };

  const handleTagClick = (tag) => {
    setSubIndustryFilter(tag);
    setCurrentPage(1);
  };


  const [proRating, setProRating] = useState(null)

  function submitRating() {
    sqlService.submitProRating({ user: user, input: { rating: proRating, pro_id: ratingPopup.id } }).then((d) => {
      setRatingPopup(null)
      setProRating(null)
      setRefresh(!refresh)

    }).catch((err) => {

    })

  }

  const handleScrollingTable = (e) => {
    let element = e.target
    let pageNumber = Math.round(element.scrollTop / (element.clientHeight - 100)) + 1;
    setScrollCurrentPage(pageNumber);
    if (element.scrollHeight - Math.round(element.scrollTop) <= element.clientHeight) {
      setScrollCurrentPage(scrollTotalPages);
    }
  }


  useEffect(() => {
    let element = document.getElementById('tableScrollElementHoneyydoClient');
    if (element) {
      let totalPages = Math.round(element?.scrollHeight / (element?.clientHeight - 100));
      console.log(totalPages, element.scrollHeight, element.clientHeight)
      element.scrollTo({ top: 0 })
      setScrollCurrentPage(1);
      setScrollTotalPages(totalPages)
    }
  }, [windowSize, currentProfessionals])

  return (
    <div className="align-items-center display-flex flex-direction-column ">
      <SubHeader
        pageName={
          windowSize > 850
            ? `Find a Pro > ${professionFilter.charAt(0).toUpperCase() +
            professionFilter.slice(1)
            }`
            : "Find a Pro"
          // : `${
          //     professionFilter.charAt(0).toUpperCase() +
          //     professionFilter.slice(1)
          //   }`
        }
        showSearchBar={true}
        showRangeSlider={true}
        onSearchBarChange={onSearchBarChange}
        searchBarValue={searchBarValue}
        searchBarPlaceHolder={"Search Pros"}
        onSearch={onSearch}
        rangeValue={distanceRange}
        setRangeValue={setDistanceRange}
        maxRangeValue={user?.country === "Canada" ? MAX_PRO_DISTANCE * 1.6 : MAX_PRO_DISTANCE}
      />
      <TagsContainer
        tags={subIndustries}
        onClick={handleTagClick}
        subIndustryFilter={subIndustryFilter}
        handleProfessionChange={handleProfessionChange}
        professionFilter={professionFilter}

      />
      <>

        {false ? (
          <>
            <div id="tableScrollElementHoneyydoClient" className="Table-List-Container" onScroll={handleScrollingTable}>
              <div className="Table-List-Container">
                <table className="Uni-Table-Container ">
                  <thead className="Uni-Table-Header">
                    <tr>
                      <th></th>
                      <th>Company</th>
                      <th>Sub-Industry</th>
                      <th>City</th>
                      <th>Contact</th>
                      <th>Email</th>
                      <th>Website</th>
                      <th>Distance</th>
                    </tr>
                  </thead>
                  <tbody className="Uni-Table-Body Mobile-Table-Body">
                    {currentProfessionals.map((pros, index) => (
                      <tr key={index} className="table-row">
                        <td>
                          <FontAwesomeIcon
                            className={`fa-sm padding-right-10-px ${pros.agent === 2 ? 'orange' : 'star-blue'}`}
                            icon={faStar}
                            onMouseEnter={(e) => {
                              setShow(true);
                              setHintY(e.pageY - 50);
                              setStarMessage(pros.agent === 2 ? 'Honeyydo verified pro' : 'Recommended by your agent')
                            }}

                            onMouseLeave={() => setShow(false)}
                          />
                          <div style={{ top: hintY }} className={`star-hint border-radius-10-px ${show ? 'visibility-visible' : 'visibility-hidden'}`}>
                            <span>{starMessage}</span>
                          </div>
                        </td>
                        <td>{pros.company_name}</td>
                        <td>{pros.sub_industry_name}</td>
                        <td>{pros.city}</td>
                        <td>{pros.contact_name}</td>
                        <td>
                          {pros.email ?
                            <a className="grey"
                              href={`mailto:${pros.email}`}
                              title={`Email: ${pros.email}`}
                            >
                              Email Pro
                            </a>
                            : null}
                        </td>
                        <td>
                          {pros.website ?
                            <a className="grey"
                              href={`https://${pros.website.replace(/^https?:\/\//, '')}`}
                              title={`https://${pros.website.replace(/^https?:\/\//, '')}`}
                            >
                              {pros.website.replace(/^https?:\/\//, '')}
                            </a>
                            : null
                          }
                        </td>
                        <td>{pros.distance}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {currentProfessionals?.length ?

              <div className="center-wrapper margin-top-10-px">
                <TableFooter
                  currentPage={scrollCurrentPage}
                  totalPages={scrollTotalPages}
                  nextPage={() => {
                    let element = document.getElementById("tableScrollElementHoneyydoClient");
                    if (element) {
                      element.scrollBy({
                        top: element.clientHeight - 100,
                        behavior: "smooth",
                      })
                    }

                  }}
                  prevPage={() => {
                    let element = document.getElementById("tableScrollElementHoneyydoClient");
                    if (element) {
                      element.scrollBy({
                        top: - (element.clientHeight - 100),
                        behavior: "smooth",
                      })
                    }
                  }}
                />
              </div>
              : null}
            <h5 className="grey margin-top-30-px">Check back soon, we are always adding more pros!</h5>
          </>
        ) : currentProfessionals.length ? (
          <>
            <div id="tableScrollElementHoneyydoClient" className="Table-List-Container padding-10-px" onScroll={handleScrollingTable}>
              {currentProfessionals.map((list, index) => (
                <div className={`mobile-profs-container ${index == 0 ? 'mobile-premium-profs-container' : ''}`} >
                  {/* <h2 className="mobile-pros-page-name grey">Pros</h2> */}

                  {list.map((pros, index) => (
                    <div className={`mobile-prof-container ${pros.plan_type === 2 ? 'min-height-345-px' : ''}`} key={index}>
                      <div className="mobile-pros-header">
                        <div className="mobile-pro-icon-industry align-content-center display-flex column-gap-5-px">
                          <FontAwesomeIcon
                            style={{ alignSelf: 'center' }}
                            className="mobile-pros-toolbox"
                            icon={faToolbox}
                          />
                          <div style={{ alignSelf: 'center' }} className="mobile-pros-industry-name">
                            {pros.sub_industry_name}
                          </div>
                        </div>
                        {pros.coupons?.length ?

                          <div className="pro-coupon-preview box-shadow-2" onClick={() => setCouponPopup(pros)}>
                            {/* {pros.sub_industry_name} */}
                            Promotion available! Click to view

                          </div> : null}
                      </div>

                      <div className="mobile-pro-info">

                        <div className="mobile-pros-company-name grey margin-bottom-20-px">
                          {pros.company_name}
                        </div>

                        {pros.logo_link?.length ? <div className="prof-grid">
                          <div style={{ height: '100px', width: '100px' }} className="pro-logo"><img className="object-fit-cover height-width-100-pt" src={pros.logo_link} /></div>
                          <div>
                            <div className="mobile-pros-contact-name">
                              {pros.contact_name}
                            </div>
                            {pros.city || pros.state ?
                              <div className="mobile-pros-city">
                                <span>{`${pros.city ? pros.city + ',' : ''} ${pros.state ?? ''} `}</span>
                              </div>
                              : null
                            }
                            {pros.address ?
                              <div className="pros-number">
                                <FontAwesomeIcon icon={faLocationDot} className="faPros" />
                                {pros.address} {pros.distance ? `(${pros.distance})` : ''}
                              </div> : null
                            }

                            {pros.phone_number ?
                              <div className="pros-number">
                                <FontAwesomeIcon icon={faPhone} className="faPros" />
                                {pros.phone_number}
                              </div>
                              : null
                            }
                            <div className="pro-source">
                              <FontAwesomeIcon
                                icon={faStar}
                                className={`fa-sm padding-right-5-px ${pros.agent === 2 ? 'orange' : 'star-blue'}`} />
                              {pros.agent === 2 ? 'Honeyydo certified' : 'Recommended by your agent'}

                            </div>
                            {pros.avg_rating ?
                              <div className="display-flex column-gap-5-px">
                                <span className="grey bold">{pros.avg_rating}</span>
                                <Rating style={{ fontSize: '.5em' }} initialRating={pros.avg_rating} readonly={true} emptySymbol={` bi-hexagon fa-2x  star-grey `} fullSymbol={` bi-hexagon-fill fa-2x logo-orange`} />
                                <span style={{ fontSize: '.8em', color: 'grey' }}>({pros.rating_count} rating{pros.rating_count > 1 ? 's' : ''})</span>
                              </div> : null}
                          </div>

                        </div> : <>
                          <div className="mobile-pros-contact-name">
                            {pros.contact_name}
                          </div>
                          {
                            pros?.nation_wide === 1 ?
                              <div className="mobile-pros-city">
                                <FontAwesomeIcon icon={faLocationDot} className="faPros" />
                                <span className="Profession-Outstanding-Flag">{`Nation Wide`}</span>
                              </div>
                              : pros?.state_wide === 1 ?
                                <div className="mobile-pros-city">
                                  <FontAwesomeIcon icon={faLocationDot} className="faPros" />
                                  <span className="Profession-Outstanding-Flag">{`State Wide`}</span>
                                </div> :
                                <>
                                  {pros.city || pros.state ?
                                    <div className="mobile-pros-city">
                                      <span>{`${pros.city ? pros.city + ',' : ''} ${pros.state ?? ''} `}</span>
                                    </div>
                                    : null
                                  }
                                  {pros.address ?
                                    <div className="pros-number">
                                      <FontAwesomeIcon icon={faLocationDot} className="faPros" />
                                      {pros.address} {pros.distance ? `(${pros.distance})` : ''}
                                    </div> : null
                                  }
                                </>
                          }


                          {pros.phone_number ?
                            <div className="pros-number">
                              <FontAwesomeIcon icon={faPhone} className="faPros" />
                              {pros.phone_number}
                            </div>
                            : null
                          }
                          <div className="pro-source">
                            <FontAwesomeIcon
                              icon={faStar}
                              className={`fa-sm padding-right-5-px ${pros.agent === 2 ? 'orange' : 'star-blue'}`} />
                            {pros.agent === 2 ? 'Honeyydo certified' : 'Recommended by your agent'}

                          </div>
                          {pros.avg_rating ?
                            <div className="display-flex column-gap-5-px">
                              <span className="grey bold">{pros.avg_rating}</span>
                              <Rating style={{ fontSize: '.5em' }} initialRating={pros.avg_rating} readonly={true} emptySymbol={` bi-hexagon fa-2x  star-grey `} fullSymbol={` bi-hexagon-fill fa-2x logo-orange`} />
                              <span style={{ fontSize: '.8em', color: 'grey' }}>({pros.rating_count} rating{pros.rating_count > 1 ? 's' : ''})</span>
                            </div> : null}
                        </>}
                        <div onClick={() => { setRatingPopup(pros); setProRating(pros.rating) }} className="pro-coupon-preview rate-pro-button"><span>Rate this pro</span></div>
                        {/* <div className="pros-website">
                    <a
                      href={`http://${pros.website}`}
                      title={`http://${pros.website}`}
                    >
                      <FontAwesomeIcon icon={faGlobe} className="faPhone" />
                      Website
                    </a>
                  </div> */}
                      </div>
                      <div className="mobile-pros-footer">
                        {pros.email ?
                          <div className="mobile-pros-email">
                            <FontAwesomeIcon icon={faEnvelope} className="faPros" />
                            <a className="grey"
                              href={`mailto:${pros.email}`}
                              title={`Email: ${pros.email}`}
                            >
                              {pros.email}
                            </a>
                          </div>
                          : null}

                        {pros.website ?
                          <div className="mobile-pros-website">
                            <a className="grey"
                              href={`http://${pros.website}`}
                              title={`http://${pros.website}`}
                            >
                              <FontAwesomeIcon icon={faGlobe} className="faPhone" />
                              Website
                            </a>
                          </div>
                          : null
                        }
                      </div>
                    </div>

                  ))}
                </div>
              ))}
            </div>
            {currentProfessionals?.length ?
              <div className="center-wrapper margin-top-10-px">
                <TableFooter
                  currentPage={scrollCurrentPage}
                  totalPages={scrollTotalPages}
                  nextPage={() => {
                    let element = document.getElementById("tableScrollElementHoneyydoClient");
                    if (element) {
                      element.scrollBy({
                        top: element.clientHeight - 100,
                        behavior: "smooth",
                      })
                    }

                  }}
                  prevPage={() => {
                    let element = document.getElementById("tableScrollElementHoneyydoClient");
                    if (element) {
                      element.scrollBy({
                        top: - (element.clientHeight - 100),
                        behavior: "smooth",
                      })
                    }
                  }}
                />
              </div>
              : null}
            <h5 className="grey text-align-center" style={{ padding: '0 10%', margin: '50px 0' }}>Check back soon, we are always adding more pros!</h5>
          </>

        )
          :
          <div className="Empty-Message">
            <h3>There are no pros available yet in your area</h3>
            <p>Check back soon, we are always adding more pros!</p>
          </div>
        }
      </>
      <PoweredBy />
      {couponPopup ?
        <>
          <div className="backdrop" />
          <div style={{ padding: '40px' }} className="popup min-width-300-px min-height-220-px">
            <h5 className="grey">Current Promotions for {couponPopup.company_name}</h5>
            <div className="display-flex column-gap-15-px margin-top-20-px margin-bottom-30-px">
              {couponPopup.coupons?.map((coupon) =>
                <div className="Coupon">
                  <h5 className="grey">{coupon.name}</h5>

                  <span className=" bold" style={{ color: '#a1a1a1' }}  >{coupon.description}</span>

                </div>
              )}
            </div>
            <span className="bold margin-bottom-30-px" style={{ color: '#a1a1a1' }} >Mention the coupon code to the vendor to claim your discount!</span>
            <button className="popup-submit-button margin-top-auto" onClick={() => setCouponPopup(null)}>Close</button>
          </div>
        </> : null}
      {ratingPopup ?
        <>
          <div className="backdrop" />
          <div style={{ padding: '40px', position: 'fixed' }} className="popup min-width-300-px min-height-220-px">
            <h5 className="grey">Did you use the services of {ratingPopup.company_name}?</h5>
            <div className="display-flex flex-direction-column align-items-center margin-top-20-px margin-bottom-30-px">
              <span className="bold margin-bottom-30-px" style={{ color: '#a1a1a1' }} >Help out this pro and your fellow homeowners by submitting a rating:</span>
              <Rating
                initialRating={proRating}
                onClick={(r) => { setProRating(r) }}

                emptySymbol={` bi-hexagon fa-2x  star-grey ${user?.role_id === 1 ? 'star-animate' : ''}`}
                fullSymbol={` bi-hexagon-fill fa-2x logo-orange ${user?.role_id === 1 ? 'star-animate' : ''}`}
                fractions={2}

              />

            </div>
            <div className="display-flex column-gap-10-px margin-top-auto">
              {/* <span className="bold margin-bottom-30-px" style={{ color: '#a1a1a1' }} >Mention the coupon code to the vendor to claim your discount!</span> */}
              <button className="Cancel-Button " onClick={() => setRatingPopup(null)}>Close</button>
              <button className="popup-submit-button " onClick={() => submitRating()}>{ratingPopup.rating ? 'Update Rating' : 'Submit Rating'}</button>
            </div>
          </div>
        </> : null}
    </div>
  );
}
