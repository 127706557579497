import React, { useEffect, useState, useRef } from "react";
import sqlEcardsService from "../../../services/sqlEcardService";
import { Link } from "react-router-dom";
import { UserContext } from "../../../App";
import { useContext } from "react";


    
function EcardsComingSoon() {
   

    return(
        <div className="width-100% display-flex justify-content-center align-items-center height-100-px">
            <span className="padding-10-px background1 border-radius-5-px">
                Ecards page is coming soon.
            </span>
        </div>
        
    )
    
}

export default EcardsComingSoon;


