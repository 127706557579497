import React, { useEffect, useState, useRef } from "react";

import "./settings.component.css";
// import facebook from "../../../assets/images/Icons/Facebook-removebg.png";
// import google from "../../../assets/images/Icons/Google-removebg.png";
// import zillow from "../../../assets/images/Icons/Zillow-removebg.png";
// import realtor from "../../../assets/images/Icons/Realtor-removebg.png";
// import instagram from "../../../assets/images/Icons/Instagram-removebg.png";
// import website from "../../../assets/images/Icons/Internet-removebg.png";
// import Placeholder from '../../../assets/images/Placeholder-removebg.png';
import Placeholder2 from '../../../assets/images/Placeholder2-removebg.png';

import sqlService from "../../../services/sqlService";

import { UserContext } from "../../../App";
import { useContext } from "react";
import Cookies from "universal-cookie";
import Spinner from 'react-bootstrap/Spinner';
import StarRating from "../../utility.component/rating.component/rating.component";
import MultipleSelection from "../../utility.component/multipleSelection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight, faCheck, faCircleCheck, faCreditCard, faEnvelope, faGear, faKey, faLink, faPen, faReceipt, faStar, faUser } from "@fortawesome/free-solid-svg-icons";
import { SiZillow } from "react-icons/si";
import {
  faFacebook,
  faInstagram,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";
import { Button, Modal, ModalBody } from "react-bootstrap";
import { ImCheckmark } from "react-icons/im";
import inputConstants from "../../../constants/inputConstants";
import serverResponseMessagesConstants from "../../../constants/serverResponseMessagesConstants";
import PopupMessage from "../../utility.component/popupMessage.component/popupMessage.component";
import stripeService from "../../../services/stripeService";

import { BiRadioCircleMarked, BiRadioCircle } from "react-icons/bi";
import { RiVisaLine, RiMastercardFill } from "react-icons/ri";
import { SiAmericanexpress } from "react-icons/si";
import Card from "../addPayment.component/components/card";
import HoverHint from "../../utility.component/hoverHint.component/hoverHint.component";
import { FcCancel } from "react-icons/fc";
import validateUtility from "../../../functions/validateUtility";
import GoogleAutoComplete from "../../utility.component/googleAutoComplete.omponent";
import timeUtility from "../../../functions/timeUtility";
import calcUtility from "../../../functions/calcUtility";
import emailCategories from "../../../constants/emailCategoriesConstants";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import html2pdf from 'html2pdf.js';

function ReviewPopup({ site, onClose, onSubmit, reviewLink }) {
  const [link, setLink] = useState(reviewLink);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(site, link);
    setLink('');
  };

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <h2 className='popup-heading grey'>Enter {site} Link</h2>
        <form onSubmit={handleSubmit}>
          <input className='popup-input' type="url" placeholder="https://site.com/username" value={link} onChange={(e) => setLink(e.target.value)} />
          <div className="popup-buttons">
            <button className="popup-close-button" type="button" onClick={onClose}>Cancel</button>
            <button className="popup-submit-button" type="submit">Submit</button>
          </div>
        </form>
        {reviewLink && (
          <div className="review-link">
            Link: <a href={reviewLink} target="_blank" rel="noopener noreferrer">{reviewLink}</a>
          </div>
        )}
      </div>
    </div>
  );
}


function Settings({ setUser }) {
  const user = useContext(UserContext);
  const requiredVals = ["contact_name", "email", "address", "city", "zip_code", "phone_number", "state"]

  const [selectedArea, setSelectedArea] = useState('');
  const [profileFileObject, setProfileFileObject] = useState(null);
  const [logoFileObject, setLogoFileObject] = useState(null);
  const profileImgEdit = useRef(null)
  const logoImgEdit = useRef(null)

  const [profileImage, setProfile] = useState(null);
  const [logoImage, setLogo] = useState(null);

  const [newUser, setNewUser] = useState(user);

  const [contactInfoDropdown, setContactInfoDropdown] = useState(true)
  const [brokerageInfoDropdown, setBrokerageInfoDropdown] = useState(false)
  const [imageDropdown, setImageDropdown] = useState(false)
  const [professionalInfoDropdown, setProfessionalInfoDropdown] = useState(false);
  const [addressDropdown, setAddressDropdown] = useState(false)
  const [stripeMessage, setStripeMessage] = useState(null)
  const [popUp2, setPopUp2] = useState({})
  const [showPopUp2, setShowPopUp2] = useState(false)
  const subscriptionsLoaded = useRef(false)
  const [subscriptions, setSubscriptions] = useState(null)
  const [subTypes, setSubTypes] = useState({})
  const [submitted, setSubmitted] = useState(false)


  const cardTypesIcons = {
    "visa": <RiVisaLine />,
    "mastercard": <RiMastercardFill />,
    "amex": <SiAmericanexpress />
  }

  const bannerImgs = ['https://honeyydo.s3.us-east-2.amazonaws.com/other/EmailBrandingBackground/House1-min.jpg',
    'https://honeyydo.s3.us-east-2.amazonaws.com/other/EmailBrandingBackground/House2-min.jpg',
    'https://honeyydo.s3.us-east-2.amazonaws.com/other/EmailBrandingBackground/House3-min.jpg',
    'https://honeyydo.s3.us-east-2.amazonaws.com/other/EmailBrandingBackground/House4-min.jpg',
    'https://honeyydo.s3.us-east-2.amazonaws.com/other/EmailBrandingBackground/House5-min.jpg'
]

const banner = useRef({})

useEffect(() => {

    banner.current = bannerImgs[Math.floor(Math.random() * bannerImgs?.length)]

}, [])



  const closePopUp2 = () => {
    setPopUp2({})
    setShowPopUp2(false)

  }

  const closeDropdowns = (dropdown) => {
    if (dropdown !== "brokerage") {
      setBrokerageInfoDropdown(false)
    }
    if (dropdown !== "name") {
      setContactInfoDropdown(false)
    }
    if (dropdown !== "image") {
      setImageDropdown(false)
    }
    if (dropdown !== "prof") {
      setProfessionalInfoDropdown(false)
    }
    if (dropdown !== "address") {
      setAddressDropdown(false)
    }
  }


  const onWindowResize = () => {
    setWindowSize(window.innerWidth);

  };

  const [addingNewPayment, setAddingNewPayment] = useState(false)

  const [show, setShow] = useState(false);
  const [updated, setUpdated] = useState(false);

  const [selectionOptionsArray, setSelectionOptionsArray] = useState([""])
  const [finishSettingsButtonClicked, setFinishSettingsButtonClicked] = useState(false);


  const [windowSize, setWindowSize] = React.useState(window.innerWidth);
  // Stuff for opting back in to universal tasks

  const [optedOutTasks, setOptedOutTasks] = useState([]);
  const optedOutLoaded = useRef(false);
  const [selectedTask, setSelectedTask] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [selectionMap, setSelectionMap] = useState({});

  const handleSelectionMap = (newValue) => {
    // setSelectionMap(newValue);
    let temp = { ...newTask }
    temp["boards"] = newValue;
    // console.log(temp)
    setNewTask(temp)

    // let d = { ...selectionMap }
    // d[newValue] = 1;

    // setSelectionMap(d)
  };

  const boardsLoaded = useRef(false)
  const linksLoaded = useRef(false)
  const [socialLinks, setSocialLinks] = useState({})

  useEffect(() => {

    if (!subscriptionsLoaded.current) {
      sqlService.getUserSubscriptions({ user: user }).then((data) => {

        const g = data.data?.flatMap((value) => Object.values(value))


        setSubscriptions(g)
        var temp = {}
        for (let [key, value] of Object.entries(emailCategories)) {
          for (let [k, v] of Object.entries(value)) {

            if (parseInt(k) === parseInt(user?.role_id)) {

              temp[v.KEY] = g.includes(v.KEY) ? false : true;


            }

          }
        }
        console.log(temp)
        setSubTypes(temp)

      }).catch((err) => {
        console.log(err)
      })
    }
  }, [])



  function updateTypes(key, val) {
    var temp = { ...subTypes }
    temp[key] = val;
    setSubTypes(temp)
    console.log(temp)
  }

  function unsubscribe() {
    sqlService.unsubscribe({ user: user, input: { subTypes: subTypes } }).then(() => {
      setSubmitted(true)

    }).catch((err) => {

    })
  }



  useEffect(() => {

    if ((user?.role_id === 2 || user?.role_id === 3) && !boardsLoaded.current) {
      sqlService
        .getBoardsForUser({ user: user })
        .then((data) => {
          var boards = {}
          for (let board of data.data) {
            boards[board.contact_name] = 1;
          }
          let temp = { ...newTask }
          userBoards.current = boards;
          temp["boards"] = boards;
          setNewTask(temp);
          // console.log(boards)
          boardsLoaded.current = true;


        })
        .catch((err) => {
          console.log(err);
        });
    }
    if ((user?.role_id === 1 || user?.role_id === 2) && !linksLoaded.current) {
      sqlService
        .getAgentsPB({ user: user })
        .then((data) => {
          const links = {}
          for (var item of data.data) {
            links[item.type_name] = item.link;
          }
          setSocialLinks(links)
          // console.log(links)
          linksLoaded.current = true;


        })
        .catch((err) => {
          console.log(err);
        });
      getAgentWelcomeEmail()
    }
  }, [refresh])

  const [requested, setRequested] = useState(false);

  const [preferencesDropdown, setPreferencesDropdown] = useState(false);

  const [checkedState, setCheckedState] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);

  const loaded = useRef(false);

  const [serverMessage, setServerMessage] = useState(null);

  const [newTask, setNewTask] = useState({ ...user });
  const [checked, setChecked] = useState({})
  const [updatedMessage, setUpdatedMessage] = useState(false);

  const [paymentMethodsArray, setPaymentMethodsArray] = useState(null);
  const [billingAddress, setBillingAddress] = useState(null);

  const userBoards = useRef([]);
  const [focused, setFocused] = useState(null);

  const address1FieldRef = useRef({})
  const newTaskRef = useRef({})
  const checkedRef = useRef()

  const [userInvoices, setUserInvoices] = useState(null);

  const [selectedInvoiceIndex, setSelectedInvoiceIndex] = useState(-1);

  const printTriggered = useRef(false);

  const getUser = () => {
    // console.log(user);
    return user;
  }

  useEffect(()=> {
    if(printTriggered.current){
      printTriggered.current = false;
      window.print();
    }
  })

  function onFinishUpdate(input) {
    setFinishSettingsButtonClicked(true)
    // console.log(input, getUser())
    sqlService.finishSettingsUpdate({ "input": input }).then(data1 => {
      // the data contains the score for captcha if the score is less than 0.5 then we ask for email verification
      // also contains the user informations
      // alert(data.user)

      let data = data1.data;
      let temp = { "role_id": user?.role_id }
      if (data) {
        for (let [key, value] of Object.entries(data)) {
          temp[key] = value;
        }
      }
      // console.log(temp)
      temp["token"] = user.token;
      setUser(temp);
      setNewTask(temp);
      loaded.current = false;
      boardsLoaded.current = false;
      setRefresh(!refresh)
      setUpdatedMessage(true);
      setFinishSettingsButtonClicked(false);
      setTimeout(() => {
        setUpdatedMessage(false);
      }, 2000);
      // setPopUp2({title: 'Thanks!', message: 'Your information has been updated'})
      // setShowPopUp2(true)
      // setGoogleResponse(temp)
    }).catch(err => {
      setFinishSettingsButtonClicked(false);
      setNewTask({ "email": user?.email ?? "", "contact_name": user?.contact_name ?? "", "broker_name": user?.broker_name ?? "", "phone_number": user?.phone_number ?? "", "address": user?.address ?? "", "city": user?.city ?? "", "state": user?.state ?? "", "zip_code": user?.zip_code ?? "", "fax_number": user?.fax_number ?? "", "website": user?.website ?? "", "agent_title": user?.agent_title ?? "", "img_link": user?.img_link ?? "", "logo_link": user?.logo_link ?? "" })
      loaded.current = false;
      boardsLoaded.current = false;
      setRefresh(!refresh)
      setTriggerX(false);
      console.log(err)
    })

  }

  //welcome email stuff
  const [welcomePreview, setWelcomePreview] = useState(false);


  const [welcomeEmailObject, setWelcomeEmailObject] = useState({
    "image": null,
    "allow_sending": true,
    "header": "",
    "content": '<p>Hi {{homeowner-name}}, you have been invited to Honeyydo by {{my-name}}</p><p>Honeyydo is an all-in-one home-management service that offers customizable task management, a management system for your appliance warranties and manuals, and connects you to verified home maintenance professionals in your area.</p><p>Finish setting up your account to take advantage of better organization and productivity for your home.</p>',

  })

  const resetWelcomeEmail = () => {
    setWelcomeEmailObject({
      "image": { ...welcomeEmailObject.image, "old": welcomeEmailObject.image?.data, "data": null, "new": null },
      "allow_sending": true,
      "header": "",
      "content": '<p>Hi {{homeowner-name}}, you have been invited to Honeyydo by {{my-name}}</p><p>Honeyydo is an all-in-one home-management service that offers customizable task management, a management system for your appliance warranties and manuals, and connects you to verified home maintenance professionals in your area.</p><p>Finish setting up your account to take advantage of better organization and productivity for your home.</p>',
    });


  }

  const getAgentWelcomeEmail = () => {
    sqlService.getAgentWelcomeEmail({ "user": user }).then(data => {
      console.log(data);
      if (data?.data) {
        var d = data?.data;
        if (d?.image) {
          d["image"] = { "data": d?.image }
        }
        setWelcomeEmailObject(d)
      } else {
        resetWelcomeEmail();
      }
    }).catch(err => {
      console.log(err);
      resetWelcomeEmail();
    });
  }

  const proccessTemplateWelcomeEmail = (temp) => {
    temp = temp.replaceAll("{{homeowner-name}}", "John Doe");
    temp = temp.replaceAll("{{my-name}}", user.contact_name);
    // var email = ""
    //     for (let it of temp.split(/\n/)) {
    //         email += `<p">${it}</p>`
    //     }
    //     return email;
    return temp

  }

  const LOGIN_LINK = "https://honeydo.com/#/login";

  const emailTemplateWrapper = (endMessage, html) => {
    return `
    
<!DOCTYPE html>

<html>
<head>

  <meta charset="utf-8">
  <meta http-equiv="x-ua-compatible" content="ie=edge">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
  <title>Email Receipt</title>
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <style type="text/css">
  /**
   * Google webfonts. Recommended to include the .woff version for cross-client compatibility.
   */
  @media screen {
    @font-face {
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 400;
      src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/ODelI1aHBYDBqgeIAH2zlBM0YzuT7MdOe03otPbuUS0.woff) format('woff');
    }

    @font-face {
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 700;
      src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/toadOcfmlt9b38dHJxOBGFkQc6VGVFSmCnC_l7QZG60.woff) format('woff');
    }
  }

  /**
   * Avoid browser level font resizing.
   * 1. Windows Mobile
   * 2. iOS / OSX
   */
  body,
  table,
  td,
  a {
    -ms-text-size-adjust: 100%; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
  }

  /**
   * Remove extra space added to tables and cells in Outlook.
   */
  table,
  td {
    mso-table-rspace: 0pt;
    mso-table-lspace: 0pt;
  }

  /**
   * Better fluid images in Internet Explorer.
   */
  img {
    -ms-interpolation-mode: bicubic;
  }

  /**
   * Remove blue links for iOS devices.
   */
  a[x-apple-data-detectors] {
    font-family: inherit !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    color: inherit !important;
    text-decoration: none !important;
  }

  /**
   * Fix centering issues in Android 4.4.
   */
  div[style*="margin: 16px 0;"] {
    margin: 0 !important;
  }

  body {
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  /**
   * Collapse table borders to avoid space between cells.
   */
  table {
    border-collapse: collapse !important;
  }

  a {
    color: #1a82e2;
  }

  img {
    height: auto;
    line-height: 100%;
    text-decoration: none;
    border: 0;
    outline: none;
  }
  </style>

</head>
<body style="background-color: #f8a025;">



  

  <!-- start body -->
  <table border="0" cellpadding="0" cellspacing="0" width="100%">
  <!-- start logo -->
   
   <tr >
        <td align="left" bgcolor="#f7f3f2"  style="background-image: url(${banner.current}); background-size: cover; background-position: center;" >
            <div style="width: 100%; min-width: ${windowSize > 700 ? '40vw' : '60vw'}; margin-bottom: 40px; background-color:rgb(62,59,60, .6); padding: 5px 15px;">
                <h5 style="color: #e2e2e2; font-style: italic;">Brought to you by </h5>
                <h4 style="color: white"> ${user?.contact_name}</h4>
            </div>
     <div style="padding: 15px;">
        <table>
            <tr>
                
                ${user?.img_link ?
              `
                <td>
                    <div style=" overflow: hidden; border-radius: 50%; width: 100px; height: 100px; border: solid 3px white;">
                        <img  src="${user?.img_link}" width="100%" height="100%" style="object-fit: cover; height: 100%"; width: 100%;"/>
                    </div>
                </td>`
                : '' }
                ${user?.logo_link ?
              `
                <td valign="bottom">
                    <div style="margin-left: ${user?.img_link ? '-35px' : '0px'}; overflow: hidden; border-radius: 999px; position: relative; z-index: 10;  width: 50px; height: 50px;">
                        <img  src="${user?.logo_link}" width="100%" height="100%" style="object-fit: cover; height: 100%"; width: 100%;"/>
                    </div>
                </td>`
                : '' }
            </tr>
        </table>
        </div>
       
      

          <!--[if (gte mso 9)|(IE)]>
          
          <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%">
          <tr>
          <td align="center" valign="top" width="100%"  >
          <![endif]-->
      
      

      
 

          <!--[if (gte mso 9)|(IE)]>
          </td>
          </tr>
          </table>
          <![endif]-->
          </td>
        </tr>



    ${proccessTemplateWelcomeEmail(`<tr>
            <td align="left"  bgcolor="#f7f3f2">
            <div  style="margin-top: 30px; padding: 24px">
            ${welcomeEmailObject?.header && welcomeEmailObject?.header !== "" ?
            `<h2>${welcomeEmailObject?.header}</h2>` : ""}
            ${document.getElementById('welcome-email')?.innerHTML}
                
          
            ${welcomeEmailObject?.image?.new || welcomeEmailObject?.image?.data ?
            `<img src=${welcomeEmailObject?.image?.new ?? welcomeEmailObject?.image?.data} alt="email img" style="max-width: 600px; margin-top: 10px; margin-bottom: 20px;" />`
            : ""}
                  <p>Here is your Honeyydo account information:</p>
                     <h3 style="background-color: #f4f4f4; padding: 10px; display: inline-block;">Email: samplehomeowner@gmail.com</h3>
                 <h3 style="background-color: #f4f4f4; padding: 10px; display: inline-block;">Password: homeowner_sample_password</h3>
                    <p>Please use this information to login to Honeyydo using the following link and don't forget to change your password</p>
                     <a href="${LOGIN_LINK}"><p>Login</p></a>
                        <p >
                        If you would like more information, please reach out to your agent, {{my-name}}.
                        </p>
                 </div>
             </td>
         </tr>`)}


  


    <!-- start footer -->
    <tr>
      <td align="left" bgcolor="#f0eae4" style="padding: 24px;">
        <!--[if (gte mso 9)|(IE)]>
        <table align="left" border="0" cellpadding="0" cellspacing="0" width="600">
        <tr>
        <td align="left" valign="top" width="600">
        <![endif]-->
        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">

          <!-- start permission -->
  
          <!-- end permission -->

          <div style="font-family: Arial, sans-serif; margin: 20px;">
          <p style="font-size:14px">Honeyydo</p>
          <a href="https://honeyydo.com" target="_blank" rel="noreferrer" style="display: inline-block;">
          <img src="https://honeyydo.s3.us-east-2.amazonaws.com/other/Honeyydo-logo-no-text.png" alt="Honeyydo Logo" width="50px" height="50px">
         </a>
 
          
          </div>
          <div style="margin: 20px;">
          <p style="font-size:14px">Want to stop receiving these emails? </p>
          <a style="font-size:14px" href="">Click here to unsubscribe</a>
          </div>

        </table>
        <!--[if (gte mso 9)|(IE)]>
        </td>
        </tr>
        </table>
        <![endif]-->
      </td>
    </tr>
    <!-- end footer -->

  </table>
  <!-- end body -->

</body>
</html> 

    
    `
  }

  const handleDeleteImg = (link) => {
    return new Promise((resolve, reject) => {
      sqlService.deleteAwsImage({ input: { "link": link, "imgSubfolder": "client/agent/agent_email_imgs" } }).then(d => {
        console.log(d)
        resolve(d);
      }).catch(err => {
        console.log(err);
        reject(err);
      })
    })
  }

  function formatPhoneNumber(number) {
    return '(' + number?.slice(0, 3) + ') ' + number?.slice(3, 6) + '-' + number?.slice(6, 10);
  }

  const uploadAgentWelcomEmailImg = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    if (!validateUtility.validFileSize(file)) {
      setServerMessage({ "message": `File size must be less than ${inputConstants.MAX_FILE_SIZE / 1000000}mb`, "title": "Error" })
      return;
    }

    reader.onload = async () => {
      let data = reader.result;
      let t = file.type;
      let type = t.split("/")[1]

      var dd = { "type": type };
      if (welcomeEmailObject?.image?.data) {
        dd["old"] = welcomeEmailObject?.image?.data;
      }
      dd["new"] = data;
      setWelcomeEmailObject({ ...welcomeEmailObject, "image": dd });

    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleUploadToAws = async (file, type) => {
    return new Promise((resolve, reject) => {
      let fileObject = { "imgFile": file, "imgType": type, "imgSubfolder": "client/agent/agent_email_imgs" }
      sqlService.uploadImgAws({ input: fileObject }).then(lnk => {
        lnk = lnk?.data?.link;
        console.log(lnk)
        resolve(lnk);
      }).catch(err => {
        console.log(err);
        reject(err);
      })
    })

  }

  async function updateWelcomeEmail() {
    var copyOfEmailObject = { ...welcomeEmailObject };
    copyOfEmailObject.content = document.getElementById('welcome-email')?.innerHTML
    if (welcomeEmailObject?.image?.new) {
      await handleUploadToAws(welcomeEmailObject?.image?.new, welcomeEmailObject?.image?.type).then(d => {
        copyOfEmailObject = { ...copyOfEmailObject, "image": { ...copyOfEmailObject.image, "data": d, "new": null } }
      }).catch(err => {
        console.log(err);
      });
    }
    if (welcomeEmailObject?.image?.old) {
      await handleDeleteImg(welcomeEmailObject?.image?.old).then(d => {
        copyOfEmailObject = { ...copyOfEmailObject, "image": { ...copyOfEmailObject.image, "old": null } }
      }).catch(err => {
        console.log(err);
      });
    }
    sqlService.updateAgentWelcomeEmail({ "input": { emailObject: copyOfEmailObject } }).then(d => {
      console.log("ddd is", d);
      setServerMessage({ "message": `Message is saved`, "title": "Success" })
    }).catch(err => {
      console.log(err);
    })

  }

  function updateNewTask(key, value) {
    switch (key) {
      case ("password"):
        if (validateUtility.regValidate(key, value)) {
          let nChecked = { ...checked }
          nChecked["password"] = "true";
          if (value === newTask.confirm_password) {
            nChecked["confirm_password"] = "true";
          } else {
            nChecked["confirm_password"] = "false";
          }
          setChecked(nChecked)
          setFocused('')
        } else {
          setChecked({ ...checked, "password": "false", "confirm_password": "false" })
          setFocused('Password not strong enough')
        }
        break;

      case ("confirm_password"):
        if (checked.password === "false") {
          setFocused('Password not strong enough')

        } else if (newTask?.password !== value) {
          setFocused('Password does not match')
        }

        if (newTask?.password === value && checked.password === "true") {
          let nChecked = { ...checked }
          nChecked["confirm_password"] = "true";
          setChecked(nChecked)
          setFocused('')
        } else {
          setChecked({ ...checked, "confirm_password": "false" })
        }

        break;

      case ("current_password"):
        if (key === "current_password") {
          if (validateUtility.regValidate("password", value)) {
            let nChecked = { ...checked }
            nChecked["current_password"] = "true";
            setChecked(nChecked)
          } else {
            setChecked({ ...checked, "current_password": "false" })
          }
        }

      case ("state"):
        // console.log(value)
        if (value !== "") {
          setChecked({ ...checked, [key]: "true" })
        } else {
          setChecked({ ...checked, [key]: "false" })
        }
        break;


      case ("city"):
        if (value !== "") {
          setChecked({ ...checked, [key]: "true" })
        } else {
          console.log("Not a Valid Name")
          setChecked({ ...checked, [key]: "false" })
        }
        break;

      case ("email"):
        if (validateUtility.regValidate(key, value)) {
          setChecked({ ...checked, [key]: "true" })
        } else {
          setChecked({ ...checked, [key]: "false" })
        }
        break;

      case ("contact_name"):
        if (validateUtility.regValidate(key, value)) {
          setChecked({ ...checked, [key]: "true" })
        } else {
          setChecked({ ...checked, [key]: "false" })
        }
        break;


      case ("phone_number"):
        if (validateUtility.regValidate(key, value)) {
          // console.log("validPhone")
          setChecked({ ...checked, [key]: "true" })
        } else {
          console.log("Not a Valid Phone")
          setChecked({ ...checked, [key]: "false" })
        }
        break;

      case ("address"):
        if (validateUtility.regValidate(key, value)) {
          // console.log("validAddress")
          setChecked({ ...checked, [key]: "true" })
        } else {
          setChecked({ ...checked, [key]: "false" })
        }
        break;

      case ("zip_code"):
        if (validateUtility.regValidate(key, value)) {
          // console.log("validAddress")
          setChecked({ ...checked, [key]: "true" })
        } else {
          setChecked({ ...checked, [key]: "false" })
        }
        break;

      case ("brokerage_name"):
        if (validateUtility.regValidate(key, value)) {
          setChecked({ ...checked, [key]: "true" })
        } else {
          setChecked({ ...checked, [key]: "false" })
        }
        break;


    }

    let nTask = { ...newTask }
    nTask[key] = value;
    setNewTask(nTask);
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handlePasswordUpdate = () => {
    let pass = true;
    if (!validateCheckedPasswords()) {
      pass = false;
      setTriggerX(true);
    }
    // console.log(pass)
    // console.log(newTask)

    if (pass) {
      sqlService.updateUserPassword({ "input": newTask }).then(d => {
        setNewTask({});
        setRequested(false);
        setUpdatedMessage(true);
        setChecked({ "current_password": "false", "password": "false", "confirm_password": "false" })
        setTriggerX(false);
        setTimeout(() => {
          setUpdatedMessage(false);
        }, 2000);
      }).catch(err => {
        console.log(err);
        setNewTask({});
        setChecked({ "current_password": "false", "password": "false", "confirm_password": "false" })
        setTriggerX(false);
        setRequested(false);
      })
    }

  }

  const validateChecked = () => {
    var valid = false;
    valid = (checked?.contact_name === "true" && checked?.phone_number === "true" && checked?.address === "true" && checked?.city === "true" && checked?.state === "true" && checked?.zip_code === "true")
    return valid;
  }

  const validateCheckedPasswords = () => {
    var valid = false;
    valid = ((checked?.current_password === "true" || (user.google_verified === 1 && user.has_password === 0)) && checked?.password === "true" && checked?.confirm_password === "true")
    return valid;
  }

  const billingInfoLoaded = useRef(false);
  const boardsRecieved = useRef(false);
  const allBoards = useRef([]);

  const [userSubscriptions, setUserSubscriptions] = useState(null);

  useEffect(() => {
    if ((user?.role_id === 2 || user?.role_id === 5 || user?.role_id === 6 || user?.role_id === 3) && user?.subscribed === 1 && !billingInfoLoaded.current) {
      stripeService.getBillingInfo({ "user": user }).then(d => {
        let data = d.data;
        let paymentMethodsArray = data?.paymentMethods?.data ?? null;
        let billingAddress = data?.address ?? null;
        // console.log(paymentMethodsArray)
        setPaymentMethodsArray(paymentMethodsArray);
        setBillingAddress(billingAddress);
      }).catch(err => {
        console.log(err);
      });

      stripeService.getCustomerSubscriptions({ "user": user }).then(d => {
        let data = d.data;
        // console.log(data);
        setUserSubscriptions(data.data);
      }).catch(err => {
        console.log(err);
      })

      billingInfoLoaded.current = true;
    }

  }, []);

  useEffect(() => {
    if ((user?.role_id === 2 || user?.role_id === 3) && (!boardsRecieved.current || (selectionOptionsArray.length < 2))) {
      sqlService.getAllBoards({}).then(d => {

        let arr = d.data
        allBoards.current = arr;
        var f = []
        for (let i of arr) {
          if ((user?.country && user?.country !== "" && i.country === user?.country) || (!user.country || user.country === "")) {
            f.push(i.contact_name)
          }
        }
        setSelectionOptionsArray(f)

      }).catch(err => {
        console.log(err);
      })
      boardsRecieved.current = true;
    }
  }, []);

  useEffect(() => {
    if (user?.role_id === 1 && !optedOutLoaded.current) {
      sqlService
        .getOptedOutTasks({ user: user })
        .then((data) => {
          setOptedOutTasks(data.data);
          setCheckedState(new Array(data.data.length).fill(false));
          optedOutLoaded.current = true;
          // console.log(data)

        })
        .catch((err) => {
          console.log(err);
        });
    }

  }, [refresh]);

  function optIntoTask() {

    sqlService
      .taskOptBackIn({ user: user, input: selectedTasks })
      .then((data) => {
        optedOutLoaded.current = false;
        setRefresh(!refresh);
        // setSubmitted(true)
        //   setServerMessage({
        //     color: "green",
        //     message: "Preferences have been updated!",
        // });
        // setTimeout(() => {
        //     setServerMessage({ color: "none", message: "" });
        // }, 3000);

      })
      .catch((err) => {
        console.log(err);
      });
  }


  function handleSelectionChange(i) {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === i ? !item : item
    );
    setCheckedState(updatedCheckedState);
    updateSelected(updatedCheckedState);

  };


  function updateSelected(s) {
    var selected = [];
    for (var i = 0; i < optedOutTasks.length && i < checkedState.length; i++) {
      if (s[i]) {
        selected.push(optedOutTasks[i]);
      }
    }
    setSelectedTasks(selected);
    // console.log({ selected });

  }

  function resetPreferences() {
    setPreferencesDropdown(false);
    setCheckedState(new Array(optedOutTasks.length).fill(false));
    setSelectedTasks([]);

  }

  const handleLogoChange = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();


    reader.onload = async () => {
      let data = reader.result;
      let t = file.type;
      let type = t.split("/")[1];
      // console.log(data);
      // setLogo(reader.result);
      //handleChange("logo_link",  data)
      sqlService.uploadImg({ input: { "imgFile": data, "imgType": type, "imgKey": "logo_link", "imgSubfolder": user.role_id === 2 ? "client/agent/agent_logos" : user.role_id === 3 ? "client/brokerage/brokerages_logos" : "client/admin/admin_logos" } }).then(d => {
        //handleChange("logo_link",  d.link)
        //console.log(d.link)
        let ne = { ...user };
        ne["logo_link"] = d.data.link;
        setCookie(ne);
      }).catch(err => {
        console.log(err);
      });
      // await convertToBase64(data, type, async function(data){
      //   console.log(data)
      // })
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleProfileChangeClick = (event, key) => {
    if (key === 'img_link') {
      profileImgEdit.current.click(event, key)
    } else {
      logoImgEdit.current.click(event, key)
    }

  }



  const handleProfileChange = (event, key) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    console.log(file.size)
    if (!validateUtility.validFileSize(file)) {
      setServerMessage({ "message": `File size must be less than ${inputConstants.MAX_FILE_SIZE / 1000000}mb`, "title": "Error" })
      return;
    }
    reader.onload = () => {

      let data = reader.result;
      let t = file.type;
      let type = t.split("/")[1]
      let subFolder = (key === "logo_link") ? "logos" : "imgs"
      let fileObject = { "imgFile": data, "imgType": type, "imgKey": key, "imgSubfolder": user?.role_id === 2 ? `agent_${subFolder}` : user?.role_id === 3 ? `brokerages_${subFolder}` : `admin_${subFolder}` }
      updateNewTask(key, data)
      if (key === "logo_link") {
        setLogoFileObject(fileObject)
      } else {
        setProfileFileObject(fileObject)
      }

    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };


  const handleAreaChange = (event) => {
    setSelectedArea(event.target.value);
  };

  const [showPopup, setShowPopup] = useState(false);
  const [selectedSite, setSelectedSite] = useState('');
  const [reviewLinks, setReviewLinks] = useState({
    zillow: '',
    realtor: '',
    google: '',
    facebook: '',
    instagram: '',
    website: ''
  });

  const handleButtonClick = (site) => {
    setSelectedSite(site);
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handlePopupSubmit = (site, link) => {
    setReviewLinks((prevLinks) => ({
      ...prevLinks,
      [site.toLowerCase()]: link
    }));
    // console.log({ reviewLinks });
    sqlService.addLinks({ "input": { "site": site, "link": link } }).then(d => {
      let data = { ...user };
      // console.log(data);
      // for (let key of Object.keys(newUser)) {
      //   nUser[key] = newUser[key];
      // }
      data[site.toLowerCase()] = link;
      const cookie = new Cookies();
      // console.log(data);
      // debugger;
      setUser(data);

      document.cookie = `honeyydo_user_cookie=${JSON.stringify({ data })}; expires=${new Date(Date.now() + (30 * 86400000))}; path=/; sameSite=strict; secure`;

    }).catch(err => {
      console.log(err);
    });
    setShowPopup(false);
  };




  const finish = () => {
    let validated = validateChecked()
    if (!validated) {
      // console.log("not valide checked")
      if (!triggerX) {
        setTriggerX(true);
      }
      setPopUp2({ title: 'Oops', message: 'Please fill out all required fields' })
      setShowPopUp2(true)

      return;
    }
    let copy = { ...newTask }
    if (profileFileObject) {
      copy["img_link"] = profileFileObject
    }
    if (logoFileObject) {
      copy["logo_link"] = logoFileObject
    }
    var arrayOfBoards = null;


    if (newTask?.boards && Object.keys(newTask.boards).length) {
      arrayOfBoards = allBoards.current.filter(item => newTask.boards[item.contact_name]).map(item => item);

      // arrayOfBoards = arrayOfBoards.filter(item => !(item.contact_name in originalBoards))
    }
    // console.log({ arrayOfBoards })
    copy["boards"] = arrayOfBoards

    // if (Object.keys(selectionMap).length > 1) {
    //   let board = "";
    //   for (let k of Object.keys(selectionMap)) {
    //     board += `{%}${k}`
    //   }
    //   copy["board"] = board;
    // }
    onFinishUpdate(copy)
  }

  useEffect(() => {
    handleInfo();
    setNewUser(user);
  }, [user]);


  function setCookie(data) {
    // // console.log(data.img_link, data.logo_link);
    const cookie = new Cookies();
    // cookie expires in one month
    // secures means only accessible using https
    // you can also add domain which is the domain of the cookie
    document.cookie = `honeyydo_user_cookie=${JSON.stringify({ data })}; expires=${new Date(Date.now() + (30 * 86400000))}; path=/; sameSite=strict; secure`;
    // window.location.reload();
    setUpdated(!updated)
  }

  const onSubmit = () => {
    // console.log("newUser = ", newUser);
    sqlService.updateUser({ "input": newUser }).then(data => {
      let nUser = { ...user };
      for (let key of Object.keys(newUser)) {
        nUser[key] = newUser[key];
      }
      // console.log(nUser);
      setUser(nUser);
      setCookie(nUser);
    }).catch(err => {
      console.log(err);
    });
  };

  const handleChange = (key, value) => {
    let temp = { ...newUser };
    temp[key] = value;
    setNewUser(temp);
  };


  const handleTabChange = (tabNum) => {
    setTab(parseInt(tabNum))
    if (tabNum !== "6") {
      setActivePaymentMethodId(null)
    }
    switch (tabNum) {
      case "1":
        handleInfo();
        break;
      case "2":
        handlePassword();
        break;
      case "3":
        handleReview();
        break;
      case "4":
        handlePreferences();
        break;
      case "5":
        handleLinks()
        break;
      case "6":
        handleBillingInfo();
        break;
      case "7":
        handleInvoices();
        break;
    }
  };


  const handleLogout = () => {
    // logout Stuff
    document.cookie = "honeyydo_user_cookie=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/; sameSite=strict; secure"

    //localStorage.removeItem("honeyydo-user");
    setUser(null);
    window.location.reload();

  };
  const handleInfo = () => {
    console.log(user);
    setNewTask({
      "boards": userBoards.current,
      "email": user?.email ?? "",
      "contact_name": user?.contact_name ?? "",
      "broker_name": user?.broker_name ?? "",
      "phone_number": user?.phone_number ?? "",
      "address": user?.address ?? "",
      "city": user?.city ?? "",
      "state": user?.state ?? "",
      "country": user?.country ?? "",
      "zip_code": user?.zip_code ?? "",
      "fax_number": user?.fax_number ?? "",
      "website": user?.website ?? "",
      "agent_title": user?.agent_title ?? "",
      "img_link": user?.img_link ?? "",
      "logo_link": user?.logo_link ?? "",
      "company_name": user?.company_name ?? "",
      "spouse_contact_name": user?.spouse_contact_name ?? "",
      "spouse_email": user?.spouse_email ?? "",
      "brokerage_name": user?.brokerage_name ?? "",
    })
    if (!address1FieldRef?.current) {

      // document.getElementById("address1FieldRef").value = user?.address ?? ""

    } else {
      address1FieldRef.current["value"] = user?.address ?? "";
    }

    resetPreferences();
    setChecked({ "contact_name": "true", "email": "true", "state": "true", "city": "true", "zip_code": "true", "phone_number": "true", "address": "true", "country": "true" });
  };
  const handlePassword = () => {
    setNewTask({})
    setChecked({ "current_password": "false", "password": "false", "confirm_password": "false" });
    resetPreferences();
  };
  const handleReview = () => {
    resetPreferences();
  };
  const handlePreferences = () => {
  };
  const handleLinks = () => {
    setNewTask({})
  };
  const handleBillingInfo = () => {
    // console.log(billingAddress)
    let task = {
      "state": billingAddress?.state ?? "",
      "country": billingAddress?.country ?? "",
      "city": billingAddress?.city ?? "",
      "address1": billingAddress?.address1 ?? "",
      "address2": billingAddress?.address2 ?? "",
      "zip_code": billingAddress?.zip_code ?? "",
    }
    setNewTask(task)
  };

  const handleInvoices = () => {
    // sqlService.getUserInvoices({}).then(d => {
    //   setUserInvoices(d)
    // }).catch(err => {
    //   console.log(err)
    // })

    getAllInvoices();
  }

  const getAllInvoices = async () => {
    var hasMore = false;
    var allInvoices = [];
    var starting_after = null;
    do {
      await stripeService.getAllInvoices({ input: { starting_after: starting_after } }).then(d => {
        let data = d.data;
        hasMore = data?.has_more
        starting_after = data?.data[data?.data?.length - 1]?.id;
        allInvoices = [...allInvoices, ...data.data];
      }).catch(err => {
        console.log(err)
      })
    } while (hasMore === true)
    console.log(allInvoices);
    setUserInvoices(allInvoices);
  }

  // const [account, setAccount] = useState(1);
  const [tab, setTab] = useState(1);


  useEffect(() => {
    window.addEventListener("resize", onWindowResize);


    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  const [triggerX, setTriggerX] = useState(false);
  const [activePaymentMethodId, setActivePaymentMethodId] = useState(null);

  const currentYear = new Date().getFullYear();
  const monthsArr = Array.from({ length: 12 }, (x, i) => {
    const month = i + 1;
    return month <= 9 ? '0' + month : month;
  });
  const yearsArr = Array.from({ length: 9 }, (_x, i) => currentYear + i);

  const handleAddingNewPayment = (val) => {
    if (val) {
      setNewTask({
        "card_name": "",
        "card_year": "",
        "card_month": "",
        "card_cvv": "",
        "card_number": "",
        "card_year": "",
        "country": "United States",
      })
      setAddingNewPayment(true);
    } else {
      let task = {
        "state": billingAddress?.state ?? "",
        "country": "United States",
        "city": billingAddress?.city ?? "",
        "address1": billingAddress?.address1 ?? "",
        "address2": billingAddress?.address2 ?? "",
        "zip_code": billingAddress?.zip_code ?? "",
      }
      setNewTask(task)
      setAddingNewPayment(false);
    }
  }

  const addNewCard = () => {
    stripeService.addNewCard({ "user": user, input: { card: newTask } }).then(d => {
      let data = d.data;
      let paymentMethodsArray = data?.paymentMethods?.data ?? null;
      let billingAddress = data?.address ?? null;
      setPaymentMethodsArray(paymentMethodsArray);
      setBillingAddress(billingAddress);
      handleAddingNewPayment(false);
    }).catch(err => {
      console.log(err)
    })
  }

  const handleUpdatePaymentMethod = () => {
    if (userSubscriptions[0] && activePaymentMethodId && activePaymentMethodId !== userSubscriptions[0].default_payment_method) {
      stripeService.updatePaymentMethod({ "user": user, "input": { "paymentId": activePaymentMethodId, "subscriptionId": userSubscriptions[0].id } }).then(d => {
        stripeService.getCustomerSubscriptions({ "user": user }).then(d => {
          let data = d.data;
          // console.log(data);
          setUserSubscriptions(data.data);
        }).catch(err => {
          console.log(err);
        })
      }).catch(err => {
        console.log(err);
      })
    }
  }


  useEffect(() => {
    checkedRef.current = checked;
  }, [checked])

  useEffect(() => {
    newTaskRef.current = newTask;
  }, [newTask])

  useEffect(() => {
    if (address1FieldRef.current) {
      address1FieldRef.current["value"] = user?.address ?? "";
    }
  }, [tab])

  const handleDownloadPDF = (index) => {
    var element = document.getElementById(`${index}_pdf-content`);
    var opt = {
      margin: 0,
      filename: `honeyydo-invoice.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: {
        unit: 'mm',
        format: [200, 300],
        orientation: 'portrait'
      }
    };

    html2pdf().set(opt).from(element).save();
  };

  const print = () => {
    printTriggered.current = true;
  };

  return (
    <>
      <GoogleAutoComplete address1FieldRef={address1FieldRef} callBack={(postcode, city1, country1, state1, address1) => {
        setChecked({ ...checkedRef.current, "zip_code": postcode !== "" ? "true" : "false", "city": city1 !== "" ? "true" : false, "country": country1 !== "" ? "true" : "false", "state": state1 !== "" ? "true" : "false", "address": "true" })
        setNewTask({ ...newTaskRef.current, "zip_code": postcode, "city": city1, "country": country1, "state": state1, "address": address1 });
      }} />

      <div className="Settings-Page">
        <h4 className="grey no-print">Settings</h4>
        <div className="display-flex Settings-Container">
          <div className="Settings-Buttons no-print">

            {/* Personal Info */}
            <button className={`settings-button ${tab === 1 ? "Selected-Settings-Button" : ""}`} onClick={() => { handleTabChange("1") }}><FontAwesomeIcon icon={faUser} className="fa-sm padding-right-10-px" />{user?.role_id === 3 ? "Company Info" : "Personal Info"}</button>

            {/* Password */}
            <button className={`settings-button ${tab === 2 ? "Selected-Settings-Button" : ""}`} onClick={() => { handleTabChange("2") }}><FontAwesomeIcon icon={faKey} className="fa-sm padding-right-10-px" />Password</button>

            {(user?.role_id === 2 || user?.role_id === 3) ?

              /* Links */
              < button className={`settings-button ${tab === 6 ? "Selected-Settings-Button" : ""}`} onClick={() => { handleTabChange("6") }}><FontAwesomeIcon icon={faCreditCard} className="fa-sm padding-right-10-px" />Billing Info</button>

              : null
            }

            {(user?.role_id === 2 || user?.role_id === 3 || user?.role_id === 5 || user?.role_id === 6) ?

              /* Links */
              < button className={`settings-button ${tab === 8 ? "Selected-Settings-Button" : ""}`} onClick={() => { handleTabChange("8") }}><FontAwesomeIcon icon={faEnvelope} className="fa-sm padding-right-10-px" />Invitation Email</button>

              : null
            }

            {/* Reviews */}
            {user?.role_id !== 0 && !(user?.role_id === 1 && user?.solo_subscribed) ?
              <button className={`settings-button ${tab === 3 ? "Selected-Settings-Button" : ""}`} onClick={() => { handleTabChange("3") }}><FontAwesomeIcon icon={faStar} className="fa-sm padding-right-10-px" />Review</button>
              : null}

            {/* Preferences */}
            {user?.role_id !== 0 ?
              <button className={`settings-button ${tab === 4 ? "Selected-Settings-Button" : ""}`} onClick={() => { handleTabChange("4") }}><FontAwesomeIcon icon={faGear} className="fa-sm padding-right-10-px" />Preferences</button>
              : null}
            {/*
                        {user?.role_id === 2 ?

                            < button className={`settings-button ${tab === 5 ? "Selected-Settings-Button" : ""}`} onClick={() => { handleTabChange("5") }}><FontAwesomeIcon icon={faLink} className="fa-sm padding-right-10-px" />Social Links</button>

                            : null
                        } */}

            {user?.role_id !== 0 ?
              <button className={`settings-button ${tab === 7 ? "Selected-Settings-Button" : ""}`} onClick={() => { handleTabChange("7") }}><FontAwesomeIcon icon={faReceipt} className="fa-sm padding-right-10-px" />Invoices</button>
              : null}


          </div>
          {tab === 1 ?
            <>
              <PopupMessage title={popUp2?.title} message={popUp2?.message} onConfirm={closePopUp2} show={showPopUp2}></PopupMessage>
              <div className="Settings-Panel display-flex flex-direction-column">
                <div className="Settings-Heading">
                  <h5 className="wrap-none grey">Contact Information </h5>
                </div>

                <div className="card-form height-fit-content padding-10-px box-shadow-none margin-top-20-px">

                  <div className=" box-shadow-none ">

                    <div className="card-input position-relative">
                      <label className="card-input__label">
                        {"Email*"}
                      </label>
                      <input
                        type="text"
                        className={`card-input__input-disabled`}
                        autoComplete="off"
                        value={newTask?.email}
                        disabled
                      />
                    </div>

                    {user?.role_id !== 2 ?
                      <div className="card-input position-relative">
                        <label className="card-input__label">
                          {user?.role_id === 3 ? "Company Name*" : "Name*"}
                        </label>
                        <input
                          type="text"
                          className={`card-input__input ${(triggerX && checked?.contact_name !== "true") ? "Input-Error-Border" : ""}`}
                          autoComplete="off"
                          value={newTask?.contact_name}
                          onChange={(e) => { updateNewTask("contact_name", e.currentTarget.value); }}
                        />
                        {checked?.contact_name === "true" ?
                          <span style={{ top: '30px' }} className="Checkmark"><ImCheckmark /></span>
                          : null}
                        {(triggerX && checked?.contact_name !== "true") ?

                          <span className="Finish-Check"><FcCancel /></span>
                          : null}
                      </div>

                      :

                      <div className="card-form__row">
                        <div className="card-form__col position-relative" style={windowSize > 480 ? { flex: '1' } : {}} >
                          <label className="card-input__label">
                            Name*
                          </label>
                          <input
                            type="text"
                            className={`card-input__input ${(triggerX && checked?.contact_name !== "true") ? "Input-Error-Border" : ""}`}
                            autoComplete="off"

                            value={newTask.contact_name}
                            onChange={(e) => { updateNewTask("contact_name", e.currentTarget.value); }}

                          />
                          {checked?.contact_name === "true" ?
                            <span style={{ top: '30px' }} className="Checkmark"><ImCheckmark /></span>
                            : null}
                          {(triggerX && checked?.contact_name !== "true") ?

                            <span className="Finish-Check"><FcCancel /></span>
                            : null}
                        </div>
                        <div className="card-form__col" style={windowSize > 480 ? { flex: '1' } : {}}>
                          <div className="card-input position-relative">
                            <label

                              className="card-input__label"
                            >
                              Title
                            </label>
                            <select
                              style={{ minHeight: '39px' }}
                              name="title"
                              className="card-input__input "
                              value={newTask?.agent_title ?? ""}
                              onChange={(e) => { updateNewTask("agent_title", e.currentTarget.value); }}

                            >

                              <option value={1}>{"Real State Agent"}</option>
                              <option value={2}>{"Broker"}</option>


                            </select>


                          </div>
                        </div>
                      </div>}

                    {user?.role_id === 1 ?

                      <div className="card-input position-relative">
                        <label className="card-input__label">
                          Phone*
                        </label>
                        <input
                          type="tel"
                          className={`card-input__input ${(triggerX && checked?.phone_number !== "true") ? "Input-Error-Border" : ""}`}
                          autoComplete="off"

                          value={newTask.phone_number}
                          onChange={(e) => { updateNewTask("phone_number", e.currentTarget.value); }}

                        />
                        {checked?.phone_number === "true" ?
                          <span style={{ top: '30px' }} className="Checkmark"><ImCheckmark /></span>
                          : null}
                        {(triggerX && checked?.phone_number !== "true") ?

                          <span className="Finish-Check"><FcCancel /></span>
                          : null}
                      </div>

                      :


                      <>

                        {user?.role_id === 3 ?

                          <div className="card-input">
                            <label className="card-input__label">
                              Broker Name
                            </label>
                            <input
                              type="text"
                              className="card-input__input"
                              autoComplete="off"

                              value={newTask.broker_name}
                              onChange={(e) => { updateNewTask("broker_name", e.currentTarget.value); }}

                            />
                          </div>
                          : null}

                        <div className="card-form__row">
                          <div className="card-form__col position-relative">
                            <label className="card-input__label">
                              Phone*
                            </label>
                            <input
                              type="tel"
                              className={`card-input__input ${(triggerX && checked?.phone_number !== "true") ? "Input-Error-Border" : ""}`}
                              autoComplete="off"

                              value={newTask.phone_number}
                              onChange={(e) => { updateNewTask("phone_number", e.currentTarget.value); }}

                            />
                            {checked?.phone_number === "true" ?
                              <span style={{ top: '30px' }} className="Checkmark"><ImCheckmark /></span>
                              : null}
                            {(triggerX && checked?.phone_number !== "true") ?

                              <span className="Finish-Check"><FcCancel /></span>
                              : null}
                          </div>


                          <div className="card-form__col ">
                            <div className="card-input">
                              <label className="card-input__label">
                                Fax
                              </label>
                              <input
                                type="tel"
                                className="card-input__input"
                                autoComplete="off"

                                value={newTask.fax_number}
                                onChange={(e) => { updateNewTask("fax_number", e.currentTarget.value); }}

                              />
                            </div>
                          </div>

                        </div>


                      </>}

                    <div className="card-input position-relative">
                      <label className="card-input__label">
                        Address*
                      </label>
                      <input
                        type="text"
                        className={`card-input__input ${(triggerX && checked?.address !== "true") ? "Input-Error-Border" : ""}`}
                        autoComplete="off"
                        ref={address1FieldRef}
                        // value={newTask.address}
                        onChange={(e) => { updateNewTask("address", e.currentTarget.value); }}

                      />
                      {checked?.address === "true" ?
                        <span style={{ top: '30px' }} className="Checkmark"><ImCheckmark /></span>
                        : null}
                      {(triggerX && checked?.address !== "true") ?

                        <span className="Finish-Check"><FcCancel /></span>
                        : null}
                    </div>

                    <div className="card-form__row">
                      <div className="card-form__col position-relative">
                        <label

                          className="card-input__label"
                        >
                          City*
                        </label>
                        <input
                          type="text"
                          className={`card-input__input ${(triggerX && checked?.city !== "true") ? "Input-Error-Border" : ""}`}


                          autoComplete="off"
                          value={newTask?.city ?? ""}
                          onChange={(e) => { updateNewTask("city", e.currentTarget.value); }}


                        />
                        {checked?.city === "true" ?
                          <span style={{ top: '30px' }} className="Checkmark"><ImCheckmark /></span>
                          : null}
                        {(triggerX && checked?.city !== "true") ?

                          <span className="Finish-Check"><FcCancel /></span>
                          : null}
                      </div>
                      <div className="card-form__col ">
                        <div className="card-input position-relative">
                          <label

                            className="card-input__label"
                          >
                            {newTask?.country === "Canada" ? "Postal Code*" : "Zip Code*"}
                          </label>
                          <input
                            type="text"
                            className={`card-input__input ${(triggerX && checked?.zip_code !== "true") ? "Input-Error-Border" : ""}`}
                            maxLength={newTask?.country === "Canada" ? "6" : "5"}
                            autoComplete="off"
                            value={newTask?.zip_code ?? ""}
                            onChange={(e) => { updateNewTask("zip_code", e.currentTarget.value); }}


                          />
                          {checked?.zip_code === "true" ?
                            <span style={{ top: '30px' }} className="Checkmark"><ImCheckmark /></span>
                            : null}
                          {(triggerX && checked?.zip_code !== "true") ?

                            <span className="Finish-Check"><FcCancel /></span>
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="card-form__row">
                      <div className="card-form__col">
                        <div className="card-form__group position-relative">
                          <label

                            className="card-input__label"
                          >
                            {newTask?.country === "Canada" ? "Province*" : "State*"}
                          </label>
                          <select
                            name="state"
                            className={`card-input__input -select ${(triggerX && checked?.state !== "true") ? "Input-Error-Border" : ""}`}

                            value={newTask?.state ?? ""}
                            onChange={(e) => { updateNewTask("state", e.currentTarget.value); }}

                          >
                            {/* <option value="" >
                              Select State
                            </option> */}
                            {newTask.country !== "Canada" ?
                              inputConstants?.US_STATES.map((val, index) => (
                                <option key={index} value={val.value}>
                                  {val.label}
                                </option>
                              )) : null}
                            {newTask.country !== "United States" ?
                              inputConstants?.CANDADIAN_PROVINCES.map((val, index) => (
                                <option key={index} value={val.value}>
                                  {val.label}
                                </option>
                              )) : null}
                          </select>
                          {checked?.state === "true" ?
                            <span style={{ top: '30px' }} className="Checkmark"><ImCheckmark /></span>
                            : null}
                          {(triggerX && checked?.state !== "true") ?

                            <span className="Finish-Check"><FcCancel /></span>
                            : null}

                        </div>
                      </div>

                      {/* <div className="card-form__col">
                        <div className="card-form__group position-relative">
                          <label

                            className="card-input__label"
                          >
                            Country*
                          </label>
                          <select
                            name="country"
                            className={`card-input__input -select ${(triggerX && checked?.country !== "true") ? "Input-Error-Border" : ""}`}
                            value={newTask?.country ?? ""}
                            onChange={(e) => { updateNewTask("country", e.currentTarget.value); }}
                            
                          >

                            <option value="">Select Country</option>
                            <option key={"countryCanada"} value={"Canada"}>
                              {"Canada"}
                            </option>
                            <option key={"countryUS"} value={"United States"}>
                              {"United States"}
                            </option>
                          </select>
                          {checked?.state === "true" ?
                            <span className="Checkmark"><ImCheckmark /></span>
                            : null}
                          {(triggerX && checked?.state !== "true") ?

                            <span className="Finish-Check"><FcCancel /></span>
                            : null}

                        </div>
                      </div> */}
                    </div>

                    {user?.role_id === 1 ?
                      <div className="card-form__row margin-top-20-px">
                        <div className="card-form__col position-relative">
                          <label

                            className="card-input__label"
                          >
                            {`Spouse Full Name (optional)`}
                          </label>
                          <input
                            type="text"
                            className={`card-input__input`}


                            autoComplete="off"
                            value={newTask?.spouse_contact_name ?? ""}
                            onChange={(e) => { updateNewTask("spouse_contact_name", e.currentTarget.value); }}


                          />

                        </div>
                        <div className="card-form__col ">
                          <div className="card-input position-relative">
                            <label

                              className="card-input__label"
                            >
                              {`Spouse Email (optional)`}
                            </label>
                            <input
                              type="text"
                              className={`card-input__input`}
                              autoComplete="off"
                              value={newTask?.spouse_email ?? ""}
                              onChange={(e) => { updateNewTask("spouse_email", e.currentTarget.value); }}
                            />
                          </div>
                        </div>
                      </div> : null}

                    {user?.role_id === 2 || user?.role_id === 5 || user?.role_id === 6 ?
                      <div className="card-input position-relative margin-top-20-px">
                        <label className="card-input__label">
                          Brokerage*
                        </label>
                        <input
                          type="text"
                          className={`card-input__input ${(triggerX && checked?.brokerage_name !== "true") ? "Input-Error-Border" : ""}`}
                          autoComplete="off"
                          value={newTask?.brokerage_name ?? ""}
                          onChange={(e) => { updateNewTask("brokerage_name", e.currentTarget.value); }}

                        />
                        {checked?.brokerage_name === "true" ?
                          <span style={{ top: '30px' }} className="Checkmark"><ImCheckmark /></span>
                          : null}
                        {(triggerX && checked?.brokerage_name !== "true") ?

                          <span className="Finish-Check"><FcCancel /></span>
                          : null}
                      </div>

                      : null}

                    {user?.role_id === 2 || user?.role_id === 5 || user?.role_id === 6 ?
                      <div className="card-input margin-top-20-px">
                        <label className="card-input__label">
                          Website
                        </label>
                        <input
                          type="url"

                          className="card-input__input"
                          autoComplete="off"
                          value={newTask?.website}
                          onChange={(e) => { updateNewTask("website", e.currentTarget.value); }}
                        />
                      </div>

                      : null}


                  </div>
                  {(finishSettingsButtonClicked && !updatedMessage && user?.role_id === 1) ?
                    <Button className="submit Settings-Submit " variant="primary" disabled>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="text-align-center"> Loading...</span>
                    </Button> : null
                  }
                  {(!finishSettingsButtonClicked && !updatedMessage && user?.role_id === 1) ? <button className="popup-submit-button Settings-Submit " type="submit" onClick={() => { finish() }}>Update</button> : null}

                </div>
                {user?.role_id === 2 || user?.role_id === 3 ?
                  <>

                    <div className="Settings-Heading">
                      <h5 className="wrap-none grey">Board(s) </h5>
                    </div>

                    <MultipleSelection theme={'Settings'} selectionMap={newTask.boards ?? {}} handleSelectionMap={handleSelectionMap} selectionOptionsArray={selectionOptionsArray} />

                  </>

                  : null}

                {user?.role_id === 2 ?

                  <>

                    <div className="Settings-Heading">
                      <h5 className="wrap-none grey">Social Media Accounts </h5>
                    </div>

                    <div className="Social-Icons">
                      <div className="position-relative">
                        <FontAwesomeIcon icon={faInstagram} onClick={() => handleButtonClick('Instagram')} className="Social-Icon fa-xl" />
                        {socialLinks?.instagram ?
                          <FontAwesomeIcon className="Check-Icon" icon={faCircleCheck} />
                          : null}
                      </div>
                      <div className="position-relative">
                        <FontAwesomeIcon icon={faFacebook} onClick={() => handleButtonClick('Facebook')} className="Social-Icon fa-xl" />
                        {socialLinks?.facebook ?
                          <FontAwesomeIcon className="Check-Icon" icon={faCircleCheck} />
                          : null}
                      </div>
                      <div className="position-relative">
                        <SiZillow onClick={() => handleButtonClick('Zillow')} className="Social-Icon fa-xl" />
                        {socialLinks?.zillow ?
                          <FontAwesomeIcon className="Check-Icon" icon={faCircleCheck} />
                          : null}
                      </div>
                      <div className="position-relative">
                        <FontAwesomeIcon icon={faGoogle} onClick={() => handleButtonClick('Google')} className="Social-Icon fa-xl" />
                        {socialLinks?.google ?
                          <FontAwesomeIcon className="Check-Icon" icon={faCircleCheck} />
                          : null}
                      </div>

                    </div>
                  </>
                  : null}

                {user?.role_id === 2 || user?.role_id === 3 ?
                  <>

                    <div className="Settings-Heading">
                      <h5 className="wrap-none grey">Profile Images </h5>
                    </div>

                    <div style={{ maxWidth: '570px' }} className='settings-images'>
                      <div className="user-images display-flex ">

                        {user?.role_id === 2 ?
                          <div className='logo'>
                            <span className='card-input__label'>Picture</span>
                            <label className='imglabels' htmlFor="imageUpload">
                              <img className='imglabels' src={newTask?.img_link || Placeholder2} alt="Placeholder" />
                            </label>
                            <input className='display-none' ref={profileImgEdit} type="file" id="imageUpload" accept="image/*" onChange={(e) => handleProfileChange(e, "img_link")} />
                            <div className="Edit-Label" onClick={(e) => handleProfileChangeClick(e, "img_link")}><FontAwesomeIcon icon={faPen} className="fa-sm grey" /><span>Edit</span></div>
                          </div>
                          : null}


                        <div className='logo'>
                          <span className='card-input__label'>Logo</span>
                          <label className='imglabels' htmlFor="logoUpload">
                            <img className='imglabels' src={newTask?.logo_link || Placeholder2} alt="Placeholder" />
                          </label>
                          <input className='display-none' ref={logoImgEdit} type="file" id="logoUpload" accept="image/*" onChange={(e) => handleProfileChange(e, "logo_link")} />
                          <div className="Edit-Label" onClick={(e) => handleProfileChangeClick(e, "logo_link")}><FontAwesomeIcon icon={faPen} className="fa-sm grey" /><span>Edit</span></div>
                        </div>
                      </div>

                      {(finishSettingsButtonClicked && !updatedMessage && (user?.role_id === 2 || user?.role_id === 3)) ?
                        <Button className="submit Settings-Submit " variant="primary" disabled>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span className="text-align-center"> Loading...</span>
                        </Button> : null
                      }
                      {(!finishSettingsButtonClicked && !updatedMessage && (user?.role_id === 2 || user?.role_id === 3)) ? <button className="popup-submit-button Settings-Submit " type="submit" onClick={() => { finish() }}>Update</button> : null}





                    </div>


                  </>

                  : null
                }



                {showPopup && (
                  <ReviewPopup
                    site={selectedSite}
                    onClose={handlePopupClose}
                    onSubmit={handlePopupSubmit}
                    reviewLink={socialLinks[selectedSite.toLowerCase()]}
                  />
                )}




              </div>
            </> : null}

          {tab === 2 ?
            <>
              <div className="Settings-Panel display-flex flex-direction-column">
                <div className="Settings-Heading">
                  <h5 className="wrap-none grey">Update Password </h5>
                </div>

                <div className="card-form height-fit-content padding-10-px box-shadow-none margin-top-20-px">

                  <div className=" box-shadow-none">
                    {(user.google_verified === 0 || user.google_verified === 1 && user.has_password === 1) ?
                      <div className="card-input position-relative">
                        <label className="card-input__label " htmlFor="currentpass">
                          Current Password
                        </label>
                        <input
                          name="currentpass"
                          type="password"

                          className={`card-input__input ${(triggerX && checked?.current_password !== "true") ? "Input-Error-Border" : ""}`}
                          autoComplete="off"
                          value={newTask?.current_password}
                          onChange={(e) => { updateNewTask("current_password", e.currentTarget.value); }}
                        />
                        {checked?.current_password === "true" ?
                          <span style={{ top: '50%' }} className="settings-blue position-absolute right-10-px top-2-px"><ImCheckmark /></span>
                          : null}
                        {(triggerX && checked?.current_password !== "true") ?

                          <span className="Finish-Check"><FcCancel /></span>
                          : null}
                      </div>
                      :
                      <div className="card-input position-relative">
                        <span className="">
                          Your account was verified by Google, but you can add a new password to use it to login with your email <span className="bold">{user.email}</span>.
                        </span>
                      </div>

                    }


                    <div className="card-input position-relative">
                      <label className="card-input__label " htmlFor="newpass">
                        New Password
                      </label>
                      <input
                        name="newpass"
                        type="password"

                        className={`card-input__input ${(triggerX && checked?.password !== "true") ? "Input-Error-Border" : ""}`}

                        autoComplete="off"
                        value={newTask?.password}
                        onChange={(e) => { updateNewTask("password", e.currentTarget.value); }}
                      />
                      {checked?.password === "true" ?
                        <span style={{ top: '50%' }} className="settings-blue position-absolute right-10-px top-2-px"><ImCheckmark /></span>
                        : null}
                      {(triggerX && checked?.password !== "true") ?

                        <span className="Finish-Check"><FcCancel /></span>
                        : null}

                    </div>

                    <div className="card-input position-relative">
                      <label className="card-input__label " htmlFor="repeatpass">
                        Confirm Password
                      </label>
                      <input
                        name="repeatpass"
                        type="password"
                        className={`card-input__input ${(triggerX && checked?.confirm_password !== "true") ? "Input-Error-Border" : ""}`}
                        autoComplete="off"
                        value={newTask?.confirm_password}
                        onChange={(e) => { updateNewTask("confirm_password", e.currentTarget.value); }}
                      />
                      {checked?.confirm_password === "true" ?
                        <span style={{ top: '50%' }} className="settings-blue position-absolute right-10-px top-2-px"><ImCheckmark /></span>
                        : null}

                      {(triggerX && checked?.confirm_password !== "true") ?

                        <span className="Finish-Check"><FcCancel /></span>
                        : null}

                    </div>
                  </div>
                  <div className='width-100-psnt margin-top-20-px display-flex flex-wrap justify-content-center' >

                    <span className="Signup-Hint padding-x-10-px align-items-center display-flex justify-content-center grey">{focused}</span>

                  </div>
                  <div className="display-flex flex-direction-column justify-content-center width-full-pt row-gap-15-px">
                    <button onClick={() => { handlePasswordUpdate() }} className="popup-submit-button Settings-Submit" type="submit">{(user.google_verified === 0 || user.google_verified === 1 && user.has_password === 1) ? "Change" : "Add Password"}</button>
                    {requested ? <div className="display-flex align-items-center justify-content-center"><Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner></div> : null}
                    {updatedMessage ? <span className="display-block margin-auto-lr">Updated!</span> : null}
                  </div>
                </div>

              </div>

            </> : null}

          {tab === 6 ?
            <>
              <div className="Settings-Panel display-flex flex-direction-column">
                <div className="Settings-Heading">
                  <h5 className="wrap-none grey">Update Billing Info </h5>
                </div>

                <div className="card-form height-fit-content padding-10-px box-shadow-none margin-top-20-px">
                  <div className="card-input">
                    <label className="card-input__label">
                      Address
                    </label>
                    <input
                      type="text"
                      className="card-input__input"
                      autoComplete="off"
                      value={newTask.address}
                      onChange={(e) => { updateNewTask("address", e.currentTarget.value); }}

                    />
                  </div>

                  <div className="card-form__row">
                    <div className="card-form__col">
                      <label

                        className="card-input__label"
                      >
                        City*
                      </label>
                      <input
                        type="text"
                        className="card-input__input"

                        autoComplete="off"
                        value={newTask?.city ?? ""}
                        onChange={(e) => { updateNewTask("city", e.currentTarget.value); }}


                      />
                    </div>
                    <div className="card-form__col">
                      <div className="card-form__group">
                        <label

                          className="card-input__label"
                        >
                          {newTask?.country === "Canada" ? "Province*" : "State*"}
                        </label>
                        <select
                          name="state"
                          className="card-input__input -select"
                          value={newTask?.state ?? ""}
                          onChange={(e) => { updateNewTask("state", e.currentTarget.value); }}

                        >
                          {newTask.country !== "Canada" ?
                            inputConstants?.US_STATES.map((val, index) => (
                              <option key={index} value={val.value}>
                                {val.label}
                              </option>
                            )) : null}
                          {newTask.country !== "United States" ?
                            inputConstants?.CANDADIAN_PROVINCES.map((val, index) => (
                              <option key={index} value={val.value}>
                                {val.label}
                              </option>
                            )) : null}
                        </select>


                      </div>
                    </div>
                    <div className="card-form__col ">
                      <div className="card-input">
                        <label

                          className="card-input__label"
                        >
                          {newTask?.country === "Canada" ? "Postal Code*" : "Zip Code*"}
                        </label>
                        <input
                          type="text"
                          className="card-input__input"
                          maxLength={newTask?.country === "Canada" ? "6" : "5"}
                          autoComplete="off"
                          value={newTask?.zip_code ?? ""}
                          onChange={(e) => { updateNewTask("zip_code", e.currentTarget.value); }}

                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="card-input">
                                            <label className="card-input__label">
                                                Country
                                            </label>
                                            <input
                                                type="text"
                                                className="card-input__input"
                                                autoComplete="off"
                                                disabled
                                            
                                                value={newTask?.country}
                                                

                                            />
                                        </div> */}

                </div>
                {/* <div style={{marginBottom: '20px'}} className="Settings-Heading">
                                    <h5 className="wrap-none grey">Default Payment Method </h5>
                                </div> */}
                <div className="">

                  {!addingNewPayment ?

                    <>
                      <label className="card-input__label">
                        Default Payment Method
                      </label>
                      {paymentMethodsArray ? paymentMethodsArray?.map(paymentMethod =>
                        <div className="display-flex flex-direction-column padding-10-px">

                          <div className={`Payment-Method-Div ${((activePaymentMethodId && activePaymentMethodId === paymentMethod.id) || (userSubscriptions.length && userSubscriptions[0].default_payment_method === paymentMethod.id && !activePaymentMethodId)) ? 'Selected-Payment' : ''}`} onClick={() => { setActivePaymentMethodId(paymentMethod.id) }}>
                            <div className="display-flex flex-wrap column-gap-15-px row-gap-15-px position-relative">
                              <div className="display-flex">
                                <span> {((activePaymentMethodId && activePaymentMethodId === paymentMethod.id) || (userSubscriptions.length && userSubscriptions[0].default_payment_method === paymentMethod.id && !activePaymentMethodId)) ? <BiRadioCircleMarked /> : <BiRadioCircle />}</span>
                              </div>

                              <div className="display-flex flex-direction-column">
                                <span className="width-fit-content">{paymentMethod?.billing_details?.name ?? 'No Name'}</span>
                                <span>**** {paymentMethod?.card?.last4 ?? ""} Expiry: {paymentMethod?.card?.exp_month ?? ""}/{paymentMethod?.card?.exp_year ?? ""}</span>
                              </div>

                              <div style={{ position: 'absolute', right: '5%', top: '-10%' }}>
                                <span>{cardTypesIcons[paymentMethod?.card?.brand] ?? paymentMethod?.card?.brand}</span>
                              </div>

                            </div>
                          </div>



                        </div>) : null}
                      <div style={{ maxWidth: '550px' }} className="display-flex flex-wrap column-gap-15-px row-gap-15-px justify-content-center">

                        <button onClick={() => { handleAddingNewPayment(true) }} className="popup-submit-button Add-Payment-Button" type="submit">Add New Payment</button>
                        <button onClick={() => { handleUpdatePaymentMethod() }} className={`${!(userSubscriptions && userSubscriptions[0] && activePaymentMethodId && activePaymentMethodId !== userSubscriptions[0]?.default_payment_method) ? "Settings-Submit-Disabled" : "popup-submit-button Settings-Submit"}`} type="submit" disabled={!(userSubscriptions && userSubscriptions[0] && activePaymentMethodId && activePaymentMethodId !== userSubscriptions[0]?.default_payment_method)}>Update Billing</button>
                      </div>
                    </> :

                    <div className="card-form height-fit-content box-shadow-none padding-10-px">


                      <div className="card-input">
                        <label htmlFor="cardNumber" className="card-input__label">
                          Card Number
                        </label>
                        <input
                          type="tel"
                          name="cardNumber"
                          className="card-input__input"
                          autoComplete="off"
                          onChange={(e) => { updateNewTask("card_number", e.currentTarget.value); }}
                          maxLength="19"

                          value={newTask.card_number}
                        />
                      </div>

                      <div className="card-input">
                        <label htmlFor="cardName" className="card-input__label">
                          Card Holder
                        </label>
                        <input
                          type="text"
                          className="card-input__input"
                          autoComplete="off"
                          name="cardHolder"
                          value={newTask.card_name}
                          onChange={(e) => { updateNewTask("card_name", e.currentTarget.value); }}

                        />
                      </div>

                      <div className="card-form__row">
                        <div className="card-form__col">
                          <div className="card-form__group">
                            <label
                              htmlFor="cardMonth"
                              className="card-input__label"
                            >
                              Expiration Date
                            </label>
                            <select
                              className="card-input__input -select"
                              value={newTask.card_month}
                              name="cardMonth"
                              onChange={(e) => { updateNewTask("card_month", e.currentTarget.value); }}

                            >
                              <option value="" disabled>
                                Month
                              </option>

                              {monthsArr.map((val, index) => (
                                <option key={index} value={val}>
                                  {val}
                                </option>
                              ))}
                            </select>
                            <select
                              name="cardYear"
                              className="card-input__input -select"
                              value={newTask.card_year}
                              onChange={(e) => { updateNewTask("card_year", e.currentTarget.value); }}

                            >
                              <option value="" disabled>
                                Year
                              </option>

                              {yearsArr.map((val, index) => (
                                <option key={index} value={val}>
                                  {val}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="card-form__col -cvv">
                          <div className="card-input">
                            <label
                              htmlFor="cardCvv"
                              className="card-input__label"
                            >
                              CVV
                            </label>
                            <input
                              type="tel"
                              className="card-input__input"
                              maxLength="4"
                              autoComplete="off"
                              name="cardCvv"
                              value={newTask.card_cvv}
                              onChange={(e) => { updateNewTask("card_cvv", e.currentTarget.value); }}


                            />

                          </div>

                        </div>
                      </div>
                      <div className="display-flex flex-wrap column-gap-15-px row-gap-15-px justify-content-center">

                        <button onClick={() => { handleAddingNewPayment(false) }} className="popup-submit-button Add-Payment-Button" type="submit">Cancel New Payment</button>
                        <button onClick={() => { addNewCard() }} className="popup-submit-button Add-Payment-Button" type="submit">Add Card</button>
                      </div>
                    </div>
                  }
                </div>
              </div>

            </>
            : null}

          {tab === 8 ?
            <>
              <div className="Settings-Panel display-flex flex-direction-column">
                <div className="Settings-Heading">
                  <h5 className="wrap-none grey">Invitation Email Settings</h5>
                </div>

                <div className="card-form height-fit-content padding-10-px box-shadow-none margin-top-20-px">
                  <span className="grey ">Control whether or not we send out an invitation email on your behalf when you add clients, and customize your welcome message. </span>
                  <div className="display-flex flex-wrap column-gap-5-px row-gap-5-px margin-top-20-px">
                    <span className="grey">Send welcome email:</span>
                    <div className="admin-toggle-buttons-container-small margin-left-10-px">
                      <div style={{ border: !welcomeEmailObject?.allow_sending ? '2px solid rgb(136, 154, 172)' : '' }} className="toggle-buttons-small">
                        <button
                          className={`toggle-button-small ${!welcomeEmailObject?.allow_sending ? "toggle-button-selected-off" : ""
                            }`}
                          onClick={() => setWelcomeEmailObject({ ...welcomeEmailObject, "allow_sending": false })}
                        >
                        </button>
                        <button
                          className={`toggle-button-small ${welcomeEmailObject?.allow_sending ? "toggle-button-selected" : ""
                            }`}
                          onClick={() => setWelcomeEmailObject({ ...welcomeEmailObject, "allow_sending": true })}
                        >
                        </button>

                      </div>
                    </div>
                  </div>
                  </div>
                  <div className="Settings-Heading margin-top-15-px">
                  <h5 className="wrap-none grey">Edit Welcome Message</h5>
                  </div>
                  
                
                <div className="card-form height-fit-content padding-10-px box-shadow-none margin-top-20-px">
                <span className="grey ">{`Write your own welcome message for that personal touch. To include the recipient's name, simply write {{homeowner-name}} and it will be replaced with their name in the email. You also have the option to insert an image.`} </span>


                  <div style={{ height: '300px', width: '100%', overflow: 'auto', scrollbarWidth: 'thin' }} id='welcome-email' contentEditable="true" dangerouslySetInnerHTML={{ __html: welcomeEmailObject?.content }} className="Input-Style Email-Message margin-top-20-px">


                  </div>
                  <div className="display-flex column-gap-15-px margin-top-30-px justify-content-center">
                  <input className="display-none" type="file" id="agentWelcomEmailImg" accept="image/*" onChange={(e) => uploadAgentWelcomEmailImg(e)} />

                  <button onClick={() => { let inp = document.getElementById("agentWelcomEmailImg"); if (inp) { inp.click() } }} className="popup-submit-button">{welcomeEmailObject?.image?.data || welcomeEmailObject?.image?.new  ? 'Update Image' : 'Insert Image'}
                    {/* {welcomeEmailObject?.image?.data || welcomeEmailObject?.image?.new  ?
                    <FontAwesomeIcon className="margin-left-10-px" icon={faCheck}/>
                    : null
                    
                  } */}
                  </button>
                  {welcomeEmailObject?.image?.data || welcomeEmailObject?.image?.new  ?
                                    <button onClick={() => { setWelcomeEmailObject({ ...welcomeEmailObject, "image": { "old": welcomeEmailObject?.image?.data, "new": null } }); }} className="popup-submit-button">Remove Image</button>
: null}
                  <button onClick={() => setWelcomePreview(true)} className="popup-submit-button">Preview</button>
                  <button onClick={() => updateWelcomeEmail()} className="popup-submit-button">Save</button>
                  </div>




                </div>



              </div>

            </> : null}


          {tab === 3 && (user?.role_id === 2 || user?.role_id === 3) ?
            <>
              <div className="Settings-Panel display-flex flex-direction-column">
                <div className="Settings-Heading">
                  <h5 className="wrap-none grey">Your Rating</h5>
                </div>
                <div className='block reviews'>

                  <div className="position-relative" onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>

                    <StarRating
                      readOnly={true}
                      dashboard={false}

                    />
                    {/* <div className={show ? "settings-hover-popup visibility-visible" : "hover-popup visibility-hidden"} >
                      {user?.average_rating ?
                        <span>Your average rating is {user?.average_rating}</span> : <span>You don't have any ratings yet</span>}
                    </div> */}
                  </div>

                </div>
              </div>

            </>
            : null}

          {tab === 3 && user?.role_id === 1 && !(user?.solo_subscribed) ?
            <>
              <div className="Settings-Panel display-flex flex-direction-column">
                <div className="Settings-Heading">
                  <h5 className="wrap-none grey">Review Your Agent</h5>
                </div>
                <div className="reviews" onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
                  <StarRating
                    readOnly={false}
                    dashboard={false}
                    links={socialLinks}
                  />


                </div>
              </div>

            </>
            : null}


          {tab === 4 ?
            <>
              <PopupMessage title={'Thanks!'} message={'Your preferences have been updated'} onConfirm={() => setSubmitted(false)} show={submitted} />
              <div className="Settings-Panel display-flex flex-direction-column">
                {user?.role_id === 1 ?
                  <>
                    <div className="Settings-Heading">
                      <h5 className="wrap-none grey">Deleted Tasks</h5>
                    </div>
                    {optedOutTasks.length > 0 ?
                      <>
                        <h6 className="grey padding-10-15  margin-top-30-px">Select any tasks that you would like us to resume scheduling for you</h6>
                        <div style={{ minHeight: '100px', maxHeight: '250px', overflow: 'auto' }} >
                          {
                            optedOutTasks.map((task, index) => (
                              <div style={{ padding: '0 15px' }} className={`display-flex align-items-center  ${checkedState[index] ? 'background-color-light-blue' : ''}`}>
                                <input type='checkbox' checked={checkedState[index] || false} onChange={() => handleSelectionChange(index)} />
                                <p className="margin-left-10-px margin-top-10-px">{task.title}</p>
                              </div>
                            ))
                          }
                        </div>
                        <div className="display-flex justify-content-end">
                          <button className='popup-submit-button margin-top-15-px' type='submit' onClick={() => optIntoTask()}>
                            Update
                          </button>


                        </div>
                      </>
                      :
                      <h6 style={{ margin: '30px 0' }} className="grey padding-10-15  margin-top-20-px">You haven't deleted any tasks</h6>
                    }
                  </> : null}

                <div className="Settings-Heading">
                  <h5 className="wrap-none grey">Email Preferences</h5>
                </div>

                <h6 className="grey padding-10-15  margin-top-30-px">Update which emails you would like to receive</h6>
                <div style={{ minHeight: '100px', overflow: 'auto' }}  >
                  {Object.entries(emailCategories).map(([key, value]) =>

                    Object.entries(value).filter((type) => parseInt(type) === parseInt(user?.role_id)).map(([k, v]) =>
                      <div style={{ padding: '0 15px' }} className={`display-flex column-gap-10-px ${subTypes[v.KEY] && !subscriptions?.includes('ALL') ? 'background-color-light-blue' : ''}`}>

                        <input type="checkbox" checked={subTypes[v.KEY] && !subscriptions?.includes('ALL')} onChange={(e) => updateTypes(v.KEY, e.target.checked)}></input>
                        <p className="margin-left-10-px margin-top-10-px">{v.DESCRIPTION}</p>
                      </div>
                    )
                  )}
                </div>
                <div className="display-flex justify-content-end">
                  <button className='popup-submit-button margin-top-15-px' type='submit' onClick={() => unsubscribe()}>
                    Update
                  </button>

                </div>


              </div>


            </> : null}
          {tab === 7 && (user?.role_id === 2 || user?.role_id === 5 || user?.role_id === 6)  ?
            <div className="width-100-psnt display-flex flex-direction-column column-gap-20-px row-gap-20-px">
              {userInvoices ? userInvoices?.map((item, index) =>
                <>


                  <div className={`Settings-Invoice-Div ${selectedInvoiceIndex !== index ? "no-print" : ""}`} key={`${index}_invoice`} id={`${index}_pdf-content`}>
                    <span className="align-text-left width-100-psnt bold margin-bottom-10-px">{`Honeyydo Order ID ${item?.number}`}</span>
                    <span className="align-text-left width-100-psnt bold margin-bottom-10-px">{timeUtility.getTimeFromSecondsToString(item?.created)}</span>

                    <span className="Invoice-Line-Break"></span>
                    <table className="Invoice-Table">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Items</th>
                          <th>Duration</th>
                          <th>Amount</th>
                          <th>Discount</th>
                          <th>Charged</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{item?.lines?.data?.length > 0 ? item?.lines?.data[0].type : "Subscription"}</td>
                          <td>1</td>
                          <td>{item?.lines?.data?.length > 0 ? item?.lines?.data[0].description.split(" ")[2] ?? "" : ""}</td>
                          <td>${parseInt(item?.subtotal) / 100}</td>
                          <td>{item?.discount ? item.discount + "%" : "0%"}</td>
                          <td>${parseInt(item.total) / 100}</td>
                          <td>✓</td>

                        </tr>
                        <tr>
                          <td>Subtotal</td>
                          <td>{""}</td>
                          <td>{""}</td>
                          <td>${parseInt(item?.subtotal) / 100}</td>
                          <td>{""}</td>
                          <td>${parseInt(item?.total) / 100}</td>
                          <td>{""}</td>

                        </tr>
                      </tbody>
                    </table>

                  </div>
                  <div className="display-flex flex-wrap width-100-psnt column-gap-10-px row-gap-10-px no-print">
                      <div className="cursor-pointer" onClick={() => { setSelectedInvoiceIndex(index); print(); }}>
                        <FontAwesomeIcon
                          icon={faPrint}
                          className="print-icon"
                        />
                        <span>Print</span>
                      </div>
                      <button className="Invoice-Button" onClick={()=> handleDownloadPDF(index)}>Download PDF</button>
                    </div>
                  <div className="Settings-Separator-Div"></div>
                </>
              ) :
                <Spinner />
              }
            </div> : null
          }
        </div>
      </div>
      <PopupMessage onConfirm={() => { setServerMessage(null) }} message={serverMessage?.message} title={serverMessage?.title} show={serverMessage !== null} />
      <Modal
                show={welcomePreview}
                onHide={() => setWelcomePreview(null)}
                keyboard={false}
                size="lg"
                focus={true}
            >
                <ModalBody >

                    <div className="display-flex justify-content-center flex-wrap">

                        <div dangerouslySetInnerHTML={{
                            __html: emailTemplateWrapper()
                        }}></div>
                    </div>

                </ModalBody>
                <Modal.Footer >

                    <Button variant="secondary height-40-px" onClick={() => setWelcomePreview(null)}>
                        Close
                    </Button>



                </Modal.Footer>

            </Modal>
    </>


  );
};


export default Settings;




































