import React, { useEffect, useRef, useState } from "react"
import stripeService from "../../../services/stripeService";
import "./adminStripeControl.css";
import sqlService from "../../../services/sqlService";
import { Modal } from "react-bootstrap";
import searchUtility from "../../../functions/searchUtility";
import { AiOutlineSearch } from "react-icons/ai";
export default function AdminStripControl() {

    const [percentOff, setPercentOff] = useState(null);
    const [durationInMonths, setDurationInMonths] = useState(null);
    const [maxRedemption, setMaxRedemption] = useState(null);
    const [couponName, setCouponName] = useState("");
    const [refresh, setRefresh] = useState(false);
    const [duration, setDuration] = useState("once");

    const [percentOff1, setPercentOff1] = useState(null);
    const [durationInMonths1, setDurationInMonths1] = useState(null);
    const [maxRedemption1, setMaxRedemption1] = useState(null);
    const [couponName1, setCouponName1] = useState("");
    const [couponCode, setCouponCode] = useState(null);

    const [coupons, setCoupons] = useState(null);
    const couponsRef = useRef(null);

    const loaded = useRef(false);

    const [selectedCouponId, setSelectedCouponId] = useState(null);
    const [subscriptionPlan, setSubscriptionPlan] = useState("monthly");

    const [associations, setAssociations] = useState(null);
    const [association, setAssociation] = useState(null);

    const [usersToCoupons, setUsersToCoupons] = useState([]);

    const [showSelectedCoupon, setShowSelectedCoupon] = useState(null);
    const [allUsers, setAllUsers] = useState(null);
    const [searchKeywordFilter, setSearchKeywordFilter] = useState("");
    const [minDiscountPercentageFilter, setMinDiscountPercentageFilter] = useState(0);
    const [maxDiscountPercentageFilter, setMaxDiscountPercentageFilter] = useState(100);
    const [filteredCoupons, setFilteredCoupons] = useState([]);
    const [selectedDurationFilter, setSelectedDurationFilter] = useState("All");
    const [customDurationFilter, setCustomDurationFilter] = useState(2);

    function createCoupon() {
        console.log(duration)
        stripeService.createCoupon({ input: { percent_off: percentOff, duration: duration, duration_in_months: durationInMonths ?? 1, max_redemptions: maxRedemption, name: couponName, subscriptionPlan: subscriptionPlan, association: association, coupon_code: couponCode } }).then(d => {
            loaded.current = false;
            setRefresh(refresh => !refresh);
        }).catch(err => {
            console.log(err);
        })
    }

    function modifyCoupon() {
        stripeService.modifyCoupon({ input: { coupon_id: selectedCouponId, name: couponName1 } }).then(d => {
            loaded.current = false;
            setRefresh(refresh => !refresh);
            setSelectedCouponId(null);
        }).catch(err => {
            console.log(err);
            setSelectedCouponId(null);
        })
    }

    function deleteCoupon(id, strip_id) {
        console.log(id, strip_id);
        stripeService.deleteCoupon({ input: { coupon_id: id, coupon_id_stripe: strip_id } }).then(d => {
            loaded.current = false;
            setRefresh(refresh => !refresh);
            setSelectedCouponId(null);
        }).catch(err => {
            console.log(err);
            setSelectedCouponId(null);
        })
    }

    async function getCoupons() {
        var hasMore = false;
        var allCoupons = [];
        var starting_after = null;
        do {
            await stripeService.getAllCoupons({ input: { starting_after: starting_after } }).then(d => {
                let data = d.data;
                console.log(data)
                hasMore = data?.has_more
                if(data?.data?.length > 0){
                    starting_after = data?.data[data?.data?.length - 1]?.id;
                }else{
                    hasMore = false;
                }
                allCoupons = [...allCoupons, ...data.data];
                console.log(starting_after, hasMore)
            }).catch(err => {
                hasMore = false;
                console.log(err)
            })
        } while (hasMore)
        console.log(allCoupons)
        setCoupons(allCoupons);
        couponsRef.current = allCoupons;
    }

    async function getAssociations() {
        sqlService.getAllAssociations().then(d => {
            console.log(d);
            setAssociations(d)
        }).catch(err => {
            console.log(err);
        })
    }

    async function getUsersToCoupons() {
        sqlService.getAllUsersToCoupons().then(d => {
            console.log(d);
            setUsersToCoupons(d);
        }).catch(err => {
            console.log(err);
        })
    }

    async function getAllUsers() {
        sqlService.getAllUsers().then(d => {
            console.log(d);
            setAllUsers(d);
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        if (!loaded.current) {
            loaded.current = true;
            getCoupons()
            getAssociations()
            getUsersToCoupons()
            getAllUsers()
        }
    }, [refresh])

    useEffect(() => {
        var filtered = couponsRef.current ?? [];
        if (searchKeywordFilter !== "") {
            let filt = searchUtility.filterBySearchArrayOfObjects(couponsRef.current, searchKeywordFilter)
            if (filt) {
                filtered = filt;
            }
        }
        if (minDiscountPercentageFilter) {
            filtered = filtered?.filter(item => item?.percent_off >= minDiscountPercentageFilter);
        }
        if (maxDiscountPercentageFilter) {
            filtered = filtered?.filter(item => item?.percent_off <= maxDiscountPercentageFilter);
        }
        if (selectedDurationFilter !== "All") {
            if (selectedDurationFilter === "Repeating") {
                filtered = filtered?.filter(item => item?.duration === "repeating" && item?.duration_in_months?.toString() === customDurationFilter?.toString());
            } else if (selectedDurationFilter === "Once") {
                filtered = filtered?.filter(item => item?.duration === "once");
            } else if (selectedDurationFilter === "Forever") {
                filtered = filtered?.filter(item => item?.duration === "forever");
            }
        }
        setFilteredCoupons(filtered);
    }, [searchKeywordFilter, minDiscountPercentageFilter, maxDiscountPercentageFilter, coupons, selectedDurationFilter, customDurationFilter])

    const getAssociationName = (id) => {
        if (!id) {
            return "";
        }
        let a = associations?.filter(item => item?.id == id)
        if (a && a[0]) {
            return a[0].name
        }
        else {
            return "";
        }
    }

    const getCouponeUsers = (id) => {
        console.log(usersToCoupons, id)
        let c = usersToCoupons.filter(item => parseInt(item.coupon_id) === parseInt(id))
        console.log(c)
        if (c) {
            return c;
        }
        return [];
    }

    const getUserById = (id) => {
        console.log(allUsers, id)
        let c = allUsers?.filter(item => parseInt(item.id) === parseInt(id))
        console.log(c)
        if (c) {
            return c[0];
        }
        return {};
    }

    return (
        <div className="display-flex flex-direction-column flex-wrap justify-content-center align-items-center margin-top-50-px">
            <h4 className="coupon-heading">Create New Coupon</h4>
            <div style={{ minWidth: '375px', padding: '10px', rowGap: '5px' }} className="display-flex flex-direction-column flex-wrap coupon-cell-div ">
                <div className="display-flex justify-content-left align-items-center column-gap-5-px">
                    <span className="width-100-px" style={{ minWidth: '65px' }}>Name:</span>
                    <input className="flex-grow-1" type="text" value={couponName} onChange={(e) => { setCouponName(e.currentTarget.value) }} placeholder="Coupon Name" />
                </div>
                <div className="display-flex justify-content-left align-items-center column-gap-5-px">
                    <span className="width-100-px" style={{ minWidth: '65px' }}>Code:</span>
                    <input className="flex-grow-1" type="text" value={couponCode} onChange={(e) => { setCouponCode(e.currentTarget.value) }} placeholder="Coupon Code" />
                </div>
                <div className="display-flex justify-content-left align-items-center column-gap-5-px">
                    <span className="width-100-px" style={{ minWidth: '65px' }}>Percent Off:</span>
                    <input className="flex-grow-1" type="number" value={percentOff} onChange={(e) => { setPercentOff(e.currentTarget.value) }} placeholder="Percent Off" />
                </div>
                <div className="display-flex justify-content-left align-items-center column-gap-5-px">
                    <span className="width-100-px">Duration:</span>
                    <select className="flex-grow-1" style={{ height: '30px' }} onChange={(e) => { setDuration(e.target.value) }} value={duration}>
                        <option value={"once"}>One Month</option>
                        <option value={"repeating"}>Custom</option>
                        <option value={"forever"}>Forever</option>
                    </select>
                    {duration === "repeating" ?
                        <input type="number" value={durationInMonths} onChange={(e) => { setDurationInMonths(e.currentTarget.value) }} placeholder="Duration in Months" />
                        : null
                    }
                </div>
                <div className="display-flex justify-content-left align-items-center column-gap-5-px">
                    <span className="width-100-px" style={{ minWidth: '65px' }}>Redemption:</span>
                    <input className="flex-grow-1" type="number" value={maxRedemption} onChange={(e) => { setMaxRedemption(e.currentTarget.value) }} placeholder="Max Redemptions" />
                </div>
                <div className="display-flex justify-content-left align-items-center column-gap-5-px">
                    <span className="width-100-px" style={{ minWidth: '65px' }}>Plan:</span>
                    <select className="flex-grow-1" style={{ height: '30px' }} value={subscriptionPlan} onChange={(e) => { setSubscriptionPlan(e.target.value) }}>
                        <option value={"monthly"}>Monthly</option>
                        <option value={"yearly"}>Yearly</option>
                    </select>
                </div>
                {associations?.length ?
                    <div className="display-flex justify-content-left align-items-center column-gap-5-px">
                        <span className="width-100-px" style={{ minWidth: '65px' }}>Association:</span>
                        <select className="flex-grow-1" style={{ height: '30px' }} value={association} onChange={(e) => { setAssociation(e.target.value) }}>
                            <option value={null}>{"No association"}</option>
                            {associations.map(item =>
                                <option value={item.id}>{item.name}</option>
                            )}
                        </select>
                    </div>
                    : null
                }

                <button style={{ margin: '15px auto 10px auto' }} className="Tag-Button" onClick={() => { createCoupon() }}>Create</button>
            </div>

            <h4 className="coupon-heading">Existing Coupons</h4>

            <div className="display-flex flex-wrap column-gap-10-px row-gap-10-px aling-items-center justify-content-center">
                <span className="font-size-20-px bold">Filters</span>
                <div className="Search-Bar-Focus position-relative">
                    <span
                        className="position-absolute left-8-px top-4-px purple border-radius-5-px"
                    >
                        <AiOutlineSearch />
                    </span>
                    <input
                        className="Search-Bar"
                        type="text"
                        onChange={(e) => { setSearchKeywordFilter(e.currentTarget.value) }}
                        placeholder={"Search Coupons"}
                    />
                </div>
                <div class="range_container">
                    <div class="sliders_control">
                        <input className="Admin_Range_Style" id="fromSlider" type="range" min="0" max={maxDiscountPercentageFilter ?? 100} value={minDiscountPercentageFilter ?? 0} onChange={(e) => { setMinDiscountPercentageFilter(e.currentTarget.value) }} />
                        <input className="Admin_Range_Style" id="toSlider" type="range" value={maxDiscountPercentageFilter ?? 100} min={minDiscountPercentageFilter} max={100} onChange={(e) => { setMaxDiscountPercentageFilter(e.currentTarget.value) }} />
                    </div>
                    <div class="form_control">
                        <div class="form_control_container">
                            <div class="form_control_container__time">Min</div>
                            <input class="form_control_container__time__input Admin_Range_Number" onChange={(e) => { setMinDiscountPercentageFilter(e.currentTarget.value) }} type="number" id="fromInput" value={minDiscountPercentageFilter ?? 0} min="0" max={maxDiscountPercentageFilter ?? 100} />
                        </div>
                        <div>
                            <span className="">Percentage off</span>
                        </div>
                        <div class="form_control_container">
                            <div class="form_control_container__time">Max</div>
                            <input class="form_control_container__time__input Admin_Range_Number" type="number" id="toInput" onChange={(e) => { setMaxDiscountPercentageFilter(e.currentTarget.value) }} value={maxDiscountPercentageFilter} min={minDiscountPercentageFilter} max="100" />
                        </div>
                    </div>
                </div>
                <div className="display-flex flex-direction-column aling-items-center">
                    <span className="bold">Duration</span>
                    <div className="display-flex flex-wrap">
                        <span onClick={() => { setSelectedDurationFilter("All") }} className={`${selectedDurationFilter === "All" ? "Stripe-Selected-Duration" : ""} Coupon-Duration-Button Coupon-Left-Border Coupon-Right-Border`}>All</span>
                        <span onClick={() => { setSelectedDurationFilter("Once") }} className={`${selectedDurationFilter === "Once" ? "Stripe-Selected-Duration" : ""} Coupon-Duration-Button Coupon-Right-Border`}>Once</span>
                        <span onClick={() => { setSelectedDurationFilter("Repeating") }} className={`${selectedDurationFilter === "Repeating" ? "Stripe-Selected-Duration" : ""} Coupon-Duration-Button Coupon-Right-Border`}>Repeating</span>
                        <span onClick={() => { setSelectedDurationFilter("Forever") }} className={`${selectedDurationFilter === "Forever" ? "Stripe-Selected-Duration" : ""} Coupon-Duration-Button Coupon-Right-Border-Curve`}>Forever</span>
                    </div>
                    {selectedDurationFilter === "Repeating" ?
                        <input type="number" min="0" max="1000" value={customDurationFilter ?? 1} onChange={(e) => { setCustomDurationFilter(e.currentTarget.value) }} />
                        : null
                    }
                </div>

            </div>

            <div className="display-flex flex-wrap justify-content-center aling-items-center column-gap-10-px row-gap-10-px">

                {filteredCoupons?.length ?
                    filteredCoupons.map(item =>
                        <div style={{ width: '350px', minHeight: '300px' }} className={` coupon-cell-div ${parseInt(item?.times_redeemed) >= parseInt(item?.max_redemptions) ? "background-color-red" : ""}`}>
                            {/* <div className="display-flex justify-content-center align-items-center column-gap-5-px">
                                <span className="Tag-Button" onClick={() => { console.log(item.id); setSelectedCouponId(item.id); setCouponName1(item.name); setDurationInMonths1(item.duration_in_months); setMaxRedemption1(item.max_redempiton); setPercentOff1(item.percent_off) }}>Edit Name</span>
                                <span className="Tag-Button" onClick={() => { modifyCoupon() }}>Submit</span>
                                <span className="Tag-Button" onClick={() => { deleteCoupon(item?.id) }}>Delete</span>
                            </div> */}
                            <div style={{ marginTop: '15px' }} className="display-flex justify-content-left align-items-center column-gap-5-px">
                                <div className="coupon-label-div">
                                    <span >Name:</span>
                                </div>
                                {selectedCouponId !== item?.stripe_coupon_code ?
                                    <span className="bold">{item?.name}</span>
                                    :
                                    <input className="existing-coupon-input" type="text" value={selectedCouponId === item?.stripe_coupon_code ? couponName1 : item?.name} onChange={(e) => { setCouponName1(e.currentTarget.value) }} placeholder="Coupon Name" disabled={selectedCouponId !== item?.stripe_coupon_code} />
                                }
                            </div>
                            <div className="display-flex justify-content-left align-items-center column-gap-5-px">
                                <div className="coupon-label-div">
                                    <span >Code:</span>
                                </div>
                                <code className="bold background5">{item?.metadata?.coupon_code ?? item?.stripe_coupon_code}</code>

                            </div>
                            <div className="display-flex justify-content-left align-items-center column-gap-5-px">
                                <div className="coupon-label-div">
                                    <span >Percentage:</span>
                                </div>
                                <span className="bold">{item?.percent_off}</span>
                            </div>
                            <div className="display-flex justify-content-left align-items-center column-gap-5-px">
                                <div className="coupon-label-div">
                                    <span >Duration:</span>
                                </div>
                                <span className="bold">{item?.duration}</span>

                                {item.duration === "repeating" ?
                                    <input className="width-100-px" value={selectedCouponId === item.id ? durationInMonths1 : `${item?.duration_in_months} months`} onChange={(e) => { setDurationInMonths1(e.currentTarget.value) }} placeholder="Duration in Months" disabled />
                                    : null
                                }
                            </div>
                            <div className="display-flex justify-content-left align-items-center column-gap-5-px">
                                <div className="coupon-label-div">
                                    <span >Max Uses:</span>
                                </div>
                                <span className="bold">{item?.max_redemptions}</span>
                            </div>
                            <div className="display-flex justify-content-left align-items-center column-gap-5-px">
                                <div className="coupon-label-div">
                                    <span >Plan:</span>
                                </div>
                                <span className="bold">{item?.metadata?.subscriptionPlan}</span>
                            </div>
                            <div className="display-flex justify-content-left align-items-center column-gap-5-px">
                                <div className="coupon-label-div">
                                    <span >Redeemed:</span>
                                </div>
                                <span>{item?.times_redeemed}</span>
                            </div>
                            <div className="display-flex justify-content-left align-items-center column-gap-5-px">
                                <div className="coupon-label-div">
                                    <span >Association:</span>
                                </div>
                                <span className="background5">{getAssociationName(item?.metadata?.association_id)}</span>
                            </div>
                            <div className="display-flex justify-content-center align-items-center column-gap-5-px margin-10-px">
                                <div className="coupon-label-div">
                                    <span onClick={() => { console.log(item); setShowSelectedCoupon(item) }} className="background1 border-radius-5-px padding-5-px cursor-pointer">Show Users</span>
                                </div>
                            </div>
                            <div className="display-flex justify-content-center align-items-center column-gap-5-px margin-10-px">
                                <span className="Tag-Button cursor-pointer" onClick={() => { console.log(item.id); setSelectedCouponId(item?.stripe_coupon_code); setCouponName1(item.name); setDurationInMonths1(item.duration_in_months); setMaxRedemption1(item.max_redempiton); setPercentOff1(item.percent_off) }}>Edit Name</span>
                                <span className="Tag-Button cursor-pointer" onClick={() => { modifyCoupon() }}>Submit</span>
                                <span className="Tag-Button cursor-pointer" onClick={() => { console.log(item); deleteCoupon(item?.id, item?.stripe_coupon_code) }}>Delete</span>
                            </div>
                            {parseInt(item?.times_redeemed) >= parseInt(item?.max_redemptions) ?
                                <div className="display-flex justify-content-center ">
                                    <h5>No Remaining Redemptions</h5>
                                </div>
                                : null}

                        </div>
                    )
                    : "No coupons found"}

            </div>

            <Modal
                show={showSelectedCoupon}
                onHide={() => { setShowSelectedCoupon(null) }}
                backdrop="static"
                keyboard={false}
                size="lg"
                className="overflow-y-scroll"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{showSelectedCoupon?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showSelectedCoupon ?
                        <div className="display-flex flex-direction-column row-gap-10-px colum-gap-10-px">
                            {getCouponeUsers(showSelectedCoupon?.id).map((item, index) =>
                                <div className="background4 display-flex flex-wrap column-gap-10-px row-gap-10-px">
                                    <span>{index + 1}</span>
                                    <span>{getUserById(item.user_id).contact_name}</span>
                                    <span>{getUserById(item.user_id).email}</span>
                                    <span>{getUserById(item.user_id).phone_number}</span>
                                    <span>{getUserById(item.user_id).address}</span>
                                    <span>{getUserById(item.user_id).city}</span>
                                    <span>{getUserById(item.user_id).role_id}</span>
                                </div>
                            )}
                        </div> : null
                    }

                </Modal.Body>

            </Modal>
        </div>
    )
}