import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import sqlService from "../../../services/sqlService";
import emailCategories from "../../../constants/emailCategoriesConstants";

export default function Unsubscribe() {
    const { roleId, code, email } = useParams()
    const [subTypes, setSubtypes] = useState({})
    const [submitted, setSubmitted] = useState(false)
    const submittedRef = useRef(false);
    useEffect(() => {
        var temp = {}

        for (let [key, value] of Object.entries(emailCategories)) {
            for (let [k, v] of Object.entries(value)) {
  
                if (parseInt(k) === parseInt(roleId)) {
                    temp[v.KEY] = true;
                    

                }

            }
        }
        setSubtypes(temp)


        }, [])


    function updateTypes(key, val) {
        var temp = { ...subTypes }
        temp[key] = val;
        setSubtypes(temp)
        console.log(temp)
    }

    function unsubscribe() {
        sqlService.unsubscribe({ input: { subTypes: subTypes, code: code } }).then(() => {
            setSubmitted(true);
        }).catch((err) => {
            console.log(err);
        })
    }

    
    function unsubscribeEmail() {
        debugger;
        sqlService.unsubscribe({ input: { subTypes: "email", email: email } }).then(() => {
            setSubmitted(true);
            submittedRef.current = true;
        }).catch((err) => {
            console.log(err);
        })
    }
    
    function unsubscribeAll() {
        sqlService.unsubscribe({ input: { subTypes: { "ALL": false }, code: code } }).then(() => {
            setSubmitted(true)
        }).catch((err) => {
            console.log(err);
        })
    }

    return (
        <>
        {email ? 
          <div className="padding-top-10-percent">
            <h4 className="grey heading padding-sides-10-pt">By clicking unsubscribe you will no longer recieve emails from Honeyydo!</h4>
            <div className="display-flex flex-direction-column margin-auto-lr width-fit-content row-gap-10-px">
            <button onClick={unsubscribeEmail} className="popup-submit-button" disabled={submittedRef.current}>Unsubscribe</button>
        </div>
        </div>
        :
        <div className="padding-top-10-percent">
        <h4 className="grey heading padding-sides-10-pt">Select which emails you would like to receive</h4>
        <div className="display-flex flex-direction-column margin-auto-lr width-fit-content row-gap-20-px padding-40-px">
            {Object.entries(emailCategories).map(([key, value]) =>

                Object.entries(value).filter((type) => parseInt(type) === parseInt(roleId)).map(([k, v]) =>
                    <div className="display-flex column-gap-10-px">

                        <input type="checkbox" checked={subTypes[v.KEY]} onChange={(e) => updateTypes(v.KEY, e.target.checked)}></input>
                        <span>{v.DESCRIPTION}</span>
                    </div>
                )
            )}
            {/* <div className="display-flex">

                <input type="checkbox" onChange={(e) => updateTypes("ALL", e.target.checked)}></input>
                <span>All emails</span>
            </div> */}

            
        </div>
        <div className="display-flex flex-direction-column margin-auto-lr width-fit-content row-gap-10-px">
            <button onClick={unsubscribe} className="popup-submit-button">Submit</button>
            <button onClick={unsubscribeAll} className="unsubscribe-all-button">Unsubscribe from all</button>
        </div>


  
        </div>  }
        {submitted ?
        <>
        <div className="backdrop"></div>
        <div className="popup">
            <h5 className="grey">Thanks!</h5>
            <p className="text-align-center padding-10-px">Your preferences have been updated</p>
            <button onClick={()=>setSubmitted(false)} className="popup-submit-button">Close</button>
        </div>
            </> : null }
        </>
    )
}