
import axios from "axios";
import { userState } from "../App";
import { json } from "react-router-dom";
import serverConstants from "../constants/serverConstants";
import { serverResponseErrActions, getUserFromCookie } from "./requestActions";
axios.defaults.withCredentials = true;



function getHomeowners(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getHomeowners`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAgents(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAgents`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getTasks(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getTasks`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addTask(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addTask`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}



function updateTask(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/updateTask`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function deleteTask(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/deleteTask`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getCabinets(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getCabinets`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getProfessionalsForAgent(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getProfessionalsForAgent`, {
          data: data,
        })
        .then((response) => {
          let ret = response.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addCabinet(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addCabinet`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function editCabinet(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/editCabinet`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function deleteCabinet(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/deleteCabinet`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAgentsPB(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAgentsPB`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addProfessional(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addProfessional`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function updateProfessional(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/updateProfessional`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getBlogs() {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(`${serverConstants.baseURL}/getBlogs`)
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function signup(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/auth/signup`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function skipSignup(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/auth/skip_signup`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function finishSignup(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/auth/finish_signup`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function login(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/auth/login`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}
function getHomeownerById(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getHomeownerById`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAgentById(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAgentById`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getBrokerageById(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getBrokerageById`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}
function updateUser(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/updateUser`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getNotifications(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getNotifications`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getProfessionalsForHomeownerByIndustry(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(
          `${serverConstants.baseURL}/getProfessionalsForHomeownerByIndustry`,
          { data: data }
        )
        .then((response) => {
          let ret = response.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}


// function getAllBrokerages(){
//     return new Promise((resolve, reject)=>{
//         try{
//             axios.get(`${serverConstants.baseURL}/getAllBrokerages`).then(data => {
//                 let ret = data.data;
//                 resolve(ret);
//             }).catch(err => {
//                 reject(err);
//             })
//         }catch(err){
//             reject(err);
//         }
//     })
// }

function getAllBrokerages(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAllBrokerages`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          console.error("Error during /getAllBrokerages request:", err);
          reject(err);
        });
    } catch (err) {
      console.error("Error during promise handling:", err);
      reject(err);
    }
  });
}

function uploadImg(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/uploadImg`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAllAgents(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAllAgents`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAllMortgageAgents(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAllMortgageAgents`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAllInsuranceAgents(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAllInsuranceAgents`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getTasksByCategory(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getTasksByCategory`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAllPros(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAllPros`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getHomeownersByBrokerage(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getHomeownersByBrokerage`, {
          data: data,
        })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAllHomeowners(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAllHomeowners`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getTopAgents(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getTopAgents`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAllTasks(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAllTasks`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addAgent(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addAgent`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          // serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAllBlogs() {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(`${serverConstants.baseURL}/getAllBlogs`)
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

// function getAllLibrary() {
//   return new Promise((resolve, reject) => {
//     tr
//  let user = getUserFromCookie()
//   if(!data.user){
//     data["user"] = user;
//   }
//       axios
//         .get(`${serverConstants.baseURL}/getAllLibrary`)
//         .then((data) => {
//           let ret = data.data;
//           resolve(ret);
//         })
//         .catch((err) => {
//  serverResponseErrActions(err)
//           reject(err);
//         });
//     } catch (err) {
//       reject(err);
//     }
//   });
// }

function deleteAgent(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/deleteAgent`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getReferralsByBrokerage(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getReferralsByBrokerage`, {
          data: data,
        })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}
function updateAgent(data = {}) {
  return new Promise((resolove, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/updateAgent`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolove(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getReferralsByAgent(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getReferralsByAgent`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getProfessionalsByAgent(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getProfessionalsByAgent`, {
          data: data,
        })
        .then((response) => {
          let ret = response.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function postRating(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/postRating`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getRating(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getRating`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addReferral(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addReferral`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getLibraries() {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(`${serverConstants.baseURL}/getLibraries`)
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function updateLead(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/updateLead`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getUserById(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getUserById`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function editAdminBrokerage(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/editAdminBrokerage`, {
          "data": data
        })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function editAdminAgent(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/editAdminAgent`, {
          "data": data
        })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function editAdminMortgageAgent(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/editAdminMortgageAgent`, {
          "data": data
        })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function editAdminInsuranceAgent(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/editAdminInsuranceAgent`, {
          "data": data
        })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function editAdminPro(data = {}) {
  console.log(">><><>>>",data)
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/editAdminPro`, {
          "data": data
        })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function editAdminHomeowner(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/editAdminHomeowner`, {
          "data": data
        })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function editAdminTask(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/editAdminTask`, {
          "data": data
        })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function editAdminBlog(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/editAdminBlog`, {
          "data": data
        })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function editAdminLibrary(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/editAdminLibrary`, {
          "data": data
        })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function deleteAdminAgent(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/deleteAdminAgent`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function deleteAdminMortgageAgent(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/deleteAdminMortgageAgent`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function deleteAdminInsuranceAgent(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/deleteAdminInsuranceAgent`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function deleteAdminBlog(id) {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(`${serverConstants.baseURL}/deleteAdminBlog`, {
          data: { id },
        })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function deleteAdminHomeowner(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/deleteAdminHomeowner`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}


function deleteAdminPro(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/deleteAdminPro`, {
          "data": data,
        })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function deleteAdminTask(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/deleteAdminTask`, {
          "data": data,
        })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function deleteAdminBrokerage(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/deleteAdminBrokerage`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function deleteAdminLibrary(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/deleteAdminLibrary`, {
          "data": data,
        })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}
function addAdminTask(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addAdminTask`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addAdminBrokerage(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addAdminBrokerage`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addAdminAgent(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addAdminAgent`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addAdminMortgageAgent(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addAdminMortgageAgent`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addAdminInsuranceAgent(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addAdminInsuranceAgent`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addAdminBlog(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addAdminBlog`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addAdminHomeowner(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addAdminHomeowner`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}



function addAdminPro(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addAdminPro`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addAdminLibrary(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addAdminLibrary`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAdminBoards(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAdminBoards`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAdminBrokerages(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAdminBrokerages`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAdminAgents(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAdminAgents`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function updateCompletedTask(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/updateCompletedTask`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {

      console.error(err.response.data)
      reject(err);
    }
  });
}

function getRepeatingTasks(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getRepeatingTasks`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {

      console.error(err.response.data)
      reject(err);
    }
  });
}

function editTask(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/editTask`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {

      console.error(err.response.data)
      reject(err);
    }
  });
}

function addLinks(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addLinks`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      console.error(err.response.data)
      reject(err);
    }
  });
}

function getRankedTasks(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getRankedTasks`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      console.error(err.response.data)
      reject(err);
    }
  });
}

function getAuthCodeStatus(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAuthCodeStatus`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      console.error(err.response.data)
      reject(err);
    }
  });
}

function verifyUser(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/verifyUser`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      console.error(err.response.data)
      reject(err);
    }
  });
}

function sendNewCode(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/sendNewCode`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      console.error(err.response.data)
      reject(err);
    }
  });
}

function taskOptBackIn(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/taskOptBackIn`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      console.error(err.response.data)
      reject(err);
    }
  });
}

function getOptedOutTasks(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getOptedOutTasks`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      console.error(err.response.data)
      reject(err);
    }
  });
}

function deleteAwsImage(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/deleteAwsImage`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      console.error(err.response.data)
      reject(err);
    }
  });
}

function uploadEcardImg(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      console.log(user);
      axios
        .post(`${serverConstants.baseURL}/uploadEcardImg`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          console.log(err);
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      console.error(err.response.data)
      reject(err);
    }
  });
}


function uploadFile(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/uploadFile`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function deletePro(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/deletePro`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function passwordResetRequest(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/auth/passwordResetRequest`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function passwordResetConfirm(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/auth/passwordResetConfirm`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function updateUserPassword(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/updateUserPassword`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function finishSettingsUpdate(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/finishSettingsUpdate`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function importAgents(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/importAgents`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAllBoards(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAllBoards`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function updateUserByField(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/updateUserByField`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getBoardsForUser(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getBoardsForUser`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAllUpcomingUniversalTasksWithinThreeWeeks(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAllUpcomingUniversalTasksWithinThreeWeeks`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAllUniversalTasksFromHomeOwnerAdmin(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAllUniversalTasksFromHomeOwnerAdmin`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAllUniversalTasksFromHomeOwnerAdminByCategoryThatHomeowenerIsNotAssignedWithin30Days(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAllUniversalTasksFromHomeOwnerAdminByCategoryThatHomeowenerIsNotAssignedWithin30Days`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAllUniversalTasksFromHomeOwnerAdminByCategoryThatHomeowenerIsNotAssignedWithNoTimeLimit(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAllUniversalTasksFromHomeOwnerAdminByCategoryThatHomeowenerIsNotAssignedWithNoTimeLimit`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAllUniversalTasksFromHomeOwnerAdminThatUserNotOptedOut(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAllUniversalTasksFromHomeOwnerAdminThatUserNotOptedOut`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function optoutTask(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/optoutTask`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getUserNotifications(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getUserNotifications`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function updateUserNotificationToRead(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/updateUserNotificationToRead`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}


function deleteUserNotification(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/deleteUserNotification`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function importSpreadsheet(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/importSpreadsheet`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function updateProByField(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/updateProByField`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}


function getIndustryNames() {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(`${serverConstants.baseURL}/getIndustryNames`)
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}


function getCompanyNames() {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(`${serverConstants.baseURL}/getCompanyNames`)
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function sendReviewRequest(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(`${serverConstants.baseURL}/mail/sendReviewRequest`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function requestQuote(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(`${serverConstants.baseURL}/mail/requestQuote`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function sendEmailToClients(data) {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(`${serverConstants.baseURL}/mail/sendEmailToClients`, { "data": data }).then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}
function getAllBillingInfo(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAllBillingInfo`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function applyCouponCode(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/applyCouponCode`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addEmailToUserEmailQueue(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addEmailToUserEmailQueue`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getScheduledEmailsForUser(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getScheduledEmailsForUser`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function updateScheduledEmail(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/updateScheduledEmail`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}


function getAllAssociations(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAllAssociations`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addAgentEvent(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addAgentEvent`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAgentEvents(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAgentEvents`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAllUsersToAssociations(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAllUsersToAssociations`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function deleteAgentEvent(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/deleteAgentEvent`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAllUsersToCoupons(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAllUsersToCoupons`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}


function updateAgentEvent(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/updateAgentEvent`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAllAssociationsWithTheirUsersAndCoupons(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAllAssociationsWithTheirUsersAndCoupons`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAllUsers(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAllUsers`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addAssociation(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addAssociation`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addNote(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addNote`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getUserNameByInputId(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getUserNameByInputId`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}
function getNotesForUser(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getNotesForUser`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function deleteNote(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/deleteNote`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addNoteForClient(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addNoteForClient`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addImportantDates(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addImportantDates`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getNewsletterQueue(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getNewsletterQueue`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getNewsletterQueueForAgent(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getNewsletterQueueForAgent`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function deleteNewsletter(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/deleteNewsletter`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function modifyNewsletter(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/modifyNewsletter`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function deleteToken(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/deleteToken`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addAdminNote(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addAdminNote`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addNewSpreadSheetRequest(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addNewSpreadSheetRequest`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addHomeowner(data = {}) {

  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addHomeowner`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function loginAdminToUser(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/auth/loginAdminToUser`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getUserActivity(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getUserActivity`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getLoginHistory(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getLoginHistory`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function setWelcomeAsSeen(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/setWelcomeAsSeen`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}


function unsubscribe(data = {}) {
    return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/unsubscribe`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}


function getUserInvoices(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getUserInvoices`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function affiliateRequest(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/mail/affiliateRequest`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getUserSubscriptions(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getUserSubscriptions`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}


function getHomeownerProperty(data = {}) {

  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getHomeownerProperty`, { "data": data })

        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getHomeImprovementRequestsPhotos(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getHomeImprovementRequestsPhotos`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}


function addHomeownerProperty(data = {}) {
    return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addHomeownerProperty`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function uploadImgAws(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/uploadImgAws`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}


function addPropertyImage(data = {}) {
    return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addPropertyImage`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function insertHomeImprovementRequestsPhotos(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/insertHomeImprovementRequestsPhotos`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addPropertyDetails(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addPropertyDetails`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getPropertyDetails(data = {}) {
    return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getPropertyDetails`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function insertHomeImprovementPhotos(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/insertHomeImprovementPhotos`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getHomeownerRenovations(data = {}) {
    return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getHomeownerRenovations`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function updateHomeImprovementRequestPhotos(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/updateHomeImprovementRequestPhotos`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function updateRenovation(data = {}) {
   return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/updateRenovation`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function deleteHomeImprovementRequestImage(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/deleteHomeImprovementRequestImage`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addHomeownerPropertyRenovation(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addHomeownerPropertyRenovation`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}


function addProUser(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addProUser`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}
  
function getProCoupons(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
          .post(`${serverConstants.baseURL}/getProCoupons`, { "data": data })
          .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getProById(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getProById`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function addCoupon(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/addCoupon`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function deleteCoupon(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/deleteCoupon`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function submitProRating(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/submitProRating`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getMortgageAgentById(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getMortgageAgentById`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getInsuranceAgentById(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getInsuranceAgentById`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}


function saveEmailTemplate(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/saveEmailTemplate`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getEmailTemplatesByUserId(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getEmailTemplatesByUserId`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getSignatureTemplatesByUserId(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getSignatureTemplatesByUserId`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getUniversalEmailTemplates(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getUniversalEmailTemplates`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function sendEmailsToSelectedUsersUsingTemplateId(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/sendEmailsToSelectedUsersUsingTemplateId`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}


function getEmailRecords(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getEmailRecords`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getEmailRecordsByUserId(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getEmailRecordsByUserId`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getHomeownersByAgent(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getHomeownersByAgent`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function optAgentNewsletter(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/optAgentNewsletter`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getOptNewsletterStatus(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getOptNewsletterStatus`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function resendInvitation(data = {}) {
  return new Promise((resolve, reject) => {
  try {
    let user = getUserFromCookie()
    if (!data.user) {
      data["user"] = user;
    }
    axios
      .post(`${serverConstants.baseURL}/resendInvitation`, { "data": data })
      .then((data) => {
        let ret = data.data;
        resolve(ret);
      })
      .catch((err) => {
        serverResponseErrActions(err);
        reject(err);
      });
  } catch (err) {
    reject(err);
  }
});
}

function updateAgentWelcomeEmail(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/updateAgentWelcomeEmail`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function getAgentWelcomeEmail(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/getAgentWelcomeEmail`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function deleteEmailTemplate(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/deleteEmailTemplate`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function deleteEmailSignature(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/deleteEmailSignature`, { data: data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err)
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function supportRequest(data = {}) {
  return new Promise((resolve, reject) => {
    try {
      let user = getUserFromCookie()
      if (!data.user) {
        data["user"] = user;
      }
      axios
        .post(`${serverConstants.baseURL}/mail/supportRequest`, { "data": data })
        .then((data) => {
          let ret = data.data;
          resolve(ret);
        })
        .catch((err) => {
          serverResponseErrActions(err);
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

const sqlService = {
  getHomeowners,
  getAgents,
  getTasks,
  addTask,
  updateTask,
  deleteTask,
  getCabinets,
  getProfessionalsForAgent,
  getCabinets,
  addCabinet,
  deleteCabinet,
  getAgentsPB,
  addProfessional,
  updateProfessional,
  getBlogs,
  signup,
  finishSignup,
  skipSignup,
  login,
  updateUser,
  getProfessionalsForHomeownerByIndustry,
  getHomeownerById,
  getNotifications,
  getTasksByCategory,
  getHomeownersByBrokerage,
  getTopAgents,
  addAgent,
  deleteAgent,
  getReferralsByBrokerage,
  getReferralsByAgent,
  postRating,
  uploadImg,
  getRating,
  getAgentById,
  getBrokerageById,
  addReferral,
  updateLead,
  getUserById,
  getLibraries,
  getProfessionalsByAgent,
  getAllBrokerages,
  getAllAgents,
  getAllMortgageAgents,
  getAllInsuranceAgents,
  getAllPros,
  getAllHomeowners,
  getAllTasks,
  getAllBlogs,
  editAdminBrokerage,
  editAdminAgent,
  editAdminMortgageAgent,
  editAdminInsuranceAgent,
  editAdminPro,
  editAdminHomeowner,
  editAdminTask,
  editAdminBlog,
  editAdminLibrary,
  updateAgent,
  deleteAdminBrokerage,
  deleteAdminAgent,
  deleteAdminMortgageAgent,
  deleteAdminInsuranceAgent,
  deleteAdminBlog,
  deleteAdminHomeowner,
  deleteAdminPro,
  deleteAdminTask,
  deleteAdminLibrary,
  addAdminTask,
  addAdminBrokerage,
  addAdminAgent,
  addAdminMortgageAgent,
  addAdminInsuranceAgent,
  addAdminBlog,
  addAdminHomeowner,
  addAdminPro,
  addAdminLibrary,
  getAdminBoards,
  getAdminBrokerages,
  getAdminAgents,
  updateCompletedTask,
  getRepeatingTasks,
  editTask,
  addLinks,
  getRankedTasks,
  getAuthCodeStatus,
  verifyUser,
  sendNewCode,
  taskOptBackIn,
  getOptedOutTasks,
  deleteAwsImage,
  uploadEcardImg,
  uploadFile,
  deletePro,
  importAgents,
  passwordResetRequest,
  passwordResetConfirm,
  updateUserPassword,
  finishSettingsUpdate,
  getAllBoards,
  getBoardsForUser,
  updateUserByField,
  getAllUpcomingUniversalTasksWithinThreeWeeks,
  getAllUniversalTasksFromHomeOwnerAdmin,
  getAllUniversalTasksFromHomeOwnerAdminByCategoryThatHomeowenerIsNotAssignedWithin30Days,
  getAllUniversalTasksFromHomeOwnerAdminByCategoryThatHomeowenerIsNotAssignedWithNoTimeLimit,
  getAllUniversalTasksFromHomeOwnerAdminThatUserNotOptedOut,
  optoutTask,
  getUserNotifications,
  updateUserNotificationToRead,
  deleteUserNotification,
  importSpreadsheet,
  updateProByField,
  getIndustryNames,
  getCompanyNames,
  sendReviewRequest,
  requestQuote,
  sendEmailToClients,
  getAllBillingInfo,
  applyCouponCode,
  addEmailToUserEmailQueue,
  getScheduledEmailsForUser,
  updateScheduledEmail,
  addAgentEvent,
  getAgentEvents,
  deleteAgentEvent,
  updateAgentEvent,
  getAllAssociations,
  getAllUsersToAssociations,
  getAllUsersToCoupons,
  getAllAssociationsWithTheirUsersAndCoupons,
  getAllUsers,
  addAssociation,
  addNote,
  getNotesForUser,
  deleteNote,
  getUserNameByInputId,
  addNoteForClient,
  addImportantDates,
  getNewsletterQueue,
  getNewsletterQueueForAgent,
  deleteNewsletter,
  modifyNewsletter,
  deleteToken,
  addNewSpreadSheetRequest,
  addHomeowner,
  loginAdminToUser,
  getLoginHistory,
  setWelcomeAsSeen,
  addAdminNote,
  getUserActivity,
  unsubscribe,
  getUserInvoices,
  affiliateRequest,
  getUserSubscriptions,
  getHomeownerProperty,
  addHomeownerProperty,
  addPropertyImage,
  addPropertyDetails,
  getPropertyDetails,
  getHomeownerRenovations,
  updateRenovation,
  getHomeImprovementRequestsPhotos,
  uploadImgAws,
  insertHomeImprovementPhotos,
  insertHomeImprovementRequestsPhotos,
  updateHomeImprovementRequestPhotos,
  deleteHomeImprovementRequestImage,
  editCabinet,
  addHomeownerPropertyRenovation,
  getProCoupons,
  addCoupon,
  deleteCoupon,
  addProUser,
  getProById,
  submitProRating,
  getMortgageAgentById,
  getInsuranceAgentById,
  saveEmailTemplate,
  getUniversalEmailTemplates,
  getEmailTemplatesByUserId,
  getSignatureTemplatesByUserId,
  sendEmailsToSelectedUsersUsingTemplateId,
  getEmailRecords,
  getEmailRecordsByUserId,
  getHomeownersByAgent,
  optAgentNewsletter,
  getOptNewsletterStatus,
  resendInvitation,
  updateAgentWelcomeEmail,
  getAgentWelcomeEmail,
  deleteEmailTemplate,
  deleteEmailSignature,
  supportRequest
};

export default sqlService;
