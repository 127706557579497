import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGift, faStar, faArrowDown, faEnvelope, faPhone, faUsers, faCopy, faShare, faArrowRight, faEdit, faTrash, faPlus, faPaperclip, faFileImport, faFileCsv,
  faFileExcel
} from "@fortawesome/free-solid-svg-icons";
import { AiOutlineSearch } from "react-icons/ai";
import TableFooter from "../../utility.component/tableFooter.component";
import ScrollToTop from "../../utility.component/scrollToTop.component";
import searchUtility from "../../../functions/searchUtility";
import sortUtility from "../../../functions/sortUtility";
import useKeypress from "react-use-keypress";
import "./manageAgent.component.css";
import Rating from 'react-rating';
// import { read, writeFileXLSX } from "./xlsx.mjs";
import sqlService from "../../../services/sqlService";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useContext } from "react";
import { UserContext } from "../../../App";
import { ModalBody, ModalHeader } from "react-bootstrap";
import InputModal from "../../utility.component/inputModal.component/inputModal.component";
import emailtemplates from "../../utility.component/template.component/email-templace.component";
import { useNavigate } from "react-router-dom";
import serverResponseMessagesConstants from "../../../constants/serverResponseMessagesConstants";
import ImportButton from "../../utility.component/importButton.component/importButton.component";


function ManageAgent() {
  const user = useContext(UserContext);

  const [windowSize, setWindowSize] = React.useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);


  const onWindowResize = () => {
    setWindowSize(window.innerWidth);
  }


  // Dropdown menu stuff


  const [actionSelect, setActionSelect] = useState('Send')
  const [moveSelect, setMoveSelect] = useState('')
  const [sendSelect, setSendSelect] = useState('')
  const [currentFilter, setCurrentFilter] = useState(0);


  // Batch selection stuff


  const [selectedAgents, setSelectedAgents] = useState([])
  const [checkedState, setCheckedState] = useState([]);
  const allSelected = useRef(false);
  const [selected, setSelected] = useState(false);

  const [uploadingImport, setUploadingImport] = useState(false);

  const Navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      Navigate("/")
    }
  })


  function resetSelections() {


    setMoveSelect('');
    setSendSelect('');
  }


  function clearSelectedAgents() {
    allSelected.current = false;
    selectAll();

  }


  function handleChange(i) {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === i ? !item : item
    );

    setCheckedState(updatedCheckedState)
    updateSelected(updatedCheckedState);

  };

  const openImportMenu = () => {
    setImportMenu(true);
  }

  function updateSelected(s) {

    var selected = [];
    for (var i = 0; i < paginatedAgents.length && i < checkedState.length; i++) {
      if (s[i]) {
        selected.push(paginatedAgents[i])
      }
    }

    setSelectedAgents(selected)

  }


  useEffect(() => {
    if (checkedState.includes(true)) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }, [checkedState])


  function selectAll() {
    if (allSelected.current) {
      var s = new Array(checkedState.length).fill(true)
    } else {
      var s = new Array(checkedState.length).fill(false)
    }
    setCheckedState(s)
    updateSelected(s);
  }


  // Batch email stuff


  // Constructing mailto link and updating whenever recepient selection changes
  const [emailLink, setEmailLink] = useState('');


  useEffect(() => {
    const { exampletemplate, exampletemplateSubject } = emailtemplates(); // getting the body and subject of the desired template
    const emailBody = exampletemplate(); // Registering the body stuff
    const emailSubject = exampletemplateSubject(); // The subject stuff

    var str = 'mailto:?bcc='
    if (selectedAgents !== []) {
      for (var i = 0; i < selectedAgents.length - 1; i++) {
        str = str + selectedAgents[i].email + ',';
      }
      str = str + `${selectedAgents[selectedAgents.length - 1]?.email}&subject=${encodeURIComponent(emailSubject)}& body=${encodeURIComponent(emailBody)}`
    }


    setEmailLink(str)


  }, [selectedAgents])


  const [topAgents, setTopAgents] = useState([]);
  const topAgentsLoaded = useRef(false);


  const [refresh, setRefresh] = useState(false);


  const cl = useRef([]);
  const [currentAgent, setCurrentAgent] = useState({});


  // Pagination stuff


  const [filteredAgents, setFilteredAgents] = useState([]);
  const [paginatedAgents, setPaginatedAgents] = useState([]);


  const [currentPage, setCurrentPage] = useState(1);
  const currentPageRef = useRef(1);
  const [totalPages, setTotalPages] = useState(0);
  const agentsPerPage = 10;


  const indexOfLastAgent = currentPage * agentsPerPage;
  const indexOfFirstAgent = indexOfLastAgent - agentsPerPage;

  const [scrollCurrentPage, setScrollCurrentPage] = useState(1)
  const [scrollTotalPages, setScrollTotalPages] = useState(1)


  // Get total number of pages based on length of client list
  useEffect(() => {
    setTotalPages(Math.ceil(filteredAgents.length / agentsPerPage));


  }, [filteredAgents]);


  // Split clients into pages based on max page length
  // useEffect(() => {
  //   const currentAgents = topAgents.slice(indexOfFirstAgent, indexOfLastAgent);
  //   setPaginatedAgents(currentAgents);
  // }, [currentPage, topAgents, indexOfFirstAgent, indexOfLastAgent]);






  // Get total number of pages based on length of agent list
  // useEffect(() => {
  //   setTotalPages(Math.ceil(topAgents.length / agentsPerPage));


  // }, [topAgents]);



  // Split clients into pages based on max page length
  useEffect(() => {
    // const currentAgents = filteredAgents.slice(indexOfFirstAgent, indexOfLastAgent);
    const currentAgents = filteredAgents;
    setPaginatedAgents(currentAgents);
    // Initialize values of selected agents based on length of paginated agents
    setCheckedState(new Array(currentAgents.length).fill(false))
  }, [currentPage, filteredAgents, indexOfFirstAgent, indexOfLastAgent]);


  const nextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    currentPageRef.current = Math.min(currentPageRef.current + 1, totalPages)
  };


  const prevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
    currentPageRef.current = Math.min(currentPageRef.current - 1, 1);
  };




  const [addModal, setAddModal] = useState(false);
  const [agentEditModal, setAgentEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [importMenu, setImportMenu] = useState(false);


  const [titleValue, setTitleValue] = useState('')


  // Values passed to the add/edit agent modals
  const agentValues = {
    "nameText": { label: 'Name', key: 'contact_name' },
    "titleDropdown": { label: 'Title', key: 'agent_title', dropdown: ['Agent', 'Broker'], value: [titleValue, setTitleValue] },
    "phoneText": { label: 'Phone', key: 'phone_number' },
    "emailText": { label: 'Email', key: 'email' },
    "addressText": { label: 'Address', key: 'address' },
    "cityText": { label: 'City', key: 'city' },
    "zipText": { label: 'Zip/Postal Code', key: 'zip_code' },
    "stateSelect": { label: 'State/Province', key: 'state' },
    // "countrySelect": { label: 'Country', key: 'country' },
    // { label: 'Date', key: 'creation_date', date: true },
  }

  const [newAgent, setNewAgent] = useState({
    contact_name: null,
    agent_title: null,
    email: null,
    phone_number: null,
    address: null,
    zip_code: null,
    state: null,
    country: user?.country
    // creation_date: timeUtility.getDateTimeToMysqlDatetime(new Date())
  });


  function updateNewAgent(key, value) {
    let nAgent = { ...newAgent };
    nAgent[key] = value;
    console.log("nAgent", nAgent);
    setNewAgent(nAgent);
  }


  function resetNewAgent() {
    setNewAgent({
      contact_name: null,
      agent_title: null,
      email: null,
      phone_number: null,
      address: null,
      zip_code: null,
      state: null,
      country: user?.country
    })
  }


  function editAgent() {


    for (const [key, value] of Object.entries(newAgent)) {
      if (value) {




        sqlService
          .updateAgent({
            "user": user,
            input: { cell: key, data: value, id: currentAgent.id }
          })
          .then((data) => {
            // setServerMessage({
            //   color: "green",
            //   message: "Agent has been updated",
            // });
            // setTimeout(() => {
            //   setServerMessage({ color: "none", message: "" });
            // }, 3000);
            // Update value in currentAgent
            setCurrentAgent(previousState => ({
              ...previousState,
              [key]: value
            }));
            handleEditClose();

          })
          .catch((err) => {
            console.log(err);
          });

      }

    }


    topAgentsLoaded.current = false;
    setRefresh(!refresh);
    setSortFilter('Date')
    setAscending(false)
    resetNewAgent();


  }


  const [agentIndex, setAgentIndex] = useState(0)

  const [requestedAddAgent, setRequestedAddAgent] = useState(false);

  const refreshAgents = () => {
    topAgentsLoaded.current = false;
    setRefresh(!refresh);
  }


  function submitNewAgent() {

    setRequestedAddAgent(true);
    sqlService
      .addAgent({
        user: user,
        input: newAgent
      })
      .then((data) => {
        // setServerMessage({
        //   color: "green",
        //   message: "Agent Added Successfully",
        // });
        // setTimeout(() => {
        //   setServerMessage({ color: "none", message: "" });
        // }, 3000);
        topAgentsLoaded.current = false;
        setRequestedAddAgent(false);
        setRefresh(!refresh);
        handleAddClose();
      })
      .catch((err) => {
        setServerMessage({ color: "red", message: serverResponseMessagesConstants[err?.response?.data?.err]?.message ?? "Something Went Wrong" });
        setRequestedAddAgent(false);
        setTimeout(() => {
          setServerMessage({ color: "none", message: "" });
        }, 3000);
      });
  }


  const handleDelete = () => {


    if (selected) {
      var ids = []
      for (var agent of selectedAgents) {
        ids.push(agent.id)
      }



    } else {
      var ids = [currentAgent.id]

    }

    sqlService
      .deleteAgent({
        user: user,
        input: ids


      })
      .then((data) => {
        setServerMessage({
          color: "green",
          message: "Agent has been deleted",
        });
        setTimeout(() => {
          setServerMessage({ color: "none", message: "" });
        }, 3000);
        topAgentsLoaded.current = false;
        setRefresh(!refresh);
      })
      .catch((err) => {
        setServerMessage({ color: "red", message: "Something Went Wrong" });
        setTimeout(() => {
          setServerMessage({ color: "none", message: "" });
        }, 3000);
      });
  };


  const onAdd = () => {
    setAddModal(true);
  };


  const [searchBarValue, setSearchBarValue] = useState('');


  const onSearchBarChange = (e) => {
    setSearchBarValue(e.currentTarget.value);
    console.log(e.currentTarget.value);
    onSearch(e.currentTarget.value);
  };


  useKeypress("Enter", () => {
    // Do something when the user has pressed the Escape key
    onSearch();
  });


  const onSearch = (value) => {
    if ((!value && searchBarValue === "") || (value === "")) {
      setPaginatedAgents(cl.current);
    } else {
      let filteredAgents = searchUtility.filterBySearch(
        cl.current,
        value ?? searchBarValue
      );
      setPaginatedAgents(filteredAgents);
    }
  };


  useEffect(() => {
    let filtered = topAgents.filter((agent) =>
      agent.agent_title === currentFilter || currentFilter === 0
    );
    setFilteredAgents(filtered);
    setCurrentPage(currentPageRef.current);
  }, [currentFilter, topAgents]);


  const handleEditClose = () => {
    setAgentEditModal(false);
    setDeleteModal(false);
    resetNewAgent();
    setCurrentAgent({});
  };


  const handleDeleteClose = () => {
    setDeleteModal(false);
  }


  const handleAddClose = () => {
    setAddModal(false);
    resetNewAgent();
  }



  const TABLE_COLUMNS = [
    "contact_name",
    "title",
    "total",
    "rating",
    "phone_number",
    "address",
    "email",
    "website"
  ];


  const [inviteModal, setInviteModal] = useState(false);
  const handleClose = () => {
    setInviteModal(false);
  };


  const [serverMessage, setServerMessage] = useState({
    color: "none",
    message: "",
  });


  const inviteNewAgent = () => {
    setServerMessage({ color: "green", message: "Invitation Sent" });
  }


  useEffect(() => {
    if (!topAgentsLoaded.current) {
      sqlService
        .getTopAgents({ user: user })
        .then((data) => {
          const topagents = data.data.reverse();
          cl.current = topagents;

          setTopAgents(topagents);
          setPaginatedAgents(topagents?.slice(0, agentsPerPage))


        })
        .catch((err) => {
          console.log(err);
        });
      topAgentsLoaded.current = true;


    }


  }, [refresh]);




  // Sorting stuff


  const [sortFilter, setSortFilter] = useState('Date')
  const [ascending, setAscending] = useState(false)


  const sortValues = [
    { value: 'Date', label: 'Join Date', key: 'creation_date' },
    { value: 'Name', label: 'Name', key: 'contact_name' },
    { value: 'Homeowners', label: 'Homeowners', key: 'total' },
    { value: 'Rating', label: 'Rating', key: 'rating' }

  ]


  const sort = (val, asc) => {


    if (val === 'Name') {
      setTopAgents(sortUtility.sortByName('contact_name', topAgents, asc))


    } else {
      for (var sortable of sortValues) {
        if (val === sortable.value) {
          setTopAgents(sortUtility.sort(sortable.key, topAgents, asc))
          break;
        }
      }
    }
  }



  const handleScrollingTable = (e) => {
    let element = e.target
    let pageNumber = Math.round(element.scrollTop / (element.clientHeight - 100)) + 1;
    setScrollCurrentPage(pageNumber);
    if (element.scrollHeight - Math.round(element.scrollTop) <= element.clientHeight) {
      setScrollCurrentPage(scrollTotalPages);
    }
  }


  useEffect(() => {
    let element = document.getElementById('tableScrollElementHoneyydoClient');
    if (element) {
      let totalPages = Math.round(element?.scrollHeight / (element?.clientHeight - 100));
      console.log(totalPages, element.scrollHeight, element.clientHeight)
      element.scrollTo({ top: 0 })
      setScrollCurrentPage(1);
      setScrollTotalPages(totalPages)
    }
  }, [windowSize, paginatedAgents])




  return (
    <>
      {windowSize > 1000 ? (
        <div>
          <div className="align-items-center display-flex flex-direction-column margin-top-5-vh ">
            <h2 className="grey">Agents</h2>
            <span >{paginatedAgents.length} of {topAgents.length} </span>
            {selected ?
              <>
                <div className="display-flex flex-direction-row justify-content-center desktop-bubble">
                  <span>{selectedAgents.length} selected</span>
                  <div className={selected ? "display-block " : "display-none"}>
                    <select
                      value={actionSelect}
                      className="mobile-dropdown header-dropdown margin-left-5-px "
                      onChange={(e) => (setActionSelect(e.target.value), resetSelections())}>
                      <option value='Send'>Send</option>
                      <option value='Move'>Move to</option>
                    </select>
                  </div>
                  <div className={actionSelect === 'Move' ? "display-block " : "display-none"}>
                    <select
                      value={moveSelect}
                      className="mobile-dropdown header-dropdown margin-left-5-px "
                      onChange={(e) => setMoveSelect(e.target.value)}>
                      <option value=''>Select</option>
                      <option value='Trash'>Trash</option>
                    </select>
                    {moveSelect ?
                      <FontAwesomeIcon
                        className="orange fa-lg padding-left-10-px cursor-pointer"
                        icon={faArrowRight}
                        onClick={() => setDeleteModal(true)}
                      /> : null
                    }
                  </div>
                  <div className={actionSelect === 'Send' ? "display-block " : "display-none"}>
                    <select
                      value={sendSelect}
                      className="mobile-dropdown header-dropdown margin-left-5-px "
                      onChange={(e) => (setSendSelect(e.target.value))}
                    >

                      <option value="">Select</option>
                      <option value="Email">Email</option>

                    </select>
                    {sendSelect ?
                      <a href={emailLink}>
                        <FontAwesomeIcon
                          className="orange fa-lg padding-left-10-px"
                          icon={faArrowRight}
                        />
                      </a> : null
                    }
                  </div>
                </div>
              </> : null
            }
            <div className="desktop-bubble ">
              <span style={{ padding: '0 5px' }}>Sort by
                <select
                  className="mobile-dropdown header-dropdown margin-left-10-px "
                  onChange={(e) => { setSortFilter(e.target.value); sort(e.target.value, ascending) }}
                  value={sortFilter}
                >
                  {sortValues.map((val) =>
                    <option key={val.value} value={val.value} >{val.label}</option>
                  )}
                </select>

                <FontAwesomeIcon
                  onClick={() => { setAscending(!ascending); sort(sortFilter, !ascending); }}
                  style={{ marginLeft: '5px' }}
                  className={`orange cursor-pointer ${ascending ? 'fa-flip-vertical' : ''}`}

                  icon={faArrowDown}
                />

              </span>

              <div className="Search-Bar-Focus position-relative margin-left-5-px" style={{ margin: '0 25px' }} >
                <span
                  className="position-absolute left-8-px top-4-px purple hover-blue cursor-pointer border-radius-5-px "
                  onClick={() => {
                    onSearch();
                  }}
                >
                  <AiOutlineSearch />
                </span>
                <input
                  className="Search-Bar"
                  style={{ width: '175px' }}
                  type="text"
                  onChange={onSearchBarChange}
                  value={searchBarValue}
                  placeholder={"Search"}
                />
              </div>
              {/* <span style={{ padding: '0 10px' }}>Filter
                <select


                  className="mobile-dropdown header-dropdown margin-left-10-px "
                  onChange={(e) => (setCurrentFilter(e.target.value))}>


                  <option value="All">All</option>
                  <option value="Agent">Agent</option>
                  <option value="Broker">Broker</option>
                </select>


              </span> */}
              <div className="cursor-pointer" onClick={() => onAdd()}>
                <FontAwesomeIcon
                  icon={faPlus}
                  className="orange "


                >


                </FontAwesomeIcon>
                <span style={{ margin: '0 5px' }}>Add</span>
              </div>

              <div className="margin-left-5-px">

                <ImportButton importButtonStyle={"Tasklist-Button height-40-px "} buttonText={"Import Agents"} importType={"agents"} importMenu={importMenu} setImportMenu={setImportMenu} openImportMenu={openImportMenu} onImport={refreshAgents} country={user.country} />
              </div>




            </div>
            {cl.current.length ?
              <div>
                <div id="tableScrollElementHoneyydoClient" className="Table-List-Container" onScroll={handleScrollingTable}>
                  <table className="Uni-Table-Container">
                    <thead className="Uni-Table-Header">
                      <tr>
                        <th ><input type='checkbox' className="margin-left-15-px margin-right-10-px" onChange={() => { allSelected.current = !allSelected.current; selectAll() }} /> Select All</th>
                        <th>Name </th>
                        <th>Title</th>
                        <th>Rating</th>
                        <th>Homeowners</th>


                        <th>Email</th>
                        <th>Phone</th>
                        <th></th>
                      </tr>



                    </thead>
                    <tbody className="Uni-Table-Body">
                      {paginatedAgents.map((agent, index) => (
                        <tr key={agent.id} >
                          <td><input type='checkbox' className="margin-left-15-px" checked={checkedState[index] || false} onChange={() => handleChange(index)} /></td>


                          <td>{agent.contact_name}</td>
                          <td>{agent.type_name}</td>
                          <td>
                            <Rating
                              initialRating={agent.rating}


                              emptySymbol={'fa fa-star star-clear '}
                              fullSymbol={'fa fa-star star-orange '}
                              fractions={2}
                              readonly={true}


                            />
                          </td>
                          <td>{agent.total}</td>
                          <td>{agent.email}</td>
                          <td>{agent.phone_number}</td>
                          <td><FontAwesomeIcon
                            icon={faEdit}
                            className="grey hover-icon"
                            onClick={() => {
                              clearSelectedAgents();
                              setAgentEditModal(true);
                              setCurrentAgent(paginatedAgents[index]);

                            }}
                          /></td>






                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>
                {paginatedAgents?.length ?

                  <div className="center-wrapper margin-top-10-px">
                    <TableFooter
                      currentPage={scrollCurrentPage}
                      totalPages={scrollTotalPages}
                      nextPage={() => {
                        let element = document.getElementById("tableScrollElementHoneyydoClient");
                        if (element) {
                          element.scrollBy({
                            top: element.clientHeight - 100,
                            behavior: "smooth",
                          })
                        }

                      }}
                      prevPage={() => {
                        let element = document.getElementById("tableScrollElementHoneyydoClient");
                        if (element) {
                          element.scrollBy({
                            top: - (element.clientHeight - 100),
                            behavior: "smooth",
                          })
                        }
                      }}
                    />
                  </div>
                  : null}
              </div>
              :
              <div className="bubble">
                <div className="Empty-Message" style={{ marginTop: '40px' }}>
                  <h3>You haven't added any agents yet</h3>
                  <p>Click "Add" to start creating accounts for your agents and you will see them appear here</p>
                </div>
              </div>
            }
          </div>
        </div>
      ) : (
        <>
          <div className="mobile-dashboard-container padding-top-5-vh" >
            <div className="mobile-dashboard-clients-container position-relative">
              <h4>Agents</h4>
              <span >{paginatedAgents?.length} of {topAgents?.length} </span>
              <span className="padding-top-5-px">
                Select all
                {' '}
                <input
                  type='checkbox'
                  onChange={() => {
                    allSelected.current = !allSelected.current;
                    selectAll()
                  }} />
              </span>
              {selected ?
                <>
                  <div className="bubble">
                    <div className="display-flex flex-direction-row justify-content-center ">
                      <span>{selectedAgents.length} selected</span>
                      <div className={selected ? "display-block " : "display-none"}>
                        <select
                          value={actionSelect}
                          className="mobile-dropdown header-dropdown margin-left-5-px "
                          onChange={(e) => (setActionSelect(e.target.value), resetSelections())}>
                          <option value='Send'>Send</option>
                          <option value='Move'>Move to</option>
                        </select>
                      </div>
                      <div className={actionSelect === 'Move' ? "display-block " : "display-none"}>
                        <select
                          value={moveSelect}
                          className="mobile-dropdown header-dropdown margin-left-5-px "
                          onChange={(e) => setMoveSelect(e.target.value)}>
                          <option value=''>Select</option>
                          <option value='Trash'>Trash</option>
                        </select>

                        {moveSelect ?
                          <FontAwesomeIcon
                            className="orange padding-left-10-px cursor-pointer"
                            icon={faArrowRight}
                            onClick={() => setDeleteModal(true)}
                          /> : null
                        }

                      </div>
                      <div className={actionSelect === 'Send' ? "display-block " : "display-none"}>
                        <select
                          value={sendSelect}
                          className="mobile-dropdown header-dropdown margin-left-5-px "
                          onChange={(e) => (setSendSelect(e.target.value))}
                        >

                          <option value="">Select</option>
                          <option value="Email">Email</option>

                        </select>
                        {sendSelect ?
                          <a href={emailLink}>
                            <FontAwesomeIcon
                              className="orange padding-left-10-px"
                              icon={faArrowRight}
                            />
                          </a> : null
                        }
                      </div>
                    </div>
                  </div>
                </> : null
              }

              <div className="bubble">
                <div className="display-flex flex-direction-row justify-content-center" >
                  <span style={{ padding: '0 5px' }}>Filter
                    <select

                      className="mobile-dropdown header-dropdown margin-left-10-px margin-bottom-10-px"
                      onChange={(e) => (setCurrentFilter(e.target.selectedIndex))}>

                      <option >All</option>
                      <option >Agents</option>
                      <option >Brokers</option>
                    </select>
                  </span>
                  <span style={{ padding: '0 5px' }}>Sort by
                    <select
                      className="mobile-dropdown header-dropdown margin-left-10-px margin-bottom-10-px"
                      onChange={(e) => { setSortFilter(e.target.value); sort(e.target.value, ascending) }}
                      value={sortFilter}
                    >
                      {sortValues.map((val) =>
                        <option key={val.value} value={val.value} >{val.label}</option>
                      )}

                    </select>

                    <FontAwesomeIcon
                      onClick={() => { setAscending(!ascending); sort(sortFilter, !ascending); }}
                      style={{ marginLeft: '5px' }}
                      className={`orange cursor-pointer ${ascending ? 'fa-flip-vertical' : ''}`}

                      icon={faArrowDown}
                    />

                  </span>

                </div>
                <div className="display-flex align-items-center">
                  <div className="Search-Bar-Focus position-relative margin-left-5-px"  >
                    <span
                      className="position-absolute left-8-px top-4-px purple hover-blue cursor-pointer border-radius-5-px "
                      onClick={() => {
                        onSearch();
                      }}
                    >
                      <AiOutlineSearch />
                    </span>
                    <input
                      className="Search-Bar"
                      style={{ width: '175px' }}
                      type="text"
                      onChange={onSearchBarChange}
                      value={searchBarValue}
                      placeholder={"Search"}
                    />
                  </div>

                  <span style={{ marginLeft: '100px' }}>Add</span>
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="orange cursor-pointer"
                    style={{ marginLeft: '5px' }}
                    onClick={() => onAdd()}>


                  </FontAwesomeIcon>

                </div>
              </div>


              <div id="tableScrollElementHoneyydoClient" className="Table-List-Container padding-10-px" onScroll={handleScrollingTable}>

                {paginatedAgents.map((agent, index) => (
                  <div className={`mobile-client  ${checkedState[index] ? 'clicked' : ''}`}


                    key={agent.id}
                  >
                    <div className="mobile-contact-name-container cursor-pointer"
                      onClick={() => {
                        handleChange(index);
                      }}>
                      <div className="mobile-contact-name grey">
                        {agent.contact_name}
                      </div>
                    </div>
                    <div className="mobile-client-info cursor-pointer"
                      onClick={() => {
                        handleChange(index);
                      }}>
                      <div>
                        {" "}
                        <FontAwesomeIcon
                          className="mobile-dashboard-icons"
                          icon={faEnvelope}
                        />{" "}
                        {agent.email}
                      </div>
                      <div>
                        {" "}
                        <FontAwesomeIcon
                          className="mobile-dashboard-icons"
                          icon={faPhone}
                        />{" "}
                        {agent.phone_number}
                      </div>
                      <div>
                        <FontAwesomeIcon
                          className="mobile-dashboard-icons"
                          icon={faUsers}
                        />{" "}
                        {agent.total} Homeowners
                      </div>
                      <div>
                        <Rating
                          initialRating={agent.rating}
                          emptySymbol={'fa fa-star star-clear '}
                          fullSymbol={'fa fa-star star-orange '}
                          fractions={2}
                          readonly={true}


                        />
                      </div>
                    </div>
                    <div className="mobile-agent-footer">


                      <div className="float-right">
                        <span
                          className="cursor-pointer"
                          onClick={() => {
                            clearSelectedAgents();
                            setAgentEditModal(true);
                            setCurrentAgent(paginatedAgents[index]);
                            setAgentIndex(index);


                          }}
                        >
                          <FontAwesomeIcon
                            className="orange"
                            icon={faEdit}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

            </div>
            {cl?.current?.length ?
              <div className="center-wrapper margin-top-10-px">
                <TableFooter
                  currentPage={scrollCurrentPage}
                  totalPages={scrollTotalPages}
                  nextPage={() => {
                    let element = document.getElementById("tableScrollElementHoneyydoClient");
                    if (element) {
                      element.scrollBy({
                        top: element.clientHeight - 100,
                        behavior: "smooth",
                      })
                    }

                  }}
                  prevPage={() => {
                    let element = document.getElementById("tableScrollElementHoneyydoClient");
                    if (element) {
                      element.scrollBy({
                        top: - (element.clientHeight - 100),
                        behavior: "smooth",
                      })
                    }
                  }}
                />
              </div>
              :
              <div className="bubble" style={{ maxWidth: '90%' }}>
                <div className="Empty-Message" style={{ marginTop: '40px' }}>
                  <h3>You haven't added any agents yet</h3>
                  <p>Click "Add" to start creating accounts for your agents and you will see them appear here</p>
                </div>
              </div>

            }
          </div>
          <ScrollToTop />


        </>
      )}

      <Modal
        show={deleteModal}
        onHide={handleDeleteClose}


        backdrop="static"
        keyboard={false}
        size="sm"
        style={{ top: '15%' }}
        focus={true}


      >
        <ModalBody >
          <p className="padding-10-15 text-align-center">Deleting agents will prevent them from being able to access their account and remove them from your brokerage. Are you sure?</p>
          <div className="display-flex justify-content-center">
            <span
              className="margin-right-10-px"
              onClick={() => {

                handleDelete();
                setDeleteModal(false);

              }}
            >
              <button className="Tasklist-Button height-40-px red">
                Delete
              </button>
            </span>
            <Button variant="secondary height-40-px" onClick={handleDeleteClose}>
              Cancel
            </Button>
          </div>

        </ModalBody>

      </Modal>
      {agentEditModal ?
        <InputModal
          inputValues={agentValues}
          blurCondition={deleteModal}
          show={agentEditModal}
          hide={handleEditClose}
          title='Edit Agent'
          deletable={true}
          deleteModal={setDeleteModal}
          aggFunction={updateNewAgent}
          agg={newAgent}
          submit={editAgent}
          serverMessage={serverMessage}
          filledval={currentAgent}
          user={user}
          setMultipleAggValues={setNewAgent}
        />
        : null
      }

      {addModal ?
        <InputModal
          inputValues={agentValues}
          show={addModal}
          hide={handleAddClose}
          title="Add an Agent"
          aggFunction={updateNewAgent}
          submit={submitNewAgent}
          serverMessage={serverMessage}
          requestedAddAgent={requestedAddAgent}
          isNewAgent={true}
          user={user}
          agg={newAgent}
          setMultipleAggValues={setNewAgent}
        />
        : null
      }


    </>
  )
}

export default ManageAgent;
