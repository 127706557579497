import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import Placeholder from '../../../assets/images/Placeholder-removebg.png';
import "../adminDashboard.component/adminDashboard.css/adminDetailView.component.css";
import inputConstants from "../../../constants/inputConstants";
import MultipleSelection from "../../utility.component/multipleSelection";
import { Spinner } from "react-bootstrap";
import sqlService from "../../../services/sqlService";
import { UserContext } from "../../../App";
import AdminStripe from "../adminDashboard.component/adminStripe.component";
import { faSave, faTrash, faPlus, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdminDelete from "../adminDashboard.component/adminDelete.component";
import GoogleAutoComplete from "../../utility.component/googleAutoComplete.omponent";
import AdminStripeCoupon from "../adminDashboard.component/adminStripeCoupons.component";

export default function AdminDetailView({ }) {
    const { state } = useLocation()
    // const { client, sub, columns, selectedTable } = state;
    const client = state?.client;
    const sub = state?.sub;
    const columns = state?.columns;
    const selectedTable = state?.selectedTable;
    const [clientInfo, setClientInfo] = useState(client)
    const [changes, setChanges] = useState({ id: client?.id ?? client?.user_id });
    const [requested, setRequested] = useState(false);
    const [selectedTab, setSelectedTab] = useState('Edit')
    const user = useContext(UserContext);
    const [refresh, setRefresh] = useState(false)
    const [agents, setAgents] = useState([])
    const [brokerages, setBrokerages] = useState([])
    const activity = useRef('')
    const MAXLENGTH = 1000;
    const [deletePopup, setDeletePopup] = useState(false)
    const [userParent, setUserParent] = useState({})
    const [INDUSTRIES, setINDUSTRIES] = useState([])
    const changesRef = useRef()
    const valRef = useRef({});
    const addressRef = useRef()
    const [addresses, setAddresses] = useState([])
    const [canProcessCurrentAddresses, setCanProcessCurrentAddresses] = useState(false);

    const [googlePros, setGooglePros] = useState({});

    const [newAddressKey, setNewAddressKey] = useState(null)
    const handleCheck = (e) => {
        setChanges({ ...changes, [e.target.name]: e.target.checked });
    }
    useEffect(() => {
        console.log(client)
        if (client?.user_id) {
            client["id"] = client?.user_id
        }
        if (client?.additional_addresses) {
            changes.additional_addresses = []
            let newAddresses = {}
            for (let address of client?.additional_addresses) {
                const random = `${Math.random()}`;
                newAddresses[random] = address;
                changes.additional_addresses.push(address)
            }
            setAddresses(newAddresses);
            setCanProcessCurrentAddresses(true)
        }

    }, [])

    useEffect(() => {
        if (!canProcessCurrentAddresses) { return }
        let newGooglePros = {};
        for (let newAddressKey of Object.keys(addresses)) {
            let inputField = document.getElementById(`${newAddressKey}_honeyydo_pros_address_edit`)
            valRef.current = { ...addresses };
            if (!newGooglePros[`${newAddressKey}_honeyydo_pros_address_google_edit`]) {
                newGooglePros[`${newAddressKey}_honeyydo_pros_address_google_edit`] = <GoogleAutoComplete addressKey={`${newAddressKey}_honeyydo_pros_address_edit`} addresses={newAddressKey} address1FieldRef={inputField} callBack={(postcode, city1, country1, state1, address1, oldKey) => {
                    runCallBack(postcode, city1, country1, state1, address1, oldKey);
                }} />;

            }
            setGooglePros(newGooglePros);
            inputField.value = addresses[newAddressKey].address;
        }

    }, [canProcessCurrentAddresses]);

    useEffect(() => {
        if (!newAddressKey) {
            return
        }
        let inputField = document.getElementById(`${newAddressKey}_honeyydo_pros_address_edit`)
        valRef.current = { ...addresses };
        if (!googlePros[`${newAddressKey}_honeyydo_pros_address_google_edit`]) {
            setGooglePros({
                ...googlePros,
                [`${newAddressKey}_honeyydo_pros_address_google_edit`]: <GoogleAutoComplete addressKey={`${newAddressKey}_honeyydo_pros_address_edit`} addresses={newAddressKey} address1FieldRef={inputField} callBack={(postcode, city1, country1, state1, address1, oldKey) => {
                    runCallBack(postcode, city1, country1, state1, address1, oldKey);
                }} />
            })
        }
    }, [newAddressKey])

    const updateAddress = (key, value, addressKey) => {
        let nAddress = { ...addresses }
        nAddress[addressKey][key] = value;
        let el = document.getElementById(`${addressKey}_honeyydo_pros_address_edit`);
        if (el) {
            el.value = value;
        }
        setAddresses(nAddress)
        updateInput("additional_addresses", Object.values(nAddress))
    }

    function deleteAddress(key) {
        let updated = { ...addresses }
        // updated.splice(index, 1)
        delete updated[key]

        let newGooglePro = {}
        for (let key1 of Object.keys(updated)) {
            let el = document.getElementById(`${key1}_honeyydo_pros_address_edit`);
            if (el) {
                el.value = updated[key1].address;
                valRef.current = { ...addresses };
                if (!newGooglePro[`${key1}_honeyydo_pros_address_google_edit`]) {
                    newGooglePro[`${newAddressKey}_honeyydo_pros_address_google`] = <GoogleAutoComplete addressKey={`${key1}_honeyydo_pros_address_edit`} addresses={key1} address1FieldRef={el} callBack={(postcode, city1, country1, state1, address1, oldKey) => {
                        runCallBack(postcode, city1, country1, state1, address1, oldKey);
                    }} />
                }
            }
        }
        // delete newGooglePro[`${newAddressKey}_honeyydo_pros_address_google`];
        setGooglePros(newGooglePro);
        setAddresses(updated)
        updateInput("additional_addresses", Object.values(updated))

    }
    useEffect(() => {
        var n = { ...changes }
        console.log(columns)
        if(columns){
            for (var value of columns) {


                if (value.field !== 'creation_date' && value.field !== 'brokerage_contact_name' && value.field !== 'brokerage_phone_number' && value.field !== 'agent_name') {
    
                    n[value.field] = client[value.field]
                }
    
            }
        }

        if (selectedTable === 'pros') {
            n.nation_wide = client?.nation_wide
            n.state_wide = client?.state_wide
        }
        console.log(n)
        setChanges(n)


    }, [])

    useEffect(() => {

        sqlService
            .getIndustryNames()
            .then((data) => {
                const INDUSTRIES = [];
                for (let i = 0; i < data.data.length; i++) {
                    INDUSTRIES[i] = data.data[i].type_name;
                }
                setINDUSTRIES(INDUSTRIES);
            })
            .catch((err) => {
                console.log("Didn't get industry names");
                console.log(err);
            });

        sqlService
            .getAdminBoards({ user: user })
            .then((data) => {
                // setBoards(data);

                let arr = data
                var f = []
                for (let i of arr) {
                    f.push(i.contact_name)
                }

                setSelectionOptionsArray(f)
            })
            .catch((err) => {
                console.log(err);
            });

        sqlService
            .getAdminBrokerages({ user: user })
            .then((data) => {
                setBrokerages(data);
            })
            .catch((err) => {
                console.log(err);
            });

        sqlService
            .getAdminAgents({ user: user })
            .then((data) => {
                setAgents(data);
            })
            .catch((err) => {
                console.log(err);
            });

        // sqlService
        //     .getUserActivity({ user: user, input: clientInfo.id })
        //     .then((data) => {

        //         activity.current = data.data[data.data.length - 1];

        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });

    }, [refresh]);

    useEffect(() => {
        var b = {}
        if (client?.role_id === 2) {
            b = brokerages.find((brokerage) => brokerage.id === parseInt(clientInfo?.brokerage))
        } else if (client?.role_id === 1) {
            b = agents?.find((agent) => agent.id === parseInt(clientInfo?.agent))
        }

        setUserParent(b)


    }, [clientInfo])

    function saveNote() {
        sqlService.addAdminNote({ user: user, input: { id: clientInfo.id, note: clientInfo.admin_note } }).then((d) => {


        }).catch((err) => {

        })

    }

    const [windowSize, setWindowSize] = React.useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", onWindowResize);

        return () => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, []);


    const onWindowResize = () => {
        setWindowSize(window.innerWidth);

    };

    function updateInput(key, value) {
        let nInput = { ...changes };
        nInput[key] = value;
        console.log(nInput)
        setChanges(nInput);

    }

    function submitChanges() {

        console.log(changes)

        setRequested(true);


        tableFileds[selectedTable]["editFunction"]({ user: user, input: changes }).then((d) => {
            setRequested(false);
            console.log("Edit successful");
            var nClient = { ...clientInfo }
            for (let [key, value] of Object.entries(changes)) {
                nClient[key] = value
            }
            setClientInfo(nClient)
            setRefresh(!refresh)
            //   onClose();

        }).catch((err) => {
            setRequested(false);
            console.error("Error editing item", err);
        })


    }

    function updateClient(field, value) {
        if (field === 'admin_note') {
            if (value.length > MAXLENGTH) {
                return

            }
        }
        var nClient = { ...clientInfo }
        nClient[field] = value;
        setClientInfo(nClient)

    }

    const handleLockedToggle = (item, value) => {
        if (selectedTable === "agents" || selectedTable === "brokerages") {
            sqlService.updateUserByField({ "input": { "field": "locked", "value": value, "userId": item.id } }).then(data => {
                updateClient('locked', value)
            }).catch(err => {
                console.log(err);
            })
        } else if (selectedTable === "pros") {
            sqlService.updateProByField({ "input": { "field": "locked", "value": value, "proId": item.id } }).then(data => {
                updateClient('locked', value)
            }).catch(err => {
                console.log(err);
            })

        }
    }

    const handleSubscribeToggle = (item, value) => {
        sqlService.updateUserByField({ "input": { "field": "subscribed", "value": value, "userId": item.id } }).then(data => {
            updateClient('subscribed', value)
        }).catch(err => {
            console.log(err);
        })
    }

    const handleValidateToggle = (item, value) => {
        if (selectedTable === "agents" || selectedTable === "brokerages") {
            sqlService.updateUserByField({ "input": { "field": "admin_verified", "value": value, "userId": item.id } }).then(data => {
                updateClient('admin_verified', value)
            }).catch(err => {
                console.log(err);
            })
        } else if (selectedTable === "pros") {
            sqlService.updateProByField({ "input": { "field": "admin_verified", "value": value, "proId": item.id } }).then(data => {
                updateClient('admin_verified', value)
            }).catch(err => {
                console.log(err);
            })
        }

    }


    const decodeBoards = (board) => {
        if (!board) {
            return null;
        }
        if (typeof board !== "string") {
            return board;
        }
        let array = board.split(",")
        if (!array && array.length) {
            return null;
        }
        var rs = {}
        for (let item of array) {
            rs[item] = 1;
        }

        return rs;
    }

    const handleSelectionMap = (newValue) => {
        updateInput("board", newValue)
    }
    const [selectionOptionsArray, setSelectionOptionsArray] = useState([""]);

    useEffect(() => {
        changesRef.current = changes
    }, [changes])




    const runCallBack = (postcode, city1, country1, state1, address1, addressesKey) => {
        let inputField = document.getElementById(`${addressesKey}_honeyydo_pros_address_edit`)
        if (inputField) {
            inputField.value = address1;
        }
        if (!addresses) { return }
        let nAddress = { ...valRef.current ?? {} }
        nAddress[addressesKey]["address"] = address1;
        nAddress[addressesKey]["city"] = city1;
        nAddress[addressesKey]["zip_code"] = postcode;
        nAddress[addressesKey]["country"] = country1;
        nAddress[addressesKey]["state"] = state1;
        setAddresses(nAddress)
        valRef.current = nAddress;
    }

    const tableFileds = {
        "brokerages": {
            "editFunction": sqlService.editAdminBrokerage,
            "deleteFunction": (args) => { return sqlService.deleteAdminBrokerage({ "input": args }); },
            "addFunction": sqlService.addAdminBrokerage,


        },
        "agents": {
            "editFunction": sqlService.editAdminAgent,
            "deleteFunction": (args) => { return sqlService.deleteAdminAgent({ "input": args }); },
            "addFunction": sqlService.addAdminAgent,


        },
        "homeowners": {
            "editFunction": sqlService.editAdminHomeowner,
            "deleteFunction": (args) => { return sqlService.deleteAdminHomeowner({ "input": args }); },
            "addFunction": sqlService.addAdminHomeowner,


        },
        "pros": {
            "editFunction": sqlService.editAdminPro,
            "deleteFunction": (args) => { return sqlService.deleteAdminPro({ "input": args }); },
            "addFunction": sqlService.addAdminPro,

        }

    }

    return (
        <>
            <GoogleAutoComplete address1FieldRef={addressRef} callBack={(postcode, city1, country1, state1, address1) => {
                let nInputData = { ...changesRef.current, "zip_code": postcode, "city": city1, "country": country1, "state": state1, "address": address1 }
                console.log(nInputData);
                setChanges(nInputData)
            }} />
            <div>
                {Object.keys(googlePros)?.map(key => googlePros[key])}
            </div>
            <div className="Detail-View-Page">
                <div className="Detail-Top-Bar">
                    <h5>{`${client?.role_id === 1 ? 'Homeowner' : client?.role_id === 2 ? 'Agent' : client?.role_id === 3 ? 'Brokerage' : 'Pro'} Details`}</h5>

                </div>
                <div className="Detail-View-Header ">
                    <div>
                        <img className="margin-auto-lr display-block" src={client?.img_link ?? Placeholder} />
                    </div>
                    <div>
                        <h4>{clientInfo?.contact_name}</h4>

                        <div className="Details-Grid-Container">
                            <div>

                                <div className="Details-Grid">
                                    <div>
                                        <h6>Phone Number</h6>
                                        <span>{clientInfo?.phone_number}</span>
                                    </div>
                                    <div>
                                        <h6>Email Address</h6>
                                        <span>{client?.email}</span>
                                    </div>
                                    {client?.utc_offset ?
                                        <div>
                                            <h6>Time Zone</h6>
                                            <span>{inputConstants.TIME_ZONES[client?.utc_offset]}</span>
                                        </div>
                                        : null}

                                </div>
                            </div>
                            <div>

                                {client?.role_id === 2 ?
                                    <div>
                                        <h6>Brokerage Information</h6>
                                        <p>{userParent?.contact_name ?? clientInfo.brokerage_contact_name}</p>
                                        <p>{userParent?.phone_number ?? clientInfo.brokerage_phone_number}</p>
                                    </div> : client?.role_id === 1 ?
                                        <div>
                                            <h6>Agent Information</h6>
                                            <p>{userParent?.contact_name ?? clientInfo.agent_name}</p>
                                            <p>{userParent?.phone_number ?? clientInfo.agent_phone_number}</p>
                                        </div> : null}


                            </div>
                            {windowSize <= 900 ?
                                <div className="Account-Info " >
                                    <div className="width-full-pt text-align-center padding-5-px" style={{ backgroundColor: 'lightgrey', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                                        <h5>Account Info</h5>

                                    </div>
                                    <div className="padding-10-px" >
                                        <div style={{ marginBottom: '10px' }}>
                                            <h6>Creation Date</h6>
                                            <span>{client?.creation_date.split('T')[0]}</span>
                                        </div>
                                        {selectedTable !== 'pros' ?
                                            <div>
                                                <h6>Last Active</h6>
                                                <span>{client?.dates_active?.split(',')[0]}</span>
                                            </div>
                                            : null}


                                    </div>
                                </div>
                                : null}

                        </div>
                    </div>
                    {windowSize > 900 ?
                        <div className="Account-Info " style={{ marginLeft: '30px' }} >
                            <div className="width-full-pt text-align-center padding-5-px" style={{ backgroundColor: 'lightgrey', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                                <h5>Account Info</h5>

                            </div>
                            <div className="padding-10-px"  >
                                <div style={{ marginBottom: '20px' }}>
                                    <h6>Creation Date</h6>
                                    <span>{client?.creation_date?.split('T')[0]}</span>
                                </div>
                                {selectedTable !== 'pros' ?
                                    <div>
                                        <h6>Last Active</h6>
                                        <span>{client?.dates_active?.split(',')[0]}</span>
                                    </div>
                                    : null}


                            </div>
                        </div>
                        : null}
                </div>

                <div className="Selection-Bar">
                    <div className={`Selection-Bar-Tab cursor-pointer ${selectedTab === 'Edit' ? 'Selection-Bar-Selected' : ''} `} onClick={() => setSelectedTab('Edit')}>
                        Edit Info
                    </div>
                    {client?.role_id !== 1 && selectedTable !== 'pros' ?
                        <div className={`Selection-Bar-Tab cursor-pointer ${selectedTab === 'Subscription' ? 'Selection-Bar-Selected' : ''} `} onClick={() => setSelectedTab('Subscription')}>
                            Subscription
                        </div> : null}
                    {client?.role_id !== 1 && selectedTable !== 'pros' ?
                        <div className={`Selection-Bar-Tab cursor-pointer ${selectedTab === 'Coupon' ? 'Selection-Bar-Selected' : ''} `} onClick={() => setSelectedTab('Coupon')}>
                            Coupon
                        </div> : null}
                    {selectedTable !== 'pros' ?
                        <div className={`Selection-Bar-Tab cursor-pointer ${selectedTab === 'Notes' ? 'Selection-Bar-Selected' : ''} `} onClick={() => setSelectedTab('Notes')}>
                            Notes
                        </div>
                        : null}

                </div>
                <div className={`${selectedTab === "Edit" ? 'Tab-Page-Info' : 'Tab-Page'}`}>
                    {selectedTab === 'Edit' ?
                        <div style={{ maxWidth: '675px', padding: '10px' }}>
                            <div className="input-grid">
                                {columns?.filter(client => !["creation_date", 'brokerage_phone_number', 'brokerage'].includes(client?.field)).map((column, index) => (

                                    <div key={index} className="admin-popup-field">
                                        <label className="admin-popup-label">{column.header}</label>
                                        {column.field === "brokerage_contact_name" ? (
                                            <select
                                                className="admin-popup-dropdown"
                                                name={"brokerage"}
                                                value={changes?.brokerage ?? clientInfo.brokerage}
                                                onChange={(e) => updateInput('brokerage', e.target.value)}
                                            >
                                                {brokerages?.map((brokerage, i) => (
                                                    <option key={i} value={brokerage.id}>
                                                        {brokerage.contact_name}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : column.field === "address" ? (
                                            <input
                                                type="text"
                                                className="admin-popup-input"
                                                defaultValue={client[column.field]}
                                                name={column.field}
                                                ref={addressRef}
                                                onChange={(e) => updateInput('address', e.target.value)}
                                            />
                                        ) : column.field === "agent_name" ? (
                                            <select
                                                className="admin-popup-dropdown"
                                                name={"agent"}
                                                onChange={(e) => updateInput('agent', e.target.value)}
                                                value={changes?.agent ?? client.agent_id}
                                            >
                                                {agents?.map((agent, i) => (
                                                    <option key={i} value={agent.id}>
                                                        {agent.contact_name}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : column.field === "industry_name" ? (
                                            <select
                                                className="admin-popup-dropdown"
                                                name={"industry_name"}
                                                onChange={(e) => updateInput('industry_name', e.target.value)}
                                                value={changes?.industry_name ?? client?.industry_name}
                                            >

                                                {INDUSTRIES?.map((val, index) => (
                                                    <option key={index} value={val}>
                                                        {val}
                                                    </option>

                                                ))}

                                            </select>

                                        )
                                            : column.field === "state" ? (
                                                <select
                                                    className="admin-popup-dropdown"
                                                    name={"state"}
                                                    onChange={(e) => updateInput(column.field, e.target.value)}
                                                    value={changes?.state ?? client?.state ?? null}
                                                >
                                                    {changes?.country !== "Canada" ?
                                                        inputConstants?.US_STATES.map((val, index) => (
                                                            <option key={index} value={val.value}>
                                                                {val.label}
                                                            </option>
                                                        )) : null}
                                                    {changes?.country !== "United States" ?
                                                        inputConstants?.CANDADIAN_PROVINCES.map((val, index) => (
                                                            <option key={index} value={val.value}>
                                                                {val.label}
                                                            </option>
                                                        )) : null}
                                                </select>

                                            )
                                                : column.field === "board" ? (
                                                    <MultipleSelection selectionMap={decodeBoards(changes?.board) ?? {}} handleSelectionMap={handleSelectionMap} selectionOptionsArray={selectionOptionsArray ?? [""]} />
                                                )
                                                    :
                                                    column.field === "country" ? (
                                                        <select
                                                            className="admin-popup-dropdown"
                                                            name={"country"}
                                                            value={changes?.country ?? client?.country ?? null}
                                                            onChange={(e) => updateInput(column.field, e.target.value)}

                                                        >

                                                            <option value="">Select Country</option>
                                                            <option key={"countryCanada"} value={"Canada"}>
                                                                {"Canada"}
                                                            </option>
                                                            <option key={"countryUS"} value={"United States"}>
                                                                {"United States"}
                                                            </option>
                                                        </select>

                                                    )

                                                        : (
                                                            <input

                                                                type="text"
                                                                className="admin-popup-input"
                                                                defaultValue={changes[column.field]}
                                                                name={column.field}
                                                                onChange={(e) => updateInput(column.field, e.target.value)}
                                                            />
                                                        )}

                                    </div>

                                ))}


                            </div>
                            {selectedTable === 'pros' ?
                                <>
                                    <div className="display-flex align-items-center justify-content-center margin-10-px">
                                        <span>Nation Wide? </span>
                                        <input className="margin-left-15-px" type="checkbox" name={"nation_wide"} checked={changes.nation_wide || false} onChange={(e) => handleCheck(e)}></input>
                                        <span className="margin-left-15-px">State/Province Wide? </span>
                                        <input className="margin-left-15-px" type="checkbox" name={"state_wide"} checked={changes.state_wide || false} onChange={(e) => handleCheck(e)}></input>
                                    </div>
                                    <div className="margin-auto-lr align-items-center justify-content-center display-flex padding-bottom-20-px" >
                                        <span>Additional service areas</span>
                                        <button style={{ marginLeft: '15px' }} className="Tag-Button" onClick={() => { const random = `${Math.random()}`; setAddresses({ ...addresses, [random]: {} }); setNewAddressKey(random) }}>Add <FontAwesomeIcon icon={faPlus} /></button>
                                    </div>
                                    {Object.keys(addresses).map(key =>
                                        <div className="input-grid">

                                            <div className="admin-popup-field position-relative">
                                                <FontAwesomeIcon onClick={() => deleteAddress(key)} icon={faXmarkCircle} className=" grey position-absolute cursor-pointer" style={{ top: '55%', left: `${windowSize > 992 ? '220%' : '105%'}`, zIndex: '1' }} />

                                                <label className="admin-popup-label ">Address</label>


                                                <input
                                                    id={`${key}_honeyydo_pros_address_edit`}
                                                    className="admin-popup-input"
                                                    type="text"
                                                    name="Address"
                                                    value={addresses[key]?.address ?? null}

                                                    onChange={(e) => {
                                                        updateAddress("address", e.currentTarget.value, key);

                                                    }}
                                                />
                                            </div>


                                            <div className="admin-popup-field position-relative">

                                                <label className="admin-popup-label ">City</label>


                                                <input
                                                    className="admin-popup-input"
                                                    type="text"

                                                    value={addresses[key]?.city ?? null}

                                                    onChange={(e) => {
                                                        updateAddress("city", e.currentTarget.value, key);

                                                    }}
                                                />
                                            </div>

                                            <div className="admin-popup-field position-relative">

                                                <label className="admin-popup-label ">State</label>
                                                <select
                                                    name="state"

                                                    className='admin-popup-input'
                                                    value={addresses[key]?.state ?? null}
                                                    onChange={(e) => {
                                                        updateAddress("state", e.currentTarget.value, key);

                                                    }}

                                                >
                                                    {addresses[key]?.country !== "Canada" ?
                                                        inputConstants?.US_STATES.map((val, index) => (
                                                            <option key={index} value={val.value}>
                                                                {val.label}
                                                            </option>
                                                        )) : null}
                                                    {addresses[key]?.country !== "United States" ?
                                                        inputConstants?.CANDADIAN_PROVINCES.map((val, index) => (
                                                            <option key={index} value={val.value}>
                                                                {val.label}
                                                            </option>
                                                        )) : null}
                                                </select>

                                            </div>

                                            <div className="admin-popup-field position-relative">

                                                <label className="admin-popup-label ">
                                                    {addresses[key]?.country === "Canada" ? "Postal Code*" : "Zip Code*"}
                                                </label>


                                                <input
                                                    className="admin-popup-input"
                                                    type="text"
                                                    maxLength={addresses[key]?.country === "Canada" ? "6" : "5"}
                                                    value={addresses[key]?.zip_code ?? null}

                                                    onChange={(e) => {
                                                        updateAddress("zip_code", e.currentTarget.value, key);

                                                    }}
                                                />
                                            </div>

                                            <div className="admin-popup-field position-relative">

                                                <label className="admin-popup-label ">Country</label>
                                                <select
                                                    name="country"

                                                    className='admin-popup-input'
                                                    value={addresses[key]?.country ?? null}
                                                    onChange={(e) => {
                                                        updateAddress("country", e.currentTarget.value, key);

                                                    }}

                                                >

                                                    <option value="">Select Country</option>
                                                    <option key={"countryCanada"} value={"Canada"}>
                                                        {"Canada"}
                                                    </option>
                                                    <option key={"countryUS"} value={"United States"}>
                                                        {"United States"}
                                                    </option>
                                                </select>

                                            </div>



                                        </div>
                                    )}

                                </>
                                : null}
                            {requested ?

                                <button className="popup-submit-button display-block margin-auto-lr margin-top-30-px" >
                                    <Spinner />
                                </button>
                                :

                                <button className="popup-submit-button display-block margin-auto-lr margin-top-30-px" onClick={() => { submitChanges() }}>
                                    Save
                                </button>


                            }

                        </div> : null}

                    {selectedTab === 'Edit' ?
                        <div className="Toggle-Container">
                            {(selectedTable === "agents" || selectedTable === 'insurance_agents' || selectedTable === 'mortgage_agents'|| selectedTable === "brokerages" || selectedTable === 'pros') ?

                                <div className="admin-toggle-buttons-container-small">
                                    <span >{(clientInfo.role_id === 4 && clientInfo.plan_type === 2 && clientInfo.payment_attached === 0) ? "No Payment Attached to Pro" : "Verified: "}</span>
                                    {(clientInfo.role_id === 4 && clientInfo.plan_type === 2 && clientInfo.payment_attached === 0) ?
                                        null :
                                        <div className="toggle-buttons-small">

                                            <button
                                                className={`toggle-button-small ${clientInfo.admin_verified === 0 ? "toggle-button-selected-off" : ""
                                                    }`}
                                                onClick={() => handleValidateToggle(clientInfo, clientInfo.admin_verified === 0 ? 1 : 0)}
                                                disabled={clientInfo.role_id === 4 && clientInfo.plan_type === 2 && clientInfo.payment_attached === 0}
                                            >

                                            </button>
                                            <button
                                                className={`toggle-button-small ${clientInfo.admin_verified === 1 ? "toggle-button-selected" : ""
                                                    }`}
                                                onClick={() => handleValidateToggle(clientInfo, clientInfo.admin_verified === 0 ? 1 : 0)}
                                                disabled={clientInfo.role_id === 4 && clientInfo.plan_type === 2 && clientInfo.payment_attached === 0}
                                            >

                                            </button>

                                        </div>

                                    }
                                </div>
                                : null}
                            {(selectedTable === "agents" || selectedTable === 'insurance_agents' || selectedTable === 'mortgage_agents' || selectedTable === "brokerages" || selectedTable === "homeowners") ?
                                <div className={`admin-toggle-buttons-container-small`}>
                                    <span >Subscribed:</span>

                                    <div className="toggle-buttons-small">

                                        <button
                                            className={`toggle-button-small ${clientInfo.subscribed === 0 ? "toggle-button-selected-off" : ""
                                                }`}
                                            onClick={() => handleSubscribeToggle(clientInfo, clientInfo.subscribed === 0 ? 1 : 0)}
                                        >

                                        </button>
                                        <button
                                            className={`toggle-button-small ${clientInfo.subscribed === 1 ? "toggle-button-selected" : ""
                                                }`}
                                            onClick={() => handleSubscribeToggle(clientInfo, clientInfo.subscribed === 0 ? 1 : 0)}
                                        >


                                        </button>

                                    </div>
                                </div>
                                : null}
                            <div className={`admin-toggle-buttons-container-small`}>
                                <span >Locked:</span>

                                <div className="toggle-buttons-small">

                                    <button
                                        className={`toggle-button-small ${clientInfo?.locked === 0 ? "toggle-button-selected-off" : ""
                                            }`}
                                        onClick={() => handleLockedToggle(clientInfo, clientInfo?.locked === 0 ? 1 : 0)}
                                    >

                                    </button>
                                    <button
                                        className={`toggle-button-small ${clientInfo?.locked === 1 ? "toggle-button-selected" : ""
                                            }`}
                                        onClick={() => handleLockedToggle(clientInfo, clientInfo?.locked === 0 ? 1 : 0)}
                                    >


                                    </button>

                                </div>
                            </div>
                        </div> : null
                    }
                    {selectedTab === 'Edit' ?
                        <div style={{ flex: '1' }} className="position-relative">
                            <FontAwesomeIcon className="position-absolute fa-lg cursor-pointer" style={{ right: '5%', top: '30px', color: 'red' }} icon={faTrash} onClick={() => setDeletePopup(true)} />

                        </div> : null}

                    {selectedTab === 'Subscription' ?
                        sub ?
                            <AdminStripe

                                item={client}
                                stripeItem={sub}
                                columns={columns}
                                // editFunction={editFunction}
                                // setRefreshData={setRefresh(!refresh)}
                                selectedTable={selectedTable}
                                embedded={true}
                            />
                            : <div style={{ padding: '30px 15px', minHeight: '50vh' }} className="position-relative"><span>No subscription</span></div>
                        : null}
                    {selectedTab === 'Coupon' ?
                        sub ?
                            sub.discount ?
                                <AdminStripeCoupon

                                    item={client}
                                    stripeItem={sub}
                                    setRefreshData={setRefresh}
                                />

                                : null
                            : <div style={{ padding: '30px 15px', minHeight: '50vh' }} className="position-relative"><span>No Coupon</span></div>
                        : null}

                    {selectedTab === 'Notes' ?
                        <div style={{ width: `${windowSize > 750 ? '50%' : '100%'}`, margin: '30px' }}>
                            <textarea style={{ minHeight: '400px', width: '100%', backgroundColor: 'white' }} value={clientInfo?.admin_note} onChange={(e) => updateClient('admin_note', e.target.value)} className="Input-Style"></textarea>
                            <div style={{ justifyContent: 'right' }} className="width-full-pt display-flex ">
                                <span className="count">{clientInfo?.admin_note?.length ?? 0}/{MAXLENGTH}</span>
                            </div>
                            <div className="display-flex column-gap-10-px margin-top-15-px">
                                {/* <button className="Cancel-Button" onClick={() => { setNoteDialog(0); resetNote(); }}>Close</button> */}

                                <button className="popup-submit-button display-block margin-auto-lr" onClick={() => saveNote()} >
                                    Save
                                    {/* <FontAwesomeIcon className="fa-sm margin-left-5-px" icon={faSave} /> */}
                                </button>
                            </div>
                        </div> : null}
                </div>
                {deletePopup && (
                    <AdminDelete
                        onClose={() => setDeletePopup(false)}
                        item={client}
                        deleteFunction={tableFileds[selectedTable]["deleteFunction"]}
                        refreshAfterDelete={true}
                    // setRefreshData={setRefreshData}
                    />
                )}

            </div>
        </>
    )

}

