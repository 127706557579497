import { useEffect, useState, useRef } from "react";
import contactImg from "../../../assets/images/contact-img.jpg";
import ReCAPTCHA from "react-google-recaptcha";
import validateUtility from "../../../functions/validateUtility";
import PopupMessage from "../../utility.component/popupMessage.component/popupMessage.component";
import { ImCheckmark } from "react-icons/im";
import sqlService from "../../../services/sqlService";



function Contact() {

    const [serverMessage, setServerMessage] = useState(null)
    const [newMessage, setNewMessage] = useState({})
    const validated = useRef({ contact_name: false, email: false, subject: false, message: false })
    const [trigger, setTrigger] = useState(false)



    function updateNewMessage(key, value) {
        var temp = { ...newMessage }
        temp[key] = value;
        setNewMessage(temp)
     
            console.log(validateUtility.regValidate(key, value))

            validated.current[key] = validateUtility.regValidate(key, value) ? true : false;
       
        
    }

    function onSubmit() {
        if (!(validated.current.contact_name && validated.current.email && validated.current.subject && validated.current.message)) {
            setTrigger(true)
            setServerMessage({ "message": "Please fill out all required values", "title": "Oops" })

            return
        }
        const token = captchaRef.current.getValue();

        captchaRef.current.reset();
        if (!token) {
            setServerMessage({ "message": "Please verify that you are not a robot", "title": "Error" })
            return
        }
        submit()
    }

    function submit() {
        sqlService.supportRequest({ input: newMessage }).then(() => {
            setServerMessage({
                title: "Thanks for submitting!",
                message: "Someone from our support team will be in touch shortly",
            });

        }).catch((err) => {

        })

    }

    const captchaRef = useRef(null)
    const [loadedCaptcha, setLoadedCaptcha] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setLoadedCaptcha(true);
        }, 1500);
    })

    return (
        <div className="background">
            <h1 className="padding-top-5-vh padding-bottom-3-vh heading">Contact Us</h1>
            <img className="Contact-Img " src={contactImg} alt="Woman on laptop" />
            <div style={{ maxWidth: '1300px', padding: '0 5%' }} className="margin-auto-lr">
                <h3 className="grey text-align-center margin-top-50-px margin-bottom-30-px">Need help? Give us a call or reach out to our support staff over email</h3>
                <div className="display-flex justify-content-center column-gap-50-px row-gap-30-px row-to-column">


                    <div className="card-form height-fit-content padding-10-px box-shadow-none ">

                        <div className=" box-shadow-none ">

                            <div className="card-input position-relative">

                                <label className="card-input__label">
                                    Name<span className="red-font">*</span>
                                </label>
                                <input
                                    type="text"
                                    className={`card-input__input ${(trigger && !validated.current.contact_name) ? "Input-Error-Border" : ""}`}
                                    autoComplete="off"

                                    value={newMessage.contact_name}
                                    onChange={(e) => { updateNewMessage("contact_name", e.currentTarget.value); }}

                                />
                                {validated.current.contact_name  ?

                                    <span className="Finish-Check"><ImCheckmark /></span>
                                    : null}


                            </div>
                            <div className="card-input position-relative">

                                <label className="card-input__label">
                                    Email Address<span className="red-font">*</span>
                                </label>
                                <input
                                    type="text"
                                    className={`card-input__input ${(trigger && validated.current.email !== true) ? "Input-Error-Border" : ""}`}
                                    autoComplete="off"

                                    value={newMessage.email}
                                    onChange={(e) => { updateNewMessage("email", e.currentTarget.value); }}

                                />
                                {validated.current.email === true ?

                                    <span className="Finish-Check"><ImCheckmark /></span>
                                    : null}


                            </div>

                            <div className="card-input position-relative">

                                <label className="card-input__label">
                                    Subject<span className="red-font">*</span>
                                </label>
                                <input
                                    type="text"
                                    className={`card-input__input ${(trigger && validated.current.subject !== true) ? "Input-Error-Border" : ""}`}
                                    autoComplete="off"

                                    value={newMessage.subject}
                                    onChange={(e) => { updateNewMessage("subject", e.currentTarget.value); }}

                                />
                                {validated.current.subject === true ?

                                    <span className="Finish-Check"><ImCheckmark /></span>
                                    : null}


                            </div>

                            <div className="card-input position-relative">

                                <label className="card-input__label">
                                    Message<span className="red-font">*</span>
                                </label>
                                <textarea
                                    type="text"
                                    className={`card-input__input ${(trigger && validated.current.message !== true) ? "Input-Error-Border" : ""}`}
                                    autoComplete="off"

                                    value={newMessage.message}
                                    onChange={(e) => { updateNewMessage("message", e.currentTarget.value); }}

                                />
                                {validated.current.message === true ?

                                    <span className="Finish-Check"><ImCheckmark /></span>
                                    : null}


                            </div>
                        </div>
                        {loadedCaptcha &&
                            <ReCAPTCHA
                                style={{ marginTop: '20px' }}
                                theme="light"
                                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY_V2}
                                ref={captchaRef}
                                onChange={null}
                                asyncScriptOnLoad={null}
                            />
                        }
                        <button onClick={onSubmit} className="popup-submit-button margin-top-30-px">Submit Support Request</button>
                    </div>
                    <div style={{columnGap: '15%', marginTop: '10px'}} className="margin-left-10-px margin-bottom-20-px display-flex column-to-row  row-gap-30-px color-med-grey text-smaller bold ">
                        <p>
                            Support: <br />
                            (610) 879-1997

                        </p>
                        <p>
                            Sales: <br />
                            1-800-965-7554 
                        </p>

                    </div>


                </div>
                <p className=" color-med-grey text-smaller  bold padding-3-5-pt text-align-center">


                    **Please note that our support staff is available Monday-Friday 9am-6pm EST. We will do our best to respond to all inquiries within one business day.**
                    <br /><br />
                    Proudly built in the USA
                </p>
            </div>
            <PopupMessage show={serverMessage} onConfirm={()=>setServerMessage(null)} title={serverMessage?.title} message={serverMessage?.message}/>
        </div>

    )

}

export default Contact;