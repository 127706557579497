import React, { useState, useEffect, useRef } from "react";
import { faEnvelope, faGlobe, faStar } from "@fortawesome/free-solid-svg-icons";
import './homeownerlanding.component.css'
import PoweredBy from "../poweredby.component/poweredby.component";
import PieChart from "../../utility.component/dash.component/piechart.component";
import DonutChart from "../../utility.component/dash.component/donutchart.component";
import LineChart from "../../utility.component/dash.component/linechart.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faArrowRightLong, faSun, faMoon, faPhone, faLetter } from "@fortawesome/free-solid-svg-icons";
import sqlService from "../../../services/sqlService";
import Placeholder from "../../../assets/images/Placeholder-removebg.png";
import { UserContext } from "../../../App";
import { useContext } from "react";
import Notifications from "../../utility.component/notifications.component/notifications.component";
import timeUtility from "../../../functions/timeUtility";
import InviteButton from "../../utility.component/inviteButton.component/inviteButton.component";

import { useNavigate } from "react-router-dom";
import sortUtility from "../../../functions/sortUtility";
import Weather from "../../universal.component/weather.component/weather.component";
import WeatherMobile from "../../universal.component/weather.component/weatherMobile.component";
import Notepad from "../../utility.component/notepad.component/notepad.component";
import NotepadMobile from "../../utility.component/notepad.component/notepadMobile.component";
import WelcomeMessage from "../../utility.component/welcomeMessage.component/welcomeMessage.component"
import { Link } from "react-router-dom";
import { SiZillow } from "react-icons/si";
import {
  faFacebook,
  faInstagram,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";
import { ThreeCircles } from "react-loader-spinner";
import banner2 from "../../../assets/images/banner-photos/Banner5-min.jpg"
import banner5 from "../../../assets/images/banner-photos/Banner9-min.jpg"
import banner8 from "../../../assets/images/banner-photos/Banner3-min.jpg"
import banner9 from "../../../assets/images/banner-photos/Banner2-min.jpg"
import banner11 from "../../../assets/images/banner-photos/Banner1-min.jpg"
import banner12 from "../../../assets/images/banner-photos/Banner4-min.jpg"
import banner14 from "../../../assets/images/banner-photos/Banner8-min.jpg"
import banner15 from "../../../assets/images/banner-photos/Banner6-min.jpg"
import banner20 from "../../../assets/images/banner-photos/Banner7-min.jpg"

function HomeownerDashboard() {
  const bannerImgs = [
  { url: banner9, position: '0 90%', size: 'cover' },
  { url: banner15, position: '0 44%', size: 'cover' },
  { url: banner14, position: '0 41%', size: 'cover' },
  { url: banner12, position: '0 56%', size: 'cover' },
  { url: banner11, position: '0 80%', size: 'cover' },
  { url: banner8, position: '0 56%', size: 'cover' },
  { url: banner5, position: '0 55%', size: 'cover' },
  { url: banner2, position: '0 65%', size: 'auto' },
  { url: banner20, position: '0 49%', size: 'cover' },

]
  
  const banner = useRef({})

  useEffect(() => {

    banner.current = bannerImgs[Math.floor(Math.random() * bannerImgs?.length)]
    
  }, [])

  const user = useContext(UserContext);
  const [windowSize, setWindowSize] = React.useState(window.innerWidth);
  const [name, setName] = useState('');
  var currentDate = new Date();
  currentDate.setHours(0, 0, 0)
  // const COLORS = ['#f8a025', '#2596be', '#34378b', '#b7b8d6', '#b551dd']
  const COLORS = ['#fab43f', '#2596be', '#34378b', '#aeb1ff', '#b179fc']

  const [taskData, setTaskData] = useState([]);
  const [categoryLabels, setCategoryLabels] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [yMax, setYMax] = useState(0)

  const [completedTasks, setCompletedTasks] = useState(0);
  const [outstandingTasks, setOutstandingTasks] = useState([]);
  const [upcomingOutstandingTasks, setUpcomingOutstandingTasks] = useState([])
  // Incomplete tasks ranked by catergory
  const [rankedTasks, setRankedTasks] = useState([]);
  const [allTasks, setAllTasks] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const rankedTasksLoaded = useRef(false);
  const allTasksLoaded = useRef(false);
  const homeownerLoaded = useRef(false);
  const loaded = useRef(false)

  const [allUniversalTasks, setAllUniversalTasks] = useState(null);
  const outstandingTasksRef = useRef(null);
  const allUniversalTasksRef = useRef(null);
  const time = new Date().getHours()
  const welcome = `Good ${time < 12 && time > 4 ? 'Morning' : time < 17 && time > 2 ? 'Afternoon' : 'Evening'}, ${user?.contact_name?.split(' ')[0]}`
  const icon = time < 4 || time < 17 ? faSun : faMoon
  const [todaysTasks, setTodaysTasks] = useState([])
  const blogsLoaded = useRef(false);
  const [blog, setBlog] = useState({})
  const [agents, setAgents] = useState("");
  const [socials, setSocials] = useState([])
  const socialIcons = { "facebook": faFacebook, "instagram": faInstagram, "google": faGoogle }
  const allowRender = useRef(false)

  const Navigate = useNavigate();


  useEffect(() => {
    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);


  const onWindowResize = () => {
    setWindowSize(window.innerWidth);
  }

  useEffect(() => {
    if (!user) {
      Navigate("/")
    }
  })

  useEffect(() => {

    if (!user) {
      return;
    }
    if (!loaded.current) {
      sqlService.getAgentsPB({}).then(data => {
        setAgents(data.data[0]);
        setSocials(data.data)
        console.log(data.data)
      }).catch(err => {
        console.log(err)
      })
      loaded.current = true;
    }
    if (!blogsLoaded.current) {
      sqlService.getLibraries()
        .then(data => {

          const blogs = data.data.reverse();
          setBlog(blogs?.find((blog) => !blog.tags?.includes('Updates')))


        })
        .catch(err => {
          console.log(err);
        });
      blogsLoaded.current = true;
    }

    if (!rankedTasksLoaded.current) {
      sqlService
        .getTasksByCategory({})
        .then((data) => {
          const assigned = data.data;
          // console.log( {assigned});

          sqlService.getAllUniversalTasksFromHomeOwnerAdminByCategoryThatHomeowenerIsNotAssignedWithin30Days({}).then(d => {
            let universal = d.data;
            // console.log({universal});
            if (universal) {
              // let t = [...assigned, ...universal]
              // setRankedTasks(t);

              // Appending these two lists was causing an issue because the categories repeated. I am merging them like this for now
              // because neither list is that long but there's probably a better way to get all the catgories at once from the backend

              let sorted = sortUtility.sort("category", [...assigned, ...universal], false)
              var temp = []
              for (let item of sorted) {
                if (temp.length && item.category === temp[temp.length - 1].category) {
                  temp[temp.length - 1].total += item.total

                } else {
                  temp.push(item)
                }
              }

              setRankedTasks(sortUtility.sort("total", temp, false))

            } else {
              setRankedTasks(assigned)
            }

          }).catch(err => {
            // console.log("sdfsfsdfsdkfsjldkfjsldfsjldfksjlfksjldfkjsldkfsjf")
            console.log(err)
          })




        })
        .catch((err) => {
          console.log(err);
        });
      rankedTasksLoaded.current = true;
    }

    if (!allTasksLoaded.current) {

      sqlService
        .getRepeatingTasks({ "user": user })
        .then((data) => {
          const tasks = data.data;
          const repeatingTasks = tasks.filter(task => !task.task_completed && !task.repeating_completed)
          // console.log({ repeatingTasks })

          // console.log("++++", outstandingTasksRef.current)
          let outStanding1 = outstandingTasksRef.current ? [...outstandingTasksRef.current, ...repeatingTasks] : repeatingTasks
          setOutstandingTasks(outStanding1);
          outstandingTasksRef.current = outStanding1;

        })
        .catch((err) => {
          console.log(err);
        });


      sqlService.getAllUniversalTasksFromHomeOwnerAdminThatUserNotOptedOut({}).then(data => {
        const tasks = data.data;
        // console.log({tasks})
        const allUniversalTasks1 = tasks;

        // console.log("+-+-+-+", outstandingTasksRef.current)
        let outStanding1 = outstandingTasksRef.current ? [...outstandingTasksRef.current, ...allUniversalTasks1] : allUniversalTasks1
        setOutstandingTasks(outStanding1);
        allUniversalTasksRef.current = allUniversalTasks;
        outstandingTasksRef.current = outStanding1;

      }).catch(err => {
        console.log(err)
      })


      allTasksLoaded.current = true;
    }

    setName(user?.contact_name?.split(' ')[0]);

    if (!homeownerLoaded.current) {
      sqlService
        .getHomeownerById({ "user": user })
        .then((data) => {
          const homeowner = data.data;
          setCompletedTasks(homeowner.completed_tasks);




        })
        .catch((err) => {
          console.log(err);
        });


    }

  }, [refresh]);

  useEffect(() => {
    var t = new Date()
    const day = t.getDate()
    const month = t.getMonth()
    const year = t.getFullYear()
    var temp = []
    var tasks = []
    var plusOneMonth = new Date()
    plusOneMonth.setMonth(plusOneMonth.getMonth() + 1)

    for (var task of outstandingTasks) {
      var d = task.repeating_date ?? task.task_date ?? task.start_date;

      d = new Date(d);
      if (day === d.getDate() && month === d.getMonth() && year === d.getFullYear()) {
        temp.push(task)

      }

      if (d < plusOneMonth && d >= currentDate) {

        tasks.push(task)

      }

    }



    setUpcomingOutstandingTasks(tasks)
    setTodaysTasks(temp)

  }, [outstandingTasks])
  const [categoryColours, setCategoryColours] = useState({})


  // Parse data for "Areas for Improvement" chart (max size of 5 as dictated by COLORS)
  useEffect(() => {
    const other = 0
    const data = []
    const labels = []
    const temp = {}

    for (var i = 0; i < rankedTasks.length && i < COLORS.length; i++) {
      data.push({
        angle: rankedTasks[i].total,
        color: COLORS[i],
        label: `${rankedTasks[i].category}:  ${rankedTasks[i].total}  ${rankedTasks[i].total > 1 ? ' new tasks in the next 30 days' : ' new task in the next 30 days'}`
      })

      labels.push({ title: rankedTasks[i].category, strokeWidth: 10 })
      temp[rankedTasks[i].category] = COLORS[i]
    }
    setTaskData(data)
    setCategoryLabels(labels)
    setCategoryColours(temp)

  }, [rankedTasks])

  // Function to get date x weeks from now
  function addWeeks(date, weeks) {
    date.setDate(date.getDate() + 7 * weeks);
    return date;
  }

  // Parse data for "Upcoming Deadlines" chart
  useEffect(() => {
    var data = [{ x: '', y: 0 }, { x: '', y: 0 }, { x: '', y: 0 }, { x: '', y: 0 }]
    var weeks = []

    // Set week windows for comparison
    for (var i = 0; i < data.length + 1; i++) {
      var d = new Date()
      d.setHours(0, 0, 0, 0)
      weeks.push(addWeeks(d, i))

    }
    // console.log(weeks)

    // Check if due date falls in the four week window and increment approriate week
    for (const task of upcomingOutstandingTasks) {

      const date = timeUtility.getTimeFromMysqlDatetime(task.repeating_date ?? task.task_date ?? task.start_date)
      // console.log(task.title, date)


      if (date <= weeks[weeks.length - 1]) {

        for (var i = 0; i < data.length; i++) {


          if (date >= weeks[i] && date < weeks[i + 1]) {
            // console.log(i, task.title)
            // console.log(date)

            data[i].y++;
            break;
          }
        }
      }
    }

    // Assign labels to x axis of chart and hover labels for points
    for (var i = 0; i < data.length; i++) {
      data[i].x = new Date(weeks[i]).toLocaleString('default', { month: 'short', day: 'numeric' })
      data[i].pointLabel = (data[i].y === 0) ? 'Nothing coming up this week' : (data[i].y === 1) ? data[i].y.toString() + ' new task this week' : data[i].y.toString() + ' new tasks this week'

    }

    // Get max y value with min value of 3
    const max = Math.max(Math.max(...data.map(d => d.y)), 3)
    setLineData(data);
    setYMax(max);


  }, [upcomingOutstandingTasks])

  function formatPhoneNumber(number) {
    return '(' + number?.slice(0, 3) + ') ' + number?.slice(3, 6) + '-' + number?.slice(6, 10);
  }

  useEffect(() => {

    const onPageLoad = () => {
      allowRender.current = true;

    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <>
      {allowRender.current ?
        <>
          {!(user?.welcome_seen) ?
            <WelcomeMessage /> : null}

          <div className="dashboard-wrap">

            <div className="homepage-grid ">

              <div style={{ backgroundImage: `url(${banner.current?.url})`, backgroundPosition: banner.current?.position, backgroundSize: banner.current?.size }} className="homepage-banner display-flex justify-content-space-between">

      
                <div className="display-flex">
                  <FontAwesomeIcon style={{filter: 'drop-shadow(1px 1px 15px black)'}} className="margin-right-10-px white fa-2x" transform={{ rotate: -15 }} icon={icon} />
                  <h3 style={{ textShadow: ' black 1px 0 20px' }} className="white">

                    {welcome}</h3>
                </div>
                <div className=" dashboard-rating"  >

                  <h5 className="white">My Agent</h5>
          
                  <div className="banner-agent-container">
                    <div className="display-flex column-gap-20-px">
                      <div className="banner-agent-img" >

                        <img className="object-fit-cover height-width-100-pt" src={agents?.img_link && agents?.img_link !== '' ? agents.img_link : Placeholder} alt="agent" />
                      </div>
                      <div className="banner-agent-info display-flex flex-direction-column">
                        <span className="wrap-none" ><FontAwesomeIcon icon={faPhone} />{formatPhoneNumber(agents.phone_number)}</span>
                        <span className="wrap-none" ><FontAwesomeIcon icon={faEnvelope} />{agents.email}</span>

                        <div className="banner-social-icons">
                          {socials.map((link) =>
                            socialIcons[link.type_name] || link.type_name === 'zillow' ?
                              <a href={`https://${link.link.replace(/^https?:\/\//, '')}`} className="active" >
                                {link.type_name === 'zillow' ?
                                  <SiZillow style={{ marginBottom: '5px' }} />
                                  : <FontAwesomeIcon icon={socialIcons[link.type_name]} />
                                }

                              </a>
                              : null
                          )}
                          {agents?.website ?
                            <a href={`https://${agents.website.replace(/^https?:\/\//, '')}`} className="active" >
                              <FontAwesomeIcon icon={faGlobe} />
                            </a>
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>




                </div>

              </div>
              <div className="grid-container daily-tile">
                <h5 className="grey margin-bottom-20-px">{new Date().toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' })}</h5>


                {!todaysTasks?.length ?
                  <h6 className="color-med-grey margin-bottom-20-px margin-top-40-px">
                    Nothing scheduled for today
                  </h6> : null}
                <div className="display-flex flex-direction-column ">

                  {todaysTasks?.map((task) =>
                    <div className="column-gap-10-px daily-grid">
                      <div style={{ backgroundColor: categoryColours[task.category], height: '30px' }} className="time-tag">
                        {task.category}
                      </div>
                      <span className="bold color-med-grey">{task.title}</span>
                    </div>
                  )}
                </div>



              </div>

              <div className="graphs grid-container" >
                <h5 className="grey">My Upcoming Tasks</h5>
                <h6 className="color-med-grey">By Category</h6>

                {taskData.length > 0 ?
                  <PieChart
                    pieData={taskData}
                    labels={categoryLabels}
                    pieColors={COLORS}
                  />
                  : <div className="No-Outstanding orange display-flex flex-direction-column">
                    <h5 className="grey">You're all caught up!</h5>
                    <FontAwesomeIcon className="orange fa-5x margin-auto-lr" icon={faStar} />
                  </div>
                }

                <h6 style={{ marginTop: '30px' }} className="color-med-grey">Timeline</h6>

                <LineChart
                  lineColors={COLORS}
                  maxValue={yMax}
                  lineData={lineData}
                  xLabel={'Week'}

                />


              </div>
              <div className="grid-container notes">
                <NotepadMobile />
              </div>

              <div style={{ gridRow: '3/span 1' }} className="dashboard-notifications ">
                <Notifications max={2} />
              </div>
              <div className="grid-container graph-single">
                <h5 className="grey text-center margin-bottom-30-px">Completed Tasks</h5>
                <DonutChart
                  donutColors={COLORS}
                  filled={completedTasks}
                  unfilled={upcomingOutstandingTasks.length}
                  filledText={'Completed'}
                  unfilledText={'Outstanding'}
                  filledHover={'Total completed tasks: ' + completedTasks}
                  unfilledHover={'Outstanding in the next 30 days: ' + upcomingOutstandingTasks.length}
                />

              </div>

              <Link className="blog-img-container-landing position-relative" to={`/blog/${blog?.id}`}>
                <div className="overflow-hidden border-radius-10-px position-relative aspect-ratio">
                  <img className="height-width-100-pt object-fit-cover blog-img-landing" src={blog?.img_link} />
                  <div style={{ backgroundColor: ' rgb(105, 105, 105, .5)', padding: '15px 20px' }} className="blog-link-background position-absolute bottom-0 width-full-pt ">

                    <span style={{ fontSize: '1.1em' }} className=" z-3 white bold">Check out our latest blog on {blog?.tags?.split(',')[0] !== 'DIY' ? blog?.tags?.split(',')[0]?.toLowerCase() : blog?.tags?.split(',')[0]} <FontAwesomeIcon className="blog-arrow" icon={faArrowRightLong} /> </span>
                  </div>
                </div>
              </Link>

            </div>

            <InviteButton topMargin={'30px'} buttonText={'Invite a friend'}/>

            <PoweredBy />



          </div >
        </>
        :
        <div className="display-flex justify-content-center align-items-center height-90-vh"><ThreeCircles
          height={Math.round(window.innerWidth / 10)}
          width={Math.round(window.innerWidth / 10)}
          color={COLORS[0]}
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="three-circles-rotating"
          outerCircleColor=""
          innerCircleColor=""
          middleCircleColor=""
        /></div>
      }



    </>


  )

}

export default HomeownerDashboard;