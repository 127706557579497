import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import inputConstants from "../../../constants/inputConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCoins, faHandHoldingDollar, faMagnifyingGlassLocation, faPlus, faUser, faUserTie, faUsers } from "@fortawesome/free-solid-svg-icons";
import proPhoto from "../../../assets/images/service-pro-photo.png";
import signupConstants from "../../../constants/signupConstants";
import userUtility from "../../../functions/userUtitily";
import { UserContext } from "../../../App";
import { useNavigate } from "react-router-dom";
export default function ProInfo() {
    const [plan, setPlan] = useState("monthly");
    const [windowSize, setWindowSize] = React.useState(window.innerWidth);
    const user = useContext(UserContext);
    const Navigate = useNavigate();
    useEffect(() => {
        if (user) {
            Navigate("/");
        }

        window.addEventListener("resize", onWindowResize);

        return () => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, []);

    const onWindowResize = () => {
        setWindowSize(window.innerWidth);
    };

    return (
        <>
            <div style={{ maxWidth: '1400px', padding: '0 5%', margin: '7% auto 5% auto' }} className="Pro-Hero">
                <div >
                    <h2 className="Blog-Page-Heading">Broaden your client-base</h2>
                    <h5 className="color-med-grey">Our database of verified pros is trusted by our homeowners to connect them with professionals in their area</h5>
                    <Link to={'/pro-signup/basic'}>
                        <button style={{ margin: '30px 0' }} className="popup-submit-button" >Join for free now</button>
                    </Link>
                </div>
                <img src={proPhoto} className="Pro-Photo" />
            </div>
            <div>
                <h2 className="text-align-center grey">How it Works</h2>
                <div className="Pro-Icons">
                    <div className="Landing-sub-grid">
                        <FontAwesomeIcon className="display-block margin-auto-lr orange fa-4x" icon={faCoins} />
                        <h4 className="text-align-center grey">Choose your tier</h4>
                        <p className="bold color-med-grey padding-0-5-pt">There is no cost to join our database at our basic tier. Simply provide us with your information and we will let you know once you have been approved.</p>
                    </div>
                    <div className="Landing-sub-grid">
                        <FontAwesomeIcon className="display-block margin-auto-lr orange fa-4x" icon={faMagnifyingGlassLocation} />
                        <h4 className="text-align-center grey">Appear in Search Results</h4>
                        <p className="bold color-med-grey padding-0-5-pt">When homeowners in your area search for trusted pros in your industry, they will see your information and be able to easily reach out to you.</p>
                    </div>
                    <div className="Landing-sub-grid">
                        <FontAwesomeIcon className="display-block margin-auto-lr orange fa-4x" icon={faHandHoldingDollar} />
                        <h4 className="text-align-center grey">Get More Business</h4>
                        <p className="bold color-med-grey padding-0-5-pt">More eyes on your business means more clients for you. It's a great way to jumpstart a new business or supercharge an existing one, all at no cost to you.</p>
                    </div>
                </div>
            </div>
            <div className=" column-gap-10-px justify-content-center">
                <h2 id='pricing' className="text-align-center grey margin-bottom-30-px">Pricing</h2>
                <div className="toggle-buttons-container ">
                    <div className="toggle-buttons">
                        <button
                            className={`toggle-button ${plan === "monthly" ? "toggle-button-selected" : ""
                                }`}
                            id="month"
                            onClick={() => setPlan("monthly")}
                        >
                            Monthly
                        </button>
                        <button
                            className={`toggle-button ${plan === "yearly" ? "toggle-button-selected" : ""
                                }`}
                            id="year"
                            onClick={() => setPlan("yearly")}
                        >
                            Yearly
                        </button>
                    </div>
                </div>
            </div>
            <div style={{ maxWidth: '800px', margin: '0 auto 50px auto' }} className={`${windowSize > 750 ? 'price-cards-container' : 'mobile-price-cards-container'}`}>
                <div className="price-card" id="agent">
                    <FontAwesomeIcon className="agent-icon" icon={faUser} />
                    <span className="price-card-title grey">Basic</span>
                    <span className="price-card-price">
                        Free              </span>
                    <span style={{ color: 'white' }} className="price-card-term">
                        {plan === "monthly" ? 'per month' : 'per year'}
                    </span>
                    <Link
                        to='/pro-signup/basic'
                        className="price-card-button"
                        id="homeowner-button"
                    >
                        Sign Up
                    </Link>
                    <span className="price-card-list-title">Includes:</span>
                    <div className="price-card-list-container">
                        <ul
                            className="price-card-list"
                            style={{ listStyleType: "disc" }}
                        >
                            <li>Visibility to our database of homeowners</li>
                            <li>Appear in search results of users in your area</li>

                        </ul>
                    </div>
                </div>
                <div className="price-card" style={{ borderTop: '15px solid #007bff' }}>
                    <FontAwesomeIcon className="premium-icon" icon={faUsers} />
                    <span className="price-card-title grey">Premium</span>
                    <span className="price-card-price">
                        ${plan === "monthly" ? inputConstants.SUBSCRIPTION_PRICES.pro.monthly : inputConstants.SUBSCRIPTION_PRICES.pro.yearly}.00<span style={{ fontSize: '0.5em', color: '#aaa5a5' }}> USD</span>
                    </span>
                    <span className="price-card-term">
                        {plan === "monthly" ? 'per month' : 'per year'}
                    </span>
                    <Link
                        // to={`/add-payment/agent/${plan}`}
                        to={`/pro-signup/premium/noid/${plan}`}
                        className="price-card-button"
                        id="homeowner-button"
                    >
                        Sign Up
                    </Link>
                    <span className="price-card-list-title">Includes:</span>
                    <div className="price-card-list-container">
                        <ul
                            className="price-card-list"
                            style={{ listStyleType: "disc" }}
                        >
                            <li>Search priority</li>
                            <li>Add logos and detailed business information</li>
                            <li>Ratings and reviews</li>
                            <li>Promotional content</li>

                        </ul>
                    </div>
                </div>



            </div>
        </>
    )
}