import React, { useState, useEffect, useRef, useMemo } from "react";
import useFetch from "../../utility.component/useFetch";
import serverConstants from "../../../constants/serverConstants";

import Placeholder from "../../../assets/images/Placeholder-removebg.png";
import Placeholder2 from "../../../assets/images/Placeholder2-removebg.png"
import sqlService from "../../../services/sqlService";
import './signup.component.css'

import PricingButton from "./pricingButton.component";

import Cookies from "universal-cookie";
import { useContext } from "react";
import { UserContext } from "../../../App";

import { Navigate, useParams } from "react-router-dom";


import { useNavigate } from "react-router-dom";

import { ImCheckmark } from "react-icons/im";

import MultipleSelection from "../../utility.component/multipleSelection";

import PopupMessage from "../../utility.component/popupMessage.component/popupMessage.component";

import Spinner from 'react-bootstrap/Spinner';

import serverResponseMessagesConstants from "../../../constants/serverResponseMessagesConstants";

import inputConstants from "../../../constants/inputConstants";

import userUtility from "../../../functions/userUtitily";

import Button from 'react-bootstrap/Button';
import HoverHint from "../../utility.component/hoverHint.component/hoverHint.component";
import { nullLiteral } from "@babel/types";

import { ThreeCircles } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser, faPen } from "@fortawesome/free-solid-svg-icons";
import locationService from "../../../services/locationService";
import { FcCancel } from "react-icons/fc";
import validateUtility from "../../../functions/validateUtility";
import ReCAPTCHA from "react-google-recaptcha";
import GoogleAutoComplete from "../../utility.component/googleAutoComplete.omponent";
import DatePicker from "react-datepicker";

function GoogleDiv({ handleGoogle, verified, error, loading }) {

    const [windowSize, setWindowSize] = React.useState(window.innerWidth);


    useEffect(() => {
        window.addEventListener("resize", onWindowResize);


        return () => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, []);


    const onWindowResize = () => {
        setWindowSize(window.innerWidth);

    };

    useEffect(() => {
        /* global google */
        console.log(process.env.REACT_APP_GOOGLE_CLIENT_ID)
        if (verified === false && window.google) {
            google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                callback: handleGoogle,
            });

            google.accounts.id.renderButton(document.getElementById("signUpDiv"), { theme: 'outline', text: "continue_with", width: windowSize > 499 ? 350 : 200, height: 50 });
        }
    }, [handleGoogle]);

    return (
        <div className="height-100-px" >
            {/* {error && <p className="background-linear-gradient border-radius-10-px padding-5-px white ">{error}</p>} */}
            {loading ? (
                <div>Loading....</div>
            ) : (
                <div className=" border-radius-10-px padding-5-px white " id="signUpDiv" data-text="signup_with"></div>
            )}
        </div>
    )
}


function SignupDiv({ children, verifyButtonClicked, onVerifyUser, authCodeStatus, onSubmit, onFinishSignup, setServerMessage, setAuthCodeStatus, setUser, finishSignupButtonClicked }) {

    const user = useContext(UserContext)

    const [newTask, setNewTask] = useState({});
    const newTaskRef = useRef({});

    const { role_id } = useParams();
    const [errorMessage, setErrorMessage] = useState('')

    const [triggerX, setTriggerX] = useState(false);

    const Navigate = useNavigate()

    const loaded = useRef(false);

    const [checked, setChecked] = useState({ "contact_name": "false", "email": "false", "password": "false", "confirm_password": "false" })
    const checkedRef = useRef({ "contact_name": "false", "email": "false", "password": "false", "confirm_password": "false" })

    const [profileFileObject, setProfileFileObject] = useState(null);
    const [logoFileObject, setLogoFileObject] = useState(null);
    const profileImgEdit = useRef(null)
    const logoImgEdit = useRef(null)

    const [selectionMap, setSelectionMap] = useState({});
    const [selectionOptionsArray, setSelectionOptionsArray] = useState([""])

    const [focused, setFocused] = useState(null);

    const [authCode, setAuthCode] = useState("");

    const [sendButtonClicked, setSendButtonClicked] = useState(false);

    const [windowSize, setWindowSize] = React.useState(window.innerWidth);

    const [refresh, setRefresh] = useState(false);
    const [addressSelection, setAddressSelection] = useState("same")

    useEffect(() => {
        window.addEventListener("resize", onWindowResize);


        return () => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, []);


    const onWindowResize = () => {
        setWindowSize(window.innerWidth);

    };
    function onRadioChange(event) {
        setAddressSelection(event.target.value);
        console.log(event.target.value);
    }


    const boardsRecieved = useRef(false);
    const allBoards = useRef([]);

    const currentCountry = useRef("current");

    useEffect(() => {
        // console.log("triggered dDDDDds");
        if (!boardsRecieved.current || (selectionOptionsArray.length < 2) || currentCountry.current !== newTask?.country) {
            // console.log("dDDDDds");
            sqlService.getAllBoards({}).then(d => {

                let arr = d.data
                allBoards.current = arr;
                var f = []
                // console.log("DDDDDDDDD", arr)
                for (let i of arr) {
                    if ((newTask?.country && newTask?.country !== "" && i.country === newTask?.country) || (!newTask.country || newTask.country === "")) {
                        f.push(i.contact_name)
                    }
                }
                // console.log(newTask?.country,f)
                // debugger;
                setSelectionOptionsArray(f)

            }).catch(err => {
                console.log(err);
            })
            boardsRecieved.current = true;
        }
    }, [newTask?.country]);

    useEffect(() => {
        console.log("dddddfdsdfksjdfksjdfksdjfsjflsdfjsldfjsfsdkflsdjfslf", user)
        if (!loaded.current) {
            let nTask = { ...newTask }
            nTask["contact_name"] = user?.contact_name;
            nTask["email"] = user?.email;
            nTask["img_link"] = user?.img_link ?? "";
            nTask["logo_link"] = user?.logo_link ?? "";
            nTask["phone_number"] = user?.phone_number;
            nTask["brokerage_name"] = user?.brokerage_name ?? "";


            let newChecked = { ...checked }
            newChecked["email"] = user?.email ? "true" : "false";
            newChecked["contact_name"] = user?.contact_name ? "true" : "false";
            newChecked["phone_number"] = user?.phone_number ? "true" : "false";
            newChecked["brokerage_name"] = (user?.brokerage_name && user?.brokerage_name !== "") ? "true" : "false";




            // }\
            if (!user?.brokerage_name && user?.brokerage_name === "", user?.solo_subscribed === 0 && (user?.role_id === 2 || user?.role_id === 5 || user?.role_id === 6)) {
                sqlService.getUserNameByInputId({ input: { id: user?.brokerage } }).then(d => {
                    if (d?.data?.contact_name) {
                        nTask["brokerage_name"] = d?.data?.contact_name ?? "";
                        newChecked["brokerage_name"] = "true";
                    }
                }).catch(err => {
                    console.log(err)
                })
            }


            
            setChecked(newChecked);
            setNewTask(nTask);
            //     }
            loaded.current = true;
        }

    }, [user])


    function updateNewTask(key, value) {
        console.log(key, value);
        switch (key) {
            case ("password"):
                if (validateUtility.regValidate(key, value)) {
                    let nChecked = { ...checked }
                    nChecked["password"] = "true";
                    if (value === newTask.confirm_password) {
                        nChecked["confirm_password"] = "true";
                    } else {
                        nChecked["confirm_password"] = "false";
                    }
                    setChecked(nChecked)
                    setFocused('')
                } else {
                    setChecked({ ...checked, "password": "false", "confirm_password": "false" })
                    setFocused('Password not strong enough')
                }
                break;

            case ("confirm_password"):
                if (checked.password === "false") {
                    setFocused('Password not strong enough')
                } else if (newTask?.password !== value) {
                    setFocused('Password does not match')
                }

                if (newTask?.password === value && checked.password === "true") {
                    let nChecked = { ...checked }
                    nChecked["confirm_password"] = "true";
                    setChecked(nChecked)
                    setFocused('')
                } else {
                    setChecked({ ...checked, "confirm_password": "false" })
                }

                break;

            case ("state"):
                // console.log(value)
                if (value !== "") {
                    setChecked({ ...checked, [key]: "true" })
                } else {
                    setChecked({ ...checked, [key]: "false" })
                }
                break;


            case ("city"):
                if (value !== "") {
                    setChecked({ ...checked, [key]: "true" })
                } else {
                    console.log("Not a Valid Name")
                    setChecked({ ...checked, [key]: "false" })
                }
                break;

            case ("country"):
                if (value !== "") {
                    setChecked({ ...checked, [key]: "true" })
                } else {
                    console.log("Not a Valid Name")
                    setChecked({ ...checked, [key]: "false" })
                }
                break;

            case ("email"):
                if (validateUtility.regValidate(key, value)) {
                    setChecked({ ...checked, [key]: "true" })
                } else {
                    setChecked({ ...checked, [key]: "false" })
                }
                break;

            case ("contact_name"):
                if (validateUtility.regValidate(key, value)) {
                    setChecked({ ...checked, [key]: "true" })
                } else {
                    setChecked({ ...checked, [key]: "false" })
                }
                break;


            case ("phone_number"):
                if (validateUtility.regValidate(key, value)) {
                    // console.log("validPhone")
                    setChecked({ ...checked, [key]: "true" })
                } else {
                    setChecked({ ...checked, [key]: "false" })
                }
                break;

            case ("address"):
                if (validateUtility.regValidate(key, value)) {
                    // console.log("validAddress")
                    setChecked({ ...checked, [key]: "true" })
                } else {
                    setChecked({ ...checked, [key]: "false" })
                }
                break;

            case ("zip_code"):
                if (validateUtility.regValidate(key, value)) {
                    setChecked({ ...checked, [key]: "true" })
                } else {
                    setChecked({ ...checked, [key]: "false" })
                }
                break;


            case ("brokerage_name"):
                if (validateUtility.regValidate(key, value)) {
                    setChecked({ ...checked, [key]: "true" })
                } else {
                    setChecked({ ...checked, [key]: "false" })
                }
                break;

            case ("closing_date"):
                if (Object.hasOwn(checked, 'closing_date')) {

                    if (value !== "") {
                        setChecked({ ...checked, 'closing_date': "true" })

                    } else {
                        setChecked({ ...checked, 'closing_date': "false" })

                    }
                }
                break;

            case ("property_type"):
                if (Object.hasOwn(checked, 'property_type')) {
                    // console.log(value)
                    if (value !== "") {
                        setChecked({ ...checked, 'property_type': "true" })

                    } else {
                        setChecked({ ...checked, 'property_type': "false" })

                    }
                }
                break;

        }

        let nTask = { ...newTask }
        nTask[key] = value;
        setNewTask(nTask);

    }




    const handleProfileChangeClick = (event, key) => {
        if (key === 'img_link') {
            profileImgEdit.current.click(event, key)
        } else {
            logoImgEdit.current.click(event, key)
        }

    }




    const handleProfileChange = (event, key) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        console.log(file.size)
        if (!validateUtility.validFileSize(file)) {
            setServerMessage({ "message": `File size must be less than ${inputConstants.MAX_FILE_SIZE / 1000000}mb`, "title": "Error" })
            return;
        }

        reader.onload = () => {
            let data = reader.result;
            let t = file.type;
            let type = t.split("/")[1]
            let subFolder = (key === "logo_link") ? "logos" : "imgs"
            let folder = (roleId) => {
                switch (roleId) {
                    case 2:
                        return 'client/agent/agent_'
                        break;
                    case 3:
                        return 'client/brokerage/brokerages_'
                        break;
                    case 5:
                        return 'client/mortgage_agent/mortgage_agent_'
                        break;
                    case 6:
                        return 'client/insurance_agent/insurance_agent_'
                        break;
                    default:
                        return 'client/admin/admin_'
                }
            }
            let fileObject = { "imgFile": data, "imgType": type, "imgKey": key, "imgSubfolder": `${folder(user?.role_id)}${subFolder}` }
            updateNewTask(key, data)
            if (key === "logo_link") {
                setLogoFileObject(fileObject)
            } else {
                setProfileFileObject(fileObject)
            }
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const signup = () => {
        let pass = true;
        for (let key of Object.keys(checked)) {
            if (checked[key] !== "true") {
                pass = false;
                if (key === "contact_name") {
                    setFocused('Name must be minimum 3 characters long')
                    // } else if (key === 'password') {
                    //     setFocused('Password is not strong enough')
                } else if (key === 'email') {
                    setFocused('Invalid email address')
                }
            }
        }
        if (pass) {
            const token = captchaRef.current.getValue();
            captchaRef.current.reset();
            if (!token) {
                setServerMessage({ "message": "Please verify that you are not a robot", "title": "Error" })
                return
            }

            onSubmit(newTask, token)
        } else {
            if (!triggerX) {
                setTriggerX(true);
            }

        }

    }

    const validateChecked = () => {
        var valid = false;
        valid = (checked?.contact_name === "true" && checked?.phone_number === "true" && checked?.address === "true" && checked?.city === "true" && checked?.state === "true" && checked?.zip_code === "true" && (checked?.brokerage_name === "true" || user.role_id !== 2))
        if (newTask?.concierge) {
            valid = valid && checked?.closing_date === "true" && checked?.property_type === "true"
        }
        return valid;
    }

    const finish = () => {
        console.log(newTask)
        let validated = validateChecked()
        if (!validated) {
            // console.log("not valide checked")
            if (!triggerX) {
                setTriggerX(true);
            }

            return;
        }

        let copy = { ...newTask }
        if (profileFileObject) {
            copy["img_link"] = profileFileObject
        }
        if (logoFileObject) {
            copy["logo_link"] = logoFileObject
        }
        var arrayOfBoards = null;


        if (newTask?.boards && Object.keys(newTask.boards).length) {
            arrayOfBoards = allBoards.current.filter(item => newTask.boards[item.contact_name]).map(item => item);

        }
        copy["boards"] = arrayOfBoards
        // if (Object.keys(selectionMap).length > 1) {
        //     let board = "";
        //     for (let k of Object.keys(selectionMap)) {
        //         board += `{%}${k}`
        //     }
        //     copy["board"] = board;
        // }
        copy.address_selection = addressSelection;

        onFinishSignup(copy);

    }


    const handleSelectionMap = (newValue) => {
        // setSelectionMap(newValue);
        let temp = { ...newTask }
        temp["boards"] = newValue;
        setNewTask(temp)

    }

    const verifyUser = () => {
        onVerifyUser(authCode);
    }

    const sendNewCode = () => {
        setSendButtonClicked(true);
        sqlService.sendNewCode({}).then(data => {
            setAuthCodeStatus("ready");
            setSendButtonClicked(false);
        }).catch(err => {
            console.log(err)
            setSendButtonClicked(false);
        })
    }

    const mapArrayCitiesOption = (arrayOfCities) => {
        let r = []
        for (let city of arrayOfCities) {
            r.push({ "value": city, "label": city });
        }
        // console.log(r);
        return r;
    }

    const captchaRef = useRef(null)
    const [loadedCaptcha, setLoadedCaptcha] = useState(false);

    useEffect(() => {
        // console.log(user);
        // window.initAutocomplete = initAutocomplete;
        // initAutocomplete();
        if (!loadedCaptcha) {
            setTimeout(() => {
                setLoadedCaptcha(true);
            }, 1500);
        }

    })

    const address1FieldRef = useRef();

    const getChecked = () => {
        return checked;
    }

    useEffect(() => {
        checkedRef.current = checked;
    }, [checked])

    useEffect(() => {
        newTaskRef.current = newTask;
    }, [newTask])


    return (
        <div>
            <GoogleAutoComplete address1FieldRef={address1FieldRef} callBack={(postcode, city1, country1, state1, address1) => {
                setChecked({ ...checkedRef.current, "zip_code": postcode !== "" ? "true" : "false", "city": city1 !== "" ? "true" : "false", "country": country1 !== "" ? "true" : "false", "state": state1 !== "" ? "true" : "false", "address": "true" })
                setNewTask({ ...newTaskRef.current, "zip_code": postcode, "city": city1, "country": country1, "state": state1, "address": address1 });
            }} />
            {(user?.verified === 1 && user?.admin_verified === 0 && user?.finished_signup === 1) ?
                <div className="Signup-Border Verify-Border margin-top-5-vh" >
                    <div className="align-items-center display-flex flex-direction-column padding-top-15-px max-width-300-px">
                        <p className="bold grey">Thanks for joining Honeyydo!</p> <n /><n /> <p> Before you continue, your account will be verified by our admin within one business day. We will notify you once verification is complete.</p>
                        <FontAwesomeIcon icon={faCircleUser} className="fa-3x grey margin-top-40-px" />
                    </div>
                </div>
                : null}

            {
                (user?.verified === 1 && (user?.subscribed === 1 || user?.solo_subscribed === 0) && user?.finished_signup === 0) ?
                    <>
                        <div className="Signup-Border padding-20-px  column-gap-30-px">
                            <div style={{ padding: '3% 7%' }} className=" display-flex flex-direction-column">
                                <div className="Settings-Heading">
                                    <h5 className="wrap-none grey">Contact Information </h5>
                                </div>

                                <div className="card-form height-fit-content padding-10-px box-shadow-none margin-top-20-px">

                                    <div className=" box-shadow-none ">
                                        {user?.role_id !== 2 ?
                                            <div className="card-input position-relative">
                                                <label className="card-input__label">
                                                    {user?.role_id === 3 ? "Company Name*" : "Name*"}
                                                </label>
                                                <input
                                                    type="text"

                                                    className={`card-input__input ${(triggerX && checked?.contact_name !== "true") ? "Input-Error-Border" : ""}`}
                                                    autoComplete="off"
                                                    value={newTask?.contact_name}
                                                    onChange={(e) => { updateNewTask("contact_name", e.currentTarget.value); }}
                                                />
                                                {checked?.contact_name === "true" ?

                                                    <span className="Finish-Check"><ImCheckmark /></span>
                                                    : null}
                                                {(triggerX && checked?.contact_name !== "true") ?

                                                    <span className="Finish-Check"><FcCancel /></span>
                                                    : null}
                                            </div>

                                            :

                                            <div className="card-form__row">
                                                <div className="card-form__col position-relative" style={windowSize > 480 ? { flex: '1' } : {}} >
                                                    <label className="card-input__label">
                                                        Name*
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`card-input__input ${(triggerX && checked?.contact_name !== "true") ? "Input-Error-Border" : ""}`}
                                                        autoComplete="off"

                                                        value={newTask.contact_name}
                                                        onChange={(e) => { updateNewTask("contact_name", e.currentTarget.value); }}

                                                    />
                                                    {checked?.contact_name === "true" ?

                                                        <span className="Finish-Check"><ImCheckmark /></span>
                                                        : null}
                                                    {(triggerX && checked?.contact_name !== "true") ?

                                                        <span className="Finish-Check"><FcCancel /></span>
                                                        : null}
                                                </div>
                                                <div className="card-form__col" style={windowSize > 480 ? { flex: '1' } : {}}>
                                                    <div className="card-input">
                                                        <label

                                                            className="card-input__label"
                                                        >
                                                            Title
                                                        </label>
                                                        <select
                                                            style={{ minHeight: '39px' }}
                                                            name="title"
                                                            className="card-input__input "
                                                            value={newTask?.agent_title ?? ""}
                                                            onChange={(e) => { updateNewTask("agent_title", e.currentTarget.value); }}

                                                        >

                                                            <option value={1}>{"Real State Agent"}</option>
                                                            <option value={2}>{"Broker"}</option>


                                                        </select>


                                                    </div>
                                                </div>
                                            </div>}

                                        {user?.role_id === 1 ?

                                            <div className="card-input position-relative">
                                                <label className="card-input__label">
                                                    Phone*
                                                </label>
                                                <input
                                                    type="tel"
                                                    className={`card-input__input ${(triggerX && checked?.phone_number !== "true") ? "Input-Error-Border" : ""}`}
                                                    autoComplete="off"

                                                    value={newTask.phone_number}
                                                    onChange={(e) => { updateNewTask("phone_number", e.currentTarget.value); }}

                                                />
                                                {checked?.phone_number === "true" ?

                                                    <span className="Finish-Check"><ImCheckmark /></span>
                                                    : null}
                                                {(triggerX && checked?.phone_number !== "true") ?

                                                    <span className="Finish-Check"><FcCancel /></span>
                                                    : null}
                                            </div>

                                            :


                                            <>

                                                {user?.role_id === 3 ?

                                                    <div className="card-input position-relative">
                                                        <label className="card-input__label">
                                                            Broker Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="card-input__input"
                                                            autoComplete="off"

                                                            value={newTask.broker_name}
                                                            onChange={(e) => { updateNewTask("broker_name", e.currentTarget.value); }}

                                                        />

                                                    </div>
                                                    : null}

                                                <div className="card-form__row">
                                                    <div className="card-form__col position-relative">
                                                        <label className="card-input__label">
                                                            Phone*
                                                        </label>
                                                        <input
                                                            type="tel"
                                                            maxLength="10"
                                                            className={`card-input__input ${(triggerX && checked?.phone_number !== "true") ? "Input-Error-Border" : ""}`}
                                                            autoComplete="off"

                                                            value={newTask.phone_number}
                                                            onChange={(e) => { updateNewTask("phone_number", e.currentTarget.value); }}

                                                        />
                                                        {checked?.phone_number === "true" ?

                                                            <span className="Finish-Check"><ImCheckmark /></span>
                                                            : null}
                                                        {(triggerX && checked?.phone_number !== "true") ?

                                                            <span className="Finish-Check"><FcCancel /></span>
                                                            : null}
                                                    </div>


                                                    <div className="card-form__col ">
                                                        <div className="card-input position-relative">
                                                            <label className="card-input__label">
                                                                Fax
                                                            </label>
                                                            <input
                                                                type="tel"
                                                                className="card-input__input"
                                                                autoComplete="off"
                                                                maxLength="10"
                                                                value={newTask.fax_number}
                                                                onChange={(e) => { updateNewTask("fax_number", e.currentTarget.value); }}
                                                            />


                                                        </div>

                                                    </div>

                                                </div>


                                            </>}

                                        <div className="card-input position-relative">
                                            <label className="card-input__label">
                                                Address*
                                            </label>
                                            <input
                                                type="text"
                                                className={`card-input__input ${(triggerX && checked?.address !== "true") ? "Input-Error-Border" : ""}`}
                                                autoComplete="off"
                                                ref={address1FieldRef}
                                                // value={newTask.address}
                                                onChange={(e) => { updateNewTask("address", e.currentTarget.value); }}

                                            />
                                            {checked?.address === "true" ?

                                                <span className="Finish-Check"><ImCheckmark /></span>
                                                : null}
                                            {(triggerX && checked?.address !== "true") ?

                                                <span className="Finish-Check"><FcCancel /></span>
                                                : null}
                                        </div>

                                        <div className="card-form__row">

                                            <div className="card-form__col position-relative">
                                                <label

                                                    className="card-input__label"
                                                >
                                                    City*
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`card-input__input ${(triggerX && checked?.city !== "true") ? "Input-Error-Border" : ""}`}

                                                    autoComplete="off"
                                                    value={newTask?.city ?? ""}
                                                    onChange={(e) => { updateNewTask("city", e.currentTarget.value); }}


                                                />
                                                {/* <div className="width-150-px">
                                                    <Select options={inputConstants.US_CITIES} onChange={(e) => { updateNewTask("city", e.value); }} />

                                                </div> */}
                                                {checked?.city === "true" ?

                                                    <span className="Finish-Check"><ImCheckmark /></span>
                                                    : null}

                                                {(triggerX && checked?.city !== "true") ?

                                                    <span className="Finish-Check"><FcCancel /></span>
                                                    : null}
                                            </div>


                                            <div className="card-form__col ">
                                                <div className="card-input position-relative">
                                                    <label

                                                        className="card-input__label"
                                                    >
                                                        Zip/Postal Code*
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`card-input__input ${(triggerX && checked?.zip_code !== "true") ? "Input-Error-Border" : ""}`}
                                                        maxLength="6"
                                                        autoComplete="off"
                                                        value={newTask?.zip_code ?? ""}
                                                        onChange={(e) => { updateNewTask("zip_code", e.currentTarget.value); }}


                                                    />
                                                    {checked?.zip_code === "true" ?

                                                        <span className="Finish-Check"><ImCheckmark /></span>
                                                        : null}
                                                    {(triggerX && checked?.zip_code !== "true") ?

                                                        <span className="Finish-Check"><FcCancel /></span>
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-form__row">

                                            <div className="card-form__col position-relative" style={windowSize > 480 ? { flex: '1' } : {}} >
                                                <div className="card-form__group">
                                                    <label

                                                        className="card-input__label"
                                                    >
                                                        Country*
                                                    </label>
                                                    <select
                                                        name="country"
                                                        className={`card-input__input -select ${(triggerX && checked?.country !== "true") ? "Input-Error-Border" : ""}`}
                                                        value={newTask?.country ?? ""}
                                                        onChange={(e) => { updateNewTask("country", e.currentTarget.value); }}

                                                    >

                                                        <option value="">Select Country</option>
                                                        <option key={"countryCanada"} value={"Canada"}>
                                                            {"Canada"}
                                                        </option>
                                                        <option key={"countryUS"} value={"United States"}>
                                                            {"United States"}
                                                        </option>
                                                    </select>
                                                    {/* <input ref={countryRef} id="countryId" /> */}
                                                    {/* <div className="width-150-px">
                                                        <Select options={inputConstants.US_STATES} onChange={(e) => { updateNewTask("state", e.value); }} />

                                                    </div> */}
                                                </div>
                                                {checked?.country === "true" ?

                                                    <span className="Finish-Check"><ImCheckmark /></span>
                                                    : null}
                                                {(triggerX && checked?.country !== "true") ?

                                                    <span className="Finish-Check"><FcCancel /></span>
                                                    : null}
                                            </div>

                                            <div className="card-form__col position-relative" style={windowSize > 480 ? { flex: '1' } : {}} >
                                                <div className="card-form__group">
                                                    <label

                                                        className="card-input__label"
                                                    >
                                                        State/Province*
                                                    </label>
                                                    <select
                                                        name="state"

                                                        className={`card-input__input -select ${(triggerX && checked?.state !== "true") ? "Input-Error-Border" : ""}`}
                                                        value={newTask?.state ?? ""}
                                                        onChange={(e) => { updateNewTask("state", e.currentTarget.value); }}

                                                    >
                                                        {newTask.country !== "Canada" ?
                                                            inputConstants?.US_STATES.map((val, index) => (
                                                                <option key={index} value={val.value}>
                                                                    {val.label}
                                                                </option>
                                                            )) : null}
                                                        {newTask.country !== "United States" ?
                                                            inputConstants?.CANDADIAN_PROVINCES.map((val, index) => (
                                                                <option key={index} value={val.value}>
                                                                    {val.label}
                                                                </option>
                                                            )) : null}

                                                    </select>
                                                    {/* <div className="width-150-px">
                                                        <Select options={inputConstants.US_STATES} onChange={(e) => { updateNewTask("state", e.value); }} />

                                                    </div> */}
                                                </div>
                                                {checked?.state === "true" ?

                                                    <span className="Finish-Check"><ImCheckmark /></span>
                                                    : null}
                                                {(triggerX && checked?.state !== "true") ?

                                                    <span className="Finish-Check"><FcCancel /></span>
                                                    : null}
                                            </div>






                                        </div>

                                        {user?.role_id === 2 || user?.role_id === 5 || user?.role_id === 6 ?

                                            <div className="card-input position-relative margin-top-20-px">
                                                <label className="card-input__label">
                                                    Brokerage*
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`card-input__input ${(triggerX && checked?.brokerage_name !== "true") ? "Input-Error-Border" : ""}`}
                                                    autoComplete="off"
                                                    value={newTask.brokerage_name}
                                                    onChange={(e) => { updateNewTask("brokerage_name", e.currentTarget.value); }}
                                                    disabled={user?.solo_subscribed === 0}
                                                />
                                                {checked?.brokerage_name === "true" ?

                                                    <span className="Finish-Check"><ImCheckmark /></span>
                                                    : null}
                                                {(triggerX && checked?.brokerage_name !== "true") ?

                                                    <span className="Finish-Check"><FcCancel /></span>
                                                    : null}
                                            </div>

                                            : null}

                                        {user?.role_id === 2 || user?.role_id === 5 || user?.role_id === 6 || user?.role_id === 3 ?
                                            <div className="card-input">
                                                <label className="card-input__label">
                                                    Website
                                                </label>
                                                <input
                                                    type="url"

                                                    className="card-input__input"
                                                    autoComplete="off"
                                                    value={newTask?.website}
                                                    onChange={(e) => { updateNewTask("website", e.currentTarget.value); }}
                                                />
                                            </div>

                                            : null}


                                        {user?.role_id === 1 ?
                                            <div className="card-form__row margin-top-20-px">

                                                <div className="card-form__col position-relative">
                                                    <label

                                                        className="card-input__label"
                                                    >
                                                        {`Spouse Full Name (optional)`}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`card-input__input`}
                                                        autoComplete="off"
                                                        value={newTask?.spouse_contact_name ?? ""}
                                                        onChange={(e) => { updateNewTask("spouse_contact_name", e.currentTarget.value); }}


                                                    />
                                              
                                                </div>


                                                <div className="card-form__col ">
                                                    <div className="card-input position-relative">
                                                        <label

                                                            className="card-input__label"
                                                        >
                                                           {`Spouse Email (optional)`}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`card-input__input`}
                                                            autoComplete="off"
                                                            value={newTask?.spouse_email ?? ""}
                                                            onChange={(e) => { updateNewTask("spouse_email", e.currentTarget.value); }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            : null}




                                    </div>


                                </div>
                                {user?.role_id === 1 ?
                                    <div style={{ padding: '3% 0' }} className=" display-flex flex-direction-column">
                                        <div className="Settings-Heading">
                                            <h5 className="wrap-none grey">Property Information </h5>
                                        </div>
                                        <span style={{ padding: '4% 0 0 0' }}>Tell us a bit about your home to help us customize your experience</span>

                                        <div className="card-form height-fit-content padding-10-px box-shadow-none margin-top-20-px">

                                            <div className=" box-shadow-none ">
                                                <div className="card-form__row margin-bottom-20-px">

                                                    <div className="card-form__col position-relative" style={windowSize > 480 ? { flex: '1' } : {}} >
                                                        <div className="card-form__group">
                                                            <label

                                                                className="card-input__label"
                                                            >
                                                                Bedrooms
                                                            </label>
                                                            <select
                                                                name="bedrooms"

                                                                className={`card-input__input -select `}
                                                                value={newTask?.bedrooms ?? ""}
                                                                onChange={(e) => { updateNewTask("bedrooms", e.currentTarget.value); }}

                                                            >
                                                                <option value=''>Select</option>
                                                                {[1, 2, 3, 4, 5, 6].map((num) =>
                                                                    <option value={num}>{num}</option>)}
                                                            </select>

                                                        </div>
                                                    </div>
                                                    <div className="card-form__col position-relative" style={windowSize > 480 ? { flex: '1' } : {}} >
                                                        <div className="card-form__group">
                                                            <label

                                                                className="card-input__label"
                                                            >
                                                                Bathrooms
                                                            </label>
                                                            <select
                                                                name="bathrooms"

                                                                className={`card-input__input -select`}
                                                                value={newTask?.bathrooms ?? ""}
                                                                onChange={(e) => { updateNewTask("bathrooms", e.currentTarget.value); }}

                                                            >
                                                                <option value=''>Select</option>
                                                                {[1, 2, 3, 4, 5, 6].map((num) =>
                                                                    <option value={num}>{num}</option>)}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-form__row">

                                                    <div className="card-form__col position-relative" style={windowSize > 480 ? { flex: '1' } : {}} >
                                                        <div className="card-form__group">
                                                            <label

                                                                className="card-input__label"
                                                            >
                                                                Squarefeet
                                                            </label>
                                                            <input
                                                                name="squarefeet"

                                                                className={`card-input__input  `}
                                                                value={newTask?.squarefeet ?? ""}
                                                                onChange={(e) => { updateNewTask("squarefeet", e.currentTarget.value); }}

                                                            >

                                                            </input>

                                                        </div>
                                                    </div>
                                                    <div className="card-form__col position-relative" style={windowSize > 480 ? { flex: '1' } : {}} >
                                                        <div className="card-form__group">
                                                            <label

                                                                className="card-input__label"
                                                            >
                                                                Year built
                                                            </label>
                                                            <input
                                                                name="year_built"

                                                                className={`card-input__input`}
                                                                value={newTask?.year_built ?? ""}
                                                                onChange={(e) => { updateNewTask("year_built", e.currentTarget.value); }}

                                                            >

                                                            </input>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>


                                        </div>
                                        <div className="display-flex column-gap-10-px margin-auto-lr margin-top-10-px" onChange={onRadioChange}>
                                            <span className="display-flex column-gap-10-px"><input type="radio" name="address" value="same" checked={addressSelection === "same"} />Same address as above</span>
                                            <span className="display-flex column-gap-10-px"><input type="radio" name="address" value="different" />Use different address</span>
                                        </div>

                                        {addressSelection === 'different' ?
                                            <div className="card-form height-fit-content padding-10-px box-shadow-none margin-top-20-px">

                                                <div className=" box-shadow-none ">
                                                    <div className="card-input position-relative">
                                                        <label className="card-input__label">
                                                            Address
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`card-input__input `}
                                                            autoComplete="off"

                                                            // value={newTask.address}
                                                            onChange={(e) => { updateNewTask("prop_address", e.currentTarget.value); }}

                                                        />

                                                    </div>

                                                    <div className="card-form__row">

                                                        <div className="card-form__col position-relative">
                                                            <label

                                                                className="card-input__label"
                                                            >
                                                                City
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className={`card-input__input `}

                                                                autoComplete="off"
                                                                value={newTask?.prop_city ?? ""}
                                                                onChange={(e) => { updateNewTask("prop_city", e.currentTarget.value); }}


                                                            />


                                                        </div>


                                                        <div className="card-form__col ">
                                                            <div className="card-input position-relative">
                                                                <label

                                                                    className="card-input__label"
                                                                >
                                                                    Zip/Postal Code
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className={`card-input__input`}
                                                                    maxLength="6"
                                                                    autoComplete="off"
                                                                    value={newTask?.prop_zip_code ?? ""}
                                                                    onChange={(e) => { updateNewTask("prop_zip_code", e.currentTarget.value); }}


                                                                />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-form__row">

                                                        <div className="card-form__col position-relative" style={windowSize > 480 ? { flex: '1' } : {}} >
                                                            <div className="card-form__group">
                                                                <label

                                                                    className="card-input__label"
                                                                >
                                                                    Country
                                                                </label>
                                                                <select
                                                                    name="country"
                                                                    className={`card-input__input -select `}
                                                                    value={newTask?.prop_country ?? ""}
                                                                    onChange={(e) => { updateNewTask("prop_country", e.currentTarget.value); }}

                                                                >

                                                                    <option value="">Select Country</option>
                                                                    <option key={"countryCanada"} value={"Canada"}>
                                                                        {"Canada"}
                                                                    </option>
                                                                    <option key={"countryUS"} value={"United States"}>
                                                                        {"United States"}
                                                                    </option>
                                                                </select>

                                                            </div>

                                                        </div>

                                                        <div className="card-form__col position-relative" style={windowSize > 480 ? { flex: '1' } : {}} >
                                                            <div className="card-form__group">
                                                                <label

                                                                    className="card-input__label"
                                                                >
                                                                    State/Province
                                                                </label>
                                                                <select
                                                                    name="state"

                                                                    className={`card-input__input -select`}
                                                                    value={newTask?.prop_state ?? ""}
                                                                    onChange={(e) => { updateNewTask("prop_state", e.currentTarget.value); }}

                                                                >
                                                                    {newTask.prop_country !== "Canada" ?
                                                                        inputConstants?.US_STATES.map((val, index) => (
                                                                            <option key={index} value={val.value}>
                                                                                {val.label}
                                                                            </option>
                                                                        )) : null}
                                                                    {newTask.prop_country !== "United States" ?
                                                                        inputConstants?.CANDADIAN_PROVINCES.map((val, index) => (
                                                                            <option key={index} value={val.value}>
                                                                                {val.label}
                                                                            </option>
                                                                        )) : null}

                                                                </select>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null}
                                    </div>

                                    : null}
                                {user?.role_id === 1 && !user?.onesource_token && newTask?.country === 'United States' ?
                                    <div className="card-form height-fit-content padding-10-px box-shadow-none margin-top-20-px">

                                        <div className=" box-shadow-none ">


                                            <div style={{ marginBottom: '20px' }} className="display-flex align-items-center justify-content-center">
                                                <HoverHint
                                                    hint={"Fill out this section if you would like to take advantage of our complimentary move-in concierge service. Select for more information."}

                                                />
                                                <span >Set up concierge service? </span>
                                                <input className="margin-left-15-px" type="checkbox" name={"concierge"} checked={newTask.concierge || false} onChange={(e) => {

                                                    if (e.target.checked) {
                                                        updateNewTask("concierge", e.target.checked);
                                                        setChecked({ ...checked, closing_date: "false", property_type: 'false' })

                                                    } else {
                                                        var nChecked = { ...checked }
                                                        delete nChecked.closing_date;
                                                        delete nChecked.property_type;
                                                        setChecked(nChecked)
                                                        setNewTask({ ...newTask, concierge: e.target.checked, closing_date: '', property_type: '' })



                                                    }
                                                    console.log(checked)
                                                }}
                                                ></input>
                                            </div>

                                            {newTask.concierge ?
                                                <>
                                                    <div className="card-form__row">
                                                        <div className="card-form__col position-relative" style={windowSize > 480 ? { flex: '1' } : {}} >

                                                            <label

                                                                className="card-input__label"
                                                            >
                                                                Closing Date*
                                                            </label>
                                                            <DatePicker className={`card-input__input ${(triggerX && checked?.closing_date !== "true") ? "Input-Error-Border" : ""}`}

                                                                onFocus={e => e.target.blur()}
                                                                selected={newTask?.closing_date}
                                                                onChange={(value) => { updateNewTask('closing_date', value) }

                                                                }

                                                            />
                                                            {checked?.closing_date === "true" ?

                                                                <span className="Finish-Check"><ImCheckmark /></span>
                                                                : null}
                                                            {(triggerX && checked?.closing_date !== "true") ?

                                                                <span className="Finish-Check"><FcCancel /></span>
                                                                : null}
                                                        </div>
                                                        <div className="card-form__col position-relative" style={windowSize > 480 ? { flex: '1' } : {}} >

                                                            <label className="card-input__label">Property Type*</label>

                                                            <select
                                                                className={`card-input__input -select ${(triggerX && checked?.property_type !== "true") ? "Input-Error-Border" : ""}`}
                                                                // style={{ width: '217px' }}
                                                                onChange={(e) => updateNewTask('property_type', e.target.value)}
                                                            >
                                                                <option value=''>Select</option>
                                                                <option value='Rent'>Rental</option>
                                                                <option value='Own'>Ownership</option>
                                                            </select>
                                                            {checked?.property_type === "true" ?

                                                                <span className="Finish-Check"><ImCheckmark /></span>
                                                                : null}
                                                            {(triggerX && checked?.property_type !== "true") ?

                                                                <span className="Finish-Check"><FcCancel /></span>
                                                                : null}
                                                        </div>

                                                    </div>
                                                    <div style={{ marginTop: '30px' }}><p>Moving into your new home soon? Make your transition seamless
                                                        by registering with Honeyydo Connects, our complimentary all-in-one utility setup service.
                                                        Simply provide the above information and we will reach out to confirm which services you need,
                                                        then help you get everything set up by tapping into over 17 years of collected utility data across
                                                        35,000+ U.S. zip codes. Not only does this save you the time and frustration of setting up
                                                        services yourself, we also save you money with exclusive discounts on select services.</p>
                                                        <p className="margin-top-20-px">If you have any questions about Honeyydo Connects, please call 1-888-960-2560.
                                                             Please note that this service is only available for our American users.</p></div>
                                                </> : null}
                                        </div>
                                    </div> : null}

                                {user?.role_id === 2 || user?.role_id === 3 ?
                                    <>

                                        <div className="Settings-Heading">
                                            <h5 className="wrap-none grey">Board(s) </h5>
                                        </div>

                                        <MultipleSelection theme={'Settings'} selectionMap={newTask.boards ?? {}} handleSelectionMap={handleSelectionMap} selectionOptionsArray={selectionOptionsArray ?? [""]} />

                                    </>

                                    : null}

                                {/* {user?.role_id === 2 ?

                                <>

                                    <div className="Settings-Heading">
                                        <h5 className="wrap-none grey">Social Media Accounts </h5>
                                    </div>

                                </>
                                : null} */}

                                {user?.role_id === 2 || user?.role_id === 5 || user?.role_id === 6 || user?.role_id === 3 ?
                                    <>

                                        <div className="Settings-Heading">
                                            <h5 className="wrap-none grey">Profile Images </h5>
                                        </div>

                                        <div style={{ maxWidth: '570px' }} className='settings-images'>
                                            <div className="user-images display-flex ">

                                                {user?.role_id === 2 || user?.role_id === 5 || user?.role_id === 6 ?
                                                    <div className='logo'>
                                                        <span className='card-input__label'>Picture</span>
                                                        <label className='imglabels' htmlFor="imageUpload">
                                                            <img className='imglabels' src={newTask?.img_link || Placeholder2} alt="Placeholder" />
                                                        </label>
                                                        <input className='display-none' ref={profileImgEdit} type="file" id="imageUpload" accept="image/*" onChange={(e) => handleProfileChange(e, "img_link")} />
                                                        <div className="Edit-Label" onClick={(e) => { handleProfileChangeClick(e, "img_link") }}><FontAwesomeIcon icon={faPen} className="fa-sm grey" /><span>Add</span></div>
                                                    </div>
                                                    : null}


                                                <div className='logo'>
                                                    <span className='card-input__label'>Logo</span>
                                                    <label className='imglabels' htmlFor="logoUpload">
                                                        <img className='imglabels' src={newTask?.logo_link || Placeholder2} alt="Placeholder" />
                                                    </label>
                                                    <input className='display-none' ref={logoImgEdit} type="file" id="logoUpload" accept="image/*" onChange={(e) => handleProfileChange(e, "logo_link")} />
                                                    <div className="Edit-Label" onClick={(e) => { handleProfileChangeClick(e, "logo_link") }}><FontAwesomeIcon icon={faPen} className="fa-sm grey" /><span>Add</span></div>
                                                </div>
                                            </div>






                                        </div>


                                    </>

                                    : null
                                }


                                {finishSignupButtonClicked ?
                                    <Button style={{ margin: '40px auto 20px auto', width: '200px' }} className="submit" variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className=""> Loading...</span>
                                    </Button> :
                                    <button style={{ margin: '40px auto 20px auto', width: '200px' }} className="submit" type="submit" onClick={() => { finish() }}>Finish Sign Up</button>
                                }

                            </div>
                        </div>
                    </>
                    : null
            }

            {
                user?.verified === 0 && authCodeStatus === "ready" ?
                    <div className="Signup-Border Verify-Border" >
                        <div className="align-items-center display-flex flex-direction-column margin-top-40-px column-gap-10-px row-gap-10-px">
                            <span>Verify the code sent to your email: <span className="bold">{user?.email}</span></span>
                            <input type="tel" className="Input-Style" value={authCode} onChange={(e) => setAuthCode(e.currentTarget.value)} />
                            {verifyButtonClicked ?
                                <Button className="submit" variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span className=""> Loading...</span>
                                </Button> :
                                <button className="submit" onClick={() => { verifyUser() }}>Verify</button>
                            }

                        </div>
                    </div>
                    : null
            }

            {
                user?.verified === 0 && authCodeStatus === "expired" ?
                    <div className="Signup-Border Verify-Border" >
                        <div className="align-items-center display-flex flex-direction-column margin-top-40-px">
                            <span>The code sent to your email is expired please click "Send" to send new code to your email and then verify</span>
                            {sendButtonClicked ?
                                <Button className="submit" variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span className=""> Loading...</span>
                                </Button> :
                                <button className="submit" onClick={() => { sendNewCode() }}>Send</button>
                            }

                        </div>
                    </div>
                    : null
            }

            {
                !user && authCodeStatus === "null" ?
                    <div className="Signup-Border margin-bottom-50-px align-items-center display-flex flex-direction-column  ">

                        <div className="Signup-Container padding-20-px  column-gap-30-px ">
                            <div className="margin-bottom-20-px  display-flex flex-wrap flex-direction-column justify-content-center  column-gap-30-px row-gap-30-px">


                                <div className=" display-flex flex-direction-row margin-bottom-10-px flex-wrap position-relative">

                                    <span className="background-linear-gradient Signup-Label padding-5-px white ">{parseInt(role_id) === 3 ? "Company Name*" : "Name*"}</span>

                                    <input onFocus={() => { setFocused(``) }} className={`Signup-Input-Style flex-grow-1 ${(triggerX && checked?.contact_name !== "true") ? "Input-Error-Border" : ""}`} type="text" value={newTask?.contact_name ?? ""} onChange={(e) => { updateNewTask("contact_name", e.currentTarget.value) }} />
                                    {checked?.contact_name === "true" ?
                                        <span className="Checkmark"><ImCheckmark /></span>
                                        : null}

                                    {(triggerX && checked?.contact_name !== "true") ?

                                        <span className="Checkmark"><FcCancel /></span>
                                        : null}

                                </div>

                                <div className=" display-flex flex-direction-row margin-bottom-10-px flex-wrap position-relative">

                                    <span className="background-linear-gradient Signup-Label padding-5-px   white">Email*</span>
                                    <input onFocus={() => { setFocused(``) }} className={`Signup-Input-Style flex-grow-1 ${(triggerX && checked?.email !== "true") ? "Input-Error-Border" : ""}`} type="email" value={newTask?.email ?? ""} onChange={(e) => { updateNewTask("email", e.currentTarget.value); }} />
                                    {checked?.email === "true" ?
                                        <span className="Checkmark"><ImCheckmark /></span>
                                        : null}
                                    {(triggerX && checked?.email !== "true") ?

                                        <span className="Checkmark"><FcCancel /></span>
                                        : null}
                                </div>

                                <div className=" display-flex flex-direction-row margin-bottom-10-px flex-wrap position-relative">

                                    <span className="background-linear-gradient Signup-Label padding-5-px   white">Phone Number*</span>
                                    <input onFocus={() => { setFocused(``) }} className={`Signup-Input-Style flex-grow-1 ${(triggerX && checked?.phone_number !== "true") ? "Input-Error-Border" : ""}`} type="phone_number" value={newTask?.phone_number ?? ""} onChange={(e) => { updateNewTask("phone_number", e.currentTarget.value); }} />
                                    {checked?.phone_number === "true" ?
                                        <span className="Checkmark"><ImCheckmark /></span>
                                        : null}
                                    {(triggerX && checked?.phone_number !== "true") ?

                                        <span className="Checkmark"><FcCancel /></span>
                                        : null}
                                </div>


                                <div className=" display-flex flex-direction-row margin-bottom-10-px flex-wrap position-relative">
                                    <span className="background-linear-gradient Signup-Label padding-5-px  white">Password*</span>
                                    <input onFocus={() => { setFocused(`*Minimum ${inputConstants.PASSWORD_MINIMUM_LENGTH} characters, including a special character and number `) }} className={`Signup-Input-Style flex-grow-1 ${(triggerX && checked?.password !== "true") ? "Input-Error-Border" : ""}`} type="password" value={newTask?.password ?? ""} onChange={(e) => { updateNewTask("password", e.currentTarget.value); }} pattern="/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/" />

                                    {checked?.password === "true" ?
                                        <span className="Checkmark"><ImCheckmark /></span>
                                        : null}
                                    {(triggerX && checked?.password !== "true") ?

                                        <span className="Checkmark"><FcCancel /></span>
                                        : null}
                                </div>


                                <div className=" display-flex flex-direction-row margin-bottom-10-px flex-wrap position-relative">
                                    <span className="background-linear-gradient Signup-Label padding-5-px white">Confirm Password*</span>
                                    <input onFocus={() => { }} className={`Signup-Input-Style flex-grow-1 ${(triggerX && checked?.confirm_password !== "true") ? "Input-Error-Border" : ""}`} type="password" value={newTask?.confirm_password ?? ""} onChange={(e) => { updateNewTask("confirm_password", e.currentTarget.value); }} pattern="/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/" />

                                    {checked?.confirm_password === "true" ?

                                        <span className="Checkmark"><ImCheckmark /></span>
                                        : null}
                                    {(triggerX && checked?.confirm_password !== "true") ?

                                        <span className="Checkmark"><FcCancel /></span>
                                        : null}
                                </div>

                                <div className='Hint-Div' >

                                    <span className="Signup-Hint padding-x-10-px align-items-center display-flex justify-content-center grey">{focused}</span>

                                </div>

                            </div>

                        </div>

                        {loadedCaptcha &&
                            <ReCAPTCHA
                                style={{ display: "inline-block" }}
                                theme="light"
                                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY_V2}
                                ref={captchaRef}
                                onChange={null}
                                asyncScriptOnLoad={null}
                            />
                        }
                        <button style={{ width: windowSize > 499 ? '350px' : '200px', height: '40px', padding: '0' }} className="submit margin-bottom-25-px " type="submit" onClick={() => { signup() }}>Sign Up</button>

                        <div className="display-flex position-relative" style={{ height: '20px', marginBottom: '20px' }}>
                            <div>
                                <div className="separator-div" />
                            </div>
                            <span className="Or-Span" >or</span>
                            <div className="separator-div"></div>
                        </div>


                        {children}


                    </div>
                    : null
            }

        </div >

    );
}


export default function Signup({ setUser }) {
    const user = useContext(UserContext);

    const { role_id } = useParams();
    const { link } = useParams();
    const { parent } = useParams();
    const { senderId } = useParams();
    const { senderRoleTypeId } = useParams();


    const [serverMessage, setServerMessage] = useState(null);
    const [requested, setRequested] = useState(false);

    const [currentUser, setCurrentUser] = useState(user);
    const [verifyButtonClicked, setVerifyButtonClicked] = useState(false);


    function setGoogleResponse(data) {
        //cookie expires in one month
        //secures means only accessible using https
        //you can also add domain which is the domain of the cookie

        // export interface CookieSetOptions {
        //     path?: string;
        //     expires?: Date;
        //     maxAge?: number;
        //     domain?: string;
        //     secure?: boolean;
        //     httpOnly?: boolean;
        //     sameSite?: boolean | 'none' | 'lax' | 'strict';
        //     encode?: (value: string) => string;
        // }
        // export interface CookieChangeOptions {
        //     name: string;
        //     value?: any;
        //     options?: CookieSetOptions;
        // }

        // document.cookie = `honeyydo_user_cookie=${JSON.stringify({ data })}; expires=${new Date(Date.now() + (30 * 86400000))}; path=/; sameSite=strict; secure`;
        document.cookie = `honeyydo_user_cookie=${JSON.stringify({ data })}; expires=${new Date(Date.now() + (30 * 86400000))}; path=/; sameSite=strict; secure`;
        allowRender.current = false;
        window.location.reload();
    }

    const setError = (err) => {
        console.log(serverResponseMessagesConstants[err?.message ?? "SOMETHING_WENT_WRONG"]);
        setServerMessage(serverResponseMessagesConstants[err?.message ?? "SOMETHING_WENT_WRONG"]);
    }

    const { handleGoogle, loading, error } = useFetch(
        setError, `${serverConstants.baseURL}/auth/signupUsingGoogle`, setGoogleResponse, parseInt(role_id), parent, link, senderId, senderRoleTypeId
    );

    const loaded = useRef(false);
    const loaded1 = useRef(false);

    const [verified, setVerified] = useState(user?.verified ?? false);
    const [authCodeStatus, setAuthCodeStatus] = useState("");

    const [finishSignupButtonClicked, setFinishSignupButtonClicked] = useState(false);

    const Navigate = useNavigate()

    function onSubmit(input, token) {
        setRequested(true);
        // window.grecaptcha.ready(function () {
        //     window.grecaptcha.execute(process.env.REACT_APP_CAPTCHA_SITE_KEY, { action: 'submit' }).then(function (token) {
        //         // Add your logic to submit to your backend server here.

        //     });
        // });

        sqlService.signup({ "input": { "user": input, "role_id": parseInt(role_id), "parent": parent, "link": link, "senderId": senderId, "senderRoleTypeId": senderRoleTypeId }, "captchaTokenV2": token }).then(data => {
            // the data contains the score for captcha if the score is less than 0.5 then we ask for email verification
            // also contains the user informations

            // This is for the case when the user is an agent and we need to put it in an array since the data is an array
            var userR = data;
            // if(Array.isArray(data) && data.length > 0){
            //     userR = data[0]
            // }
            // console.log("singup user ", userR)

            loaded.current = false;
            setRequested(false);
            setGoogleResponse(userR)
        }).catch(err => {
            console.log(err);
            loaded.current = false;
            setRequested(false);
        })
    }

    const getUser = () => {
        return user;
    }

    async function onFinishSignup(input) {
        setFinishSignupButtonClicked(true)
        await locationService.getGeoInfo().then(data => {
            // console.log(data);
            input["ip"] = data?.ip ?? "";
            input["utc_offset"] = data?.utc_offset ?? "";
            input["lng"] = data?.longitude ?? "";
            input["lat"] = data?.latitude ?? "";
        }).catch(err => {
            console.log(err);
        })

        sqlService.finishSignup({ "input": input }).then(data => {
            // the data contains the score for captcha if the score is less than 0.5 then we ask for email verification
            // also contains the user informations
            // alert(data.user)
            let temp = { "role_id": parseInt(role_id) }
            for (let [key, value] of Object.entries(data)) {
                temp[key] = value;
            }
            loaded.current = false;
            temp["token"] = user.token;
            setUser(temp);
            setGoogleResponse(temp);
        }).catch(err => {
            loaded.current = false;
            console.log(err)
        })

    }

    const onVerifyUser = (authCode) => {
        // console.log(authCode, "sdfsd")
        setVerifyButtonClicked(true);
        sqlService.verifyUser({ input: { "authCode": authCode } }).then(data => {
            console.log(data)
            if (data?.data === "expired") {
                setAuthCodeStatus("expired");
            } else {
                loaded.current = false;
                console.log("token", user.token);
                data["token"] = user.token;
                setUser(data);
                setVerified(true);
                setAuthCodeStatus("null");
                setGoogleResponse(data)
            }
            setVerifyButtonClicked(false);

        }).catch(err => {
            console.log(err)
            loaded.current = false;
            setVerifyButtonClicked(false);

        })
    }

    const userRef = useRef(user);

    useEffect(() => {

        if (!loaded1.current || (user && userRef.current && JSON.stringify(userRef.current) !== JSON.stringify(user))) {
            userRef.current = user;
            if (user !== null && user) {
                let userRoute = userUtility.getUserRoute(user)
                console.log(userRoute)
                let currentPath = window.location.pathname;
                if (!currentPath.endsWith(userRoute)) {
                    Navigate(userRoute);
                }
                allowRender.current = true;

            }

            if (user?.token && user?.verified === 0) {
                sqlService.getAuthCodeStatus({}).then(data => {
                    console.log(data)
                    if (data?.status === "ready") {
                        setAuthCodeStatus("ready");
                    } else if (data?.status === "expired") {
                        setAuthCodeStatus("expired");
                    } else {
                        console.log("code is null")
                        setAuthCodeStatus("null");
                    }
                }).catch(err => {
                    console.log(err)
                    setAuthCodeStatus("null");
                })
            } else {
                setAuthCodeStatus("null");
            }
            loaded1.current = true;
        }
    }, [user])

    const allowRender = useRef(false)



    // useEffect(() => {

    //     setCurrentUser(user);

    //     if (!loaded.current) {
    //         const script = document.createElement("script");
    //         script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_SITE_KEY}`
    //         document.body.appendChild(script)

    //         loaded.current = true;

    //     }


    // }, [user])


    const onConfirmServerMessage = () => {
        setServerMessage(null);
    }



    return (
        <>

            {(allowRender.current || !user) ?
                <>
                    <div className="Signup-Spacing-Div" />
                    <div className="Signup-Background" />

                    <SignupDiv verifyButtonClicked={verifyButtonClicked} onVerifyUser={onVerifyUser} finishSignupButtonClicked={finishSignupButtonClicked} setUser={setUser} setAuthCodeStatus={setAuthCodeStatus} setServerMessage={setServerMessage} authCodeStatus={authCodeStatus} verified={verified} setVerified={setVerified} onSubmit={onSubmit} onFinishSignup={onFinishSignup} >

                        <GoogleDiv handleGoogle={handleGoogle} verified={verified} error={error} loading={loading} />
                    </SignupDiv>

                    <PopupMessage onConfirm={onConfirmServerMessage} message={serverMessage?.message} title={serverMessage?.title} show={serverMessage !== null} />
                    {requested ? <div className="display-flex align-items-center justify-content-center"><Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner></div> : null}


                </>
                : <div className="display-flex justify-content-center align-items-center height-90-vh"><ThreeCircles
                    height={Math.round(window.innerWidth / 4)}
                    width={Math.round(window.innerWidth / 4)}
                    color="#ed8c00"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="three-circles-rotating"
                    outerCircleColor=""
                    innerCircleColor=""
                    middleCircleColor=""
                /></div>}

        </>



    )
}
