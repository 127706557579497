import React, { useState, useEffect, useRef, useContext } from "react";
import { UserContext } from "../../../App";
import "./notepad.component.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faChevronDown, faChevronUp, faEdit, faNoteSticky, faPlus, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import sqlService from "../../../services/sqlService";
import sortUtility from "../../../functions/sortUtility";

export default function NotepadMobile() {

    const user = useContext(UserContext);
    const [noteDialog, setNoteDialog] = useState(false)
    const [noteText, setNoteText] = useState('')
    const [titleText, setTitleText] = useState('')
    const [notes, setNotes] = useState([])
    const notesLoaded = useRef(false)
    const [refresh, setRefresh] = useState(false)
    const [notesShow, setNotesShow] = useState(true)
    const MAXLENGTH = 1000;
    const [scrollTop, setScrollTop] = useState(0)
    const [scrollBottom, setScrollBottom] = useState(0)
    const [noteId, setNoteId] = useState(null)
    const [element, setElement] = useState(null)
    const [deletePopup, setDeletePopup] = useState(null)

    useEffect(() => {
        let notes = document.getElementById('Notes-Mobile');
        setElement(notes)

        setScrollBottom(notes?.scrollHeight - notes?.clientHeight - notes?.scrollTop)

    }, [notes, notesShow])

    useEffect(() => {

        if (element) {
            element.addEventListener('scroll', onNoteScroll)

        }
        // return () => {
        //     notes.removeEventListener('scroll', onNoteScroll);
        //   };
    }, [notesShow, element])

    function onNoteScroll() {

        setScrollTop(element.scrollTop)
        setScrollBottom(element.scrollHeight - element.clientHeight - element.scrollTop)

    }



    function updateNote(val) {
        if (val.length <= MAXLENGTH) {
            setNoteText(val);

        }

    }

    function saveNote() {
        sqlService.addNote({ user: user, input: { id: noteId, title: titleText, note: noteText } }).then((d) => {
            resetNote()
            notesLoaded.current = false;
            setRefresh(!refresh)
            setNoteDialog(false)

            setScrollBottom(element.scrollHeight - element.clientHeight - element.scrollTop)
            // console.log(notes.scrollHeight , notes.clientHeight , notes.scrollTop)

        }).catch((err) => {

        })



    }

    function deleteNote() {
        sqlService.deleteNote({ user: user, input: noteId }).then(() => {
            resetNote()
            notesLoaded.current = false;
            setRefresh(!refresh)
            setNoteDialog(false)
            setDeletePopup(false)
        }).catch((err) => {

        })
    }

    function resetNote() {
        setTitleText('');
        setNoteText('');
        setNoteId(null)
 

    }

    useEffect(() => {
        if (!notesLoaded.current) {
            sqlService.getNotesForUser({ user: user }).then((data) => {
                setNotes(sortUtility.sort('creation_date', data.data, false))
                notesLoaded.current = true;

            }).catch((err) => {

            })
        }
    })

    return (
        <div style={{width: '100%', margin: '0'}} className="Notepad-Mobile position-relative text-align-center">
            <h5 style={{ padding: '20px 0 10px 0' }} className="grey text-align-center">Notes</h5>
            {noteDialog ?
                <div style={{ width: '100%', height: '100%' }} className="popup text-align-left">
                    {deletePopup ?

                        <div style={{ width: '250px', top: '60%' }} className="popup box-shadow-2">

                            <span style={{ padding: '20px 0' }}>Delete note?</span>
                            <div className="display-flex column-gap-10-px margin-top-15-px">
                                <button className="Cancel-Button" onClick={() => setDeletePopup(false)}>Cancel</button>
                                <button className="Cancel-Button red" onClick={() => deleteNote()}>Delete</button>
                            </div>
                        </div>


                        : null}
                    <label className="card-input__label">Title</label>
                    <input className="Input-Style" style={{ width: '100%' }} value={titleText} onChange={(e) => setTitleText(e.target.value)}></input>
                    <label className="card-input__label margin-top-15-px">Note</label>
                    <textarea style={{ minHeight: '200px', width: '100%' }} value={noteText} onChange={(e) => updateNote(e.target.value)} className="Input-Style"></textarea>
                    <div style={{ justifyContent: 'right' }} className="width-full-pt display-flex ">
                        <span className="count">{noteText?.length ?? 0}/{MAXLENGTH}</span>
                    </div>
                    <div className="display-flex column-gap-10-px margin-top-15-px">
                        <button className="Cancel-Button" onClick={() => { setNoteDialog(false); resetNote(); setDeletePopup(false) }}>Close</button>
                        {noteId ?
                            <button className="Cancel-Button" onClick={() => setDeletePopup(true)}><FontAwesomeIcon icon={faTrash} /></button>
                            : null}
                        <button className="popup-submit-button" onClick={() => saveNote()} >
                            Save
                            <FontAwesomeIcon className="fa-sm margin-left-5-px" icon={faSave} />
                        </button>
                    </div>
                </div> : null}
            {scrollTop > 0 ?
                <div onClick={() => {
                    let element = document.getElementById("Notes-Mobile")
                    element.scrollBy(0, -200);
                }
                } className="color-med-grey cursor-pointer fa-lg margin-bottom-15-px"><FontAwesomeIcon icon={faChevronUp} /></div>
                : <div style={{ height: '35px' }}></div>}
            <div className="Notes-Mobile hide-scroll-bar " id="Notes-Mobile">
                {notes.length ?

                    notes.map((note) => (
                        <div style={{ textAlign: 'left', padding: '10px', rowGap: '5px', width: '95%' }} className="margin-auto-lr cursor-pointer border-bottom-solid display-flex flex-direction-column " onClick={() => {
                            setTitleText(note.title);
                            setNoteText(note.note)
                            setNoteId(note.id)
                            setNoteDialog(true)
                        }}>

                            <span >{new Date(note.creation_date).toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric'  })}</span>

                            <span >{note.title !== '' ? note.title : note.note?.split(' ')?.splice(0, 10)?.join(' ') + " ..."} </span>
                        </div>
                    )) : <div className="color-med-grey bold" style={{ padding: '100px 10px 0 10px ', paddingTop: '100px' }}>You haven't added any notes yet. Click the plus sign to get started.</div>}
            </div>
            {scrollBottom > 1 ?
                <div onClick={() => {

                    element.scrollBy(0, 200);
                }
                } className="color-med-grey cursor-pointer fa-lg margin-top-15-px "><FontAwesomeIcon icon={faChevronDown} /></div>
                : <div style={{ height: '35px' }}></div>}

            <button style={{ bottom: '15px', right: '15px', }} className="position-absolute Note-Add-Button" onClick={() => { resetNote(); setNoteDialog(true) }}><FontAwesomeIcon icon={faPlus} className="fa-lg" /></button>

        </div>
    )
}