import React, { useState} from "react";
import '../../../../node_modules/react-vis/dist/style.css';
import { RadialChart, DiscreteColorLegend, Hint } from 'react-vis';



export default function DonutChart({ donutColors, filled, unfilled, filledText, unfilledText, filledHover, unfilledHover }) {
    const [hoveredCell, setHoveredCell] = useState(null)

    function buildValue(hoveredCell) {
        const { radius, angle, angle0 } = hoveredCell;
        const truedAngle = (angle + angle0) / 2;
        return {
            x: radius * Math.cos(truedAngle),
            y: radius * Math.sin(truedAngle)
        };
    }

    return (
        <>
            <RadialChart
                className='display-block margin-auto-lr'
                data={[{ angle: filled, color: donutColors[0], label: filledHover }, { angle: unfilled, color: donutColors[1], label: unfilledHover }]}
                width={200}
                height={200}
                colorType='literal'
                innerRadius={85}
                radius={70}
                onValueMouseOver={v =>

                    setHoveredCell(v)

                }
                onValueMouseOut={v => setHoveredCell(null)}
            >
                {/* {!unfilled && !filled ?
                    <h3 className="Percent-Label position-absolute horizontal-center" >0%</h3>
                    : <h3 className="Percent-Label position-absolute horizontal-center" >{Math.round(filled / (unfilled + filled) * 100)}%</h3>
                } */}
                <h3 className="Percent-Label position-absolute horizontal-center" >{filled}</h3>
                {hoveredCell ? (

                    <Hint className='Hint-Hover' value={buildValue(hoveredCell)}>
                        <div className="height-fit-content">

                            <span className="white" >{hoveredCell.label}</span>
                        </div>
                    </Hint>
                ) : null}

            </RadialChart>
           
            <DiscreteColorLegend
                items={[{title: filledText, strokeWidth: 10}, {title: unfilledText, strokeWidth: 10}]}
                colors={donutColors}
                orientation='horizontal'
                style={{ display: 'flex' }}
                className="flex-wrap justify-content-center"
                height={50}
            >
            </DiscreteColorLegend>
        </>
    )
}