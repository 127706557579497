import React, { useState, useEffect } from 'react';
import './header-animation.component.css';
import { Link } from 'react-router-dom';

function HeaderAnimation() {

const initialText = 'Welcome to Honeyydo!';
const sentence = 'The all in one home management application for Homeowners!';
const sentence2 = 'And an amazing lead generation tool for Agents!';
const sentence3 = 'Were here to help you in any way we can!';
const [position, setPosition] = useState(0);
const [currentSentence, setCurrentSentence] = useState(initialText);
const [isPaused, setIsPaused] = useState(false);

useEffect(() =>
{
    const animationInterval = setInterval(() =>
    {
        setPosition((prevPosition) =>
        {
            if (isPaused) return prevPosition;

            let newPosition = prevPosition + 1;
            if (currentSentence === initialText && newPosition > initialText.length)
            {
                setIsPaused(true);
                setTimeout(() =>
                {
                    setIsPaused(false);
                    setCurrentSentence(sentence);
                }, 2000);
            } else if (currentSentence === sentence && newPosition > sentence.length)
            {
                setIsPaused(true);
                setTimeout(() =>
                {
                    setIsPaused(false);
                    setCurrentSentence(sentence2);
                }, 2000);
            } else if (currentSentence === sentence2 && newPosition > sentence2.length)
            {
                setIsPaused(true);
                setTimeout(() =>
                {
                    setIsPaused(false);
                    setCurrentSentence(sentence3);
                }, 2000);
            } else if (currentSentence === sentence3 && newPosition > sentence3.length)
            {
                setIsPaused(true);
                setTimeout(() =>
                {
                    setIsPaused(false);
                    setCurrentSentence(initialText);
                }, 2000); // Time in miliseconds that it freezes
            }
            return newPosition;
        });
    }, 100); // Change for faster animation

    return () =>
    {
        clearInterval(animationInterval);
    };
}, [isPaused, currentSentence]);

useEffect(() =>
{
    setPosition(0);
}, [currentSentence]);

return (
    <div className="header-animation">
    {currentSentence.slice(0, position)}

</div>
);
}

export default HeaderAnimation;