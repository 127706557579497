import aboutImg from "../../../assets/images/honeyydo-about.jpg";
import aboutImg2 from "../../../assets/images/Couple Bought Home.jpg";

function About() {

    return (

        <>

            <h1 className="About-Heading padding-top-5-vh heading">Meet <span className="fade-in orange" >Honeyydo</span></h1>
            <div className="About-Wrap">


                <img className="About-Img  float-left " src={aboutImg2} alt="People touring house" />

                {/* <p className="About-Text text-spacing dark-blue text-font text-align-left">
                        Honeyydo was founded on the simple premise that it should be easy to manage a home.
                        <br /><br /><br />
                        Through our tool we remove the fluff of owning a home and simplify the experience with easy to use tools for the homeowner.
                        Here you can track routine maintenance items, digitize owners manuals, and find local professionals to rely on for your home.
                        Central to the home buying and owning experience are trusted advisors such as real estate agents and mortgage lenders.
                        We enable real estate professionals to give Honeyydo to their clients to stay top of mind.
                        On top of that, as you stay top of mind for clients, Honeyydo will drive online reviews to you.
                        <br /><br /><br />
                        Our goal is to streamline and strengthen the real estate agent and homeowner relationship through easy to use tools.
                        <br /><br /><br />
                        We hope you enjoy it! </p> */}
                <div className="About-Text-Wrapper">
                    <h4 className="margin-bottom-20-px ">Our Foundation</h4>
                    <p className=" padding-0-5-pt text-smaller ">
                        At Honeyydo, our inception was driven by a singular vision—to simplify the complexities of homeownership.
                        Founded upon the belief that managing a home should be straightforward and hassle-free,
                        we have cultivated a service that embodies efficiency and ease.
                        <br /><br />
                    </p>
                    <h4 className="margin-bottom-20-px ">Our Tools, Your Peace of Mind</h4>
                    <p className=" padding-0-5-pt text-smaller ">
                        We have meticulously crafted a suite of tools that peel away the layers of home management,
                        presenting a streamlined experience for homeowners.
                        With Honeyydo, the burdensome tasks of tracking routine maintenance, organizing digital documents,
                        and recruiting trusted local professionals are transformed into a seamless, digital journey.
                        Every feature is designed with the homeowner's ease and convenience in mind.
                        <br /><br />
                    </p>
                    <h4 className="margin-bottom-20-px ">Empowering Real Estate Professionals</h4>
                    <p className=" padding-0-5-pt text-smaller ">
                        Understanding the pivotal role of real estate agents and mortgage lenders in the homeownership experience, we extend our platform to these trusted advisors.
                        Honeyydo serves as a thoughtful gift that agents and lenders can offer their clients,
                        ensuring they remain at the forefront of their clients' minds, long after the close of a transaction.
                        Moreover, our platform is a conduit for driving valuable online reviews to these professionals, amplifying their presence and reputation in the marketplace.
                        <br /><br />
                    </p>
                    <h4 className="margin-bottom-20-px ">A Synergistic Relationship</h4>
                    <p className=" padding-0-5-pt text-smaller ">
                        Our mission transcends the provision of tools; we aim to fortify the bond between real estate agents and homeowners.
                        By equipping agents with personalized engagement tools and homeowners with our intuitive platform,
                        Honeyydo fosters a symbiotic relationship that grows stronger with each interaction.
                        <br /><br />
                    </p>
                    
                    <h4 className="margin-bottom-20-px ">Our Promise</h4>
                    <p className=" padding-0-5-pt text-smaller ">
                        In every aspect of our service, from maintenance management to client engagement, Honeyydo is committed to delivering excellence.
                        We have designed our platform not just as a product, but as a partner in the homeownership journey.
                        Join us at Honeyydo—where managing your home is not just easier, it's a pleasure.
                        <br /><br />
                    </p>
                    
                    <p className=" padding-0-5-pt text-smaller ">
                        We hope you enjoy it!
                    </p>
                </div>
            </div>
        </>

    )

}

export default About;