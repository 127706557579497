const serverResponseMessagesConstants = {
    "DUPLICATE_ENTRY" : { "message": "This email is already in use", "title": "Account Exists", "color": "#e8763d", "sticky": false },
    'ER_DUP_ENTRY': { "message": "This email is already in use", "title": "Account Exists", "color": "#e8763d", "sticky": false },
    "WRONG_CREDENTIALS" : { "message": "Password or email is wrong", "title": "Error", "color": "#e8763d", "sticky": false },
    "USER_NOT_FOUND": { "message": "User does not exist", "title": "Error", "color": "#e8763d", "sticky": false },
    "AUTH_CODE_DOES_NOT_MATCH": { "message": "Your auth code is wrong!", "title": "Auth Code Doesn't Match", "color": "#e8763d", "sticky": false },
    "USER_UNIDENTIFIED": { "message": "Email address not found", "title": "Error", "color": "#e8763d", "sticky": false },
    "SOMETHING_WENT_WRONG": { "message": "something went wrong", "title": "Error", "color": "#e8763d", "sticky": false },
    "USER_IS_LOCKED_BY_ADMIN": { "message": "Your account was locked by the admin please contact support@honeyydo.com", "title": "Locked", "color": "#e8763d", "sticky": true, "logout": true },
    // "USER_IS_NOT_SUBSCRIBED": { "message": <span>Your are not subscirbed yet, please subscirbe to access honeyydo using this link <a href="honeyydo.com/#">Subscribe</a></span>, "title": "Not Subscribed", "color": "#e8763d", "sticky": true, "logout": true },
    "WRONG_TOKEN": { "message": "You will be logged out, please log in again", "title": "Your session has expired", "color": "#e8763d", "sticky": true },
    "USER_COOKIE_EXPIRED": { "message": "Please login to verify your account, you will be loged out in 5 seconds", "title": "Your Cookie Expired", "color": "#e8763d", "sticky": false },
    "USER_IS_ROBOT": { "message": "Captcha authentication failed, please refresh and try again", "title": "Authentication Failed", "color": "#e8763d", "sticky": false },
    "MISSING_EMAIL_FIELD": { "message": "Please add an email field and then try again", "title": "Missing Email Field", "color": "#e8763d", "sticky": false },
}

export default serverResponseMessagesConstants;