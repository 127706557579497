import React, { useState, useEffect, useRef, useContext } from "react";
import { UserContext } from "../../../App";
import "./notepad.component.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faChevronDown, faChevronUp, faEdit, faNoteSticky, faPlus, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import sqlService from "../../../services/sqlService";
import sortUtility from "../../../functions/sortUtility";


export default function Notepad() {
    const user = useContext(UserContext);
    const [noteDialog, setNoteDialog] = useState(false)
    const [noteText, setNoteText] = useState('')
    const [titleText, setTitleText] = useState('')
    const [notes, setNotes] = useState([])
    const notesLoaded = useRef(false)
    const [refresh, setRefresh] = useState(false)
    const [notesShow, setNotesShow] = useState(false)
    const MAXLENGTH = 1000;
    const [scrollTop, setScrollTop] = useState(0)
    const [scrollBottom, setScrollBottom] = useState(0)
    const [noteId, setNoteId] = useState(null)
    const [element, setElement] = useState(null)
    const [deletePopup, setDeletePopup] = useState(null)

    useEffect(() => {
        let notes = document.getElementById('Notes');
        setElement(notes)

        setScrollBottom(notes?.scrollHeight - notes?.clientHeight - notes?.scrollTop)

    }, [notes, notesShow])

    useEffect(() => {

        if (element) {
            element.addEventListener('scroll', onNoteScroll)

        }
        // return () => {
        //     notes.removeEventListener('scroll', onNoteScroll);
        //   };
    }, [notesShow, element])

    function onNoteScroll() {

        setScrollTop(element.scrollTop)
        setScrollBottom(element.scrollHeight - element.clientHeight - element.scrollTop)

    }



    function updateNote(val) {
        if (val.length <= MAXLENGTH) {
            setNoteText(val);
       
        }

    }

    function saveNote() {
        console.log(titleText)
        sqlService.addNote({ user: user, input: { id: noteId, title: titleText, note: noteText } }).then((d) => {
            resetNote()
            notesLoaded.current = false;
            setRefresh(!refresh)
            setNoteDialog(false)

            setScrollBottom(element.scrollHeight - element.clientHeight - element.scrollTop)
            // console.log(notes.scrollHeight , notes.clientHeight , notes.scrollTop)

        }).catch((err) => {

        })



    }

    function deleteNote() {
        sqlService.deleteNote({ user: user, input: noteId }).then(() => {
            resetNote()
            notesLoaded.current = false;
            setRefresh(!refresh)
            setNoteDialog(false)
            setDeletePopup(false)
        }).catch((err) => {

        })
    }

    function resetNote() {
        setTitleText('');
        setNoteText('');
        setNoteId(null)

    }

    useEffect(() => {
        if (!notesLoaded.current) {
            sqlService.getNotesForUser({ user: user }).then((data) => {
                setNotes(sortUtility.sort('creation_date', data.data, false))
                notesLoaded.current = true;
              
            }).catch((err) => {

            })
        }
    }, [refresh])


    return (
        <>
            {notesShow ?
                <div className="position-absolute Notepad box-shadow-2 text-align-center">
                    <FontAwesomeIcon className="fa-lg grey padding-10-px cursor-pointer position-absolute" style={{ right: '0px' }} icon={faCaretLeft} onClick={() => { setNotesShow(false); setScrollTop(0); setScrollBottom(5); resetNote(); setNoteDialog(false); setDeletePopup(false) }} />

                    {noteDialog ?
                        <div style={{ left: '145%', width: '300px' }} className="popup box-shadow-2 text-align-left">
                            {deletePopup ?

                                <div style={{ width: '250px', top: '70%' }} className="popup box-shadow-2">

                                    <span style={{ padding: '20px 0' }}>Delete note?</span>
                                    <div className="display-flex column-gap-10-px margin-top-15-px">
                                        <button className="Cancel-Button" onClick={() => setDeletePopup(false)}>Cancel</button>
                                        <button className="Cancel-Button red" onClick={() => deleteNote()}>Delete</button>
                                    </div>
                                </div>


                                : null}
                            <label className="card-input__label">Title</label>
                            <input className="Input-Style width-full-pt" value={titleText} onChange={(e) => setTitleText(e.target.value)}></input>
                            <label className="card-input__label margin-top-15-px">Note</label>
                            <textarea style={{ minHeight: '200px', width: '100%' }} value={noteText} onChange={(e) => updateNote(e.target.value)} className="Input-Style"></textarea>
                            <div style={{ justifyContent: 'right' }} className="width-full-pt display-flex ">
                                <span className="count">{noteText?.length ?? 0}/{MAXLENGTH}</span>
                            </div>
                            <div className="display-flex column-gap-10-px margin-top-15-px">
                                <button className="Cancel-Button" onClick={() => { setNoteDialog(false); resetNote(); setDeletePopup(false) }}>Close</button>
                                {noteId ?
                                    <button className="Cancel-Button" onClick={() => setDeletePopup(true)}><FontAwesomeIcon icon={faTrash} /></button>
                                    : null}
                                <button className="popup-submit-button" onClick={() => saveNote()} >
                                    Save
                                    <FontAwesomeIcon className="fa-sm margin-left-5-px" icon={faSave} />
                                </button>
                            </div>
                        </div> : null}
                    <h5 className="grey margin-top-15-px">Notes</h5>
                    {scrollTop > 0 ?
                        <span onClick={() => {
                            let element = document.getElementById("Notes")
                            element.scrollBy(0, -200);
                        }
                        } className="grey cursor-pointer"><FontAwesomeIcon icon={faChevronUp} /></span>
                        : <div style={{ height: '24px' }}></div>}


                    <div id="Notes"  className="hide-scroll-bar text-align-left" >
                        {notes.length ?
                        notes.map((note) =>
                            <div style={{ padding: '20px', width: '95%', margin: 'auto' }} className=" border-bottom-solid note cursor-pointer " onClick={() => {
                                setTitleText(note.title);
                                setNoteText(note.note)
                                setNoteId(note.id)
                                setNoteDialog(true)
                            }}>
                                <span style={{ fontSize: '.9em' }}>{new Date(note.creation_date).toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' })}</span>

                                <span> {note.title !== '' ? note.title : note.note?.split(' ')?.splice(0, 10)?.join(' ') + " ..."} </span>
                
                            </div>
                        ) : <div style={{padding: '100px 20px 0 20px ', paddingTop: '100px'}}>You haven't added any notes yet. Click the plus sign to get started.</div>}
                    </div>
                    {scrollBottom > 1 ?
                        <span onClick={() => {
                            // let element = document.getElementById("Notes")
                            element.scrollBy(0, 200);
                        }
                        } className="grey cursor-pointer"><FontAwesomeIcon icon={faChevronDown} /></span>
                        : <div style={{ height: '24px' }}></div>}

                    <button style={{ bottom: '15px', right: '15px', }} className="position-absolute Note-Add-Button" onClick={() => { resetNote(); setNoteDialog(true) }}><FontAwesomeIcon icon={faPlus} className="fa-lg" /></button>


                </div>
                :
                <div className="Notepad position-absolute box-shadow-2 display-flex flex-direction-column" style={{ width: '30px', top: '170px' }}>
                    <FontAwesomeIcon className="fa-lg fa-flip-horizontal grey padding-10-px cursor-pointer" icon={faCaretLeft} onClick={() => setNotesShow(true)} />
                    <div className="display-flex flex-direction-column align-items-center justify-content-center">
                        <span>N</span>
                        <span>O</span>
                        <span>T</span>
                        <span>E</span>
                        <span>S</span>
                    </div>
                <FontAwesomeIcon className=" grey padding-10-px position-absolute" icon={faNoteSticky} style={{top: '90%'}}/>
                </div>
            }
        </>
    )
}