import React, { useState, useRef, useEffect } from "react"
import "./adminAssociationsControl.css"
import { Modal, Button } from "react-bootstrap";
import sqlService from "../../../services/sqlService";
import { Spinner } from "react-bootstrap";
import stripeService from "../../../services/stripeService";

export default function AdminAssociationsControl() {
    const [associations, setAssociations] = useState(null);
    const [openAssociationModal, setOpenAssociationModal] = useState(false)
    const [allAssociationsWithTheirUsersAndCoupons, setAllAssociationsWithTheirUsersAndCoupons] = useState(null);

    const [associationName, setAssociationName] = useState("");
    const [requestedAdd, setRequestedAdd] = useState(false);
    const loaded = useRef(false);
    const [refresh, setRefresh] = useState(false);
    const [allCoupons, setAllCoupons] = useState([]);
    const [showCoupons, setShowCoupons] = useState({});
    const [allUsers, setAllUsers] = useState({});
    async function getAllAssociationsWithTheirUsersAndCoupons() {
        sqlService.getAllAssociationsWithTheirUsersAndCoupons().then(d => {
            console.log(d);
            var associations = {}
            for (let item of d) {
                if (associations[item?.association_id]) {
                    associations[item?.association_id] = [...associations[item?.association_id], item];
                } else {
                    showCoupons[item?.association_id] = item?.association_id + "all"
                    associations[item?.association_id] = [item];
                }
            }
            console.log(associations);
            setAllAssociationsWithTheirUsersAndCoupons(associations);
        }).catch(err => {
            console.log(err);
        })
    }

    async function getAllCoupons() {
        var hasMore = false;
        var allCoupons = [];
        var starting_after = null;
        do {
            await stripeService.getAllCoupons({ input: { starting_after: starting_after } }).then(d => {
                let data = d.data;
                console.log(data)
                hasMore = data?.has_more
                allCoupons = [...allCoupons, ...data.data];
                console.log(starting_after, hasMore)
                if(!data?.data?.length){
                    hasMore = false;
                }else{
                    starting_after = data?.data[data?.data?.length - 1]?.id;
                }
            }).catch(err => {
                hasMore = false;
                console.log(err)
            })
        } while (hasMore)
        console.log(allCoupons)
        setAllCoupons(allCoupons);
    }

    useEffect(() => {
        if (!loaded.current) {
            loaded.current = true;
            getAllAssociationsWithTheirUsersAndCoupons();
            getAllCoupons();
            getAllUsers();
        }
    }, [refresh])

    const addAssociation = () => {
        sqlService.addAssociation({ input: { name: associationName } }).then(d => {
            loaded.current = false;
            setOpenAssociationModal(false);
            setRefresh(refresh => !refresh)
        }).catch(err => {
            setOpenAssociationModal(false);
            console.log(err);
        })
    }

    const getCouponsForAssociation = (associationId) => {
        // console.log(associationId, allCoupons)
       const r = allCoupons.filter(item => parseInt(item.metadata.association_id)===parseInt(associationId))
       if(r){return r}
       else{return []}
    }

    async function getAllUsers() {
        sqlService.getAllUsers().then(d => {
            setAllUsers(d);
        }).catch(err => {
            console.log(err);
        })
    }

    return (
        <div className="display-flex flex-direction-column justify-content-center align-items-center padding-10-px column-gap-10-px row-gap-10-px">
            <div>
                <Button variant="primary" onClick={() => { setOpenAssociationModal(true) }}>
                    Add Association
                </Button>

            </div>
            <div className="Association_Table" >
                {allAssociationsWithTheirUsersAndCoupons ?
                    Object.keys(allAssociationsWithTheirUsersAndCoupons).map(key =>
                        <div className="Association_Item_Main">
                            <span className="bold">{key}{` `}{allAssociationsWithTheirUsersAndCoupons[key][0].association_name}</span>
                            <div className="display-flex flex-wrap align-items-center justify-content-center column-gap-15-px row-gap-15-px">
                                <Button disabled={showCoupons[key] === key + "coupons"} variant="primary" onClick={() => { setShowCoupons({ ...showCoupons, [key]: key + "coupons" }) }}>
                                    {"Show Coupons "}({getCouponsForAssociation(key).length})
                                </Button>
                                <Button disabled={showCoupons[key] === key + "none"} variant="primary" onClick={() => { setShowCoupons({ ...showCoupons, [key]: key + "none" }) }}>
                                    Hide All
                                </Button>
                                <Button disabled={showCoupons[key] === key + "all"} variant="primary" onClick={() => { setShowCoupons({ ...showCoupons, [key]: key + "all" }) }}>
                                    {"Show Users <-> Coupons "}({allAssociationsWithTheirUsersAndCoupons[key].length})
                                </Button>
                            </div>

                            {showCoupons[key] === key + "all"
                                ?
                                allAssociationsWithTheirUsersAndCoupons[key][0].user_id || allAssociationsWithTheirUsersAndCoupons[key][0].coupon_id ?
                                    <div className="Association_Item_Submain">
                                        {allAssociationsWithTheirUsersAndCoupons[key].map(item =>
                                            <div className="Association_Item">
                                                <span>Coupon Code:<span className="bold margin-left-5-px">{item.coupon_code}</span></span>
                                                <span>Discount Percentage:<span className="bold margin-left-5-px">{item.discount_percentage}%</span></span>
                                                <span>Duration:<span className="bold margin-left-5-px">{item.duration}</span></span>
                                                <span>Duration In Months:<span className="bold margin-left-5-px">{item.discount_duration_in_months}</span></span>
                                                <span>Use Limit:<span className="bold margin-left-5-px">{item.use_limit}</span></span>
                                                <span>Number Of Use:<span className="bold margin-left-5-px">{item.number_of_use}</span></span>
                                                <span>Creation Date:<span className="bold margin-left-5-px">{item.creation_date}</span></span>
                                                <span>Username:<span className="bold margin-left-5-px">{item.user_contact_name}</span></span>
                                            </div>
                                        )}

                                    </div>
                                    : null
                                : null}
                            {showCoupons[key] === key + "coupons"
                                ?
                                <div className="Association_Item_Submain">
                                {getCouponsForAssociation(key).map(item =>
                                    <div className="Association_Item">
                                        <span>Name:<span className="bold margin-left-5-px">{item?.name}</span></span>
                                        <span>Coupon Code:<span className="bold margin-left-5-px">{item?.metadata?.coupon_code}</span></span>
                                        <span>Discount Percentage:<span className="bold margin-left-5-px">{item?.percent_off}%</span></span>
                                        <span>Duration:<span className="bold margin-left-5-px">{item?.duration}</span></span>
                                        <span>Duration In Months:<span className="bold margin-left-5-px">{item?.duraction_in_months}</span></span>
                                        <span>Use Limit:<span className="bold margin-left-5-px">{item?.max_redemptions}</span></span>
                                        <span>Number Of Use:<span className="bold margin-left-5-px">{item?.times_redeemed}</span></span>
                                        <span>Creation Date:<span className="bold margin-left-5-px">{item?.created}</span></span>
                                    </div>
                                )}
                            </div>
                                : null}

                        </div>

                    )
                    : <span>No associations</span>
                }
            </div>
            <Modal
                show={openAssociationModal}
                onHide={() => { setOpenAssociationModal(false) }}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{"Add Association"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/* <input type="text" onChange={(e)=> {setAssociationName(e.currentTarget.value)}} value={associationName} /> */}

                    <div className='Input-Container'>
                        <div className="Input-Wrap ">
                            <div className="Input-Div">
                                <span className="wrap-none">Association Name</span>
                            </div>
                            <div className=" position-relative">
                                <input
                                    className="Input-Style"
                                    type="text"
                                    value={associationName}

                                    onChange={(e) => { setAssociationName(e.currentTarget.value) }}
                                />
                            </div>
                        </div>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <span
                        className="margin-right-10-px"
                        onClick={() => {

                        }}
                    >
                        {requestedAdd ?
                            <Spinner /> :
                            <button onClick={() => { addAssociation() }} className="Tasklist-Button height-40-px ">
                                Save
                            </button>
                        }

                    </span>
                    <Button variant="secondary" onClick={() => { setOpenAssociationModal(false) }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}