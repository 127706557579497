import React, { useState, useEffect, useRef } from "react";

import BarChart from "../../utility.component/dash.component/barchart.component";
import StarRating from "../../utility.component/rating.component/rating.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";


import sqlService from "../../../services/sqlService";

import { useContext } from "react";
import { UserContext } from "../../../App";
import Notifications from "../../utility.component/notifications.component/notifications.component";

import { useNavigate } from "react-router-dom";
import WeatherMobile from "../../universal.component/weather.component/weatherMobile.component";
import Weather from "../../universal.component/weather.component/weather.component";

import Notepad from "../../utility.component/notepad.component/notepad.component";
import NotepadMobile from "../../utility.component/notepad.component/notepadMobile.component";
import WelcomeMessage from "../../utility.component/welcomeMessage.component/welcomeMessage.component";
import { ThreeCircles } from "react-loader-spinner";
import banner2 from "../../../assets/images/banner-photos/Banner5-min.jpg"
import banner5 from "../../../assets/images/banner-photos/Banner9-min.jpg"
import banner8 from "../../../assets/images/banner-photos/Banner3-min.jpg"
import banner9 from "../../../assets/images/banner-photos/Banner2-min.jpg"
import banner11 from "../../../assets/images/banner-photos/Banner1-min.jpg"
import banner12 from "../../../assets/images/banner-photos/Banner4-min.jpg"
import banner14 from "../../../assets/images/banner-photos/Banner8-min.jpg"
import banner15 from "../../../assets/images/banner-photos/Banner6-min.jpg"
import banner20 from "../../../assets/images/banner-photos/Banner7-min.jpg"

function BrokerageHome() {
    const bannerImgs = [
        { url: banner9, position: '0 90%', size: 'cover' },
        { url: banner15, position: '0 44%', size: 'cover' },
        { url: banner14, position: '0 41%', size: 'cover' },
        { url: banner12, position: '0 56%', size: 'cover' },
        { url: banner11, position: '0 80%', size: 'cover' },
        { url: banner8, position: '0 56%', size: 'cover' },
        { url: banner5, position: '0 55%', size: 'cover' },
        { url: banner2, position: '0 65%', size: 'auto' },
        { url: banner20, position: '0 49%', size: 'cover' },
      
      ]
        
        const banner = useRef({})
      
        useEffect(() => {
      
          banner.current = bannerImgs[Math.floor(Math.random() * bannerImgs?.length)]
          
        }, [])
    const user = useContext(UserContext);
    const [windowSize, setWindowSize] = React.useState(window.innerWidth);
    const COLORS = ['#f8a025', '#2596be', '#34378b', '#b7b8d6', '#b551dd']
    const [signupData, setSignupData] = useState([]);
    const [agentData, setAgentData] = useState([]);
    const [yMax, setYMax] = useState(0);
    const [refsYMax, setRefsYMax] = useState([]);
    const [agents, setAgents] = useState([]);
    const [referrals, setReferrals] = useState([]);
    const [homeowners, setHomeowners] = useState([]);
    const [newHomeowners, setNewHomewners] = useState([]);
    const [activeHomeowners, setActiveHomeowners] = useState(0);
    const [inactiveHomeowners, setInactiveHomeowners] = useState(0);
    const [topAgents, setTopAgents] = useState([]);
    const [agentsData, setAgentsData] = useState([]);
    const [agentsLabels, setAgentsLabels] = useState([]);
    const [referralData, setReferralData] = useState([]);
    const [leadsNum, setLeadsNum] = useState(0);
    const [currentClientsNum, setCurrentClientsNum] = useState([])

    const [refresh, setRefresh] = useState(false);
    const homeownersLoaded = useRef(false);
    const topAgentsLoaded = useRef(false);
    const agentsLoaded = useRef(false);
    const referralsLoaded = useRef(false);
    const allowRender = useRef(false)

    const [show, setShow] = useState(false)
    const blogsLoaded = useRef(false);
    const [blog, setBlog] = useState({})



    const cl = useRef([]);




    const Navigate = useNavigate();


    useEffect(() => {
        if (!user) {
            Navigate("/")
        }
    })


    const time = new Date().getHours()
    const welcome = `Good ${time < 12 && time > 4 ? 'Morning' : time < 17 && time > 2 ? 'Afternoon' : 'Evening'}, ${user?.contact_name?.split(' ')[0]}`
    const icon = time < 4 || time < 17 ? faSun : faMoon

    const loaded = React.useRef(false);

    useEffect(() => {
        window.addEventListener('resize', onWindowResize);

        return () => {
            window.removeEventListener('resize', onWindowResize);
        };
    }, []);


    const onWindowResize = () => {
        setWindowSize(window.innerWidth);
    }






    useEffect(() => {
        if (!blogsLoaded.current) {
            sqlService.getLibraries()
                .then(data => {
                    console.log(data.data)
                    const blogs = data.data.reverse();
                    setBlog(blogs.find((blog) => blog.tags.includes('Updates')))
                
                })
                .catch(err => {
                    console.log(err);
                });
            blogsLoaded.current = true;
        }
        if (!homeownersLoaded.current) {
            sqlService
                .getHomeownersByBrokerage({ user: user })
                .then((data) => {
                    const homeowners = data.data;
                    setHomeowners(homeowners);
                    const leads = homeowners.filter(homeowner => homeowner.client_type = 2)
                    setCurrentClientsNum(homeowners.length)
                    setLeadsNum(homeowners.length - leads.length)

                })
                .catch((err) => {
                    console.log(err);
                });
            homeownersLoaded.current = true;
        }

        if (!topAgentsLoaded.current) {
            sqlService
                .getTopAgents({ user: user })
                .then((data) => {
                    const topagents = data.data;
                    cl.current = topagents;

                    setTopAgents(topagents);



                })
                .catch((err) => {
                    console.log(err);
                });
            topAgentsLoaded.current = true;
        }

        if (!referralsLoaded.current) {
            sqlService
                .getReferralsByBrokerage({ user: user })
                .then((data) => {
                    const referrals = data.data;

                    setReferrals(referrals);


                })
                .catch((err) => {
                    console.log(err);
                });
            referralsLoaded.current = true;
        }

    }, [refresh]);

    // Parse data for new referrals chart
    // useEffect(() => {

    //     var refs = [{ y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }]
    //     var initialDate = new Date();
    //     // Set initial date for chart based on length of refs list
    //     initialDate.setMonth(initialDate.getMonth() - refs.length + 1, 1)
    //     initialDate.setHours(0, 0, 0)

    //     for (var referral of referrals) {
    //         var d = new Date(referral.date)
    //         // var d = new Date(referral.date.replace(/-/g, '\/'))
    //         // Check that referral date is more recent than initial date 
    //         if (d > initialDate) {
    //             // Increment appropriate index based on difference of months 
    //             if (d.getFullYear() === initialDate.getFullYear()) {
    //                 refs[d.getMonth() - initialDate.getMonth()].y++;
    //                 // If initial date was previous year 
    //             } else {
    //                 refs[12 - initialDate.getMonth() + d.getMonth()].y++;
    //             }
    //         }
    //     }

    //     // Set month labels
    //     for (var i = 0; i < refs.length; i++) {
    //         var date = new Date();
    //         date.setMonth(date.getMonth() - refs.length + i + 1, 1)
    //         initialDate.setHours(0, 0, 0)
    //         refs[i].x = date.toLocaleString('default', { month: 'short' });
    //         if (refs[i].y > 1) {
    //             refs[i].label = refs[i].y.toString() + ' new homeowner referrals in ' + date.toLocaleString('default', { month: 'long' });
    //         } else {
    //             refs[i].label = refs[i].y.toString() + ' new homeowner referral in ' + date.toLocaleString('default', { month: 'long' });
    //         }

    //     }
    //     // Set Max y value for chart (min of 3)
    //     const max = Math.max(Math.max(...refs.map(s => s.y)), 3)
    //     setRefsYMax(max)

    //     setReferralData(refs)

    // }, [referrals.length])

    // Parse data for "New Homeowners" (signups) and "Active Users"
    useEffect(() => {
        var signups = [{ y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }]
        var newAgents = [{ y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }]
        var active = 0;
        var inactive = 0;
        // var comparisonDate = new Date();
        // comparisonDate.setDate(comparisonDate.getDate() - 30);
        // comparisonDate = comparisonDate.toISOString().split('T')[0];


        var initialDate = new Date();
        initialDate.setMonth(initialDate.getMonth() - signups.length + 1, 1)
        initialDate.setHours(0, 0, 0)
        if (homeownersLoaded) {
            for (var i = 0; i < homeowners.length; i++) {
                // if (homeowners[i].last_used) {
                //     if (homeowners[i].last_used >= comparisonDate) {
                //         active++;
                //     } else {
                //         inactive++;
                //     }
                // }



                // var d = new Date(homeowners[i].creation_date.replace(/-/g, '\/'))
                var d = new Date(homeowners[i].creation_date)


                if (d > initialDate) {
                    if (d.getFullYear() === initialDate.getFullYear()) {
                        signups[d.getMonth() - initialDate.getMonth()].y++;

                    } else {
                        signups[12 - initialDate.getMonth() + d.getMonth()].y++;
                    }
                }
            }
        }
        if (topAgentsLoaded) {

            for (var i = 0; i < topAgents.length; i++) {

                // var d = new Date(topAgents[i].creation_date.replace(/-/g, '\/'))
                var d = new Date(topAgents[i].creation_date)


                if (d > initialDate) {
                    if (d.getFullYear() === initialDate.getFullYear()) {
                        newAgents[d.getMonth() - initialDate.getMonth()].y++;

                    } else {
                        newAgents[12 - initialDate.getMonth() + d.getMonth()].y++;
                    }
                }
            }
        }
        // Set x Values and text for hover
        for (var i = 0; i < signups.length; i++) {
            var date = new Date();
            date.setMonth(date.getMonth() - signups.length + i + 1, 1)
            initialDate.setHours(0, 0, 0)
            signups[i].x = date.toLocaleString('default', { month: 'short' });
            newAgents[i].x = date.toLocaleString('default', { month: 'short' });
            if (signups[i].y > 1) {
                signups[i].label = signups[i].y.toString() + ' new homeowners joined in ' + date.toLocaleString('default', { month: 'long' });
            } else {
                signups[i].label = signups[i].y.toString() + ' new homeowner joined in ' + date.toLocaleString('default', { month: 'long' });
            }
            if (newAgents[i].y > 1) {
                newAgents[i].label = newAgents[i].y.toString() + ' new agents joined in ' + date.toLocaleString('default', { month: 'long' });
            } else {
                newAgents[i].label = newAgents[i].y.toString() + ' new agent joined in ' + date.toLocaleString('default', { month: 'long' });
            }

        }

        // Get max y value with min value of 3
        const max = Math.max(Math.max(Math.max(...signups.map(s => s.y)), Math.max(...newAgents.map(s => s.y)), 3))
        setActiveHomeowners(active);
        setInactiveHomeowners(inactive);
        setSignupData(signups);
        setAgentData(newAgents);
        setYMax(max);

    }, [homeowners.length, topAgents.length]);

    // Parse data for "Top Referring Agents" (max length dictated by available colors)
    useEffect(() => {
        const data = []
        const labels = []
        for (var i = 0; i < topAgents.length && i < COLORS.length; i++) {
            data.push({
                angle: topAgents[i].total,
                color: COLORS[i],
                label: topAgents[i].contact_name + ': ' + topAgents[i].total?.toString() + ' total homeowner signups'
            })

            labels.push(topAgents[i].contact_name)
        }
        setAgentsData(data)
        setAgentsLabels(labels)
    }, [topAgents])

    useEffect(() => {
        for (var i; i < agents.length; i++) {

        }


    }, [agents, topAgents])

    useEffect(() => {

        const onPageLoad = () => {
            allowRender.current = true;

        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad, false);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);



    return (
        <>
                {allowRender.current ?
                    <>
                        {!(user?.welcome_seen) ?
                            <WelcomeMessage /> : null}
                        <div className="dashboard-wrap">

                            <div className="homepage-grid ">

                                <div style={{ backgroundImage: `url(${banner.current?.url})`, backgroundPosition: banner.current?.position, backgroundSize: banner.current?.size }} className="homepage-banner display-flex justify-content-space-between">

                                    <div className="display-flex">
                                        <FontAwesomeIcon className="margin-right-10-px white fa-2x" transform={{ rotate: -15 }} icon={icon} />
                                        <h3 style={{ textShadow: ' black 1px 0 10px' }} className="white">

                                            {welcome}</h3>
                                    </div>
                                    <div className=" dashboard-rating-div"  >

                                        <h5 style={{ textShadow: ' black 1px 0 15px' }} className="white">My Rating</h5>
                                        <div className="position-relative" onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
                                            <StarRating
                                                readOnly={true}
                                                dashboard={true}
                                            />
                                            <div className={` hover-popup ${show ? 'visibility-visible' : 'visibility-hidden'}`}>

                                                {user?.general_rating ?
                                                    <span >Your average rating is {user?.general_rating?.toFixed(2)}</span>
                                                    : <span>You don't have any ratings yet</span>}
                                            </div>
                                        </div>


                                    </div>

                                </div>
                                <div className=" brokerage-notifications">
                                    <Notifications max={7} openFromBottom={true} />
                                </div>
                                {/* <div className="grid-container daily-tile">
                                    <h5 className="grey margin-bottom-20-px">{new Date().toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' })}</h5>


                                    {!events?.length && !emails?.length && !ecards?.length && !dates?.length ?
                                        <h6 className="color-med-grey margin-bottom-20-px margin-top-40-px">
                                            Nothing scheduled for today
                                        </h6> : null}
                                    <div className="display-flex flex-direction-column daily-tile-inner">

                                        {events?.map((event) =>
                                            <div className="column-gap-10-px daily-grid">
                                                <div style={{ backgroundColor: event.color, height: '30px' }} className="time-tag">
                                                    {getTime(event.start_date)}
                                                </div>
                                                <span className="bold color-med-grey">{event.title}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        {dates?.length ?
                                            <div className="margin-top-10-px margin-bottom-15-px">
                                                {dates?.map((event) =>
                                                    <div className="column-gap-10-px daily-grid ">


                                                        <FontAwesomeIcon className="grey fa-lg justify-self-end" icon={faCalendar} />

                                                        <span className="bold color-med-grey">{event.title} ({event.name})</span>
                                                    </div>
                                                )}
                                            </div> : null}


                                        {emails?.length || ecards?.length ?
                                            <h6 className="grey margin-bottom-20-px margin-top-10-px">Sending today
                                                <FontAwesomeIcon className="margin-left-10-px" icon={faPaperPlane} />
                                            </h6> : null}
                                        {emails?.map((event) =>
                                            <div className="column-gap-10-px daily-grid">
                                                <div style={{ backgroundColor: '#3788D8', height: '30px' }} className="time-tag">
                                                    {getTime(event[0].start_date)}
                                                </div>
                                                <span className="bold color-med-grey">{event[0].subject} ({event?.length})</span>
                                            </div>
                                        )}
                                        {ecards?.map((event) =>
                                            <div className="column-gap-10-px daily-grid">
                                                <div style={{ backgroundColor: '#fab43f', height: '30px' }} className="time-tag">
                                                    {getTime(event[0].start_date)}
                                                </div>
                                                <span className="bold color-med-grey">{event[0].subject} ({event?.length})</span>
                                            </div>
                                        )}



                                    </div>


                                </div> */}

                                <div className="graphs grid-container" >
                                    <h5 className="grey">My Users</h5>
                                    <h6 className="color-med-grey">New</h6>

                                    <BarChart
                                        barData={signupData}
                                        barColor={COLORS[1]}
                                        xLabel={'Month'}
                                        maxValue={yMax}
                                        secondBar={agentData}
                                        secondBarColor={COLORS[0]}
                                        labels={[{ title: 'Homeowners', strokeWidth: 10 }, { title: 'Agents', strokeWidth: 10 }]}
                                        screenWidth={windowSize}


                                    />



                                    <h6 style={{ marginTop: '30px' }} className="color-med-grey">Total Homeowners</h6>

                                    <BarChart
                                        barData={[{ y: leadsNum, x: "Homeowners", label: `${leadsNum} homeowner${leadsNum > 1 ? `s` : ''} joined by homeowner referral` }]}
                                        barColor={COLORS[1]}

                                        maxValue={Math.max(Math.max(currentClientsNum, leadsNum), 3)}
                                        secondBar={[{ y: currentClientsNum, x: 'Homeowners', label: `${currentClientsNum} homeowner${currentClientsNum > 1 ? `s` : ''} invited by your agents joined` }]}
                                        secondBarColor={COLORS[0]}
                                        labels={[{ title: 'Leads', strokeWidth: 10 }, { title: 'Clients', strokeWidth: 10 }]}

                                        screenWidth={windowSize}

                                    />


                                </div>
                                <div className="grid-container notes">
                                    <NotepadMobile />
                                </div>


                                <Link className="blog-img-container-landing position-relative" to={`/blog/${blog?.id}`}>
                                    <div className="overflow-hidden border-radius-10-px position-relative aspect-ratio">
                                        <img className="height-width-100-pt object-fit-cover blog-img-landing" src={blog?.img_link} />
                                        <div style={{  backgroundColor: ' rgb(105, 105, 105, .5)', padding: '15px 20px'}} className="blog-link-background position-absolute bottom-0 width-full-pt ">
                                            <span style={{ fontSize: '1.1em' }} className=" z-3 white bold">Check out our latest update <FontAwesomeIcon className="blog-arrow" icon={faArrowRightLong} /> </span>
                                        </div>
                                    </div>
                                </Link>

                            </div>


                        </div >
                    </>
                    :
                    <div className="display-flex justify-content-center align-items-center height-90-vh"><ThreeCircles
                        height={Math.round(window.innerWidth / 10)}
                        width={Math.round(window.innerWidth / 10)}
                        color={COLORS[0]}
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="three-circles-rotating"
                        outerCircleColor=""
                        innerCircleColor=""
                        middleCircleColor=""
                    /></div>
                }



            </>

    );

}

export default BrokerageHome;
