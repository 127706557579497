import React from "react";
import "./adminDashboard.css/adminPopup.component.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { useState, useContext, useEffect, useRef } from "react";
import { UserContext } from "../../../App";
import sqlService from "../../../services/sqlService";
import { faPlus, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import inputConstants from "../../../constants/inputConstants";
import { Modal } from "react-bootstrap";
import MultipleSelection from "../../utility.component/multipleSelection";
import { Spinner } from "react-bootstrap";
import GoogleAutoComplete from "../../utility.component/googleAutoComplete.omponent";
export default function AdminEdit({
  onClose,
  item,
  columns,
  editFunction,
  setRefreshData,
  selectedTable
}) {
  const user = useContext(UserContext);
  const [changes, setChanges] = useState({ id: item.id, address: item.address, city: item.city, state: item.state, zip_code: item.zip_code, nation_wide: item.nation_wide, state_wide: item.state_wide, board: item?.board });
  const [boards, setBoards] = useState([]);
  const [brokerages, setBrokerages] = useState([]);
  const [agents, setAgents] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [addresses, setAddresses] = useState(item.additional_addresses ?? [])
  const [INDUSTRIES, setINDUSTRIES] = useState([]);

  const [selectionOptionsArray, setSelectionOptionsArray] = useState([""]);


  const [requested, setRequested] = useState(false);

  const addressRef = useRef()
  const changesRef = useRef()

  const [canProcessCurrentAddresses, setCanProcessCurrentAddresses] = useState(false);

  const [googlePros, setGooglePros] = useState({});

  const valRef = useRef({});

  const [newAddressKey, setNewAddressKey] = useState(null)
  useEffect(() => {
    if (item.additional_addresses) {
      changes.additional_addresses = []
      let newAddresses = {}
      for (let address of item.additional_addresses) {
        const random = `${Math.random()}`;
        newAddresses[random] = address;
        changes.additional_addresses.push(address)
      }
      setAddresses(newAddresses);
      setCanProcessCurrentAddresses(true)
    }

  }, [])

  useEffect(() => {
    if (!canProcessCurrentAddresses) { return }
    let newGooglePros = {};
    for (let newAddressKey of Object.keys(addresses)) {
      let inputField = document.getElementById(`${newAddressKey}_honeyydo_pros_address_edit`)
      valRef.current = { ...addresses };
      if (!newGooglePros[`${newAddressKey}_honeyydo_pros_address_google_edit`]) {
        newGooglePros[`${newAddressKey}_honeyydo_pros_address_google_edit`] = <GoogleAutoComplete addressKey={`${newAddressKey}_honeyydo_pros_address_edit`} addresses={newAddressKey} address1FieldRef={inputField} callBack={(postcode, city1, country1, state1, address1, oldKey) => {
          runCallBack(postcode, city1, country1, state1, address1, oldKey);
        }} />;

      }
      setGooglePros(newGooglePros);
      inputField.value = addresses[newAddressKey].address;
    }

  }, [canProcessCurrentAddresses]);

  useEffect(() => {
    if (!newAddressKey) {
      return
    }
    let inputField = document.getElementById(`${newAddressKey}_honeyydo_pros_address_edit`)
    valRef.current = { ...addresses };
    if (!googlePros[`${newAddressKey}_honeyydo_pros_address_google_edit`]) {
      setGooglePros({
        ...googlePros,
        [`${newAddressKey}_honeyydo_pros_address_google_edit`]: <GoogleAutoComplete addressKey={`${newAddressKey}_honeyydo_pros_address_edit`} addresses={newAddressKey} address1FieldRef={inputField} callBack={(postcode, city1, country1, state1, address1, oldKey) => {
          runCallBack(postcode, city1, country1, state1, address1, oldKey);
        }} />
      })
    }
  }, [newAddressKey])

  const updateAddress = (key, value, addressKey) => {
    let nAddress = { ...addresses }
    nAddress[addressKey][key] = value;
    let el = document.getElementById(`${addressKey}_honeyydo_pros_address_edit`);
    if (el) {
      el.value = value;
    }
    setAddresses(nAddress)
    updateInput("additional_addresses", Object.values(nAddress))
  }

  function deleteAddress(key) {
    let updated = { ...addresses }
    // updated.splice(index, 1)
    delete updated[key]

    let newGooglePro = {}
    for (let key1 of Object.keys(updated)) {
      let el = document.getElementById(`${key1}_honeyydo_pros_address_edit`);
      if (el) {
        el.value = updated[key1].address;
        valRef.current = { ...addresses };
        if (!newGooglePro[`${key1}_honeyydo_pros_address_google_edit`]) {
          newGooglePro[`${newAddressKey}_honeyydo_pros_address_google`] = <GoogleAutoComplete addressKey={`${key1}_honeyydo_pros_address_edit`} addresses={key1} address1FieldRef={el} callBack={(postcode, city1, country1, state1, address1, oldKey) => {
            runCallBack(postcode, city1, country1, state1, address1, oldKey);
          }} />
        }
      }
    }
    // delete newGooglePro[`${newAddressKey}_honeyydo_pros_address_google`];
    setGooglePros(newGooglePro);
    setAddresses(updated)
    updateInput("additional_addresses", Object.values(updated))

  }


  function updateInput(key, value) {
    let nInput = { ...changes };
    nInput[key] = value;
    console.log(nInput)
    setChanges(nInput);
  }

  const handleChange = (event) => {
    var inputValue = event.target.value;
    if (inputValue && event.target.name === 'phone_number') {
      inputValue = inputValue.replace(/\D/g, '');
    }
    setChanges({
      ...changes,
      [event.target.name]: inputValue,
    });
  };

  const handleEdit = async () => {
    console.log(changes)
    try {
      setRequested(true);
      await editFunction({ user: user, input: changes });
      setRequested(false);
      console.log("Edit successful");
      setRefreshData((refresh) => !refresh);
      onClose();
    } catch (err) {
      setRequested(false);
      console.error("Error editing item", err);
    }
  };

  const handleCheck = (e) => {
    setChanges({ ...changes, [e.target.name]: e.target.checked });
  }

  useEffect(() => {
    console.log(addresses)
    sqlService
      .getIndustryNames()
      .then((data) => {
        const INDUSTRIES = [];
        for (let i = 0; i < data.data.length; i++) {
          INDUSTRIES[i] = data.data[i].type_name;
        }
        setINDUSTRIES(INDUSTRIES);
      })
      .catch((err) => {
        console.log("Didn't get industry names");
        console.log(err);
      });
    sqlService
      .getAdminBoards({ user: user })
      .then((data) => {
        // setBoards(data);

        let arr = data
        var f = []
        for (let i of arr) {
          f.push(i.contact_name)
        }

        setSelectionOptionsArray(f)
      })
      .catch((err) => {
        console.log(err);
      });

    sqlService
      .getAdminBrokerages({ user: user })
      .then((data) => {
        setBrokerages(data);
      })
      .catch((err) => {
        console.log(err);
      });

    sqlService
      .getAdminAgents({ user: user })
      .then((data) => {
        setAgents(data);
      })
      .catch((err) => {
        console.log(err);
      });

  }, [refresh]);

  const handleSelectionMap = (newValue) => {
    updateInput("board", newValue)
  }

  const decodeBoards = (board) => {
    if (!board) {
      return null;
    }
    if (typeof board !== "string") {
      return board;
    }
    let array = board.split(",")
    if (!array && array.length) {
      return null;
    }
    var rs = {}
    for (let item of array) {
      rs[item] = 1;
    }
    console.log("dddddsdfsfs,", rs);
    return rs;
  }

  useEffect(() => {
    changesRef.current = changes
  }, [changes])




  const runCallBack = (postcode, city1, country1, state1, address1, addressesKey) => {
    let inputField = document.getElementById(`${addressesKey}_honeyydo_pros_address_edit`)
    if (inputField) {
      inputField.value = address1;
    }
    if (!addresses) { return }
    let nAddress = { ...valRef.current ?? {} }
    nAddress[addressesKey]["address"] = address1;
    nAddress[addressesKey]["city"] = city1;
    nAddress[addressesKey]["zip_code"] = postcode;
    nAddress[addressesKey]["country"] = country1;
    nAddress[addressesKey]["state"] = state1;
    setAddresses(nAddress)
    valRef.current = nAddress;
  }




  return (
    <>

      <GoogleAutoComplete address1FieldRef={addressRef} callBack={(postcode, city1, country1, state1, address1) => {
        let nInputData = { ...changesRef.current, "zip_code": postcode, "city": city1, "country": country1, "state": state1, "address": address1 }
        console.log(nInputData);
        setChanges(nInputData)
      }} />
      <div>
        {Object.keys(googlePros)?.map(key => googlePros[key])}
      </div>
      <Modal
        show={true}
        hide={onClose}
        size={selectedTable !== 'blogs' && selectedTable !== 'tasks' ? 'lg' : ''}
        style={{ zIndex: '12' }}
      >
        <div className="admin-popup-title">
          <h3>Edit</h3>
          <FontAwesomeIcon
            className="admin-popup-close"
            icon={faCircleXmark}
            onClick={onClose}
          />
        </div>
        <div className="admin-add-popup">
          <div className={selectedTable !== 'blogs' && selectedTable !== 'tasks' ? 'input-grid' : ''}>
            {columns.filter(item => item.field !== "creation_date" && item.field !== "additional_addresses").map((column, index) => (

              <div key={index} className="admin-popup-field">
                <label className="admin-popup-label">{column.header}</label>
                {column.field === "brokerage_contact_name" ? (
                  <select
                    className="admin-popup-dropdown"
                    name={"brokerage"}
                    onChange={handleChange}
                  >
                    {brokerages?.map((brokerage, i) => (
                      <option key={i} value={brokerage.id}>
                        {brokerage.contact_name}
                      </option>
                    ))}
                  </select>
                ) : column.field === "agent_name" ? (
                  <select
                    className="admin-popup-dropdown"
                    name={"agent"}
                    onChange={handleChange}
                  >
                    {agents?.map((agent, i) => (
                      <option key={i} value={agent.id}>
                        {agent.contact_name}
                      </option>
                    ))}
                  </select>
                ) : column.field === "seasons" ? (
                  <select
                    className="admin-popup-dropdown"
                    name={"seasons"}
                    onChange={handleChange}
                  >

                    <option value={"Monthly"}>Monthly</option>
                    <option value={"Quarterly"}>Quarterly</option>
                    <option value={"Biannual"}>Biannual</option>
                    <option value={"Yearly"}>Yearly</option>
                    <option value={"Winter"}>Winter</option>
                    <option value={"Spring"}>Spring</option>
                    <option value={"Summer"}>Summer</option>
                    <option value={"Fall"}>Fall</option>

                  </select>
                )
                  : column.field === "address" ? (
                    <input
                      type="text"
                      className="admin-popup-input"
                      defaultValue={item[column.field]}
                      name={column.field}
                      ref={addressRef}
                      onChange={handleChange}
                    />
                  )
                    : column.field === "industry_name" ? (
                      <select
                        className="admin-popup-dropdown"
                        name={"industry_name"}
                        onChange={handleChange}
                        value={changes?.industry_name ?? item?.industry_name ?? null}
                      >

                        {INDUSTRIES?.map((val, index) => (
                          <option key={index} value={val}>
                            {val}
                          </option>

                        ))}

                      </select>

                    )
                      : column.field === "state" ? (
                        <select
                          className="admin-popup-dropdown"
                          name={"state"}
                          onChange={handleChange}
                          value={changes?.state ?? item?.state ?? null}
                        >
                          {addresses[index]?.country !== "Canada" ?
                            inputConstants?.US_STATES.map((val, index) => (
                              <option key={index} value={val.value}>
                                {val.label}
                              </option>
                            )) : null}
                          {addresses[index]?.country !== "United States" ?
                            inputConstants?.CANDADIAN_PROVINCES.map((val, index) => (
                              <option key={index} value={val.value}>
                                {val.label}
                              </option>
                            )) : null}
                        </select>

                      )
                        : column.field === "board" ? (
                          <MultipleSelection selectionMap={decodeBoards(changes?.board) ?? {}} handleSelectionMap={handleSelectionMap} selectionOptionsArray={selectionOptionsArray ?? [""]} />
                        )
                          :
                          column.field === "country" ? (
                            <select
                              className="admin-popup-dropdown"
                              name={"country"}
                              value={changes?.country ?? item?.country ?? null}
                              onChange={handleChange}

                            >

                              <option value="">Select Country</option>
                              <option key={"countryCanada"} value={"Canada"}>
                                {"Canada"}
                              </option>
                              <option key={"countryUS"} value={"United States"}>
                                {"United States"}
                              </option>
                            </select>

                          )

                            : (
                              <input

                                type="text"
                                className="admin-popup-input"
                                defaultValue={changes[column.field] ?? item[column.field]}
                                value={changes[column.field] ?? item[column.field]}
                                name={column.field}
                                onChange={handleChange}
                              />
                            )}

              </div>

            ))}
          </div>
          {selectedTable === 'pros' ?
            <>
              <div className="display-flex align-items-center margin-10-px">
                <span>Nation Wide? </span>
                <input className="margin-left-15-px" type="checkbox" name={"nation_wide"} checked={changes.nation_wide || false} onChange={(e) => handleCheck(e)}></input>
                <span className="margin-left-15-px">State/Province Wide? </span>
                <input className="margin-left-15-px" type="checkbox" name={"state_wide"} checked={changes.state_wide || false} onChange={(e) => handleCheck(e)}></input>
              </div>
              <div className="margin-auto-lr align-items-center display-flex padding-bottom-20-px" >
                <span>Additional service areas</span>
                <button style={{ marginLeft: '15px' }} className="Tag-Button" onClick={() => { const random = `${Math.random()}`; setAddresses({ ...addresses, [random]: {} }); setNewAddressKey(random) }}>Add <FontAwesomeIcon icon={faPlus} /></button>
              </div>
              {Object.keys(addresses).map(key =>
                <div className="input-grid">

                  <div className="admin-popup-field position-relative">
                    <FontAwesomeIcon onClick={() => deleteAddress(key)} icon={faXmarkCircle} className=" grey position-absolute cursor-pointer" style={{ top: '55%', left: '-20%' }} />

                    <label className="admin-popup-label ">Address</label>


                    <input
                      id={`${key}_honeyydo_pros_address_edit`}
                      className="admin-popup-input"
                      type="text"
                      name="Address"
                      value={addresses[key]?.address ?? null}

                      onChange={(e) => {
                        updateAddress("address", e.currentTarget.value, key);

                      }}
                    />
                  </div>


                  <div className="admin-popup-field position-relative">

                    <label className="admin-popup-label ">City</label>


                    <input
                      className="admin-popup-input"
                      type="text"

                      value={addresses[key]?.city ?? null}

                      onChange={(e) => {
                        updateAddress("city", e.currentTarget.value, key);

                      }}
                    />
                  </div>

                  <div className="admin-popup-field position-relative">

                    <label className="admin-popup-label ">State</label>
                    <select
                      name="state"

                      className='admin-popup-input'
                      value={addresses[key]?.state ?? null}
                      onChange={(e) => {
                        updateAddress("state", e.currentTarget.value, key);

                      }}

                    >
                      {addresses[key]?.country !== "Canada" ?
                        inputConstants?.US_STATES.map((val, index) => (
                          <option key={index} value={val.value}>
                            {val.label}
                          </option>
                        )) : null}
                      {addresses[key]?.country !== "United States" ?
                        inputConstants?.CANDADIAN_PROVINCES.map((val, index) => (
                          <option key={index} value={val.value}>
                            {val.label}
                          </option>
                        )) : null}
                    </select>

                  </div>

                  <div className="admin-popup-field position-relative">

                    <label className="admin-popup-label ">
                      {addresses[key]?.country === "Canada" ? "Postal Code*" : "Zip Code*"}
                    </label>


                    <input
                      className="admin-popup-input"
                      type="text"
                      maxLength={addresses[key]?.country === "Canada" ? "6" : "5"}
                      value={addresses[key]?.zip_code ?? null}

                      onChange={(e) => {
                        updateAddress("zip_code", e.currentTarget.value, key);

                      }}
                    />
                  </div>

                  <div className="admin-popup-field position-relative">

                    <label className="admin-popup-label ">Country</label>
                    <select
                      name="country"

                      className='admin-popup-input'
                      value={addresses[key]?.country ?? null}
                      onChange={(e) => {
                        updateAddress("country", e.currentTarget.value, key);

                      }}

                    >

                      <option value="">Select Country</option>
                      <option key={"countryCanada"} value={"Canada"}>
                        {"Canada"}
                      </option>
                      <option key={"countryUS"} value={"United States"}>
                        {"United States"}
                      </option>
                    </select>

                  </div>



                </div>
              )}

            </>
            : null}
          {requested ?
            <Spinner />
            :
            <div className="admin-popup-button-containers">
              <button className="admin-popup-submit-button" onClick={handleEdit}>
                Submit
              </button>
              <button className="admin-popup-cancel-button" onClick={onClose}>
                Cancel
              </button>
            </div>
          }

        </div>
      </Modal>
    </>
  );
}
