import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import sqlService from "../../../services/sqlService";
import { sanitize } from "dompurify";

export default function BlogPost() {

    const loaded = useRef(false);
    const [blog, setBlog] = useState({});
    const [recentBlogs, setRecentBlogs] = useState([])
    const [relatedBlogs, setRelatedBlogs] = useState([])
    const { id } = useParams();

    useEffect(() => {
        if (!loaded.current) {
            sqlService.getLibraries()
                .then(data => {
                    const blogs = data.data.reverse();
                    const blog = blogs.filter(blog => blog.id === parseInt(id))[0]
                    setBlog(blog)
                    var related = {}
                    if (blog?.tags) {
                        for (let tag of blog?.tags?.split(', ')) {
                            related[tag] = blogs.filter(item => (item.tags?.includes(tag) && item.id !== parseInt(id))).slice(0, 4)
                            if (related[tag].length === 0) {
                                delete related[tag]
                            }
                        }
                    }
                    setRelatedBlogs(related)
                    setRecentBlogs(blogs)

                })
                .catch(err => {
                    console.log(err);
                });
            loaded.current = true;
        }
    }, []);


    return (
        <>
            <div className="Blog-Title">
                <h2 className="heading">{blog?.title}</h2>
                <span>{new Date(blog?.creation_date).toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' })}</span>
            </div>
            <div className="Featured-Blog-Content">
                <div className="Blog-Inner-Content">
                    <div className="Blog-Banner-Photo-Container" >
                        <img className="Blog-Banner-Photo" src={blog?.img_link} alt={blog?.title} />
                    </div>
                    <div className="Blog-Text" dangerouslySetInnerHTML={{
                      __html: sanitize(blog?.content)
                    }}>
                    </div>
                </div>
                <div className="Related-Container">
                    {Object.keys(relatedBlogs).length ?
                        Object.keys(relatedBlogs).map((key, index) =>
                            <div key={index}>
                                <h5 className="grey">More in {key}</h5>
                                <div className="Related-Blogs">
                                    {relatedBlogs[key].filter(blog => blog.id !== parseInt(id)).map((item, index) =>

                                        <Link key={index} to={`/blog/${item.id}`} className="text-decoration-none">
                                            <div className="Blog-Img-Container margin-top-15-px">
                                                <img className="Blog-Img" src={item.img_link} alt={item.title}></img>
                                            </div>

                                            <h6 className="grey">{item.title}</h6>

                                        </Link>



                                    )}
                                </div>

                            </div>

                        )

                        :

                        <div >
                            <h5 className="grey">Latest Posts</h5>
                            <div className="Related-Blogs">
                                {recentBlogs.filter(blog => blog.id !== parseInt(id)).slice(0, 4).map((item, index) =>

                                    <Link key={index} to={`/blog/${item.id}`} className="text-decoration-none">
                                        <div className="Blog-Img-Container margin-top-15-px">
                                            <img className="Blog-Img" src={item.img_link} alt={item.title}></img>
                                        </div>

                                        <h6 className="grey">{item.title}</h6>

                                    </Link>



                                )}
                            </div>

                        </div>
                    }



                </div>
            </div>
        </>
    )
}