import React, { useEffect, useState, useRef } from "react";

import { AiOutlineAlignLeft, AiOutlineAlignCenter, AiOutlineAlignRight } from "react-icons/ai";
import "./ecardsGifts.css"
import { TiDelete } from "react-icons/ti";
import { AiOutlineEdit, AiOutlineCheckCircle } from "react-icons/ai";
import { RiDragMove2Fill } from "react-icons/ri";
import Placeholder2 from "../../../assets/images/Placeholder2-removebg.png"
import sqlEcardsService from "../../../services/sqlEcardService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { UserContext } from "../../../App";
import { useContext } from "react";
import sqlService from "../../../services/sqlService";

import MultipleSelection from "../../utility.component/multipleSelection";

import { renderToString } from 'react-dom/server'
// import html2canvas from "html2canvas";
// import { useScreenshot } from 'use-react-screenshot'
import * as htmlToImage from 'html-to-image';
// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

import DraggerResizer from "./dragResize.component";

import Fireworks from "../../../assets/images/ecard-themes/fireworks.jpg";
import { RiBringToFront } from "react-icons/ri";
import { CgArrangeBack } from "react-icons/cg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ImUnderline, ImBold, ImItalic } from "react-icons/im";
import { faArrowDown, faArrowLeft, faArrowRight, faCirclePlus, faDownload, faEdit, faImage, faSave, faShare, faUpload, faTrash, faCircleXmark, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
// import { PiTextTBold } from "react-icons/pi";
import { RxText } from "react-icons/rx";

import honeydoLogo from "../../../assets/images/Honeyydo logo/Honeyydo-logo.png";
import validateUtility from "../../../functions/validateUtility";
import inputConstants from "../../../constants/inputConstants";
import PopupMessage from "../../utility.component/popupMessage.component/popupMessage.component";

function EcardsGifts() {
    const user = useContext(UserContext);
    const location = useLocation();
    const state = location.state;
    const theme = state?.theme
    const client = state?.client
    state?.date?.setFullYear(new Date().getFullYear())

    // console.log(location);

    const [template, setTemplate] = useState(``);

    const [link, setLink] = useState("https://honeydo.com/")
    const [title, setTitle] = useState("honeydo.com")
    const [text, setText] = useState("Text")
    const [topOffset, setTopOffset] = useState(0);
    const [leftOffset, setLeftOffset] = useState(60);
    const [fontColor, setFontColor] = useState("#000000");
    const [fontFamily, setFontFamily] = useState("Times New Roman");
    const [fontSize, setFontSize] = useState(30);
    const [bold, setBold] = useState(0);
    const [underline, setUnderline] = useState(0);
    const [italic, setItalic] = useState(0);
    // const [currentItemKey, setCurrentItemKey] = useState(0);


    const [image, setImage] = useState(null);
    const [height, setHeight] = useState(100);
    const [width, setWidth] = useState(100);
    const [borderRadius, setBorderRadius] = useState(0);
    const [zIndex, setZIndex] = useState(1);
    const [windowSize, setWindowSize] = React.useState(window.innerWidth);

    const [serverMessage, setServerMessage] = useState(null);

    useEffect(() => {
        window.addEventListener("resize", onWindowResize);

        return () => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, []);

    const onWindowResize = () => {
        setWindowSize(window.innerWidth);
    };

    const currentItemKey = useRef(null);

    const [myTemplates, setMyTemplates] = useState([]);
    const [myTemplatesFilteredByTheme, setMyTemplatesFilteredByTheme] = useState([]);

    const [templateModeEditing, setTemplateModeEditing] = useState(false);

    // const [alignSelected, setAlignSelected] = useState("middle")
    // const htmlItems = useRef({})
    const [htmlItems, setHtmlItems] = useState({});

    const [htmlItemTypeSelected, setHtmlItemTypeSelected] = useState("select");

    // this is to give unique keys for html items 
    const [lastKey, setLastKey] = useState(0);

    const [dragStarted, setDragStarted] = useState(false);

    // const [columnWidth, setColumnWidth] = useState(100);
    // const [rowHeight, setRowHeight] = useState(60);
    const [mainDivBackgroundColor, setMainDivBackgroundColor] = useState("#ffffff");
    const [windowWidth, setWindowWidth] = useState(parseInt(800));
    const [windowHeight, setWindowHeight] = useState(parseInt(500));
    // const [windowWidth, setWindowWidth] = useState(parseInt(window.innerWidth * 0.6));
    // const [windowHeight, setWindowHeight] = useState(parseInt(window.innerHeight * 0.6));
    const [templateTitle, setTemplateTitle] = useState("Template Title")
    const [templateId, setTemplateId] = useState(null);

    const [mousePos, setMousePos] = useState({ x: 20, y: 30 })
    const [mousePosStart, setMousePosStart] = useState(null)

    const [itemIsMoveable, setItemIsMoveable] = useState(false);

    const [caretstartPosition, setCaretStartPosition] = useState(0);

    const [editibleElement, setEditableElement] = useState(false);

    const [stickers, setStickers] = useState([]);
    const [stickerSearchKeyword, setStickerSearchKeyword] = useState('')

    const loaded = useRef(false);
    const [refresh, setRefresh] = useState(false);

    const tempText = useRef("");
    const tempTitle = useRef("");

    const [pageNoneScrollable, setPageNonScrollable] = useState(false);

    const [autoSaving, setAutoSaving] = useState(false);

    const [showItemBar, setShowItemBar] = useState(false);

    const [itemBackgroundColor, setItemBackgroundColor] = useState("transparent");

    const [arrayOfAgentHomeownersEmails, setArrayOfAgentHomeownersEmails] = useState([]);
    const [emailMap, setEmailMap] = useState(client ? { [client]: 1 } : {});

    // const [ image ,takeScreenshot] = useScreenshot()

    const [ecardEmailRecipient, setEcardEmailRecipient] = useState("")

    const [templateThemeId, setTemplateThemeId] = useState(inputConstants.ECARD_THEMES[theme] ?? null);
    const [templatesThemes, setTemplatesThemes] = useState(null);
    const [templatesThemesUniversal, setTemplatesThemesUniversal] = useState(null);
    const [startNewTheme, setStartNewTheme] = useState(false);
    const [templateThemeTitle, setTemplateThemeTitle] = useState(theme ?? "Theme Title")
    const [templateThemeTitleTemp, setTemplateThemeTitleTemp] = useState('')
    const [myTemplatesUniversal, setMyTemplatesUniversal] = useState([]);
    const [templateThemeIdUniversal, setTemplateThemeIdUniversal] = useState(inputConstants.ECARD_THEMES[theme] ?? null);
    const [newTemplate, setNewTemplate] = useState(false);

    const [universalThemeOn, setUniversalThemeOn] = useState(theme ? true : false)

    const [themePopup, setThemePopup] = useState(false)
    const [sharePopup, setSharePopup] = useState(false)
    const [savePopup, setSavePopup] = useState(false)
    const [schedulePopup, setSchedulePopup] = useState(false)
    const [finishedPopup, setFinishedPopup] = useState(false)

    const [fileObject, setFileObject] = useState(null)
    const [sendDate, setSendDate] = useState(state?.date ?? new Date())

    const [saveAsTriggered, setSaveAsTriggered] = useState(false);


    const exportRef = useRef();


    const [widthOfCell, setWidthOfCell] = useState(100);
    const [heightOfCell, setHeightOfCell] = useState(100);
    const [numberOfRows, setNumberOfRows] = useState(parseInt(windowHeight / heightOfCell))
    const [numberOfColumns, setNumberOfColumns] = useState(parseInt(windowWidth / widthOfCell))

    const [gridItemsArray, setGridItemsArray] = useState([...Array(numberOfColumns * numberOfRows)].map(item => ({ "height": heightOfCell, "width": widthOfCell, "children": null })))
    const [currentGridItem, setCurrentGridItem] = useState(null);


    const [deletePopup, setDeletePopup] = useState(false);
    const [ecardAgent, setEcardAgent] = useState(null);
    const [themeAgent, setThemeAgent] = useState(null);

    const fontFamilies = [
        "Arial",
        "sans-serif",
        "Helvetica",
        "Times New Roman",
        "serif",
        "Times",
        "serif",
        "Courier New",
        "Courier",
        "monospace",
        "Georgia",
        "Palatino",
        "Garamond",
        "Bookman",
        "Trebuchet MS",
        "Verdana",
        "Geneva",
        "Impact",
        "Arial Black",
        "Lucida Console",
        "Monaco",
        "Lucida Sans Unicode",
        "Lucida Grande",
        "Tahoma",
        "Century Gothic",
        "MS Sans Serif",
        "MS Serif",
        "Comic Sans MS",
        "cursive",
        // "Apple Chancery",
        // "Zapf Chancery",
        "Bradley Hand",
        "Brush Script MT",
        // "Snell Roundhand",
        // "URW Chancery L",
        // "Apple Chancery",
        "Copperplate",
        "fantasy",
        "Papyrus",
        // "Luminari",
        // "Trattatello"
    ];



    ////////////////////////////////////////////////////////////////////////////////
    const handleEcardEmailRecipient = () => {
        if (ecardEmailRecipient === "") {
            return;
        }
        let t = { ...emailMap };
        t[ecardEmailRecipient] = 1
        setEmailMap(t);
    }

    const handleImgUpload = (event, key) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        if (!validateUtility.validFileSize(file)) {
            setServerMessage({ "message": `File size must be less than ${inputConstants.MAX_FILE_SIZE / 1000000}mb`, "title": "Error" })
            return;
        }

        reader.onload = () => {
            let data = reader.result;
            let t = file.type;
            let type = t.split("/")[1]

            let fileObject = { "imgFile": data, "imgType": type, "imgKey": "img_link", "imgSubfolder": "content/ecard/theme_imgs" }
            setFileObject(fileObject)



        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const getEcardAsImg = async (skipUpload) => {
        return new Promise((resolve, reject) => {
            try {
                htmlToImage.toPng(document.getElementById('honeyydo-main-div-ecard'))
                    .then(function (dataUrl) {
                        if (skipUpload) {
                            resolve(dataUrl)
                        } else {
                            sqlService.uploadEcardImg({ input: { "imgFile": dataUrl, "imgType": "png", "imgSubfolder": "content/ecard/template_imgs" } }).then(d => {
                                // console.log("image added ", d.data.link);
                                resolve(d.data.link)
                                // triggerAutoSaving();
                            }).catch(err => {
                                console.log(err);
                            });
                        }


                        // downloadImage(dataUrl, imageFileName);
                    }).catch(err => {
                        console.log(err);
                    })
            }
            catch (err) {
                reject(err)
            }
        })


    };

    const exportAsImage = (imageFileName) => {
        getEcardAsImg(true).then(d => {
            // console.log(d)
            downloadImage(d, imageFileName);
        }).catch(err => {
            console.log(err)
        })
    };


    // const getImage = () => takeScreenshot(exportRef.current)

    const downloadImage = (blob, fileName) => {
        const fakeLink = window.document.createElement("a");
        fakeLink.style = "display:none;";
        fakeLink.download = fileName;

        fakeLink.href = blob;

        document.body.appendChild(fakeLink);
        fakeLink.click();
        document.body.removeChild(fakeLink);

        fakeLink.remove();
    };

    function filteredTimes(time) {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();


    }





    const handleSelectionMap = (newMap) => {
        setEmailMap(newMap);
    }


    const updateColor = (newColor) => {
        // console.log(newColor);
        setMainDivBackgroundColor(newColor)
    }
    const getLeftOffset = (number) => {
        let n = number;
        return `${n}px`
    }
    const getTopOffset = (number) => {
        let n = number;
        return `${n}px`
    }
    const getFontSize = (number) => {
        return `${number}px`
    }

    const getSizeInPixel = (number) => {
        return `${number}px`
    }

    const getSizeInPercentage = (number) => {
        return `${number}%`
    }



    const addItemToMap = (htmlItemTypeSelected_p, value) => {
        var newKey = lastKey + 1;
        // when I take the htmlItems it is not updated 
        let newHtmlItem = { ...htmlItems };
        var newKeyString = "";
        // console.log(newKey.toString())
        if (htmlItemTypeSelected_p === "text") {
            // console.log("sdfsdf")
            newKeyString = "text_" + newKey.toString() + "_" + `${Math.random().toString()}`;
            //setCurrentItemKey(newKeyString);
            currentItemKey.current = newKeyString;
            // console.log(newKeyString)
            newHtmlItem[newKeyString] = {
                "text": "Text",
                "link": link,
                "leftOffset": 0,
                "topOffset": 0,
                "fontColor": fontColor,
                "fontFamily": fontFamily,
                "fontSize": 22,
                "width": 100,
                "height": 100,
                "borderRadius": 0,
                "itemBackgroundColor": itemBackgroundColor,
                "bold": 0,
                "underline": 0,
                "italic": 0,
                "zIndex": zIndex
            }
        } else if (htmlItemTypeSelected_p === "image") {
            newKeyString = "image_" + newKey.toString() + "_" + `${Math.random().toString()}`
            // setCurrentItemKey(newKeyString);
            currentItemKey.current = newKeyString;
            newHtmlItem[newKeyString] = {
                "image": value ?? image,
                "width": 100,
                "height": 100,
                "leftOffset": 0,
                "topOffset": 0,
                "borderRadius": 0,
                "itemBackgroundColor": itemBackgroundColor,
                "zIndex": zIndex
            }
        }
        else if (htmlItemTypeSelected_p === "sticker") {
            newKeyString = "sticker_" + newKey.toString() + "_" + `${Math.random().toString()}`
            // setCurrentItemKey(newKeyString);
            currentItemKey.current = newKeyString;
            newHtmlItem[newKeyString] = {
                "sticker": value ?? sticker,
                "width": 100,
                "height": 100,
                "leftOffset": 0,
                "topOffset": 0,
                "borderRadius": 0,
                "itemBackgroundColor": itemBackgroundColor,
                "zIndex": zIndex
            }
        }
        // console.log(newKeyString)
        // console.log(".>>>>>>>>>>>>>>addItemToMap>>>>>>>>>>>>>>>>>> ", newHtmlItem);
        if (htmlItemTypeSelected_p !== "" && htmlItemTypeSelected_p !== "select") {
            setLastKey(newKey);
            //htmlItems = newHtmlItem;
            setHtmlItems(newHtmlItem)
        }

    }

    const handleHtmlItemTypeSelected = (selection, value) => {
        let newItem = { ...htmlItems };

        delete newItem[currentItemKey.current];

        // htmlItems = newItem
        setHtmlItems(newItem)
        addItemToMap(selection, value);
        // setCurrentItemKey("");
        currentItemKey.current = null;
        setEditableElement(false)
        // resetItem()
        // triggerAutoSaving();
    }

    let leftOffsetRef = useRef(0);
    let topOffsetRef = useRef(0);
    let heightRef = useRef(50);
    let widthRef = useRef(50);

    const handlePlace = () => {
        if (currentItemKey.current === null) {
            return htmlItems;
        }
        var newItems = { ...htmlItems };

        let html_key = `${templateId ? templateId?.toString() : "tempId"}` + "honeyydo-html-ecard_" + currentItemKey.current;
        let item = document.getElementById(html_key);
        // console.log(item);
        var width = 100;
        var height = 100;
        var leftOffset = 0;
        var topOffset = 0;
        var fontSize = 22;

        if (item) {
            width = item.style.width ?? 100;
            height = item.style.height ?? 100;
            leftOffset = item.style.left ?? 0;
            topOffset = item.style.top ?? 0;
            var fontSizePx = window.getComputedStyle(document.getElementById(`${html_key}_child`)).getPropertyValue('font-size');
            fontSize = getIntFromPixel(fontSizePx) ?? 22;
        }


        if (htmlItemTypeSelected === "text") {
            newItems[currentItemKey.current] = {
                "text": text,
                "link": link,
                "leftOffset": leftOffset,
                "topOffset": topOffset,
                "fontColor": fontColor,
                "fontFamily": fontFamily,
                "fontSize": fontSize,
                "width": width,
                "height": height,
                "borderRadius": borderRadius,
                "itemBackgroundColor": itemBackgroundColor,
                "bold": bold,
                "underline": underline,
                "italic": italic,
                "zIndex": zIndex
            }
        }
        else if (htmlItemTypeSelected === "image") {
            newItems[currentItemKey.current] = {
                "image": image,
                "width": width,
                "height": height,
                "leftOffset": leftOffset,
                "topOffset": topOffset,
                "borderRadius": borderRadius,
                "itemBackgroundColor": itemBackgroundColor,
                "zIndex": zIndex
            }
        }
        else if (htmlItemTypeSelected === "sticker") {

            // console.log(width, height)
            newItems[currentItemKey.current] = {
                "sticker": sticker,
                "width": width,
                "height": height,
                "leftOffset": leftOffset,
                "topOffset": topOffset,
                "borderRadius": borderRadius,
                "itemBackgroundColor": itemBackgroundColor,
                "zIndex": zIndex
            }
        }
        // htmlItems = newItems;
        setEditableElement(false);



        // console.log(".>>>>>>>>>>>>>>handle place>>>>>>>>>>>>>>>>>> ", newItems);
        setHtmlItems(newItems);
        // triggerAutoSaving();
        // setCurrentItemKey("");
        currentItemKey.current = null;
        setEditableElement(false)
        resetItem()
        return newItems;
    }


    const handleCurrentItemKey = (key) => {
        // console.log(">>>>>", key)

        const s = key.replace("honeyydo-html-ecard_", "");

        const sp = s.replace(`${templateId ? templateId?.toString() : "tempId"}`, "");

        // if you click on the item an it is selected already then you deselect it 
        handlePlace();
        if (currentItemKey.current === sp) {
            // resetItem()
            return;
        }
        currentItemKey.current = sp;
        let item = htmlItems[sp];

        // console.log(item)
        setHtmlItemTypeSelected(sp.split("_")[0]);
        setLink(item?.link ?? "https://honeyydo.com")
        setTitle(item?.title ?? "honeyydo.com")
        setText(item?.text ?? "Text")
        setItemBackgroundColor(item.itemBackgroundColor ?? "none");
        setTopOffset(item?.topOffset ?? 0);
        setLeftOffset(item?.leftOffset ?? 0);
        setFontColor(item?.fontColor ?? "#000000");
        setFontFamily(item?.fontFamily ?? "Times New Roman");
        setFontSize(item?.fontSize ?? 30);


        setImage(item?.image ?? null);
        setSticker(item?.sticker ?? "Placeholder2");
        setBorderRadius(item?.borderRadius ?? 0);
        setHeight(item?.height ?? 100);
        setWidth(item?.width ?? 100);
        setBold(item?.bold ?? 0);
        setUnderline(item?.underline ?? 0);
        setItalic(item?.italic ?? 0);
        setZIndex(item?.zIndex ?? 1);

        //setCurrentItemKey(sp);

    }

    const placeCurrentItemKeyIfExist = () => {
        if (currentItemKey.current !== null) {
            handlePlace();
        }
    }



    const drawItem = (key, value) => {
        let string = "";
        let currentItemSelected = false;
        // console.log(currentItemSelected)
        if (key.startsWith("link")) {
            string = "\n" + drawText("link", currentItemSelected, key, currentItemSelected ? text : value?.text, currentItemSelected ? leftOffset : value?.leftOffset, currentItemSelected ? topOffset : value?.topOffset, currentItemSelected ? fontColor : value?.fontColor, currentItemSelected ? fontFamily : value?.fontFamily, currentItemSelected ? fontSize : value?.fontSize, currentItemSelected ? width : value?.width, currentItemSelected ? height : value?.height, currentItemSelected ? image : value?.image, currentItemSelected ? borderRadius : value?.borderRadius, currentItemSelected ? itemBackgroundColor : value?.itemBackgroundColor, currentItemSelected ? title : value?.title, currentItemSelected ? sticker : value?.sticker, currentItemSelected ? link : value?.link);
            //string = "\n" + drawLink(currentItemSelected, key, currentItemSelected ? link : value.link, currentItemSelected ? title : value.title, currentItemSelected ? leftOffset : value.leftOffset, currentItemSelected ? topOffset : value.topOffset, currentItemSelected ? fontColor : value.fontColor, currentItemSelected ? fontFamily : value.fontFamily, currentItemSelected ? fontSize : value.fontSize);
        } else if (key.startsWith("text")) {
            string = "\n" + drawText("text", currentItemSelected, key, currentItemSelected ? text : value?.text, currentItemSelected ? leftOffset : value?.leftOffset, currentItemSelected ? topOffset : value?.topOffset, currentItemSelected ? fontColor : value?.fontColor, currentItemSelected ? fontFamily : value?.fontFamily, currentItemSelected ? fontSize : value?.fontSize, currentItemSelected ? width : value?.width, currentItemSelected ? height : value?.height, currentItemSelected ? image : value?.image, currentItemSelected ? borderRadius : value?.borderRadius, currentItemSelected ? itemBackgroundColor : value?.itemBackgroundColor, currentItemSelected ? title : value?.title, currentItemSelected ? sticker : value?.sticker, currentItemSelected ? link : value?.link);
        }
        else if (key.startsWith("image")) {
            string = "\n" + drawText("image", currentItemSelected, key, currentItemSelected ? text : value?.text, currentItemSelected ? leftOffset : value?.leftOffset, currentItemSelected ? topOffset : value?.topOffset, currentItemSelected ? fontColor : value?.fontColor, currentItemSelected ? fontFamily : value?.fontFamily, currentItemSelected ? fontSize : value?.fontSize, currentItemSelected ? width : value?.width, currentItemSelected ? height : value?.height, currentItemSelected ? image : value?.image, currentItemSelected ? borderRadius : value?.borderRadius, currentItemSelected ? itemBackgroundColor : value?.itemBackgroundColor, currentItemSelected ? title : value?.title, currentItemSelected ? sticker : value?.sticker, currentItemSelected ? link : value?.link);
        }
        // if(editing){
        //     string += "\n" + drawEditior();
        // }
        return string;
    }

    const drawItemReact = (key, value) => {
        let currentItemSelected = currentItemKey.current === key;
        var type = "watermark";

        var item = true;
        if (key.startsWith("text")) {
            type = "text";
        }
        else if (key.startsWith("image")) {
            type = "image";
        }
        else if (key.startsWith("sticker")) {
            type = "sticker";
        }
        else if (key.startsWith("watermark")) {
            item = false;
            type = "watermark";
        }
        if (value.deleted === "1") {
            return;
        }
        if (item) {
            return drawTextReact(type, currentItemSelected, key, currentItemSelected ? text : value?.text, currentItemSelected ? leftOffset : value?.leftOffset, currentItemSelected ? topOffset : value?.topOffset, currentItemSelected ? fontColor : value?.fontColor, currentItemSelected ? fontFamily : value?.fontFamily, currentItemSelected ? fontSize : value?.fontSize, currentItemSelected ? width : value?.width, currentItemSelected ? height : value?.height, currentItemSelected ? image : value?.image, currentItemSelected ? borderRadius : value?.borderRadius, currentItemSelected ? itemBackgroundColor : value?.itemBackgroundColor, currentItemSelected ? title : value?.title, currentItemSelected ? sticker : value?.sticker, currentItemSelected ? link : value?.link, currentItemSelected ? bold : value?.bold, currentItemSelected ? underline : value?.underline, currentItemSelected ? italic : value?.italic, currentItemSelected ? zIndex : value?.zIndex);
        } else {
            return <img alt={`Ecard Honeyydo watermark`} src={honeydoLogo} style={{ width: `120px`, height: `40px`, position: "absolute", left: "3px", bottom: "3px", opacity: 0.93, zIndex: 1000 }} />
        }

        return;
    }



    const drawText = (c_type, c_selected, c_key, c_text, c_leftOffset, c_topOffset, c_color, c_fontFamily, c_fontSize, c_width, c_height, c_image, c_borderRadius, c_itemBackgroundColor, c_title, c_sticker, c_link) => {
        // let temp = template;
        //temp = temp.replace("||", `<a class='' href='${c_link}'><b>${c_title}</b></a> ||`);

        let html_key = "honeyydo-html-ecard_" + c_key;
        var item = "";
        if (c_type === "text") {
            item = `<span class='honeyydo-element' style="position: absolute; font-size:${getFontSize(c_fontSize)}; font-family:${c_fontFamily}; color:${c_color};" id="${html_key}">${c_text}</span>;`
        } else if (c_type === "link") {
            item = `<a class='honeyydo-element' style="position: absolute; font-size:${getFontSize(c_fontSize)}; font-family:${c_fontFamily}; color:${c_color};" id="${html_key}" href='${c_link}'><b>${c_title}</b></a>`;
        }
        else if (c_type === "image") {
            item = `<img alt="Ecard Image ${html_key}" src=${c_image} style="position: absolute; width:${getSizeInPixel(c_width)}; height:${getSizeInPixel(c_height)}; borderRadius:${getSizeInPercentage(c_borderRadius)};" >`;
        }
        else if (c_type === "sticker") {
            item = `<a class='honeyydo-element' style="position: absolute; font-size:${getFontSize(c_fontSize)}; font-family:${c_fontFamily}; color:${c_color};" id="${html_key}" href='${c_link}'><b>${c_title}</b></a>`;
        }
        let FullItem = `
        <div style="left: ${getLeftOffset(c_leftOffset)}; top: ${getTopOffset(c_topOffset)}; border-radius:${c_borderRadius}; background-color:${c_itemBackgroundColor}; overflow:hidden; position: absolute; height:${getSizeInPixel(c_height)}; width:${getSizeInPixel(c_width)};">
         ${item} 
        </div>
        `
        return FullItem;
    }

    const getIntFromPixel = (val) => {
        val = val?.replace("px", "");
        return val ? parseInt(val) : null;
    }
    const getIntFromPercentage = (val) => {
        val = val?.replace("%", "");
        return val ? parseInt(val) : null;
    }

    const drawTextReact = (type, c_selected, c_key, c_text, c_leftOffset, c_topOffset, c_color, c_fontFamily, c_fontSize, c_width, c_height, c_image, c_borderRadius, c_itemBackgroundColor, c_title, c_sticker, c_link, bold, underline, italic, zIndex) => {
        // let temp = template;
        //temp = temp.replace("||", `<a class='' href='${c_link}'><b>${c_title}</b></a> ||`);
        let html_key = `${templateId ? templateId?.toString() : "tempId"}` + "honeyydo-html-ecard_" + c_key;

        // console.log("old", c_width, c_height)
        // console.log("new", width, height)

        var quarter = 1;

        // if (templateModeEditing) {
        return <>
            <DraggerResizer
                classNames={"resizeable"}
                id={html_key}
                style={{
                    border: (c_selected && templateModeEditing) ? "3px black dotted" : "",
                    // borderRadius: c_selected ? getSizeInPercentage(borderRadius) : getSizeInPercentage(c_borderRadius),
                    // background: `${c_selected ? itemBackgroundColor : c_itemBackgroundColor}`,
                    // display: "flex",
                    // alignItems: "center",
                    // justifyContent: "center",
                    // width: c_selected ? width : c_width,
                    // height: c_selected ? height : c_height,
                    // left: c_selected ? leftOffset : c_leftOffset,
                    // top: c_selected ? topOffset : c_topOffset,
                    overflow: type === "text" ? "" : "hidden",
                    position: "absolute",
                    cursor: templateModeEditing ? "pointer" : "",
                    zIndex: zIndex
                }}
                initialValues={{ "width": c_selected ? 100 : c_width, "height": c_selected ? 100 : c_height, "left": c_selected ? leftOffset : c_leftOffset, "top": c_selected ? topOffset : c_topOffset }}
                handleOnClick={() => {
                    handleCurrentItemKey(html_key);

                }}
                onPositionChange={onPositionChange}
                handlePlace={handlePlace}
                onSizeChange={onSizeChange}
                preventEditingExternal={templateModeEditing ? "false" : "true"}
            >
                {type === "text" ?
                    <div id={html_key + "_child"} contenteditable={editibleElement && c_selected && templateModeEditing ? "true" : "false"} onInputCapture={(e) => { console.log(e) }} onInput={e => { if (!templateModeEditing) { return } editElement("text", e.currentTarget.textContent) }} className={`${templateModeEditing ? "cursor-pointer" : ""} display-flex justify-content-center align-items-center `} style={{ width: `${document.getElementById(html_key)?.style?.width ?? getSizeInPixel(c_width)}`, height: `${document.getElementById(html_key)?.style?.height ?? getSizeInPixel(c_height)}`, background: `${c_selected ? itemBackgroundColor : c_itemBackgroundColor}`, fontSize: `${getFontSize(c_fontSize)}`, fontFamily: `${c_fontFamily}`, color: `${c_color}`, borderRadius: `${getSizeInPercentage(c_borderRadius)}`, fontWeight: `${bold === 1 ? "bold" : "normal"}`, textDecoration: `${underline === 1 ? "underline" : "none"}`, fontStyle: `${italic === 1 ? "italic" : ""}` }} ><span>{editibleElement && c_selected ? tempText.current : c_text}</span></div>
                    : null}
                {type === "link" ?
                    <div id={html_key + "_child"} contenteditable={editibleElement && c_selected && templateModeEditing ? "true" : "false"} onInputCapture={(e) => { console.log(e) }} onInput={e => { if (!templateModeEditing) { return } editElement("text", e.currentTarget.textContent) }} className={`overflow-hidden  ${templateModeEditing ? "cursor-pointer" : ""} display-flex justify-content-center align-items-center`} style={{ width: `${document.getElementById(html_key)?.style?.width ?? getSizeInPixel(c_width)}`, height: `${document.getElementById(html_key)?.style?.height ?? getSizeInPixel(c_height)}`, background: `${c_selected ? itemBackgroundColor : c_itemBackgroundColor}`, fontSize: `${getFontSize(c_fontSize)}`, fontFamily: `${c_fontFamily}`, color: `${c_color}`, borderRadius: `${getSizeInPercentage(c_borderRadius)}` }} ><a src={c_link} >{editibleElement && c_selected ? tempTitle.current : c_title}</a></div>
                    : null}
                {type === "image" ?
                    <div id={html_key + "_child"} className={`overflow-hidden ${templateModeEditing ? "cursor-pointer" : ""} display-flex justify-content-center align-items-center`} style={{ width: `${document.getElementById(html_key)?.style?.width ?? getSizeInPixel(c_width)}`, height: `${document.getElementById(html_key)?.style?.height ?? getSizeInPixel(c_height)}`, background: `${c_selected ? itemBackgroundColor : c_itemBackgroundColor}`, fontSize: `${getFontSize(c_fontSize)}`, fontFamily: `${c_fontFamily}`, color: `${c_color}`, borderRadius: `${getSizeInPercentage(c_borderRadius)}` }} >
                        <img alt={`Ecard Image ${html_key}`} src={c_image} style={{ width: `${document.getElementById(html_key)?.style?.width ?? getSizeInPixel(c_width)}`, height: `${document.getElementById(html_key)?.style?.height ?? getSizeInPixel(c_height)}`, borderRadius: `${getSizeInPercentage(c_borderRadius)}` }} />
                    </div>
                    : null}
                {type === "sticker" ?
                    <div id={html_key + "_child"} className={`overflow-hidden ${templateModeEditing ? "cursor-pointer" : ""} display-flex justify-content-center align-items-center`} style={{ width: `${document.getElementById(html_key)?.style?.width ?? getSizeInPixel(c_width)}`, height: `${document.getElementById(html_key)?.style?.height ?? getSizeInPixel(c_height)}`, background: `${c_selected ? itemBackgroundColor : c_itemBackgroundColor}`, fontSize: `${getFontSize(c_fontSize)}`, fontFamily: `${c_fontFamily}`, color: `${c_color}`, borderRadius: `${getSizeInPercentage(c_borderRadius)}` }} >
                        <img alt={`Ecard Sticker ${html_key}`} src={c_sticker} style={{ width: `${document.getElementById(html_key)?.style?.width ?? getSizeInPixel(c_width)}`, height: `${document.getElementById(html_key)?.style?.height ?? getSizeInPixel(c_height)}`, borderRadius: `${getSizeInPercentage(c_borderRadius)}` }} />
                    </div>
                    : null}
            </DraggerResizer >
        </>
    }


    const handleItemChange = (field, newValue) => {
        setHtmlItems({ ...htmlItems, [currentItemKey.current]: { ...htmlItems[currentItemKey.current], [field]: newValue } });
    }

    const itemBar = <div style={{ minWidth: '275px' }} className="display-flex flex-wrap position-absolute width-240-px custom-border-3 column-gap-5-px row-gap-5-px align-items-center justify-content-center background1-transparent">

        {htmlItemTypeSelected === "text" ?

            <>

                <div className="display-flex column-gap-5-px row-gap-5-px align-items-center justify-content-center">
                    <div style={{ minWidth: '126px' }} className="display-flex flex-direction-column">
                        <div className="display-flex flex-wrap align-items-center justify-content-center">
                            <span className="font-size-14-px margin-right-5-px">Border Radius</span>
                            <span className="font-size-14-px">{borderRadius}%</span>
                        </div>
                        <input type="range" min="0" max={50} value={borderRadius} className="slider" onChange={(e) => { handleItemChange("borderRadius", getIntFromPercentage(e.target.value)); setBorderRadius(e.target.value) }} onTouchStart={(e) => { addClass(document.body, "overflow-hidden"); }} onTouchEnd={(e) => { removeClass(document.body, "overflow-hidden"); }} disabled={!templateModeEditing} />
                    </div>

                    {/* {curr} */}

                    <div style={{ minWidth: '126px' }} className={`display-flex flex-direction-column ${htmlItemTypeSelected === "text" ? "" : "visibility-hidden display-none"}`}>
                        <div className="display-flex flex-wrap align-items-center justify-content-center">
                            <span className="font-size-14-px margin-right-5-px">Font Size</span>
                            <span className="font-size-14-px">{fontSize}</span>
                        </div>
                        <input type="range" min="1" max="100" value={fontSize} className="slider" onChange={(e) => { handleItemChange("fontSize", parseInt(e.target.value)); setFontSize(e.target.value); }} onTouchStart={(e) => { addClass(document.body, "overflow-hidden"); }} onTouchEnd={(e) => { removeClass(document.body, "overflow-hidden"); }} disabled={!templateModeEditing} />
                    </div>
                </div>

                <div className="display-flex column-gap-5-px row-gap-5-px align-items-center justify-content-center">
                    <div className="display-flex flex-wrap align-items-center justify-content-center">
                        <span onClick={() => { handleItemChange("underline", underline === 0 ? 1 : 0); setUnderline(underline === 0 ? 1 : 0); }} className={`font-size-14-px margin-right-5-px cursor-pointer ${underline === 1 ? "background-highlight" : ""}`}><ImUnderline /></span>
                        <span onClick={() => { handleItemChange("bold", bold === 0 ? 1 : 0); setBold(bold === 0 ? 1 : 0) }} className={`font-size-14-px margin-right-5-px cursor-pointer ${bold === 1 ? "background-highlight" : ""}`}><ImBold /></span>
                        <span onClick={() => { handleItemChange("italic", italic === 0 ? 1 : 0); setItalic(italic === 0 ? 1 : 0) }} className={`font-size-14-px margin-right-5-px cursor-pointer ${italic === 1 ? "background-highlight" : ""}`}><ImItalic /></span>
                    </div>
                    <div className={`display-flex column-gap-5-px row-gap-5-px ${htmlItemTypeSelected === "text" ? "" : "visibility-hidden display-none"}`}>
                        <span className="font-size-14-px">Color</span>
                        <input className="width-20-px" type="color" value={fontColor} onChange={(e) => { handleItemChange("fontColor", e.target.value); setFontColor(e.target.value); }} onTouchStart={(e) => { addClass(document.body, "overflow-hidden"); }} onTouchEnd={(e) => { removeClass(document.body, "overflow-hidden"); }} disabled={!templateModeEditing} />
                        <span onClick={() => { handleItemChange("fontColor", "transparent"); setFontColor("transparent") }} className="honeyydo-ecard-icon"><TiDelete /></span>
                    </div>

                    <div className="display-flex column-gap-5-px row-gap-5-px">
                        <span className="font-size-14-px">Background</span>
                        <input className="width-20-px" type="color" value={itemBackgroundColor} onChange={(e) => { handleItemChange("itemBackgroundColor", e.target.value); setItemBackgroundColor(e.target.value); }} onTouchStart={(e) => { addClass(document.body, "overflow-hidden"); }} onTouchEnd={(e) => { removeClass(document.body, "overflow-hidden"); }} disabled={!templateModeEditing} />
                        <span onClick={() => { handleItemChange("itemBackgroundColor", "none"); setItemBackgroundColor("none") }} className="honeyydo-ecard-icon"><TiDelete /></span>

                    </div>
                </div>
            </>
            :
            <div style={{ minWidth: '126px' }} className="display-flex flex-direction-column">
                <div className="display-flex flex-wrap align-items-center justify-content-center">
                    <span className="font-size-14-px margin-right-5-px">Border Radius</span>
                    <span className="font-size-14-px">{borderRadius}%</span>
                </div>
                <input type="range" min="0" max={50} value={borderRadius} className="slider" onChange={(e) => { handleItemChange("borderRadius", getIntFromPercentage(e.target.value)); setBorderRadius(e.target.value); }} onTouchStart={(e) => { addClass(document.body, "overflow-hidden"); }} onTouchEnd={(e) => { removeClass(document.body, "overflow-hidden"); }} disabled={!templateModeEditing} />
            </div>

        }

        <div className={`${htmlItemTypeSelected === "text" ? "" : "visibility-hidden display-none"}`}>

            {/* <FontPicker
                apiKey={process.env.REACT_APP_GOOGLE_FONTS}
                activeFontFamily={fontFamily}
                onChange={(nextFont) => { console.log("dsf"); if (templateModeEditing) { handleItemChange("fontFamily", nextFont.family); setFontFamily(nextFont.family) } }}
            /> */}

            <select
                className="admin-popup-dropdown"
                value={fontFamily}
                name={"agent"}
                onChange={(e) => {
                    handleItemChange("fontFamily", e.target.value);
                    setFontFamily(e.target.value);
                }}
            >
                {fontFamilies?.map((item, i) => (
                    <option style={{ fontFamily: `${item}` }} key={i} value={item}>
                        <span>{item}</span>
                    </option>
                ))}
            </select>


        </div>


        {["link", "text", "image"].includes(htmlItemTypeSelected) ?

            <div className="display-flex flex-wrap align-items-center justify-content-center column-gap-10-px row-gap-10-px">
                {
                    htmlItemTypeSelected && htmlItemTypeSelected === "text" ?
                        <div>
                            <input style={{ backgroundColor: 'white', width: '200px', fontSize: '15px' }} className=" height-25-px card-input__input" onKeyUp={(e) => { handleElementKeyup(e) }} placeholder="text" type="text" value={text} onChange={(e) => { handleItemChange("text", e.currentTarget.value); setText(e.currentTarget.value); tempText.current = e.currentTarget.value; }} />
                        </div>
                        : null
                }
                {
                    htmlItemTypeSelected && htmlItemTypeSelected === "image" ?
                        <div>
                            <label className='imglabels1' htmlFor="honeyydo-ecard-imageUpload">
                                <img className='imglabels1' src={image || Placeholder2} alt="Placeholder" />
                            </label>
                            <input className='display-none' type="file" id="honeyydo-ecard-imageUpload" accept="image/*" onChange={(e) => { handleImageChange(e) }} />
                        </div>
                        : null
                }

            </div> : null}

        <div className="display-flex flex-wrap align-items-center justify-content-center column-gap-10-px row-gap-10-px">
            <span onClick={() => { handleZIndex("back"); }} className={`font-size-14-px margin-right-5-px cursor-pointer`}>Send to Back<CgArrangeBack /></span>
            <span onClick={() => { handleZIndex("front"); }} className={`font-size-14-px margin-right-5-px cursor-pointer`}>Bring to Front<RiBringToFront /></span>
        </div>


    </div>


    const UserInfo = {
        name: "geore",
        email: "nice"
    }

    const handleZIndex = (towards) => {
        var newHtmlItems = { ...htmlItems };
        var highesetZIndex = 0;
        var lowestZIndex = 0;
        for (let [key, val] of Object.entries(htmlItems)) {
            if (towards === "front" && val.zIndex > highesetZIndex) {
                highesetZIndex = val.zIndex;
            }
            else if (towards === "back" && val.zIndex < lowestZIndex) {
                lowestZIndex = val.zIndex;
            }
        }
        for (let [key, val] of Object.entries(htmlItems)) {
            if (key === currentItemKey.current) {
                if (towards === "front") {
                    newHtmlItems[key]["zIndex"] = highesetZIndex + 1;
                }
                else if (towards === "back") {
                    newHtmlItems[key]["zIndex"] = lowestZIndex - 1;
                }
            }
            // else if ((towards === "front" && val.zIndex === 2) || (towards === "back" && val.zIndex === 0)) {
            //     newHtmlItems[key]["zIndex"] = 1;
            // }
        }
        setHtmlItems(newHtmlItems);

        if (towards === "front") {
            handleItemChange("zIndex", 2);
            setZIndex(2);
        }
        else if (towards === "back") {
            handleItemChange("zIndex", 0);
            setZIndex(0);
        }


    }

    function exportUserInfo(text) {
        // const fileData = JSON.stringify(userInfo);
        const blob = new Blob([text], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "user-info.html";
        link.href = url;
        link.click();
    }

    const handleElementKeyup = (e) => {
        // console.log("sdf")
        // console.log(e.target.selectionStart)
        setCaretStartPosition(e.target.selectionStart);
    }
    const handleEdit = () => {
        setEditableElement(true);
        var el = document.getElementById("honeyydo-html-ecard_" + currentItemKey.current);
        el.focus()

    }

    async function deleteItem() {
        var pass = true;
        var deleted = false;
        if (htmlItemTypeSelected === "image") {
            // deleted = await handleImageDelete(image);
            // console.log("deleted", deleted)

            await sqlService.deleteAwsImage({ input: { "link": image, "imgSubfolder": "content/ecard/ecard_imgs" } }).then(d => {
                console.log("deleted")
                pass = true;
            }).catch(err => {
                console.log(err);
                pass = false;
            });
        }
        if (!pass) {
            return;
        }

        // setCurrentItemKey("");

        setEditableElement(false)
        // resetItem()
        let temp = JSON.parse(JSON.stringify(htmlItems));
        // console.log(">>>>><><>>>< ", JSON.parse(JSON.stringify(temp)));
        temp[currentItemKey.current]["deleted"] = "1"
        // resetItem()
        // console.log(">>>>><><>>>ccccc< ", temp);
        // htmlItems = temp;
        currentItemKey.current = null;
        setHtmlItems(temp)
        // triggerAutoSaving();
    }

    const editElement = (key, text) => {
        // setCaret1();
        switch (key) {
            case "text":
                setText(text);
                break;
            case "title":
                setTitle(text);
                break;
            default:
                break;
        }
        // setCaret1();
        // setCaretStartPosition(text.length)

    }


    const drawTemplate = () => {
        let string = "";
        // console.log("drawing")
        for (let [key, value] of Object.entries(htmlItems)) {
            string += drawItem(key, value)
        }

        let newTemplate = `<!DOCTYPE html>
        <html>
        <head>
        <style>
        body,h1,h2{font-family: "Raleway", sans-serif}
        body, html {height: 100%}
        p {line-height: 2}
        .green-background{
            background-color: green;
        }
        .display-flex-html{
            display: flex;
        }
        .justify-content-left-html{
            justify-content: left;
        }


        </style>
        </head>
        <body>
            <div style="position:relative; border: 2px green solid; width: ${windowWidth}; height: ${windowHeight}; background-color: ${mainDivBackgroundColor}; overflow:hidden;">
                ${string}
            </div>
        </body>
        </html>
        `

        // console.log(newTemplate);
        return newTemplate;
        // setTemplate(newTemplate);
    }


    const drawTemplateWithGrid = (link) => {
        let string = "";
        // console.log("drawing")
        for (let [key, value] of Object.entries(htmlItems)) {
            string += drawItem(key, value)
        }

        let newTemplate = `
            <div style="position:relative; border: 2px green solid; width: ${windowWidth}; height: ${windowHeight};  background-image: url("${link}")|none|initial|inherit; overflow:hidden;">
                <div style="display: "grid"; grid-template-columns: "${getGridTemplateColumns()}"; ">
                    ${drawGrid()}
                </div>
            </div>
        `

        // console.log(newTemplate);
        return newTemplate;
        // setTemplate(newTemplate);
    }

    const drawGrid = () => {
        var txt = "";
        if (gridItemsArray.length > 0) {
            for (let item of gridItemsArray) {
                txt += `<div style="z-index: 100; text-align: "center"; height: ${getSizeInPixel(item.height)}; width: ${getSizeInPixel(item.width)};">${item.children ?? ""}</div>`
            }
        }
        return txt;
    }


    const handleMainClick = (e) => {
        if (e.target.id === "honeyydo-main-div-ecard") {
            handlePlace();
        }
    }

    const loadedStickers = useRef(false);
    const currentThemeTitle = useRef("");
    useEffect(() => {
        if (templateModeEditing && stickerSearchKeyword !== "" && currentThemeTitle.current !== templateThemeTitle) {
            currentThemeTitle.current = templateThemeTitle;
            console.log("loading stickers ...", templateModeEditing);
            loadedStickers.current = true;
            console.log("stickerSearchKeyword", stickerSearchKeyword)
            sqlEcardsService.getStickersByKeyworkd({ "user": user, "input": { "keyword": parseStickerSearchKeyword(stickerSearchKeyword) } }).then(data => {
                setStickers(data.data);
            }).catch(err => {
                console.log(err)
            });
        }


    }, [templateThemeTitle, templateModeEditing])


    const loadedHomeowners = useRef(false);
    const [agentHomeowners, setAgentHomeOwners] = useState([]);
    useEffect(() => {
        if (!loadedHomeowners.current) {
            loadedHomeowners.current = true;
            sqlService.getHomeowners({}).then(data => {
                let homeowners = data.data;
                setAgentHomeOwners(homeowners);
                let homeownersEmail = homeowners.map(item => item.email);
                // console.log(homeownersEmail);
                setArrayOfAgentHomeownersEmails([...arrayOfAgentHomeownersEmails, ...homeownersEmail]);
            }).catch(err => {
                console.log(err)
            });
        }


    }, [])

    useEffect(() => {
        // addClass(document.getElementById("font-picker"), "width-150-px")

        // console.log(
        //     widthOfCell
        //     , heightOfCell
        //     , numberOfRows
        //     , numberOfColumns
        // )


        if (!loaded.current) {
            loaded.current = true;


            sqlEcardsService.getMyTemplatesThemesUniversal({ "user": user }).then(data => {
                // console.log(data)
                setTemplatesThemesUniversal(data);
            }).catch(err => {
                console.log(err)
            });

            sqlEcardsService.getMyTemplatesUniversal({ "user": user }).then(data => {
                // console.log(data)
                if (Array.isArray(data)) {
                    data = data.map(item => ({ ...item, "isUniversal": true }));
                }
                setMyTemplatesUniversal(data);
            }).catch(err => {
                console.log(err)
            });

            sqlEcardsService.getMyTemplatesThemes({ "user": user }).then(data => {
                // console.log(data)
                setTemplatesThemes(data);
            }).catch(err => {
                console.log(err)
            });

            sqlEcardsService.getMyTemplates({ "user": user }).then(data => {
                // console.log(data)
                setMyTemplates(data);

            }).catch(err => {
                console.log(err)
            });
        }
    }, [refresh])

    useEffect(() => {
        // addClass(document.getElementById("font-picker"), "width-150-px")
        // console.log(htmlItems)
    }, [htmlItems])


    const [sticker, setSticker] = useState("Placeholder2")

    const handleStickerClick = (id) => {
        // placeCurrentItemKeyIfExist();
        // console.log(id)
        setSticker(id);
        setHtmlItemTypeSelected("sticker")
        handleHtmlItemTypeSelected("sticker", id)
    }

    const getNumFromPx = (string) => {
        let ret = string.replace("px", "")
        return parseInt(ret);
    }

    async function createNewTheme() {
        var img_link = null
        if (fileObject) {
            await sqlService.uploadEcardImg({ input: fileObject }).then(d => {
                // console.log("image added ", d.data.link);
                img_link = d.data.link

                // triggerAutoSaving();
            }).catch(err => {
                console.log(err);
            });
        }
        sqlEcardsService.saveTheme({ "input": { id: templateThemeId, title: templateThemeTitleTemp, img: img_link } }).then(data => {


            setTemplateThemeTitleTemp('');
            setTemplateThemeTitle(templateThemeTitleTemp)
            setThemePopup(false);
            setFileObject(null)
            sqlEcardsService.getMyTemplatesThemes({ "user": user }).then(data => {
                // console.log(data)
                setTemplatesThemes(data);
            }).catch(err => {
                console.log(err)
            });
            if (user?.role_id === 0) {
                sqlEcardsService.getMyTemplatesThemesUniversal({ "user": user }).then(data => {
                    console.log(data)
                    setTemplatesThemesUniversal(data);
                }).catch(err => {
                    console.log(err)
                });
            }

        }).catch(err => {
            console.log(err)
        })

    }

    async function handleDeleteEcard() {
        sqlEcardsService.deleteEcard({ user: user, input: { ecardId: templateId } }).then(data => {
            // console.log(data)
            sqlEcardsService.getMyTemplates({ "user": user }).then(data => {
                // console.log(data)
                setMyTemplates(data);
            }).catch(err => {
                console.log(err)
            });
            sqlEcardsService.getMyTemplatesUniversal({ "user": user }).then(data => {
                // console.log(data)
                if (Array.isArray(data)) {
                    data = data.map(item => ({ ...item, "isUniversal": true }));
                }
                setMyTemplatesUniversal(data);
            }).catch(err => {
                console.log(err)
            });
            setTemplateId(null);
            resetTemplate();
            resetItem();
        }).catch(err => {
            console.log(err)
        });
    }


    async function handleDeleteEcardTheme() {
        sqlEcardsService.deleteEcardTheme({ user: user, input: { themeId: templateThemeId } }).then(data => {
            // console.log(data)

            sqlEcardsService.getMyTemplatesUniversal({ "user": user }).then(data => {
                // console.log(data)
                if (Array.isArray(data)) {
                    data = data.map(item => ({ ...item, "isUniversal": true }));
                }
                setMyTemplatesUniversal(data);
            }).catch(err => {
                console.log(err)
            });

            sqlEcardsService.getMyTemplatesThemesUniversal({ "user": user }).then(data => {
                // console.log(data)
                setTemplatesThemesUniversal(data);
            }).catch(err => {
                console.log(err)
            });

            sqlEcardsService.getMyTemplatesThemes({ "user": user }).then(data => {
                // console.log(data)
                setTemplatesThemes(data);
            }).catch(err => {
                console.log(err)
            });

            sqlEcardsService.getMyTemplates({ "user": user }).then(data => {
                // console.log(data)
                setMyTemplates(data);
            }).catch(err => {
                console.log(err)
            });
            setTemplateThemeId(null);
            setTemplateId(null);
            resetTemplate();
            resetItem();
        }).catch(err => {
            console.log(err)
        });
    }

    async function saveTemplate(finishedEditing, overwrite) {
        let newItems = handlePlace();
        // console.log("newItems", newItems)
        if (!newItems) { return }
        // console.log(universalThemeOn)
        var ecardImg = null;
        await getEcardAsImg().then(d => {
            // console.log(d)
            ecardImg = d;

        }).catch(err => {
            console.log(err)
        })
        // console.log("ecard", ecardImg)

        var temp = { ...newItems }
        // console.log(temp);
        for (let [key, value] of Object.entries(newItems)) {
            // console.log(key)
            let item = document.getElementById(`${templateId ? templateId?.toString() : "tempId"}` + "honeyydo-html-ecard_" + key)
            if (item) {
                // console.log(item);
                temp[key]["width"] = getNumFromPx(item.style.width);
                temp[key]["height"] = getNumFromPx(item.style.height);
                temp[key]["leftOffset"] = getNumFromPx(item.style.left);
                temp[key]["topOffset"] = getNumFromPx(item.style.top);
            }
            if (key.startsWith("text")) {
                temp[key]["text"] = value.text.replace("'", "''");
            }
            if (value?.deleted === "1") {
                delete temp[key]
            }
        }

        // console.log(temp)

        setAutoSaving(true);
        let template = {
            "mainDivBackgroundColor": mainDivBackgroundColor,
            "windowWidth": windowWidth,
            "windowHeight": windowHeight,
            "htmlItems": temp,
            "title": templateTitle ?? "",
            "id": templateId,
            "finished_editing": finishedEditing ? 1 : 0,
            "image": ecardImg,
            "universal": universalThemeOn ? 1 : 0
        }
        console.log("template ===> ", template, "overwrite", overwrite);
        let theme = {
            // This is the thing I changed that will maybe break something--what you had is commented out
            // "id": universalThemeOn ? null : templateThemeId,
            "id": universalThemeOn ? templateThemeIdUniversal : templateThemeId,
            "title": templateThemeTitle ?? "Untitled"
        }
        // console.log("ugh", universalThemeOn)
        // console.log("theme", theme)
        // console.log("saving this template id", templateId);
        // console.log("template is saving ======================>", template);
        await sqlEcardsService.saveTemplate({ "user": user, "input": { "template": template, "theme": theme, "overwrite": overwrite } }).then(data => {

            if (finishedEditing) {
                setShowItemBar(false);
                sqlEcardsService.getMyTemplatesUniversal({ "user": user }).then(data => {
                    // console.log(data)
                    if (Array.isArray(data)) {
                        data = data.map(item => ({ ...item, "isUniversal": true }));
                    }
                    setMyTemplatesUniversal(data);
                }).catch(err => {
                    console.log(err)
                });
                sqlEcardsService.getMyTemplates({ "user": user }).then(data => {
                    // console.log(data)
                    setMyTemplates(data);
                }).catch(err => {
                    console.log(err)
                });
            }
            // console.log("saved", data)
            setTemplateId(parseInt(data?.templateId));
            setTemplateThemeId(parseInt(data?.themeId));
            setAutoSaving(false);

        }).catch(err => {
            console.log(err)
        })
        setSaveAsTriggered(false);
    }

    const stickerSearchKeywordRef = useRef("");
    const handleActiveTemplateTheme = async (template) => {
        setTemplateThemeIdUniversal(null);
        setTemplateThemeId(template.id);
        setUniversalThemeOn(false);
        setTemplateThemeTitle(template.title)
        setStickerSearchKeyword(template.title)
        stickerSearchKeywordRef.current = template.title;
        setThemeAgent(template.agent);
    }

    const handleActiveTemplateThemeUniversal = async (template) => {
        // console.log(template)
        setTemplateThemeId(template.id);
        setTemplateThemeIdUniversal(template.id);
        setUniversalThemeOn(true);
        setTemplateThemeTitle(template.title)
        setStickerSearchKeyword(template.title)
        stickerSearchKeywordRef.current = template.title;
        setThemeAgent(template.agent);
        // console.log("effff", template.id)


    }

    const handleActiveTemplate = async (template) => {
        // if (templateModeEditing) {
        //     await saveTemplate()
        // }

        // console.log(template);

        setMainDivBackgroundColor(template.mainDivBackgroundColor);
        setWindowWidth(template.windowWidth);
        setWindowHeight(template.windowHeight);
        let htmlItemsN = JSON.parse(template.htmlItems);
        // console.log(">>>>>>>>html Items", htmlItemsN, Object.keys(htmlItemsN).length)
        setHtmlItems(htmlItemsN);
        setLastKey(Object.keys(htmlItemsN).length)
        // universal templates should be saved with no idea so that they can be stored in the backend 
        if (!template?.isUniversal) {
            console.log("not universal =====>");
            setTemplateId(template.id);
        } else {
            setTemplateId(null);
            console.log("is universal")
            setNewTemplate(true);
        }
        setTemplateTitle(template.title);
        setEcardAgent(template.agent);
        if (universalThemeOn) {
            setTemplateThemeIdUniversal(template.themeId)
        } else {
            setTemplateThemeId(template.themeId)
        }
    }

    const parseStickerSearchKeyword = (word) => {
        // let r = word.replace(" ", "");
        return word;
    }

    const handleStickerSearch = () => {
        console.log("searching stickers =======>")
        sqlEcardsService.getStickersByKeyworkd({ "user": user, "input": { "keyword": parseStickerSearchKeyword(stickerSearchKeyword) } }).then(data => {
            // console.log(data.data)
            setStickers(data.data);
        }).catch(err => {
            console.log(err)
        });

    }


    function hasClass(ele, cls) {
        return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
    }

    function addClass(ele, cls) {
        if (!hasClass(ele, cls)) ele.className += " " + cls;
    }

    function removeClass(ele, cls) {
        if (hasClass(ele, cls)) {
            var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
            ele.className = ele.className.replace(reg, ' ');
        }
    }


    const handleImageChange = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        console.log(file.size)
        if (!validateUtility.validFileSize(file)) {
            setServerMessage({ "message": `File size must be less than ${inputConstants.MAX_FILE_SIZE / 1000000}mb`, "title": "Error" })
            return;
        }
        document.getElementById("honeyydo-ecard-imageUpload-hidden").value = '';
        var pass = true;
        if (image) {
            // deleted = await handleImageDelete(image);
            // console.log("deleted", deleted)

            await sqlService.deleteAwsImage({ input: { "link": image, "imgSubfolder": "content/ecard/ecard_imgs" } }).then(d => {
                console.log("deleted")
                pass = true;
            }).catch(err => {
                console.log(err);
                pass = false;
            });
        }
        if (!pass) {
            return;
        }


        reader.onload = async () => {
            let data = reader.result;
            let t = file.type;
            let type = t.split("/")[1];
            sqlService.uploadEcardImg({ input: { "imgFile": data, "imgType": type, "imgSubfolder": "content/ecard/ecard_imgs" } }).then(d => {
                let src = d.data.link;
                if (currentItemKey.current !== null) {
                    setImage(src)
                    handleItemChange("image", src);
                } else {
                    handleHtmlItemTypeSelected("image", src);
                }

            }).catch(err => {
                console.log(err);
            });
            // await convertToBase64(data, type, async function(data){
            //   console.log(data)
            // })
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const allowAutoSaving = useRef("true");

    const handleAllowAutoSaving = () => {
        setTimeout(() => {
            allowAutoSaving.current = "true";
        }, 3000);
    }

    // auto saving will be triggered only once every 6 seconds
    const triggerAutoSaving = () => {
        if (allowAutoSaving.current === "true") {
            allowAutoSaving.current = "false";
            setTimeout(() => {
                if (!autoSaving) {
                    saveTemplate()
                    handleAllowAutoSaving()
                }
            }, 3000)
        }

    }


    const handleDownloadEcard = () => {
        exportAsImage("honeydo-ecard");
        // exportUserInfo(drawTemplate())
    }

    const handleStartNewTemplate = () => {
        resetItem()
        resetTemplate()
        setTemplateModeEditing(true);
        setNewTemplate(true)
    }

    // const handleStartNewTemplateTheme = () => {
    //     setStartNewTheme(true)
    // }



    const resetTemplate = () => {
        setLastKey(0);
        setHtmlItems({
            "watermark": {
                "image": "https://honeyydo.s3.us-east-2.amazonaws.com/other/Honeyydo-logo.png",
                "width": 150,
                "height": 40,
                "leftOffset": 10,
                "topOffset": 452,
                "borderRadius": 0,
                "itemBackgroundColor": "item"
            }
        });
        setTemplateId(null);
        setTemplateTitle("Template Title");
        setMainDivBackgroundColor("#fafafa");
        // setWindowHeight(parseInt(window.innerHeight * 0.6))
        // setWindowWidth(parseInt(window.innerWidth * 0.6))
        setWindowHeight(parseInt(500))
        setWindowWidth(parseInt(800))

    }

    const resetItem = () => {
        setFontColor("#000000");
        setFontFamily("Times New Roman");
        setFontSize(15);

        setBorderRadius(0);
        setHeight(100);
        setWidth(100);
        setItemBackgroundColor("none");
        //setCurrentItemKey("");
        currentItemKey.current = null;
        setHtmlItemTypeSelected("select");
        setLink("https://honeyydo.com/");
        setTitle("honeyydo.com");
        setText("Text");
        setItemBackgroundColor("item");
        setImage(null);
        setSticker("Placeholder2");
        setBold(0);
        setUnderline(0);
        setItalic(0);
        setZIndex(1);
        tempText.current = "Text";
        tempTitle.current = "honeyydo.com";
    }

    const getReactComponentToRawHtml = (component) => {
        renderToString(component)
    }

    const handleSendEcard = async () => {
        if (Object.keys(emailMap).length === 0) {
            return;
        }
        getEcardAsImg().then(link => {
            // console.log(link)
            let template = drawTemplateWithGrid(link);
            // console.log(template);
            sqlEcardsService.sendEcardByEmail({ "user": user, "input": { "imageSize": { "width": windowWidth, "height": windowWidth, "link": "www.honeyydo.com" }, "ecardImg": link, "ecardHtml": template, "selectedHomeowners": emailMap } }).then(d => {
                // console.log(d)
            }).catch(err => {
                console.log(err)
            })
        })

    }

    const handleScheduleEcard = () => {
        if (Object.keys(emailMap).length === 0) {
            return;
        }
        getEcardAsImg().then(link => {

            let template = drawTemplateWithGrid(link);

            sqlEcardsService.addEcardToEmailQueue({ user: user, "input": { "title": templateTitle, "imageSize": { "width": windowWidth, "height": windowWidth, "link": "www.honeyydo.com" }, "ecardImg": link, "ecardHtml": template, "selectedHomeowners": emailMap, "date": sendDate } })
                .then((data) => {
                    setSchedulePopup(false)
                    setSharePopup(false)
                    setFinishedPopup(true)



                })
                .catch((err) => {
                    console.log(err);
                });


        })

    }




    useEffect(() => {
        // setNumberOfRows(parseInt(windowHeight / heightOfCell))
        // setNumberOfColumns(parseInt(windowWidth / widthOfCell))
        // console.log("<><><><>>>>>", windowWidth, windowHeight)
        // let d = document.getElementById("sddd-dddd")
        // console.log(d);
        // d.style.width = windowWidth;
        // document.getElementById("sddd-dddd").style.height = windowHeight;
    }, [windowHeight, windowWidth])


    const getGridTemplateColumns = () => {
        var string = "";
        for (let i = 0; i < numberOfColumns; i++) {
            string += "auto "
        }
        return string;
    }

    const [showGrid, setShowGrid] = useState(false);
    const [showBackground, setShowBackground] = useState(true);
    const [showPreview, setShowPreview] = useState(false);

    const [currentGridItemText, setCurrentGridItemText] = useState("");
    const [currentGridItemLink, setCurrentGridItemLink] = useState("");
    const handleCurrentGridItemText = () => {
        let tmp = [...gridItemsArray]
        // console.log(tmp, tmp[currentGridItem])
        tmp[currentGridItem]["children"] = <a href={currentGridItemLink}>{currentGridItemText}</a>
        setGridItemsArray(tmp);
    }


    const [gridBackgroundLink, setGridBackgroundLink] = useState("");

    const handleWindowResize = (w, h) => {
        // console.log("<><sdsfsdfsdfsdf<>>>>>", w, h)
        rndRef.current.updateSize({ width: w, height: h });
        // console.log(rndRef.current)
        // let d = document.getElementById("sddd-dddd")
        // console.log(d);
        // d.style.width = w;
        // document.getElementById("sddd-dddd").style.height = h;
    }

    const updateSize = ({ width: w, height: h }) => {

    }

    const rndRef = useRef();

    const getItemHtmlFromItemKey = (key) => {
        let st = `${templateId ? templateId?.toString() : "tempId"}honeyydo-html-ecard_${key}`;
        return st;
    }

    const drawRnd = (windowWidth, windowHeight, templateModeEditing) => {
        //using document.getElement to get the exact position of the element 
        var width = 100;
        var height = 100;
        var leftOffset = 0;
        var topOffset = 0;
        // console.log(getItemHtmlFromItemKey(currentItemKey))
        let currentHtmlItem = document.getElementById(getItemHtmlFromItemKey(currentItemKey.current));
        if (currentHtmlItem) {
            // console.log("exist")
            width = parseInt(currentHtmlItem.style.width)
            height = parseInt(currentHtmlItem.style.height)
            leftOffset = parseInt(currentHtmlItem.style.left)
            topOffset = parseInt(currentHtmlItem.style.top)

        }


        var deleteLeft = getLeftOffset(parseInt(leftOffset) - 50)
        var deleteTop = getTopOffset(parseInt(topOffset))

        var EditLeft = getLeftOffset(parseInt(leftOffset) - 50)
        var EdtTop = getTopOffset(parseInt(topOffset) + 35)

        var barLeft = getLeftOffset(parseInt(leftOffset) - 340)
        var barTop = getTopOffset(parseInt(topOffset))


        if ((leftOffset + width / 2) >= windowWidth / 2 && (topOffset + height / 2) >= windowHeight / 2) {
            // quarter = 4
            deleteLeft = getLeftOffset(parseInt(leftOffset) - 35)
            deleteTop = getTopOffset(parseInt(topOffset) - 35)

            EditLeft = getLeftOffset(parseInt(leftOffset) - 35)
            EdtTop = getTopOffset(parseInt(topOffset) - 70)

            barLeft = getLeftOffset(parseInt(leftOffset) - 320)
            barTop = getTopOffset(parseInt(topOffset) - 90)
        } else if ((leftOffset + width / 2) < windowWidth / 2 && (topOffset + height / 2) >= windowHeight / 2) {
            // quarter = 3
            deleteLeft = getLeftOffset(parseInt(leftOffset) + 5 + parseInt(width))
            deleteTop = getTopOffset(parseInt(topOffset) - 35)

            EditLeft = getLeftOffset(parseInt(leftOffset) + 5 + parseInt(width))
            EdtTop = getTopOffset(parseInt(topOffset) - 70)

            barLeft = getLeftOffset(parseInt(leftOffset) + 30 + parseInt(width))
            barTop = getTopOffset(parseInt(topOffset) - 90)
        } else if ((leftOffset + width / 2) < windowWidth / 2 && (topOffset + height / 2) < windowHeight / 2) {
            // quarter = 2
            deleteLeft = getLeftOffset(parseInt(leftOffset) + 5 + parseInt(width))
            deleteTop = getTopOffset(parseInt(topOffset) + parseInt(height))

            EditLeft = getLeftOffset(parseInt(leftOffset) + 5 + parseInt(width))
            EdtTop = getTopOffset(parseInt(topOffset) + 35 + parseInt(height))

            barLeft = getLeftOffset(parseInt(leftOffset) + 30 + parseInt(width))
            barTop = getTopOffset(parseInt(topOffset) + parseInt(height))
        } else if ((leftOffset + width / 2) >= windowWidth / 2 && (topOffset + height / 2) < windowHeight / 2) {
            // quarter = 1
            deleteLeft = getLeftOffset(parseInt(leftOffset) - 35)
            deleteTop = getTopOffset(parseInt(topOffset) + parseInt(height))

            EditLeft = getLeftOffset(parseInt(leftOffset) - 35)
            EdtTop = getTopOffset(parseInt(topOffset) + 35 + parseInt(height))

            barLeft = getLeftOffset(parseInt(leftOffset) - 320)
            barTop = getTopOffset(parseInt(topOffset) + parseInt(height))
        }

        // console.log(">>>>>>>", barLeft, barTop)

        return <div className="position-relative"><div id={"honeyydo-main-div-ecard"} onClick={(e) => { handleMainClick(e) }} style={{ backgroundColor: mainDivBackgroundColor, width: windowWidth, height: windowHeight }} className={"resizeable-main-div"}>
            {showBackground ? Object.keys(htmlItems).map(key =>
                drawItemReact(key, htmlItems[key])
            ) : null}

        </div>
            {templateModeEditing && !dragStarted && currentItemKey.current !== null ? <span onClick={() => { deleteItem() }} className="z-index-99 honeyydo-element honeyydo-ecard-icon background1-transparent" style={{ left: `${deleteLeft}`, top: `${deleteTop}` }}><FontAwesomeIcon icon={faTrash} className="width-16-px" /></span> : null}
            {templateModeEditing && !dragStarted && currentItemKey.current !== null ? <span onClick={() => { setShowItemBar(!showItemBar); }} className="z-index-99 honeyydo-element honeyydo-ecard-icon background1-transparent" style={{ left: `${EditLeft}`, top: `${EdtTop}` }}><AiOutlineEdit /></span> : null}
            {templateModeEditing ? <div className={`${(!showItemBar && !dragStarted && currentItemKey.current !== null) ? "" : "display-none visibility-hidden"} honeyydo-element z-index-99`} style={{ left: `${barLeft}`, top: `${barTop}` }} >{itemBar}</div> : null}

        </div>
    }


    function onPositionChange(x, y) {
        setLeftOffset(x)
        setTopOffset(y);
        leftOffsetRef.current = x;
        topOffsetRef.current = y;
    }

    function onSizeChange(key, val) {
        if (key === "width") {
            setWidth(val);
            widthRef.current = val;
        } else if (key === "height") {
            setHeight(val);
            heightRef.current = val;
        }
    }

    useEffect(() => {
        if (!universalThemeOn) {
            if (!templateThemeId) {
                setMyTemplatesFilteredByTheme(null);

            } else {
                // resetTemplate();
                // resetItem();
                // console.log(`%c  your templateThemeId ${templateThemeId}`, 'background: #222; color: #bada55');
                let tem = myTemplates.filter(item => (item.themeId === templateThemeId && item.universal === 0)).map(item => item);
                setMyTemplatesFilteredByTheme(tem)

            }
        } else {
            if (!templateThemeIdUniversal) {
                setMyTemplatesFilteredByTheme(null);
            } else {
                // resetTemplate();
                // resetItem();
                // console.log(`%c  your templateThemeId ${templateThemeIdUniversal}`, 'background: #222; color: #bada55');
                let tem = myTemplatesUniversal.filter(item => item.themeId === templateThemeIdUniversal).map(item => item);
                // console.log(myTemplates);
                let temUniversal = myTemplates.filter(item => (item.themeId === templateThemeIdUniversal && item.universal === 1)).map(item => item);
                // console.log(">>> ", tem, temUniversal);
                console.log("temps =====>", [...tem, ...temUniversal])
                setMyTemplatesFilteredByTheme([...tem, ...temUniversal])

            }
        }

    }, [templateThemeIdUniversal, myTemplatesUniversal, templateThemeId, myTemplates, universalThemeOn])

    return (
        <>
            <div className="Ecard-Page">
                <input className='display-none' type="file" id="honeyydo-ecard-imageUpload-hidden" accept="image/*" onChange={(e) => { handleImageChange(e) }} />
                <h2 className="Blog-Page-Heading">Honeyydo Ecard Tool</h2>
                {windowSize > 1100 ?
                    <>
                        {!(templateThemeIdUniversal || templateThemeId) ?
                            <>


                                <h6 style={{ marginBottom: "5vh" }} className="Blog-Hero-Text color-med-grey">Let your clients know you're thinking of them by sending them an ecard.
                                    Choose from one of our customizeable templates or create your own. Get started by choosing your ecard theme:</h6>



                                <div className="Theme-Container">

                                    {templatesThemesUniversal?.map(item =>

                                        <div style={{ width: '100%', height: '100px' }} onClick={() => { handleActiveTemplateThemeUniversal(item); }} className="nicefindaprostuff  display-flex flex-direction-row column-gap-10-px row-gap-10-px cursor-pointer align-items-center border-radius-10-px" key={item}>
                                            <div style={{ height: '100%', width: '40%' }}>
                                                <img src={item?.img_link ?? Fireworks} className="height-width-100-pt object-fit-cover" />
                                            </div>
                                            <h6 className="grey">{item.title}</h6>
                                        </div>

                                    )}
                                    {templatesThemes?.map(item =>

                                        <div style={{ width: '100%', height: '100px' }} onClick={() => { handleActiveTemplateTheme(item); }} className="nicefindaprostuff  display-flex flex-direction-row column-gap-10-px row-gap-10-px cursor-pointer align-items-center border-radius-10-px" key={item}>
                                            <div style={{ height: '100%', width: '40%' }}>
                                                <img src={myTemplates.filter(template => (template.themeId === item.id))[0]?.img_link ?? Fireworks} className="height-width-100-pt object-fit-cover" />
                                            </div>
                                            <h6 className="grey">{item.title}</h6>
                                        </div>

                                    )}

                                    <div style={{ width: '100%', height: '100px', justifyContent: 'center' }} onClick={() => { setThemePopup(true) }} className="nicefindaprostuff  display-flex  cursor-pointer  border-radius-10-px" >
                                        <div className="display-flex column-gap-15-px align-items-center">
                                            <FontAwesomeIcon icon={faCirclePlus} className="fa-2x grey" />

                                            <h6 className="grey">New Theme</h6>
                                        </div>
                                    </div>

                                </div>
                                {user?.role_id !== 0 ?
                                    <div style={{ justifyContent: 'end' }} className="display-flex">
                                        <Link className="text-decoration-none margin-top-30-px" to={"/calendar"}><h6 className="grey margin-right-15-px">View scheduled ecards <FontAwesomeIcon className="grey fa-xl" icon={faArrowRight} /></h6></Link>
                                    </div>
                                    : null}

                            </>
                            : !(templateId || newTemplate) ?


                                <>

                                    <div className="display-flex flex-wrap column-gap-10-px row-gap-10-px">
                                        <h5 style={{ marginBottom: '30px' }} className="grey">{templateThemeTitle} Ecards</h5>
                                        {(themeAgent === user.id || user.id < 1000) ?
                                            <>
                                                <div className="Toolbar-Button" onClick={() => { setThemePopup(true); setTemplateThemeTitleTemp(templateThemeTitle) }} >
                                                    <span >Rename</span>
                                                    <FontAwesomeIcon icon={faEdit} className="Toolbar-Icon" />
                                                </div>
                                                <div className="Toolbar-Button" onClick={() => { setDeletePopup(true); }} >
                                                    <span >Delete</span>
                                                    <FontAwesomeIcon icon={faTrash} className="Toolbar-Icon" />
                                                </div>
                                            </> : null
                                        }



                                    </div>
                                    {/* <h5>{templateThemeIdUniversal ? templatesThemesUniversal?.filter(item => item.id === templateThemeIdUniversal)?.title }</h5> */}
                                    <div className="Theme-Container">

                                        {myTemplatesFilteredByTheme?.map((item, index) =>
                                            <div style={{ width: '100%', height: '100px' }} onClick={() => { handleActiveTemplate(item) }} className="nicefindaprostuff  display-flex flex-direction-row column-gap-10-px row-gap-10-px cursor-pointer align-items-center border-radius-10-px" key={item}>
                                                <div style={{ height: '100%', width: '40%' }}>
                                                    <img src={item.img_link ?? Fireworks} className="height-width-100-pt object-fit-cover" />
                                                </div>
                                                <h6 className="grey">{item.title}</h6>
                                            </div>

                                        )}
                                        <div style={{ minWidth: '300px', width: '100%', height: '100px', justifyContent: 'center' }} onClick={() => { handleStartNewTemplate() }} className="nicefindaprostuff  display-flex  cursor-pointer  border-radius-10-px" >
                                            <div className="display-flex column-gap-15-px align-items-center">
                                                <FontAwesomeIcon icon={faCirclePlus} className="fa-2x grey" />

                                                <h6 className="grey">New Template</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="display-flex justify-content-space-between">

                                        <div className="display-flex  border-radius-5-px cursor-pointer margin-top-20-px" onClick={() => { setTemplateThemeId(null); setTemplateThemeIdUniversal(null) }}>
                                            <FontAwesomeIcon icon={faArrowLeft} className="fa-xl grey" />

                                            <h6 style={{ marginLeft: '5px' }} className="grey">Choose Different Theme</h6>
                                        </div>
                                        <Link className="text-decoration-none margin-top-30-px" to={"/calendar"}><h6 className="grey margin-right-15-px">View scheduled ecards <FontAwesomeIcon className="grey fa-xl" icon={faArrowRight} /></h6></Link>

                                    </div>



                                </>
                                : null
                        }

                        {templateId || newTemplate ?
                            <>
                                <h6 style={{ marginBottom: "5vh" }} className="Blog-Hero-Text color-med-grey">Send this ecard template as is or personalize it to make it your own</h6>
                                <div style={{ paddingBottom: "5vh" }} className="Ecard-Grid flex-wrap margin-top-30-px ">
                                    {!templateModeEditing ?
                                        <>
                                            <div></div>


                                            <div className="Toolbar-Container">
                                                <div className="Toolbar">
                                                    <div style={{ width: '200px', borderLeft: "none" }} className="Toolbar-Button" onClick={() => (setTemplateId(null), setTemplateThemeId(null), setTemplateThemeIdUniversal(null), setNewTemplate(false), setTemplateModeEditing(false))}>
                                                        <FontAwesomeIcon icon={faArrowLeft} className="Toolbar-Icon" />
                                                        <span>Choose New Theme</span>
                                                    </div>

                                                    <div className="Toolbar-Button" onClick={() => { setTemplateModeEditing(true) }}>
                                                        <span >Edit</span>
                                                        <FontAwesomeIcon icon={faEdit} className="Toolbar-Icon" />
                                                    </div>
                                                    <div className="Toolbar-Button" onClick={() => { handleDownloadEcard() }} >
                                                        <span >Download</span>
                                                        <FontAwesomeIcon icon={faArrowDown} className="Toolbar-Icon" />
                                                    </div>
                                                    <div className="Toolbar-Button" onClick={() => { setSharePopup(true) }} >
                                                        <span >Share</span>
                                                        <FontAwesomeIcon icon={faShare} className="Toolbar-Icon" />
                                                    </div>
                                                    {(ecardAgent === user.id || user.id < 1000) ?
                                                        <div className="Toolbar-Button" onClick={() => { handleDeleteEcard() }} >
                                                            <span >Delete</span>
                                                            <FontAwesomeIcon icon={faTrash} className="Toolbar-Icon" />
                                                        </div> : null
                                                    }


                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div></div>
                                            <div className="Toolbar-Container">
                                                <div className="Toolbar">
                                                    <div style={{ width: '120px', borderLeft: "none" }} className="Toolbar-Button" onClick={() => { setTemplateModeEditing(false); setStartNewTheme(false); }}>
                                                        <FontAwesomeIcon icon={faArrowLeft} className="Toolbar-Icon" />
                                                        <span>Back</span>
                                                    </div>

                                                    <div className="Toolbar-Button hover-disable">
                                                        <span >Background</span>
                                                        <input className="width-20-px cursor-pointer" type="color" value={mainDivBackgroundColor} onChange={(e) => { updateColor(e.target.value); }} />

                                                    </div>
                                                    <div className="Toolbar-Button" onClick={() => { placeCurrentItemKeyIfExist(); setHtmlItemTypeSelected("text"); handleHtmlItemTypeSelected("text"); }}>
                                                        <span >Text</span>
                                                        <RxText className="fa-lg Toolbar-Icon" />
                                                        {/* <PiTextTBold/> */}

                                                    </div>
                                                    <div className="Toolbar-Button" onClick={() => { placeCurrentItemKeyIfExist(); setHtmlItemTypeSelected("image"); document.getElementById("honeyydo-ecard-imageUpload-hidden").click(); }} >
                                                        <span >Add Image</span>
                                                        <FontAwesomeIcon icon={faImage} className="Toolbar-Icon" />
                                                    </div>
                                                    <div className="Toolbar-Button" onClick={() => { setSavePopup(true) }} >
                                                        <span >Save</span>
                                                        <FontAwesomeIcon icon={faSave} className="Toolbar-Icon" />
                                                    </div>

                                                </div>
                                            </div>
                                        </>
                                    }

                                    <div style={{ gridColumn: 'span 2' }} className="Ecard-Container display-flex">
                                        {!templateModeEditing ?
                                            <div style={{ flexGrow: '1' }} className="Related-Container Other-Ecards">

                                                {myTemplatesFilteredByTheme?.length ?
                                                    <>
                                                        <h6 style={{ color: '#919191', marginBottom: '30px' }}>More {templateThemeTitle} Ecards</h6>
                                                        <div className="">
                                                            {myTemplatesFilteredByTheme?.map((item, index) =>



                                                                <div className={`Ecard-Preview-Container margin-top-15-px cursor-pointer ${item.id === parseInt(templateId) ? "selected-border" : ""}`} onClick={() => { if (item.id === parseInt(templateId)) { return } handleActiveTemplate(item) }}>
                                                                    <img style={{ width: '90%' }} className="" src={item.img_link} alt={item.title}></img>
                                                                </div>





                                                            )}
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                                }
                                                <div className="display-flex column-gap-15-px align-items-center justify-content-center border-radius-10-px cursor-pointer nicefindaprostuff" onClick={() => handleStartNewTemplate()} style={{ width: '90%', height: '100px', backgroundColor: '#f2f2f2' }}>
                                                    <FontAwesomeIcon icon={faCirclePlus} className="fa-2x grey" />

                                                    <h6 className="grey">New Template</h6>
                                                </div>


                                            </div>

                                            :
                                            <div style={{ flex: '1' }} className="Related-Container Other-Ecards">
                                                <h6 style={{ color: '#919191', marginBottom: '20px' }}>Add a Sticker</h6>

                                                <div className="display-flex flex-wrap width-200-px flex-wrap height-25-px">
                                                    <input style={{ width: '125px', height: '25px', fontSize: '15px' }} className="card-input__input width-125-px height-25-px" placeholder="Sticker Keyword" type="text" value={stickerSearchKeyword} onChange={(e) => { setStickerSearchKeyword(e.currentTarget.value); }} />
                                                    <button className="ecards-button height-25-px " onClick={() => { handleStickerSearch() }} >Search</button>
                                                </div>
                                                <div style={{ height: '415px', marginTop: '20px', scrollbarWidth: 'thin' }} className="display-flex flex-wrap width-200-px  overflow-y-scroll">
                                                    {stickers?.length && stickers?.map((item, index) =>
                                                        <img className="cursor-pointer" onClick={() => { if (!templateModeEditing) { return } handleStickerClick(item["link512"]) }} alt={item.description} src={item["link128"]} key={index} style={{ width: "100%" }} />
                                                    )}
                                                </div>

                                            </div>
                                        }
                                        <div className="" id="parent-ecards-honeydo">
                                            {/* <div onClick={clickHandler} onMouseUp={mouseUpHandler} onMouseDown={mouseDownHandler}   dangerouslySetInnerHTML={{ __html: template }}></div> */}
                                            {drawRnd(windowWidth, windowHeight, templateModeEditing)}
                                        </div>
                                    </div>


                                </div>
                            </>

                            : null}

                    </> :
                    <h6 style={{ margin: "5vh 0" }} className="grey">For the best user experience, our ecard tool should be used on a desktop. Please log in on your computer to get started customizing and creating ecards.</h6>
                }
            </div>

            {themePopup ?
                <>

                    <div className="backdrop"></div>
                    <div className="popup">
                        <div className="card-input">
                            <label className="card-input__label">
                                Enter a custom theme
                            </label>
                            <input className="card-input__input" value={templateThemeTitleTemp} onChange={(e) => { setTemplateThemeTitleTemp(e.currentTarget.value); }}></input>
                        </div>
                        {user?.role_id === 0 ?
                            <div style={{ width: '235px' }} className="card-input">
                                <label className="card-input__label">
                                    Image
                                </label>
                                <input type="file" id="imageUpload" accept="image/*" onChange={(e) => handleImgUpload(e, "img_link")} />
                            </div>
                            : null
                        }
                        <div className="display-flex column-gap-10-px">
                            <button className="Cancel-Button" onClick={() => (setThemePopup(false), setTemplateThemeTitleTemp(''))}>
                                Cancel
                            </button>
                            <button className="popup-submit-button" onClick={() => createNewTheme()}>
                                {templateThemeId ? 'Save' : 'Create'}
                            </button>
                        </div>
                    </div>
                </>
                : null}

            {sharePopup ?
                <>


                    <div className="backdrop"></div>
                    <div className="popup">
                        <div className="display-flex flex-direction-column flex-wrap">
                            <label style={{ paddingLeft: '10px', marginBottom: '-15px' }} className="card-input__label">
                                Select Recipient(s)
                            </label>
                            <MultipleSelection theme='Settings' selectionMap={emailMap} handleSelectionMap={handleSelectionMap} selectionOptionsArray={arrayOfAgentHomeownersEmails} searchMap={agentHomeowners} searchMapResultField={"email"} enableSelectAll={true} showFieldArray={["contact_name", "email"]} />
                            <div style={{ padding: '0 10px', margin: '-10px 0 20px 0' }} className="display-flex column-gap-10-px card-form__row">
                                <input style={{ width: '48%' }} placeholder="Add custom recipient" className="card-input__input" type="text" value={ecardEmailRecipient} onChange={(e) => { setEcardEmailRecipient(e.currentTarget.value) }}></input>
                                <button style={{ flexGrow: '1', height: '38px' }} className=" ecards-button" onClick={() => { handleEcardEmailRecipient() }} >Add Recipient</button>
                            </div>
                        </div>

                        <div className="display-flex column-gap-10-px">
                            <button className="Cancel-Button" onClick={() => (setSharePopup(false), setEmailMap({}))}>
                                Cancel
                            </button>
                            <button className="popup-submit-button" onClick={() => (handleSendEcard(), setSharePopup(false))}>
                                Send
                            </button>
                            <button className="popup-submit-button" onClick={() => (setSchedulePopup(true))}>
                                Schedule
                            </button>
                        </div>
                    </div>
                </>
                : null}

            {savePopup ?
                <>

                    <div className="backdrop"></div>
                    <div className="popup">
             
                            <div className="display-flex flex-direction-column column-gap-10-px row-gap-10-px">
                                <input placeholder="Title" className="card-input__input" onChange={(e) => { setTemplateTitle(e.currentTarget.value); }}></input>

                                <div className="display-flex column-gap-10-px row-gap-10-px align-items-center justify-content-center">
                                    {(templateId) ?
                                        <button className="popup-submit-button" onClick={() => { saveTemplate(true, true); setTemplateModeEditing(false); setStartNewTheme(false); setSavePopup(false) }}>
                                            Overwrite
                                        </button> : null
                                    }
                                    <button className="popup-submit-button" onClick={() => { saveTemplate(true); setTemplateModeEditing(false); setStartNewTheme(false); setSavePopup(false) }}>
                                        {templateId ? "Save as new" : "Save"}
                                    </button>
                                    <button className="Cancel-Button" onClick={() => setSavePopup(false)}>
                                        Cancel
                                    </button>

                                </div>

                            </div>
                     


                    </div>
                </>
                : null}


            {deletePopup ?
                <>
                    <div className="backdrop"></div>
                    <div className="delete-popup">
                        {/* <div className="card-input">
                            <label  className="card-input__label">
                                Theme
                            </label>
                            <input className="card-input__input" value={templateThemeTitle} onChange={(e) => { setTemplateThemeTitle(e.currentTarget.value); }}></input>
                        </div> */}
                        <div className="card-input">
                            <label className="card-input__label delete-Pop-Up-Question">
                                By deleting this theme you will delete all the ecards belonging to this theme. Are you sure you want to delete?
                            </label>
                        </div>
                        <div className="display-flex column-gap-10-px">
                            <button className="Cancel-Button" onClick={() => setDeletePopup(false)}>
                                Cancel
                            </button>
                            <button className="popup-delete-button" onClick={() => { handleDeleteEcardTheme(); setDeletePopup(false); }}>
                                Delete
                            </button>
                        </div>
                    </div>

                    {/* <div className="popup">
                        <div className="admin-popup-title">
                            <h3>Confirm</h3>
                            <FontAwesomeIcon
                                className="admin-popup-close"
                                icon={faCircleXmark}
                                onClick={()=> {setDeletePopup(false)}}
                            />
                        </div>
                        <h2 className="delete-Pop-Up-Question">
                            Are you sure you want to delete?
                        </h2>
                        <div className="delete-Pop-Up-Buttons">
                            <button className="admin-delete-Button" onClick={()=>{handleDeleteEcardTheme()}}>
                                Delete
                            </button>
                            <button className="admin-cancel-Button" onClick={setDeletePopup(false)}>
                                Cancel
                            </button>
                        </div>
                    </div> */}
                </>

                : null}

            {schedulePopup ?
                <>


                    <div style={{ zIndex: '1999' }} className="backdrop"></div>
                    <div style={{ zIndex: '2000', width: '400px', height: '450px' }} className="popup">
                        <div className="display-flex flex-direction-column flex-wrap">
                            <label className="card-input__label">
                                Select Date to Send
                            </label>
                            <div style={{ minHeight: '300px', paddingTop: '15px' }}>
                                <DatePicker className="Input-Style"
                                    minDate={new Date()}

                                    selected={sendDate}
                                    onChange={(value) => { setSendDate(value) }}
                                    inline
                                    showTimeSelect
                                    filterTime={filteredTimes}
                                />
                            </div>

                        </div>

                        <div className="display-flex column-gap-10-px">
                            <button className="Cancel-Button" onClick={() => (setSchedulePopup(false), setSendDate(null))}>
                                Cancel
                            </button>
                            <button className="popup-submit-button" onClick={() => (handleScheduleEcard())}>
                                Save
                            </button>
                        </div>
                    </div>
                </>
                : null}

            {finishedPopup ?
                <>
                    <div className="backdrop"></div>
                    <div style={{ zIndex: '2000', width: '350px', height: '250px' }} className="popup">
                        <h4 className="grey">Scheduled for {new Date(sendDate).toLocaleString('default', { month: 'short', day: 'numeric' })} </h4>
                        <FontAwesomeIcon className="grey fa-lg" icon={faPaperPlane} />
                        <Link className="text-decoration-none margin-top-30-px" to={"/calendar"}>View scheduled ecards <FontAwesomeIcon className="grey" icon={faArrowRight} /></Link>
                        <button className="Cancel-Button margin-top-30-px" onClick={() => (setFinishedPopup(false))}>
                            Close
                        </button>
                    </div>
                </>

                : null}

            <PopupMessage onConfirm={() => { setServerMessage(null) }} message={serverMessage?.message} title={serverMessage?.title} show={serverMessage !== null} />

        </>
    )
}

export default EcardsGifts;


{/* <div className="grid-container" style={{ display: "grid", gridTemplateColumns: `${getGridTemplateColumns()}`, backgroundColor: "#2196F304" }}>
                                    {showGrid ?
                                        gridItemsArray.map((item, index) =>
                                            <div onClick={() => { setCurrentGridItem(index) }} style={{ cursor: `${showPreview ? "" : "pointer"}`, zIndex: 100, backgroundColor:  `${showPreview ? "" : "rgba(255, 255, 255, 0.8)"}`, border: `${showPreview ? "" : "1px solid rgba(0, 0, 0, 0.8)"}` , textAlign: "center", height: `${getSizeInPixel(item.height)}`, width: `${getSizeInPixel(item.width)}` }}>{showPreview ? null : index + 1}{item.children}</div>
                                        )
                                        : null
                                    }
                                </div> */}