import React, { useEffect, useState } from "react";

import sqlService from "../../../services/sqlService";
import Handyman from "../../../assets/images/find-a-pro/Handyman.jpg";
import Plumber from "../../../assets/images/find-a-pro/Plumber.jpg";
import Electricity from "../../../assets/images/find-a-pro/Electricity.jpg";
import Roofing from "../../../assets/images/find-a-pro/Roofing.jpg";
import Pest from "../../../assets/images/find-a-pro/Pest.jpg";
import Cleaning from "../../../assets/images/find-a-pro/Cleaning.jpg";
import Gas from "../../../assets/images/find-a-pro/Gas.jpg";
import Moving from "../../../assets/images/find-a-pro/Moving.jpg";
import Landscaping from "../../../assets/images/find-a-pro/Landscaping.jpg";
import Seasonal from "../../../assets/images/find-a-pro/Seasonal.jpg";
import { Link } from "react-router-dom";

import "./findPro.css";

function FindPro() {

    const loaded = React.useRef(false);

    const [refresh, setRefresh] = useState(false);


    const [professions, setProfessions] = useState([]);

    const loaded2 = React.useRef(false);


    useEffect(() => {
        if (!loaded2.current) {
            sqlService
                .getIndustryNames()
                .then((data) => {

                    const professionsArray = data.data.map((item) => ({
                        type_name: item.type_name,
                        img_link: item.img_link
                    }));

                    setProfessions(professionsArray);
                })
                .catch((err) => {
                    console.error("Didn't get industry names", err);
                });
            loaded2.current = true;
        }
    }, []);

    // const professions = {"Handyman": Handyman, "Plumbing": Plumber, "Seasonal": Seasonal, "Electricity": Electricity, "Roofing": Roofing, "Pest":  Pest, "Cleaning" : Cleaning, "Gas": Gas, "Moving": Moving, "Landscaping": Landscaping};

    useEffect(
        () => {

            if (!loaded.current) {
                //   sqlService.getPro().then(data => {
                //     const tasks = data.data;
                //     const categories = {}
                //     categories["All"] = 1;
                //     for(let item of tasks){
                //       categories[item.category] = 1;
                //     }
                //     setCategories(categories)
                //     setTasks(tasks)

                //   }).catch(err => {
                //     console.log(err)
                //   })
                loaded.current = true;
            }

        }, [refresh])

    return (
        <div className="display-flex flex-direction-row flex-wrap justify-content-center align-items-center column-gap-40-px row-gap-40-px margin-bottom-50-px overflow-x-none">
            <div className="background-linear-gradient height-200-px align-items-center width-100-psnt align-text-center background-color-lightgrey display-flex justify-content-center">
                <span className="font-size-25-px typewriter">
                    <h2 >
                        Find All your Professionals in One Place
                    </h2>
                </span>
            </div>
            {professions.map((item, index) => (
                <Link
                    key={index}
                    className="text-decoration-none color-black"
                    to={`/profession/${item.type_name}`}
                    params={{ testvalue: "hello" }}
                >
                    <div className="nicefindaprostuff display-flex flex-direction-row flex-wrap width-300-px column-gap-10-px row-gap-10-px cursor-pointer align-items-center border-radius-10-px">
                        <img src={item.img_link} className="image-find-a-pro height-75-px width-100-px" />
                        <span>{item.type_name}</span>
                    </div>
                </Link>
            ))}

        </div>

    )
}

export default FindPro;