import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { useContext, useState } from "react";
import { UserContext } from "../../../App";
import sqlService from "../../../services/sqlService";

export default function AdminDelete({
  onClose,
  item,
  deleteFunction,
  setRefreshData,
  handleDeleteFunction,
  refreshAfterDelete
}) {
  const user = useContext(UserContext);
  const [deleted, setDeleted] = useState(false)

  const handleDelete = async () => {
    try {
      if (handleDeleteFunction) {
        await handleDeleteFunction()
      } else {
        // await sqlService.deleteAdminAgent({ user: user, input: { deletedId: item.id, img_link: item.img_link ?? null } });
        await deleteFunction({ deletedId: item.id, img_link: item?.img_link ?? null });
        console.log("Delete successful");
      }
      if (setRefreshData) {
        setRefreshData((refresh) => !refresh);
      }
      if (refreshAfterDelete) {
        setTimeout(() => {
          window.location.reload();
        }, "3000");

      }

      // onClose();
      setDeleted(true)

    } catch (err) {
      console.error("Error deleting item", err);
    }
  };

  return (
    <>
      <div className="delete-backdrop"></div>
      <div className="delete-popup">
        <div className="admin-popup-title">
          {deleted ? <h3 >User/item has been deleted!</h3>
            : <h3>Confirm</h3>}
          {/* <FontAwesomeIcon
            className="admin-popup-close"
            icon={faCircleXmark}
            onClick={onClose}
          /> */}
        </div>
        {!deleted ?
          <h2 className="delete-Pop-Up-Question">
            Are you sure you want to delete?
          </h2>
          : null}
        <div className="delete-Pop-Up-Buttons">
          {!deleted ?
            <button className="admin-delete-Button" onClick={handleDelete}>
              Delete
            </button> : null}
            {refreshAfterDelete && !deleted ? 
            <button className="admin-cancel-Button" onClick={onClose}>
           Cancel
          </button> 
          : !refreshAfterDelete ?
          <button className="admin-cancel-Button" onClick={onClose}>
            {deleted ? 'Close' : 'Cancel' }
          </button> : null}
          {deleted && refreshAfterDelete ?
          <span>Rerouting you back to dashboard...</span> : null}
        </div>
      </div>
    </>
  );
}
