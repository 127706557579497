import React, { useState, useRef, useEffect } from "react";
import "./adminDashboard.css/adminDashboard.component.css";
import SubHeader from "../subHeader.component/subHeader.component";
import sqlService from "../../../services/sqlService";
import {
  AddBrokerageButton,
  AddAgentButton,
  AddMortgageAgentButton,
  AddInsuranceAgentButton,
  AddProButton,
  AddHomeownerButton,
  AddTaskButton,
  AddBlogButton,
  AddLibraryButton,
} from "./addButtons.component";

import { useNavigate } from "react-router";
import { useContext } from "react";
import { UserContext } from "../../../App";
import stripeService from "../../../services/stripeService";

import AdminTable from "./adminTable.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import AdminCard from "./adminCard.component";
import AdminAdd from "./adminAdd.component";

import { faArrowDown, faWindowClose, faSave } from "@fortawesome/free-solid-svg-icons";
import "./adminDashboard.css/adminDashboard.component.css";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import AdminDelete from "./adminDelete.component";

import DatePicker from "react-datepicker";
import timeUtility from "../../../functions/timeUtility";
import { Spinner } from "react-bootstrap";
import sortUtility from "../../../functions/sortUtility";
import "react-datepicker/dist/react-datepicker.css";
import Placeholder from '../../../assets/images/Placeholder-removebg.png';
import Placeholder2 from '../../../assets/images/Placeholder2-removebg.png';

export default function AdminDashboard() {
  const [data, setData] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [filteredData, setFilteredData] = useState(null);

  const [lockedFilteredData, setLockedFilteredData] = useState(null);
  const [adminVerifiedFilteredData, setAdminVerifiedFilteredData] = useState(null);

  const [activeFilteredData, setActiveFilteredData] = useState(null)
  const [selectedActiveFilter, setSelectedActiveFilter] = useState(null)

  const [agentFilteredData, setAgentFilteredDate] = useState(null)

  const Navigate = useNavigate();
  const user = useContext(UserContext);
  useEffect(() => {
    if (!user) {
      Navigate("/");
    }
  });

  //table options
  const clientTableOptions = [
    { value: "null", label: "Select Client" },
    { value: "brokerages", label: "Brokerages" },
    { value: "agents", label: "Agents" },
    { value: "mortgage_agents", label: "Mortgage Agents" },
    { value: "insurance_agents", label: "Insurance Agents" },
    { value: "homeowners", label: "Homeowners" },
    { value: "pros", label: "Pros" }
  ];

  const libraryTableOptions = [
    { value: "null", label: "Select Library" },
    { value: "tasks", label: "Tasks" },
    { value: "blogs", label: "Blogs" },
    { value: "newsletter", label: "Newsletter" },
    // { value: "library", label: "Library" },
  ];

  const tableFilterOptions = [
    { value: "not-validated", label: "Not Validated" },
    { value: "validated", label: "Validated" },
    { value: "all", label: "All" },
  ];

  const tableLockedFilterOptions = [
    { value: "locked", label: "Locked" },
    { value: "not-locked", label: "Not Locked" },
    { value: "all", label: "All" },
  ];

  //sql functions for tables
  const tableServiceFunctions = {
    brokerages: sqlService.getAllBrokerages,
    agents: sqlService.getAllAgents,
    pros: sqlService.getAllPros,
    homeowners: sqlService.getAllHomeowners,
    tasks: sqlService.getAllTasks,
    blogs: sqlService.getAllBlogs,
    library: sqlService.getLibraries,
  };

  //pagination code
  const pageSize = 10;
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageRef = useRef(1);

  const nextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    currentPageRef.current = Math.min(currentPageRef.current + 1, totalPages)
  };

  const prevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
    currentPageRef.current = Math.max(currentPageRef.current - 1, 1)
  };

  const [currentTableOptions, setCurrentTableOptions] = useState(clientTableOptions);
  const [selectedTable, setSelectedTable] = useState("null");

  const [selectedFilter, setSelectedFilter] = useState("all");
  const [selectedLockedFilter, setSelectedLockedFilter] = useState("all");

  //toggle code:

  const handleToggle = (type) => {
    setSelectedToggle(type);

    const tableOptions = type === "Clients" ? clientTableOptions : libraryTableOptions;
    setCurrentTableOptions(tableOptions);
    setSelectedTable(tableOptions[0].value);
  };

  const [selectedToggle, setSelectedToggle] = useState("Clients");

  const handleTableChange = (e) => {
    console.log(e.target.value)
    setSelectedTable(e.target.value);
  };

  const handleFilterChange = (e) => {
    setSelectedFilter(e.target.value);
  };

  const handleLockedFilterChange = (e) => {
    setSelectedLockedFilter(e.target.value);
  };
  const handleActiveFilterChange = (e) => {
    setSelectedActiveFilter(e);
  };

  const handleAgentFilter = (e) => {
    setAgentFilter(e.target.value)
  }

  const [agentFilter, setAgentFilter] = useState('All')
  //search bar code:

  const [searchBarValue, setSearchBarValue] = useState("");
  const [searchBarPlaceholder, setSearchBarPlaceholder] = useState("Search...");



  const [allSubscriptions, setAllSubscriptions] = useState(null);
  const loadedSubscriptions = useRef(false);
  const [refreshSubscriptions, setRefreshSubscriptions] = useState(false);
  const [usersSubscriptionsInfo, setUsersSubscriptionsInfo] = useState({});

  const onSearchBarChange = (event) => {
    setSearchBarValue(event.target.value);
    onSearch(event.target.value)
  };


  const getAllSubscriptions = async () => {
    var hasMore = false;
    var allSubscriptions = [];
    var starting_after = null;
    do {
      await stripeService.getAllSubscriptions({ input: { starting_after: starting_after } }).then(d => {
        let data = d.data;
        hasMore = data?.has_more
        starting_after = data?.data[data?.data?.length - 1]?.id;
        allSubscriptions = [...allSubscriptions, ...data.data];
      }).catch(err => {
        console.log(err)
      })
    } while (hasMore === true)
    setAllSubscriptions(allSubscriptions);

    await sqlService.getAllBillingInfo().then(d => {
      let data = d.data;
      var userSubsTemp = {};
      for (let billing of data) {
        let sub = allSubscriptions.filter(item => item.customer === billing.stripe_user_id)
        if (sub?.length) {
          userSubsTemp[billing.user_id] = sub[0];
        }
      }
      console.log(userSubsTemp);
      setUsersSubscriptionsInfo(userSubsTemp);
    }).catch(err => {
      console.log(err)
    })

  }

  useEffect(() => {
    if (!loadedSubscriptions.current) {
      loadedSubscriptions.current = true;
      getAllSubscriptions();
    }
  }, [refreshSubscriptions])



  const onSearch = (value) => {
    if (data) {
      if ((!value && searchBarValue === "") || (value === "")) {
        setFilteredData(data);
      } else {
        const lowerCaseSearchValue = value ? value.toLowerCase() : searchBarValue.toLowerCase();
        const filtered = data.filter((row) => {
          const rowValues = Object.values(row).map((value) =>
            String(value).toLowerCase()
          );
          return rowValues.some((value) => value.includes(lowerCaseSearchValue));
        });
        setFilteredData(filtered);
        setTotalPages(Math.ceil(filtered?.length / pageSize));
      }
    }
  };

  useEffect(() => {
    if (currentPageRef.current > totalPages) {
      setCurrentPage(totalPages);
    } else if (currentPageRef.current < 1 && totalPages > 0) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPageRef.current)
    }
  }, [totalPages, currentPage]);

  async function runFunction(table) {
    return new Promise((resolve, reject) => {
      switch (table) {
        case "brokerages":
          sqlService
            .getAllBrokerages({ user: user })
            .then((d) => {
              for (let agent of d.data) {
                if (agent.dates_active) {
                  agent.last_active = new Date(agent.dates_active?.split(',')[0])
                } else {
                  agent.last_active = null;
                }
              }
              resolve(d);
            })
            .catch((err) => {
              reject(err);
            });
          break;
        case "agents":
          sqlService
            .getAllAgents({ user: user })
            .then((d) => {
              console.log(d)
              for (let agent of d.data) {
                if (agent.dates_active) {
                  agent.last_active = new Date(agent.dates_active?.split(',')[0])
                } else {
                  agent.last_active = null;
                }
              }
              resolve(d);
            })
            .catch((err) => {
              reject(err);
            });
          break;
        case "mortgage_agents":
          sqlService
            .getAllMortgageAgents({ user: user })
            .then((d) => {
              console.log(d)
              for (let agent of d.data) {
                if (agent.dates_active) {
                  agent.last_active = new Date(agent.dates_active?.split(',')[0])
                } else {
                  agent.last_active = null;
                }
              }
              resolve(d);
            })
            .catch((err) => {
              reject(err);
            });
          break;
        case "insurance_agents":
          sqlService
            .getAllInsuranceAgents({ user: user })
            .then((d) => {
              console.log(d)
              for (let agent of d.data) {
                if (agent.dates_active) {
                  agent.last_active = new Date(agent.dates_active?.split(',')[0])
                } else {
                  agent.last_active = null;
                }
              }
              resolve(d);
            })
            .catch((err) => {
              reject(err);
            });
          break;
        case "homeowners":
          sqlService
            .getAllHomeowners({ user: user })
            .then((d) => {
              for (let agent of d.data) {
                if (agent.dates_active) {
                  agent.last_active = new Date(agent.dates_active?.split(',')[0])
                } else {
                  agent.last_active = null;
                }
              }
              resolve(d);
              console.log(d)
            })
            .catch((err) => {
              reject(err);
            });
          break;
        case "pros":
          sqlService
            .getAllPros({ user: user })
            .then((d) => {
              resolve(d);
            })
            .catch((err) => {
              reject(err);
            });
          break;
        // case "blogs":
        //   sqlService
        //     .getAllBlogs()
        //     .then((d) => {
        //       resolve(d);
        //     })
        //     .catch((err) => {
        //       reject(err);
        //     });
        //   break;
        case "tasks":
          sqlService
            .getAllTasks({ user: user })
            .then((d) => {
              resolve(d);
            })
            .catch((err) => {
              reject(err);
            });
          break;
        case "blogs":
          sqlService
            .getLibraries()
            .then((d) => {
              resolve(d);
            })
            .catch((err) => {
              reject(err);
            });
          break;
        case "newsletter":
          sqlService
            .getNewsletterQueue()
            .then((d) => {
              console.log(d)
              resolve(d);
            })
            .catch((err) => {
              reject(err);
            });
          break;
        default:
          reject("no table selected");
      }
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      // const serviceFunction = tableServiceFunctions[selectedTable];

      // if (!serviceFunction) {
      //   console.error(`No fetch function found for table "${selectedTable}"`);
      //   return;
      // }

      runFunction(selectedTable)
        .then((data) => {
          console.log(data)
          var d = data?.data ?? data;
          if (selectedTable === 'pros') {
            console.log(">>>>", d);
            var prosObj = {}
            for (let pro of d) {
              let address = { address: pro?.address, state: pro?.state, city: pro?.city, zip_code: pro?.zip_code, country: pro?.country, lat: pro?.lat, lng: pro?.lng }
              if(prosObj[pro.id]){
                prosObj[pro.id] = {...prosObj[pro.id], "additional_addresses": [...prosObj[pro.id]["additional_addresses"], address]}
              }else{
                prosObj[pro.id] = {...pro, "additional_addresses": [address]}
              }
              // if (!pro.primary_address) {
              //   let address = { address: pro?.address, state: pro?.state, city: pro?.city, zip_code: pro?.zip_code, country: pro?.country, lat: pro?.lat, lng: pro?.lng }
              //   var temp = d.find(p => p.primary_address && p.id === pro.id);
              //   if (temp) {
              //     temp["additional_addresses"] = temp?.additional_addresses ? temp.additional_addresses.concat([address]) : [address];
              //   }

              // }
            }
            console.log(prosObj)
            // d = d.filter(p => p.primary_address);
            d = Object.values(prosObj);
          }
          d = sortUtility.sort("creation_date", d, false);
          console.log('selected table', selectedTable);
          console.log("ddddddata => ", d)
          setData(d);
          setFilteredData(d);
          setAdminVerifiedFilteredData(d);
          setLockedFilteredData(d)
          setActiveFilteredData(d)
          setAgentFilteredDate(d)
          setTotalPages(Math.ceil(d?.length / pageSize));
          setSortFilter("newFirst")
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchData();
  }, [selectedTable, pageSize, refreshData]);


  useEffect(() => {
    if (!filteredData) { return }
    if (selectedLockedFilter === "all") {
      setLockedFilteredData(filteredData);
    } else if (selectedLockedFilter === "locked") {
      let filteredByValidation = filteredData.filter(item => item?.locked === 1).map(item => item)
      setLockedFilteredData(filteredByValidation);
    }
    else if (selectedLockedFilter === "not-locked") {
      let filteredByValidation = filteredData.filter(item => (item?.locked === 0 || !item?.locked)).map(item => item)
      setLockedFilteredData(filteredByValidation);
    }
  }, [filteredData, selectedLockedFilter]);

  useEffect(() => {
    if (!lockedFilteredData) { return }
    if (selectedFilter === "all") {
      setAdminVerifiedFilteredData(lockedFilteredData);
    } else if (selectedFilter === "validated") {
      let filteredByValidation = lockedFilteredData.filter(item => item?.admin_verified === 1).map(item => item)
      setAdminVerifiedFilteredData(filteredByValidation);
    }
    else if (selectedFilter === "not-validated") {
      let filteredByValidation = lockedFilteredData.filter(item => (item?.admin_verified === 0 || !item?.admin_verified)).map(item => item)
      setAdminVerifiedFilteredData(filteredByValidation);
    }
  }, [lockedFilteredData, selectedFilter]);

  useEffect(() => {

    if (!activeFilteredData) { return }

    if (!selectedActiveFilter) {
      setAdminVerifiedFilteredData(activeFilteredData)
      setTotalPages(Math.ceil(activeFilteredData?.length / pageSize));
    } else {
      let filtered = activeFilteredData.filter(item => (item?.dates_active && new Date(item.dates_active?.split(',')[0]) > selectedActiveFilter))
      console.log(filtered)
      setTotalPages(Math.ceil(filtered?.length / pageSize));
      setAdminVerifiedFilteredData(filtered);
    }
  }, [activeFilteredData, selectedActiveFilter]);

  useEffect(() => {
    if (!agentFilteredData) { return }
    if (agentFilter === "All") {
      setAdminVerifiedFilteredData(agentFilteredData);
      setTotalPages(Math.ceil(agentFilteredData?.length / pageSize));
    } else {
      let filtered = agentFilteredData.filter(item => item?.agent === parseInt(agentFilter))
      setAdminVerifiedFilteredData(filtered);
      setTotalPages(Math.ceil(filtered?.length / pageSize));

    }

  }, [agentFilteredData, agentFilter]);

  const brokerageColumns = [
    { header: "Name", field: "contact_name" },
    { header: "Number", field: "phone_number" },
    { header: "Email", field: "email" },
    { header: "Board", field: "board" },
    { header: "Address", field: "address" },
    { header: "City", field: "city" },
    { header: "State/Province", field: "state" },
    { header: "Zip/Postal Code", field: "zip_code" },
    { header: "Country", field: "country" },
    { header: "Creation Date", field: "creation_date" },

  ];

  const agentColumns = [
    { header: "Name", field: "contact_name" },
    { header: "Brokerage", field: "brokerage_contact_name" },
    { header: "Board(s)", field: "board" },
    { header: "Number", field: "phone_number" },
    { header: "Email", field: "email" },
    { header: "Website", field: "website" },
    { header: "Address", field: "address" },
    { header: "City", field: "city" },
    { header: "State/Province", field: "state" },
    { header: "Zip/Postal Code", field: "zip_code" },
    { header: "Country", field: "country" },
    { header: "Creation Date", field: "creation_date" },
    // { header: 'Brokerage id', field: 'brokerage' },
    // { header: 'Brokerage Phone', field: 'brokerage_phone_number' }
  ];

  const mortgageAgentColumns = [
    { header: "Name", field: "contact_name" },
    { header: "Brokerage", field: "brokerage_contact_name" },
    { header: "Number", field: "phone_number" },
    { header: "Email", field: "email" },
    { header: "Website", field: "website" },
    { header: "Address", field: "address" },
    { header: "City", field: "city" },
    { header: "State/Province", field: "state" },
    { header: "Zip/Postal Code", field: "zip_code" },
    { header: "Country", field: "country" },
    { header: "Creation Date", field: "creation_date" },
    // { header: 'Brokerage id', field: 'brokerage' },
    // { header: 'Brokerage Phone', field: 'brokerage_phone_number' }
  ];
  const insuranceAgentColumns = [
    { header: "Name", field: "contact_name" },
    { header: "Brokerage", field: "brokerage_contact_name" },
    { header: "Number", field: "phone_number" },
    { header: "Email", field: "email" },
    { header: "Website", field: "website" },
    { header: "Address", field: "address" },
    { header: "City", field: "city" },
    { header: "State/Province", field: "state" },
    { header: "Zip/Postal Code", field: "zip_code" },
    { header: "Country", field: "country" },
    { header: "Creation Date", field: "creation_date" },
    // { header: 'Brokerage id', field: 'brokerage' },
    // { header: 'Brokerage Phone', field: 'brokerage_phone_number' }
  ];

  const homeownerColumns = [
    { header: "Name", field: "contact_name" },
    { header: "Number", field: "phone_number" },
    { header: "Email", field: "email" },
    { header: "Address", field: "address" },
    { header: "City", field: "city" },
    { header: "State/Province", field: "state" },
    { header: "Zip/Postal Code", field: "zip_code" },
    { header: "Country", field: "country" },
    { header: "Agent", field: "agent_name" },
    { header: "Creation Date", field: "creation_date" },
  ];

  const proColumns = [
    { header: "Company Name", field: "company_name" },
    { header: "Industry", field: "industry_name" },
    { header: "Sub-industry", field: "sub_industry_name" },
    { header: "Name", field: "contact_name" },
    { header: "Email", field: "email" },
    { header: "Phone", field: "phone_number" },
    { header: "Website", field: "website" },
    { header: "Address", field: "address" },
    { header: "City", field: "city" },
    { header: "State/Province", field: "state" },
    { header: "Zip/Postal Code", field: "zip_code" },
    { header: "Country", field: "country" },
    { header: "Agent", field: "agent_name" },
    // { header: "Creation Date", field: "creation_date" },
  ];

  const tasksColumns = [
    { header: "Title", field: "title" },
    { header: "Note", field: "note" },
    { header: "Season", field: "seasons" },
    { header: "Category", field: "category" },
    {
      header: "Videolink",
      field: "videoLink",
      render: (item) => {
        let url = item.videoLink;
        if (!/^https?:\/\//i.test(url)) {
          url = "https://" + url;
        }
        return (
          <a href={url} target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faVideo} size="lg" color="orange" />
          </a>
        );
      },
    },
  ];


  const libraryColumns = [
    { header: "Title", field: "title" },
    { header: "Image", field: "img_link" },
    {
      header: "Content",
      field: "content",
      render: (item, openAdminPopup) =>
        selectedTable === "library" ? (
          <span onClick={() => openAdminPopup(item)}>Click To View</span>
        ) : (
          item.content
        ),
    },
    { header: "Creation Date", field: "creation_date" },
  ];


  const blogColumns = [
    { header: "Title", field: "title" },
    {
      header: "Content",
      field: "content",
    },
    {
      header: "Image",
      field: "img_link",
      render: (item, openAdminPopup) =>
        selectedTable === "blogs" ? (
          <span onClick={() => openAdminPopup(item)}>Click To View</span>
        ) : (
          item.link
        ),
    },

    { header: "Tag(s)", field: "tags" },
  ];

  const tableFileds = {
    "brokerages": {
      "editFunction": sqlService.editAdminBrokerage,
      "deleteFunction": sqlService.deleteAdminBrokerage,
      "addFunction": sqlService.addAdminBrokerage,
      "columns": brokerageColumns,
      "addButton": <AddBrokerageButton />
    },
    "agents": {
      "editFunction": sqlService.editAdminAgent.bind(sqlService),
      "deleteFunction": (...args) => { return sqlService.deleteAdminAgent({ "input": { ...args } }); },
      "addFunction": sqlService.addAdminAgent,
      "columns": agentColumns,
      "addButton": <AddAgentButton />
    },
    "mortgage_agents": {
      "editFunction": sqlService.editAdminMortgageAgent.bind(sqlService),
      "deleteFunction": (...args) => { return sqlService.deleteMortgageAdminAgent({ "input": { ...args } }); },
      "addFunction": sqlService.addAdminMortgageAgent,
      "columns": mortgageAgentColumns,
      "addButton": <AddMortgageAgentButton />
    },
    "insurance_agents": {
      "editFunction": sqlService.editAdminInsuranceAgent.bind(sqlService),
      "deleteFunction": (...args) => { return sqlService.deleteInsuranceAdminAgent({ "input": { ...args } }); },
      "addFunction": sqlService.addAdminInsuranceAgent,
      "columns": insuranceAgentColumns,
      "addButton": <AddInsuranceAgentButton />
    },
    "homeowners": {
      "editFunction": sqlService.editAdminHomeowner,
      "deleteFunction": sqlService.deleteAdminHomeowner,
      "addFunction": sqlService.addAdminHomeowner,
      "columns": homeownerColumns,
      "addButton": <AddHomeownerButton />
    },
    "pros": {
      "editFunction": sqlService.editAdminPro,
      "deleteFunction": (args) => { return sqlService.deleteAdminPro({ "input": args }); },
      "addFunction": sqlService.addAdminPro,
      "columns": proColumns,
      "addButton": <AddProButton />
    },
    "tasks": {
      "editFunction": sqlService.editAdminTask,
      "deleteFunction": (args) => { return sqlService.deleteAdminTask({ "input": args }); },
      "addFunction": sqlService.addAdminTask,
      "columns": tasksColumns,
      "addButton": <AddTaskButton />
    },
    "blogs": {
      "editFunction": sqlService.editAdminLibrary,
      "deleteFunction": (args) => { return sqlService.deleteAdminLibrary({ "input": args }); },
      "addFunction": sqlService.addAdminLibrary,
      "columns": blogColumns,
      "addButton": <AddBlogButton />
    },
    // "library": {
    //   "editFunction": sqlService.editAdminLibrary.bind(sqlService),
    //   "deleteFunction": sqlService.deleteAdminLibrary,
    //   "addFunction": sqlService.addAdminLibrary,
    //   "columns": libraryColumns,
    //   "addButton": <AddLibraryButton />
    // },
  }




  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  // const getPageData = (data) => data?.slice(startIndex, endIndex);
  const getPageData = (data) => data;

  const [addPopup, setAddPopup] = useState(false);
  const closeAddWindow = () => {
    setAddPopup(false);
  };

  const openAddWindow = () => {
    setAddPopup(true);
  };


  const [requested, setRequested] = useState(false);

  const [sortFilter, setSortFilter] = useState('newFirst')


  const [sortValues, setSortValues] = useState([
    // { value: 'newFirst', label: 'New First', key: 'newFirst' },
    // { value: 'oldFirst', label: 'Old First', key: 'oldFirst' },
    //  { value: 'mostActive', label: 'Most Recently Active', key: 'mostActive'},
    //  { value: 'leastActive', label: 'Least Recently Active', key: 'leastActive'}
  ])

  useEffect(() => {
    if (selectedTable === 'homeowners' || selectedTable === 'agents' || selectedTable === 'mortgage_agents' || selectedTable === 'insurance_agents') {
      setSortValues([
        { value: 'newFirst', label: 'New First', key: 'newFirst' },
        { value: 'oldFirst', label: 'Old First', key: 'oldFirst' },
        { value: 'mostActive', label: 'Most Recently Active', key: 'mostActive' },
        { value: 'leastActive', label: 'Least Recently Active', key: 'leastActive' }
      ])

    } else {
      setSortValues([{ value: 'newFirst', label: 'New First', key: 'newFirst' },
      { value: 'oldFirst', label: 'Old First', key: 'oldFirst' }])

    }

    // setAgentFilter('All')
    // if (!(selectedTable === 'agents' || selectedTable === 'homeowners' || selectedTable === 'brokerages')) {
    setSelectedActiveFilter(null)
    // }


  }, [selectedTable])




  const sort = (value) => {
    if (value === "newFirst" || value === "oldFirst") {
      var oldFirst = adminVerifiedFilteredData.sort(function (a, b) {
        var c = new Date(a.creation_date);
        var d = new Date(b.creation_date);
        return c - d;
      })
    }
    if (value === "newFirst") {
      var reverse = []
      for (let d = oldFirst.length - 1; d > -1; d--) {
        reverse.push(oldFirst[d])
      }
      console.log("reverse", reverse)
      setAdminVerifiedFilteredData(reverse)
    } else if (value === "oldFirst") {
      console.log("oldFirst", oldFirst)
      setAdminVerifiedFilteredData(oldFirst)
    } else if (value === "leastActive") {
      let s = sortUtility.sort('last_active', adminVerifiedFilteredData, true)

      setAdminVerifiedFilteredData(s)
    } else if (value === "mostActive") {
      let s = sortUtility.sort('last_active', adminVerifiedFilteredData, false)

      setAdminVerifiedFilteredData(s)
    }

  }

  const [selectedNewsletter, setSelectedNewsletter] = useState(null);
  const [deletePopup, setDeletePopup] = useState(false);

  const deleteFunction = () => {
    sqlService.deleteNewsletter({ input: { "deletedId": deletePopup } }).then(d => {
      setRefreshData(refreshData => !refreshData);
    }).catch(err => {
      console.log(err)
    })
  }

  const saveSelectedNewsletter = () => {
    setRequested(true)
    sqlService.modifyNewsletter({ input: { "newsletter": selectedNewsletter } }).then(d => {
      setRefreshData(refreshData => !refreshData);
      setRequested(false);
      setSelectedNewsletter(null)
    }).catch(err => {
      setRequested(false);
      console.log(err)
      setSelectedNewsletter(null)
    })
  }


  function filteredTimes(time) {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  }



  return (
    <div className="">
      <div className="center-wrapper">
        <SubHeader
          pageName="Admin Dashboard"
          showSearchBar={true}
          onSearchBarChange={onSearchBarChange}
          searchBarValue={searchBarValue}
          searchBarPlaceHolder={searchBarPlaceholder}
          onSearch={onSearch}
        />
      </div>
      <div className="center-wrapper">

        <span style={{ padding: '0 5px' }}>Sort by
          <select
            className="mobile-dropdown margin-left-10-px "
            onChange={(e) => { setSortFilter(e.target.value); sort(e.target.value) }}
            value={sortFilter}
          >
            {sortValues.map((val) =>
              <option key={val.value} value={val.value} >{val.label}</option>
            )}

          </select>

        </span>

      </div>
      <div className="center-wrapper">
        <div className="admin-toggle-buttons-container">
          <div className="toggle-buttons">
            <button
              className={`toggle-button ${selectedToggle === "Clients" ? "toggle-button-selected" : ""
                }`}
              onClick={() => handleToggle("Clients")}
            >
              Clients
            </button>
            <button
              className={`toggle-button ${selectedToggle === "Libraries" ? "toggle-button-selected" : ""
                }`}
              onClick={() => handleToggle("Libraries")}
            >
              Content
            </button>
          </div>
        </div>
      </div>
      {selectedTable === "library" || selectedTable === "blogs" || selectedTable === "newsletter" ? (

        <>
          {selectedTable === "null" ?

            <div className="center-wrapper">
              <div className="width-100-psnt text-center">Please select a library</div>
              <div className="adminTable-name-button-container">
                <select
                  className="admin-dropdown"
                  value={selectedTable}
                  onChange={handleTableChange}
                >
                  {currentTableOptions.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <div onClick={openAddWindow}>{tableFileds[selectedTable] ? tableFileds[selectedTable].addButton : null}</div>
              </div>
            </div>
            :
            <>
              <div className="center-wrapper">
                <div className="adminTable-name-button-container">
                  <select
                    className="admin-dropdown"
                    value={selectedTable}
                    onChange={handleTableChange}
                  >
                    {currentTableOptions.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <div onClick={openAddWindow}>{tableFileds[selectedTable] ? tableFileds[selectedTable].addButton : null}</div>
                </div>
              </div>
              {selectedTable === "newsletter" ?
                selectedNewsletter ?

                  <div>
                    {requested ?
                      <Spinner /> :
                      <div className="center-wrapper column-gap-20-px row-gap-20-px margin-10-px">
                        <span className="cursor-pointer " onClick={() => setSelectedNewsletter(null)}>
                          {"Close Editing "}
                          <FontAwesomeIcon
                            icon={faWindowClose}
                            size="lg"
                            color="orange"
                            onClick={() => null}
                          />
                        </span>
                        <span className="cursor-pointer " onClick={() => saveSelectedNewsletter()}>
                          {"Save "}
                          <FontAwesomeIcon
                            icon={faSave}
                            size="lg"
                            color="orange"
                            onClick={() => null}
                          />
                        </span>
                      </div>
                    }
                    <div className="display-flex flex-wrap justify-content-center aling-items-center column-gap-20-px row-gap-20-px margin-10-px">
                      <div className="display-flex flex-wrap flex-direction-column">
                        <span>{"Title: "}<input type="text" value={selectedNewsletter?.title} onChange={(e) => { setSelectedNewsletter({ ...selectedNewsletter, "title": e.currentTarget.value }) }} /></span>
                        <span style={{maxWidth: '300px', padding: '3px 0'}} className="color-med-grey italic">{'To include a name as a variable in the title, you can write "{{recipient_name}}" or "{{sender_name}}"'}</span>
                        <span>{"Send Email: "}<input type="checkbox" checked={selectedNewsletter?.send_email === 1} onChange={() => { setSelectedNewsletter({ ...selectedNewsletter, "send_email": selectedNewsletter?.send_email === 0 ? 1 : 0 }) }} /></span>
                        <span>{"Targeted Group: "}
                          <select value={selectedNewsletter?.target_group_role_id} onChange={(e) => { setSelectedNewsletter({ ...selectedNewsletter, "target_group_role_id": e.currentTarget.value }) }} >
                            <option value={1}>1 Homeowners</option>
                            <option value={2}>2 Agents</option>
                            <option value={3}>3 Brokerages</option>
                            <option value={5}>5 Mortgage Agents</option>
                            <option value={6}>6 Insurance Agents</option>
                          </select>

                        </span>
                        <span>{"Sent: "}<input type="checkbox" checked={selectedNewsletter?.sent === 1} onChange={() => { setSelectedNewsletter({ ...selectedNewsletter, "sent": selectedNewsletter?.sent === 0 ? 1 : 0 }) }} /></span>

                      </div>
                      <span>{"Creation Date: "}   <DatePicker className="Input-Style"
                        // minDate={new Date()}

                        selected={typeof selectedNewsletter?.creation_date === "string" ? timeUtility.getTimeFromMysqlDatetime(selectedNewsletter?.creation_date) : selectedNewsletter?.creation_date}
                        onChange={(value) => { setSelectedNewsletter({ ...selectedNewsletter, "creation_date": value }) }}
                        inline
                        showTimeSelect
                        filterTime={filteredTimes}

                      /></span>

                      <span>{"Send Date: "}   <DatePicker className="Input-Style"
                        // minDate={new Date()}

                        selected={typeof selectedNewsletter?.send_date === "string" ? timeUtility.getTimeFromMysqlDatetime(selectedNewsletter?.send_date) : selectedNewsletter?.send_date}
                        onChange={(value) => { setSelectedNewsletter({ ...selectedNewsletter, "send_date": value }) }}
                        inline
                        showTimeSelect
                        filterTime={filteredTimes}

                      /></span>
                    </div>
                    <div className="display-flex justify-content-center flex-wrap">
                      <div>
                        <textarea className="Admin-Dashboard-Html-Input" value={selectedNewsletter.content} onChange={(e) => { setSelectedNewsletter({ ...selectedNewsletter, "content": e.currentTarget.value }) }} />
                      </div>
                      <div dangerouslySetInnerHTML={{
                        __html: selectedNewsletter && selectedNewsletter.content ? selectedNewsletter.content : ""
                      }}></div>
                    </div>
                  </div>
                  :
                  <div className="center-wrapper">
                    <div className="display-flex flex-wrap margin-10-px">
                      <div className="center-wrapper margin-10-px">
                        <div className="Admin-button" onClick={() => { setSelectedNewsletter({}) }}>{"Add Newsletter"}</div>
                      </div>
                      {adminVerifiedFilteredData?.map(item =>
                        <div className="Admin-Dashboard-Custome-Div1 display-flex flex-direction-column flex-wrap justify-content-center aling-items-center column-gap-10-px row-gap-10-px">
                          <div className="display-flex flex-wrap justify-content-center aling-items-center column-gap-5-px row-gap-5-px">
                            <span className="cursor-pointer " onClick={() => setSelectedNewsletter(item)}>
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                size="lg"
                                color="orange"
                                onClick={() => null}
                              />
                            </span>
                            <span className="cursor-pointer ">
                              <FontAwesomeIcon
                                className="cursor-pointer margin-left-10-px"
                                icon={faTrash}
                                size="lg"
                                color="orange"
                                onClick={() => {
                                  setDeletePopup(item.id);
                                }}
                              />
                            </span>

                          </div>
                          <span className="bold">{item?.title}</span>
                          <span>{"Creation Date: "}{item?.creation_date}</span>
                          <span>{"Send Date: "}{item?.send_date}</span>
                          <span>{"Send Email: "}{item?.send_email}</span>
                          <span>{"Targeted Group: "}{item?.target_group_role_id}</span>
                          <span>{"Sent: "}{item?.sent}</span>
                        </div>
                      )}
                    </div>
                  </div>
                :
                <div className="admin-cards-container">
                  {adminVerifiedFilteredData?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
                    .map((item, index) => (
                      <AdminCard
                        key={index}
                        itemData={item}
                        columns={tableFileds[selectedTable] ? tableFileds[selectedTable].columns : null}
                        selectedTable={selectedTable}
                        onTableChange={handleTableChange}
                        addButton={tableFileds[selectedTable] ? tableFileds[selectedTable].addButton : null}
                        tableOptions={currentTableOptions}
                        setRefreshData={setRefreshData}
                        editFunction={tableFileds[selectedTable] ? tableFileds[selectedTable].editFunction : null}
                        deleteFunction={tableFileds[selectedTable] ? tableFileds[selectedTable].deleteFunction : null}
                        addFunction={tableFileds[selectedTable] ? tableFileds[selectedTable].addFunction : null}
                      />
                    ))}
                </div>
              }
              <div className="center-wrapper">
                <div className="admin-pagination-buttons">
                  <button
                    className="admin-pagination-button"
                    onClick={() => { setCurrentPage(currentPage - 1); currentPageRef.current = currentPageRef.current - 1 }}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <button
                    className="admin-pagination-button"
                    onClick={() => { setCurrentPage(currentPage + 1); currentPageRef.current = currentPageRef.current + 1 }}
                    disabled={currentPage === Math.ceil(data?.length / pageSize)}
                  >
                    Next
                  </button>
                </div>
              </div>
              {addPopup && (
                <AdminAdd
                  onClose={closeAddWindow}
                  columns={tableFileds[selectedTable] ? tableFileds[selectedTable].columns : null}
                  addFunction={tableFileds[selectedTable] ? tableFileds[selectedTable].addFunction : null}
                  setRefreshData={setRefreshData}
                  selectedTable={selectedTable}
                />)}
            </>
          }
        </>
      ) : (
        <>
          {selectedTable === "null" ?

            <div style={{ maxWidth: '800px' }} className="margin-auto-lr display-flex flex-direction-row flex-wrap justify-content-center align-items-center column-gap-40-px row-gap-40-px margin-bottom-50-px overflow-x-none">
              {/* <div className="background-linear-gradient height-200-px align-items-center width-100-psnt align-text-center background-color-lightgrey display-flex justify-content-center">
                <span className="font-size-25-px typewriter">
                  <h2 >
                    Find All your Professionals in One Place
                  </h2>
                </span>
              </div> */}
              {currentTableOptions.filter((option) => option.value !== 'null').map((option, index) => (

                <div onClick={() => setSelectedTable(option.value)} className="nicefindaadminstuff display-flex flex-direction-row flex-wrap width-300-px column-gap-10-px row-gap-10-px cursor-pointer align-items-center border-radius-10-px">
                  <img src={selectedToggle === 'Clients' ? Placeholder : Placeholder2} className="image-find-a-pro height-75-px width-100-px" />
                  <span>{option.label}</span>
                </div>

              ))}

            </div>

            // <div className="center-wrapper">
            //   <div className="width-100-psnt text-center">Please select a data option</div>
            //   <div className="adminTable-name-button-container">
            //     <select
            //       className="admin-dropdown"
            //       value={selectedTable}
            //       onChange={handleTableChange}
            //     >
            //       {currentTableOptions.map((option, index) => (
            //         <option key={index} value={option.value}>
            //           {option.label}
            //         </option>
            //       ))}
            //     </select>
            //     <div onClick={openAddWindow}>{tableFileds[selectedTable] ? tableFileds[selectedTable].addButton : null}</div>
            //   </div>
            // </div>
            :

            <AdminTable
              // data={(selectedTable === "brokerages" || selectedTable === "agents" || selectedTable === "pros") ? getPageData(adminVerifiedFilteredData) ?? [] : getPageData(lockedFilteredData) ?? []}
              data={getPageData(adminVerifiedFilteredData)}
              columns={tableFileds[selectedTable] ? tableFileds[selectedTable].columns : null}
              selectedTable={selectedTable}
              tableOptions={currentTableOptions}
              onTableChange={handleTableChange}
              addButton={tableFileds[selectedTable] ? tableFileds[selectedTable].addButton : null}
              currentPage={currentPage}
              totalPages={totalPages}
              nextPage={nextPage}
              prevPage={prevPage}
              editFunction={tableFileds[selectedTable] ? tableFileds[selectedTable].editFunction : null}
              setRefreshData={setRefreshData}
              deleteFunction={tableFileds[selectedTable] ? tableFileds[selectedTable].deleteFunction : null}
              addFunction={tableFileds[selectedTable] ? tableFileds[selectedTable].addFunction : null}
              selectedLockedFilter={selectedLockedFilter}
              onTableFilterChange={handleFilterChange}
              tableLockedFilterOptions={tableLockedFilterOptions}
              onTableLockedFilterChange={handleLockedFilterChange}
              selectedFilter={selectedFilter}
              tableFilterOptions={tableFilterOptions}
              setRefreshSubscriptions={setRefreshSubscriptions}
              allSubscriptions={usersSubscriptionsInfo}
              onTableActiveFilterChange={handleActiveFilterChange}
              selectedActiveFilter={selectedActiveFilter}
              agentFilter={agentFilter}
              handleAgentFilter={handleAgentFilter}
            />
          }
        </>

      )}
      {deletePopup && (
        <AdminDelete
          onClose={() => { setDeletePopup(null) }}
          item={deletePopup}
          setRefreshData={setRefreshData}
          handleDeleteFunction={deleteFunction}
        />
      )}
    </div>
  );
}
