import logo from "../assets/images/Honeyydo logo/Honeyydo-logo.png"
const NotificationsTemplates = {
    "1": {
        preview: <span>Welcome to Honeyydo</span>,
        normal: <div>
            <h1>Welcome to Honeyydo</h1>
            <img src={logo} className="width-150-px" />
            </div>
    }
}

export default NotificationsTemplates;