import React, { useEffect, useRef, useState, useSyncExternalStore } from "react";

import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import logoImg from "../../../assets/images/Honeyydo logo/Honeyydo-logo s.png";
import { MdOutlineMenuOpen } from "react-icons/md";
import { TbTableOptions } from "react-icons/tb";
import { BiRightIndent } from "react-icons/bi";
import { CgCloseR } from "react-icons/cg";
import { TfiClose } from "react-icons/tfi";
// import HeaderAnimation from "../header-animation.component/header-animation.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faBars, faCaretDown, faAngleDown } from "@fortawesome/free-solid-svg-icons";

import { useContext } from "react";
import { UserContext } from "../../../App";


import "./header.css";


import Cookies from "universal-cookie";

import { useNavigate } from "react-router-dom";

import signupConstants from "../../../constants/signupConstants";

import PopupMessage from "../../utility.component/popupMessage.component/popupMessage.component";

import { userState } from "../../../App";
import { useOnlineIndicator } from "../../../App";

import serverResponseMessagesConstants from "../../../constants/serverResponseMessagesConstants";
import { Button } from "react-bootstrap";

import publicUrlsConstants from "../../../constants/publicUrlConstants";
import sqlService from "../../../services/sqlService";
import userUtility from "../../../functions/userUtitily";
import Placeholder from "../../../assets/images/Placeholder-removebg.png";
import InviteButton from "../../utility.component/inviteButton.component/inviteButton.component";

function Header({ setUser }) {

    const user = useContext(UserContext);
    const currentPathRef = useRef(window.location.pathname);

    const [agents, setAgents] = useState("");
    const loadedAgent = useRef(false);

    const [isOpen, setIsOpen] = React.useState(false);
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState);
    };

    function StatusBar() {
        const isOnline = useOnlineIndicator();
        return <span className={`${isOnline ? 'color-green' : 'color-red'}`}>{isOnline ? '✓ Online' : '✕ Disconnected'}</span>;
    }

    useEffect(() => {
        if (!user) { return }
        if (!loadedAgent.current && user && user.role_id === 1) {
            sqlService.getAgentsPB({}).then(data => {
                console.log(data);
                setAgents(data.data[0]);
            }).catch(err => {
                console.log(err)
            })
            loadedAgent.current = true;
        }

    }, []);

    const [honeydoStorageServerState, setHoneydoStorageServerState] = useState(localStorage.getItem("honeyydo-storage-server-state"));

    const onConfirmServerMessage = () => {
        setServerMessage(null);
    }


    const listener = (e) => {
        console.log("triggered >>>>>>",
            localStorage.getItem("honeyydo-storage-server-state"),
            serverResponseMessagesConstants[localStorage.getItem("honeyydo-storage-server-state")]
        )
        if (localStorage.getItem("honeyydo-storage-server-state") === "USER_COOKIE_EXPIRED") {
            setTimeout(() => { logoutForce() }, 5000);
        }
        if (localStorage.getItem("honeyydo-storage-server-state") === "WRONG_TOKEN") {
            setTimeout(() => { logoutForce() }, 5000);
        }
        setHoneydoStorageServerState(localStorage.getItem("honeyydo-storage-server-state"));
        if (serverResponseMessagesConstants[localStorage.getItem("honeyydo-storage-server-state")]) {
            setServerMessage(serverResponseMessagesConstants[localStorage.getItem("honeyydo-storage-server-state")]);
            localStorage.removeItem("honeyydo-storage-server-state");
        }
    }

    useEffect(() => {
        window.addEventListener("storage", listener);
        return () => {
            window.removeEventListener("storage", listener);
        };
    }, [])



    const [isOpen1, setIsOpen1] = React.useState(false);
    const toggleDrawer1 = () => {
        setIsOpen1((prevState1) => !prevState1);
    };

    const [serverMessage, setServerMessage] = useState(null);

    const [windowSize, setWindowSize] = React.useState(window.innerWidth);



    const [path, setPath] = useState(window.location.pathname);

    const location = useLocation();



    // useEffect(() => {
    //     const storage = (event) => {
    //         console.log("dfdskdfjsldfjksdjfncnccn bbbb")
    //       if(event?.originalEvent?.storageArea===sessionStorage){
    //       }
    //     };
    //     window.addEventListener("storage", storage);

    //     return ()=> window.removeEventListener("storage", storage)
    //   }, []);





    useEffect(() => {
        window.addEventListener('resize', onWindowResize);

        return () => {
            window.removeEventListener('resize', onWindowResize);
        };
    }, []);


    const onWindowResize = () => {
        setWindowSize(window.innerWidth);
    };


    const Navigate = useNavigate();

    const isAdminDashboardPage = (path) => {
        const paths = ["ecards", "admin-stripe-control", "admin-associations-control"]
        if (paths.includes(path)) {
            return true;
        }
        return false;
    }

    // useEffect(() => {
    //     //console.log("////// user", user)
    //     let windowUrl = window.location.href;
    //     let windowPath = windowUrl?.split("#")[1];
    //     let startOfPath = windowPath?.split("/")[1];
    //     // console.log(userUtility.getUserRoute(user))
    //     //console.log(!(userUtility.getUserRoute(user) === "/admin-dashboard" && isAdminDashboardPage(startOfPath)) , !(userUtility.getUserRoute(user) === "/pricing" && startOfPath === "add-payment") , (!user || user?.finished_signup === 0 || user?.verified === 0 || (user?.admin_verified === 0 && user?.role_id !== 1)) , !publicUrlsConstants[startOfPath])
    //     // debugger;
    //     let currentPath = window.location.pathname;
    //     if (!(userUtility.getUserRoute(user, currentPath) === "/admin-dashboard" && isAdminDashboardPage(startOfPath)) && !(userUtility.getUserRoute(user, currentPath) === "/pricing" && startOfPath === "add-payment") && (!user || user?.finished_signup === 0 || user?.verified === 0 || (user?.admin_verified === 0 && user?.role_id !== 1)) && (!publicUrlsConstants[startOfPath])) {
    //         // debugger;
    //         Navigate("/");
    //     }

    // }, [honeydoStorageServerState]);


    // const loaded = React.useRef(false);

    const logout = async () => {
        const cookie = new Cookies();
        // the domain property is important


        //localStorage.removeItem("honeyydo-user");
        await sqlService.deleteToken({ input: { token: user.token } }).then(d => {

        }).catch(err => {
            setServerMessage(serverResponseMessagesConstants["SOMETHING_WENT_WRONG"]);
        })
        document.cookie = "honeyydo_user_cookie=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/; sameSite=strict; secure"
        Navigate("/");
        setUser(null);
        window.location.reload();
    };


    const logoutForce = () => {
        document.cookie = "honeyydo_user_cookie=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/; sameSite=strict; secure"
        Navigate("/");
        setUser(null);
        window.location.reload();
    }



    useEffect(
        () => {
            // hiding or showing re_captcha
            let captch = document.getElementsByClassName("grecaptcha-badge");
            if (captch && captch["0"] && captch["0"].style) {
                if (location.pathname.startsWith("/signup") || location.pathname.startsWith("/login")) {
                    captch["0"].style.visibility = "visible";
                } else {
                    captch["0"].style.visibility = "hidden";
                }

            }
            //making screen screen scroll to top when visiting for first time
            if (location.pathname !== currentPathRef.current) {
                window.scrollTo(0, 0);
                currentPathRef.current = location.pathname;
            }
            if (path !== location.pathname) {
                setPath(location.pathname);
            }
            let startOfPath = location.pathname.split("/")[1]
            if (user) {
                // console.log(user);
                let currentPath = window.location.pathname;
                let userRoute = userUtility.getUserRoute(user, location.pathname)
                // debugger;
                console.log(currentPath, location?.pathname, userRoute)
                if (userRoute !== "" && !location?.pathname?.endsWith(userRoute)) {
                    Navigate(userRoute);
                }
            }

        }
        , [location]);

    // This is for the animation of the header if they are logged out and for the login button dropdown if they are logged out

    const initialText = 'Welcome to honeydo!';
    const sentence = 'The all in one home management application for Homeowners!';
    const sentence2 = 'And an amazing lead generation tool for Agents!';
    const sentence3 = 'We are here to help you in any way we can!';
    const [position, setPosition] = useState(0);
    const [currentSentence, setCurrentSentence] = useState(initialText);
    const [isPaused, setIsPaused] = useState(false);

    const [mouseEnterEmailService, setMouseEnterEmailService] = useState(false);

    // useEffect(() => {
    //     const animationInterval = setInterval(() => {
    //         setPosition((prevPosition) => {
    //             if (isPaused) return prevPosition;

    //             let newPosition = prevPosition + 1;
    //             if (currentSentence === initialText && newPosition > initialText.length) {
    //                 setIsPaused(true);
    //                 setTimeout(() => {
    //                     setIsPaused(false);
    //                     setCurrentSentence(sentence);
    //                 }, 2000);
    //             } else if (currentSentence === sentence && newPosition > sentence.length) {
    //                 setIsPaused(true);
    //                 setTimeout(() => {
    //                     setIsPaused(false);
    //                     setCurrentSentence(sentence2);
    //                 }, 2000);
    //             } else if (currentSentence === sentence2 && newPosition > sentence2.length) {
    //                 setIsPaused(true);
    //                 setTimeout(() => {
    //                     setIsPaused(false);
    //                     setCurrentSentence(sentence3);
    //                 }, 2000);
    //             } else if (currentSentence === sentence3 && newPosition > sentence3.length) {
    //                 setIsPaused(true);
    //                 setTimeout(() => {
    //                     setIsPaused(false);
    //                     setCurrentSentence(initialText);
    //                 }, 2000); // Time in miliseconds that it freezes
    //             }
    //             return newPosition;
    //         });
    //     }, 100); // Change for faster animation

    //     return () => {
    //         clearInterval(animationInterval);
    //     };
    // }, [isPaused, currentSentence]);

    // useEffect(() => {
    //     setPosition(0);
    // }, [currentSentence]);

    const [dropdown, setDropdown] = useState(false);
    const [signupDropdown, setsignupDropdown] = useState(false);

    const toggleDropdown = () => {
        setDropdown(!dropdown);
    };

    const toggleSignupDropdown = () => {
        setsignupDropdown(!signupDropdown);
    };

    const getAgentPath = (roleId) => {
        switch (roleId) {
            case 2:
                return "agent-home"
            case 5:
                return "mortgage-agent-home"
            case 6:
                return "insurance-agent-home"
            default:
                return "agent-home"
        }
    }


    return (
        <>
            <div className="Header-Block no-print" >


                {(user?.role_id === 0 && windowSize > 1500) ? /*This is the admin account and later on change it to if admin = 1 on the homeowner table in the database*/

                    <div>

                        <div className="position-absolute left-10-px">
                            <Link to="/admin-dashboard">
                                <img className="Header-Logo-Image" src={logoImg} />
                            </Link>
                        </div>


                        <div className="display-flex flex-direction-row column-gap-10-px row-gap-10-px absolute-center width-fit-content display-none-mobile position-absolute margin-top-30-px">
                            <Link className="text-decoration-none" to={"admin-dashboard"}><div className={`Admin-Header-Button ${path === "/admin-dashboard" ? "Selected-Header-Button" : ""}`}>Dashboard</div></Link>
                            <Link className="text-decoration-none" to={"ecards"}><div className={`Admin-Header-Button ${path === "/ecards" ? "Selected-Header-Button" : ""}`}>Ecards</div></Link>
                            <Link className="text-decoration-none" to={"admin-stripe-control"}><div className={`Admin-Header-Button ${path === "/admin-stripe-control" ? "Selected-Header-Button" : ""}`}>Coupons</div></Link>
                            <Link className="text-decoration-none" to={"admin-associations-control"}><div className={`Admin-Header-Button ${path === "/admin-associations-control" ? "Selected-Header-Button" : ""}`}>Associations Control</div></Link>
                            <Link className="text-decoration-none" to={"admin-email-service"}><div className={`Admin-Header-Button ${path === "/admin-email-service" ? "Selected-Header-Button" : ""}`}>Email Serivce</div></Link>
                        </div>

                        <div className="position-absolute right-10-px">
                            <div className="login-button Header-Icon cursor-pointer">
                                <FontAwesomeIcon icon={faUser} className="fa-xl" />
                            </div>
                            <div className="login-dropdown">
                                <div className="login-option cursor-pointer" onClick={() => { setDropdown(false); logout(); }}>Logout</div>
                            </div>
                        </div>



                    </div>
                    : user?.role_id === 0 ?
                        <div>
                            <div className="position-absolute left-10-px">
                                <div className="login-button Header-Icon cursor-pointer" onClick={toggleDropdown}>
                                    <FontAwesomeIcon icon={faBars} className="fa-xl" />
                                </div>
                                {dropdown && (
                                    <div className="login-dropdown left-10px">
                                        <Link className="login-option" to="/admin-dashboard" onClick={() => setDropdown(false)}>Dashboard</Link>
                                        <Link className="login-option" to="/ecards" onClick={() => setDropdown(false)}>Ecards</Link>
                                        <Link className="login-option" to="/admin-stripe-control" onClick={() => setDropdown(false)}>Coupons</Link>
                                        <Link className="login-option" to="/admin-associations-control" onClick={() => setDropdown(false)}>Associations Control</Link>
                                        <Link className="login-option" to="/admin-email-service" onClick={() => setDropdown(false)}>Email Service</Link>
                                    </div>
                                )}
                            </div>
                            <div className="mobile-header">
                                <Link to="/admin-dashboard">
                                    <img className="Header-Logo-Image" src={logoImg} />
                                </Link>
                            </div>


                            <div className="position-absolute right-10-px">
                                <div className="login-button Header-Icon cursor-pointer" onClick={toggleDropdown}>
                                    <FontAwesomeIcon icon={faUser} className="fa-xl" />
                                </div>
                                {dropdown && (
                                    <div className="login-dropdown">
                                        {/* <Link className="login-option" to="/settings" onClick={() => setDropdown(false)}>Settings</Link> */}
                                        <div className="login-option cursor-pointer" onClick={() => { setDropdown(false); logout(); }}>Logout</div>
                                    </div>
                                )}
                            </div>
                        </div>


                        // This is if they aren't logged in

                        : (!user && windowSize > 1200) ?

                            <div className="Header-Blocks" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <div>
                                    <Link to="/">
                                        <img className="Header-Logo-Image" src={logoImg} />
                                    </Link>
                                </div>
                                {/* <div className="header-animation-contaners">
                                <HeaderAnimation />

                            </div> */}

                                <div className='display-flex justify-content-center align-items-center'>
                                    <Link className="text-decoration-none" to={"/pricing"}><div className={`Header-Button-Login ${path === "/pricing" ? "Selected-Header-Button" : ""}`}>Pricing</div></Link>
                                    <Link className="text-decoration-none" to={"/login"}><div className={`Header-Button-Login ${path === "/login" ? "Selected-Header-Button" : ""}`}>Login</div></Link>

                                    {/* <div className={`Header-Button-Login login-button cursor-pointer ${path.startsWith("/signup") ? "Selected-Header-Button" : "" }`} onClick={toggleDropdown}>
                                    Sign Up
                                </div>
                                {dropdown && (
                                    // /signup/:role_id/:link/:parent/:senderId/:senderRoleTypeId
                                    <div className="login-dropdown right-10px">
                                        <Link className="login-option" to={signupConstants.HOMEOWNER} onClick={() => setDropdown(false)}>Homeowner</Link>
                                        <Link className="login-option" to={signupConstants.AGENT} onClick={() => setDropdown(false)}>Agent</Link>
                                        <Link className="login-option" to={signupConstants.BROKERAGE} onClick={() => setDropdown(false)}>Brokerage</Link>
                                    </div>
                                )} */}
                                    <div className="">
                                        <div className="login-button cursor-pointer" onClick={toggleDropdown}>
                                            <div className={`Header-Button-Login`}>Join as</div>
                                        </div>
                                        {dropdown && (
                                            <div className="login-dropdown right-10px">
                                                <Link onClick={toggleDropdown} className="login-option" to={signupConstants.AGENT}><div>Realtor</div></Link>
                                                <Link onClick={toggleDropdown} className="login-option" to={"/pro-info"}><div>Pro</div></Link>
                                                <Link onClick={toggleDropdown} className="login-option" to={signupConstants.MORTGAGE_AGENT}><div>Mortgage Agent</div></Link>
                                                <Link onClick={toggleDropdown} className="login-option" to={signupConstants.INSURANCE_AGENT}><div>Insurance Agent</div></Link>
                                            </div>
                                        )}
                                    </div>


                                </div>


                            </div>
                            : (!user && windowSize > 850) ?


                                <div className="Header-Blocks" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                    <div>
                                        <Link to="/">
                                            <img className="Header-Logo-Image" src={logoImg} />
                                        </Link>
                                    </div>



                                    <div className='display-flex justify-content-center align-items-center'>
                                        <Link className="text-decoration-none" to={"/pricing"}><div className={`Header-Button-Login ${path === "/pricing" ? "Selected-Header-Button" : ""}`}>Pricing</div></Link>
                                        <Link className="text-decoration-none" to={"/login"}><div className={`Header-Button-Login ${path === "/login" ? "Selected-Header-Button" : ""}`}>Login</div></Link>

                                        {/* <div className={`Header-Button-Login login-button cursor-pointer ${path.startsWith("/signup") ? "Selected-Header-Button" : "" }`} onClick={toggleDropdown}>
                                    Sign Up
                                </div>
                                {dropdown && (
                                    // /signup/:role_id/:link/:parent/:senderId/:senderRoleTypeId
                                    <div className="login-dropdown right-10px">
                                        <Link className="login-option" to={signupConstants.HOMEOWNER} onClick={() => setDropdown(false)}>Homeowner</Link>
                                        <Link className="login-option" to={signupConstants.AGENT} onClick={() => setDropdown(false)}>Agent</Link>
                                        <Link className="login-option" to={signupConstants.BROKERAGE} onClick={() => setDropdown(false)}>Brokerage</Link>
                                    </div>
                                )} */}
                                        <div className="">
                                            <div className="login-button cursor-pointer" onClick={toggleDropdown}>
                                                <div className={`Header-Button-Login`}>Join as</div>
                                            </div>
                                            <div className="login-dropdown right-10px">
                                                <Link onClick={toggleDropdown} className="login-option" to={signupConstants.AGENT}><div>Realtor</div></Link>
                                                <Link onClick={toggleDropdown} className="login-option" to={"/pro-info"}><div>Pro</div></Link>
                                                <Link onClick={toggleDropdown} className="login-option" to={signupConstants.MORTGAGE_AGENT}><div>Mortgage Agent</div></Link>
                                                <Link onClick={toggleDropdown} className="login-option" to={signupConstants.INSURANCE_AGENT}><div>Insurance Agent</div></Link>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                                : (!user) ?
                                    <>
                                        <div className="Header-Blocks" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                            <div>
                                                <Link to="/">
                                                    <img className="Header-Logo-Image" src={logoImg} />
                                                </Link>
                                            </div>



                                            <div className="position-absolute right-10-px">
                                                <div className="login-button Header-Icon cursor-pointer" onClick={toggleDropdown}>
                                                    <FontAwesomeIcon icon={faUser} className="fa-xl" />
                                                </div>
                                                {dropdown && (
                                                    <div className="login-dropdown right-10px">
                                                        <Link className="login-option" to="/pricing"><div className={``} onClick={() => setDropdown(false)}>Pricing</div></Link>
                                                        <Link className="login-option" to="/login"><div className={``} onClick={() => setDropdown(false)}>Login</div></Link>
                                                        {/* <div className="login-option login-button cursor-pointer" onClick={toggleSignupDropdown}>
                                                        Sign Up
                                                    </div>
                                                    {signupDropdown && (
                                                        <div className="tiny-login-dropdown left-10px">
                                                            <Link className="login-option" to={signupConstants.HOMEOWNER} onClick={() => setDropdown(false)}>Homeowner</Link>
                                                            <Link className="login-option" to={signupConstants.AGENT} onClick={() => setDropdown(false)}>Agent</Link>
                                                            <Link className="login-option" to={signupConstants.BROKERAGE} onClick={() => setDropdown(false)}>Brokerage</Link>
                                                        </div>
                                                    )} */}
                                                        <Link className="login-option" to="/pro-info"><div className={``} onClick={() => setDropdown(false)}>Join as a Pro</div></Link>
                                                        <Link className="login-option" to={signupConstants.AGENT}><div className={``} onClick={() => setDropdown(false)}>Join as a Realtor</div></Link>
                                                        <Link className="login-option" to={signupConstants.MORTGAGE_AGENT}><div className={``} onClick={() => setDropdown(false)}>Join as Mortgage Agent</div></Link>
                                                        <Link className="login-option" to={signupConstants.INSURANCE_AGENT}><div className={``} onClick={() => setDropdown(false)}>Join as Insurance Agent</div></Link>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                    // If a user has made an account but not finished signup / verified
                                    : ((user && (user?.finished_signup === 0 || user?.verified === 0 || (user?.admin_verified === 0 && user.role_id !== 1))) && windowSize > 1200) ?

                                        <div className="Header-Blocks" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                            <div>
                                                <Link to="/">
                                                    <img className="Header-Logo-Image" src={logoImg} />
                                                </Link>
                                            </div>
                                            {/* <div className="header-animation-contaners">
                                        <HeaderAnimation />
        
                                    </div> */}


                                            <div className="cursor-pointer" onClick={() => { logout(); }}>Logout</div>
                                        </div>
                                        : ((user && (user?.finished_signup === 0 || user?.verified === 0 || (user?.admin_verified === 0 && user.role_id !== 1))) && windowSize > 500) ?


                                            <div className="Header-Blocks" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                                <div>
                                                    <Link to="/">
                                                        <img className="Header-Logo-Image" src={logoImg} />
                                                    </Link>
                                                </div>



                                                <div className="cursor-pointer" onClick={() => { logout(); }}>Logout</div>

                                            </div>
                                            : (user && (user?.finished_signup === 0 || user?.verified === 0 || (user?.admin_verified === 0 && user.role_id !== 1))) ?
                                                <>
                                                    <div className="Header-Blocks" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                                        <div>
                                                            <Link to="/">
                                                                <img className="Header-Logo-Image" src={logoImg} />
                                                            </Link>
                                                        </div>



                                                        <div className="cursor-pointer" onClick={() => { logout(); }}>Logout</div>
                                                    </div>
                                                </>




                                                : user?.role_id === 1 ?

                                                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>

                                                        {windowSize > 1280 ?
                                                            <>
                                                                <div className="position-absolute left-10-px display-flex align-item-center justify-content-center">
                                                                    <Link to="/dashboard">
                                                                        <img className="Header-Logo-Image" src={logoImg} />
                                                                    </Link>

                                                                    <div className="display-flex align-self-center flex-direction-row width-fit-content display-none-mobile align-items-center justify-content-center height-fit-content">
                                                                        {/* <span className="font-size-8-px">Powered By</span> */}
                                                                        <img className="AgentImg-Header margin-right-5-px" src={agents?.logo_link && agents?.logo_link !== '' ? agents?.logo_link : agents?.img_link && agents?.img_link !== '' ? agents.img_link : Placeholder} alt="agent" />
                                                                        <span className="bold grey">{agents?.contact_name}</span>
                                                                    </div>
                                                                </div>

                                                                <div className="display-flex flex-direction-row column-gap-10-px row-gap-10-px absolute-center width-fit-content display-none-mobile position-absolute margin-top-30-px">
                                                                    <Link className="text-decoration-none" to={"dashboard"}><div className={`Header-Button ${path === "/dashboard" ? "Selected-Header-Button" : ""}`}>Dashboard</div></Link>
                                                                    <Link className="text-decoration-none" to={"tasklist"}><div className={`Header-Button ${path === "/tasklist" ? "Selected-Header-Button" : ""}`}>Task List</div></Link>
                                                                    <Link className="text-decoration-none" to={"my-property"}><div className={`Header-Button ${path === "/my-property" ? "Selected-Header-Button" : ""}`}>My Property</div></Link>
                                                                    <Link className="text-decoration-none" to={"find-a-pro"}><div className={`Header-Button ${path === "/find-a-pro" ? "Selected-Header-Button" : ""}`}>Find a Pro</div></Link>
                                                                    <Link className="text-decoration-none" to={"filing-cabinet"}><div className={`Header-Button ${path === "/filing-cabinet" ? "Selected-Header-Button" : ""}`}>Filing Cabinet</div></Link>

                                                                </div>
                                                            </>


                                                            :
                                                            <>
                                                                <div className="position-absolute left-10-px">
                                                                    <div className="login-button Header-Icon cursor-pointer" onClick={toggleDropdown}>
                                                                        <FontAwesomeIcon icon={faBars} className="fa-xl" />
                                                                    </div>
                                                                    {dropdown && (
                                                                        <div className="login-dropdown left-10px">
                                                                            <Link className="login-option" to="/tasklist" onClick={() => setDropdown(false)}>Task List</Link>
                                                                            <Link className="login-option" to="/my-propery" onClick={() => setDropdown(false)}>My Property</Link>
                                                                            <Link className="login-option" to="/find-a-pro" onClick={() => setDropdown(false)}>Find a Pro</Link>
                                                                            <Link className="login-option" to="/filing-cabinet" onClick={() => setDropdown(false)}>Filing Cabinet</Link>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="float-left display-flex align-item-center justify-content-center">
                                                                    <Link to="/dashboard">
                                                                        <img className="Header-Logo-Image" src={logoImg} />
                                                                    </Link>
                                                                    <div className="display-flex flex-direction-row width-fit-content display-none-mobile align-items-center justify-content-center height-fit-content">
                                                                        <img className="AgentImg-Header margin-right-5-px" src={agents?.logo_link && agents?.logo_link !== '' ? agents?.logo_link : agents?.img_link && agents?.img_link !== '' ? agents.img_link : Placeholder} alt="agent" />
                                                                        <span className="bold grey">{agents?.contact_name}</span>
                                                                    </div>
                                                                </div>


                                                                {/* <div className="position-absolute right-10-px">
                                                                <div className="login-button Header-Icon cursor-pointer" onClick={toggleDropdown}>
                                                                    <FontAwesomeIcon icon={faUser} className="fa-xl" />
                                                                </div>
                                                                    <div className="login-dropdown">
                                                                        <Link className="login-option" to="/settings">Settings</Link>
                                                                        <div className="login-option cursor-pointer" onClick={() => { logout(); }}>Logout</div>
                                                                    </div>
                                                                )}
                                                            </div> */}


                                                            </>
                                                        }

                                                        <div className="position-absolute right-10-px display-flex align-items-center column-gap-20-px">
                                                            {user?.role_id === 1 && windowSize > 600 ?
                                                                <InviteButton header topMargin={'15px'} buttonText={'Invite a friend'} /> : null
                                                            }

                                                            <div className="login-button Header-Icon cursor-pointer" onClick={toggleDropdown}>
                                                                <FontAwesomeIcon icon={faUser} className="fa-xl" />
                                                            </div>
                                                            <div className="login-dropdown">
                                                                <Link className="login-option" to="/settings">Settings</Link>
                                                                <div className="login-option cursor-pointer" onClick={() => { logout(); }}>Logout</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    : (user?.role_id === 2 || user?.role_id === 5 || user?.role_id === 6) ?

                                                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>

                                                            {windowSize > 1450 ?
                                                                <>
                                                                    <div className="position-absolute left-10-px">
                                                                        <Link to={`/${getAgentPath(user?.role_id)}`} >
                                                                            <img className="Header-Logo-Image" src={logoImg} />
                                                                        </Link>
                                                                        {/* {StatusBar()} */}
                                                                    </div>


                                                                    <div className="display-flex flex-direction-row column-gap-10-px row-gap-10-px absolute-center width-fit-content display-none-mobile position-absolute margin-top-30-px">
                                                                        <Link className="text-decoration-none" to={`/${getAgentPath(user?.role_id)}`} ><div className={`Header-Button ${path === `/${getAgentPath(user?.role_id)}` ? "Selected-Header-Button" : ""}`}>Dashboard</div></Link>
                                                                        <Link className="text-decoration-none" to={"/clients"}><div className={`Header-Button ${path === "/clients" ? "Selected-Header-Button" : ""}`}>Clients</div></Link>

                                                                        <Link className="text-decoration-none" to={"/manage-a-pro"}><div className={`Header-Button ${path === "/manage-a-pro" ? "Selected-Header-Button" : ""}`}>Manage Pros</div></Link>
                                                                        <Link className="text-decoration-none" to={"/ecards"}><div className={`Header-Button ${path === "/ecards" ? "Selected-Header-Button" : ""}`}>Ecards</div></Link>
                                                                        <Link className="text-decoration-none" to={"/calendar"}><div className={`Header-Button ${path === "/calendar" ? "Selected-Header-Button" : ""}`}>Calendar</div></Link>
                                                                        {/* <Link className="text-decoration-none" to={"admin-email-service"}><div className={`Admin-Header-Button ${path === "/admin-email-service" ? "Selected-Header-Button" : ""}`}>Email Serivce</div></Link> */}
                                                                        <div onMouseEnter={() => { setMouseEnterEmailService(true); }} onMouseLeave={() => { setMouseEnterEmailService(false); }} className={`cursor-pointer Admin-Header-Button-no-hover display-flex align-items-center column-gap-10-px position-relative`}>
                                                                            <span className={`position-relative ${mouseEnterEmailService ? "" : ""}`}><span>Email Service</span> </span>
                                                                            <FontAwesomeIcon style={{ marginTop: '2px' }} icon={faAngleDown} />

                                                                            {mouseEnterEmailService ? <div className="Header-Dropdown-Container">
                                                                                <Link className="text-decoration-none" to={"/agent-email-service"}><div className={`Header-Button-Dropdown`}>Create templates and send emails</div></Link>
                                                                                <Link className="text-decoration-none" to={"/email-history"}><div className={`Header-Button-Dropdown`}>View email history</div></Link>
                                                                            </div> : null}
                                                                        </div>
                                                                        <Link className="text-decoration-none" to={"/agent-newsletter"}><div className={`Header-Button ${path === "/agent-newsletter" ? "Selected-Header-Button" : ""}`}>Newsletter</div></Link>
                                                                    </div>
                                                                </>


                                                                :
                                                                <>
                                                                    {/* <div className="display-flex flex-direction-row column-gap-10-px row-gap-10-px absolute-center width-fit-content display-none-mobile position-absolute margin-top-30-px">
                                                                    <Link className="text-decoration-none" to={"agent-home"}><div className={`Header-Button ${path === "/agent-home" ? "Selected-Header-Button" : ""}`}>Dashboard</div></Link>

                                                                    <Link className="text-decoration-none" to={"manage-a-pro"}><div className={`Header-Button ${path === "/manage-a-pro" ? "Selected-Header-Button" : ""}`}>Manage Pros</div></Link>
                                                                </div> */}

                                                                    <div className="position-absolute left-10-px">
                                                                        <div className="login-button Header-Icon cursor-pointer">
                                                                            <FontAwesomeIcon icon={faBars} className="fa-xl" />
                                                                        </div>
                                                                        <div className="login-dropdown left-10px">
                                                                            <Link className="login-option" to={`/${getAgentPath(user?.role_id)}`} onClick={() => setDropdown(false)}>Dashboard</Link>
                                                                            <Link className="login-option" to="/clients" onClick={() => setDropdown(false)}>Clients</Link>
                                                                            <Link className="login-option" to="/manage-a-pro" onClick={() => setDropdown(false)}>Manage Pros</Link>
                                                                            <Link className="login-option" to="/ecards" onClick={() => setDropdown(false)}>Ecards</Link>
                                                                            <Link className="login-option" to="/calendar" onClick={() => setDropdown(false)}>Calendar</Link>
                                                                            <Link className="login-option" to="/agent-newsletter" onClick={() => setDropdown(false)}>Newsletter</Link>
                                                                            <div onMouseEnter={() => { setMouseEnterEmailService(true); }} onMouseLeave={() => { setMouseEnterEmailService(false); }} className={`login-option cursor-pointer Admin-Header-Button-Dropdown-no-hover display-flex align-items-center column-gap-15-px position-relative`}>
                                                                                <span className={` position-relative width-100-psnt ${mouseEnterEmailService ? "" : ""}`}><span>Email Service</span> <FontAwesomeIcon className="margin-left-5-px" icon={faAngleDown} /></span>

                                                                                {mouseEnterEmailService ? <div className="Header-Dropdown-Container-Dropdown">
                                                                                    <Link className="text-decoration-none" to={"/agent-email-service"}><div className={`Header-Button-Dropdown`}>Create templates and send emails</div></Link>
                                                                                    <Link className="text-decoration-none" to={"/email-history"}><div className={`Header-Button-Dropdown`}>View email history</div></Link>
                                                                                </div> : null}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="float-left">
                                                                        <Link to={`/${getAgentPath(user?.role_id)}`} >
                                                                            <img className="Header-Logo-Image" src={logoImg} />
                                                                        </Link> </div>


                                                                    <div className="position-absolute right-10-px">
                                                                        <div className="login-button Header-Icon cursor-pointer">
                                                                            <FontAwesomeIcon icon={faUser} className="fa-xl" />
                                                                        </div>
                                                                        <div className="login-dropdown">
                                                                            <Link className="login-option" to="/settings">Settings</Link>
                                                                            <div className="login-option cursor-pointer" onClick={() => { logout(); }}>Logout</div>
                                                                        </div>
                                                                    </div>

                                                                </>
                                                            }

                                                            <div className="position-absolute right-10-px">
                                                                <div className="login-button Header-Icon cursor-pointer">
                                                                    <FontAwesomeIcon icon={faUser} className="fa-xl" />
                                                                </div>
                                                                <div className="login-dropdown">
                                                                    <Link className="login-option" to="/settings">Settings</Link>
                                                                    <div className="login-option cursor-pointer" onClick={() => { logout(); }}>Logout</div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        : user?.role_id === 3 ?

                                                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>

                                                                {windowSize > 1450 ?
                                                                    <>
                                                                        <div className="position-absolute left-10-px">
                                                                            <Link to="/brokerage-home">
                                                                                <img className="Header-Logo-Image" src={logoImg} />
                                                                            </Link>
                                                                        </div>


                                                                        <div className="display-flex flex-direction-row column-gap-10-px row-gap-10-px absolute-center width-fit-content display-none-mobile position-absolute margin-top-30-px">
                                                                            <Link className="text-decoration-none" to={"brokerage-home"}><div className={`Header-Button ${path === "/brokerage-home" ? "Selected-Header-Button" : ""}`}>Dashboard</div></Link>
                                                                            <Link className="text-decoration-none" to={"manage-an-agent"}><div className={`Header-Button ${path === "/manage-an-agent" ? "Selected-Header-Button" : ""}`}>Manage Agents</div></Link>
                                                                        </div>
                                                                    </>


                                                                    :
                                                                    <>
                                                                        <div className="display-flex flex-direction-row column-gap-10-px row-gap-10-px absolute-center width-fit-content display-none-mobile position-absolute margin-top-30-px">
                                                                            <Link className="text-decoration-none" to={"brokerage-home"}><div className={`Header-Button ${path === "/brokerage-home" ? "Selected-Header-Button" : ""}`}>Dashboard</div></Link>
                                                                            <Link className="text-decoration-none" to={"manage-an-agent"}><div className={`Header-Button ${path === "/manage-an-agent" ? "Selected-Header-Button" : ""}`}>Manage Agents</div></Link>
                                                                        </div>

                                                                        <div className="position-absolute left-10-px">
                                                                            <div className="login-button Header-Icon cursor-pointer" onClick={toggleDropdown}>
                                                                                <FontAwesomeIcon icon={faBars} className="fa-xl" />
                                                                            </div>
                                                                            {dropdown && (
                                                                                <div className="login-dropdown left-10px">
                                                                                    <Link className="login-option" to="/brokerage-home" onClick={() => setDropdown(false)}>Dashboard</Link>
                                                                                    <Link className="login-option" to="/manage-an-agent" onClick={() => setDropdown(false)}>Manage Agents</Link>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div className="float-left">
                                                                            <Link to="/brokerage-home">
                                                                                <img className="Header-Logo-Image" src={logoImg} />
                                                                            </Link> </div>


                                                                        <div className="position-absolute right-10-px">
                                                                            <div className="login-button Header-Icon cursor-pointer" onClick={toggleDropdown}>
                                                                                <FontAwesomeIcon icon={faUser} className="fa-xl" />
                                                                            </div>
                                                                            {dropdown && (
                                                                                <div className="login-dropdown">
                                                                                    <Link className="login-option" to="/settings" onClick={() => setDropdown(false)}>Settings</Link>
                                                                                    <div className="login-option cursor-pointer" onClick={() => { setDropdown(false); logout(); }}>Logout</div>
                                                                                </div>
                                                                            )}
                                                                        </div>

                                                                    </>
                                                                }

                                                                <div className="position-absolute right-10-px">
                                                                    <div className="login-button Header-Icon cursor-pointer" onClick={toggleDropdown}>
                                                                        <FontAwesomeIcon icon={faUser} className="fa-xl" />
                                                                    </div>
                                                                    {dropdown && (
                                                                        <div className="login-dropdown">
                                                                            <Link className="login-option" to="/settings" onClick={() => setDropdown(false)}>Settings</Link>
                                                                            <div className="login-option cursor-pointer" onClick={() => { setDropdown(false); logout(); }}>Logout</div>
                                                                        </div>
                                                                    )}
                                                                </div>

                                                            </div>

                                                            : null


                }


            </div>
            <PopupMessage onConfirm={!serverMessage?.sticky ? onConfirmServerMessage : null} message={serverMessage?.message} color={serverMessage?.color} title={serverMessage?.title} show={serverMessage !== null} sticky={serverMessage?.sticky ?? false} logout={serverMessage?.logout ? logout : null} />
        </>
    );
}


export default Header;
