import React, { useState, useEffect, useRef, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../../../App";
import { useNavigate } from "react-router-dom";
import userUtility from "../../../functions/userUtitily";
export default function ProPending() {
    const Navigate = useNavigate();
    const user = useContext(UserContext);
    useEffect(()=>{
        if (!user || !user.role_id === 4 || user?.admin_verified === 1) {
            Navigate("/");
        }
    },[])
    return (
        <div className="Signup-Border Verify-Border margin-top-5-vh" >
            <div className="align-items-center display-flex flex-direction-column padding-top-15-px max-width-300-px">
                <p className="bold grey">Thanks for joining Honeyydo!</p> <n /><n /> <p> Before you continue, your account will be verified by our admin within one business day. We will notify you once verification is complete.</p>
                <FontAwesomeIcon icon={faCircleUser} className="fa-3x grey margin-top-40-px" />
            </div>
        </div>
    )
}