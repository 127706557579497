import React from "react";
import { useParams } from "react-router";
import BlogPost from "./BlogPost.component";

export default function BlogParent() {
    const { id } = useParams();

    return (
    <BlogPost key={id}/>
    )
}