import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { faX } from "@fortawesome/free-solid-svg-icons";



//fix this cause all delete goes to admin brokerage
/**
 *
 * @param {*} param0 Message for popup
 */
function PopupMessage({ title, message, show, onConfirm, color, sticky, logout }) {
  const handleConfirm = async () => {
    onConfirm()
  };
  return (
    <div className={show ? "visibility-visible z-index-99" : "visibility-hidden"}>
      <div className="delete-backdrop"></div>
      <div style={{minWidth: '300px'}} className="delete-popup">
        <div  className="admin-popup-title">
          <h3 className="grey ">{title ?? ""}</h3>
          {/* {!sticky ?
            <FontAwesomeIcon
              className="admin-popup-close fa-sm"
              icon={faX}
              onClick={() => { handleConfirm() }}
            /> : null
          } */}
          {logout ?
          <div className="login-option cursor-pointer" onClick={() => { logout(); }}>Logout</div>
            :null
          }

        </div>
        <div style={{padding: '0 20px'}}>
          <span
            className={` ${color && color === "red"
              ? "red-background"
              : color && color === "green"
                ? "green-background"
                : ""
              }`}
          >
            {message && typeof message === "string" ? message : ""}
          </span>
        </div>
        {!sticky ?
          <div className="delete-Pop-Up-Buttons">
            <button className="admin-delete-Button" onClick={() => { handleConfirm() }}>
              Close
            </button>
          </div> : null
        }

      </div>
    </div>
  );
}

export default PopupMessage;