import React, { useState, useEffect, useRef } from "react";
import inputConstants from "../../../constants/inputConstants";
import PopupMessage from "../../utility.component/popupMessage.component/popupMessage.component";
import sqlService from "../../../services/sqlService";
import ReCAPTCHA from "react-google-recaptcha";

function QuoteForm() {

    const [newTask, setNewTask] = useState({ company_type: "Brokerage" })
    const [serverMessage, setServerMessage] = useState(null)

    const requiredVals = ["contact_name", "company_name", "company_type", "company_size", "email", "phone_number", "state"]



    function updateNewTask(key, value) {
        let nTask = { ...newTask }
        nTask[key] = value;
        setNewTask(nTask);

    }

    function closePopup() {
        setServerMessage(null)
    }

    function submitForm() {
        const token = captchaRef.current.getValue();
        captchaRef.current.reset();
        if (!token) {
            setServerMessage({ "message": "Please verify that you are not a robot", "title": "Error" })
            return
        }
        for (let value of requiredVals) {
            if (!(newTask[value])) {
                setServerMessage({ title: 'Oops', message: "Please fill out all required fields" });
                // console.log(value)
                return;

            }
        }


        sqlService
            .requestQuote({ input: newTask, captchaTokenV2: token })
            .then((data) => {
                setServerMessage({
                    title: "Thanks for submitting!",
                    message: "You will receive an email response from us shortly",
                });


                setNewTask({})
            })
            .catch((err) => {
                console.log(err)
                setServerMessage({ title: "Oops", message: "Something went wrong" });

            });

    }

    const captchaRef = useRef(null)
    const [loadedCaptcha, setLoadedCaptcha] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setLoadedCaptcha(true);
        }, 1500);
    })


    return (
        <>
            <div style={{ padding: '0 5%', margin: 'auto', maxWidth: '1200px' }}>
                <div className="Blog-Hero-Section">
                    <h2 className="Blog-Page-Heading">Request a Quote</h2>
                    <h6 style={{ marginBottom: '20px' }} className="Blog-Hero-Text text-smaller color-med-grey">Tell us a bit of information about your brokerage or association and we will get back to you with an estimate.</h6>
                    <span >* denotes a required field</span>
                </div>

                <div style={{ padding: '0 5%' }} className="Settings-Panel display-flex flex-direction-column">

                    <h5 style={{ paddingLeft: '10px' }} className="wrap-none grey">Contact Information </h5>

                    <div className="card-form height-fit-content padding-10-px box-shadow-none margin-top-20-px">

                        <div className=" box-shadow-none ">

                            <div className="card-input">

                                <label className="card-input__label">
                                    Contact Name*
                                </label>
                                <input
                                    type="text"
                                    className="card-input__input"
                                    autoComplete="off"

                                    value={newTask.contact_name}
                                    onChange={(e) => { updateNewTask("contact_name", e.currentTarget.value); }}

                                />


                            </div>





                            <div className="card-form__row">
                                <div className="card-form__col">
                                    <label className="card-input__label">
                                        Phone*
                                    </label>
                                    <input
                                        type="tel"
                                        className="card-input__input"
                                        autoComplete="off"

                                        value={newTask.phone_number}
                                        onChange={(e) => { updateNewTask("phone_number", e.currentTarget.value); }}

                                    />
                                </div>


                                <div className="card-form__col ">
                                    <div className="card-input">
                                        <label className="card-input__label">
                                            Ext.
                                        </label>
                                        <input
                                            type="tel"
                                            className="card-input__input"
                                            autoComplete="off"

                                            value={newTask.extension}
                                            onChange={(e) => { updateNewTask("extension", e.currentTarget.value); }}

                                        />
                                    </div>
                                </div>

                            </div>


                            <div className="card-input">
                                <label className="card-input__label">
                                    Email Address*
                                </label>
                                <input
                                    type="text"

                                    className="card-input__input"
                                    autoComplete="off"
                                    value={newTask?.email}
                                    onChange={(e) => { updateNewTask("email", e.currentTarget.value); }}
                                />
                            </div>

                        </div>



                    </div>
                    <h5 style={{ paddingLeft: '10px' }} className="wrap-none grey">Organization Information </h5>

                    <div className="card-form height-fit-content padding-10-px box-shadow-none margin-top-20-px">

                        <div className=" box-shadow-none ">
                            <div className="card-input">
                                <label className="card-input__label">
                                    Name*
                                </label>
                                <input
                                    type="text"

                                    className="card-input__input"
                                    autoComplete="off"
                                    value={newTask?.company_name}
                                    onChange={(e) => { updateNewTask("company_name", e.currentTarget.value); }}
                                />
                            </div>


                            <div className="card-form__row">
                                <div style={{ minWidth: '46%' }} className="card-form__col">

                                    <label

                                        className="card-input__label"
                                    >
                                        Type*
                                    </label>
                                    <select
                                        name="company_type"
                                        className="card-input__input -select"
                                        value={newTask?.company_type ?? ""}
                                        onChange={(e) => { updateNewTask("company_type", e.currentTarget.value); }}

                                    >

                                        <option value="Brokerage">Brokerage</option>
                                        <option value="Association">Association</option>

                                    </select>





                                </div>


                                <div className="card-form__col ">
                                    <div className="card-input">
                                        <label

                                            className="card-input__label"
                                        >
                                            Number of {newTask?.company_type === "Association" ? 'members' : 'agents'}*
                                        </label>
                                        <input
                                            type="text"
                                            className="card-input__input"
                                            autoComplete="off"
                                            value={newTask?.company_size}
                                            onChange={(e) => { updateNewTask("company_size", e.currentTarget.value); }}


                                        />
                                    </div>
                                </div>
                            </div>




                            <div className="card-input">
                                <label className="card-input__label">
                                    Address
                                </label>
                                <input
                                    type="text"
                                    className="card-input__input"
                                    autoComplete="off"

                                    value={newTask.address}
                                    onChange={(e) => { updateNewTask("address", e.currentTarget.value); }}

                                />
                            </div>



                            <div className="card-form__row">
                                <div className="card-form__col">

                                    <label

                                        className="card-input__label"
                                    >
                                        {newTask.country === "Canada" ? "Province*" : "State*"}
                                    </label>
                                    <select
                                        name="state"
                                        className="card-input__input -select"
                                        value={newTask?.state ?? ""}
                                        onChange={(e) => { updateNewTask("state", e.currentTarget.value); }}
                                    >
                                        {newTask.country !== "Canada" ?
                                            inputConstants?.US_STATES.map((val, index) => (
                                                <option key={index} value={val.value}>
                                                    {val.label}
                                                </option>
                                            )) : null}
                                        {newTask.country !== "United States" ?
                                            inputConstants?.CANDADIAN_PROVINCES.map((val, index) => (
                                                <option key={index} value={val.value}>
                                                    {val.label}
                                                </option>
                                            )) : null}
                                    </select>





                                </div>
                                <div className="card-form__col">
                                    <label

                                        className="card-input__label"
                                    >
                                        City
                                    </label>
                                    <input
                                        type="text"
                                        className="card-input__input"

                                        autoComplete="off"
                                        value={newTask?.city ?? ""}
                                        onChange={(e) => { updateNewTask("city", e.currentTarget.value); }}


                                    />

                                </div>

                                <div className="card-form__col ">
                                    <div className="card-input">
                                        <label

                                            className="card-input__label"
                                        >
                                            {newTask?.country === "Canada" ? "Postal Code*" : "Zip Code*"}
                                        </label>
                                        <input
                                            type="text"
                                            className="card-input__input"
                                            maxLength={newTask?.country === "Canada" ? "6" : "5"}
                                            autoComplete="off"
                                            value={newTask?.zip_code ?? ""}
                                            onChange={(e) => { updateNewTask("zip_code", e.currentTarget.value); }}


                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="card-input">
                                <label className="card-input__label">
                                    Country<span>*</span>
                                </label>
                                <select
                                    name="country"
                                    className="card-input__input -select"
                                    value={newTask?.country ?? ""}
                                    onChange={(e) => { updateNewTask("country", e.currentTarget.value); }}

                                >
                                    <option value="" >
                                        Select
                                    </option>

                                    <option value="United States" >
                                        United States
                                    </option>

                                   
                                        <option  value='Canada'>
                                            Canada
                                        </option>
                                </select>
                            </div>

                        </div>
                    </div>
                    <h5 style={{ paddingLeft: '10px' }} className="wrap-none grey">How did you hear about us?</h5>

                    <div className="card-form height-fit-content padding-10-px box-shadow-none margin-top-20-px">

                        <div className=" box-shadow-none ">
                            <div className="card-input">

                                <input
                                    type="text"

                                    className="card-input__input"
                                    autoComplete="off"
                                    value={newTask?.heard_about_from}
                                    onChange={(e) => { updateNewTask("heard_about_from", e.currentTarget.value); }}
                                />
                            </div>
                        </div>
                        {loadedCaptcha &&
                            <ReCAPTCHA
                                style={{ display: "inline-block", marginTop: '20px' }}
                                theme="light"
                                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY_V2}
                                ref={captchaRef}
                                onChange={null}
                                asyncScriptOnLoad={null}
                            />
                        }
                        <button style={{ marginBottom: '40px' }} className="popup-submit-button Settings-Submit " type="submit" onClick={() => { submitForm() }}>Submit</button>

                    </div>

                </div>
            </div>

            <PopupMessage title={serverMessage?.title} message={serverMessage?.message} onConfirm={closePopup} show={serverMessage} />
        </>
    )
}

export default QuoteForm;