import React, { useEffect, useState, useRef, useContext, createContext } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";


import "./tasklist.component.css";


import PoweredBy from "../poweredby.component/poweredby.component";


import sqlService from "../../../services/sqlService";
import { HiVideoCamera } from "react-icons/hi";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import { CiEdit } from "react-icons/ci";
import { VscSave } from "react-icons/vsc";


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";



import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";


import SubHeader from "../../main.component/subHeader.component/subHeader.component";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faList, faEdit, faStar, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import searchUtility from "../../../functions/searchUtility";
import TableFooter from "../../utility.component/tableFooter.component";


import { UserContext } from "../../../App";


import ScrollToTop from "../../utility.component/scrollToTop.component";
import InputModal from "../../utility.component/inputModal.component/inputModal.component";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction';
import rrulePlugin from '@fullcalendar/rrule'
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import { ModalBody, ModalFooter, ModalHeader, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../../../../node_modules/react-vis/dist/style.css';
// import '../../../dash.component.css'
import { DiscreteColorLegend } from 'react-vis';
import timeUtility from "../../../functions/timeUtility";
import sortUtility from "../../../functions/sortUtility";


import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { BsStar } from "react-icons/bs";
import HoverHint from "../../utility.component/hoverHint.component/hoverHint.component";

import { VscSyncIgnored } from "react-icons/vsc";

import { faCircleXmark, faPlus } from "@fortawesome/free-solid-svg-icons";
import validateUtility from "../../../functions/validateUtility";
import inputConstants from "../../../constants/inputConstants";
import MultipleSelection from "../../utility.component/multipleSelection";

export default function TaskList() {
  const user = useContext(UserContext);
  const context = createContext();
  const [seasonFilter, setSeasonFilter] = useState("All");
  const [completedFilter, setCompletedFilter] = useState("All");


  const [filteredTasks, setFilteredTasks] = useState([]);
  const [filteredTasksCompleted, setFilteredTasksCompleted] = useState([]);


  const [tasks, setTasks] = useState([]);
  const [rankedTasks, setRankedTasks] = useState([]);
  const [repeatingTasks, setRepeatingTasks] = useState([]);


  const loaded = React.useRef(false);
  const rankedTasksLoaded = React.useRef(false);
  const homeImprovementTasksPhotosLoaded = React.useRef(false);
  const repeatingTasksLoaded = useRef(false);


  const [editingRow, setEditingRow] = useState(null);
  const [editingCell, setEditingCell] = useState(null);


  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredCell, setHoveredCell] = useState(null);


  const [valueOnEdit, setValueOnEdit] = useState(null);


  const [videoView, setVideoView] = useState(false);
  const [videoLink, setVideoLink] = useState(null);


  const [taskModal, setTaskModal] = useState(false);
  const [homeImprovementModal, setHomeImprovementModal] = useState(false);
  const [taskEditModal, setTaskEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteAll, setDeleteAll] = useState(false);

  const [showOptout, setShowOptout] = useState(false);


  const [taskDropdown, setTaskDropdown] = useState('');
  const [repeatingDropdown, setRepeatingDropdown] = useState(0);
  const [durationDropdown, setDurationDropdown] = useState(0);


  const COLORS = ['#f8a025', '#2596be', '#34378b', '#aeb1ff', '#b179fc', '#8ceffc', '#f7ef7f', '#fc8cfc']
  const [homeImprovementRequestsPhotos, setHomeImprovementRequestsPhotos] = useState([]);

  const [completeHomeImporvementTask, setCompleteHomeImporvementTask] = useState(false);
  const [showCompletionDetails, setShowCompletionDetails] = useState(false);
  const [allPros, setAllPros] = useState([]);

  const [scrollCurrentPage, setScrollCurrentPage] = useState(1)
  const [scrollTotalPages, setScrollTotalPages] = useState(1)

  const taskValues = {
    "titleText": { label: 'Title', key: 'title' },
    "taskDate": { label: 'Date', key: 'start_date' },
    "noteText": { label: 'Notes', key: 'note', long: true },
    "categoryDropdown": {
      label: 'Category', key: 'category', dropdown: ['General Repairs', 'House Cleaning', 'Outdoor Maintenance', 'Custom'],
      value: [taskDropdown, setTaskDropdown], hasPopUp: true, textInput: true
    }
  }
  const homeImprovementValues = {
    "titleText": { label: 'Title', key: 'title' },
    "taskDate": { label: 'Date', key: 'start_date' },
    "noteText": { label: 'Details', key: 'note', long: true },
    "categoryFixed": { label: 'Category', key: 'Home Improvement' },
    "homeImprovementRequestsPhotos": { label: 'Photos', key: 'homeImprovementRequestsPhotos' },

  }


  const advancedTaskValues = {
    "videoText": { label: 'Video link', key: 'videoLink' },
    "dueDate": { label: 'Due', key: 'due_date', hint: 'Set a due date if you want to create a non-repeating task that spans multiple days' },
    "repeatingDropdown": {
      label: 'Repeating', key: 'repeating', dropdown: ['Weekly', 'Monthly', 'Yearly'], value: [repeatingDropdown, setRepeatingDropdown],
      submenus: {
        menus: [['One Month', 'Six Months', 'One Year'], ['Six Months', 'One Year'], ['Five Years', 'Ten Years']],
        label: 'Duration',
        key: 'repeating_duration',
        value: [durationDropdown, setDurationDropdown],
        textInput: true
      },
      // hint: 'Weekly and monthly tasks will schedule for one year; yearly tasks for five years'
    }
  }


  const [newTask, setNewTask] = useState({
    title: null,
    note: null,
    start_date: new Date(),
    repeating: 0,
    repeating_duration: null,
    category: "Uncategorized",
    videoLink: null,
    due_date: null
  });

  function resetNewTask() {
    setNewTask({
      title: null,
      note: null,
      start_date: new Date(),
      repeating: 0,
      repeating_duration: null,
      category: "Uncategorized",
      videoLink: null,
      due_date: null
    });

  }


  const [refresh, setRefresh] = useState(false);

  const [show, setShow] = useState(false);

  const tableRef = useRef();
  const [tableHeight, setTableHeight] = useState(0);
  useEffect(() => { setTableHeight(tableRef?.current?.clientHeight); });






  /* delete pop logic below */


  const Navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      Navigate("/")
    }
  })




  const [popUp, setPopUp] = useState(false);


  const openPopUp = () => {
    setPopUp(true);
  };


  const closePopUp = () => {
    setPopUp(false);
  };


  const [taskToDelete, setTaskToDelete] = useState(null);


  {
    /*ends here */
  }


  const handleClose = () => {
    setVideoView(false);
  };


  const handleDeleteClose = () => {
    setDeleteModal(false);
    setDeleteAll(false);
  }


  const [categories, setCategories] = useState({});


  const handleAddClose = () => {
    setTaskModal(false);
    setHomeImprovementModal(false);
    resetDropdowns();
    resetNewTask();

  };


  const handleEditClose = () => {
    setTaskEditModal(false);
    resetDropdowns();
    resetNewTask();
  }

  const resetDropdowns = () => {
    setTaskDropdown('');
    setRepeatingDropdown(0);

  }


  function updateNewTask(key, value) {
    // console.log("GGGFGFGFGFGFGF",key, value )
    let nTask = { ...newTask };
    nTask[key] = value;
    setNewTask(nTask);
  }


  {
    /*pagination logic */
  }
  const tasksPerPage = 5;


  const [currentPage, setCurrentPage] = useState(1);
  const currentPageRef = useRef(1);

  const [currentPageComplete, setCurrentPageComplete] = useState(1);
  const currentPageRefComplete = useRef(1);

  const indexOfLastRoom = currentPage * tasksPerPage;
  const indexOfFirstRoom = indexOfLastRoom - tasksPerPage;

  const indexOfLastRoomComplete = currentPageComplete * tasksPerPage;
  const indexOfFirstRoomComplete = indexOfLastRoomComplete - tasksPerPage;


  const [totalPages, setTotalPages] = useState(0);
  const [totalPagesComplete, setTotalPagesComplete] = useState(0);


  const [filteredPaginatedTasks, setFilteredPaginatedTasks] = useState([]);
  const [filteredPaginatedTasksCompleted, setFilteredPaginatedTasksCompleted] = useState([]);


  const [upcomingTasks, setUpcomingTasks] = useState([]);
  const [currentTasks, setCurrentTasks] = useState([]);
  const [historyOfCompletedTasks, setHistoryOfCompletedTasks] = useState([]);



  // useEffect(() => {
  //   setTotalPages(Math.ceil(filteredTasks.length / tasksPerPage));
  // }, [filteredTasks]);

  // useEffect(() => {
  //   setTotalPagesComplete(Math.ceil(filteredTasksCompleted.length / tasksPerPage));
  // }, [filteredTasksCompleted]);

  useEffect(() => {
    const indexOfLastRoom = currentPage * tasksPerPage;
    const indexOfFirstRoom = indexOfLastRoom - tasksPerPage;
    // const currentTasks = filteredTasks.slice(indexOfFirstRoom, indexOfLastRoom);
    setFilteredPaginatedTasks(filteredTasks);
  }, [currentPage, filteredTasks]);

  useEffect(() => {
    const indexOfLastRoomComplete = currentPageComplete * tasksPerPage;
    const indexOfFirstRoom = indexOfLastRoomComplete - tasksPerPage;
    // const currentTasksCompleted = filteredTasksCompleted.slice(indexOfFirstRoom, indexOfLastRoomComplete);
    setFilteredPaginatedTasksCompleted(filteredTasksCompleted);
  }, [currentPageComplete, filteredTasksCompleted]);


  // useEffect(() => {
  //   const currentTasks = filteredTasks.slice(indexOfFirstRoom, indexOfLastRoom);
  //   setFilteredPaginatedTasks(currentTasks);
  // }, [indexOfFirstRoom, indexOfLastRoom, filteredTasks]);

  // useEffect(() => {
  //   const currentTasksCompleted = filteredTasksCompleted.slice(indexOfFirstRoomComplete, indexOfLastRoomComplete);
  //   setFilteredPaginatedTasksCompleted(currentTasksCompleted);

  // }, [indexOfFirstRoomComplete, indexOfLastRoomComplete, filteredTasksCompleted]);


  {
    /*ends here */
  }


  const [serverMessage, setServerMessage] = useState({
    color: "none",
    message: "",
  });




  // to add a new task in the task table
  function submitNewTask() {
    // console.log(">>>>>>", newTask);
    sqlService


      .addTask({ user: user, input: newTask })
      .then((data) => {
        setServerMessage({
          color: "green",
          message: "Task is Added Successfully",
        });
        setTimeout(() => {
          setServerMessage({ color: "none", message: "" });
        }, 3000);
        loaded.current = false;
        repeatingTasksLoaded.current = false;
        rankedTasksLoaded.current = false;
        homeImprovementTasksPhotosLoaded.current = false;
        setRefresh(!refresh);
        handleAddClose()
      })
      .catch((err) => {
        setServerMessage({ color: "red", message: "Something Went Wrong" });
        setTimeout(() => {
          setServerMessage({ color: "none", message: "" });
        }, 3000);
      });
  }

  function submitHomeImprovement(obj) {
    // console.log(">>>>>>", newTask);
    if (obj?.homeImprovementImgOne?.oldLink) {
      sqlService.deleteAwsImage({ input: { "link": obj?.homeImprovementImgOne?.oldLink, "imgSubfolder": "client/homeowner/property_renovation_request_photos" } }).then(d => {
        console.log(d)
      }).catch(err => {
        console.log(err);
      })
    }
    if (obj?.homeImprovementImgTwo?.oldLink) {
      sqlService.deleteAwsImage({ input: { "link": obj?.homeImprovementImgTwo?.oldLink, "imgSubfolder": "client/homeowner/property_renovation_request_photos" } }).then(d => {
        console.log(d)
      }).catch(err => {
        console.log(err);
      })
    }
    sqlService
      .addTask({ user: user, input: { ...newTask, "home_improvement": true, "home_improvement_photos": obj } })
      .then((data) => {
        setServerMessage({
          color: "green",
          message: "Home Improvement is Added Successfully",
        });
        setTimeout(() => {
          setServerMessage({ color: "none", message: "" });
        }, 3000);
        loaded.current = false;
        repeatingTasksLoaded.current = false;
        rankedTasksLoaded.current = false;
        homeImprovementTasksPhotosLoaded.current = false;
        setRefresh(!refresh);
        handleAddClose()
      })
      .catch((err) => {
        setServerMessage({ color: "red", message: "Something Went Wrong" });
        setTimeout(() => {
          setServerMessage({ color: "none", message: "" });
        }, 3000);
      });
  }


  function editTask(obj) {
    // console.log(newTask)
    // console.log(current)
    // console.log(obj)
    if (current?.category === "Home Improvement") {
      newTask["category"] = "Home Improvement";
      delete newTask["homeImprovementRequestsPhotos"]
      if (obj) {
        sqlService.updateHomeImprovementRequestPhotos({ input: { "task_id": current?.task_id, "photos": obj } }).then(d => {
          console.log(d)
        }).catch(err => {
          console.log(err);
        })
      }
    }
    if (current.repeating !== newTask.repeating || current.repeating_duration !== newTask.repeating_duration) {
      sqlService


        .addTask({ user: user, input: newTask })
        .then((data) => {

        })
        .catch((err) => {
          setServerMessage({ color: "red", message: "Something Went Wrong" });
          setTimeout(() => {
            setServerMessage({ color: "none", message: "" });
          }, 3000);
        });


      const id = current.repeating_id ?? current.task_id;
      sqlService
        .deleteTask({
          "user": user,
          input: {
            id: id,
            repeating: current.repeating_id,
            task_id: current.task_id,
            deleteAll: current.repeating ? true : false,
            universal: current.universal,
            universal_id: current.universal_id
          }
        })
        .then((data) => {
          setServerMessage({
            color: "green",
            message: "Task has been updated",
          });
          setTimeout(() => {
            setServerMessage({ color: "none", message: "" });
          }, 3000);
          handleEditClose()



        })
        .catch((err) => {
          console.log(err);
        });



    } else {


      for (const [key, value] of Object.entries(newTask)) {
        if (value) {
          console.log("updatint gggg ======", key, value);

          sqlService
            .editTask({
              "user": user,
              input: { cell: key, data: value, task_id: current.task_id, rt_id: current.repeating_id, d: newTask }
              // input: { task_date: current.task_date, task_id: current.task_id, rt_id: current.repeating_id, data: newTask}
            })
            .then((data) => {
              setServerMessage({
                color: "green",
                message: "Task has been updated",
              });
              setTimeout(() => {
                setServerMessage({ color: "none", message: "" });
              }, 3000);
              // Update value in current
              setCurrent(previousState => ({
                ...previousState,
                [key]: value
              }));
              handleEditClose()
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }


    repeatingTasksLoaded.current = false;
    homeImprovementTasksPhotosLoaded.current = false;
    setRefresh(!refresh);
    // resetNewTask();


  }


  function deleteTask() {
    const id = current.repeating_id ?? current.task_id;
    sqlService
      .deleteTask({
        "user": user,
        input: {
          id: id,
          repeating: current.repeating_id,
          task_id: current.task_id,
          deleteAll: deleteAll,
          universal: current.universal,
          universal_id: current.universal_id
        }
      })
      .then((data) => {
        setTaskEditModal(false);
        setTaskModal(false);
        setTaskView(false);
        setServerMessage({
          color: "green",
          message: "Task has been deleted",
        });
        setTimeout(() => {
          setServerMessage({ color: "none", message: "" });
        }, 3000);
        repeatingTasksLoaded.current = false;
        homeImprovementTasksPhotosLoaded.current = false;
        setRefresh(!refresh);
        setDeleteAll(false);
      })
      .catch((err) => {
        console.log(err);
      });


  }



  function optoutTask() {
    sqlService
      .optoutTask({
        "user": user,
        input: {
          universal_id: current.universal_id
        }
      })
      .then(async (data) => {
        await getCalendarTasks().then(d => {
          setShowOptout(false);
          setTaskEditModal(false);
          setTaskModal(false);
          setTaskView(false);
          setServerMessage({
            color: "green",
            message: "Task has been opted out",
          });
          setTimeout(() => {
            setServerMessage({ color: "none", message: "" });
          }, 3000);
          repeatingTasksLoaded.current = false;
          homeImprovementTasksPhotosLoaded.current = false;
          setRefresh(!refresh);
        }).catch(err => {
          console.log(err)
        })

      })
      .catch((err) => {
        console.log(err);
      });


  }




  const addTask = () => {
    setTaskModal(true);
    setRefresh(!refresh);
  };
  const addHomeImprovement = () => {
    setHomeImprovementModal(true);
    setRefresh(!refresh);
  };


  {
    /*search bar function */
  }


  const [searchBarValue, setSearchBarValue] = useState("");


  const prof = useRef([]);


  const onSearchBarChange = (e) => {
    setSearchBarValue(e.currentTarget.value);
    onSearch(e.currentTarget.value)
  };


  const [assignedTasks, setAssignedTasks] = useState([])

  const getCalendarTasks = async () => {
    return new Promise((resolve, reject) => {
      sqlService
        .getRepeatingTasks({ "user": user })
        .then(async (data) => {
          var reptasks = data.data;
          setAssignedTasks(reptasks)

          if (user.utc_offset) {
            var fixedDateTasks = [];
            for (let task of reptasks) {
              task["task_date"] = timeUtility.utcOffsetDate(user.utc_offset, task.task_date);
              if (task?.repeating_date) {
                timeUtility.utcOffsetDate(user.utc_offset, task.repeating_date)
              }
              // task["creation_date"] = timeUtility.utcOffsetDate(user.utc_offset, task.start_date);
              fixedDateTasks.push(task);
            }
            reptasks = [...fixedDateTasks];
          }

          await sqlService.getAllUniversalTasksFromHomeOwnerAdminThatUserNotOptedOut({}).then(data => {
            let uniTasksData = data.data;
            // console.log(uniTasksData)
            if (uniTasksData) {
              var fieldMatchedUniversalTasks = []
              for (let task of uniTasksData) {
                let item = {}
                item["category"] = task?.category ?? "";
                item["repeating_duration"] = null;
                item["note"] = task?.note ?? "";
                item["repeating"] = task?.repeating ?? 0;
                item["repeating_completed"] = null;
                item["repeating_date"] = null;
                item["repeating_id"] = null;
                item["task_complete"] = task?.completed ?? 0;
                if (user.utc_offset) {
                  item["task_date"] = timeUtility.utcOffsetDate(user.utc_offset, task.start_date);
                  item["due_date"] = timeUtility.utcOffsetDate(user.utc_offset, task.due_date);


                } else {
                  item["task_date"] = task?.start_date ?? null;
                  item["due_date"] = task?.due_date ?? "";
                }
                item["task_id"] = task?.id ?? null;
                item["title"] = task?.title ?? "";
                item["universal"] = 1;
                item["universal_id"] = task?.universal_id ?? "";
                item["videoLink"] = task?.videoLink ?? null;
                item["renderAsUniversal"] = 1;

                fieldMatchedUniversalTasks.push(item);
              }
              reptasks = [...reptasks, ...fieldMatchedUniversalTasks]
            }
          }).catch(err => {
            console.log(err)
            reject(err);
          })



          // reptasks = [...reptasks].sort(function (a, b) {
          //   let aDate = a.universal ? a.due_date : a.repeating_date ?? a.task_date;
          //   let bDate = b.universal ? b.due_date : b.repeating_date ?? b.task_date;
          //   if (aDate > bDate) {
          //     return 1
          //   } else {
          //     return -1
          //   }

          // });



          const outstanding = reptasks.filter(task => !task.repeating_completed && !task.task_completed)
          // console.log(outstanding)
          const completed = reptasks.filter(task => task.repeating_completed || task.task_completed)
          // setRepeatingTasks(outstanding.concat(completed))
          setRepeatingTasks(reptasks)
          resolve("success")

        })
        .catch((err) => {
          console.log(err);
          reject(err)
        });

    });
  }


  useEffect(() => {
    console.log("dfdf")
    if (!repeatingTasksLoaded.current) {
      getCalendarTasks().then(d => {

        //
      }).catch(err => {
        console.log(err)
      })
      repeatingTasksLoaded.current = true;
    }
    if (!rankedTasksLoaded.current) {
      sqlService
        .getRankedTasks({})
        .then((data) => {
          const t = data.data;
          // console.log(t)
          sqlService.getAllUniversalTasksFromHomeOwnerAdminByCategoryThatHomeowenerIsNotAssignedWithNoTimeLimit({}).then(d => {
            // console.log(d)
            let dd = d.data;

            if (dd) {

              // Appending these two lists was causing an issue because the categories repeated. I am merging them like this for now
              // because neither list is that long but there's probably a better way to get all the catgories at once from the backend

              // setRankedTasks([...t, ...dd])
              // setRankedTasks(dd)

              let sorted = sortUtility.sort("category", [...dd, ...t], false)
              var temp = []
              for (let item of sorted) {
                if (temp.length && item.category === temp[temp.length - 1].category) {
                  temp[temp.length - 1].total += item.total

                } else {
                  temp.push(item)
                }
              }



              setRankedTasks(sortUtility.sort("total", temp, false))

            } else {
              setRankedTasks(t)
            }
          }).catch(err => {
            console.log(err)
          })

          // setRankedTasks(t);
        })
        .catch((err) => {
          console.log(err);
        });
      rankedTasksLoaded.current = true;
    }
    if (!loaded.current) {
      sqlService
        .getTasks({ user: user })
        .then((data) => {

          const tasks = data.data;
          const categories = {};
          categories["All"] = 1;
          for (let item of tasks) {
            categories[item.category] = 1;
          }
          setCategories(categories);

        })
        .catch((err) => {
          console.log(err);
        });
      loaded.current = true;
    }
    console.log("dbdbd", homeImprovementRequestsPhotos)
    if (!homeImprovementTasksPhotosLoaded.current) {
      homeImprovementTasksPhotosLoaded.current = true;
      console.log("getting photos")
      sqlService.getHomeImprovementRequestsPhotos({}).then(d => {
        console.log(d);
        setHomeImprovementRequestsPhotos(d.data);
      }).catch(err => {
        console.log(err);
      })
    }
  }, [refresh]);


  useEffect(() => {
    let filtered = currentTasks.filter((task) => {
      return (
        (task.repeating_completed == completedFilter || task.task_completed == completedFilter || completedFilter === "All") &&
        (task.category === seasonFilter || seasonFilter === "All")
      );
    });
    setFilteredTasks(filtered);
    setCurrentPage(currentPageRef.current)
  }, [seasonFilter, completedFilter, currentTasks]);

  useEffect(() => {
    let filtered = historyOfCompletedTasks.filter((task) => {
      return (
        (task.category === seasonFilter || seasonFilter === "All")
      );
    });
    setFilteredTasksCompleted(filtered);
    setCurrentPageComplete(currentPageRefComplete.current)
  }, [seasonFilter, historyOfCompletedTasks]);


  const onSearch = (value) => {
    if ((!value && searchBarValue === "") || (value === "")) {
      console.log("empty");
      if (toggleSelection === "ListCompleted") {
        setFilteredTasksCompleted(historyOfCompletedTasks);
      } else {
        setFilteredTasks(currentTasks);
      }
    } else {
      let searchedTasks = searchUtility.filterBySearch(
        toggleSelection === "ListCompleted" ? historyOfCompletedTasks : currentTasks,
        value ?? searchBarValue
      );
      if (toggleSelection === "ListCompleted") {
        setFilteredTasksCompleted(searchedTasks);
      } else {
        setFilteredTasks(searchedTasks);
      }
    }
    setCurrentPage(1);
    currentPageRef.current = 1;
    currentPageRefComplete.current = 1;
  };


  const nextPage = () => {
    if (toggleSelection === "ListCompleted") {
      setCurrentPageComplete((prev) => Math.min(prev + 1, totalPagesComplete));
      currentPageRefComplete.current = Math.min(currentPageRefComplete.current + 1, totalPagesComplete)
    } else {
      setCurrentPage((prev) => Math.min(prev + 1, totalPages));
      currentPageRef.current = Math.min(currentPageRef.current + 1, totalPages)
    }
  };


  const prevPage = () => {
    if (toggleSelection === "ListCompleted") {
      setCurrentPageComplete((prev) => Math.max(prev - 1, 1));
      currentPageRefComplete.current = Math.min(currentPageRefComplete.current - 1, 1)
    } else {
      setCurrentPage((prev) => Math.max(prev - 1, 1));
      currentPageRef.current = Math.min(currentPageRef.current - 1, 1)
    }

  };


  const [windowSize, setWindowSize] = React.useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = React.useState(document.body.scrollHeight)


  useEffect(() => {
    window.addEventListener("resize", onWindowResize);


    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);


  const onWindowResize = () => {
    setWindowSize(window.innerWidth);
    setWindowHeight(document.body.scrollHeight)
  };


  const [calData, setCalData] = useState([])

  const [current, setCurrent] = useState({})
  const [taskView, setTaskView] = useState(false)
  const handleTaskClose = () => {
    setTaskView(false)
  }


  const currentDate = new Date();
  currentDate.setHours(0, 0, 0)

  useEffect(() => {
    var currentTasks = []
    var historyCompletedTasks = []
    var upcomingTasks = []
    var plusTwoWeeks = new Date()
    plusTwoWeeks.setDate(plusTwoWeeks.getDate() + 14)
    var minusTwoWeeks = new Date()
    minusTwoWeeks.setDate(minusTwoWeeks.getDate() - 14)

    for (var task of repeatingTasks) {

      var d = task.repeating_date ?? task.task_date;
      d = new Date(d);
      d.setHours(0, 0, 0)
      // if (task.universal) {
      //   var due = task?.due_date;
      //   due = new Date(due);
      //   due.setHours(0, 0, 0)
      //   if (d <= currentDate && currentDate <= due) {
      //     currentTasks.push(task)

      //   }
      // }
      //  else if (!task.universal && d > minusTwoWeeks && d <= currentDate) {
      //   currentTasks.push(task)


      // } 
      if (d < plusTwoWeeks && d > currentDate) {
        upcomingTasks.push(task)
      }
    }

    console.log('assingedTasks', assignedTasks)
    for (var t of assignedTasks) {
      if (t.task_completed === 1) {
        historyCompletedTasks.push(t);
      }

      if (t.universal) {
        currentTasks.push(t)
      } else {
        var d = t.repeating_date ?? t.task_date;
        d = new Date(d);
        d.setHours(0, 0, 0)
        if (d > minusTwoWeeks && d <= currentDate) {
          currentTasks.push(t)

        }


      }


    }

    upcomingTasks = [...upcomingTasks].sort(function (a, b) {
      let aDate = a?.repeating_date ? new Date(a.repeating_date) : new Date(a.due_date);
      let bDate = b?.repeating_date ? new Date(b.repeating_date) : new Date(b.due_date);
      if (aDate > bDate) {
        return 1
      } else {
        return -1
      }

    });



    currentTasks = [...currentTasks].sort(function (a, b) {
      let aDate = a.universal ? new Date(a.due_date) : a?.repeating_date ? new Date(a.repeating_date) : new Date(a.task_date);
      let bDate = b.universal ? new Date(b.due_date) : b?.repeating_date ? new Date(b.repeating_date) : new Date(b.task_date);


      if (aDate > bDate) {
        return 1
      } else {
        return -1
      }


    });

    console.log("<><><><><><>", currentTasks, historyCompletedTasks)

    historyCompletedTasks = [...historyCompletedTasks].sort(function (a, b) {
      let aDate = a.completion_date ? new Date(a.completion_date) : new Date(a.due_date);
      let bDate = b.completion_date ? new Date(b.completion_date) : new Date(b.due_date);


      if (aDate > bDate) {
        return 1
      } else {
        return -1
      }


    });



    const outstanding = currentTasks.filter(task => !task.repeating_completed && !task.task_completed)
    // console.log(outstanding)
    const completed = currentTasks.filter(task => task.repeating_completed || task.task_completed)
    setUpcomingTasks(upcomingTasks)
    setCurrentTasks(outstanding.concat(completed))
    setHistoryOfCompletedTasks(historyCompletedTasks)

  }, [repeatingTasks, assignedTasks])


  // Compile data for calendar
  useEffect(() => {

    var data = []
    var l = []
    var id = 0

    for (var t of repeatingTasks) {

      var task = {
        id: id,
        task_id: (t.repeating_id ?? t.task_id),
        title: t.title ?? '',
        repeating: t.repeating,
        start: t.repeating_date ?? t.task_date,
        editable: true,
        color: t?.category === "Home Improvement" ? "red" : '#50fab9',
        end: !t.universal && !t.repeating ? t.due_date : null,
        allDay: true
      }

      for (let i = 0; i < rankedTasks.length && i < COLORS.length; i++) {
        if (rankedTasks[i].category === t.category && !(t.category === "Home Improvement")) {
          task.color = COLORS[i]
        }
      }

      if (t.task_completed || t.repeating_completed) {
        task.color = 'blue'
      }
      data.push(task)
      id++;


    }

    for (let i = 0; i < rankedTasks.length && i < COLORS.length; i++) {
      l.push({ title: rankedTasks[i].category, strokeWidth: 10, color: COLORS[i] })
    }

    l.push({ title: "Home Improvement", strokeWidth: 10, color: "red" })

    if (rankedTasks.length > COLORS.length) {

      l.push({ title: 'Other', strokeWidth: 10, color: '#50fab9' })
    }


    if (l.length > 0) {
      l.unshift({ title: 'Completed', strokeWidth: 10, color: 'blue' })
    }
    // console.log(calData)
    setCalData(data)
    setLabels(l)



  }, [rankedTasks, repeatingTasks])


  const [labels, setLabels] = useState([])

  const [toggleSelection, setToggleSelection] = useState('Calendar')

  const [completedPopup, setCompletedPopup] = useState(false);
  const [completedMessage, setCompletedMessage] = useState('')
  const [popUpX, setPopUpX] = useState(0);
  const [popUpY, setPopUpY] = useState(0);
  const [hintY, setHintY] = useState(0);
  const [starMessage, setStarMessage] = useState('');
  const [panelShow, setPanelShow] = useState(false);

  function completeTask(task = current) {
    if (task?.category === "Home Improvement") {
      setCurrent(task);
      getPros();
      setTaskView(true);
      setCompleteHomeImporvementTask(true);
      return
    }
    const type = task.repeating_date ? 'repeating_completed' : 'task_completed'
    var completed = task.repeating_completed ?? task.task_completed;
    completed = 1 - completed;
    if (completed) {
      setCompletedMessage('Moved to completed')

    } else {
      setCompletedMessage('Moved to outstanding')

    }

    const id = task.repeating_id ?? task.task_id;
    sqlService
      .updateCompletedTask({
        "user": user,
        input: { id: id, repeating: task.repeating_id, completed: completed }
      })
      .then((data) => {
        setCompletedPopup(true);
        setTimeout(() => {
          setCompletedPopup(false)
        }, 2000);
        repeatingTasksLoaded.current = false;
        setRefresh(!refresh);
        setCurrent(previousState => ({
          ...previousState,
          [type]: completed
        }));

      })
      .catch((err) => {
        console.log(err);
      });



  }


  async function hanldeCompleteHomeImporvementTask(task = current) {
    const type = 'task_completed';
    var completed = 1;
    var added = false;
    await sqlService.addHomeownerPropertyRenovation({
      input: {
        taskId: task?.task_id,
        renovationTitle: renovationTitle,
        renovationDetails: JSON.stringify({ details: [renovationDetails] }),
        renovationCost: renovationCost,
        selectedPro: parseInt(selectedPro),
        customPro: customPro,
        homeRenovationImages: {
          one: homeImprovementImgOne,
          two: homeImprovementImgTwo
        }
      }
    }).then(d => {
      added = true;
    }).catch(err => {
      console.log(err)
      added = false;
    })

    console.log("added", added)

    if (!added) {
      setCompletedMessage('Something went wrong')
      return
    }
    console.log("ghhhhhhhhhhhhhhhh")
    setCompletedMessage('Moved to completed')


    const id = task.task_id;
    sqlService
      .updateCompletedTask({
        "user": user,
        input: { id: id, repeating: task.repeating_id, completed: completed }
      })
      .then((data) => {
        setCompletedPopup(true);
        setTimeout(() => {
          setCompletedPopup(false)
        }, 2000);
        repeatingTasksLoaded.current = false;
        setRefresh(!refresh);
        setCurrent(previousState => ({
          ...previousState,
          [type]: completed
        }));

      })
      .catch((err) => {
        console.log(err);
      });



  }


  const getTaskPhotos = (taskId) => {
    var ph = homeImprovementRequestsPhotos?.filter(item => item?.task_id?.toString() === taskId?.toString());
    // console.log(taskId, ph)
    if (!ph) {
      ph = [];
    }
    return ph;
  }

  const getPaginatedListTasks = () => {
    if (toggleSelection === "ListCompleted") {
      return filteredPaginatedTasksCompleted
    } else {
      return filteredPaginatedTasks
    }
  }

  const [homeImprovementImgOne, setHomeImprovementImgOne] = useState(null)
  const [homeImprovementImgTwo, setHomeImprovementImgTwo] = useState(null)

  const uploadHomeImprovementPhoto = async (event, key) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    if (!validateUtility.validFileSize(file)) {
      setServerMessage({ "message": `File size must be less than ${inputConstants.MAX_FILE_SIZE / 1000000}mb`, "title": "Error" })
      return;
    }

    var imgValues = {}
    if (key === "HomeImprovementImgOne") {
      if (homeImprovementImgOne?.oldLink) {
        sqlService.deleteAwsImage({ input: { "link": homeImprovementImgOne?.oldLink, "imgSubfolder": "client/homeowner/property_renovation_photos" } }).then(d => {
          console.log(d)
        }).catch(err => {
          console.log(err);
        })
      }
      if (homeImprovementImgOne?.link) {
        imgValues = { "oldLink": homeImprovementImgOne?.link }
      }
    } else if (key === "HomeImprovementImgTwo") {
      if (homeImprovementImgTwo?.oldLink) {
        sqlService.deleteAwsImage({ input: { "link": homeImprovementImgTwo?.oldLink, "imgSubfolder": "client/homeowner/property_renovation_photos" } }).then(d => {
          console.log(d)
        }).catch(err => {
          console.log(err);
        })
      }
      if (homeImprovementImgTwo?.link) {
        imgValues = { "oldLink": homeImprovementImgTwo?.link }
      }
    }

    reader.onload = async () => {
      let data = reader.result;
      let t = file.type;
      let type = t.split("/")[1]
      // let subFolder = (key === "logo_link") ? "logos" : "imgs"
      let fileObject = { "imgFile": data, "imgType": type, "imgSubfolder": "client/homeowner/property_renovation_photos" }
      //   setInputData({ ...inputData, "img_link": fileObject });
      //   setBlogImg(fileObject)
      await sqlService.uploadImgAws({ input: fileObject }).then(lnk => {
        console.log(lnk);
        lnk = lnk?.data?.link;
        console.log(lnk)
        if (key === "HomeImprovementImgOne") {
          setHomeImprovementImgOne({ ...imgValues, "link": lnk });
        } else if (key === "HomeImprovementImgTwo") {
          setHomeImprovementImgTwo({ ...imgValues, "link": lnk })
        }
      }).catch(err => {
        console.log(err);
      })
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImg = (key) => {
    if (key === "one") {
      sqlService.deleteAwsImage({ input: { "link": homeImprovementImgOne?.link, "imgSubfolder": "client/homeowner/property_renovation_photos" } }).then(d => {
        console.log(d)
        setHomeImprovementImgOne({})
      }).catch(err => {
        console.log(err);
      })
    } else {
      sqlService.deleteAwsImage({ input: { "link": homeImprovementImgTwo?.link, "imgSubfolder": "client/homeowner/property_renovation_photos" } }).then(d => {
        console.log(d)
        setHomeImprovementImgTwo({})
      }).catch(err => {
        console.log(err);
      })
    }

  }


  useEffect(() => {
    getPros();
  }, [])

  const [selectedPro, setSelectedPro] = useState(null);
  const [renovationCost, setRenovationCost] = useState(null);
  const [renovationDetails, setRenovationDetails] = useState(null);
  const [renovationTitle, setRenovationTitle] = useState(null);
  const [customPro, setCustomPro] = useState(null)

  const getPros = () => {
    if (allPros.length === 0) {
      sqlService.getAllPros().then(d => {
        console.log(d.data?.filter((p) => p.state === user.state))
        setAllPros(d.data);
      }).catch(err => {
        console.log(err);
      });
    }
  }

  const handleCancelComplete = () => {
    if (homeImprovementImgOne?.link) {
      sqlService.deleteAwsImage({ input: { "link": homeImprovementImgOne?.link, "imgSubfolder": "client/homeowner/property_renovation_photos" } }).then(d => {
        console.log(d)
      }).catch(err => {
        console.log(err);
      })
    }
    if (homeImprovementImgTwo?.link) {
      sqlService.deleteAwsImage({ input: { "link": homeImprovementImgTwo?.link, "imgSubfolder": "client/homeowner/property_renovation_photos" } }).then(d => {
        console.log(d)
      }).catch(err => {
        console.log(err);
      })
    }
    setCompleteHomeImporvementTask(false);

  }


  const handleScrollingTable = (e) => {
    let element = e.target
    let pageNumber = Math.round(element.scrollTop / (element.clientHeight - 100)) + 1;
    setScrollCurrentPage(pageNumber);
    if (element.scrollHeight - Math.round(element.scrollTop) <= element.clientHeight) {
      setScrollCurrentPage(scrollTotalPages);
    }
  }


  useEffect(() => {
    let element = document.getElementById('tableScrollElementHoneyydoClient');
    if (element) {
      let totalPages = Math.round(element?.scrollHeight / (element?.clientHeight - 100));
      console.log(totalPages, element.scrollHeight, element.clientHeight)
      element.scrollTo({ top: 0 })
      setScrollCurrentPage(1);
      setScrollTotalPages(totalPages)
    }
  }, [windowSize, toggleSelection, filteredPaginatedTasks, filteredPaginatedTasksCompleted])

  return (
    <>
      {toggleSelection === 'List' && windowSize > 1500 ?

        <div className='Upcoming-Task-Panel box-shadow-top border-radius-left-10-px'>
          <h6 style={{ padding: '30px 0 10px 0' }} className="grey text-align-center">Upcoming Tasks</h6>

          {upcomingTasks.map((task) => (
            <div className="Upcoming-Grid border-bottom-solid ">
              <p className="Upcoming-Title">{task.title}</p>
              <p className="Upcoming-Date">{new Date(task.repeating_date ?? task.task_date).toLocaleString('default', { month: 'short', day: 'numeric' })}</p>
            </div>
          ))}

        </div>
        : toggleSelection === 'List' && windowSize < 1500 && windowSize > 850 ?
          panelShow ?

            <div className='Upcoming-Task-Panel box-shadow-top border-radius-left-10-px'>

              <FontAwesomeIcon className="fa-lg fa-flip-horizontal grey padding-10-px cursor-pointer" icon={faCaretLeft} onClick={() => setPanelShow(false)} />
              <h6 style={{ padding: '20px 0 30px 0' }} className="grey text-align-center">Upcoming Tasks</h6>

              {upcomingTasks.map((task) => (
                <div className="Upcoming-Grid border-bottom-solid " >
                  <p className="Upcoming-Title">{task.title}</p>
                  <p className="Upcoming-Date">{new Date(task.repeating_date ?? task.task_date).toLocaleString('default', { month: 'short', day: 'numeric' })}</p>
                </div>

              ))}

            </div>

            :
            <div className='Upcoming-Task-Panel box-shadow-top border-radius-left-10-px' style={{ width: '30px' }}>
              <FontAwesomeIcon className="fa-lg grey padding-10-px cursor-pointer" icon={faCaretLeft} onClick={() => setPanelShow(true)} />
            </div>
          : null


      }

      <div className="Task-Wrap">
        <div className="View-Toggle">
          <ToggleButtonGroup type='radio' name='view' className=" margin-top-20-px" value={toggleSelection} onChange={(val) => (setToggleSelection(val))}>
            <ToggleButton id={1} value="Calendar" variant='outline-secondary' >Calendar</ToggleButton>
            <ToggleButton id={2} value="List" variant='outline-secondary'  >List</ToggleButton>
            <ToggleButton id={3} value="ListCompleted" variant='outline-secondary'  >Completed</ToggleButton>
          </ToggleButtonGroup>
        </div>

        {toggleSelection === "Calendar" ?
          <button className="Impovment_Button" onClick={() => setHomeImprovementModal(true)}>Add Home Improvement</button> : null
        }

        {toggleSelection === 'Calendar' ?


          <div className="Calendar-Wrap">
            <FullCalendar className='Calendar'

              plugins={[dayGridPlugin, bootstrap5Plugin]}
              themeSystem="bootstrap5"
              initialView="dayGridMonth"
              events={calData}
              eventDisplay='block'
              displayEventTime={false}
              dayMaxEventRows={windowSize < 600 ? false : true}
              eventClick={(e) => (
                setCurrent(repeatingTasks[parseInt(e.event._def.publicId)]),
                setTaskView(true),
                console.log({ newTask }),
                console.log("rep tasks at id/current", repeatingTasks[parseInt(e.event._def.publicId)])
              )}
              headerToolbar={{
                left: `${windowSize < 480 ? '' : 'addButton'}`,
                center: `${windowSize < 480 ? 'title' : 'title'}`,
                right: `${windowSize < 480 ? 'addButton prev next' : 'prev next'}`
              }}
              customButtons={{
                addButton: {
                  text: 'Add Task',
                  click: (() => setTaskModal(true))
                }
              }}





            />
            <div>
              <DiscreteColorLegend
                items={labels}
                orientation='vertical'
                style={{ display: 'flex' }}
                className="justify-content-center flex-wrap padding-top-5-px"
                height={windowSize < 750 ? 150 : 100}


              >
              </DiscreteColorLegend>
            </div>
          </div>
          :


          <div className="Table-Wrap position-relative align-items-center display-flex flex-direction-column ">
            {toggleSelection === "List" ?
              <SubHeader
                fullWidth={true}
                pageName="Tasks"
                addbutton={addTask}
                addHomeImporvebutton={addHomeImprovement}
                buttonName="Add Task"
                showSearchBar={true}
                onSearchBarChange={onSearchBarChange}
                searchBarValue={searchBarValue}
                searchBarPlaceHolder={"Search Tasks"}
                onSearch={onSearch}
              /> :
              toggleSelection === "ListCompleted" ?
                <SubHeader
                  fullWidth={true}
                  pageName="Completed Tasks"
                  showSearchBar={true}
                  onSearchBarChange={onSearchBarChange}
                  searchBarValue={searchBarValue}
                  searchBarPlaceHolder={"Search Completed Tasks"}
                  onSearch={onSearch}
                /> : null
            }


            {windowSize > 850 ? (


              <>


                <div ref={tableRef} id="tableScrollElementHoneyydoClient" className="Table-List-Container padding-10-px overflow-x-hidden" onScroll={handleScrollingTable}>
                  <table className="Uni-Table-Container full-width-important">
                    <thead className="Uni-Table-Header ">
                      <tr>
                        <th>Done</th>
                        <th>
                          <select
                            style={{ width: '120px' }}
                            className="Task-Dropdown"
                            onChange={(e) => setCompletedFilter(e.target.value)}
                          >
                            <option value="All">All</option>
                            <option value="1">Completed</option>
                            <option value="0">Outstanding</option>
                          </select>
                        </th>
                        <th></th>
                        <th>Due</th>


                        <th>
                          <select
                            className="Task-Dropdown"
                            onChange={(e) => setSeasonFilter(e.target.value)}
                          >
                            {Object.keys(categories).map((key) => (
                              <option key={key} value={key}>{key}</option>
                            ))}
                          </select>
                        </th>
                        <th></th>
                        <th></th>

                      </tr>


                    </thead>
                    <tbody className="Uni-Table-Body" style={{ margin: '15px 0' }}>
                      {getPaginatedListTasks().map((task, index) => (
                        <tr key={task.id} >
                          <td ><input
                            type='checkbox'
                            className="Completed-Check-Table accent-light-blue"
                            checked={task.repeating_completed || task.task_completed || false}
                            onClick={(e) => { setPopUpY(e.pageY); setPopUpX(e.pageX) }}
                            onChange={() => { completeTask(getPaginatedListTasks()[index]) }}
                            disabled={task?.category === "Home Improvement" && task.task_completed} />
                          </td>

                          <td onClick={() => { setCurrent(getPaginatedListTasks()[index], task?.category === "Home Improvement" && task.task_completed ? setTaskView(true) : setTaskEditModal(true)) }}
                            className="bold">
                            <div className="display-flex flex-direction-row align-items-center ">
                              <FontAwesomeIcon
                                className={`fa-sm padding-right-10-px ${task.universal ? 'orange' : 'star-blue'}`}
                                icon={faStar}
                                onMouseEnter={(e) => {
                                  setShow(true);
                                  setHintY(e.pageY - 225);
                                  setStarMessage(task.universal ? 'Honeyydo recommended maintenance' : 'Custom task')
                                }}

                                onMouseLeave={() => setShow(false)}
                              />
                              <div style={{ top: hintY }} className={`star-hint border-radius-10-px ${show ? 'visibility-visible' : 'visibility-hidden'}`}>
                                <span>{starMessage}</span>
                              </div>
                              {task.title}
                            </div>
                          </td>
                          <td onClick={() => { setCurrent(getPaginatedListTasks()[index], task?.category === "Home Improvement" && task.task_completed ? setTaskView(true) : setTaskEditModal(true)) }} style={{ maxWidth: '200px' }}>{task.note}</td>
                          <td onClick={() => { setCurrent(getPaginatedListTasks()[index], task?.category === "Home Improvement" && task.task_completed ? setTaskView(true) : setTaskEditModal(true)) }}>{task.universal ? new Date(task.due_date).toLocaleString('default', { month: 'short', day: 'numeric' }) : null}</td>

                          <td onClick={() => { setCurrent(getPaginatedListTasks()[index], task?.category === "Home Improvement" && task.task_completed ? setTaskView(true) : setTaskEditModal(true)); }}>{task.category}</td>
                          <td>

                            {task.videoLink ?
                              <HiVideoCamera
                                className="cursor-pointer hover-icon"
                                onClick={() => {
                                  setVideoView(true);
                                  setVideoLink(task.videoLink ?? "");
                                }}

                              />
                              : null}
                          </td>
                          <td >
                            <div className="width-50-px">
                              {
                                task?.category === "Home Improvement" && task.task_completed ?
                                  null :
                                  <span className="float-right" >
                                    <FontAwesomeIcon
                                      className="hover-icon grey margin-right-20-px cursor-pointer"
                                      icon={faEdit}
                                      onClick={() => { setCurrent(getPaginatedListTasks()[index], setTaskEditModal(true)) }}
                                    />
                                    <FontAwesomeIcon
                                      className="hover-icon-red grey cursor-pointer"
                                      icon={faTrash}
                                      onClick={() => { setCurrent(getPaginatedListTasks()[index], setDeleteModal(true)) }}
                                    />

                                  </span>
                              }

                            </div>


                          </td>


                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>

              </>





            ) : (

              <>
                <div className="mobile-page-name-container margin-top-20-px">
                  {" "}
                  {/*mobile task list container */}
                  <h2 className="-mobile-page-name grey">Tasks</h2>
                </div>

                <div id="tableScrollElementHoneyydoClient" className="Table-List-Container padding-10-px overflow-x-hidden" onScroll={handleScrollingTable}>
                  <div className="tasklist-mobile-container">
                    {getPaginatedListTasks().length > 0
                      ? getPaginatedListTasks().map((task, index) => (
                        <div className="task-mobile-container" key={task.title}>
                          <div className="task-icon-checkmark">
                            <div className="icon-category-task-mobile">
                              <FontAwesomeIcon icon={faList} className="faList" />
                              <div className="task-category">
                                {task.category}
                              </div>
                            </div>
                            <div className="name-checkmark-mobile">
                              <div className="task-name">

                                {task.title}

                              </div>
                              <div className="task-completion-checkmark">

                                <input
                                  type='checkbox'
                                  className="Completed-Check accent-light-blue"
                                  checked={task.repeating_completed || task.task_completed || false}
                                  onClick={(e) => { setPopUpY(e.pageY); setPopUpX(e.pageX - 200) }}
                                  onChange={() => completeTask(getPaginatedListTasks()[index])} />

                              </div>
                            </div>
                          </div>
                          <div className="task-videolink">

                            <span
                              className="cursor-pointer hover-blue"
                              onClick={() => {
                                setVideoView(true);
                                setVideoLink(task.videoLink ?? "");
                              }}
                            >
                              {task.videoLink ?
                                <HiVideoCamera />
                                : null
                              }
                            </span>

                          </div>
                          <div className="task-notes">

                            {task.note}

                          </div>
                          <div className="task-mobile-footer">
                            <div className="due-date-task-container-mobile">
                              <span className="due-date-task-mobile">Date:</span>
                              <div className="task-due-date">

                                {new Date(task.repeating_date ?? task.task_date).toLocaleString('default', { month: 'short', day: 'numeric' })}

                              </div>
                            </div>
                            <span className="float-right" >
                              <FontAwesomeIcon
                                className="hover-icon grey margin-right-20-px cursor-pointer"
                                icon={faEdit}
                                onClick={() => { setCurrent(getPaginatedListTasks()[index], setTaskEditModal(true)) }}
                              />
                              <FontAwesomeIcon
                                className="hover-icon-red grey cursor-pointer"
                                icon={faTrash}
                                onClick={() => { setCurrent(getPaginatedListTasks()[index], setDeleteModal(true)) }}
                              />
                            </span>
                          </div>
                        </div>
                      ))
                      : null}
                  </div>
                </div>
              </>
            )}
            {/* <TableFooter
              currentPage={toggleSelection === "ListCompleted" ? currentPageComplete : currentPage}
              totalPages={toggleSelection === "ListCompleted" ? totalPagesComplete : totalPages}
              nextPage={nextPage}
              prevPage={prevPage}
              fullWidth={true}
            /> */}
            {getPaginatedListTasks().length > 0 ?

              <div className="center-wrapper margin-top-10-px">
                <TableFooter
                  currentPage={scrollCurrentPage}
                  totalPages={scrollTotalPages}
                  nextPage={() => {
                    let element = document.getElementById("tableScrollElementHoneyydoClient");
                    if (element) {
                      element.scrollBy({
                        top: element.clientHeight - 100,
                        behavior: "smooth",
                      })
                    }

                  }}
                  prevPage={() => {
                    let element = document.getElementById("tableScrollElementHoneyydoClient");
                    if (element) {
                      element.scrollBy({
                        top: - (element.clientHeight - 100),
                        behavior: "smooth",
                      })
                    }
                  }}
                />
              </div>
              : null}

            {toggleSelection === 'List' && windowSize < 850 ?

              <div className="Upcoming-Mobile-Container">
                <h6 style={{ padding: '20px 0 10px 0' }} className="grey text-align-center">Upcoming Tasks</h6>

                {upcomingTasks.map((task) => (
                  <div className="border-bottom-solid display-grid" style={{ margin: '5px auto', gridTemplateColumns: '70% 30%' }}>
                    <p style={{ fontSize: '.9em', textAlign: 'left' }}>{task.title}</p>
                    <p style={{ fontSize: '0.75em', alignSelf: 'right' }}>{new Date(task.repeating_date ?? task.task_date).toLocaleString('default', { month: 'short', day: 'numeric' })}</p>
                  </div>
                ))}
              </div>
              : null
            }


          </div>



        }









        {/*delete cabinet item pop up below */}
        {popUp && (
          <>
            <div className="backdrop"></div>
            <div className="popup">
              <FontAwesomeIcon
                className="ExTriangle"
                icon={faExclamationTriangle}
              />
              <h2 className="Pop-Up-Question">
                Are you sure you want to delete?
              </h2>
              <div className="Pop-Up-Buttons">
                <button
                  className="Delete-Button"
                  onClick={() => {
                    deleteTask();
                    // sqlService
                    //   .deleteTask({ user: user, input: { id: taskToDelete } })
                    //   .then(() => {
                    //     console.log("Delete successful");
                    //     loaded.current = false;
                    //     setRefresh(true);
                    //     closePopUp();
                    //   })
                    //   .catch((err) => {
                    //     console.log("Error deleting Task", err);
                    //   });
                  }}
                >
                  Delete
                </button>
                <button className="Cancel-Button" onClick={closePopUp}>
                  Cancel
                </button>
              </div>
            </div>

          </>
        )}
        <Modal
          show={videoView}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>

          </Modal.Header>
          <Modal.Body>
            <LiteYouTubeEmbed
              id={videoLink?.split('=')[1] ?? ""}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {taskModal ?
          <InputModal
            inputValues={taskValues}
            blurCondition={taskEditModal}
            show={taskModal}
            hide={handleAddClose}
            title="Add a Task"
            aggFunction={updateNewTask}
            submit={submitNewTask}
            serverMessage={serverMessage}
            advanced={advancedTaskValues}
            context={context}
            agg={newTask}
            skipValidation={true}
            itemId={current?.task_id}

          />
          : null
        }
        {homeImprovementModal ?
          <InputModal
            inputValues={homeImprovementValues}
            blurCondition={taskEditModal}
            show={homeImprovementModal}
            hide={() => { setHomeImprovementModal(false) }}
            title="Add Home Improvement"
            aggFunction={updateNewTask}
            submit={submitHomeImprovement}
            serverMessage={serverMessage}
            context={context}
            agg={newTask}
            skipValidation={true}
            setServerMessage={setServerMessage}
          />
          : null
        }

        {taskEditModal ?
          <InputModal
            inputValues={current?.category === "Home Improvement" ? homeImprovementValues : taskValues}
            advanced={current?.category === "Home Improvement" ? null : advancedTaskValues}
            show={taskEditModal}
            hide={handleEditClose}
            title='Edit Task'
            aggFunction={updateNewTask}
            agg={newTask}
            submit={editTask}
            serverMessage={serverMessage}
            filledval={{ ...current, "homeImprovementRequestsPhotos": getTaskPhotos(current?.task_id) }}
            skipValidation={true}
            setServerMessage={setServerMessage}

          />

          : null
        }
        <Modal
          className={deleteModal || showOptout ? "blur" : ''}
          show={taskView}
          onHide={handleTaskClose}

          size={current.category === "Home Improvement" && !current.task_completed && completeHomeImporvementTask ? "lg" : ""}
          keyboard={false}
          style={{ zIndex: '11' }}


        >
          <ModalHeader className="h4">
            {current?.title}
            {/* <p>{currentTask?.due}</p> */}
            {!current.renderAsUniversal ?
              <div className='display-flex'>
                {current.category === "Home Improvement" ?
                  current.task_completed ?
                    <span style={{ background: "lightgreen", fontSize: "16px", borderRadius: "10px", padding: "3px" }}>Complete</span> :
                    !completeHomeImporvementTask ?
                      <button className="Tasklist-Button-Small height-40-px" onClick={() => { getPros(); setCompleteHomeImporvementTask(true); }}>Mark as Complete</button> : null :
                  <>
                    <span className="point-5-em padding-x-10-px grey">Done</span>
                    <input type='checkbox' className='Completed-Check accent-blue' checked={current.repeating_completed ?? current.task_completed} onChange={() => completeTask()}></input>
                  </>
                }

              </div> : null
            }
            {current.renderAsUniversal ?

              <div className='display-flex justify-content-center align-items-center'>
                <span className="point-5-em padding-x-10-px grey">Opt Out</span>
                <span
                  className="grey cursor-pointer  "
                  onClick={() => {
                    setShowOptout(true)
                  }}
                >
                  <VscSyncIgnored />
                </span>
              </div> : null


            }

          </ModalHeader>
          <ModalBody>
            <div className="display-flex flex-direction-column column-gap-5-px row-gap-5-px">
              {current.category === "Home Improvement" && current.task_completed && showCompletionDetails ?
                <span>Details about the completion of the home improvement can be found in "My Property" page</span>
                : current.category === "Home Improvement" && !current.task_completed && completeHomeImporvementTask ?
                  <div className="display-flex flex-direction-column column-gap-10-px row-gap-10-px">
                    {/* <span>Title</span>
                    <input placeholder="Title" value={renovationTitle} onChange={(e) => { setRenovationTitle(e.currentTarget.value) }} className="Input-Style" type="text" /> */}
                    <span>Please provide some details about this home improvement task</span>
                    <textarea className="Input-Style-Text-Area" placeholder="Information about what has been done for the property" value={renovationDetails} onChange={(e) => { setRenovationDetails(e.currentTarget.value) }} />
                    <span>Approximately how much did you spend on this renovation?</span>
                    <div className="display-flex flex-wrap column-gap-5-px row-5-px align-items-center">
                      <span>$</span>
                      <input value={renovationCost} onChange={(e) => { setRenovationCost(e.currentTarget.value) }} className="Input-Style" type="number" />

                    </div>
                    <span>Did you use a professional to complete this improvement? If so, please enter their name below. Alternatively, if you used one of our pros, please select which one from the dropdown menu.</span>
                    {/* <span>If one of our pros helped you for this home improvement, please select them from the menu:</span> */}
                    <div className="display-flex column-gap-20-px">
                      <input value={customPro} onChange={(e) => { setCustomPro(e.currentTarget.value) }} placeholder="Pro Used" style={{ width: '200px' }} className="Input-Style"></input>
                      <select value={selectedPro} onChange={(e) => setSelectedPro(e.target.value)} className="Input-Style" style={{ width: '135px' }}>

                        <option value={null}>Select</option>
                        {allPros ? allPros?.filter((p) => p.state === user.state)?.map(item =>
                          <option value={item?.id}>{item.contact_name ?? item.company_name}</option>
                        ) : <option>No values</option>}
                      </select>
                    </div>

                    <span>Include some post-renovation images for your property report</span>
                    <div className=" position-relative">
                      <div className="display-flex flex-wrap column-gap-10-px row-gap-10-px margin-10-px">
                        <div className="Input_Modal_IMG_INPUT" >
                          <input className="display-none" type="file" id="HomeImprovementImgOne1" accept="image/*" onChange={(e) => uploadHomeImprovementPhoto(e, "HomeImprovementImgOne")} />
                          {homeImprovementImgOne && homeImprovementImgOne?.link ?
                            <div className="Home-Improvement-Img-Wrap">
                              <span className="Home-Improvement-Img-Delete" onClick={() => { handleDeleteImg("one") }}>
                                <FontAwesomeIcon className="Home-Improvement-Img-Delete-Icon" icon={faCircleXmark} />
                              </span>
                              <img onClick={() => { let inp = document.getElementById("HomeImprovementImgOne1"); if (inp) { inp.click() } }} className="Home-Improvement-Img" src={homeImprovementImgOne?.link} />
                            </div>
                            :
                            <div className="cursor-pointer" onClick={() => { let inp = document.getElementById("HomeImprovementImgOne1"); if (inp) { inp.click() } }}>
                              <FontAwesomeIcon
                                icon={faPlus}
                                className="Home-Improvement-plus-icon"
                              />
                            </div>

                          }
                        </div>
                        <div className="Input_Modal_IMG_INPUT">
                          <input className="display-none" type="file" id="HomeImprovementImgTwo1" accept="image/*" onChange={(e) => uploadHomeImprovementPhoto(e, "HomeImprovementImgTwo")} />
                          {homeImprovementImgTwo && homeImprovementImgTwo?.link ?
                            <div className="Home-Improvement-Img-Wrap">
                              <span className="Home-Improvement-Img-Delete" onClick={() => { handleDeleteImg("two") }}>
                                <FontAwesomeIcon className="Home-Improvement-Img-Delete-Icon" icon={faCircleXmark} />
                              </span>
                              <img onClick={() => { let inp = document.getElementById("HomeImprovementImgTwo1"); if (inp) { inp.click() } }} className="Home-Improvement-Img" src={homeImprovementImgTwo?.link} />
                            </div>
                            :
                            <div className="cursor-pointer" onClick={() => { let inp = document.getElementById("HomeImprovementImgTwo1"); if (inp) { inp.click() } }} >
                              <FontAwesomeIcon
                                icon={faPlus}
                                className="Home-Improvement-plus-icon"
                              />
                            </div>

                          }
                        </div>
                      </div>
                    </div>

                  </div> :
                  <>
                    <p>{current?.note}</p>
                    {
                      getTaskPhotos(current?.task_id).map((item, index) =>
                        <img src={item?.link} alt={`Home imporovement photo ${index}`} />
                      )
                    }
                  </>

              }

            </div>

          </ModalBody>
          <ModalFooter >
            {
              current.category === "Home Improvement" && current.task_completed ?
                showCompletionDetails ?
                  <>
                    <button className="Tasklist-Button-Small height-40-px " onClick={() => { setShowCompletionDetails(false) }}>Show Improvement Task</button>
                    <Link to={"/my-property"}>
                      <button className="Tasklist-Button-Small height-40-px " >Go To My Property Page</button>
                    </Link>
                  </> :
                  <button className="Tasklist-Button-Small height-40-px" onClick={() => { setShowCompletionDetails(true) }}>Show Completion Details</button> :
                completeHomeImporvementTask ?
                  <div>
                    <button className="Tasklist-Button-Small height-40-px" onClick={() => { hanldeCompleteHomeImporvementTask() }}>Complete</button>
                    <button style={{ backgroundColor: '#56596d' }} className="Tasklist-Button-Small height-40-px" onClick={() => { handleCancelComplete() }}>Cancel</button>
                  </div> :
                  <>

                    <span
                      className="cursor-pointer hover-blue"
                      onClick={() => {
                        setVideoView(true);
                        setVideoLink(current?.videoLink ?? "");
                      }}
                    > {current.videoLink ?
                      <HiVideoCamera className="fa-lg" />
                      : null
                      }
                    </span>
                    <div >
                      {!current.renderAsUniversal ?
                        <span
                          className="grey cursor-pointer margin-right-20-px"
                          onClick={() => {
                            setTaskView(false);
                            setTaskEditModal(true);




                            console.log({ current })


                          }}
                        >
                          <FontAwesomeIcon icon={faEdit} className="fa-lg" />
                        </span> : null
                      }

                      {!current.renderAsUniversal ?
                        <span
                          className="grey cursor-pointer  "
                          onClick={() => {
                            setDeleteModal(true)
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} className="fa-lg margin-right-3-px" />
                        </span> : null
                      }

                    </div>



                  </>

            }


          </ModalFooter>


        </Modal >


        <Modal


          show={deleteModal}
          onHide={handleDeleteClose}


          backdrop="static"
          keyboard={false}
          size="sm"
          style={{ top: '15%' }}
          focus={true}


        >
          <ModalBody >
            <p className="padding-10-15">Are you sure you want to delete?</p>
            <div className="display-flex justify-content-center">
              <span
                className="margin-right-10-px"
                onClick={() => {


                  deleteTask();
                  setDeleteModal(false);
                  // setDeleteAll(false);


                }}
              >
                <button className="Tasklist-Button height-40-px red">
                  Delete
                </button>
              </span>
              <Button variant="secondary height-40-px" onClick={handleDeleteClose}>
                Cancel
              </Button>
            </div>



          </ModalBody>
          {current.repeating || current.universal ?
            <ModalFooter className="Delete-Footer">
              {current.universal ?
                <HoverHint
                  hint={'If you change your mind, you can add Honeyydo tasks back under preferences in settings'}
                />
                : null
              }
              <p >
                Delete all occurances?

              </p>
              <input className='margin-left-10-px'
                type='checkbox'
                onChange={() => setDeleteAll(!deleteAll)}
              />
            </ModalFooter>
            : null
          }


        </Modal>




        <Modal


          show={showOptout}
          onHide={() => { setShowOptout(false) }}


          backdrop="static"
          keyboard={false}
          size="sm"
          style={{ top: '15%' }}
          focus={true}


        >
          <ModalBody >
            <p className="padding-10-15">Are you sure you want to opt out from this task, including all future occurances?</p>
            <div className="display-flex justify-content-center">
              <span
                className="margin-right-10-px"
                onClick={() => {


                  optoutTask();
                  setShowOptout(false);
                  // setDeleteAll(false);


                }}
              >
                <button className="Tasklist-Button height-40-px red">
                  Opt Out
                </button>
              </span>
              <Button variant="secondary height-40-px" onClick={() => { setShowOptout(false) }}>
                Cancel
              </Button>
            </div>



          </ModalBody>

        </Modal>





        {/* <Toast show={completedPopup} >
          <ToastHeader>
            <strong>Moved to completed</strong>
          </ToastHeader>
        </Toast> */}

        {completedPopup ?
          <TransitionGroup
            transitionName="Completed-Popup"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={300}
          >


            <div style={{ top: popUpY, left: popUpX }} className="Completed-Popup position-absolute z-1 border-radius-10-px">
              <span>{completedMessage}</span>

            </div>
          </TransitionGroup>
          : null

        }


        {/* <Modal
          show={taskModal}
          onHide={handleClose1}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>{"Add a Task"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="align-items-center padding-20-px">
              <div className="display-flex flex-direction-row margin-bottom-10-px">
                <span className="margin-right-10-px">Task Title</span>
                <input
                  className="border-radius-5-px border-style-solid flex-grow-1"
                  type="text"
                  value={newTask?.title ?? ""}
                  onChange={(e) => {
                    updateNewTask("title", e.currentTarget.value);
                  }}
                />
              </div>


              <div className=" display-flex flex-direction-row margin-bottom-10-px">
                <span className="margin-right-10-px">Task Note</span>
                <input
                  className="border-radius-5-px border-style-solid flex-grow-1"
                  type="text"
                  value={newTask?.note ?? ""}
                  onChange={(e) => {
                    updateNewTask("note", e.currentTarget.value);
                  }}
                />
              </div>


              <div className=" display-flex flex-direction-row margin-bottom-10-px">
                <span className="margin-right-10-px">Task Due</span>
                <span className="flex-grow-1">
                  <DatePicker
                    selected={newTask?.due ?? new Date()}
                    onChange={onChangeDateNewTask}
                  />
                </span>
              </div>


              <div className=" display-flex flex-direction-row margin-bottom-10-px">
                <span className="margin-right-10-px">Task Category</span>
                <select
                  onChange={(e) => updateNewTask("category", e.target.value)}
                  value={newTask?.category ?? ""}
                >
                  {Object.keys(categories).map((key) => (
                    <option value={key}>{key}</option>
                  ))}
                </select>
                <input
                  className="border-radius-5-px border-style-solid flex-grow-1"
                  type="text"
                  value={newTask?.category ?? "No Category"}
                  onChange={(e) => {
                    updateNewTask("category", e.currentTarget.value);
                  }}
                />
              </div>


              <div className=" display-flex flex-direction-row margin-bottom-10-px">
                <span className="margin-right-10-px">Task Video Link</span>
                <input
                  className="border-radius-5-px border-style-solid flex-grow-1"
                  type="text"
                  value={newTask?.videoLink ?? ""}
                  onChange={(e) => {
                    updateNewTask("videoLink", e.currentTarget.value);
                  }}
                />
              </div>


              <div className=" display-flex flex-direction-row margin-bottom-10-px">
                <span
                  className="margin-right-10-px"
                  onClick={() => {
                    submitNewTask();
                  }}
                >
                  <button className="Tasklist-Button">Add Task</button>
                </span>
                {serverMessage.color !== "none" ? (
                  <span
                    className={`${
                      serverMessage === "red"
                        ? "red-background"
                        : serverMessage === "green"
                        ? "green-background"
                        : ""
                    }`}
                  >
                    {serverMessage.message}
                  </span>
                ) : null}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal> */}
      </div>
      <PoweredBy />
      <ScrollToTop />


    </>
  );
}


//toUTCString()
//date in milli Date.parse



