import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/images/Honeyydo logo/Honeyydo-logo s.png";
import "./footer.component.css";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import Cookies from "universal-cookie";

/**
 *
 * Renders footer section of the application.
 * If under 850px, layout will change to mobile view.
 * It also allows email subscriptions that currently has not been setup as of yet.
 *
 */

function Footer() {
  const links = ["About", "FAQ", "Contact", "Blogs", "Affiliate Program"];
  const [path, setPath] = useState(window.location.pathname);
  const [email, setEmail] = useState("");
  const location = useLocation();
  const [windowSize, setWindowSize] = React.useState(window.innerWidth);
  const [cookie, setCookie] =  useState(new Cookies())
 
  function updateCookie() {
    document.cookie = `cookie_accept=${JSON.stringify({ "accepted_cookies": true})}; expires=${new Date(Date.now() + (30 * 86400000))}; path=/; sameSite=strict; secure`;
    setCookie(new Cookies())

  }

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  const onWindowResize = () => {
    setWindowSize(window.innerWidth);
  };

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  const handleSubscribe = () => {
    console.log("Subscribed with email:", email);
    // logic for subcribing will go here when ever we set something up
  };

  return (
    // <div className={path === "/" ? "display-none" : "footer"}>
    <div className="footer no-print">
      {!cookie?.get("cookie_accept")?.accepted_cookies ?
        <div className="Cookie-Popup box-shadow-2">
          <span> We use cookies to provide the best user experience possible. By using this website, you agree to the use of cookies.</span>
        <button className="popup-submit-button" onClick={()=>updateCookie()}>Accept & Close</button>
        </div> 
        : null}
      {/*desktop view*/}
      {windowSize > 750 ? (
        <>
        
          <div className="footer-content-container">
            <div className="footer-content">
              <div className="display-flex align-items-center">
              <div className="footer-logo-container">
                <Link to="/">
                  <img className="footer-logo" src={Logo} alt="Honeyydo logo" />
                </Link>
              </div>
              <div className="footer-links">
                  {links.map((link, index) => (
                    <Link
                      key={index}
                      target="_blank"
                      rel="noreferrer"
                      className="footer-link"
                      to={link.replace(/ /g, "-").toLowerCase()}
                    >
                      {link}
                    </Link>
                  ))}
                </div>
              </div>
              <div className="social-link-container">
                <div className="social-media-container">
                  <span>Follow Us</span>

                  <div className="social-icon-containers">
                    <a href="https://www.facebook.com/honeyydo" target="_blank">
                      <FontAwesomeIcon className="social-icon" icon={faFacebook} />
                    </a>

                    <a href="https://twitter.com/honeyydo" target="_blank">
                      <FontAwesomeIcon className="social-icon" icon={faTwitter} />
                    </a>

                    {/* <a href="https://www.linkedin.com/company/honeyydo/" target="_blank">
                      <FontAwesomeIcon className="social-icon" icon={faLinkedin} />
                    </a> */}

                    <a href="https://www.youtube.com/@Honeydo" target="_blank">
                      <FontAwesomeIcon className="social-icon" icon={faYoutube} />
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="subscribe-container">
                <h1 className="subcribe-title">SUBSCRIBE</h1>
                <input
                  type="email"
                  className="subscribe-input"
                  placeholder="Your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button className="subscribe-button" onClick={handleSubscribe}>
                  Subscribe
                </button>
              </div> */}
            </div>
          </div>
          <div className="footer-copyright-container">
            <div className="copyright">© 2023 Honeyydo Inc.</div>
            <div className="policy-links">
              <Link className="policy-link" to="/terms-of-service">
                Terms of Service
              </Link>
              <Link className="policy-link" to="/privacy-policy">
                Privacy Policy
              </Link>
            </div>
          </div>
        </>
      ) : (
        <>
          {/*mobile view*/}
          <div className="content-container">
            <div className="footer-content">
              <div className="footer-logo-container">
                <Link to="/">
                  <img className="footer-logo" src={Logo} alt="Logo" />
                </Link>
              </div>
              <div className="mobile-social-link-container">
                <div className="mobile-footer-links">
                  {links.map((link, index) => (
                    <Link
                      key={index}
                      className="footer-link"
                      to={link.replace(/ /g, "-").toLowerCase()}
                    >
                      {link}
                    </Link>
                  ))}
                </div>
                <div className="social-media-container">
                  <span>Follow Us</span>

                  <div className="social-icon-containers">
                    <a href="https://www.facebook.com/honeyydo" target="_blank">
                      <FontAwesomeIcon className="social-icon" icon={faFacebook} />
                    </a>

                    <a href="https://twitter.com/honeyydo" target="_blank">
                      <FontAwesomeIcon className="social-icon" icon={faTwitter} />
                    </a>

                    {/* <a href="https://www.linkedin.com/company/honeyydo/" target="_blank">
                      <FontAwesomeIcon className="social-icon" icon={faLinkedin} />
                    </a> */}

                    <a href="https://www.youtube.com/@Honeydo" target="_blank">
                      <FontAwesomeIcon className="social-icon" icon={faYoutube} />
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="subscribe-container">
                <h1 className="subcribe-title">SUBSCRIBE</h1>
                <input
                  type="email"
                  className="subscribe-input"
                  placeholder="Your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button className="subscribe-button" onClick={handleSubscribe}>
                  Subscribe
                </button>
              </div> */}
            </div>
          </div>
          <div className="footer-copyright-container">
            <div className="copyright">© 2023 Honeyydo Inc.</div>
            <div className="policy-links">
              <Link className="policy-link" to="/terms-of-service">
                Terms of Service
              </Link>
              <Link className="policy-link" to="/privacy-policy">
                Privacy Policy
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Footer;
