import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCalendar, faCalendarAlt, faCircleXmark, faLocationDot, faMapPin, faPlus, faSave, faUsers } from "@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlass, faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import inputConstants from "../../../constants/inputConstants";
import sqlService from "../../../services/sqlService";
import { useContext } from "react";
import { UserContext } from "../../../App";
import { FaCalendar } from "react-icons/fa";

export default function ClientDetailView() {
    const { state } = useLocation()
    const [client, setClient] = useState(state?.client);
    const [windowSize, setWindowSize] = React.useState(window.innerWidth);
    const [selectedTab, setSelectedTab] = useState('Info')
    const MAXLENGTH = 1000;
    const user = useContext(UserContext);
    const [popupMessage, setPopupMessage] = useState(null)
    const [importantDates, setImportantDates] = useState([])
    const [selectedDate, setSelectedDate] = useState({ month: '0', day: '1', title: '', reminder: false })
    const [hint, setHint] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [deletePopup, setDeletePopup] = useState(false)
    const [resendPopup, setResendPopup] = useState(false)


    useEffect(() => {

        window.addEventListener("resize", onWindowResize);

        return () => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, []);


    const onWindowResize = () => {
        setWindowSize(window.innerWidth);

    };

    const [activity, setActivity] = useState(null)

    useEffect(() => {

        sqlService
            .getUserActivity({ input: client.id })
            .then((data) => {


                setActivity(data.data[data.data.length - 1])


            })
            .catch((err) => {
                console.log(err);
            });

    }, []);

    function updateClient(field, value) {
        if (field === 'admin_note') {
            if (value.length > MAXLENGTH) {
                return

            }
        }
        var nClient = { ...client }
        nClient[field] = value;
        setClient(nClient)

    }

    function saveNote() {

        sqlService.addNoteForClient({ user: user, input: { id: client.id, note: client.note } }).then((d) => {
            // resetNote()
            // clientsLoaded.current = false;
            // setRefresh(!refresh)
            // setNoteDialog(0)
            setPopupMessage('Note Saved!')
            setTimeout(() => {
                setPopupMessage(null)
            }, 1500)
        }).catch((err) => {

        })


    }

    function resendInvitation(client) {
        sqlService.resendInvitation({ user: user, input: client }).then(() => {
            setResendPopup(false)
        
        }).catch((err) => {

        })
    }


    function saveDate() {

        sqlService.addImportantDates({ user: user, input: { dates: importantDates, homeowner: { id: client.id, name: client.contact_name } } }).then(() => {
            // clientsLoaded.current = false;
            // setRefresh(!refresh)
            // setDateDialog(0)


        }).catch((err) => {

        })


    }

    function updateSelectedDate(key, value) {
        var temp = { ...selectedDate }
        temp[key] = value
        setSelectedDate(temp)

    }

    function resetSelectedDate() {
        setSelectedDate({ month: '0', day: '1', title: '', reminder: false })

    }


    function addDate() {
        if (!selectedDate.title) {
            setPopupMessage('Please Enter a Title')
            setTimeout(() => {
                setPopupMessage(null)
            }, 1500)
            return;

        }

        var temp = [...importantDates]
        temp.push(selectedDate)
        resetSelectedDate();
        setImportantDates(temp)
        updateDates(temp)

    }

    useEffect(() => {
        var dates = []
        if (client.dates) {
            for (let date of client.dates.split('<<<!>>>')) {

                let d = date.split('***!***')
                dates.push({ id: d[4], month: d[0], day: d[1], title: d[2], reminder: d[3] })

            }

        }


        setImportantDates(dates)
    }, [])

    function removeDate() {
        let updated = [...importantDates]
        updated.splice(deletePopup, 1)

        setImportantDates(updated)
        updateDates(updated)
        setDeletePopup(false)

    }

    function updateDates(dates) {
        sqlService.addImportantDates({ user: user, input: { dates: dates, homeowner: { id: client.id, name: client.contact_name } } }).then(() => {
            // clientsLoaded.current = false;
            setRefresh(!refresh)
            // setDateDialog(0)


        }).catch((err) => {

        })
    }

    return (
        <div className="Detail-View-Page">
            <div className="Detail-Top-Bar">
                <h5>{`Client Details`}</h5>

            </div>
            <div className="Detail-View-Header ">
                {/* <div>
                        <img className="margin-auto-lr display-block" src={client?.img_link ?? Placeholder} />
                    </div> */}
                <div>
                    <h4>{client?.contact_name}</h4>

                    <div className="Details-Grid-Container">
                        <div>

                            <div className="Details-Grid">
                                <div>
                                    <h6>Phone Number</h6>
                                    <span>{client?.phone_number}</span>
                                </div>
                                <div>
                                    <h6>Email Address</h6>
                                    <span>{client?.email}</span>
                                </div>
                                {client?.utc_offset ?
                                    <div>
                                        <h6>Time Zone</h6>
                                        <span>{inputConstants.TIME_ZONES[client?.utc_offset]}</span>
                                    </div>
                                    : null}

                            </div>
                        </div>
                        <div>

                            {/* {client?.role_id === 2 ?
                                    <div>
                                        <h6>Brokerage Information</h6>
                                        <p>{userParent?.contact_name ?? clientInfo.brokerage_contact_name}</p>
                                        <p>{userParent?.phone_number ?? clientInfo.brokerage_phone_number}</p>
                                    </div> : client?.role_id === 1 ?
                                        <div>
                                            <h6>Agent Information</h6>
                                            <p>{userParent?.contact_name ?? clientInfo.agent_name}</p>
                                            <p>{userParent?.phone_number ?? clientInfo.agent_phone_number}</p>
                                        </div> : null} */}


                        </div>
                        {windowSize <= 900 ?
                            <div className="Account-Info " >
                                <div className="width-full-pt text-align-center padding-5-px" style={{ backgroundColor: 'lightgrey', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                                    <h5>Account Info</h5>

                                </div>
                                <div className="padding-10-px" >
                                    <div style={{ marginBottom: '10px' }}>
                                        <h6>Creation Date</h6>
                                        <span>{client?.creation_date.split('T')[0]}</span>
                                    </div>

                                    <div>
                                        <h6>Active</h6>
                                        {client?.finished_signup ?
                                        <span>Yes</span>
                                        :
                                        <div className="display-flex column-gap-10-px justify-content-space-between">
                                            <span>No</span>
                                            <button style={{fontSize: '.9em', padding: '5px'}} className="popup-submit-button" onClick={()=>setResendPopup(client)}>Resend Invite</button>
                                            </div>}
                                     
                                    </div>



                                </div>
                            </div>
                            : null}

                    </div>
                </div>
                {windowSize > 900 ?
                    <div className="Account-Info " style={{ marginLeft: '30px' }} >
                        <div className="width-full-pt text-align-center padding-5-px" style={{ backgroundColor: 'lightgrey', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                            <h5>Account Info</h5>

                        </div>
                        <div className="padding-10-px"  >
                            <div style={{ marginBottom: '20px' }}>
                                <h6>Creation Date</h6>
                                <span>{client?.creation_date?.split('T')[0]}</span>
                            </div>

                            <div>
                                <h6>Active</h6>
                                {client?.finished_signup ?
                                        <span>Yes</span>
                                        :
                                        <div className="display-flex column-gap-10-px justify-content-space-between">
                                            <span>No</span>
                                            <button style={{fontSize: '.9em', padding: '5px'}} className="popup-submit-button" onClick={()=>setResendPopup(client)}>Resend Invite</button>
                                            </div>}
                            </div>



                        </div>
                    </div>
                    : null}
            </div>
            <div className="Selection-Bar">
                <div className={`Selection-Bar-Tab cursor-pointer ${selectedTab === 'Info' ? 'Selection-Bar-Selected' : ''} `} onClick={() => setSelectedTab('Info')}>
                    Information
                </div>

                {/* <div className={`Selection-Bar-Tab cursor-pointer ${selectedTab === 'Subscription' ? 'Selection-Bar-Selected' : ''} `} onClick={() => setSelectedTab('Subscription')}>
                            Subscription
                        </div>  */}


                <div className={`Selection-Bar-Tab cursor-pointer ${selectedTab === 'Notes' ? 'Selection-Bar-Selected' : ''} `} onClick={() => setSelectedTab('Notes')}>
                    Notes
                </div>

            </div>
            <div className='Tab-Page'>
                {selectedTab === 'Info' ?
                    <div style={{ gap: '20px', width: `${windowSize < 600 ? '100%' : windowSize < 1000 ? '75%' : '50%'}`, marginLeft: `${windowSize < 600 ? '10px' : windowSize < 900 ? '20px' : '40px'}`  }} className="Toggle-Container display-flex flex-direction-column margin-right-10-px">
                        <div style={{ gap: '20px 75px', flexWrap: 'wrap', margin: '0 10px 0 0 ', width: '100%'}} className="align-self-start display-flex Current-Plan">
                            <div className="display-flex flex-direction-column">
                                <h5  className="bold grey margin-bottom-20-px"><FontAwesomeIcon className=" margin-right-10-px fa-sm" icon={faLocationDot}/>Address</h5>
                                <span>{client.address}</span>
                                <span>{client.city}</span>
                                <span>{client.state}</span>
                                <span>{client.zip_code}</span>
                            </div>
                            <div className="display-flex flex-direction-column">
                                <h5  className="bold grey margin-bottom-20-px"><FontAwesomeIcon className=" margin-right-10-px fa-sm" icon={faUsers}/>Referrals</h5>
                                <span>{client.referrals_number}</span>
                            </div>

                        </div>
                        <div style={{ width: '100%', minHeight: '400px', margin: '0 10px 30px 0 ' }} className="Current-Plan align-self-start">
                            {deletePopup ?
                                <>
                                    <div className="popup">
                                        <span style={{ padding: '20px 0' }}>Are you sure you want to delete?</span>
                                        <div className="display-flex column-gap-10-px margin-top-15-px">
                                            <button className="Cancel-Button" onClick={() => setDeletePopup(false)}>Close</button>
                                            <button className="Cancel-Button red" onClick={() => removeDate()}>Delete</button>
                                        </div>
                                    </div>
                                    <div className="backdrop"></div>

                                </> : null}
                            <h5 className="grey bold display-inline-block " ><FontAwesomeIcon className="margin-right-10-px fa-sm" icon={faCalendar}/>Important Dates</h5>

                            {importantDates.length ?

                                <div className="dates-grid" style={{ minWidth: '80%', marginTop: '20px' }}>
                                    {importantDates.map((date, index) =>
                                        <  >

                                            <span>{inputConstants.MONTH_ARRAY[date.month]} {date.day}</span>
                                            <span className="bold ">{date.title}
                                                {parseInt(date.reminder) || date.reminder === true ?
                                                    <div className="position-relative display-inline-block">
                                                        <FontAwesomeIcon icon={faBell} onMouseEnter={() => setHint(index)} onMouseLeave={() => setHint(false)} className="logo-orange margin-left-10-px" />
                                                        <div className={`position-absolute hint wrap-none ${hint === index ? 'display-block' : 'display-none'}`} style={{ top: '-30px' }}>Reminder Set</div>

                                                    </div>
                                                    : ''}
                                            </span>

                                            <FontAwesomeIcon style={{ alignSelf: 'center' }} className="cursor-pointer hover-red grey fa-sm" icon={faTrash} onClick={() => setDeletePopup(index)} />

                                        </>)}
                                </div>

                                : null}
                            <span style={{ fontSize: '0.9em', margin: '20px 0', display: 'inline-block', paddingRight: '10px' }}>Add any recurring dates you want to be reminded of for this client, such as their anniversary of home purchase or birthday</span>
                            {/* <label className="card-input__label">Title</label> */}


                            <div className="display-flex " style={{ columnGap: '10px', margin: '10px 0' }}>
                                <div style={{ width: '90%' }}>
                                    <input className="Input-Style width-full-pt" placeholder="Name" value={selectedDate.title} onChange={(e) => updateSelectedDate('title', e.target.value)}></input>
                                    <div style={{ gap: '10px 30px', marginTop: '15px' }} className="display-flex width-full-pt flex-wrap">
                                        <select value={selectedDate.month} onChange={(e) => updateSelectedDate('month', e.target.value)} className="Input-Style" style={{ maxWidth: '135px' }}>
                                            {inputConstants.MONTH_ARRAY.map((month, index) =>
                                                <option value={index}>{month}</option>
                                            )}
                                        </select>
                                        <select className="Input-Style" value={selectedDate.day} onChange={(e) => updateSelectedDate('day', e.target.value)}>
                                            {[...Array(inputConstants.MONTHS[inputConstants.MONTH_ARRAY[selectedDate.month]]).keys()].map((index) =>
                                                <option>{index + 1}</option>
                                            )}



                                        </select>

                                    </div>
                                    <div className="display-flex align-items-center margin-top-15-px position-relative">
                                        {popupMessage ? <div style={{ padding: '20px', right: '-50px', top: '-150px', zIndex: '1002', backgroundColor: 'white' }} className="position-absolute border-radius-10-px box-shadow-2 padding-40-px">
                                            <span className="bold color-med-grey wrap-none">{popupMessage}</span>
                                        </div> : null}

                                        <input className="margin-right-15-px" type="checkbox" name={"reminder"} checked={selectedDate.reminder || false} onChange={(e) => setSelectedDate({ ...selectedDate, [e.target.name]: e.target.checked })}></input>
                                        <span>Send me a reminder the day before</span>
                                    </div>
                                </div>
                                <button style={{ height: '30px', width: '30px', padding: '0', marginRight: '10px' }} onClick={() => addDate()} className="popup-submit-button">

                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                            </div>


                            {/* <div className="display-flex column-gap-10-px margin-top-15-px">
                                <button className="Cancel-Button" onClick={() => { resetSelectedDate() }}>Close</button>


                                <button className="popup-submit-button" onClick={() => saveDate()} >
                                    Save
                                    <FontAwesomeIcon className="fa-sm margin-left-5-px" icon={faSave} />
                                </button>
                            </div> */}

                        </div>

                    </div> : null}
                {selectedTab === 'Notes' ?
                    <div style={{ width: `${windowSize > 750 ? '50%' : '100%'}`, margin: '30px', position: 'relative' }}>
                        {popupMessage ? <div style={{ padding: '50px', left: '50%', bottom: '0', transform: 'translate(-50%, -50%)', zIndex: '1002', backgroundColor: 'white' }} className="position-absolute border-radius-10-px box-shadow-2 padding-40-px">
                            <span className="bold color-med-grey wrap-none">{popupMessage}</span>
                        </div> : null}
                        <textarea style={{ minHeight: '400px', width: '100%', backgroundColor: 'white' }} value={client?.note} onChange={(e) => updateClient('note', e.target.value)} className="Input-Style"></textarea>
                        <div style={{ justifyContent: 'right' }} className="width-full-pt display-flex ">
                            <span className="count">{client.note?.length ?? 0}/{MAXLENGTH}</span>
                        </div>
                        <div className="display-flex column-gap-10-px margin-top-15-px">

                            <button className="popup-submit-button display-block margin-auto-lr" onClick={() => saveNote()} >
                                Save
                            </button>
                        </div>
                    </div> : null}
            </div>
            {
                resendPopup ?
                    <>
                        <div className="backdrop"></div>
                        <div style={{ zIndex: '2000', minWidth: '350px', height: '250px' }} className="popup">
                            <h4 className="grey margin-bottom-30-px">Resend Invitation?</h4>
                            <p style={{padding: '0 5%'}}>We will send a reminder email to your client with a new temporary password so that they can log in and finish setting up their account</p>
                            <div className="display-flex column-gap-10-px">
                            <button className="popup-submit-button margin-top-30-px" onClick={() => (resendInvitation(resendPopup))}>
                               Send
                            </button>
                            <button className="Cancel-Button margin-top-30-px" onClick={() => (setResendPopup(false))}>
                                Cancel
                            </button>
                            </div>
                        </div>
                    </>

                    : null
            }
        </div>
    )
}