import "./invoice.css";
import React, { useEffect, useState } from 'react';
import honeyydoLogo from "../../../assets/images/Honeyydo logo/Honeyydo-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import userUtility from "../../../functions/userUtitily";
import { UserContext } from "../../../App";
import { useContext } from "react";
import html2pdf from 'html2pdf.js';
import timeUtility from "../../../functions/timeUtility";

const Invoice = () => {
    const Navigate = useNavigate();
    const user = useContext(UserContext);
    const handleDownloadPDF = () => {
        var element = document.getElementById('pdf-content');
        var opt = {
            margin: 0,
            filename: `honeyydo-invoice.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: {
                unit: 'mm',
                format: [200, 300],
                orientation: 'portrait'
            }
        };

        html2pdf().set(opt).from(element).save();
    };
    const [duration, setDuration] = useState("");
    const [amount, setAmount] = useState("");
    const [amountAfterDiscount, setAmountAfterDiscount] = useState("");
    const [orderId, setOrderId] = useState("");
    const [discount, setDiscount] = useState(null);
    const [created, setCreated] = useState("");

    const print = () => {
        const input = document.getElementById('pdf-content');
        window.print();
    };

    useEffect(()=>{
        const cookie = new Cookies();
        const userData = cookie.get("honeyydo_user_invoice");
        if (userData) {
          // setUser(userData.data)
          // console.log(JSON.parse(cookie))
          // setUser(cookie.getAll)
          console.log(userData);
          setDuration(userData?.subscription?.plan ?? "")
          var price = userData?.subscription?.price + " USD" ?? "";
          if(process.env.REACT_APP_ENV === "production"){
            price = `${userData?.subscription?.price / 100}} USD`
          }
          setAmount(price)

          var priceAfterDiscount = userData?.subscription?.priceAfterDiscount + " USD" ?? "";
          if(process.env.REACT_APP_ENV === "production"){
            priceAfterDiscount = `${userData?.subscription?.priceAfterDiscount / 100} USD`
          }
          setAmountAfterDiscount(priceAfterDiscount);
          

          setOrderId(userData?.subscription?.orderId ?? "");
          setDiscount(userData?.subscription?.discount ?? null);
          setCreated(userData?.subscription?.created);
        }else{
            if(user.role_id === 4){
                Navigate("/pro-dashboard");
            }else{
                Navigate("/");
            }
            // window.location.reload();
        }
    },[])
    return (
        <div className="Invoice-Wrap">
            <div className="Invoice-Card" id="pdf-content">
                <div className="Invoice-Top-Line">
                    <img className="Invoice-Card-Logo" src={honeyydoLogo} />
                    <span className="Invoice-Invoice-Text">Invoice</span>
                </div>
                <span className="align-text-left width-100-psnt bold margin-bottom-10-px">{`Honeyydo Order Id: ${orderId}`}</span>
                <span className="align-text-left width-100-psnt bold margin-bottom-10-px">{timeUtility.getTimeFromSecondsToString(parseInt(created ?? "0"))}</span>
                <span className="Invoice-Line-Break"></span>
                <table className="Invoice-Table">
                    <thead>
                        <tr>
                            <th>Product</th>
                            <th>Items</th>
                            <th>Duration</th>
                            <th>Amount</th>
                            <th>Discount</th>
                            <th>Charged</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Subscription</td>
                            <td>1</td>
                            <td>{duration}</td>
                            <td>${amount}</td>
                            <td>{discount ? discount + "%" : "0%"}</td>
                            <td>${amountAfterDiscount}</td>
                            <td>✓</td>

                        </tr>
                        <tr>
                            <td>Subtotal</td>
                            <td>{""}</td>
                            <td>{""}</td>
                            <td>${amount}</td>
                            <td>{""}</td>
                            <td>${amountAfterDiscount}</td>
                            <td>{""}</td>

                        </tr>
                    </tbody>
                </table>
                <span class="align-text-left width-100-psnt bold margin-bottom-10-px">Thank you for choosing Honeyydo</span>

            </div>

            <div className={`display-flex flex-wrap column-gap-20-px row-gap-20-px no-print`}>
                <div className="cursor-pointer" onClick={()=>{print()}}>
                    <FontAwesomeIcon
                        icon={faPrint}
                        className="print-icon"
                    />
                    <span>Print</span>
                </div>
                <button className="Invoice-Button" onClick={handleDownloadPDF}>Download PDF</button>
                <button className="Invoice-Button" onClick={()=>{
                    document.cookie = "honeyydo_user_invoice=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/; sameSite=strict; secure";
                    // window.location.reload()
                    // userUtility.getUserRoute(user)
                    if(user?.role_id === 4){
                        Navigate("/pro-dashboard");
                    }else{
                        Navigate(userUtility.getUserRoute(user))
                    }
                    }}>Continue</button>
            </div>
        </div>
    );
};
export default Invoice;