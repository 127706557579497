import React from "react";
import "./adminDashboard.css/adminPopup.component.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faXmarkCircle } from "@fortawesome/free-regular-svg-icons";
import { useState, useEffect } from "react";
import sqlService from "../../../services/sqlService";
import { useContext } from "react";
import { UserContext } from "../../../App";
import inputConstants from "../../../constants/inputConstants";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PopupMessage from "../../utility.component/popupMessage.component/popupMessage.component";
import validateUtility from "../../../functions/validateUtility";
import GoogleAutoComplete from "../../utility.component/googleAutoComplete.omponent";
import { useRef } from "react";
import { Modal } from "react-bootstrap";
import MultipleSelection from "../../utility.component/multipleSelection";
import { Spinner } from "react-bootstrap";
/**
 *
 * This component renders a popup form that allows an admin to add an item
 * to a selected table (such as board, brokerage, or agent) with specific input fields.
 *
 * @param {function} props.onClose A function to close the popup.
 * @param {Array} props.columns An array defining the columns for the selected table.
 * @param {function} props.addFunction A function to handle adding the new item to the selected table.
 * @param {function} props.setRefreshData A function to toggle the refreshing of the data.
 * @param {string} props.selectedTable A string representing the selected table (e.g., 'board', 'brokerage').
 */

export default function AdminAdd({
  onClose,
  columns,
  addFunction,
  setRefreshData,
  selectedTable,
}) {
  //get current user
  const user = useContext(UserContext);

  const [inputData, setInputData] = useState({});
  const [boards, setBoards] = useState([]);
  const [brokerages, setBrokerages] = useState([]);
  const [agents, setAgents] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [pros, setPros] = useState([]);
  const [addresses, setAddresses] = useState([])
  const [INDUSTRIES, setINDUSTRIES] = useState([]);

  const [serverMessage, setServerMessage] = useState(null);

  const [requested, setRequested] = useState(false);

  const [selectionOptionsArray, setSelectionOptionsArray] = useState([""])

  const refreshData = () => {
    setRefresh(!refresh);
  };


  const [googlePros, setGooglePros] = useState({});
  const valRef = useRef({});

  const [newAddressKey, setNewAddressKey] = useState(null);
  const addressRef = useRef()
  const inputDataRef = useRef()

  const updateAddress = (key, value, addressKey) => {
    let nAddress = { ...addresses }
    nAddress[addressKey][key] = value;
    let el = document.getElementById(`${addressKey}_honeyydo_pros_address`);
    if (el) {
      el.value = value;
    }
    console.log(nAddress)
    setAddresses(nAddress)
    updateInput("additional_addresses", Object.values(nAddress))
  }

  const runCallBack = (postcode, city1, country1, state1, address1, addressesKey) => {
    let inputField = document.getElementById(`${addressesKey}_honeyydo_pros_address`)
    if (inputField) {
      inputField.value = address1;
    }
    if (!addresses) { return }
    let nAddress = { ...valRef.current ?? {} }
    nAddress[addressesKey]["address"] = address1;
    nAddress[addressesKey]["city"] = city1;
    nAddress[addressesKey]["zip_code"] = postcode;
    nAddress[addressesKey]["country"] = country1;
    nAddress[addressesKey]["state"] = state1;
    setAddresses(nAddress)
    valRef.current = nAddress;
  }



  useEffect(() => {
    if (!newAddressKey) {
      return
    }
    let inputField = document.getElementById(`${newAddressKey}_honeyydo_pros_address`)
    valRef.current = { ...addresses };
    if (!googlePros[`${newAddressKey}_honeyydo_pros_address_google`]) {
      setGooglePros({
        ...googlePros,
        [`${newAddressKey}_honeyydo_pros_address_google`]: <GoogleAutoComplete addressKey={`${newAddressKey}_honeyydo_pros_address`} addresses={newAddressKey} address1FieldRef={inputField} callBack={(postcode, city1, country1, state1, address1, oldKey) => {
          runCallBack(postcode, city1, country1, state1, address1, oldKey);
        }} />
      })
    }
  }, [newAddressKey])


  function deleteAddress(key) {
    let updated = { ...addresses }
    // updated.splice(index, 1)
    delete updated[key]

    let newGooglePro = {}
    for (let key1 of Object.keys(updated)) {
      let el = document.getElementById(`${key1}_honeyydo_pros_address`);
      if (el) {
        el.value = updated[key1].address;
        valRef.current = { ...addresses };
        if (!newGooglePro[`${key1}_honeyydo_pros_address_google`]) {
          newGooglePro[`${newAddressKey}_honeyydo_pros_address_google`] = <GoogleAutoComplete addressKey={`${key1}_honeyydo_pros_address`} addresses={key1} address1FieldRef={el} callBack={(postcode, city1, country1, state1, address1, oldKey) => {
            runCallBack(postcode, city1, country1, state1, address1, oldKey);
          }} />
        }
      }
    }
    // delete newGooglePro[`${newAddressKey}_honeyydo_pros_address_google`];
    setGooglePros(newGooglePro);
    setAddresses(updated)
    updateInput("additional_addresses", Object.values(updated))

  }

  function updateInput(key, value) {
    let nInput = { ...inputData };
    nInput[key] = value;
    setInputData(nInput);
  }

  // Function to handle the input changes and update the state accordingly
  const handleInputChange = (event) => {
    let inputValue = event.target.value;
    if (inputValue && event.target.name === 'phone_number') {
      inputValue = inputValue.replace(/\D/g, '');
    }
    if (inputValue && ["phone_number", "board"].includes(event.target.name)) {
      inputValue = parseInt(inputValue);

    }
    if (inputValue && event.target.name === 'brokerage') {
      var brokerageName = brokerages.filter(item => item.id === parseInt(inputValue))
      if(brokerageName?.length){
        setInputData({ ...inputData, [event.target.name]: inputValue, "brokerage_name": brokerageName[0].contact_name });
      }else{
        setInputData({ ...inputData, [event.target.name]: inputValue });
      }
    }else{
      setInputData({ ...inputData, [event.target.name]: inputValue });
    }


  };

  // Function to handle adding the new item
  const handleAdd = async () => {
    setRequested(true);
    console.log(inputData)
    // console.log(inputData)
    try {
      await addFunction({ user: user, input: inputData });
      setRequested(false);
      setRefreshData((refresh) => !refresh);
      refreshData();
      onClose();
    } catch (err) {
      setRequested(false);
      console.error("Error editing item", err);
    }
  };

  const handleCheck = (e) => {
    console.log(e.target.checked)
    setInputData({ ...inputData, [e.target.name]: e.target.checked });


  }

  // useEffect hook to fetch various data (e.g., boards, brokerages, agents) from the server
  useEffect(() => {
    console.log(columns)
    sqlService
      .getIndustryNames()
      .then((data) => {
        const INDUSTRIES = [];
        for (let i = 0; i < data.data.length; i++) {
          INDUSTRIES[i] = data.data[i].type_name;
        }
        setINDUSTRIES(INDUSTRIES);
        console.log(INDUSTRIES)
      })
      .catch((err) => {
        console.log("Didn't get industry names");
        console.log(err);
      });
    sqlService
      .getAdminBoards({ user: user })
      .then((data) => {
        // setBoards(data);

        let arr = data
        var f = []
        for (let i of arr) {
          f.push(i.contact_name)
        }

        setSelectionOptionsArray(f)
      })
      .catch((err) => {
        console.log(err);
      });

    sqlService
      .getAllBrokerages({ user: user , input : {"request_type" : "read_only"} })
      .then((data) => {
        setBrokerages(data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    sqlService
      .getAllAgents({ user: user, input : {"request_type" : "read_only"} })
      .then((data) => {
        setAgents(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    sqlService
      .getAllPros({ user: user })
      .then((data) => {
        if (Array.isArray(data)) {
          setPros(data);
        } else {
          // console.error("Error: Data returned from getAllPros is not an array");
          setPros([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setPros([]);
      });
  }, [selectedTable, refresh]);

  const [blogImg, setBlogImg] = useState({})

  const handleImgUpload = (event, key) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    if (!validateUtility.validFileSize(file)) {
      setServerMessage({ "message": `File size must be less than ${inputConstants.MAX_FILE_SIZE / 1000000}mb`, "title": "Error" })
      return;
    }

    reader.onload = () => {
      let data = reader.result;
      let t = file.type;
      let type = t.split("/")[1]
      // let subFolder = (key === "logo_link") ? "logos" : "imgs"
      let fileObject = { "imgFile": data, "imgType": type, "imgKey": "img_link", "imgSubfolder": "content/Library" }
      setInputData({ ...inputData, "img_link": fileObject });
      setBlogImg(fileObject)
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };



  useEffect(() => {
    inputDataRef.current = inputData
  }, [inputData])

  const handleSelectionMap = (newValue) => {
    setInputData({ ...inputData, "board": newValue });
  }



  return (
    <>

      <GoogleAutoComplete address1FieldRef={addressRef} callBack={(postcode, city1, country1, state1, address1) => {
        let nInputData = { ...inputDataRef.current, "zip_code": postcode, "city": city1, "country": country1, "state": state1, "address": address1 }
        setInputData(nInputData)
      }} />

      <div>
        {Object.keys(googlePros)?.map(key => googlePros[key])}
      </div>
      <Modal
        show={true}
        hide={onClose}
        size={selectedTable !== 'blogs' && selectedTable !== 'tasks' ? 'lg' : ''}
        style={{ zIndex: '2' }}
      >

        <div className="admin-popup-title">
          <h3>Add</h3>
          <FontAwesomeIcon
            className="admin-popup-close"
            icon={faCircleXmark}
            onClick={onClose}
          />
        </div>
        <div className="admin-add-popup">
          <div className={selectedTable !== 'blogs' && selectedTable !== 'tasks' ? 'input-grid' : ''}>

            {columns
              .filter((column) => column.field !== "creation_date")
              .map((column, index) => (
                <div key={index} className="admin-popup-field">
                  <label className="admin-popup-label ">{column.header}</label>
                  {column.field === "board" ? (
                    // <select
                    //   className="admin-popup-dropdown"
                    //   name={column.field}
                    //   onChange={handleInputChange}
                    // >
                    //   {boards?.map((board, i) => (
                    //     <option key={i} value={board.id}>
                    //       {board.contact_name}
                    //     </option>
                    //   ))}
                    // </select>
                    <MultipleSelection selectionMap={inputData?.board ?? {}} handleSelectionMap={handleSelectionMap} selectionOptionsArray={selectionOptionsArray ?? [""]} />
                    //<></>
                  ) :
                    column.field === "address" ? (
                      <input
                        type="text"
                        className="admin-popup-input"
                        defaultValue=""
                        name={column.field}
                        ref={addressRef}
                        onChange={handleInputChange}
                      />
                    ) : column.field === "brokerage_contact_name" ? (
                      <select
                        className="admin-popup-dropdown"
                        name={"brokerage"}
                        onChange={handleInputChange}
                      >
                        {brokerages?.map((brokerage, i) => (
                          <option key={i} value={brokerage.id}>
                            {brokerage.contact_name}
                          </option>
                        ))}
                      </select>
                    ) : column.field === "agent_name" ? (
                      <select
                        className="admin-popup-dropdown"
                        name={"agent"}
                        onChange={handleInputChange}
                      >
                        {agents?.map((agent, i) => (
                          <option key={i} value={agent.id}>
                            {agent.contact_name}
                          </option>
                        ))}
                      </select>
                    ) :
                      column.field === "content" || column.field === "note" ? (
                        <textarea
                          className="admin-popup-input admin-popup-textarea"
                          defaultValue=""
                          name={column.field}
                          onChange={handleInputChange}
                        />
                      ) : column.field === "img_link" ? (
                        <input type="file" id="imageUpload" accept="image/*" onChange={(e) => handleImgUpload(e, "img_link")} />

                      ) : column.field === "seasons" ? (
                        <select
                          className="admin-popup-dropdown"
                          name={"seasons"}
                          onChange={handleInputChange}
                        >

                          <option value={"Monthly"}>Monthly</option>
                          <option value={"Quarterly"}>Quarterly</option>
                          <option value={"Biannual"}>Biannual</option>
                          <option value={"Yearly"}>Yearly</option>
                          <option value={"Winter"}>Winter</option>
                          <option value={"Spring"}>Spring</option>
                          <option value={"Summer"}>Summer</option>
                          <option value={"Fall"}>Fall</option>

                        </select>
                      ) : column.field === "state" ? (
                        <select
                          className="admin-popup-dropdown"
                          name={"state"}
                          onChange={handleInputChange}
                          value={inputData["state"]}
                        >
                          {addresses[index]?.country !== "Canada" ?
                            inputConstants?.US_STATES.map((val, index) => (
                              <option key={index} value={val.value}>
                                {val.label}
                              </option>
                            )) : null}
                          {addresses[index]?.country !== "United States" ?
                            inputConstants?.CANDADIAN_PROVINCES.map((val, index) => (
                              <option key={index} value={val.value}>
                                {val.label}
                              </option>
                            )) : null}
                        </select>
                      )

                        : column.field === "industry_name" ? (
                          <select
                            className="admin-popup-dropdown"
                            name={"industry_name"}
                            onChange={handleInputChange}


                          >
                            <option value=''>Select</option>

                            {INDUSTRIES?.map((val, index) => (
                              <option key={index} value={val}>
                                {val}
                              </option>

                            ))}
                          </select>

                        ) :
                          column.field === "country" ? (
                            <select
                              className="admin-popup-dropdown"
                              name={"country"}
                              value={inputData["country"]}
                              onChange={handleInputChange}

                            >

                              <option value="">Select Country</option>
                              <option key={"countryCanada"} value={"Canada"}>
                                {"Canada"}
                              </option>
                              <option key={"countryUS"} value={"United States"}>
                                {"United States"}
                              </option>
                            </select>

                          ) : column.field === "phone_number" ? (

                            <input
                              type="tel"
                              className="admin-popup-input"
                              value={inputData[column.field]}
                              name={column.field}
                              onChange={handleInputChange}
                            />

                          ) :



                            (
                              <input
                                type="text"
                                className="admin-popup-input"
                                value={inputData[column.field]}
                                name={column.field}
                                onChange={handleInputChange}
                              />
                            )}
                </div>
              ))}
          </div>


          {selectedTable === 'pros' ?
            <>
              <div className="display-flex align-items-center margin-10-px ">
                <span>Nation Wide? </span>
                <input className="margin-left-15-px" type="checkbox" name={"nation_wide"} checked={inputData.nation_wide || false} onChange={(e) => handleCheck(e)}></input>
                <span className="margin-left-15-px">State/Province Wide? </span>
                <input className="margin-left-15-px" type="checkbox" name={"state_wide"} checked={inputData.state_wide || false} onChange={(e) => handleCheck(e)}></input>
              </div>
              <div className="margin-auto-lr align-items-center display-flex padding-bottom-20-px" >
                <span>Additional service areas</span>
                <button style={{ marginLeft: '15px' }} className="Tag-Button" onClick={() => { const random = `${Math.random()}`; setAddresses({ ...addresses, [random]: {} }); setNewAddressKey(random) }}>Add <FontAwesomeIcon icon={faPlus} /></button>
              </div>
              {Object.keys(addresses).map(key =>
                <div className="input-grid">



                  <div className="admin-popup-field position-relative">
                    <FontAwesomeIcon onClick={() => deleteAddress(key)} icon={faXmarkCircle} className=" grey position-absolute cursor-pointer" style={{ top: '55%', left: '-20%' }} />

                    <label className="admin-popup-label ">Address</label>


                    <input
                      id={`${key}_honeyydo_pros_address`}
                      className="admin-popup-input"
                      type="text"
                      name="Address"
                      value={addresses[key]?.address ?? null}

                      onChange={(e) => {
                        updateAddress("address", e.currentTarget.value, key);

                      }}
                    />
                  </div>


                  <div className="admin-popup-field position-relative">

                    <label className="admin-popup-label ">City</label>


                    <input
                      className="admin-popup-input"
                      type="text"

                      value={addresses[key]?.city ?? null}

                      onChange={(e) => {
                        updateAddress("city", e.currentTarget.value, key);

                      }}
                    />
                  </div>

                  <div className="admin-popup-field position-relative">

                    <label className="admin-popup-label ">State</label>
                    <select
                      name="state"

                      className='admin-popup-input'
                      value={addresses[key]?.state ?? null}
                      onChange={(e) => {
                        updateAddress("state", e.currentTarget.value, key);

                      }}

                    >
                      {addresses[key]?.country !== "Canada" ?
                        inputConstants?.US_STATES.map((val, index) => (
                          <option key={index} value={val.value}>
                            {val.label}
                          </option>
                        )) : null}
                      {addresses[key]?.country !== "United States" ?
                        inputConstants?.CANDADIAN_PROVINCES.map((val, index) => (
                          <option key={index} value={val.value}>
                            {val.label}
                          </option>
                        )) : null}
                    </select>

                  </div>

                  <div className="admin-popup-field position-relative">

                    <label className="admin-popup-label ">Country</label>
                    <select
                      name="country"

                      className='admin-popup-input'
                      value={addresses[key]?.country ?? null}
                      onChange={(e) => {
                        updateAddress("country", e.currentTarget.value, key);

                      }}

                    >

                      <option value="">Select Country</option>
                      <option key={"countryCanada"} value={"Canada"}>
                        {"Canada"}
                      </option>
                      <option key={"countryUS"} value={"United States"}>
                        {"United States"}
                      </option>
                    </select>

                  </div>

                  <div className="admin-popup-field position-relative">

                    <label className="admin-popup-label ">
                      {addresses[key]?.country === "Canada" ? "Postal Code*" : "Zip Code*"}
                    </label>


                    <input
                      className="admin-popup-input"
                      type="text"
                      maxLength={addresses[key]?.country === "Canada" ? "6" : "5"}
                      value={addresses[key]?.zip_code ?? null}

                      onChange={(e) => {
                        updateAddress("zip_code", e.currentTarget.value, key);

                      }}
                    />
                  </div>



                </div>
              )}

            </>
            : null}

          {requested ?
            <Spinner />
            :
            <div className="admin-popup-button-containers">
              <button className="admin-popup-submit-button" onClick={handleAdd}>
                Submit
              </button>
              <button className="admin-popup-cancel-button" onClick={onClose}>
                Cancel
              </button>
            </div>
          }
        </div>
      </Modal>


      <PopupMessage onConfirm={() => { setServerMessage(null) }} message={serverMessage?.message} title={serverMessage?.title} show={serverMessage !== null} />
    </>
  );
}
