import React from "react";
import phoneImg from "../../../assets/images/phone.png"
import sqlService from "../../../services/sqlService";
import { useState, useRef, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import PopupMessage from "../../utility.component/popupMessage.component/popupMessage.component";
import inputConstants from "../../../constants/inputConstants";


export default function AffiliateProgram() {
    const [clientInfo, setClientInfo] = useState({})
    const requiredVals = ["contact_name", "phone_number", "email", "address", "city", "zip_code", "state", "country", "organization"]
    const [serverMessage, setServerMessage] = useState(null)

    function updateClientInfo(key, value) {
        var temp = { ...clientInfo }
        temp[key] = value
        setClientInfo(temp)

    }

    function onSubmit() {
        const token = captchaRef.current.getValue();

        captchaRef.current.reset();
        if (!token) {
            setServerMessage({ "message": "Please verify that you are not a robot", "title": "Error" })
            return
        }
        submitInfo(token)
    }

    const captchaRef = useRef(null)
    const [loadedCaptcha, setLoadedCaptcha] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setLoadedCaptcha(true);
        }, 1500);
    })

    function submitInfo() {
        for (let value of requiredVals) {
            if (!(clientInfo[value])) {
                setServerMessage({ title: 'Oops', message: "Please fill out all required fields" });
                // console.log(value)
                return;

            }
        }
        sqlService.affiliateRequest({ input: clientInfo }).then(() => {
            setServerMessage({
                title: "Thanks for submitting!",
                message: "Someone from our support team will be in touch shortly",
            });

        }).catch((err) => {

        })

    }

    return (
        <>
            <div className="pattern" />

            <div className="margin-auto-lr Affiliate-Program-Wrap">
                <h1 style={{ textAlign: 'left' }} className="heading">Affiliate Program</h1>
                <p >Help your clients grow their business and earn residual income for yourself in the process. By joining our affiliate program, your clients will get the benfits of Honeyydo for realtors and you will earn commission for every client you refer.</p>
                <span className="bold">Benefits for Honeyydo affiliates include:</span>
                <ul>
                    <li>No upfront fees or financial investment required</li>
                    <li>Honeyydo handles the sales process, client billing, and customer support</li>
                    <li>Honeyydo ensures your success with sales and marketing support</li>
                </ul>
                <span className="bold">Benefits for your clients include:</span>
                <ul>
                    <li>Lead generation and client referrals</li>
                    <li>More reviews on Google, Zillow, and Facebook</li>
                    <li>Fully branded and automated marketing on their behalf</li>
                </ul>
                <span className="bold">Who can qualify as a Honeyydo affiliate?</span>
                <ul className="check-list">
                    <li><div>Real estate trainers, coaches, consultants, and speakers</div></li>
                    <li><div>Providers of complementary real estate products or services</div></li>
                    <li><div>Online marketers selling to real estate agents</div></li>
                    <li><div>Virtual assistents</div></li>
                </ul>
                <img src={phoneImg} style={{ width: '30%', minWidth: '200px', marginLeft: '10%', paddingBottom: '30px' }} />

                <p>Interested? Give us a call at 1-800-965-7554 or submit your contact information below and we will get back to you.</p>
                <p>Please note that <span style={{ color: 'red' }}>*</span> denotes a required field</p>
                <div className="card-form height-fit-content  box-shadow-none margin-top-20-px">

                    <div className=" box-shadow-none ">

                        <div className="card-input">

                            <label className="card-input__label">
                                Contact Name<span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                className="card-input__input"
                                autoComplete="off"
                                style={{ backgroundColor: 'white' }}

                                value={clientInfo.contact_name}
                                onChange={(e) => { updateClientInfo("contact_name", e.currentTarget.value); }}

                            />


                        </div>





                        <div className="card-form__row">
                            <div className="card-form__col">
                                <label className="card-input__label">
                                    Phone<span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    type="tel"
                                    className="card-input__input"
                                    autoComplete="off"
                                    style={{ backgroundColor: 'white' }}

                                    value={clientInfo.phone_number}
                                    onChange={(e) => { updateClientInfo("phone_number", e.currentTarget.value); }}

                                />
                            </div>


                            <div className="card-form__col ">
                                <div className="card-input">
                                    <label className="card-input__label">
                                        Ext.<span></span>
                                    </label>
                                    <input
                                        type="tel"
                                        className="card-input__input"
                                        autoComplete="off"
                                        style={{ backgroundColor: 'white' }}

                                        value={clientInfo.extension}
                                        onChange={(e) => { updateClientInfo("extension", e.currentTarget.value); }}

                                    />
                                </div>
                            </div>

                        </div>

                        <div className="card-input">
                            <label className="card-input__label">
                                Address<span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                className="card-input__input"
                                autoComplete="off"
                                style={{ backgroundColor: 'white' }}

                                value={clientInfo.address}
                                onChange={(e) => { updateClientInfo("address", e.currentTarget.value); }}

                            />
                        </div>

                        <div className="card-form__row">
                            <div className="card-form__col">
                                <label

                                    className="card-input__label"
                                >
                                    City<span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    type="text"
                                    className="card-input__input"
                                    style={{ backgroundColor: 'white' }}
                                    autoComplete="off"
                                    value={clientInfo?.city ?? ""}
                                    onChange={(e) => { updateClientInfo("city", e.currentTarget.value); }}


                                />

                            </div>
                            <div className="card-form__col">

                                <label

                                    className="card-input__label"
                                >
                                    {clientInfo.country === "Canada" ? "Province" : "State"}
                                    <span style={{ color: 'red' }}>*</span>
                                </label>
                                <select
                                    style={{ backgroundColor: 'white' }}
                                    name="state"
                                    className="card-input__input -select"
                                    value={clientInfo?.state ?? ""}
                                    onChange={(e) => { updateClientInfo("state", e.currentTarget.value); }}

                                >
                                    {/* <option value="" >
                                                Select State
                                            </option> */}

                                    {clientInfo.country !== "Canada" ?
                                        inputConstants?.US_STATES.map((val, index) => (
                                            <option key={index} value={val.value}>
                                                {val.label}
                                            </option>
                                        )) : null}
                                    {clientInfo.country && clientInfo.country !== "United States" ?
                                        inputConstants?.CANDADIAN_PROVINCES.map((val, index) => (
                                            <option key={index} value={val.value}>
                                                {val.label}
                                            </option>
                                        )) : null}
                                </select>




                            </div>
                            <div className="card-form__col ">
                                <div className="card-input">
                                    <label

                                        className="card-input__label"
                                    >
                                        {clientInfo.country === "Canada" ? "Postal Code" : "Zip Code"}
                                        <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <input
                                        style={{ backgroundColor: 'white' }}
                                        type="text"
                                        className="card-input__input"
                                        maxLength={clientInfo?.country === "Canada" ? "6" : "5"}
                                        autoComplete="off"
                                        value={clientInfo?.zip_code ?? ""}
                                        onChange={(e) => { updateClientInfo("zip_code", e.currentTarget.value); }}


                                    />
                                </div>
                            </div>
                        </div>

                        <div className="card-input">
                            <label className="card-input__label">
                                Country<span style={{ color: 'red' }}>*</span>
                            </label>
                            <select
                                style={{ backgroundColor: 'white' }}
                                name="country"
                                className="card-input__input -select"
                                value={clientInfo.country ?? ""}
                                onChange={(e) => { updateClientInfo("country", e.currentTarget.value); }}

                            >
                                <option value="" >
                                    Select
                                </option>

                                <option value="United States" >
                                    United States
                                </option>


                                <option value='Canada'>
                                    Canada
                                </option>
                            </select>
                        </div>


                        <div className="card-input">
                            <label className="card-input__label">
                                Email Address<span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                style={{ backgroundColor: 'white' }}

                                className="card-input__input"
                                autoComplete="off"
                                value={clientInfo?.email}
                                onChange={(e) => { updateClientInfo("email", e.currentTarget.value); }}
                            />
                        </div>

                        <div className="card-input">
                            <label className="card-input__label">
                                Company or Organization Name<span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                style={{ backgroundColor: 'white' }}

                                className="card-input__input"
                                autoComplete="off"
                                value={clientInfo?.organization}
                                onChange={(e) => { updateClientInfo("organization", e.currentTarget.value); }}
                            />
                        </div>

                        {loadedCaptcha &&
                            <ReCAPTCHA
                                style={{ display: "inline-block", marginTop: '20px' }}
                                theme="light"
                                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY_V2}
                                ref={captchaRef}
                                onChange={null}
                                asyncScriptOnLoad={null}
                            />
                        }

                        <button style={{ marginBottom: '40px' }} className="popup-submit-button Settings-Submit " type="submit" onClick={() => { onSubmit() }}>Submit</button>


                    </div>



                </div>
                {/* <img src={phoneImg} style={{width: '30%', minWidth: '200px', marginLeft: '10%'}} /> */}

                <h4 style={{ textAlign: 'left', padding: '0px 0 10px 0' }} className="heading">Frequently Asked Questions</h4>
                <span className="bold">How much does it cost to join the Honeyydo affiliate program?</span>
                <p>There is no cost to join the program. In fact, you'll earn money from each new client you send our way.</p>
                <span className="bold">When will I start receiving commission payments?</span>
                <p>Your commission payments kick in once the client you referred registers and completes payment for thier subscription.</p>
                <span className="bold">How will I know how much commission I have earned through the Honeyydo affiliate program?</span>
                <p>You will receive a monthly commission statement detailing the names of the clients who made payments in the past month.</p>
                <span className="bold">What are my responsibilities as a Honeyydo affiliate?</span>
                <p>Your only responsibilities are to promote Honeyydo in good faith and abide by the terms of the Honeyydo Affiliate Agreement.</p>
                <span className="bold">What kind of sales and marketing support will I receive?</span>
                <p>We will provide you with support that includes:</p>
                <ul>
                    <li>Honeyydo account for personal use and sales demonstrations</li>
                    <li>Full access to our customer support team</li>
                    <li>Internet-based sales, training and demo support</li>
                    <li>Pre-written drip email promotional campaigns</li>
                    <li>Partner branded sales and marketing collateral</li>
                </ul>
                <p className="margin-top-30-px">If you have any questions or would like more information, please contact us at 1-800-965-7554 or support@honeyydo.com</p>

            </div>
            <PopupMessage title={serverMessage?.title} message={serverMessage?.message} onConfirm={() => setServerMessage(null)} show={serverMessage} />
        </>
    )
}