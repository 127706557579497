import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./agentlanding.component.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAmazon, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faGift, faStar, faArrowDown, faEnvelope, faPhone, faUsers, faCopy, faShare, faCheck, faX, faArrowRight, faMoon, faSun, faPaperPlane, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import TableFooter from "../../utility.component/tableFooter.component";
import ScrollToTop from "../../utility.component/scrollToTop.component";
import BarChart from "../../utility.component/dash.component/barchart.component";
import StarRating from "../../utility.component/rating.component/rating.component";
import Notifications from "../../utility.component/notifications.component/notifications.component";
import { Link } from "react-router-dom";

import sqlService from "../../../services/sqlService";
import sqlEcardService from "../../../services/sqlEcardService";

import { useContext } from "react";
import { UserContext } from "../../../App";

import { json, useNavigate } from "react-router-dom";
import timeUtility from "../../../functions/timeUtility";


// import WeatherMobile from "../../universal.component/weather.component/weatherMobile.component";
// import Weather from "../../universal.component/weather.component/weather.component";
import Notepad from "../../utility.component/notepad.component/notepad.component";
import NotepadMobile from "../../utility.component/notepad.component/notepadMobile.component";
import WelcomeMessage from "../../utility.component/welcomeMessage.component/welcomeMessage.component";
import { eventTupleToStore } from "@fullcalendar/core/internal";
import sortUtility from "../../../functions/sortUtility";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { ThreeCircles } from "react-loader-spinner";
import { RRule } from 'rrule'
import banner2 from "../../../assets/images/banner-photos/Banner5-min.jpg"
import banner5 from "../../../assets/images/banner-photos/Banner9-min.jpg"
import banner8 from "../../../assets/images/banner-photos/Banner3-min.jpg"
import banner9 from "../../../assets/images/banner-photos/Banner2-min.jpg"
import banner11 from "../../../assets/images/banner-photos/Banner1-min.jpg"
import banner12 from "../../../assets/images/banner-photos/Banner4-min.jpg"
import banner14 from "../../../assets/images/banner-photos/Banner8-min.jpg"
import banner15 from "../../../assets/images/banner-photos/Banner6-min.jpg"
import banner20 from "../../../assets/images/banner-photos/Banner7-min.jpg"

function AgentLanding() {
    const bannerImgs = [
        { url: banner9, position: '0 90%', size: 'cover' },
        { url: banner15, position: '0 44%', size: 'cover' },
        { url: banner14, position: '0 41%', size: 'cover' },
        { url: banner12, position: '0 56%', size: 'cover' },
        { url: banner11, position: '0 80%', size: 'cover' },
        { url: banner8, position: '0 56%', size: 'cover' },
        { url: banner5, position: '0 55%', size: 'cover' },
        { url: banner2, position: '0 65%', size: 'auto' },
        { url: banner20, position: '0 49%', size: 'cover' },
      
      ]
        
        const banner = useRef({})
      
        useEffect(() => {
      
          banner.current = bannerImgs[Math.floor(Math.random() * bannerImgs?.length)]
          
        }, [])
    const user = useContext(UserContext);
    const COLORS = ['#fab43f', '#007bff', '#34378b', '#b7b8d6', '#b551dd']

    const [inviteModal, setInviteModal] = useState(false);
    const handleClose = () => {
        setInviteModal(false);
        setCopyMessage('');
        setInviteLink('');
    };

    const [referrals, setReferrals] = useState([]);
    const [clients, setClients] = useState([]);
    const [allClients, setAllClients] = useState([])
    const [leadsNum, setLeadsNum] = useState(0);
    const [currentClientsNum, setCurrentClientsNum] = useState([])
    const [referralData, setReferralData] = useState([]);
    const [refsYMax, setRefsYMax] = useState(0);
    const [signupData, setSignupData] = useState([]);
    const [signupYMax, setSignupYMax] = useState(0)

    const time = new Date().getHours()
    const welcome = `Good ${time < 12 && time > 4 ? 'Morning' : time < 17 && time > 2 ? 'Afternoon' : 'Evening'}, ${user?.contact_name?.split(' ')[0]}`
    const icon = time < 4 || time < 17 ? faSun : faMoon
    const [refresh, setRefresh] = useState(false);
    const allowRender = useRef(false)



    const clientsLoaded = useRef(false);
    const referralsLoaded = useRef(false);
    const blogsLoaded = useRef(false);
    const [blog, setBlog] = useState({})
    const [scheduledEcards, setScheduledEcards] = useState([]);
    const ecardsLoaded = useRef(false)
    const [scheduledEmails, setScheduledEmails] = useState([]);
    const emailsLoaded = useRef(false)
    const [agentEvents, setAgentEvents] = useState([])
    const agentEventsLoaded = useRef(false)

    const [copyMessage, setCopyMessage] = useState('');



    const [windowSize, setWindowSize] = React.useState(window.innerWidth);

    const Navigate = useNavigate();
    const loadedWeather = useRef(false);

    useEffect(() => {
        if (!user) {
            Navigate("/")
        }
    })


    useEffect(() => {
        window.addEventListener('resize', onWindowResize);

        return () => {
            window.removeEventListener('resize', onWindowResize);
        };
    }, []);


    const onWindowResize = () => {
        setWindowSize(window.innerWidth);
    }


    const [inviteLink, setInviteLink] = useState('');

    //   function generateLink() {
    //     sqlService
    //       .addReferral({ user: user })
    //       .then((data) => {
    //         const referral = data;
    //         setInviteLink(referral.link)

    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });


    //   }


    // Called by "copy invitation link" button
    //   function copyLink() {
    //     var copyText = document.getElementById("inviteLink");
    //     copyText.select();
    //     copyText.setSelectionRange(0, 99999);

    //     navigator.clipboard.writeText(copyText.value);

    //     setCopyMessage("Copied to clipboard!");
    //   }


    // Holds data for share invitation link function
    //   const shareData = {
    //     text: "Check out Honeyydo, the all-in-one home-management service",
    //     url: inviteLink

    //   }

    //   async function share() {
    //     try {
    //       await navigator.share(shareData);
    //     } catch (err) {
    //       setCopyMessage(`Error: ${err}`);
    //     }

    //   }


    const [show, setShow] = useState(false)


    useEffect(() => {
        if (!clientsLoaded.current) {
            sqlService
                .getHomeowners({ user: user })
                .then((data) => {
                    const allClients = data.data;
                    setAllClients(allClients);
                    const clients = allClients.filter(client => client.client_type === 1)
                    setClients(clients);
                    const leads = allClients.filter(client => client.client_type === 2)
                    setCurrentClientsNum(clients.length)
                    setLeadsNum(leads.length)


                })
                .catch((err) => {
                    console.log(err);
                });
            clientsLoaded.current = true;
        }
        if (!referralsLoaded.current) {
            sqlService
                .getReferralsByAgent({ user: user })
                .then((data) => {
                    const referrals = data.data;
                    setReferrals(referrals)


                })
                .catch((err) => {
                    console.log(err);
                });
            referralsLoaded.current = true;
        }
        if (!blogsLoaded.current) {
            sqlService.getLibraries()
                .then(data => {
                    console.log(data.data)
                    const blogs = data.data.reverse();
                    setBlog(blogs.find((blog) => blog.tags.includes('Updates')))


                })
                .catch(err => {
                    console.log(err);
                });
            blogsLoaded.current = true;
        }
        if (!ecardsLoaded.current) {

            sqlEcardService.getScheduledEcardsForUser({ "user": user }).then((data) => {
                var mapOfEcards = {};
                for (let item of data.data) {
                    if (mapOfEcards[item.id]) {
                        mapOfEcards[item.id] = [...mapOfEcards[item.id], item];
                    } else {
                        mapOfEcards[item.id] = [item];
                    }
                }
                console.log(Object.keys(mapOfEcards))
                console.log(data.data)

                setScheduledEcards(mapOfEcards)
                ecardsLoaded.current = true;


            })
                .catch((err) => {
                    console.log(err);
                });

        }
        if (!emailsLoaded.current) {
            sqlService.getScheduledEmailsForUser({ "user": user }).then((data) => {
                var mapOfEmails = {};
                for (let item of data.data) {
                    if (mapOfEmails[item.id]) {
                        mapOfEmails[item.id] = [...mapOfEmails[item.id], item];
                    } else {
                        mapOfEmails[item.id] = [item];
                    }
                }
                console.log(mapOfEmails)

                setScheduledEmails(mapOfEmails)
                emailsLoaded.current = true;


            })
                .catch((err) => {
                    console.log(err);
                });

        }
        if (!agentEventsLoaded.current) {
            sqlService.getAgentEvents({ "user": user }).then((data) => {

                setAgentEvents(data.data)
                agentEventsLoaded.current = true;

            })
                .catch((err) => {
                    console.log(err);
                });
        }


    }, [refresh]);

    const [emails, setEmails] = useState([])
    const [events, setEvents] = useState([])
    const [ecards, setEcards] = useState([])
    const [dates, setDates] = useState([])

    useEffect(() => {
        var d = new Date()
        var tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1)
        tomorrow.setHours(0, 0, 0)
        var today = new Date()
        today.setHours(0, 0, 0)
        const day = d.getDate()
        const month = d.getMonth()
        const year = d.getFullYear()

        var temp = []
        var temp1 = []
        var temp2 = []
        var temp3 = []
        for (let event of agentEvents) {
            if (event.repeating === 4 && event.weekly_days.length > 0) {
                var days = event.weekly_days.split('<!>')
                var ds = []

                days = days.splice(0, days.length - 1)
                for (let d of days) {
                    ds.push(RRule[d.toUpperCase()])

                }
                let rule = new RRule({

                    byweekday: ds,
                    dtstart: new Date(event.start_date),
                    until: tomorrow
                })
                if (rule.between(today, tomorrow)?.length) {
                    temp1.push(event)
                }

            } else if (event.repeating) {
                let rule = new RRule({
                    freq: event.repeating === 1 ? RRule.WEEKLY : event.repeating === 2 ? RRule.MONTHLY : RRule.YEARLY,
                    dtstart: new Date(event.start_date),
                    until: tomorrow
                })
                if (rule.between(today, tomorrow)?.length) {
                    temp1.push(event)
                }

            } else {
                let t = new Date(event.start_date)
                if (day === t.getDate() && month === t.getMonth() && d.getFullYear() === t.getFullYear()) {
                    temp1.push(event)

                }
            }
        }


        // const temp1 = agentEvents.filter((event) => event.start_date?.split('T')[0] === d)
        // const temp1 = agentEvents.filter((event) => new Date(event.start_date))

        // const emails = scheduledEmails.filter((email)=>Object.values(email)[0] ==true)
        for (let [key, value] of Object.entries(scheduledEmails)) {
            let t = new Date(value[0]?.start_date)

            if (day === t.getDate() && month === t.getMonth() && year === t.getFullYear()) {
                temp.push(value)

            }
        }

        for (let [key, value] of Object.entries(scheduledEcards)) {
            let t = new Date(value[0]?.start_date)

            if (day === t.getDate() && month === t.getMonth() && year === t.getFullYear()) {
                temp2.push(value)

            }
        }


        for (let c of allClients) {
            if (c.dates) {
                for (let date of c.dates.split('<<<!>>>')) {
                    let temp = date.split('***!***')
                    // console.log(temp[0], temp[1], month, day)
                    // console.log(day == temp[1] && month == temp[0])
                    // var cd = new Date()
                    // cd.setMonth(temp[0])
                    // cd.setDate(temp[1])
                    // let t = new Date(d[0]?.start_date)

                    if (day == temp[1] && month == temp[0]) {
                        temp3.push({ title: temp[2], name: c.contact_name })

                    }
                }
            }
        }


        setEvents(sortUtility.sort('start_date', temp1, true))
        setEmails(sortUtility.sort('start_date', temp, true))
        setEcards(sortUtility.sort('start_date', temp2, true))
        setDates(temp3)



    }, [agentEvents, scheduledEcards, scheduledEmails])






    // Parse data for new referrals chart
    // useEffect(() => {

    //   var refs = [{ y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }]
    //   var initialDate = new Date();
    //   // Set initial date for chart based on length of refs list 
    //   initialDate.setMonth(initialDate.getMonth() - refs.length + 1, 1)
    //   initialDate.setHours(0, 0, 0)

    //   for (var ref of referrals) {
    //     var d = new Date(ref.creation_date.replace(/-/g, '\/'))
    //     // Check that referral date is more recent than initial date 
    //     if (d >= initialDate) {
    //       // Increment appropriate index based on difference of months 
    //       if (d.getFullYear() === initialDate.getFullYear()) {
    //         refs[d.getMonth() - initialDate.getMonth()].y++;
    //         // If initial date was previous year 
    //       } else {
    //         refs[12 - initialDate.getMonth() + d.getMonth()].y++;
    //       }
    //     }
    //   }

    //   // Set month labels 
    //   for (var i = 0; i < refs.length; i++) {
    //     var date = new Date();
    //     date.setMonth(date.getMonth() - refs.length + i + 1, 1)
    //     initialDate.setHours(0, 0, 0)
    //     refs[i].x = date.toLocaleString('default', { month: 'short' });
    //     if (refs[i].y > 1) {
    //       refs[i].label = refs[i].y.toString() + ' new homeowner referrals in ' + date.toLocaleString('default', { month: 'long' });
    //     } else {
    //       refs[i].label = refs[i].y.toString() + ' new homeowner referral in ' + date.toLocaleString('default', { month: 'long' });
    //     }

    //   }

    //   // Set Max y value for chart (min of 3)
    //   const max = Math.max(Math.max(...refs.map(s => s.y)), 3)
    //   setRefsYMax(max)
    //   setReferralData(refs)


    // }, [referrals.length])

    // Parse data for new homeowners chart, same method as above
    useEffect(() => {
        if (!allClients) { return }

        var signups = [{ y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }]
        var initialDate = new Date();
        initialDate.setMonth(initialDate.getMonth() - signups.length + 1, 1)
        initialDate.setHours(0, 0, 0)

        for (var client of allClients) {
            var d = timeUtility.getTimeFromMysqlDatetime(client.creation_date);
            // console.log(d.getDate());
            if (d > initialDate) {
                if (d.getFullYear() === initialDate.getFullYear()) {
                    signups[d.getMonth() - initialDate.getMonth()].y++;

                } else {
                    signups[12 - initialDate.getMonth() + d.getMonth()].y++;
                }
            }
        }


        for (var i = 0; i < signups.length; i++) {
            var date = new Date();
            date.setMonth(date.getMonth() - signups.length + i + 1, 1)
            initialDate.setHours(0, 0, 0)
            signups[i].x = date.toLocaleString('default', { month: 'short' });
            if (signups[i].y > 1) {
                signups[i].label = signups[i].y.toString() + ' new homeowner signups in ' + date.toLocaleString('default', { month: 'long' });
            } else {
                signups[i].label = signups[i].y.toString() + ' new homeowner signup in ' + date.toLocaleString('default', { month: 'long' });
            }

        }

        const max = Math.max(Math.max(...signups.map(s => s.y)), 3)
        setSignupYMax(max)
        setSignupData(signups)


    }, [allClients])

    function getTime(date) {
        var t = 'am'
        const time = new Date(date)
        var hours = time.getHours()
        const min = time.getMinutes()
        if (hours > 12) {
            hours = hours - 12
            t = 'pm'
        }
        return hours + ':' + `${min < 10 ? '0' : ''}` + min + t
    }

    useEffect(() => {

        const onPageLoad = () => {
            allowRender.current = true;

        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad, false);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);



    return (
        <>
            {allowRender.current ?
                <>
                    {!(user?.welcome_seen) ?
                        <WelcomeMessage /> : null}
                    <div className="dashboard-wrap">

                        <div className="homepage-grid ">

                            <div style={{ backgroundImage: `url(${banner.current?.url})`, backgroundPosition: banner.current?.position, backgroundSize: banner.current?.size }} className="homepage-banner display-flex justify-content-space-between">

                                {/* <img src={bannerImg} className="height-width-100-pt object-fit-cover" /> */}
                                <div className="display-flex">
                                    <FontAwesomeIcon style={{filter: 'drop-shadow(1px 1px 15px black)'}}  className="margin-right-10-px white fa-2x" transform={{ rotate: -15 }} icon={icon} />
                                    <h3 style={{ textShadow: ' black 1px 0 20px' }} className="white">

                                        {welcome}</h3>
                                </div>
                                <div className=" dashboard-rating-div"  >

                                    <h5 style={{ textShadow: ' black 1px 0 25px' }} className="white">My Rating</h5>
                                    <div className="position-relative" onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
                                        <StarRating
                                            readOnly={true}
                                            dashboard={true}
                                        />
                                        <div className={` hover-popup ${show ? 'visibility-visible' : 'visibility-hidden'}`}>

                                            {user?.general_rating ?
                                                <span >Your average rating is {user?.general_rating?.toFixed(2)}</span>
                                                : <span>You don't have any ratings yet</span>}
                                        </div>
                                    </div>


                                </div>

                            </div>
                            <div className="grid-container daily-tile">
                                <h5 className="grey margin-bottom-20-px">{new Date().toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' })}</h5>


                                {!events?.length && !emails?.length && !ecards?.length && !dates?.length ?
                                    <h6 className="color-med-grey margin-bottom-20-px margin-top-40-px">
                                        Nothing scheduled for today
                                    </h6> : null}
                                <div className="display-flex flex-direction-column daily-tile-inner">

                                    {events?.map((event) =>
                                        <div className="column-gap-10-px daily-grid">
                                            <div style={{ backgroundColor: event.color, height: '30px' }} className="time-tag">
                                                {getTime(event.start_date)}
                                            </div>
                                            <span className="bold color-med-grey">{event.title}</span>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    {dates?.length ?
                                        <div className="margin-top-10-px margin-bottom-15-px">
                                            {dates?.map((event) =>
                                                <div className="column-gap-10-px daily-grid ">


                                                    <FontAwesomeIcon className="grey fa-lg justify-self-end" icon={faCalendar} />

                                                    <span className="bold color-med-grey">{event.title} ({event.name})</span>
                                                </div>
                                            )}
                                        </div> : null}


                                    {emails?.length || ecards?.length ?
                                        <h6 className="grey margin-bottom-20-px margin-top-10-px">Sending today
                                            <FontAwesomeIcon className="margin-left-10-px" icon={faPaperPlane} />
                                        </h6> : null}
                                    {emails?.map((event) =>
                                        <div className="column-gap-10-px daily-grid">
                                            <div style={{ backgroundColor: '#3788D8', height: '30px' }} className="time-tag">
                                                {getTime(event[0].start_date)}
                                            </div>
                                            <span className="bold color-med-grey">{event[0].subject} ({event?.length})</span>
                                        </div>
                                    )}
                                    {ecards?.map((event) =>
                                        <div className="column-gap-10-px daily-grid">
                                            <div style={{ backgroundColor: '#fab43f', height: '30px' }} className="time-tag">
                                                {getTime(event[0].start_date)}
                                            </div>
                                            <span className="bold color-med-grey">{event[0].subject} ({event?.length})</span>
                                        </div>
                                    )}



                                </div>


                            </div>

                            <div className="graphs grid-container" >
                                <h5 className="grey">My Homeowners</h5>
                                <h6 className="color-med-grey">New Users</h6>

                                <BarChart
                                    barData={signupData}
                                    barColor={COLORS[1]}
                                    xLabel={'Month'}
                                    maxValue={signupYMax}
                                    screenWidth={windowSize}



                                />

                                <h6 style={{ marginTop: '30px' }} className="color-med-grey">Total Users</h6>

                                <BarChart
                                    barData={[{ y: leadsNum, x: "Users", label: `${leadsNum} unconverted homeowner${leadsNum > 1 ? `s` : ''} joined by homeowner referral` }]}
                                    barColor={COLORS[1]}

                                    maxValue={Math.max(Math.max(currentClientsNum, leadsNum), 3)}
                                    secondBar={[{ y: currentClientsNum, x: 'Users', label: `${currentClientsNum} homeowner${currentClientsNum > 1 ? `s` : ''} you invited or converted` }]}
                                    secondBarColor={COLORS[0]}
                                    labels={[{ title: 'Leads', strokeWidth: 10 }, { title: 'Clients', strokeWidth: 10 }]}                                    screenWidth={windowSize}

                                />


                            </div>
                            <div className="grid-container notes">
                                <NotepadMobile />
                            </div>

                            <div className="dashboard-notifications">
                                <Notifications max={5} openFromBottom={true} />
                            </div>
                            <Link className="blog-img-container-landing position-relative" to={`/blog/${blog?.id}`}>
                                <div className="overflow-hidden border-radius-10-px position-relative aspect-ratio">
                                    <img className="height-width-100-pt object-fit-cover blog-img-landing" src={blog?.img_link} />
                                    <div style={{  backgroundColor: ' rgb(105, 105, 105, .5)', padding: '15px 20px'}} className="blog-link-background position-absolute bottom-0 width-full-pt ">
                                            <span style={{ fontSize: '1.1em' }} className=" z-3 white bold">Check out our latest update <FontAwesomeIcon className="blog-arrow" icon={faArrowRightLong} /> </span>
                                        </div>                                </div>
                            </Link>

                        </div>







                    </div >
                </>
                :
                <div className="display-flex justify-content-center align-items-center height-90-vh"><ThreeCircles
                    height={Math.round(window.innerWidth / 10)}
                    width={Math.round(window.innerWidth / 10)}
                    color={COLORS[0]}
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="three-circles-rotating"
                    outerCircleColor=""
                    innerCircleColor=""
                    middleCircleColor=""
                /></div>
            }



        </>
    );
}

export default AgentLanding;
