
function filterBySearch(array, keyword){
    let result = []
    for (let item of array){
        //let string = item.company_name + item.industry_name + item.contact_name + item.email + item.phone_number + item.city + item.state + item.zip_code + item.website + item.image_link + item.agent;
        let string = "";
        var keyV = "" + keyword.toLowerCase() + "";
        keyV = keyV.replace(/\\/g, "\\\\");
        let reg = new RegExp(keyV, "g");
        for(let [key, value] of Object.entries(item)){
            string += value?.toString()
        }
        let match = reg.exec(string.toLowerCase())
        if(match){
            result.push(item)
        }
    }

    return result;
}


function filterBySearchArray(array, keyword){
    let result = []
    for (let item of array){
        let key = "" + keyword.toLowerCase() + "";
        key = key.replace(/\\/g, "\\\\");
        let reg = new RegExp(key, "g");
        let match = reg.exec(item?.toString().toLowerCase())
        if(match){
            result.push(item)
        }
    }
    return result;
}

function filterBySearchArrayOfObjects(array, keyword){
    console.log(keyword)
    let result = []
    for (let item of array){
        var itemEntryString = Object.values(item).map(item => item).join("")
        itemEntryString = itemEntryString.replace(/\\/g, "\\\\");
        var key = "" + keyword?.toLowerCase() + "";
        key = key.replace(/\\/g, "\\\\");
        let reg = new RegExp(key, "g");
        let match = reg.exec(itemEntryString?.toString().toLowerCase())
        if(match){
            result.push(item)
        }
    }
    return result;
}


const searchUtility = {
    filterBySearch,
    filterBySearchArray,
    filterBySearchArrayOfObjects
}

export default searchUtility;