import React, { useState, useEffect, useRef, useContext } from "react";
import { UserContext } from "../../../App";
import "./welcomeMessage.component.css"
import sqlService from "../../../services/sqlService";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, EffectFade, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import welcomeHouseImg from "../../../assets/images/Home_Maintenence_Welcome_Less.png";
import toDoListImg from "../../../assets/images/list.png"
import prosImg from "../../../assets/images/pros.png"
import filingImg from "../../../assets/images/file.png"
import reviewsImg from "../../../assets/images/reviews.png"
import calendarImg from "../../../assets/images/calendar.png"
import phoneImg from "../../../assets/images/phone.png"
import ecardsImg from "../../../assets/images/ecards.png"
import Cookies from "universal-cookie";

export default function WelcomeMessage() {
    const user = useContext(UserContext);
    const historyLoaded = useRef(false)
    const history = useRef([])
    const [showPopup, setShowPopup] = useState(true)
    const [cookie, setCookie] =  useState(new Cookies())
    useEffect(() => {

        if (!historyLoaded.current) {
            sqlService.getLoginHistory().then((data) => {
                history.current = (data)
                historyLoaded.current = true;
            }).catch((err) => {
                console.log(err)

            })
        }
    })


    function hidePopup() {
        document.cookie = `welcome_seen=${JSON.stringify({ "welcome_seen": true})}; expires=${new Date(Date.now() + (30 * 86400000))}; path=/; sameSite=strict; secure`;
        setCookie(new Cookies())
  
        setShowPopup(false)
        sqlService.setWelcomeAsSeen({ user: user }).then((data) => {

        }).catch((err) => {
            console.log(err)

        })

    }

    function updateCookie() {
        document.cookie = `cookie_accept=${JSON.stringify({ "accepted_cookies": true})}; expires=${new Date(Date.now() + (30 * 86400000))}; path=/; sameSite=strict; secure`;
        setCookie(new Cookies())
    
      }
    

    const content = {
        1: [
            { image: welcomeHouseImg, heading: 'Your all-in-one home management site', text: 'Helping homeowners care for their most important investment–their home' },
            { image: toDoListImg, heading: 'Pre-built Maintenance Schedules', text: "Enhance your productivity with Honeyydo's easy-to-use home maintenance schedules. We take the guesswork out of maintaining your new home by letting you know when necessary maintenance tasks should be completed." },
            { image: prosImg, heading: 'Find Local Pros', text: "If you need some backup, consult our database of verified professionals. We do the research so you don't have to and only allow highly rated pros to join. " },
            { image: filingImg, heading: "Digitize Documents", text: "Keep track of everything for your home all in one place. Upload your warranties and owner's manuals so you'll always know where they are when you need them." }],
        2: [
            { image: reviewsImg, heading: 'Turning Your Clients into Advocates', text: "Your tool for lead generation, client engagement and retention, and reputation management" },
            { image: phoneImg, heading: 'Comprehensive Client Management', text: "Easily import your clients and view them along with anyone they invite to the platform. Filter and sort, add notes and dates to remember for your clients, and send review requests and bulk emails from your client list." },
            { image: calendarImg, heading: 'Calendar Made for Agents', text: "Organize your time with consolidated events and automated reminders in your calendar. Schedule events, view and edit upcoming email blasts, and get reminders about your client's important dates–all in one place." },
            { image: prosImg, heading: "Recommend Pros", text: "Strengthen your relationship with your clients by adding your own recommended pros to our database. Your clients will see your recommended pros when they search for pros in their area and be thankful for the help. " },
            { image: ecardsImg, heading: "Ecard Greetings", text: "Stay in touch and top of mind in a unique way with our ecard builder. Choose from one of our predesigned templates and send as is, customize the message or add a signature or logo, or create your own entirely unique greeting. " }
        ],
        3: [
            { image: reviewsImg, heading: 'Turning Your Clients into Advocates', text: "Your tool for lead generation, client engagement and retention, and reputation management" },
            { image: phoneImg, heading: 'Get Started by Adding Your Agents', text: "Easily import your agents and view analytics on how many clients they're bringing to the platform." },
            { image: calendarImg, heading: 'Suite of Features Especially for Agents', text: "Once they log in to their account, your agents will be able to make use of all of our features, including client management, calendar, pro database, and many more." },
        ]
    }


    return (
        <>
            {history.current.length === 1 && showPopup && !cookie?.get("welcome_seen")?.welcome_seen ? 
     
                <div className="Welcome-Message-Popup box-shadow-2">
                    <button className="Tag-Button position-absolute z-index-99" onClick={() => { hidePopup() }}>Close</button>
                    <h2 className="heading">Welcome to Honeyydo!</h2>
                    <div className="Welcome-Slide-Wrapper">
                        <Swiper

                            modules={[Pagination, Navigation]}
                            pagination={true}
                            navigation={true}




                        >

                            {content[user?.role_id]?.map((item, index) =>

                                <SwiperSlide key={index}>
                                    <div className='Welcome-Slide' >
                                        <div className="Welcome-Inner">

                                            <img src={item.image} />
                                            <h4 className='grey heading-font '>{item.heading}</h4>
                                            <p className="grey">{item.text}</p>
                                        </div>
                                    </div>
                                    <div className="swiper-pagination"></div>

                                </SwiperSlide>



                            )}


                        </Swiper>
                    </div>
                </div>

                :
                null}
        </>
    )
}