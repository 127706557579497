import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";


import "./clients.component.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAmazon, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faArrowUp, faCalendarDay, faCalendarDays, faCheck, faCirclePlus, faMagnifyingGlass, faPaperPlane, faPaperclip, faPlus, faPlusCircle, faSave, faStar, faStickyNote, faTrash, faX } from "@fortawesome/free-solid-svg-icons";
import { faArrowDown, faEnvelope, faPhone, faUsers, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { AiOutlineSearch } from "react-icons/ai";
import TableFooter from "../../utility.component/tableFooter.component";
import ScrollToTop from "../../utility.component/scrollToTop.component";
import searchUtility from "../../../functions/searchUtility";
import sortUtility from "../../../functions/sortUtility";
import useKeypress from "react-use-keypress";
import emailtemplates from "../../utility.component/template.component/email-templace.component";
import InviteButton from "../../utility.component/inviteButton.component/inviteButton.component";

import ImportButton from "../../utility.component/importButton.component/importButton.component";

import sqlService from "../../../services/sqlService";
import reloadlyService from "../../../services/reloadlyService";

import { useContext } from "react";
import { UserContext } from "../../../App";
import { SiZillow } from "react-icons/si";
import {
    faFacebook,
    faInstagram,
    faGoogle,
} from "@fortawesome/free-brands-svg-icons";
import PopupMessage from "../../utility.component/popupMessage.component/popupMessage.component";
import validateUtility from "../../../functions/validateUtility";
import inputConstants from "../../../constants/inputConstants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import InputModal from "../../utility.component/inputModal.component/inputModal.component";


const LOGIN_LINK = "https://honeydo.com/#/login";


function Clients() {


    const user = useContext(UserContext);

    const [clients, setClients] = useState([]);


    // Used to display client's name in modal popups
    const [name, setName] = useState("");

    const [refresh, setRefresh] = useState(false);

    const clientsLoaded = useRef(false);

    const [windowSize, setWindowSize] = React.useState(window.innerWidth);

    // Email scheduling stuff
    const [schedulePopup, setSchedulePopup] = useState(false)
    const [finishedPopup, setFinishedPopup] = useState(false)
    const [sendDate, setSendDate] = useState(new Date())

    // Dropdown menu stuff

    const [actionSelect, setActionSelect] = useState('Send')
    const [moveSelect, setMoveSelect] = useState(0)
    const [sendSelect, setSendSelect] = useState('')

    //Sending email stuff
    const [emailForm, setEmailForm] = useState(false)
    const [giftForm, setGiftForm] = useState(false)
    const [emailInfo, setEmailInfo] = useState({})
    const [attachment, setAttachment] = useState([])
    const attachmentFile = useRef(null)
    const [sendRequested, setSendRequested] = useState(false)

    //Stuff for requesting reviews
    const [reviewPopup, setReviewPopup] = useState(false);
    const [recipient, setRecipient] = useState({})
    const socialIcons = { "facebook": faFacebook, "instagram": faInstagram, "google": faGoogle }

    const loadedGiftsProducts = useRef(false);
    const [giftCards, setGiftCards] = useState(null);
    const [selectedGiftCard, setSelectedGiftCard] = useState(null);
    const [unitPrice, setUnitPrice] = useState(0);
    const [giftcardSend, setGiftCardSend] = useState(false)
    const [serverMessage, setServerMessage] = useState(null);
    const [noteDialog, setNoteDialog] = useState(0)
    const [noteText, setNoteText] = useState('')
    const [hint, setHint] = useState(0)
    const MAXLENGTH = 1000;
    const [dateDialog, setDateDialog] = useState(0)
    const [importantDates, setImportantDates] = useState([])
    const [addPopup, setAddPopup] = useState(false)
    const [resendPopup, setResendPopup] = useState(false)

    const [selectedDate, setSelectedDate] = useState({ month: '0', day: '1', title: '', reminder: false })


    // Pagination stuff

    const [currentPage, setCurrentPage] = useState(1);
    const currentPageRef = useRef(1);
    const [totalPages, setTotalPages] = useState(1);
    const [paginatedClients, setPaginatedClients] = useState([]);
    const roomPerPage = 10;
    const indexOfLastRoom = currentPage * roomPerPage;
    const indexOfFirstRoom = indexOfLastRoom - roomPerPage;

    const [scrollCurrentPage, setScrollCurrentPage] = useState(1)
    const [scrollTotalPages, setScrollTotalPages] = useState(1)


    function closeEmailForm() {
        setEmailForm(false)
        setEmailInfo({})
        setAttachment([])
        setSendSelect("Select")
        setSendRequested(false)
    }

    function closeGiftForm() {
        setGiftForm(false);
        setSendSelect("Select")
    }

    useEffect(() => {
        window.addEventListener('resize', onWindowResize);

        return () => {
            window.removeEventListener('resize', onWindowResize);
        };
    }, []);


    const onWindowResize = () => {
        setWindowSize(window.innerWidth);
    }

    function resetNote() {

        setNoteText('');


    }


    function updateNote(val) {
        if (val.length <= MAXLENGTH) {
            setNoteText(val);

        }

    }

    function saveNote() {
        sqlService.addNoteForClient({ user: user, input: { id: noteDialog, note: noteText } }).then((d) => {
            resetNote()
            clientsLoaded.current = false;
            setRefresh(!refresh)
            setNoteDialog(0)

        }).catch((err) => {

        })

    }

    function saveDate() {

        sqlService.addImportantDates({ user: user, input: { dates: importantDates, homeowner: { id: dateDialog, name: clients.find((client) => client.id === dateDialog).contact_name } } }).then(() => {
            clientsLoaded.current = false;
            setRefresh(!refresh)
            setDateDialog(0)


        }).catch((err) => {

        })


    }

    function updateSelectedDate(key, value) {
        var temp = { ...selectedDate }
        temp[key] = value
        setSelectedDate(temp)

    }

    function resetSelectedDate() {
        setSelectedDate({ month: '0', day: '1', title: '', reminder: false })

    }

    function addDate() {
        var temp = [...importantDates]
        temp.push(selectedDate)
        resetSelectedDate();
        setImportantDates(temp)
        console.log(temp)
    }

    function parseDates(client) {
        var dates = []
        if (client.dates) {
            for (let date of client.dates.split('<<<!>>>')) {
                let d = date.split('***!***')
                dates.push({ id: d[3], month: d[0], day: d[1], title: d[2] })

            }

        }

        setImportantDates(dates)
    }

    function removeDate(index) {
        let updated = [...importantDates]
        updated.splice(index, 1)

        setImportantDates(updated)

    }

    // function updateImportantDates(date) {
    //     var updated = [...importantDates]
    //     updated.push(date)
    //     setImportantDates(updated)
    // }

    const [welcomeEmailObject, setWelcomeEmailObject] = useState({
        "image": null,
        "allow_sending": true,
        "header": "",
        "content": '<p>Hi {{homeowner-name}}, you have been invited to Honeyydo by {{my-name}}</p><p>Honeyydo is an all-in-one home-management service that offers customizable task management, a management system for your appliance warranties and manuals, and connects you to verified home maintenance professionals in your area.</p><p>Finish setting up your account to take advantage of better organization and productivity for your home.</p>',

    })

    const resetWelcomeEmail = () => {
        setWelcomeEmailObject({
            "image": { ...welcomeEmailObject.image, "old": welcomeEmailObject.image?.data, "data": null, "new": null },
            "allow_sending": true,
            "header": "",
            "content": '<p>Hi {{homeowner-name}}, you have been invited to Honeyydo by {{my-name}}</p><p>Honeyydo is an all-in-one home-management service that offers customizable task management, a management system for your appliance warranties and manuals, and connects you to verified home maintenance professionals in your area.</p><p>Finish setting up your account to take advantage of better organization and productivity for your home.</p>',
        });


    }

    useEffect(() => {
        sqlService.getAgentWelcomeEmail({ "user": user }).then(data => {
            console.log(data);
            if (data?.data) {
                var d = data?.data;
                if (d?.image) {
                    d["image"] = { "data": d?.image }
                }
                setWelcomeEmailObject(d)
            } else {
                resetWelcomeEmail();
            }
        }).catch(err => {
            console.log(err);
            resetWelcomeEmail();
        });
    }, [])

    const bannerImgs = ['https://honeyydo.s3.us-east-2.amazonaws.com/other/EmailBrandingBackground/House1-min.jpg',
        'https://honeyydo.s3.us-east-2.amazonaws.com/other/EmailBrandingBackground/House2-min.jpg',
        'https://honeyydo.s3.us-east-2.amazonaws.com/other/EmailBrandingBackground/House3-min.jpg',
        'https://honeyydo.s3.us-east-2.amazonaws.com/other/EmailBrandingBackground/House4-min.jpg',
        'https://honeyydo.s3.us-east-2.amazonaws.com/other/EmailBrandingBackground/House5-min.jpg'
    ]

    const banner = useRef({})

    useEffect(() => {

        banner.current = bannerImgs[Math.floor(Math.random() * bannerImgs?.length)]

    }, [])


    const proccessTemplateWelcomeEmail = (temp) => {
        temp = temp.replaceAll("{{homeowner-name}}", resendPopup?.contact_name);
        temp = temp.replaceAll("{{my-name}}", user?.contact_name);
        // var email = ""
        //     for (let it of temp.split(/\n/)) {
        //         email += `<p">${it}</p>`
        //     }
        //     return email;
        return temp

    }

    const emailTemplateWrapper = (endMessage, html) => {
        return `
        
    <!DOCTYPE html>
    
    <html>
    <head>
    
      <meta charset="utf-8">
      <meta http-equiv="x-ua-compatible" content="ie=edge">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
      <title>Email Receipt</title>
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <style type="text/css">
      /**
       * Google webfonts. Recommended to include the .woff version for cross-client compatibility.
       */
      @media screen {
        @font-face {
          font-family: 'Source Sans Pro';
          font-style: normal;
          font-weight: 400;
          src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/ODelI1aHBYDBqgeIAH2zlBM0YzuT7MdOe03otPbuUS0.woff) format('woff');
        }
    
        @font-face {
          font-family: 'Source Sans Pro';
          font-style: normal;
          font-weight: 700;
          src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/toadOcfmlt9b38dHJxOBGFkQc6VGVFSmCnC_l7QZG60.woff) format('woff');
        }
      }
    
      /**
       * Avoid browser level font resizing.
       * 1. Windows Mobile
       * 2. iOS / OSX
       */
      body,
      table,
      td,
      a {
        -ms-text-size-adjust: 100%; /* 1 */
        -webkit-text-size-adjust: 100%; /* 2 */
      }
    
      /**
       * Remove extra space added to tables and cells in Outlook.
       */
      table,
      td {
        mso-table-rspace: 0pt;
        mso-table-lspace: 0pt;
      }
    
      /**
       * Better fluid images in Internet Explorer.
       */
      img {
        -ms-interpolation-mode: bicubic;
      }
    
      /**
       * Remove blue links for iOS devices.
       */
      a[x-apple-data-detectors] {
        font-family: inherit !important;
        font-size: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
        color: inherit !important;
        text-decoration: none !important;
      }
    
      /**
       * Fix centering issues in Android 4.4.
       */
      div[style*="margin: 16px 0;"] {
        margin: 0 !important;
      }
    
      body {
        width: 100% !important;
        height: 100% !important;
        padding: 0 !important;
        margin: 0 !important;
      }
    
      /**
       * Collapse table borders to avoid space between cells.
       */
      table {
        border-collapse: collapse !important;
      }
    
      a {
        color: #1a82e2;
      }
    
      img {
        height: auto;
        line-height: 100%;
        text-decoration: none;
        border: 0;
        outline: none;
      }
      </style>
    
    </head>
    <body style="background-color: #f8a025;">
   
      
    
      <!-- start body -->
      <table border="0" cellpadding="0" cellspacing="0" width="100%">
      <!-- start logo -->
       
   <tr >
        <td align="left" bgcolor="#f7f3f2"  style="background-image: url(${banner.current}); background-size: cover; background-position: center;" >
            <div style="width: 100%; min-width: ${windowSize > 700 ? '40vw' : '60vw'}; margin-bottom: 40px; background-color:rgb(62,59,60, .6); padding: 5px 15px;">
                <h5 style="color: #e2e2e2; font-style: italic;">Brought to you by </h5>
                <h4 style="color: white"> ${user?.contact_name}</h4>
            </div>
     <div style="padding: 15px;">
        <table>
            <tr>
                
                ${user?.img_link ?
                `
                <td>
                    <div style=" overflow: hidden; border-radius: 50%; width: 100px; height: 100px; border: solid 3px white;">
                        <img  src="${user?.img_link}" width="100%" height="100%" style="object-fit: cover; height: 100%"; width: 100%;"/>
                    </div>
                </td>`
                : ''}
                ${user?.logo_link ?
                `
                <td valign="bottom">
                    <div style="margin-left: ${user?.img_link ? '-35px' : '0px'}; overflow: hidden; border-radius: 999px; position: relative; z-index: 10;  width: 50px; height: 50px;">
                        <img  src="${user?.logo_link}" width="100%" height="100%" style="object-fit: cover; height: 100%"; width: 100%;"/>
                    </div>
                </td>`
                : ''}
            </tr>
        </table>
        </div>
       
      

          <!--[if (gte mso 9)|(IE)]>
          
          <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%">
          <tr>
          <td align="center" valign="top" width="100%"  >
          <![endif]-->
      
      

      
 

          <!--[if (gte mso 9)|(IE)]>
          </td>
          </tr>
          </table>
          <![endif]-->
          </td>
        </tr>
    
    
    
        ${proccessTemplateWelcomeEmail(`<tr>
                <td align="left"  bgcolor="#f7f3f2">
                <div  style="margin-top: 30px; padding: 24px">
                ${welcomeEmailObject?.header && welcomeEmailObject?.header !== "" ?
                        `<h2>${welcomeEmailObject?.header}</h2>` : ""}
               ${welcomeEmailObject?.content}
                    
              
                ${welcomeEmailObject?.image?.new || welcomeEmailObject?.image?.data ?
                        `<img src=${welcomeEmailObject?.image?.new ?? welcomeEmailObject?.image?.data} alt="email img" style="max-width: 600px; margin-top: 10px; margin-bottom: 20px;" />`
                        : ""}
                      <p>Here is your Honeyydo account information:</p>
                         <h3 style="background-color: #f4f4f4; padding: 10px; display: inline-block;">Email: samplehomeowner@gmail.com</h3>
                     <h3 style="background-color: #f4f4f4; padding: 10px; display: inline-block;">Password: homeowner_sample_password</h3>
                        <p>Please use this information to login to Honeyydo using the following link and don't forget to change your password</p>
                         <a href="${LOGIN_LINK}"><p>Login</p></a>
                         <p >
                        If you would like more information, please reach out to your agent, {{my-name}}.
                        </p>
                     </div>
                 </td>
             </tr>
      
             `)}
    
    
      
    
    
        <!-- start footer -->
        <tr>
          <td align="left" bgcolor="#f0eae4" style="padding: 24px;">
            <!--[if (gte mso 9)|(IE)]>
            <table align="left" border="0" cellpadding="0" cellspacing="0" width="600">
            <tr>
            <td align="left" valign="top" width="600">
            <![endif]-->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
    
              <!-- start permission -->
      
              <!-- end permission -->
    
              <div style="font-family: Arial, sans-serif; margin: 20px;">
              <p style="font-size:14px">Honeyydo</p>
              <a href="https://honeyydo.com" target="_blank" rel="noreferrer" style="display: inline-block;">
              <img src="https://honeyydo.s3.us-east-2.amazonaws.com/other/Honeyydo-logo-no-text.png" alt="Honeyydo Logo" width="50px" height="50px">
             </a>
     
              
              </div>
              <div style="margin: 20px;">
              <p style="font-size:14px">Want to stop receiving these emails? </p>
              <a style="font-size:14px" href="">Click here to unsubscribe</a>
              </div>
    
            </table>
            <!--[if (gte mso 9)|(IE)]>
            </td>
            </tr>
            </table>
            <![endif]-->
          </td>
        </tr>
        <!-- end footer -->
    
      </table>
      <!-- end body -->
    
    </body>
    </html> 
    
        
        `
    }

    function resendInvitation(client) {
        sqlService.resendInvitation({ user: user, input: { client: client, banner: banner.current } }).then(() => {
            setResendPopup(false)

        }).catch((err) => {

        })
    }


    useEffect(() => {
        if (!loadedGiftsProducts.current) {
            loadedGiftsProducts.current = true;
            //it is paused for now
            // reloadlyService.getGiftsProducts().then(d => {
            //     console.log(d)
            //     setGiftCards(d.data)
            // }).catch(err => {
            //     console.log(err);
            // })
        }
    }, []);

    const [requestedAddAgent, setRequestedAddAgent] = useState(false)
    const [newClient, setNewClient] = useState({ contact_name: '', email: '', phone_number: '', address: '', state: '', zip_code: '', concierge: false, closing_date: '', property_type: '' })
    const clientValues = {

        "nameText": { label: "Name*", key: "contact_name" },
        "emailText": { label: 'Email*', key: 'email' },
        "phoneText": { label: 'Number*', key: 'phone_number' },
        "cityText": { label: 'City*', key: 'city' },
        "addressText": { label: 'Address*', key: 'address' },
        "zipText": { label: user.country === 'Canada' ? 'Postal Code*' : 'Zip Code*', key: 'zip_code' },
        "stateSelect": { label: user.country === 'Canada' ? 'Province*' : 'State*', key: 'state' },
    }
    function updateNewClient(key, value) {
        let nClient = { ...newClient };
        nClient[key] = value;
        setNewClient(nClient);
    }

    function submitNewClient() {
        setRequestedAddAgent(true);
        sqlService.addHomeowner({ user: user, input: newClient }).then((d) => {
            setRequestedAddAgent(false);
            clientsLoaded.current = false;
            setRefresh(!refresh)
            closeAdd()
        }).catch((err) => {
            console.log(err);
            setRequestedAddAgent(false);
            closeAdd()
        });

    }

    function closeAdd() {
        setAddPopup(false)
        setNewClient({ contact_name: '', email: '', phone_number: '', address: '', state: '', zip_code: '', concierge: false, closing_date: '', property_type: '' })
    }



    function filteredTimes(time) {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();


    }


    function sendEmail() {
        if (emailInfo) {
            setSendRequested(true)
            sqlService
                .sendEmailToClients({
                    user: user, input: {
                        subject: emailInfo.subject ?? "No Subject",
                        message: emailInfo.message ?? "No Message",
                        attachment: attachment,
                        recipients: clientEmails
                    }
                })
                .then((data) => {
                    setSendRequested(false)
                    closeEmailForm();


                })
                .catch((err) => {
                    console.log(err);
                });
        }

    }

    function scheduleEmail() {
        if (clientEmails.length === 0) {
            return;
        }

        sqlService.addEmailToUserEmailQueue({ user: user, "input": { subject: emailInfo.subject ?? "No Subject", message: emailInfo.message ?? "No Message", attachment: attachment, recipients: clientEmails, date: sendDate } })
            .then((data) => {
                setSchedulePopup(false)
                closeEmailForm()
                setFinishedPopup(true)

            })
            .catch((err) => {
                console.log(err);
            });




    }

    function updateEmailInfo(key, value) {

        let newInfo = { ...emailInfo }
        newInfo[key] = value;
        setEmailInfo(newInfo);

    }

    const getAttachmentsSizs = (att) => {
        var size = 0;
        for (let a of att) {
            size += a?.size;
        }
        return size;
    }

    const handleAttachmentChange = (event) => {


        const file = event.target.files[0];
        const reader = new FileReader();

        console.log(file?.size)
        if (!validateUtility.validFileSize(file)) {
            setServerMessage({ "message": `File size must be less than ${inputConstants.MAX_FILE_SIZE / 1000000}mb`, "title": "Error" })
            return;
        }
        if ((getAttachmentsSizs(attachment) + file?.size) > inputConstants.MAX_FILE_SIZE) {
            setServerMessage({ "message": `Attachments size must be less than ${inputConstants.MAX_FILE_SIZE / 1000000}mb and your attachements size is will be ${(getAttachmentsSizs(attachment) + file?.size) / 1000000}mb with this file`, "title": "Error" })
            return;
        }

        reader.onload = () => {
            if (!file?.size) {
                setServerMessage({ "message": `File is invalid`, "title": "Error" })
                return
            }

            let data = reader.result;

            let t = file.type;

            let type = t.split("/")[1];

            let updated = [...attachment]
            updated.push({ name: file.name, type: type, data: data, size: file?.size });


            setAttachment(updated);


        };

        if (file) {
            reader.readAsDataURL(file);
        }

    }

    const handleAttachmentUpload = () => {
        attachmentFile.current.click();

    }

    function removeAttachment(index) {
        let updated = [...attachment]
        updated.splice(index, 1)
        console.log(updated)

        setAttachment(updated)

    }



    function closeReviewPopup() {
        setReviewPopup(false)
        setSelectedLinks([])
    }

    function sendReviewRequest() {
        if (selectedLinks.length) {
            sqlService
                .sendReviewRequest({ user: user, input: { links: selectedLinks, recipient: recipient } })
                .then((data) => {
                    closeReviewPopup();


                })
                .catch((err) => {
                    console.log(err);
                });
        }

    }

    const [socialLinks, setSocialLinks] = useState([])
    const socialsLoaded = useRef(false)

    const [selectedLinks, setSelectedLinks] = useState([]);

    function handleSelectionChange(key, value) {
        var selected = [...selectedLinks]

        if (!(selected.includes(value))) {
            selected.push(value)
        } else {
            selected.splice(selected.indexOf(value), 1)
        }

        console.log(selected)
        setSelectedLinks(selected)

    };







    function resetSelections() {

        setMoveSelect(0);
        setSendSelect('');
        setEmailForm(false)
    }

    // Function to move leads to clients and vice versa
    function moveToCurrent(client) {
        var input = { ids: [client.id], type: client.client_type === 1 ? 2 : 1 };
        // for (var client of selectedClients) {
        //     input.ids.push(client.id)
        // }
        sqlService
            .updateLead({ user: user, input: input })
            .then((data) => {
                clientsLoaded.current = false;
                setRefresh(!refresh)


            })
            .catch((err) => {
                console.log(err);
            });

    }

    // Select all / batch selection stuff

    const [selectedClients, setSelectedClients] = useState([])

    // Keeps track of selected indexes of paginated agents
    const [checkedState, setCheckedState] = useState([]);
    const [checkedIds, setCheckedIds] = useState([])

    // Keeps track of whether any clients are selected
    const [selected, setSelected] = useState(false);

    // Update checkedState when select box is selected
    function handleChange(i) {
        // const updatedCheckedState = checkedState.map((item, index) =>
        //     index === i ? !item : item

        // );

        // setCheckedState(updatedCheckedState)
        // updateSelected(updatedCheckedState);

        if (checkedIds.includes(i.id)) {
            var s = [...selectedClients]
            s = s.filter((client) => client.id !== i.id)
            setSelectedClients(s)
            var ids = [...checkedIds]
            ids = ids.filter((id) => id !== i.id)
            setCheckedIds(ids)




        } else {
            var s = [...selectedClients]
            s.push(i)
            setSelectedClients(s)
            var ids = [...checkedIds]
            ids.push(i.id)
            setCheckedIds(ids)


        }
        // var s = [...selectedClients]
        // s.push(i)
        // setSelectedClients(s)
        // var l = [...checkedIds]
        // l.push(i.id)
        // setCheckedIds(l)

    };

    // Update list of selected clients
    function updateSelected(s) {

        var selected = [];
        for (var i = 0; i < paginatedClients.length && i < checkedState.length; i++) {
            if (s[i]) {
                selected.push(paginatedClients[i])
            }

        }
        setSelectedClients(selected)


    }

    // function selectAll() {
    //     if (allSelected.current) {
    //         var s = new Array(checkedState.length).fill(true)
    //     } else {
    //         var s = new Array(checkedState.length).fill(false)
    //     }
    //     setCheckedState(s)
    //     updateSelected(s);
    // }

    function selectAll() {
        // if (allSelected.current) {
        //     var s = new Array(clients.length).fill(true)
        // } else {
        //     var s = new Array(clients.length).fill(false)
        // }
        // setCheckedState(s)

        var p = []
        if (selectedClients.length !== filteredClients.length) {
            setSelectedClients(filteredClients)
            for (let c of filteredClients) {
                p.push(c.id)

            }
        } else {
            setSelectedClients([])
        }
        setCheckedIds(p)
    }

    // useEffect(() => {

    //     if (checkedState.includes(true)) {

    //         setSelected(true)
    //     } else {

    //         setSelected(false)
    //     }

    // }, [checkedState])
    useEffect(() => {

        if (checkedIds.length) {

            setSelected(true)
        } else {

            setSelected(false)
        }

    }, [checkedIds])

    // Constructing mailto link and updating whenever recepient selection changes
    const [emailLink, setEmailLink] = useState('');
    const [GCLink, setGCLink] = useState('');
    const [clientEmails, setClientEmails] = useState([])

    useEffect(() => {
        const { exampletemplate, exampletemplateSubject } = emailtemplates(); // getting the body and subject of the desired template
        const emailBody = exampletemplate(); // Registering the body stuff
        const emailSubject = exampletemplateSubject(); // The subject stuff

        // var str = 'mailto:?bcc='
        // if (selectedClients != []) {
        //     for (var i = 0; i < selectedClients.length - 1; i++) {
        //         str = str + selectedClients[i].email + ',';
        //     }
        //     str = str + `${selectedClients[selectedClients.length - 1]?.email}&subject=${encodeURIComponent(emailSubject)}& body=${encodeURIComponent(emailBody)}`
        // }
        var clients = []

        if (selectedClients != []) {
            for (var i = 0; i < selectedClients.length; i++) {

                clients.push(selectedClients[i].email)
            }

        }

        // setEmailLink(str)
        // console.log(str)
        setClientEmails(clients)
        console.log(clients)

    }, [selectedClients])


    // Filtering and search

    const [filteredClients, setFilteredClients] = useState([]);
    const [currentFilter, setCurrentFilter] = useState(0);
    const [activityFilter, setActivityFilter] = useState(null)

    const cl = useRef([])
    const [searchBarValue, setSearchBarValue] = useState('');

    const onSearchBarChange = (e) => {
        setSearchBarValue(e.currentTarget.value);
        onSearch(e.currentTarget.value)
    };



    useKeypress("Enter", () => {
        // Do something when the user has pressed the Escape key
        onSearch();
    });

    const onSearch = (value) => {
        if ((!value && searchBarValue === "") || (value === "")) {
            // console.log("empty");

            // setPaginatedClients(cl.current);
            // setClients(clients);
            setPaginatedClients(cl.current);
            // setTotalPages(Math.ceil(cl.current?.length / roomPerPage));
        } else {
            let filtered = searchUtility.filterBySearch(
                cl.current,
                value ?? searchBarValue
            );
            setFilteredClients(filtered)
            setPaginatedClients(filtered);
        }
    };


    useEffect(() => {
        let filtered = clients.filter((client) =>

            client.client_type === currentFilter || currentFilter === 0

        );

        let moreFiltered = filtered.filter((client) =>

            !activityFilter || client.last_active > activityFilter

        );

        setFilteredClients(moreFiltered);
        setCurrentPage(1)
    }, [currentFilter, clients, activityFilter]);


    // const nextPage = () => {
    //     setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    //     currentPageRef.current = Math.min(currentPageRef.current + 1, totalPages)
    // };

    // const prevPage = () => {
    //     setCurrentPage((prev) => Math.max(prev - 1, 1));
    //     currentPageRef.current = Math.min(currentPageRef.current - 1, 1)
    // };

    // useEffect(() => {
    //     setTotalPages(Math.ceil(filteredClients.length / roomPerPage));
    // }, [filteredClients]);

    useEffect(() => {

        // const currentClients = filteredClients.slice(indexOfFirstRoom, indexOfLastRoom);
        const currentClients = filteredClients

        setPaginatedClients(currentClients);
        // Initialize selected indexes based on length of paginated client list
        setCheckedState(new Array(filteredClients.length).fill(false))
    }, [filteredClients]);

    // Sorting stuff
    const [sortFilter, setSortFilter] = useState('Date')
    const [ascending, setAscending] = useState(false)

    const sortValues = [{ value: 'Date', label: 'Join Date', key: 'creation_date' },
    { value: 'Name', label: 'Name', key: 'contact_name' },
    { value: 'Referrals', label: 'Referrals', key: 'referrals_number' }]


    const sort = (val, asc) => {

        if (val === 'Name') {
            setClients(sortUtility.sortByName('contact_name', clients, asc))

        } else {
            for (var sortable of sortValues) {
                if (val === sortable.value) {
                    setClients(sortUtility.sort(sortable.key, clients, asc))
                    break;
                }
            }

        }
    }



    useEffect(() => {
        if (!clientsLoaded.current) {
            sqlService
                .getHomeowners({ user: user })
                .then((data) => {
                    var clients = data.data.reverse();
                    for (let client of clients) {
                        if (client.last_active) {
                            client.last_active = new Date(client.last_active)
                        }
                    }
                    setClients(clients);
                    setPaginatedClients(clients);
                    setFilteredClients(clients)
                    cl.current = clients;



                })
                .catch((err) => {
                    console.log(err);
                });
            clientsLoaded.current = true;
        }

        if (!socialsLoaded.current) {
            sqlService
                .getAgentsPB({ user: user })
                .then((data) => {
                    const links = {}
                    for (var item of data.data) {
                        links[item.type_name] = item.link;
                    }
                    setSocialLinks(links)
                    socialsLoaded.current = true;

                })
                .catch((err) => {
                    console.log(err);
                });
        }

    }, [refresh]);


    // useEffect(() => {
    //     console.log (paginatedClients);
    // }, [paginatedClients])
    const [importMenu, setImportMenu] = useState(false);
    const openImportMenu = () => {
        setImportMenu(true);
    }

    const onImport = () => {
        sqlService
            .getHomeowners({ user: user })
            .then((data) => {
                const clients = data.data.reverse();
                setClients(clients);
                setPaginatedClients(clients);
                cl.current = clients;

            })
            .catch((err) => {
                console.log(err);
            });
    }

    const makeGiftCardOrder = () => {
        if (!selectedGiftCard) { return }
        console.log(selectedGiftCard, selectedClients)
        reloadlyService.makeGiftCardOrder({ "input": { productId: selectedGiftCard?.productId, quantity: selectedClients.length, "unitPrice": unitPrice, recipients: selectedClients } }).then(d => {
            console.log(d)
        }).catch(err => {
            console.log(err);
        })
    }


    const handleScrollingTable = (e) => {
        let element = e.target
        let pageNumber = Math.round(element.scrollTop / (element.clientHeight - 100)) + 1;
        setScrollCurrentPage(pageNumber);
        if (element.scrollHeight - Math.round(element.scrollTop) <= element.clientHeight) {
            setScrollCurrentPage(scrollTotalPages);
        }
    }


    useEffect(() => {
        let element = document.getElementById('tableScrollElementHoneyydoClient');
        if (element) {
            let totalPages = Math.round(element?.scrollHeight / (element?.clientHeight - 100));
            console.log(totalPages, element.scrollHeight, element.clientHeight)
            element.scrollTo({ top: 0 })
            setScrollCurrentPage(1);
            setScrollTotalPages(totalPages)
        }
    }, [windowSize, paginatedClients])

    return (
        <>
            <div className="margin-top-50-px">

                {clients.length ?
                    <>


                        {windowSize > 1150 ? (
                            <div>


                                <div className="align-items-center display-flex flex-direction-column  ">

                                    <h2 className="grey">My Homeowners</h2>

                                    <span className="bold">{clients?.length} total clients </span>
                                    {/* {selected ?
                                        <>

                                            <div className="display-flex flex-direction-row justify-content-center desktop-bubble">
                                           
                                                <span>{selectedClients.length} selected</span>


                                                <div className={selected ? "display-block " : "display-none"}>

                                                    <select
                                                        value={actionSelect}
                                                        className="mobile-dropdown header-dropdown margin-left-5-px "
                                                        onChange={(e) => (setActionSelect(e.target.value), resetSelections())}>
                                                        <option value='Send'>Send</option>
                                                        <option value='Move'>Move to</option>
                                                    </select>

                                                </div>
                                                <div className={actionSelect === 'Move' ? "display-block " : "display-none"}>
                                                    <select
                                                        value={moveSelect}
                                                        className="mobile-dropdown header-dropdown margin-left-5-px "
                                                        onChange={(e) => setMoveSelect(e.target.selectedIndex)}>
                                                        <option value={0} >Select</option>
                                                        <option value={1}>Clients</option>
                                                        <option value={2} >Leads</option>
                                                    </select>

                                                    {moveSelect ?
                                                        <FontAwesomeIcon
                                                            className="orange fa-lg padding-left-10-px cursor-pointer"
                                                            icon={faArrowRight}
                                                            onClick={() => moveToCurrent(moveSelect)}
                                                        /> : null
                                                    }

                                                </div>

                                                <div className={actionSelect === 'Send' ? "display-block " : "display-none"}>

                                                    <select
                                                        value={sendSelect}
                                                        className="mobile-dropdown header-dropdown margin-left-5-px "
                                                        onChange={(e) => {
                                                            setSendSelect(e.target.value);
                                                            if (e.target.value === 'email') {
                                                                setEmailForm(true)
                                                            } else if (e.target.value === 'gift') {
                                                                setGiftForm(true)
                                                            }
                                                        }}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="email">Email</option>
                                                        <option value="gift">Gift Card</option>

                                                    </select>

                                                    {emailForm ?
                                                    <Link to={'/agent-email-service'} state={{selected: selectedClients}}>
                                                        <FontAwesomeIcon
                                                            className="orange fa-lg padding-left-10-px cursor-pointer"
                                                            icon={faArrowRight}
                                                    
                                                        /></Link> : null
                                                    }
                                                </div>

                                            </div>

                                        </> : null
                                    } */}
                                    <div style={{ columnGap: '30px' }} className="desktop-bubble">
                                        {/* <div style={{ columnGap: '30px' }} className="display-flex flex-direction-row "> */}
                                        <span >Filter
                                            <select

                                                className="mobile-dropdown header-dropdown margin-left-10-px "
                                                onChange={(e) => (setCurrentFilter(e.target.selectedIndex))}>

                                                <option >All</option>
                                                <option >Clients</option>
                                                <option >Leads</option>
                                            </select>

                                        </span>



                                        <div className="Sort-Radio display-flex justify-content-end">
                                            <span>Sort by:</span>

                                            {sortValues.map((val) => (
                                                <>
                                                    <input key={val.value} type='radio' name='sort' value={val.value} checked={sortFilter === val.value} onChange={() => { setSortFilter(val.value); sort(val.value, ascending) }} ></input>
                                                    <label>{val.label}</label>
                                                </>
                                            ))}

                                            <FontAwesomeIcon
                                                onClick={() => { setAscending(!ascending); sort(sortFilter, !ascending); }}
                                                style={{ marginLeft: '5px' }}
                                                className={`orange cursor-pointer fa-lg ${ascending ? 'fa-flip-vertical' : ''}`}
                                                icon={faArrowDown}
                                            />
                                        </div>

                                        {/* 
                                        </div> */}
                                        {/* <div className="display-flex flex-direction-row justify-content-space-between width-full-pt "> */}
                                        {/* <div >
                                                <span>Active since:</span>
                                                <div className="display-flex ">
                                                    <DatePicker className="Input-Style"

                                                        onChange={(value) => setActivityFilter(value)}
                                                        selected={activityFilter}
                                                    // icon={faCalendar}
                                                    ></DatePicker>
                                                    <button style={{ padding: '0', marginLeft: '5px', zIndex: 1 }} onClick={() => setActivityFilter(null)} className="Tag-Button">Clear</button>
                                                </div>
                                            </div> */}
                                        <div className="Search-Bar-Focus position-relative align-self-end " >
                                            <span

                                                className="position-absolute left-8-px top-4-px purple hover-blue cursor-pointer border-radius-5-px "
                                                onClick={() => {
                                                    onSearch();
                                                }}
                                            >
                                                <AiOutlineSearch />
                                            </span>
                                            <input
                                                style={{ height: '34px' }}
                                                className="Search-Bar"
                                                type="text"
                                                onChange={onSearchBarChange}
                                                value={searchBarValue}
                                                placeholder={"Search"}
                                            />
                                            {/* </div> */}
                                        </div>



                                    </div>
                                    <div id="tableScrollElementHoneyydoClient" className="Table-List-Container" onScroll={handleScrollingTable}>
                                        <table className="Uni-Table-Container">
                                            <thead className="Uni-Table-Header">
                                                <tr>
                                                    {/* <th ><input type='checkbox' className="margin-left-15-px margin-right-10-px " checked={selectedClients.length === filteredClients.length || false} onChange={() => { selectAll() }} /> Select All</th> */}
                                                    <th>Client Name</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>Referrals</th>
                                                    <th>Request Review</th>
                                                    <th>Notes</th>
                                                    <th>Active</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody className="Uni-Table-Body">
                                                {paginatedClients.map((client, index) => (
                                                    <tr key={client.id}  >
                                                        {/* <td><input type='checkbox' className="margin-left-15-px" checked={checkedIds.includes(client.id) || false} onChange={() => handleChange(client)} /></td> */}
                                                        <td className="position-relative">
                                                            <FontAwesomeIcon
                                                                className={`fa-sm padding-right-10-px cursor-pointer ${client.client_type === 1 ? 'orange' : 'star-blue'}`}
                                                                icon={faStar}
                                                                onMouseEnter={() => {
                                                                    setHint(client.id)

                                                                }}

                                                                onMouseLeave={() => setHint(0)}
                                                                onClick={() => moveToCurrent(client)}

                                                            />
                                                            <div style={{ top: '-20px' }} className={`star-hint border-radius-10-px ${hint === client.id ? 'visibility-visible' : 'visibility-hidden'}`}>
                                                                <span className="wrap-none z-1" >{client.client_type === 1 ? 'Client - Click to convert to lead' : 'Lead - Click to convert to client'}</span>
                                                            </div>
                                                            {client.contact_name}
                                                        </td>

                                                        <td>{client.email}</td>
                                                        <td>{client.phone_number}</td>
                                                        <td>{client.referrals_number}</td>
                                                        <td>Send <FontAwesomeIcon icon={faArrowRight} className="grey hover-orange cursor-pointer" onClick={() => (setReviewPopup(true), setRecipient(client))} /></td>
                                                        <td className="position-relative">
                                                            {client.note ?
                                                                <span style={{ marginRight: '5px' }}> {client.note?.split(' ').splice(0, 3).join(' ')}{client.note?.split(' ').length > 3 ? ' ...' : ''}</span> : null}
                                                            <FontAwesomeIcon onClick={() => { setNoteDialog(client.id); setNoteText(client.note) }} className="grey cursor-pointer hover-orange" transform={{ rotate: client.note ? 45 : 0 }} icon={client.note ? faArrowUp : faCirclePlus} />
                                                            {noteDialog === client.id ?
                                                                <div style={{ left: '90%', top: '-150px', width: '300px' }} className="popup box-shadow-2 text-align-left">

                                                                    <textarea style={{ minHeight: '200px', width: '100%' }} value={noteText} onChange={(e) => updateNote(e.target.value)} className="Input-Style"></textarea>
                                                                    <div style={{ justifyContent: 'right' }} className="width-full-pt display-flex ">
                                                                        <span className="count">{noteText?.length ?? 0}/{MAXLENGTH}</span>
                                                                    </div>
                                                                    <div className="display-flex column-gap-10-px margin-top-15-px">
                                                                        <button className="Cancel-Button" onClick={() => { setNoteDialog(0); resetNote(); }}>Close</button>

                                                                        <button className="popup-submit-button" onClick={() => saveNote()} >
                                                                            Save
                                                                            <FontAwesomeIcon className="fa-sm margin-left-5-px" icon={faSave} />
                                                                        </button>
                                                                    </div>
                                                                </div> : null}
                                                        </td>
                                                        <td>
                                                            {client.finished_signup ?
                                                                <FontAwesomeIcon className="green" icon={faCheck} /> :
                                                                <>
                                                                    <FontAwesomeIcon className="margin-right-10-px" icon={faX} />
                                                                    <button style={{ fontSize: '.75em' }} className="Tag-Button" onClick={() => setResendPopup(client)}>Resend Invite</button>
                                                                </>}
                                                        </td>
                                                        {/* <td className="position-relative" >
                                                            <FontAwesomeIcon icon={faCalendar} className="hover-orange cursor-pointer" onClick={() => { parseDates(client); setDateDialog(client.id); }} />
                                                            {dateDialog === client.id ?
                                                                <div style={{ left: '50%', top: '-140px', width: '300px' }} className="popup box-shadow-2 text-align-center">
                                                                    <span style={{ fontSize: '0.9em', marginBottom: '20px' }}>Add any recurring dates you want to be reminded of for this client, such as their anniversary of home purchase or birthday</span>

                                                                    {importantDates.length ?
                                                                        <div className="dates-grid" style={{ minWidth: '80%' }}>
                                                                            {importantDates.map((date, index) =>
                                                                                <  >

                                                                                    <span>{inputConstants.MONTH_ARRAY[date.month]} {date.day}</span>
                                                                                    <span className="bold">{date.title}</span>

                                                                                    <FontAwesomeIcon style={{ alignSelf: 'center' }} className="cursor-pointer hover-red grey fa-sm" icon={faTrash} onClick={() => removeDate(index)} />

                                                                                </>)}
                                                                        </div>
                                                                        : null}
                                                                    <div className="display-flex " style={{ columnGap: '10px', margin: '10px 0' }}>
                                                                        <div style={{ width: '90%' }}>
                                                                            <input className="Input-Style width-full-pt" placeholder="Name" value={selectedDate.title} onChange={(e) => updateSelectedDate('title', e.target.value)}></input>
                                                                            <div style={{ columnGap: '30px', marginTop: '15px' }} className="display-flex width-full-pt justify-content-space-between">
                                                                                <select value={selectedDate.month} onChange={(e) => updateSelectedDate('month', e.target.value)} className="Input-Style" style={{ width: '135px' }}>
                                                                                    {inputConstants.MONTH_ARRAY.map((month, index) =>
                                                                                        <option value={index}>{month}</option>
                                                                                    )}
                                                                                </select>
                                                                                <select className="Input-Style" value={selectedDate.day} onChange={(e) => updateSelectedDate('day', e.target.value)}>
                                                                                    {[...Array(inputConstants.MONTHS[inputConstants.MONTH_ARRAY[selectedDate.month]]).keys()].map((index) =>
                                                                                        <option>{index + 1}</option>
                                                                                    )}



                                                                                </select>

                                                                            </div>
                                                                            <div className="display-flex align-items-center margin-10-px ">
                                                                                <span>Send me a reminder the day before</span>
                                                                                <input className="margin-left-15-px" type="checkbox" name={"reminder"} checked={selectedDate.reminder || false} onChange={(e) => setSelectedDate({ ...selectedDate, [e.target.name]: e.target.checked })}></input>
                                                                            </div>
                                                                        </div>
                                                                        <button style={{ height: '30px', width: '30px', padding: '0' }} onClick={() => addDate()} className="popup-submit-button">

                                                                            <FontAwesomeIcon icon={faPlus} />
                                                                        </button>
                                                                    </div>


                                                                    <div className="display-flex column-gap-10-px margin-top-15-px">
                                                                        <button className="Cancel-Button" onClick={() => { setDateDialog(0); resetSelectedDate() }}>Close</button>


                                                                        <button className="popup-submit-button" onClick={() => saveDate()} >
                                                                            Save
                                                                            <FontAwesomeIcon className="fa-sm margin-left-5-px" icon={faSave} />
                                                                        </button>
                                                                    </div>
                                            
                                                                </div> : null}
                                                                
                                                        </td> */}
                                                        <Link to={`/client/${client.id}`} state={{ client: client }}>
                                                            <FontAwesomeIcon className="fa-lg padding-10-px hover-dark-blue" icon={faMagnifyingGlass} />
                                                        </Link>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </div>
                                    {paginatedClients?.length ?

                                        <div className="center-wrapper margin-top-10-px">
                                            <TableFooter
                                                currentPage={scrollCurrentPage}
                                                totalPages={scrollTotalPages}
                                                nextPage={() => {
                                                    let element = document.getElementById("tableScrollElementHoneyydoClient");
                                                    if (element) {
                                                        element.scrollBy({
                                                            top: element.clientHeight - 100,
                                                            behavior: "smooth",
                                                        })
                                                    }

                                                }}
                                                prevPage={() => {
                                                    let element = document.getElementById("tableScrollElementHoneyydoClient");
                                                    if (element) {
                                                        element.scrollBy({
                                                            top: - (element.clientHeight - 100),
                                                            behavior: "smooth",
                                                        })
                                                    }
                                                }}
                                            />
                                        </div>
                                        : null}
                                </div>
                            </div>
                        ) : (
                            <>

                                <div className="mobile-dashboard-container">

                                    <div className="mobile-dashboard-clients-container position-relative">
                                        <h4>Homeowners</h4>
                                        <span className="bold">{clients?.length} total clients </span>
                                        {/* <span className="padding-top-5-px">
                                            Select all
                                            {' '}
                                            <input
                                                type='checkbox'
                                                checked={checkedIds.length === filteredClients.length}
                                                onChange={() => {

                                                    selectAll()
                                                }} />
                                        </span> */}
                                        {/* {selected ?
                                            <>


                                                <div className="bubble">

                                                    <div className="display-flex flex-direction-row justify-content-center ">

                                                        <span>{selectedClients.length} selected</span>

                                                        <div className={selected ? "display-block " : "display-none"}>

                                                            <select
                                                                value={actionSelect}
                                                                className="mobile-dropdown header-dropdown margin-left-5-px "
                                                                onChange={(e) => (setActionSelect(e.target.value), resetSelections())}>
                                                                <option value='Send'>Send</option>
                                                                <option value='Move'>Move to</option>
                                                            </select>

                                                        </div>
                                                        <div className={actionSelect === 'Move' ? "display-block " : "display-none"}>
                                                            <select
                                                                value={moveSelect}
                                                                className="mobile-dropdown header-dropdown margin-left-5-px "
                                                                onChange={(e) => setMoveSelect(e.target.selectedIndex)}>
                                                                <option >Select</option>
                                                                <option >Clients</option>
                                                                <option >Leads</option>
                                                            </select>

                                                            {moveSelect ?
                                                                <FontAwesomeIcon
                                                                    className="orange padding-left-10-px cursor-pointer"
                                                                    icon={faArrowRight}
                                                                    onClick={() => moveToCurrent(moveSelect)}
                                                                /> : null
                                                            }

                                                        </div>

                                                        <div className={actionSelect === 'Send' ? "display-block " : "display-none"}>

                                                            <select
                                                                value={sendSelect}
                                                                className="mobile-dropdown header-dropdown margin-left-5-px "
                                                                onChange={(e) => {
                                                                    setSendSelect(e.target.value);
                                                                    if (e.target.value === 'email') {
                                                                        setEmailForm(true)
                                                                    } else if (e.target.value === 'gift') {
                                                                        setGiftForm(true)
                                                                    }
                                                                }}
                                                            >
                                                                <option value="">Select</option>
                                                                <option value="email">Email</option>
                                                                <option value="gift">Gift Card</option>

                                                            </select>
                                                        </div>

                                                    </div>
                                                </div>
                                            </> : null
                                        } */}



                                        <div className="bubble">
                                            <div className="display-flex flex-direction-row justify-content-center" >

                                                <span style={{ padding: '0 5px' }}>Filter
                                                    <select

                                                        className="mobile-dropdown header-dropdown margin-left-10-px margin-bottom-10-px"
                                                        onChange={(e) => (setCurrentFilter(e.target.selectedIndex))}>

                                                        <option >All</option>
                                                        <option >Clients</option>
                                                        <option >Leads</option>
                                                    </select>

                                                </span>
                                                <span style={{ padding: '0 5px' }}>Sort by
                                                    <select
                                                        className="mobile-dropdown header-dropdown margin-left-10-px margin-bottom-10-px"
                                                        onChange={(e) => { setSortFilter(e.target.value); sort(e.target.value, ascending) }}
                                                        value={sortFilter}
                                                    >
                                                        <option value='Date'>Join Date</option>
                                                        <option value='Name'>Name</option>
                                                        <option value="Referrals">Referrals</option>

                                                    </select>

                                                    <FontAwesomeIcon
                                                        onClick={() => { setAscending(!ascending); sort(sortFilter, !ascending); }}
                                                        style={{ marginLeft: '5px' }}
                                                        className={`orange cursor-pointer ${ascending ? 'fa-flip-vertical' : ''}`}
                                                        icon={faArrowDown}
                                                    />

                                                </span>

                                            </div>
                                            <div className="Search-Bar-Focus position-relative margin-left-5-px" >
                                                <span

                                                    className="position-absolute left-8-px top-4-px purple hover-blue cursor-pointer border-radius-5-px "
                                                    onClick={() => {
                                                        onSearch();
                                                    }}
                                                >
                                                    <AiOutlineSearch />
                                                </span>
                                                <input
                                                    className="Search-Bar"
                                                    type="text"
                                                    onChange={onSearchBarChange}
                                                    value={searchBarValue}
                                                    placeholder={"Search"}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="Search-Bar-Focus position-relative margin-bottom-10-px">
                                    <span
                                        className="position-absolute left-8-px top-4-px purple hover-blue cursor-pointer border-radius-5-px "
                                        onClick={() => {
                                            onSearch();
                                        }}
                                    >
                                        <AiOutlineSearch />
                                    </span>
                                    <input
                                        className="Search-Bar"
                                        type="text"
                                        onChange={onSearchBarChange}
                                        value={searchBarValue}
                                        placeholder={"Search"}
                                    />
                                </div> */}
                                        {/* {selected ?
                                    <>
                                        {selectedClients.length > 1 ?
                                            <span>{selectedClients.length} homeowners selected</span> :
                                            <span>{selectedClients.length} homeowner selected</span>


                                        }
                                        <div className={selected ? "display-block padding-top-5-px" : "display-none"}>
                                            <span>Move to current clients</span>


                                            <FontAwesomeIcon
                                                className="orange fa-lg padding-left-10-px cursor-pointer"
                                                icon={faArrowRight}
                                                onClick={moveToCurrent()}
                                            />

                                        </div>

                                        <div className={selected ? "display-block padding-top-5-px" : "display-none"}>
                                            <span>Send </span>
                                            <select
                                                className="mobile-dropdown margin-left-5-px margin-bottom-10-px"
                                                onChange={(e) => sendToClient(e.target.value)}>

                                                <option value="All">Select</option>
                                                <option value="Email">Email</option>
                                                <option value="Review">Review Request</option>
                                                <option value="Gift">Gift</option>
                                            </select>
                                            <a href={emailLink}>
                                                <FontAwesomeIcon
                                                    className="orange fa-lg padding-left-10-px"
                                                    icon={faArrowRight}
                                                />
                                            </a>
                                        </div>
                                    </> : null
                                } */}

                                        <div id="tableScrollElementHoneyydoClient" className="Table-List-Container padding-10-px" onScroll={handleScrollingTable}>
                                            {paginatedClients.map((client, index) => (
                                                <div className={`mobile-client position-relative ${checkedIds.includes(client.id) ? 'clicked' : ''}`}

                                                    key={client.id}
                                                >
                                                    <div >
                                                        <div className="mobile-contact-name-container">
                                                            <div className="mobile-contact-name">
                                                                {client.contact_name}
                                                            </div>
                                                        </div>
                                                        <div className="mobile-client-info">
                                                            <div>
                                                                {" "}
                                                                <FontAwesomeIcon
                                                                    className="mobile-dashboard-icons"
                                                                    icon={faEnvelope}
                                                                />{" "}
                                                                {client.email}
                                                            </div>
                                                            <div>
                                                                {" "}
                                                                <FontAwesomeIcon
                                                                    className="mobile-dashboard-icons"
                                                                    icon={faPhone}
                                                                />{" "}
                                                                {client.phone_number}
                                                            </div>
                                                            <div>
                                                                <FontAwesomeIcon
                                                                    className="mobile-dashboard-icons"
                                                                    icon={faUsers}
                                                                />{" "}
                                                                {client.referrals_number} Referrals
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="display-flex justify-content-space-between">
                                                        {/* <button style={{ minWidth: '40px' }} className="Tag-Button" onClick={() => { setDateDialog(client.id); parseDates(client) }}>
                                                        
                                                    </button> */}

                                                        <button style={{ minWidth: '40px' }} className="Tag-Button" onClick={() => { setNoteDialog(client.id); setNoteText(client.note) }}>
                                                            <FontAwesomeIcon icon={faStickyNote} />
                                                        </button>
                                                        <Link to={`/client/${client.id}`} state={{ client: client }}>
                                                            <FontAwesomeIcon className="fa-lg padding-10-px hover-dark-blue" icon={faMagnifyingGlass} />
                                                        </Link>
                                                        {dateDialog === client.id ?
                                                            <div style={{ left: '175px', top: '-5%', width: '300px' }} className="popup box-shadow-2 text-align-center">
                                                                <span style={{ fontSize: '0.9em', marginBottom: '20px' }}>Add any recurring dates you want to be reminded of for this client, such as their anniversary of home purchase or birthday</span>
                                                                {/* <label className="card-input__label">Title</label> */}

                                                                {importantDates.length ?
                                                                    <div className="dates-grid" style={{ minWidth: '80%' }}>
                                                                        {importantDates.map((date, index) =>
                                                                            <  >

                                                                                <span>{inputConstants.MONTH_ARRAY[date.month - 1]} {date.day}</span>
                                                                                <span className="bold">{date.title}</span>

                                                                                <FontAwesomeIcon style={{ alignSelf: 'center' }} className="cursor-pointer hover-red grey fa-sm" icon={faTrash} onClick={() => removeDate(index)} />

                                                                            </>)}
                                                                    </div>
                                                                    : null}
                                                                <div className="display-flex " style={{ columnGap: '10px', margin: '10px 0' }}>
                                                                    <div style={{ width: '90%' }}>
                                                                        <input style={{ width: '100%' }} className="Input-Style " placeholder="Name" value={selectedDate.title} onChange={(e) => updateSelectedDate('title', e.target.value)}></input>
                                                                        <div style={{ marginTop: '15px' }} className="display-flex full-width-pt justify-content-space-between">
                                                                            <select value={selectedDate.month} onChange={(e) => updateSelectedDate('month', e.target.value)} className="Input-Style" style={{ width: '135px' }}>
                                                                                {inputConstants.MONTH_ARRAY.map((month, index) =>
                                                                                    <option value={index}>{month}</option>
                                                                                )}
                                                                            </select>
                                                                            <select className="Input-Style" value={selectedDate.day} onChange={(e) => updateSelectedDate('day', e.target.value)}>
                                                                                {[...Array(inputConstants.MONTHS[inputConstants.MONTH_ARRAY[selectedDate.month]]).keys()].map((index) =>
                                                                                    <option>{index + 1}</option>
                                                                                )}



                                                                            </select>

                                                                        </div>
                                                                        <div className="display-flex align-items-center margin-10-px ">
                                                                            <span>Send me a reminder the day before</span>
                                                                            <input className="margin-left-15-px" type="checkbox" name={"reminder"} checked={selectedDate.reminder || false} onChange={(e) => setSelectedDate({ ...selectedDate, [e.target.name]: e.target.checked })}></input>
                                                                        </div>
                                                                    </div>
                                                                    <button style={{ height: '30px', width: '30px', padding: '0' }} onClick={() => addDate()} className="popup-submit-button">

                                                                        <FontAwesomeIcon icon={faPlus} />
                                                                    </button>

                                                                </div>


                                                                <div className="display-flex column-gap-10-px margin-top-15-px">
                                                                    <button className="Cancel-Button" onClick={() => { setDateDialog(0); resetSelectedDate() }}>Close</button>


                                                                    <button className="popup-submit-button" onClick={() => saveDate()} >
                                                                        Save
                                                                        <FontAwesomeIcon className="fa-sm margin-left-5-px" icon={faSave} />
                                                                    </button>
                                                                </div>
                                                            </div> : null}
                                                        {noteDialog === client.id ?
                                                            <div style={{ top: '-5%', left: '50%', width: '300px' }} className="popup box-shadow-2 text-align-left">

                                                                {/* 
                                                                    <label className="card-input__label">Note</label> */}
                                                                <textarea style={{ minHeight: '200px', width: '100%' }} value={noteText} onChange={(e) => updateNote(e.target.value)} className="Input-Style"></textarea>
                                                                <div style={{ justifyContent: 'right' }} className="width-full-pt display-flex ">
                                                                    <span className="count">{noteText?.length ?? 0}/{MAXLENGTH}</span>
                                                                </div>
                                                                <div className="display-flex column-gap-10-px margin-top-15-px">
                                                                    <button className="Cancel-Button" onClick={() => { setNoteDialog(false); resetNote(); }}>Close</button>

                                                                    <button className="popup-submit-button" onClick={() => saveNote()} >
                                                                        Save
                                                                        <FontAwesomeIcon className="fa-sm margin-left-5-px" icon={faSave} />
                                                                    </button>
                                                                </div>
                                                            </div> : null}

                                                    </div>
                                                </div>



                                            ))}
                                        </div>
                                    </div>
                                    {paginatedClients?.length ?

                                        <div className="center-wrapper margin-top-10-px">
                                            <TableFooter
                                                currentPage={scrollCurrentPage}
                                                totalPages={scrollTotalPages}
                                                nextPage={() => {
                                                    let element = document.getElementById("tableScrollElementHoneyydoClient");
                                                    if (element) {
                                                        element.scrollBy({
                                                            top: element.clientHeight - 100,
                                                            behavior: "smooth",
                                                        })
                                                    }

                                                }}
                                                prevPage={() => {
                                                    let element = document.getElementById("tableScrollElementHoneyydoClient");
                                                    if (element) {
                                                        element.scrollBy({
                                                            top: - (element.clientHeight - 100),
                                                            behavior: "smooth",
                                                        })
                                                    }
                                                }}
                                            />
                                        </div>
                                        : null}
                                </div>
                                <ScrollToTop />
                            </>
                        )}
                    </>

                    :
                    <div className="Empty-Message">
                        <h3>You haven't added any homeowners yet</h3>
                        <p>Invite some of your clients-when they join, you will see them appear here</p>
                    </div>
                }
                <div className="Options-Bar display-flex ">
                    {/* <InviteButton
                        buttonText={'Invite a New Client'}
                    /> */}
                    <button onClick={() => setAddPopup(true)}>Add a Client</button>
                    <ImportButton onImport={onImport} buttonText={"Import Homeowners"} importType={"homeowners"} importMenu={importMenu} setImportMenu={setImportMenu} openImportMenu={openImportMenu} />

                </div>




            </div >
            {(reviewPopup) &&
                <>
                    <div className="backdrop"></div>
                    <div className='review-popup'>
                        <h5 className='grey'>Send a Review Request</h5>
                        {Object.values(socialLinks)[0] ?
                            <>
                                <p className='margin-top-10-px'>Select which accounts you would like to send a request for:</p>
                                <div className='display-flex flex-direction-row column-gap-20-px margin-bottom-5-pt'>
                                    {
                                        Object.entries(socialLinks).map(([key, value]) => (
                                            <div style={{ padding: '0 15px' }} className={`display-flex align-items-center  ${selectedLinks.includes(value) ? 'background-color-light-blue' : ''}`}>
                                                <input type='checkbox' checked={selectedLinks.includes(value) || false} onChange={() => handleSelectionChange(key, value)} />
                                                <p className="margin-left-10-px margin-top-10-px">{key}</p>
                                            </div>
                                        ))
                                    }


                                </div>
                            </>
                            : <p style={{ padding: '5%' }}>It looks like you haven't linked any social media accounts yet. Head over to settings to add your socials so you can conveniently send review requests with one click.</p>}

                        <div className="display-flex column-gap-10-px">
                            {Object.values(socialLinks)[0] ?
                                <button className="popup-submit-button" onClick={() => sendReviewRequest()}>Send</button>
                                : null}
                            <button className="Cancel-Button" onClick={() => closeReviewPopup()}>
                                Close
                            </button>
                        </div>
                    </div>
                </>

            }
            {
                finishedPopup ?
                    <>
                        <div className="backdrop"></div>
                        <div style={{ zIndex: '2000', minWidth: '350px', height: '250px' }} className="popup">
                            <h4 className="grey">Scheduled for {new Date(sendDate).toLocaleString('default', { month: 'short', day: 'numeric' })} </h4>
                            <FontAwesomeIcon className="grey fa-lg" icon={faPaperPlane} />
                            <Link className="text-decoration-none margin-top-30-px" to={"/calendar"}>View scheduled emails <FontAwesomeIcon className="grey" icon={faArrowRight} /></Link>
                            <button className="Cancel-Button margin-top-30-px" onClick={() => (setFinishedPopup(false))}>
                                Close
                            </button>
                        </div>
                    </>

                    : null
            }

            {/* {
                (emailForm) &&
                <>
                    <div className="backdrop"></div>
                    <div style={{ minWidth: '350px' }} className='review-popup'>
                        {schedulePopup ?
                            <>


                                <div style={{ zIndex: '1999' }} className="backdrop"></div>
                                <div style={{ zIndex: '2000', width: '400px', height: '450px' }} className="popup">
                                    <div className="display-flex flex-direction-column flex-wrap">
                                        <label className="card-input__label">
                                            Select Date to Send
                                        </label>
                                        <div style={{ minHeight: '300px', paddingTop: '15px' }}>
                                            <DatePicker className="Input-Style"
                                                minDate={new Date()}
                                                selected={sendDate}
                                                onChange={(value) => { setSendDate(value) }}
                                                inline
                                                showTimeSelect
                                                filterTime={filteredTimes}


                                            />
                                        </div>

                                    </div>

                                    <div className="display-flex column-gap-10-px">
                                        <button className="Cancel-Button" onClick={() => (setSchedulePopup(false), setSendDate(null))}>
                                            Cancel
                                        </button>
                                        <button className="popup-submit-button" onClick={() => (scheduleEmail())}>
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </>
                            : null}
                        <h5 className='grey'>{`Email ${selectedClients?.length} Selected Client${selectedClients?.length > 1 ? 's' : ''} `}</h5>
                        <div className="display-flex flex-direction-column " style={{ width: '90%', maxHeight: '125px', overflow: 'auto', scrollbarWidth: 'thin', margin: '5px 0' }}>
                            {selectedClients.map((client) =>
                                <span>{client.contact_name}</span>
                            )}
                        </div>

                        <div className="card-form height-fit-content box-shadow-none padding-10-px">


                            <div className="card-input">
                                <label htmlFor="emailSubject" className="card-input__label">
                                    Subject
                                </label>
                                <input
                                    type="text"
                                    name="emailSubject"
                                    className="card-input__input"
                                    autoComplete="off"
                                    onChange={(e) => { updateEmailInfo("subject", e.currentTarget.value); }}


                                    value={emailInfo.subject}
                                />
                            </div>
                            <div className="card-input">
                                <label htmlFor="emailMessage" className="card-input__label">
                                    Message
                                </label>
                                <textarea
                                    type="text"
                                    name="emailMessage"
                                    className="card-input__input"
                                    autoComplete="off"
                                    onChange={(e) => { updateEmailInfo("message", e.currentTarget.value); }}


                                    value={emailInfo.message}
                                />
                            </div>
                            <div className="display-flex">
                                <FontAwesomeIcon
                                    className="cursor-pointer grey padding-bottom-20-px"
                                    transform={{ rotate: -45 }}
                                    icon={faPaperclip}
                                    onClick={(e) => handleAttachmentUpload(e)}
                                />
                                <input
                                    className='display-none'
                                    type="file"
                                    ref={attachmentFile}

                                    onChange={(e) => { handleAttachmentChange(e) }}
                                />
                                {attachment !== null ?
                                    <div style={{ marginBottom: '20px' }} className="display-flex flex-direction-column">
                                        {attachment.map((file, index) =>
                                            <div className="display-flex margin-top-5-px">

                                                <span style={{ marginTop: '-5px' }} className="padding-left-10-px ">{file.name}</span>
                                                <FontAwesomeIcon icon={faX} style={{ marginTop: '2px' }} className="grey padding-left-10-px fa-xs cursor-pointer" onClick={() => removeAttachment(index)} />
                                            </div>
                                        )}
                                    </div> : null}
                            </div>
                        </div>

                        <div className="display-flex column-gap-10-px">

                            <button className="Cancel-Button" onClick={() => closeEmailForm()}>
                                Close
                            </button>
                            <button className="popup-submit-button" onClick={() => setSchedulePopup(true)}>Schedule</button>
                            <button className="popup-submit-button" onClick={() => sendEmail()}>{sendRequested ? <Spinner style={{ height: '20px', width: '20px' }} /> : 'Send'}</button>

                        </div>



                    </div>




                </>

            } */}

            {(giftForm) &&
                <>

                    <div className="backdrop"></div>
                    <div style={{ minWidth: '350px' }} className='giftcard-popup'>
                        <h5 className='grey'>Send gift card to your clients using the following links.</h5>

                        <a href="https://www.giftcards.ca/category/categories" target="_blank">Giftcards.ca</a>
                        <a href="https://www.cardly.net/gift-cards/canada" target="_blank">Cardly.net</a>
                        <a href="https://www.walmart.ca/en/digital-gift-cards" target="_blank">Walmart.ca</a>
                        <a href="https://www.bestbuy.ca/en-ca/product/best-buy-blue-gift-card-500/17539490?cmp=knc-s-71700000072725141&gad_source=1&gclid=CjwKCAiA5L2tBhBTEiwAdSxJX0WsxdifQQzRhlN49kzBakBkY1MHQHfu788Z-xkJeiyK-k8OU_PNrhoCi3YQAvD_BwE&gclsrc=aw.ds" target="_blank">Bestbuy.ca</a>



                        <button className="Cancel-Button" onClick={() => closeGiftForm()}>
                            Close
                        </button>

                    </div>
                </>
            }

            {/* {
                (giftForm) &&
                <>
                    <div className="backdrop"></div>
                    <div style={{ minWidth: '350px' }} className='giftcard-popup'>
                        <h5 className='grey'>Send gift card to the Selected Clients</h5>

                        {(giftCards?.length && !giftcardSend) ?
                            <div className="display-flex flex-wrap column-gap-20-px row-gap-20-px overflow-y-scroll height-80-psnt justify-content-center">
                                {giftCards.map(item => <div onClick={() => { setUnitPrice(item?.minRecipientDenomination ? item?.minRecipientDenomination : item?.fixedRecipientDenominations.length ? item?.fixedRecipientDenominations[0] : 0); setSelectedGiftCard(item); }} className={`cursor-pointer display-flex flex-direction-column flex-wrap ${selectedGiftCard?.productId === item.productId ? "background1" : ""}`}>
                                    <span>{item.productName}</span>
                                    <img src={item?.logoUrls[0]} className="gift-card-image" alt="gift-card" />
                                </div>)}
                            </div>
                            : null}
                        {giftcardSend ?
                            <div className="display-flex flex-wrap flex-direction-column column-gap-10-px row-gap-10-px">
                                <h5>Gift card summary</h5>
                                <span>{`(${selectedClients.length} clients) X ($${(unitPrice * 1.3574666).toFixed(2)} price per card) = ($${(selectedClients.length * unitPrice * 1.3574666).toFixed(2)} total)`}</span>
                                <span>{`Your credit card will be charged $${(selectedClients.length * unitPrice * 1.3574666).toFixed(2)}`}</span>
                                <div className="display-flex flex-wrap">
                                    <button className="popup-submit-button" onClick={() => makeGiftCardOrder()}>Purchase gift card{` for ${selectedClients.length} client${selectedClients.length > 1 ? "s" : ""}`}</button>
                                    <button className="Cancel-Button" onClick={() => setGiftCardSend(false)}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                            : null
                        }

                        {!giftcardSend ? <div className="display-flex column-gap-10-px">
                            {selectedGiftCard?.maxRecipientDenomination ?
                                <div className="background-color-shiny display-flex flex-direction-column justify-content-center align-items-center">
                                    <span className="white">{selectedGiftCard?.productName}</span>
                                    <span>Select your gift card value</span>
                                    <div className="display-flex flex-wrap justify-content-center align-items-center">
                                        <input onChange={(e) => { setUnitPrice(e.currentTarget.value) }} value={unitPrice} type="range" min={selectedGiftCard?.minRecipientDenomination ?? 1} max={selectedGiftCard?.maxRecipientDenomination ?? 100} />
                                        <span>${unitPrice}</span>
                                    </div>
                                </div>
                                : null
                            }
                            {selectedGiftCard?.fixedRecipientDenominations.length ?
                                <div className="background-color-shiny display-flex flex-direction-column justify-content-center align-items-center">
                                    <span className="white">{selectedGiftCard?.productName}</span>
                                    {selectedGiftCard?.fixedRecipientDenominations.length > 1 ?
                                        <span>Select your gift card value</span>
                                        : null}

                                    <div className="display-flex flex-wrap justify-content-center align-items-center column-gap-15-px row-gap-15-px">
                                        {selectedGiftCard?.fixedRecipientDenominations.map(item =>
                                            <span className={`cursor-pointer border-radius-5-px ${item === unitPrice ? "background1" : "background-light-grey color-black"}`} onClick={() => { setUnitPrice(item) }}>${item}</span>
                                        )}
                                    </div>
                                </div>
                                : null
                            }
                            <button className="popup-submit-button" onClick={() => setGiftCardSend(true)}>Get Summary</button>
                            <button className="Cancel-Button" onClick={() => closeGiftForm()}>
                                Close
                            </button>

                        </div> : null}



                    </div>




                </>

            } */}
            {addPopup ?
                <InputModal
                    inputValues={clientValues}
                    show={addPopup}
                    hide={closeAdd}
                    title="Add a Client"
                    aggFunction={updateNewClient}
                    submit={submitNewClient}
                    agg={newClient}
                    user={user}
                    newClient={true}
                    requestedAddAgent={requestedAddAgent}
                    setMultipleAggValues={setNewClient}

                /> : null}
            <PopupMessage onConfirm={() => { setServerMessage(null) }} message={serverMessage?.message} title={serverMessage?.title} show={serverMessage !== null} />
            <Modal
                show={resendPopup}
                onHide={() => setResendPopup(null)}
                keyboard={false}
                size="lg"
                focus={true}
            >
                <ModalBody >
                    <h4 className="grey margin-bottom-30-px text-center">Email Preview</h4>
                    <p className="bold grey padding-bottom-20-px">If you would like to send your client a reminder to finish signing up, we will send them the below message with a new temporary password. If you would like to customize your invitation message, you can do so at Settings &#x3e; Invitation Email.</p>

                    <div className="display-flex justify-content-center flex-wrap">

                        <div dangerouslySetInnerHTML={{
                            __html: emailTemplateWrapper()
                        }}></div>
                    </div>

                </ModalBody>
                <Modal.Footer >
                    <button className="Cancel-Button margin-top-30-px" onClick={() => (setResendPopup(false))}>
                        Cancel
                    </button>
                    <button className="popup-submit-button margin-top-30-px" onClick={() => (resendInvitation(resendPopup))}>
                        Send
                    </button>
                </Modal.Footer>

            </Modal>
        </>
    );
}

export default Clients;