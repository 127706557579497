const getDiscountedValue = (dicountInPercentage, actualValue) => {
    if(!dicountInPercentage){
      return actualValue
    }else{
      let num = actualValue - (actualValue * (dicountInPercentage/100))
      return num.toFixed(2)
    }
  }


  const celsiusToFahrenheit = (tempInCelsius)=> {
    if(!(typeof tempInCelsius === "number")){
      return tempInCelsius;
    }else{
      return tempInCelsius * 9 / 5 + 32;
    }
  }

  const calcUtility = {
    getDiscountedValue,
    celsiusToFahrenheit
  }

  export default calcUtility